import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {NotebookStep, NotebookStepFn, OpenSteps} from "../types";
import _ from "lodash"

export type NotebookStepDef = Pick<NotebookStep, "type" | "revert"> & {
  valid: NotebookStepFn<boolean>;
  active: NotebookStepFn<boolean>;
  subSteps?: (query: StructuredQuery) => number;
};

const STEPS: NotebookStepDef[] = [
  {
    type: "data",
    valid: query => true,
    active: query => true,
    revert: null,
  },
  {
    type: "join",
    valid: query => true,
    active: (query, index) =>
      typeof index === "number" && query.joins().length > index,
    subSteps: query => query.joins().length,
    revert: (query, index) => query.removeJoin(index),
  },
  {
    type: "expression",
    valid: query => true,
    active: query => query.hasExpressions(),
    revert: query => query.clearExpressions(),
  },
  {
    type: "filter",
    valid: query => true,
    active: query => query.hasFilters(),
    revert: query => query.clearFilters(),
  },
  {
    type: "summarize",
    valid: query => true,
    active: query => query.hasSummarize(),
    revert: query =>
      query.hasAggregations() || query.hasBreakouts()
        ? query.clearBreakouts().clearAggregations()
        : query,
  },
  {
    type: "sort",
    valid: query => true,
    active: query => query.hasOrderBys(),
    revert: query => query,
  },
  {
    type: "limit",
    valid: query => true,
    active: query => query.hasLimit(),
    revert: query => query.clearLimit(),
  },
];

/**
 * Returns an array of "steps" to be displayed in the notebook for one "stage" (nesting) of a query
 */
export function getQuestionSteps(query: StructuredQuery, openSteps = {}) {
  const allSteps: any[] = [];

  const stagedQueries = query.queries();
  for (const [stageIndex, stageQuery] of stagedQueries.entries()) {
    const {steps, actions} = getStageSteps(
      stageQuery,
      stageIndex,
      openSteps,
    );
    // append actions to last step of previous stage
    if (allSteps.length > 0) {
      allSteps[allSteps.length - 1].actions.push(...actions);
    }
    allSteps.push(...steps);
  }

  for (const [index, step] of allSteps.entries()) {
    step.previous = allSteps[index - 1];
    step.next = allSteps[index + 1];
  }

  return allSteps;
}


/**
 * Returns an array of "steps" to be displayed in the notebook for one "stage" (nesting) of a query
 */
function getStageSteps(
  stageQuery: StructuredQuery,
  stageIndex: number,
  openSteps: OpenSteps,
) {
  const getId = (step: any, itemIndex: number | null) => {
    const isValidItemIndex = itemIndex != null && itemIndex > 0;
    return (
      `${stageIndex}:${step.type}` + (isValidItemIndex ? `:${itemIndex}` : "")
    );
  };

  function getStep(STEP: any, itemIndex: number | null = null) {
    const id = getId(STEP, itemIndex);
    let isValid = STEP.valid(stageQuery, itemIndex, stageIndex);
    let isActive = STEP.active(stageQuery, itemIndex, stageIndex);
    let isOpenSteps = openSteps[id];
    let isVisible = isValid && !!(isActive || isOpenSteps)
    const step: NotebookStep = {
      id: id,
      type: STEP.type,
      stageIndex: stageIndex,
      itemIndex: itemIndex,
      query: stageQuery,
      visible:
        STEP.valid(stageQuery, itemIndex, stageIndex) &&
        !!(
          STEP.active(stageQuery, itemIndex, stageIndex) ||
          openSteps[id]
        ),
      valid: !openSteps[id],
      actions: [],
      update: datasetQuery => {
        let newQuery = stageQuery.setDataQuery(datasetQuery);
        return newQuery;
      },
      revert: STEP.revert
        ? (query: StructuredQuery) =>
          STEP.revert
            ? STEP.revert(query, itemIndex, stageIndex)
            : null
        : null,
      next: null,
      previous: null,
    };
    return step;
  }

  // get the currently visible steps, flattening "items"
  const steps = _.flatten(
    STEPS.map(STEP => {
      if (STEP.subSteps) {
        // add 1 for the initial or next action button
        const itemIndexes = _.range(0, STEP.subSteps(stageQuery) + 1);
        return itemIndexes.map(itemIndex => getStep(STEP, itemIndex));
      } else {
        return [getStep(STEP)];
      }
    }),
  );

  let actions = [];
  for (let i = steps.length - 1; i >= 0; i--) {
    const step = steps[i];
    if (step.visible) {
      step.actions = actions;
      actions = [];
    } else {
      if (step.valid) {
        actions.unshift({
          type: step.type,
          action: ({
                     openStep,
                   }: {
            openStep: (id: NotebookStep["id"]) => void;
          }) => openStep(step.id),
        });
      }
      steps.splice(i, 1);
    }
  }

  return {steps, actions};
}
