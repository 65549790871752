import React from "react";
import PopoverWithTrigger from "@byk/pages/QueryBuilder/components/ui/PopoverWithTrigger";
import Join from "@byk/pages/QueryBuilder/lib/queries/structured/Join";
import {QueryBuilderIcons} from "../../../../../../../icons/QueryBuilderIcons";
import {
  JoinTypeOptionRoot,
  JoinTypeSelectRoot
} from "@byk/pages/QueryBuilder/components/notebook/steps/JoinStep/JoinStep.styled";

export interface JoinTypePickerProps {
  join: Join,
  color: string,
  updateQuery: (query: any) => Promise<void>;
}

const JoinTypePicker: React.FC<JoinTypePickerProps> =
  ({
     join,
     color,
     updateQuery,
   }: JoinTypePickerProps) => {
    let strategyOption = join.strategyOption();
    let StrategyOptionIcon = QueryBuilderIcons[strategyOption.icon]

    const onChange = async (strategy: any, onClose: any) => {
      await updateQuery(join.setStrategy(strategy).parent());
      onClose();
    }

    return (
      <PopoverWithTrigger
        triggerElement={
          <StrategyOptionIcon
            color={color}
            height={28} width={28}
          />
        }
      >
        {({onClose}: any) => (
          <JoinTypeSelectRoot>
            {join.strategyOptions().map(option => (
              <JoinTypeOptionRoot
                key={option.value}
                isSelected={strategyOption.value === option.value}
                onClick={() => onChange(option.value, onClose)}
              >
                {option.name}
              </JoinTypeOptionRoot>
            ))}
          </JoinTypeSelectRoot>
        )}
      </PopoverWithTrigger>
    );

  };
export default JoinTypePicker;

