export default function Config ():string{
  return `
interface XState {
  message: string,
  status: string,
  isOpened: boolean
}
const initState: XState = {
  message: 'none',
  status: 'success',
  isOpened: false
}
export const X_SET_TOAST = 'X_TOAST';
export const X_TOAST_ACTION = 'X_TOAST_ACTION';

export default function XReducers(state=initState, action: any) {
  const {type, payload} = action;
  switch (type) {
    case X_SET_TOAST:
      return {
        ...state,
        ...payload.payload
      }
    default:
      return state
  }
}


  `;
}
