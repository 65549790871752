import {Button, message} from 'antd';
import React, {useState} from 'react';
import Api from "@byk/api/Api";
import {DeleteOutlined, LoadingOutlined} from "@ant-design/icons";
import {getTenant} from "../../../../utils/localStorage";

const SQL_FILE = '.sql'
const UploadSql = (props: any) => {
  const {text, callback} = props;
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [code, setCode] = useState('');
  const [fileId, setFileId] = useState('');
  const uploadChange = (ev: any) => {
    let files = ev.target.files;
    if (files[0]) {
      const isLt2M = files[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(`上传文件大小不能超过 2MB!`);
        return false;
      }
      let name = files[0]['name'];
      let ext = '';
      ext = name.substring(name.lastIndexOf('.'));
      if (ext === SQL_FILE) {
        uploadFile(files[0]);
      } else {
        message.error('只能上传sql格式文件！');
      }
    }
  };
  const uploadFile = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      let base64 = e.target['result'];
      base64 = base64.split('base64,')[1];
      let params = {
        data: base64,
        ext: file.name.substring(file.name.lastIndexOf('.')),
        filename: file.name,
        type: file.type,
      };
      setLoading(true);
      Api.post(`/byk/${getTenant()}/rest/SysFile`, params).then((res: any) => {
        let fileObj = res.result[0];
        setFileId(fileObj['id']);
        setCode(fileObj['code'])
        setFileName(file.name);
        callback(fileObj)
      }).finally(() => {
        setLoading(false);
      });
    };
  };

  const deleteFile = () => {
    setLoading(true);
    Api.delete(`/byk/${getTenant()}/rest/SysFile?id=` + fileId).then((res: any) => {
      setFileId('');
      setFileName('');
      callback(null)
    }).finally(() => {
      setLoading(false);
    });
  }

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = `/api/byk/${getTenant()}/file/` + code;
    document.body.appendChild(link);
    link.target = "blank"
    link.download = fileName;
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div style={{position: 'relative', height: '20px'}}>
      {
        fileName ? (<div>
            {text}
            <text style={{color: "red"}} onClick={downloadFile}>({fileName})</text>
            <DeleteOutlined style={{padding: "0px", color: "red"}} onClick={deleteFile}/></div>)
          : (<Button type='link'>{text}</Button>)
      }
      {
        loading && <div style={{position: 'absolute', zIndex: 3, left: '50%', top: '50%', margin: '-10px 0 0 -10px'}}>
          <LoadingOutlined/></div>
      }
      {!fileName &&
      <input disabled={loading}
             style={{width: '100%', height: '20px', opacity: '0', position: 'absolute', left: 0, top: 0}}
             accept={SQL_FILE}
             type="file" onChange={uploadChange}/>
      }
    </div>
  );
};

export default UploadSql;
