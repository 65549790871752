import {NotebookStep as INotebookStep,} from "../types"
import React, {useCallback, useMemo} from "react";
import {STEP_UI} from "./steps";
import {
  StepActionsContainer,
  StepBody,
  StepContent,
  StepHeader,
  StepRoot
} from "@byk/pages/QueryBuilder/components/notebook/NotebookStep/NotebookStep.styled";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {QueryBuilderIcons} from "../../../../../../icons/QueryBuilderIcons";

interface NotebookStepProps {
  step: INotebookStep;
  query: StructuredQuery;
  openStep: (id: string) => void;
  updateQuery: (query: StructuredQuery) => Promise<void>;
}

function NotebookStep(
  {
    step,
    openStep,
    query,
    updateQuery,
  }: NotebookStepProps) {
  console.log("3. NotebookStep", step, query);
  if (!step) {
    return null;
  }

  const canRevert = typeof step.revert === "function";

  const actionButtons = useMemo(() => {
    const actions = [];

    actions.push(
      ...step.actions.map(action => {
        const stepUi = STEP_UI[action.type];
        // @ts-ignore
        let StepIcon = QueryBuilderIcons[stepUi.icon]
        return {
          priority: stepUi.priority,
          button: (
            <StepIcon
              className="ml-auto text-light text-medium-hover hover-child"
              onClick={() => action.action({openStep})}
              color={stepUi.getColor()}
              height={24} width={24}
            />
          ),
        };
      }),
    );

    actions.sort((a, b) => (b.priority || 0) - (a.priority || 0));

    return actions.map(action => action.button);
  }, [step.query, step.actions]);

  const handleClickRevert = useCallback(async () => {
    const reverted = step.revert?.(
      step.query,
      step.itemIndex,
      step.stageIndex,
    );
    if (reverted) {
      await updateQuery(reverted);
    }
  }, [step, updateQuery]);

  const {
    title,
    getColor,
    component: NotebookStepComponent,
  } = STEP_UI[step.type] || {};
  const color = getColor();
  return (
    <div>
      <StepRoot
        className="hover-parent hover--visibility"
      >
        <StepHeader color={color}>
          {title}
          {canRevert && (
            <QueryBuilderIcons.Close
              className="ml-auto text-light text-medium-hover hover-child"
              onClick={handleClickRevert}
              color="#949aab"
              height={24} width={24}
            />
          )}
        </StepHeader>
        {NotebookStepComponent && (
          <StepBody>
            <StepContent>
              <NotebookStepComponent
                step={step}
                color={color}
                query={query}
                updateQuery={updateQuery}
                isLastOpened={false}
              />
            </StepContent>
          </StepBody>
        )}

        {query.valid() && (
          <StepActionsContainer data-testid="action-buttons">
            {actionButtons}
          </StepActionsContainer>
        )}
      </StepRoot>

    </div>
  )
}

export default NotebookStep;
