import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {AtTabBar, AtIcon} from "taro-ui";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import Api from "../../api/Api";
import {getCurrentApplicationId} from "../../selectors/editorSelectors";
const W375 = css`
  width: 375px;
`
const BgCanvas = styled.div`
  ${W375}
  height:600px;
  background: #ccc;
`;
const AtTabBarWrapper = styled.div`
  ${W375}
  .at-tab-bar{
    padding: 0;
  }
  .at-tab-bar__icon{
    font-size: 24px;
  }
  .at-tab-bar__title{
    font-size: 16px;
  }
`;
const NoMenuDiv = styled.div`
  ${W375}
  text-align: center;
  color: #ccc;
  padding: 5px;
`;
function AppEditMenuMobile(props:any){
  const{ children, isMobileFrame } = props;
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [tabList, setTabList] = useState([]);
  const appId = useSelector(getCurrentApplicationId);
  const frameDSL = useSelector((state:any)=>{
    return state.XReducers.iframeDSL;
  });

  const reMenusFun = (data:any)=>{
    return data.filter((i:any)=>{
      return i.menuKey !== 'other';
    }).map((i:any)=>{
      return {
        title: i.title,
        iconType: i.icon
      }
    });
  }

  useEffect(() => {
    if(_.isEmpty(frameDSL.menus)){
      Api.get(`/byk/platform/app/appMenu`, {
        appId: appId
      }).then((res:any)=>{
        dispatch({
          type: 'X_IFRAMEDSL',
          data: {
            menus: res.result
          }
        })
      });
    }

  }, []);

  useEffect(()=>{
    if(!_.isEmpty(frameDSL.menus)){
      let menus = reMenusFun(frameDSL.menus);
      setTabList(menus);
    }else{
      setTabList([]);
    }
  }, [frameDSL.menus]);


  return (
    <>
      {children}
      {
        isMobileFrame&&(
          <BgCanvas />
        )
      }
      {
        isMobileFrame&&tabList.length === 0&&(
          <NoMenuDiv>请在右侧配置菜单</NoMenuDiv>
        )
      }
      <AtTabBarWrapper>
        <AtTabBar
          tabList={tabList}
          onClick={(value:number)=>{
            setCurrent(value);
          }}
          current={current}
        />
      </AtTabBarWrapper>

    </>
  )
}
export default AppEditMenuMobile;
