import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout,PageHeader } from 'antd';
import BusinessPage from "@byk/pages/AppSystemInit/RoleManager/RoleList";
import {getProjectId} from "../../../../utils/localStorage";

const { Content, Sider } = Layout;

export default class Home extends Component {
    render() {
        return (
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head  title="ApplicationConfig"  />
                    <Layout>
                        <Sider className="Sider-Navbar"><Navbar title="SysAppRole"/></Sider>
                        <Content>
                            <BusinessPage   applicationId={getProjectId()} isPt={true}/>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

