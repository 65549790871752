import * as React from "react";
import {useCallback} from "react";
import AccordionList from "@byk/pages/QueryBuilder/components/ui/AccordionList/AccordionList";
import {getIcon} from "../../../../../icons/QueryBuilderIcons";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";

const DEFAULT_MAX_HEIGHT = 610;

export interface Props {
  className?: any,
  query: StructuredQuery,
  columnGroups?: any,
  checkIsColumnSelected: (item: any) => boolean,
  onSelect: (column: any) => void,
  maxHeight?: number,
  onClose?: () => void;
  stageIndex?:any,
  extraProps: any;
}

type Sections = {
  name: string;
  items: any[];
  icon?: string;
};


const QueryColumnPicker: React.FC<Props> = (
  {
    className,
    query,
    columnGroups,
    checkIsColumnSelected,
    onSelect,
    maxHeight,
    onClose,
    stageIndex,
    extraProps,
  }: Props) => {
  console.log("QueryColumnPicker---> columnGroups", columnGroups);

  const handleSelect = useCallback(
    (column: any) => {
      onSelect(column);
      onClose?.();
    },
    [onSelect, onClose],
  );

  const handleSelectColumn = useCallback(
    (item: any) => {
      const isSameColumn = checkIsColumnSelected(item);

      if (isSameColumn) {
        onClose?.();
        return;
      }

      handleSelect(item);
    },
    [
      query,
      checkIsColumnSelected,
      handleSelect,
      onClose,
    ],
  );

  return (
    <AccordionList
      className={className}
      sections={columnGroups}
      maxHeight={maxHeight || DEFAULT_MAX_HEIGHT}
      alwaysExpanded={false}
      onChange={handleSelectColumn}
      itemIsSelected={checkIsColumnSelected}
      renderItemName={renderItemName}
      renderItemIcon={renderItemIcon}
      extraProps={extraProps}/>
  );
}

function renderItemName(item: any) {
  return item.name;
}

function renderItemIcon(item: any) {
  return getIcon("label", {width: 18, height: 18});
}

export default QueryColumnPicker;
