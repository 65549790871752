//表格配置
import {ColumnsType} from "antd/lib/table";
import moment from "moment";
import {Button, Space} from "antd";
import React from "react";

/**
 * Table配置项
 */
export function tableProps(viewCallback: any, closeIssue: any) {
  const columns: ColumnsType = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => `${index + 1}`,
      width: 70,
      align: "center",
      fixed: 'left',
    },
    {
      title: '标题',
      dataIndex: 'summary',
      key: 'summary',
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '联系电话',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: '处理状态',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: '创建时间',
      dataIndex: 'created',
      key: 'created',
      render: (_, record: any) => (
        moment(record.created).format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle">
          <Button type="link" onClick={() => viewCallback(record)}>
            详情
          </Button>
          <Button disabled={record.state === '已关闭'}
                  type="link" onClick={() => closeIssue(record.id)}>
            关闭工单
          </Button>
        </Space>
      ),
      width: 100
    }
  ]

  const tableProps = {
    columns: columns,
    api: '',
    pagination: {
      currPage: 1,
      pageSize: 10
    },
  }

  return tableProps
}
