import React, {forwardRef, useImperativeHandle, useState} from 'react';

import {Alert, Button, Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import _ from "lodash";
import {ModalWrapper} from '../styled';

interface IProps {
  ref?: any,
  update: (model: any) => void,
}

const PublishPropertyDataChange: React.FC<IProps> = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
      showModal
    }));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [property, setProperty] = useState<any>({});
    const [model, setModel] = useState<any>({});

    const showModal = (model: any, record: any) => {
      setModel(model);
      setProperty(record);
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const deleteData = ({target: {checked}}: CheckboxChangeEvent) => {
      _.forEach(model.properties, item => {
        if (item.id == property.id) {
          item.deleteFlag = checked;
        }
      })

      setProperty({...property, deleteFlag: checked});
      setModel({...model});

      props.update(model);
    }

    return (
      <>
        <ModalWrapper
          className={"createModel"}
          onCancel={handleCancel} open={isModalOpen}
          footer={null}
          maskClosable={false}
          title={'属性(' + property.description + ')变更数据处理'}
          width={'80%'}
          bodyStyle={{
            padding: 5,
            height: 400,
          }}
        >
          <div>变更操作</div>
          <Alert style={{margin: "5px"}}
                 message={'删除数据会先删除字段，然后给表添加表字段'} type="info"/>
          <Checkbox disabled={model.isView} checked={property.deleteFlag}
                    onChange={(evt) => deleteData(evt)}>删除数据</Checkbox>

          <div style={{marginTop: "15px"}}>
            <Button disabled={model.isView} style={{marginRight: "5px"}}> 上传变更前脚本 </Button>
            <Button disabled={model.isView} style={{marginRight: "5px"}}> 上传变更后脚本 </Button>
          </div>
        </ModalWrapper>
      </>
    );
  }
)

export default PublishPropertyDataChange;
