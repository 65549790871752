import _ from "lodash";
export const _width = 375;
export const _grid = 128;
const fn = (child:any, obj:any, parent:any={})=>{
  let columnSpace:number = _width/_grid;
  if(!_.isEmpty(parent)){
    columnSpace = ((parent.rightColumn - parent.leftColumn) * parent.parentColumnSpace)/_grid;
  }
  _.each(child, (i:any)=>{
    /*if(i.widgetName === 'AtText325'||i.widgetName==='XImage1q'){
      console.log('*1**', i.widgetName, columnSpace);
      console.log('*2**', i, parent);
      console.log('*3**', i.parentColumnSpace);
    }*/
    let newProps:any = {
      ...i,
      parentColumnSpace: columnSpace
    };
    obj[i.widgetName] = newProps;
    if(i.children){
      fn(i.children, obj, i.type=='CONTAINER_WIDGET'?newProps:parent);
    }
  })
}
const pageToTree = (pageList:any)=>{
  let cache:any = {};
  _.each(pageList, (i:any)=>{
    if(!['frameDesignPage'].includes(i.slug)){
      cache[i.slug] = {};
      let dsl:any = [i.layouts[0].dsl];
      fn(dsl, cache[i.slug]);
    }
  })
  return cache;
}

export default function Config (dataTree:any, pageList:any):string{
  const {appsmith} = dataTree;
  let appsmithStr = JSON.stringify({
    username: '',
    name: '',
    mobile: '',
    sysRoles: '',
    tenant: '',
    theme: appsmith.theme
  }, null, 2);
  let _dataTree:any = pageToTree(pageList);
  _dataTree.pageList = _.values(pageList).map((i: any) => {
    return {
      ...i,
      layouts: null
    }
  });
  let dataTreeJsonString = JSON.stringify(_dataTree, null, 2);
  return `import _ from 'lodash';
interface XState {
  dataTree: Record<string, any>,
  appsmith: Record<string, any>
}
const initState: XState = {
  dataTree: ${dataTreeJsonString},
  appsmith: ${appsmithStr}
}
export const X_SET_DATATREE = 'X_SET_DATATREE';
export const X_SET_DATATREE_ACTION = 'X_SET_DATATREE_ACTION';

export default function XReducers(state=initState, action: any) {
  const {type, payload} = action;
  switch (type) {
    case X_SET_DATATREE:
      let tree = {...state.dataTree};
      _.set(tree, payload.key, payload.value);
      return { 
        ...state,
        dataTree: tree
      };
    default:
      return state
  }
}


  `;
}
