import styled from "styled-components";

export const NotebookRoot = styled.div`
  position: relative;
  padding: 0 1rem;
  margin-bottom: 2rem;
`;

export const FIELDS_PICKER_STYLES = {
  notebookItemContainer: {
    padding: 0,
  },
  notebookRightItemContainer: {
    width: 37,
    height: 41,
    padding: 0,
  },
  trigger: {
    marginTop: 1,
  },
};
