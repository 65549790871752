import React from 'react';
import MapSelector from './MapSelector'; // 引入MapSelector组件  

function App() {

  // 处理地点被选中的事件  
  const handleLocationSelected = (location) => {
    console.log('地点被选中:', location);
    // 在这里，你可以根据需要将地点信息保存到状态、发送到服务器等  
  };

  return (
    <div className="App">
      <h1>地图选择定位组件</h1>
      <MapSelector
        onLocationSelected={handleLocationSelected} // 传入处理地点被选中的回调函数  
      // 可以根据需要传入其他props，如默认中心点、默认缩放级别、搜索半径等  
      />
    </div>
  );
}

export default App;