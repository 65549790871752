import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout,PageHeader } from 'antd';
import BusinessPage from "@byk/pages/ComponentsManager/main";

const { Content, Sider } = Layout;

export default class Home extends Component {
    render() {
        return (
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head  title="AppServicesManager"  />
                    <Layout>
                        <Sider className="Sider-Navbar"><Navbar title="ComponentsManager"  /></Sider>
                        <Content>
                            <PageHeader className="site-page-header-main" title="自定义组件"/>
                            <Layout>
                                <BusinessPage />
                            </Layout>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

