import React, { useRef, useState, useEffect } from "react";
import Api from "@byk/api/Api";

import localStorage from "utils/localStorage";
import { Button, Form, Input, Card, message, Space, Divider } from "antd";
import logo from "@byk/assets/svg/logo.svg";
import history from "utils/history";
import pattern from "@byk/utils/pattern";
import encryptedData from "@byk/utils/encryptedData";
import { routePath } from "@byk/routes";
let timer:any=null;
const Crud = () => {
  const [time, setTime] = useState(0);
  const onFinish = async (values: any) => {
    console.log("Success:", values);
    let pwd = encryptedData(values.password);
    let params = new FormData();
    params.append("mobile", values.mobile);
    params.append("password", pwd.toString());
    params.append("smsCode", values.smsCode);

    let result: any = await Api.post("/byk/platform/system/register", params);
    if (result.success) {
      message.success("注册成功!");
      window.location.href = `${routePath}/join`;
    }
    else {
      message.error(result.message);
    }
  };
  //const [timer, setTimer] = useState(null);
  const senderCode = async () => {
    let formData = refForm.current.getFieldsValue();
    if (formData.mobile.length != 11) {
      message.warning("请输入正确的手机号!");
      return;
    }
    let params = { mobile: formData.mobile };
    let result: any = await Api.get("/byk/platform/system/sendOneSmsCode", params);
    if (result.success) {
      message.success(`获取验证码成功!【${result.ext.code}】`);
      setTime(60);
    }
    else {
      message.error(result.message);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const toLogin = () => {
    history.push(`${routePath}/login`);
  };
  const refForm = useRef<any>();
  return (
    <>
      <main
        className="x-main-login"
      >


        <Card title={<img src={logo} alt="" />} hoverable className="x-login-card">
          <div className="x-w100 x-tl">
            <span className="x-login-span">注册</span>
          </div>

          <Form
            ref={refForm}
            name="basic"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{
              mobile: "",
              password: "",
              smsCode: ''
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Button disabled={time>0} style={{width:"102px"}} className="reg-send-code" onClick={() => senderCode()}>{time>0?(time+"s"):"获取验证码"}</Button>
            <Form.Item
              label=" "
              colon={false}
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "请输入手机号!"
                },
                {
                  pattern: pattern.mobile,
                  message: "请输入正确的手机号"
                }
              ]}
            >
              <Input placeholder="请输入手机号" className="x-login-text" maxLength={11} />
            </Form.Item>
            <Form.Item
              label=" "
              colon={false}
              name="smsCode"
              rules={[
                {
                  required: true,
                  message: "请输入验证码!"
                },
              ]}
            >
              <Input placeholder="请输入验证码" className="x-login-text" />

              {/* <Input.Group compact>
                <Input placeholder="请输入验证码" className="x-login-text" style={{ width: 'calc(100% - 101px)' }} />
                <Button style={{ height: "56px" }} onClick={() => senderCode()}>获取验证码</Button>
              </Input.Group> */}
              {/* <Input/> */}
            </Form.Item>
            <Form.Item
              label=" "
              colon={false}
              name="password"
              rules={[{ required: true, message: "请输入密码!" }]}
            >
              <Input.Password placeholder="请输入密码" className="x-login-text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button type="primary" htmlType="submit" className="x-login-btn" style={{ width: "100%", height: "50px" }}>
                注册
              </Button>
            </Form.Item>
            {/* <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
          <Button
            type="link"
            className="x-login-btn-text"
          >
            切换账号密码登录
          </Button>
        </Form.Item> */}
          </Form>
          <Divider dashed={true}></Divider>
          <div className="x-tc x-w100">
            <Space align="center" className="x-vcenter" style={{ height: "100%" }}>
              <span className="x-f18">已有账号，去<a className="x-a-color" onClick={() => toLogin()}>登录</a></span>
              {/* <Button type="link" className="x-login-btn-text" style={{ width: "100%" }} onClick={() => toLogin()}>
                登录
              </Button> */}
            </Space>

          </div>

        </Card>
      </main>
    </>
  )
}

export default Crud;
