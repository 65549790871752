import React from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import type {ColumnsType} from "antd/es/table";
import {Button, Popconfirm, Space} from "antd";
import Api from "@byk/api/Api";

import {withRouter} from "react-router-dom";
import moment from 'moment';
import localStorage, {getTenant} from "utils/localStorage";
import _ from "lodash";
import FormModal from "@byk/pages/ComponentsManager/FormModal";

class AppServicesManager extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;

  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
  }

  state = {
    record: null,
    visible: false,
    rows: [],
    editData: null,
    tenant: getTenant(),
    initParams: {
      tenant: getTenant()
    },
  };

  async componentDidMount() {

  }

  render() {
    // api
    const apiGet: string = "/byk/platform/rest/CustomComponent/list";
    const apiPost: string = "/byk/platform/rest/CustomComponent";
    const apiPut: string = "/byk/platform/rest/CustomComponent";
    const apiDel: string = "/byk/platform/rest/CustomComponent";
    const apiCategory: string = "/byk/platform/service/getComponentTree";

    //表格refresh
    const refreshTable = (params?: any) => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    };

    const getOptions = async () => {
      let result: any = await Api.get(apiCategory) || [];
      let cusCmpRes = result['自定义'];
      let cusCmpOptions: any = [];
      _.keys(cusCmpRes).forEach(key => {
        cusCmpOptions.push({
          label: key,
          value: key,
        })
      });
      return cusCmpOptions;
    }

    //改造提交的data
    const reformData = (params?: any, type?: any) => {
      return params;
    };

    //验证提交的data
    const customValidFun = (params?: any) => {
      let result = {success: true, message: ""};
      let _attrs = params.attrs;
      try {
        if (!_.isEmpty(_attrs)) {
          let jsonObject = JSON.parse(_attrs);
        }
      } catch (error) {
        result = {success: false, message: "组件属性必须是json格式"}
        console.error('解析JSON时发生错误:', error);
      }
      return result;
    };

    //编辑按钮
    const EditClick = async (record: any) => {
      let result: any = await Api.get(apiPost, {id: record.id});
      if (result.success) {
        if (result.result.length > 0) {
          this.modalRef.current?.edit(record);
        }
      }
    };

    //删除
    const deleteClick = async (record: any) => {
      let {id} = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        refreshTable();
      }
    };

    const categoryDic = [
      {
        id: 'script',
        name: '脚本',
      },
      {
        id: 'source',
        name: '源代码',
      },
      {
        id: 'clazz',
        name: '.class字节码',
      }

    ];
    //查询项配置
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "code",
        label: "组件编号",
        allowClear: true,
        col: 6,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "组件名称",
        allowClear: true,
        col: 6,
      },
      {
        itemType: "select",
        placeholder: "请输入",
        name: "type",
        label: "组件类型",
        allowClear: true,
        col: 6,
        data: categoryDic,
      },
    ];
    const searchProps = {
      option: searchConfig,
      onSearch: refreshTable,
      btnBoolean: true,
    };

    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 100,
      },

      {
        title: "组件编号",
        dataIndex: "code",
        key: "code",
        width: 100,
      },
      {
        title: "分类",
        dataIndex: "category",
        key: "category",
        width: 100,
      },
      {
        title: "类型",
        dataIndex: "typeName",
        key: "typeName",
        width: 100,
      },
      {
        title: "组件名",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "组件描述",
        dataIndex: "description",
        key: "description",
        render: (text, record) => (
          <div style={{wordWrap: "break-word", wordBreak: "break-word"}}>
            {text}
          </div>
        ),
      },

      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        render: (_, record: any) => (
          moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <Space size="middle">
            <Button type="link" onClick={() => EditClick(record)}>
              编辑
            </Button>
            <Popconfirm
              placement="left"
              title={"确认删除该组件？"}
              onConfirm={() => deleteClick(record)}
              okText="删除"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </Space>
        ),
        width: 100,
      },
    ];

    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
    };

    //modal 表单配置
    const modalProps = {
      apiPost,
      apiPut,
      option: null,
      refreshTable,
      reformData,
      customValidFun,
      categoryDic,
    };
    return (
      <>
        <div className="x-main-box">
          <SearchForm ref={this.searchRef} {...searchProps} />
          <FormModal modalRef={this.modalRef} ref={this.modalRef} {...modalProps} />
          <TableList ref={this.tableRef} {...tableProps} />
        </div>
      </>
    );
  }
}

export default withRouter(AppServicesManager);
