import React, { useRef, useState, useEffect } from 'react';
import localStorage, {getAppId, getTenant} from "utils/localStorage";
import { Space, Button, Descriptions, List, Avatar, Popconfirm, Modal, Row, Col, Form, Input, message, Radio } from 'antd';
import Api from '@byk/api/Api';

import { EditOutlined, FileTextOutlined } from '@ant-design/icons';
import moment from 'moment';
import {appTenantClass, cutString} from '@byk/utils/Utils';
import _ from 'lodash';
import {apiPath} from "@byk/routes";

const { TextArea } = Input;
const App = () => {

    // api
    const apiGetAccount: string = `${apiPath}/platform/git/userGitAccoutInfo`;
    const apiGetbranchCommitLogs: string = `${apiPath}/platform/git/branchCommitLogs`;
    const formRef = useRef<any>();
    const [accountData, setAccountData] = useState({});
    const [appId, setSubAppId] = useState(getAppId());
    const [logdata, setLogData] = useState([]);
    const [isOpenGit, setIsOpenGit] = useState(false);
    const [type, setType] = useState(1);
    const [loading, setLoading] = useState(false);
    const [formCfg, setFormCfg] = useState({
        title: "编辑Git库信息",
        okText: "确定",
        type: "update",
        data: {}
    });

    //获取账号信息
    const getGitAccountInfo = async () => {
        let res: any = await Api.get(apiGetAccount, { appId: appId });
        if (res.success && res.result.length > 0) {
            setAccountData(res.result[0])
        }
    }

    //获取列表信息
    const getbranchCommitLogs = async () => {
        let res: any = await Api.get(apiGetbranchCommitLogs, { appId: appId, branchName: "master" });
        if (res.success) {
            setLogData(res.result)
        }
    }
    //回退版本
    const backVersion = async (data: any) => {
        let res: any = await Api.get("/byk/platform/git/revertAppByCommitid", { appId: appId, commitId: data.id });
        if (res.success) {
            message.success(res.message);
            onload();
        }
        else {
            message.error(res.message);
        }
    }
    //
    const openGitModal = () => {
        setIsOpenGit(true);
        setType(1);
        setFormCfg({
            title: "编辑Git库信息",
            okText: "确定",
            type: "update",
            data: {}
        });
        setTimeout(() => {
            let { gitUrl, gitUsername, gitPassword,loginType} = accountData;
            let data = { gitUrl: gitUrl,loginType:loginType, gitUsername: gitUsername, gitPassword: gitPassword, appId: appId };
            formRef.current?.setFieldsValue(data);
        }, 0);
    }

    //提交代码
    const openSubmitModal = async (data: any) => {
        setIsOpenGit(true);
        setType(2);
        setFormCfg({
            title: "提交应用",
            okText: "提交",
            type: "submit",
            data: {}
        });
        setTimeout(() => {
            let { gitUsername, gitPassword: gitUserPwd, projectId: appId } = accountData;
            let data = { gitUserName: gitUsername, gitUserPwd: gitUserPwd, appId: appId, commitMsg: "" };
            console.log(data);
            formRef.current?.setFieldsValue(data);
        }, 0);
    }
    const updateForm = async (value: any) => {
        let _data: any = {};
        let cfg: any = formCfg;
        let result: any = null;
        debugger;
        setLoading(true);
        //修改配置
        if (type == 1) {
            const formData = new FormData();
            for (let key in value) {
                formData.set(key, value[key]);
            }
            result = await Api.post(`${apiPath}/platform/git/userGitAccoutInfo`, formData);
        }
        else {
            value.appId=getAppId();
            result = await Api.post(`${apiPath}/platform/git/commit`, value);
        }

        if (result.success) {
            setIsOpenGit(false);
            message.success("提交成功!");
            onload();
        }
        else {
            //message.error(result.message);
        }
        setLoading(false);
    }
    const onload = () => {
        getGitAccountInfo();
        getbranchCommitLogs();
    }
    useEffect(() => {
        onload();
    }, []);

    useEffect(() => {
    }, [isOpenGit]);

    return (    //openGitModal
        <>
            <div className='x-main-box'>
                <Descriptions title={<><span>Git库信息</span><Button type="link" icon={<EditOutlined />} onClick={() => openGitModal()} className='x-ml10'>编辑</Button></>}>
                    <Descriptions.Item label="Git库地址">{accountData.gitUrl}</Descriptions.Item>
                    <Descriptions.Item label="用户名">{accountData.gitUsername}</Descriptions.Item>
                    <Descriptions.Item label="密码">
                        {_.isEmpty(accountData.gitPassword)?"":"******"}
                        {/* {cutString(accountData.gitPassword,3) } */}
                        </Descriptions.Item>
                </Descriptions>
                <Descriptions title="Git库历史版本">
                    <Descriptions.Item label="" span={24}>
                        <Button type="primary" onClick={() => openSubmitModal()}>提交应用</Button>
                    </Descriptions.Item>
                </Descriptions>
                <List
                    itemLayout="horizontal"
                    dataSource={logdata}
                    renderItem={(item: any) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<FileTextOutlined className='x-git-ico' />}
                                title={<><a href="#" className='x-bold x-f16'>{item.title}</a> <span className='x-fr x-mr20' ><span >{item.committer_name}</span> <span className='x-ml20'>{moment(item.committed_date).format('YYYY-MM-DD HH:mm:ss')}</span></span></>}
                                description={<><span>{item.message}</span> <Popconfirm
                                    placement="left"
                                    title={"确定回退至该版本？"}
                                    onConfirm={() => backVersion(item)}
                                    okText="提交"
                                    cancelText="取消"
                                >
                                    <Button type="link" className='x-fr'>回退至该版本</Button>
                                </Popconfirm>   </>}
                            />
                        </List.Item>
                    )}
                />

                <Modal title={formCfg.title} open={isOpenGit} onOk={() => { }} onCancel={() => setIsOpenGit(false)} footer={[]}>
                    <Row>
                        <Col className="gutter-row" md={24}>
                            <Form ref={formRef} layout={"vertical"} onFinish={updateForm}>
                                {formCfg.type == "update" && (
                                    <>
                                        <Form.Item label="appId" hidden={true} name="appId">
                                            <Input type="hidden" />
                                        </Form.Item>
                                        <Form.Item label="Git库地址" name="gitUrl"
                                            rules={[{ required: true, message: "请输入!" },
                                            ]}>
                                            <Input placeholder="请输入" />
                                        </Form.Item>
                                        <Form.Item label="登录方式" name="loginType"
                                            rules={[{ required: true, message: "请输入!" },
                                            ]}>
                                        <Radio.Group
                                            options={[{ label: '账号密码登录', value: true },
                                            { label: '个人TOKEN登录', value: false }]}
                                            //onChange={onChange4}
                                            //value={value4}
                                            //optionType="button"
                                            buttonStyle="solid"
                                        />
                                        </Form.Item>
                                        <Form.Item label="用户名" name="gitUsername"
                                            rules={[{ required: true, message: "请输入!" },
                                            ]}>
                                            <Input placeholder="请输入" />
                                        </Form.Item>
                                        <Form.Item label="密码" name="gitPassword"
                                            rules={[{ required: true, message: "请输入!" },
                                            ]}>
                                            <Input.Password placeholder="请输入" />
                                        </Form.Item>
                                    </>
                                )}

                                {formCfg.type == "submit" && (
                                    <>
                                        <Form.Item label="appId" hidden={true} name="appId">
                                            <Input type="hidden" />
                                        </Form.Item>
                                        <Form.Item label="用户名" hidden={true} name="gitUserName">
                                            <Input type="hidden" />
                                        </Form.Item>
                                        <Form.Item label="密码" hidden={true} name="gitUserPwd">
                                            <Input type="hidden" />
                                        </Form.Item>
                                        <Form.Item label="提交说明" name="commitMsg"
                                            rules={[{ required: true, message: "请输入!" }]}>
                                            <TextArea placeholder="请输入" rows={4} />
                                        </Form.Item>
                                    </>
                                )}

                                <div style={{ width: "100%", textAlign: "center" }}>
                                    <Button onClick={() => setIsOpenGit(false)}>取消</Button>
                                    <Button className="x-ml10" loading={loading} type="primary" htmlType="submit">
                                        {formCfg.okText}
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Modal>
            </div>
        </>
    )
}

export default App;
