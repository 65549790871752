import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout } from 'antd';
import BusinessPage from "@byk/pages/MembersManager/main";

const { Content, Sider } = Layout;

export default class Home extends Component {
    render() {
        return (
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head title="MembersManager" />
                    <Layout>
                        {/* <Sider className="Sider-Navbar"><Navbar title="MembersManager" /></Sider> */}
                        <Content><BusinessPage /></Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

