import React, {useEffect, useRef, useState} from 'react';
import {Button, Cascader, CascaderProps, Form, Input, Modal, Radio, Select, Space, Tooltip} from "antd";
import _ from "lodash";
import {MinusCircleOutlined, MoreOutlined, PlusCircleOutlined, SwapOutlined} from "@ant-design/icons/lib";
import XPDict from "./XPropertyPaneDict";
import {AddEventUl} from "./XPropertyPane";
import {useSelector} from "react-redux";
import XCodeMirror from "@byk/pages/MyTask/XCodeEdit";
import {getWidgetOptionsByEventSettings} from "../../../selectors/editorSelectors";

function PanelCondition(props: any) {
  const {uid, idx, addElseIf, delEventData, type, data, updateEventData} = props;
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(data && data.values || {});
  const [changeStatus, setChangeStatus] = useState<boolean>(!!(data && data.changeStatus && data.changeStatus.value));
  const options = useSelector(getWidgetOptionsByEventSettings);

  const displayRender: CascaderProps<any>['displayRender'] = (labels, selectedOptions = []) =>
    labels.map((label, i) => {
      const option = selectedOptions[i];
      if (option) {
        if (i === labels.length - 1) {
          return (
            <span key={option.value}>
          {label}
        </span>
          );
        }
        return <span key={option.value}>{label}.</span>;
      }
    });

  const widgetsOpts = useSelector((state: any) => {
    let canvasWidgets: any = state.entities.canvasWidgets;
    if (canvasWidgets) {
      let filterWidgetOpts: any = [];
      _.each(canvasWidgets, (value: any, key: any) => {
        if (!['CANVAS_WIDGET', 'CONTAINER_WIDGET'].includes(value.type)) {
          filterWidgetOpts.push({
            label: value.widgetName,
            value: `${value.widgetName}.getValue`
          })
        }
      })
      return filterWidgetOpts;
    }
    return [];
  })
  const onFinish = (changeValues: any, allValues: any) => {
    _.each(changeValues, (value: any, key: any) => {
      if (_.isEmpty(value)) {
        delete allValues[key];
      }
    })
    let params: any = {};
    _.each(allValues, (value: any, key: any) => {
      if (!_.isEmpty(value)) {
        _.each(value, (i: any) => {
          if (!i?.a && !i?.b) {
            return true;
          }
          if (!params[key]) {
            params[key] = []
          }
          params[key].push({...i});
        })
      }
    })
    updateEventData && updateEventData(uid, params, 'values');
  }
  const addFormData = () => {
    let cloneFormData: any = form.getFieldsValue();
    let _label = _.uniqueId('form_');
    cloneFormData[_label] = [{a: undefined, b: undefined, c: undefined}];
    setFormData(cloneFormData);
  }
  const radioValue = useRef(1)
  const onRadioChange = (ev: any) => {
    radioValue.current = ev.target.value;
  }

  useEffect(() => {
    form && form.setFieldsValue && form.setFieldsValue(formData);
  }, [formData]);

  useEffect(() => {
    //console.log(`changeStatus`, data)
    if(data.changeStatus&&changeStatus!==data.changeStatus.value){
      setChangeStatus(data.changeStatus.value);
    }
  }, [data.changeStataus]);

  const [expInput, setExpInput] = useState(data.exp?.value);
  const [expInputValid, setExpInputValid] = useState(true);
  const widgetTree = useSelector((state:any)=>{
    return state.evaluations.tree;
  });
  const reValid = (v:any)=>{
    let bol:any = false;
    try {
      let arr1:any = v.split('&&');
      arr1 = arr1.map((i:any)=>{
        return `widgetTree.${i}`;
      });
      arr1 = arr1.join('&&');
      let countV = eval(arr1);
      bol = true;
    } catch (error) {
      console.log('reValid_error');
    }
    setExpInputValid(bol);
  };
  useEffect(() => {
    //console.log('data.exp>>', data);
    if(data.changeStatus&&!data.changeStatus.value&&data.exp&&data.exp!==expInput){
      setExpInput(data.exp.value);
    }
  }, [data.exp]);

  const confirm = () => {
    if (type == 'if') {
      Modal.confirm({
        title: (<>删除if分支将会连同else if及else分支一起删除，以及：</>),
        content: (<Radio.Group onChange={onRadioChange} defaultValue={1}>
          <Space direction="vertical">
            <Radio value={1}>保留if分支后的动作</Radio>
            <Radio value={2}>删除所有分支及动作</Radio>
          </Space>
        </Radio.Group>),
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          delEventData && delEventData(uid, radioValue.current);
        }
      });
    }
    if (type == 'elseIf') {
      Modal.confirm({
        title: (<>删除else if分支将会连同分支后的动作一起删除</>),
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          delEventData && delEventData(uid);
        }
      });
    }

  };

  const addParent = () => {
    addElseIf(uid, idx);
  }


  return (
    <>
      <div className={`card-title`}>
        <div style={{flex: 1}}>
          {
            type == 'if' && <>当满足以下条件时/if</>
          }
          {
            type == 'elseIf' && <>当不满足其他条件但是满足以下条件时/else if</>
          }
        </div>

        <div style={{
          borderRadius: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.16)"
        }}>
          <Button onClick={() => {
            updateEventData && updateEventData(uid, {
              value: !changeStatus
            }, 'changeStatus');
            setChangeStatus(!changeStatus);
          }} icon={<SwapOutlined/>} type="text" style={{color: '#fff'}}>
            {!changeStatus ? '切换表示式' : '切换到条件设置'}
          </Button>
        </div>
        <Tooltip trigger={`click`} title={
          <AddEventUl>
            <li onClick={() => {
              addParent()
            }}>添加条件
            </li>
            <li onClick={confirm}>删除条件</li>
          </AddEventUl>
        }>
          <MoreOutlined/>
        </Tooltip>
      </div>
      {
        changeStatus===true &&
        <div className={`card-box`}>
          <div className={expInputValid?'':'x-event-error'} style={{height: '30px', border: '1px solid #d9d9d9'}}>
            <XCodeMirror value={expInput} onChange={(v:any)=>{
              reValid(v);
              updateEventData && updateEventData(uid, {
                value: v
              }, 'exp');
            }} />
          </div>
        </div>
      }
      <div className={`card-box`}>
        <Form form={form} onValuesChange={onFinish} autoComplete="off" layout="vertical">
          {
            changeStatus===false &&
            Object.keys(formData).map((iKey: any, idx: number) => {
              return (
                <div key={iKey}>
                  <Form.List name={iKey}>
                    {(fields, {add, remove}) => (
                      <>
                        {
                          idx > 0 && !_.isEmpty(fields) && (<div style={{textAlign: 'center'}}>--或--</div>)
                        }
                        {fields.map(field => (
                          <Space key={`${iKey}${field.key}`} align="baseline">
                            <div style={{width: '20px'}}>
                              {
                                field.name != 0 && (<>且</>)
                              }
                            </div>

                            <Form.Item
                              label=""
                              name={[field.name, 'a']}
                              rules={[{required: true, message: 'Missing'}]}
                              style={{width: '223px'}}
                            >
                              <Cascader
                                options={options}
                                displayRender={displayRender}
                              />
                            </Form.Item>
                            <Form.Item
                              label=""
                              name={[field.name, 'b']}
                              rules={[{required: true, message: 'Missing'}]}
                            >
                              <Select placeholder="=请选择=" style={{width: '100px'}} options={XPDict.conditionType}/>
                            </Form.Item>
                            <Form.Item
                              label=""
                              name={[field.name, 'c']}
                              rules={[{required: true, message: 'Missing'}]}
                              style={{marginLeft: '7px'}}
                            >
                              <Input width={149}/>
                            </Form.Item>
                            <PlusCircleOutlined onClick={() => add()}/>
                            <MinusCircleOutlined onClick={() => remove(field.name)}/>
                          </Space>
                        ))}
                      </>
                    )}
                  </Form.List>
                </div>
              )
            })
          }
        </Form>
        {
          !changeStatus && <Button icon={<PlusCircleOutlined/>} onClick={addFormData} type="link">添加条件组</Button>
        }
      </div>
    </>
  )
}

export default PanelCondition;
