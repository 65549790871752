import {Component, createElement, createRef} from "react";
import "./DataSelector.css";
import cx from "classnames";
import PropTypes from "prop-types";
import TablePicker from "./DataSelectorTablePicker";
import PopoverWithTrigger from "@byk/pages/QueryBuilder/components/ui/PopoverWithTrigger";

const MIN_SEARCH_LENGTH = 2;
// chooses a data source bucket (datasets / raw data (tables) / saved questions)
const DATA_BUCKET_STEP = "BUCKET";
// chooses a database
const DATABASE_STEP = "DATABASE";
// chooses a schema (given that a database has already been selected)
const SCHEMA_STEP = "SCHEMA";
// chooses a table (database has already been selected)
const TABLE_STEP = "TABLE";
// chooses a table field (table has already been selected)
const FIELD_STEP = "FIELD";


export const DataSourceSelector = props => {
  return (<DataSelector
    steps={[DATA_BUCKET_STEP, DATABASE_STEP, SCHEMA_STEP, TABLE_STEP]}
    combineDatabaseSchemaSteps
    getTriggerElementContent={TableTriggerContent}
    {...props}
  />)
};

export const DatabaseDataSelector = props => (<DataSelector
  steps={[DATABASE_STEP]}
  getTriggerElementContent={DatabaseTriggerContent}
  {...props}
/>);

export const DatabaseSchemaAndTableDataSelector = props => (<DataSelector
  steps={[DATABASE_STEP, SCHEMA_STEP, TABLE_STEP]}
  combineDatabaseSchemaSteps
  getTriggerElementContent={TableTriggerContent}
  {...props}
/>);

export const SchemaAndTableDataSelector = props => (<DataSelector
  steps={[SCHEMA_STEP, TABLE_STEP]}
  getTriggerElementContent={TableTriggerContent}
  {...props}
/>);

export const SchemaTableAndFieldDataSelector = props => (<DataSelector
  steps={[SCHEMA_STEP, TABLE_STEP, FIELD_STEP]}
  getTriggerElementContent={FieldTriggerContent}
  {...props}
/>);

const DatabaseTriggerContent = ({selectedDatabase}) => {
  return <span className="text-medium no-decoration">Select a database</span>
};

const TableTriggerContent = ({selectedTable}) => {
  return <span className="text-medium no-decoration">Select a table</span>
}

const FieldTriggerContent = ({selectedDatabase, selectedField}) => {
  return <span className="flex-full text-medium no-decoration">Select...</span>
};


class DataSelector extends Component {
  render() {
    return <UnconnectedDataSelector {...this.props} />;
  }
}

export class UnconnectedDataSelector extends Component {
  static propTypes = {
    selectedDataBucketId: PropTypes.string,
    selectedDatabaseId: PropTypes.number,
    selectedSchemaId: PropTypes.string,
    selectedTableId: PropTypes.number,
    selectedFieldId: PropTypes.number,
    databases: PropTypes.array,
    setDatabaseFn: PropTypes.func,
    setFieldFn: PropTypes.func,
    setSourceTableFn: PropTypes.func,
    hideSingleSchema: PropTypes.bool,
    hideSingleDatabase: PropTypes.bool,
    useOnlyAvailableDatabase: PropTypes.bool,
    useOnlyAvailableSchema: PropTypes.bool,
    isInitiallyOpen: PropTypes.bool,
    renderAsSelect: PropTypes.bool,
    tableFilter: PropTypes.func,
    hasTableSearch: PropTypes.bool,
    canChangeDatabase: PropTypes.bool,
    containerClassName: PropTypes.string,

    // from search entity list loader
    allError: PropTypes.bool,
    allFetched: PropTypes.bool,
    allLoaded: PropTypes.bool,
    allLoading: PropTypes.bool,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    fetched: PropTypes.bool,
    fetch: PropTypes.func,
    create: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
    reload: PropTypes.func,
    list: PropTypes.arrayOf(PropTypes.object),
    search: PropTypes.arrayOf(PropTypes.object),
    allDatabases: PropTypes.arrayOf(PropTypes.object),

    isPopover: PropTypes.bool,
  };

  static defaultProps = {
    isInitiallyOpen: false,
    renderAsSelect: false,
    useOnlyAvailableDatabase: true,
    useOnlyAvailableSchema: true,
    hideSingleSchema: true,
    hideSingleDatabase: false,
    hasTableSearch: false,
    canChangeDatabase: true,
    hasTriggerExpandControl: true,
    isPopover: true,
  };

  constructor(props) {
    super(props);

    const state = {
      selectedDataBucketId: props.selectedDataBucketId,
      selectedDatabaseId: props.selectedDatabaseId,
      selectedSchemaId: props.selectedSchemaId,
      selectedTableId: props.selectedTableId,
      selectedFieldId: props.selectedFieldId,
      searchText: "",
      isSavedQuestionPickerShown: false,
      activeStep: TABLE_STEP,
    };

    this.state = {
      isLoading: false, isError: false, tables: props.query?.models(), ...state
    };
    this.popover = createRef();
  }

  switchToStep = async (stepName, stateChange = {}, skipSteps = true) => {
    await this.setStateWithComputedState({
      ...stateChange, activeStep: stepName,
    });
  };

  onChangeTable = async table => {
    console.log('onChangeTable', table);
    if (this.props.setSourceTableFn) {
      this.props.setSourceTableFn(table);
      this.setState({...this.props, ...this.state});
      let stateChange = {
        selectedTableId: table.id, selectedTable: table,
      }
      await this.setStateWithComputedState(stateChange);
      this.popover.current && this.popover.current.toggle();
    }
  };

  setStateWithComputedState(newState, newProps = this.props) {
    return new Promise(resolve => {
      const computedState = {
        ...this.state, ...newState,
      };

      this.setState({...newState, ...computedState}, resolve);
    });
  }

  getTriggerElement = triggerProps => {
    const {
      className, style, triggerElement, getTriggerElementContent
    } = this.props;

    if (triggerElement) {
      return triggerElement;
    }

    const {selectedDatabase, selectedTable, selectedField} = this.state;

    return (<span
      className={className || "px2 py2 text-bold cursor-pointer text-default"}
      style={style}
    >
        {createElement(getTriggerElementContent, {
          selectedDatabase, selectedTable, selectedField, ...triggerProps,
        })}
      </span>);
  };

  getTriggerClasses() {
    const {readOnly, triggerClasses, renderAsSelect} = this.props;
    if (triggerClasses) {
      return cx(triggerClasses, {disabled: readOnly});
    }
    return renderAsSelect ? cx("border-medium bg-white block no-decoration", {disabled: readOnly}) : cx("flex align-center", {disabled: readOnly});
  }

  renderActiveStep() {
    const props = {
      ...this.state, onChangeTable: this.onChangeTable,
    };

    switch (this.state.activeStep) {
      case DATA_BUCKET_STEP:
      case DATABASE_STEP:
      case SCHEMA_STEP:
      case TABLE_STEP:
        return <TablePicker {...props} />;
      case FIELD_STEP:
    }

    return null;
  }

  renderContent = () => {
    const content = (<>
      {this.renderActiveStep()}
    </>);
    return content;
  };

  render() {
    if (this.props.isPopover) {
      return (<PopoverWithTrigger
        id="DataPopover"
        autoWidth
        ref={this.popover}
        isInitiallyOpen={this.props.isInitiallyOpen && !this.props.readOnly}
        containerClassName={this.props.containerClassName}
        triggerElement={this.getTriggerElement}
        triggerClasses={this.getTriggerClasses()}
        hasArrow={this.props.hasArrow}
        tetherOptions={this.props.tetherOptions}
        sizeToFit
        isOpen={this.props.isOpen}
        onClose={this.handleClose}
      >
        {this.renderContent()}
      </PopoverWithTrigger>);
    }

    return this.renderContent();
  }
}
