import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout,PageHeader } from 'antd';
import BusinessPage from "@byk/pages/ApplicationManager/LogManagement/mainLog";
import {uuid2} from "@byk/utils/Utils";

const { Content, Sider } = Layout;

export default class Home extends Component {
    state = {
        key: uuid2(),
      };
    
    changeKey = () => {
        this.setState({key: uuid2()});
    }
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         theme: "dark",
    //         current: "home"
    //     };
    // }

    // state = {
    //     collapsed: false,
    // };

    // toggleCollapsed = () => {
    //     this.setState({
    //         collapsed: !this.state.collapsed,
    //     });
    // };

    render() {
        return (
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head  title="ApplicationConfig"  />
                    <Layout>
                        <Sider className="Sider-Navbar"><Navbar  key={this.state.key} title="SysLog"  /></Sider>
                        <Content>
                            <BusinessPage  callBack={() => {
                                this.changeKey()
                            }}/>
                        </Content>
                        {/* <Content>
                            <PageHeader className="site-page-header-main" title="日志中心"/>
                            <Layout>
                                <BusinessPage />
                            </Layout>
                        </Content> */}
                    </Layout>
                </Layout>
            </div>
        );
    }
}

