import React, {useEffect, useRef} from "react";
import VirtualList from 'rc-virtual-list';
import "@byk/pages/QueryBuilder/lib/css/index.css";

import {DataSelectorTablePickerContainer as Container,} from "./DataSelectorTablePicker.styled";
import {List} from "antd";
import {TableOutlined} from "@ant-design/icons";

type DataSelectorTablePickerProps = {
  hasFiltering?: boolean;
  hasInitialFocus?: boolean;
  hasNextStep?: boolean;
  isLoading?: boolean;
  minTablesToShowSearch?: number;
  selectedTable?: any;
  tables: any[];
  onBack?: () => void;
  onChangeTable: (table: any) => void;
};

const DataSelectorTablePicker: React.FC<DataSelectorTablePickerProps> =
  ({
     tables,
     selectedTable,
     onChangeTable,
     hasNextStep,
     onBack,
     isLoading,
     hasFiltering,
     minTablesToShowSearch = 10,
     hasInitialFocus,
   }: DataSelectorTablePickerProps) => {
    const modelListRef = useRef<any>();

    const checkIfItemIsSelected = (id: any) =>
      selectedTable ? id === selectedTable.id : false;

    useEffect(() => {
      document.addEventListener('click', (ev: any) => {
        const cls = ev?.target?.className;
        if (cls.indexOf && cls.indexOf('x-a') == -1 && modelListRef && modelListRef.current) {
          modelListRef.current.hidden = true;
        }
      })
    }, []);

    const ContainerHeight = 400;
    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
      if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      }
    };

    let selectedItem: HTMLDivElement;
    const listItemSelect = ' listItemSelect';
    const onClickTable = (item: any, evt: React.MouseEvent<HTMLDivElement>) => {
      if (selectedItem) {
        selectedItem.className = selectedItem.className.replace(listItemSelect, '');
      }
      if (evt.currentTarget.className.indexOf(listItemSelect) == -1) {
        evt.currentTarget.className += listItemSelect;
      }
      modelListRef.current.hidden = true;
      selectedItem = evt.currentTarget;
      selectedTable = item;
      onChangeTable(selectedTable)
    }

    const onMouseEvent4ModelItem = (evt: React.MouseEvent<HTMLDivElement>) => {
      if (selectedItem != evt.currentTarget) {
        if ("mouseenter" === evt.type) {
          evt.currentTarget.className += listItemSelect;
        }
        if ("mouseleave" == evt.type) {
          evt.currentTarget.className = evt.currentTarget.className.replace(listItemSelect, '');
        }
      }
    }

    return (
      <Container ref={modelListRef} style={{
        backgroundColor: "white"
      }}>
        <List>
          <VirtualList
            data={tables}
            height={ContainerHeight}
            itemKey="name"
            onScroll={onScroll}
          >
            {(item: any) => (
              <List.Item
                className={checkIfItemIsSelected(item.id) ? 'listItemSelect' : ''}
                key={item.id}
                onClick={(evt) => {
                  onClickTable(item, evt)
                }}
                onMouseEnter={(evt) => {
                  onMouseEvent4ModelItem(evt);
                }}
                onMouseLeave={(evt) => {
                  onMouseEvent4ModelItem(evt);
                }}

                style={{
                  borderRadius: "6px",
                  paddingLeft: "10px",
                }}>
                <TableOutlined/> <span style={{paddingLeft: 5}}>{item.description}</span>
              </List.Item>
            )}
          </VirtualList>
        </List>
      </Container>
    );
  };

export default DataSelectorTablePicker;
