export default function Config ():string{
  return `import {AtRadio} from "taro-ui";
import React, {useState} from "react";
import {ReduxActionTypes} from "../../constants/ReduxActionConstants";
import {connect, useSelector} from "react-redux";
import XBase from "./XBase";

function XRadio(props: any) {
  const {title, propertyPath, onChange, setDataTree, style} = props;
  const [value, setValue] = useState([]);
  const currentData = useSelector((state:any)=>{
    let dataTree = state.dataTreeReducer.dataTree;
    let widget = _.get(dataTree, propertyPath);
    return widget.currentData;
  });
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <AtRadio customStyle={customStyle}
        options={currentData}
        value={value}
        onClick={(v:any)=>{ 
          setValue(v);
          setDataTree(\`\${propertyPath}.value\`, v);
          onChange&&onChange(v); 
        }}
      />
    </XBase>)
}

const dispatchToProps = (dispatch: any) => {
  return {
    setDataTree: (key: string, value: any) => {
      dispatch({
        type: ReduxActionTypes.X_SET_DATATREE_ACTION,
        payload: {
          key,
          value,
        },
      })
    }
  }
}
export default connect(null, dispatchToProps)(XRadio);
  `;
}
