import widgets from './widgetsMap';

function toWidgets(i: any, widgetNames: any, record: any) {
  let defaultReturn = {
    begin: '',
    binds: '',
    handle: '',
    useLoad: '',
    head: ''
  };
  if (widgets[i.type]) {
    return {
      ...defaultReturn,
      ...widgets[i.type](i, widgetNames, record)
    };
  }
  return defaultReturn;
}

export default toWidgets;
