import React from "react";
import type {AppState} from "@appsmith/reducers";
import 'antd/dist/antd.min.css'; //显示样式，不加显示不出来

import localStorage from "utils/localStorage";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Layout,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Tooltip
} from 'antd';
import {Link} from "react-router-dom";
import logo from "../../../../byk/assets/svg/logo.svg";
import logo1 from "@byk/assets/svg/logo1.svg";
import images1 from "@byk/assets/images/avatar-user.png";
import history from "utils/history";
import Api from "@byk/api/Api";
import {X_Edit_Url, X_PROJECT_ID, X_SUBAPPID, X_TENANTID} from '@byk/store/XReducers';
import {connect} from "react-redux";
import {LeftOutlined} from "@ant-design/icons";
import encryptedData from "@byk/utils/encryptedData";
import {setAppMode} from "../../../../actions/pageActions";
import {APP_MODE} from "entities/App";
import byk_log from "@byk/assets/images/byk_logo.png";
import user_drop from "@byk/assets/images/user_drop.png";
import JoinUs from "@byk/pages/JoinUs/JoinUs";
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import styled from "styled-components";
import pattern from "@byk/utils/pattern";
import {cutString, truncateString} from "@byk/utils/Utils";
import {getIsMyTask} from "../../../../sagas/selectors";
import { getAuthority } from "@byk/utils/authority";
import { getCookie, setCookie } from "../../../../utils/CookieUtils";
import _ from "lodash";
import { routePath } from "@byk/routes";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }
`

const HelpContainer = styled.div`
  .header {
    width: 180px;
    height: 48px;
    color: #7D7D7D;
    font-size: 14px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 20px;
  }

  .content {
    padding-left: 8px;

    .item {
      display: flex;
      height: 42;
      color: #101010;
      padding: 11px 0;
      cursor: pointer;

      .icon {
        padding-top: 3px;
      }

      .title {
        margin-left: 13px;
      }
    }
  }
`

function loadOdooChat(userInfo: any = {}) {
  function isScriptLoaded() {
    let scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src')?.startsWith('https://byk-odoo.k8s.billjc.com/im_livechat')) {
        return true;
      }
    }
    return false;
  }

  if (!isScriptLoaded()) {
    let script1 = `https://byk-odoo.k8s.billjc.com/im_livechat/loader/1?username=${userInfo?.name}&mail=${userInfo?.email || ''}&phone=${userInfo?.mobile || ''}`;
    let script2 = 'https://byk-odoo.k8s.billjc.com/im_livechat/assets_embed.js';
    // 创建<script>元素
    let script1Dom = document.createElement('script');
    script1Dom.type = 'text/javascript';
    script1Dom.src = script1;
    document.head.appendChild(script1Dom);
    let script2Dom = document.createElement('script');
    script2Dom.type = 'text/javascript';
    script2Dom.src = script2;
    document.head.appendChild(script2Dom);
  }
}

class Head extends React.Component<any> {
  formRef1: React.RefObject<any>;
  formRef2: React.RefObject<any>;
  formRef3: React.RefObject<any>;
  joinRef: React.RefObject<any>;

  constructor(props: any) {
    super(props);
    this.formRef1 = React.createRef();
    this.formRef2 = React.createRef();
    this.formRef3 = React.createRef();
    this.joinRef = React.createRef();
  }

  state = {
    isModalOpen: false,
    tenantList: [],
    defalutTenant: null,
    isModalOpenUserName: false,
    subAppList: [],
    appId: null,
    projectName: localStorage.getItem(this.props.userId+'_projectName'),
    cfg: {
      title: "修改姓名",
      mode: 1,
      visable: false
    },
    logoSrc: this.props.logoSrc || logo,
    isAppAdmin:getAuthority().project==1,
  };
  logOut = () => {
    Api.get("/byk/platform/system/exit");
    localStorage.clear();
    this.props.setProjectId('');
    history.push(`${routePath}/login`);
    //window.location.href = `${routePath}/login`;
  };

  showModal() {
    this.setState({isModalOpen: true});
  };

  infoSet() {
    this.showModal();
  };

  handleOk = () => {
    this.setState({isModalOpen: false});
  };

  handleCancel = () => {
    this.setState({isModalOpen: false});
  };

  showModal1(obj?: any, mode?: any) {
    const _cfg = {
      ...this.state.cfg,
      title: (mode == 1 ? "修改姓名" : (mode == 2 ? "修改邮箱" : "修改密码")),
      mode: mode,
      visable: true
    }
    this.setState({cfg: _cfg}, () => {
      const defalutValues: any = {};
      if (mode == 1) {
        defalutValues["name"] = obj;
      } else if (mode == 2) {
        defalutValues["email"] = obj;
      }
      this.formRef1.current?.setFieldsValue(defalutValues);
    });
  };

  infoSet1() {
    this.showModal();
  };

  handleOk1 = () => {
    this.setState({isModalOpenUserName: false});
  };

  handleCancel1 = () => {
    let cfg = {...this.state.cfg, visable: false};
    this.setState({cfg: cfg});
  };

  updateData = async (value: any) => {
    if (this.state.cfg.mode == 3) {
      await this.changePassword(value);
    } else {
      let result: any = await Api.put("/byk/platform/rest/SysUser", {
        ...value,
        id: this.props.userId
      });
      if (result.success) {
        message.success("修改成功!");
        this.handleCancel1();
      } else {
        message.error(result.message);
      }
    }
  }

  changePassword = async (value: any) => {
    const formData = new FormData();
    formData.append("newPassword", encryptedData(value.newPassword) || '');
    formData.append("srcPassord", encryptedData(value.srcPassord) || '');
    let res: any = await Api.postForm(`/byk/platform/change/password`, formData);
    if (res.success && res.message) {
      if (res.message == '处理成功') {
        message.success('重置密码成功！');
        this.logOut();
      } else {
        message.error(res.message);
      }
    }
  }


  updateValue = () => {
    window.location.reload()
  }

  componentDidMount() {
    this.bind();
    this.bindSubAppList();
  }
  componentDidUpdate(prevProps:any){
    let prevUser = prevProps.users;
    let propUser = this.props.users;
    if(!_.isEqual(prevUser, propUser)||
      !_.isEqual(prevProps.projectId, this.props.projectId)){
      this.bind();
    }
  }

  bind() {
    if (this.props.users != undefined) {
      this.bindTenantList();
    }
  }

  setTenantLocalStorage = (obj: any) => {
    let tenantId = obj.tenantId;
    let tenantName = obj.tenantName;
    let adminAble = obj.adminAble;
    let userId = this.props.userId;
    localStorage.setItem(userId+'_tenantId', tenantId);
    localStorage.setItem(userId+'_T_adminAble', adminAble);
    localStorage.setItem(userId+'_tenantName', tenantName);
    this.props.setProjectId(tenantId);
  }

  bindTenantList = async () => {
    let userId = this.props.userId;
    let all = this.props.profileObj;
    if(userId&&all){
      let list: any = all.userTenant;
      this.setState({tenantList: list});
      if (list && list.length > 0) {
        let tenantId = list[0].tenantId;
        let storageTenantId: any = localStorage.getItem(userId + '_tenantId');
        let storageUserId: any = userId;
        let cookieTenantId: any = getCookie("tenantId_" + storageUserId);

        if (storageTenantId == null) {
          if (cookieTenantId != null) {
            let r: any = list.filter((item: any) => {
              return item.tenantId == cookieTenantId
            });
            if (r.length > 0) {
              this.setTenantLocalStorage(r[0]);
              this.setState({ defalutTenant: parseInt(cookieTenantId) });
            } else {
              this.setTenantLocalStorage(list[0]);
              this.setState({ defalutTenant: parseInt(tenantId) });
              setCookie("tenantId_" + storageUserId, tenantId);
            }
          } else {
            this.setTenantLocalStorage(list[0]);
            this.setState({ defalutTenant: parseInt(tenantId) });
            setCookie("tenantId_" + storageUserId, tenantId);
          }
        } else {
          let r: any = list.filter((item: any) => {
            return item.tenantId == storageTenantId
          });
          if (r.length > 0) {
            this.setTenantLocalStorage(r[0]);
            setCookie("tenantId_" + storageUserId, storageTenantId);
          }
          let _r = list.filter((item: any) => {
            return item.id == storageTenantId
          })[0]
          this.setState({defalutTenant: parseInt(storageTenantId)});
        }
        //loadOdooChat(result);
      } else if (list.length == 0) {
        history.push(`${routePath}/join`);
      }
    }

  }

  bindSubAppList = async () => {
    let userId = this.props.userId;
    let projectId = localStorage.getItem(userId +'_projectId');


    let title = this.props.title;
    let isGetInfo = ["TenantInfo"].indexOf(title) < 0
    if (projectId != null && isGetInfo) {
      let res: any = await Api.get("/byk/platform/rest/SysProject", {id: projectId});
      let list = res.result[0]['sysApps'];
      this.setState({subAppList: list});
      let storageSubApp: any = localStorage.getItem(userId + '_appId');
      if (storageSubApp == null) {
        localStorage.setItem(userId+'_appId', list[0].projectId);
      }
      this.setState({appId: parseInt(storageSubApp)});
    }
  }

  handleChange = (val: any, record: any) => {
    this.setTenantLocalStorage(record);
    // 设置cookie
    let userId = this.props.userId;
    let tenantId_cookie = localStorage.getItem(userId+'_tenantId');
    let userId_cookie = userId;
    if (_.isEmpty(userId_cookie) || _.isEmpty(tenantId_cookie)) {
    } else {
      setCookie("tenantId_" + userId_cookie, tenantId_cookie);
    }

    //window.location.reload();
  };

  editApp = (record: any) => {
    this.setEditUrl(record, (url?: string) => {
      if (this.props.title == "PageDesign") {
        url && history.push(url);
        window.location.reload();
      } else {
        window.location.reload();
      }
    })
  }

  setEditUrl = async (record: any, cb?: (url?: string) => void) => {
    let defaultSubAppId = record.id;
    let tenant = record.tenant;
    let resultPage: any = await Api.get("/byk/platform/rest/Page/list", {
      appId: defaultSubAppId
    });

    let userId = this.props.userId;
    let cookiePageId = getCookie("pageId_" + defaultSubAppId + "_" + userId);

    let setPageId = resultPage.result[0]['id'];
    let setPageName = resultPage.result[0]['slug'];

    if (cookiePageId != null) {
      let pageList = resultPage.result.filter((item: any) => {
        return item.id == cookiePageId
      });
      if (pageList.length > 0) {
        setPageId = pageList[0].id;
        setPageName = pageList[0].slug;
      }
    }

    let defaultPage = {
      pageId: setPageId,
      pageName: setPageName//['name']
    }
    if (resultPage.success) {
      setCookie("appId_" + record.projectId + "_" + userId, defaultSubAppId);
      setCookie("pageId_" + defaultSubAppId + "_" + userId, setPageId);
      let edit_url = `${routePath}/app/${defaultSubAppId}/${defaultPage.pageName}-${defaultPage.pageId}/edit`;
      this.props.setEditUrl(edit_url, defaultSubAppId);
      localStorage.setItem(userId+'_projectId', record.projectId);
      this.setState({appId: parseInt(defaultSubAppId)});
      localStorage.setItem(userId+'_appId', defaultSubAppId);
      localStorage.setItem(userId+'_editUrl', edit_url);
      localStorage.setItem(userId+'_tenant', tenant);
      cb && cb(edit_url);
    }
  }
  redirectUrl = (url: any, action?: any) => {
    if (action == "create") {
      this.joinRef.current?.showModal2();
    } else {
      this.joinRef.current?.showModal1();
    }
  };

  render() {
    let title = this.props.title;
    let CoustomComponents = null;
    let logoComponents = (
      <Space align="center" style={{height: "100%", gap: "12px,"}}>
        <Space align="center" style={{height: "100%"}}>
          <Link to={routePath||'/'}>
            <div className="x-header-logo">
              <Space align="center" style={{height: "100%"}}>
                <img src={byk_log} style={{width: "88px", height: "23px"}} alt=""/>
              </Space>
            </div>
          </Link>
        </Space>
      </Space>
    );
    const joinComponents = () => {
      return (
        <>
          <div style={{width: "100px", height: "65px"}}>
            <Button type="link" onClick={() => {
              this.redirectUrl(`${routePath}/Join`, "create")
            }} className="x-fl"><span style={{color: "black"}}>创建组织</span></Button>
            <Button type="link" onClick={() => {
              this.redirectUrl(`${routePath}/Join`, "join")
            }} className="x-fl"><span style={{color: "black"}}>加入组织</span></Button>
          </div>

        </>
      )
    }
    const customDropdown: any = (menus: any, props: any) => {
      //debugger;
      return (
        <>
          <div style={{ maxHeight: 300, overflowY: 'auto' }}>
            {menus}
            <Tooltip color={"white"} placement="right" title={joinComponents}>
              <div className="x-head-join-span">
                创建组织/加入组织
              </div>
            </Tooltip>
          </div>
        </>

      );
    };
    const titleCompont = () => {
      const list = [{key: "TenantInfo", name: "组织管理"}, {key: "TenantRoleManager", name: "组织管理"}, {
        key: "TenantMembers",
        name: "成员管理"
      }];
      let obj: any = list.filter((item: any) => {
        return item.key == title;
      })
      return (
        obj.length > 0 ?
          <>
            <span className="x-bold x-f24"
                  style={{
                    fontSize: '16px',
                    lineHeight: "23px",
                    marginTop: '18px',
                    marginLeft: '38px',
                    paddingLeft: '19px',
                    borderLeft: 'solid 1px #a19292ab',
                    height: '23px',
                  }}>{obj?.[0]?.name}</span>
          </>

          : null
      )
    }
    //git库历史版本  显示缩小LOGO
    if (["GitHistory", "ApplicationConfig", "PageDesign", "Modeling", "AppServicesManager", "QueryBuilder", "DevConfig","DevTask"].indexOf(title) > -1) {
      logoComponents = (
        <>
          <Space align="center" style={{height: "100%", gap: "12px,"}}>
            <Space align="center" style={{height: "100%"}}>
              <Link onClick={() => this.props.setModeNone()} to={routePath||'/'}>
                <div className="x-header-logo1">
                  <Space align="center" style={{height: "100%"}}>
                    <LeftOutlined style={{marginLeft: "9px", marginTop: "-5px"}}/>
                    <img src={logo1} style={{width: "22px", height: "23px", marginLeft: "5px"}} alt=""/>
                  </Space>
                </div>
              </Link>
            </Space>
            <Space align="center" style={{height: "100%"}}>
              <div className="x-header-mainProjectName" title={this.state.projectName}>
                {truncateString(this.state.projectName,8)}
                {/* {this.state.projectName?.length > 10 ? this.state.projectName?.substring(0, 10) + "..." : this.state.projectName} */}
              </div>
            </Space>
          </Space>
        </>
      );
    }

    if (["byk", 'MyTask'].indexOf(title) > -1) {
      logoComponents = (
        <>
          <Space align="center" style={{height: "100%", gap: "12px,"}}>
            <Space align="center" style={{height: "100%"}}>
              <Link to={routePath||'/'}>
                <div className="x-header-logo">
                  <Space align="center" style={{height: "100%"}}>
                    <img src={byk_log} style={{width: "88px", height: "23px"}} alt=""/>
                  </Space>
                </div>
              </Link>
            </Space>
            <Space align="center" style={{height: "100%"}}>
              <Select
                size="large"
                listHeight={256}
                key={this.state.defalutTenant ? '1' : '2'}
                defaultActiveFirstOption
                onChange={(val, record) => this.handleChange(val, record)}
                value={this.state.defalutTenant}
                style={{height: '36px', width: '302px', marginTop: "12px", marginBottom: '16px'}}
                fieldNames={{label: 'tenantName', value: 'tenantId'}}
                options={this.state.tenantList}
                dropdownRender={customDropdown}
              />
            </Space>
          </Space>
        </>
      )
    }

    const CustomCmpData = [
      {key: 'PageDesign', to: this.props.editUrl, text: '页面设计'},
      {key: 'DevTask', to: `${routePath}/DevTask`, text: '开发任务'},
      {key: 'Modeling', to: `${routePath}/Modeling`, text: '业务建模'},
      {key: 'AppServicesManager', to: `${routePath}/AppServicesManager`, text: '服务编排'},
      {key: 'ApplicationConfig', to:`${routePath}/SysAppUser`, text: '应用设置'},
      //{key: 'ApplicationConfig', to:this.state.isAppAdmin? `${routePath}/SysAppUser`:`${routePath}/SysConfig`, text: '应用设置'},
      {key: 'DevConfig', to: `${routePath}/ApplicationConfig`, text: '开发配置'},
      // {key: 'QueryBuilder', to: `${routePath}/QueryBuilder`, text: '查询设计器'},
    ]
    const customCmpDataItemWidth = 110;
    const customCmpMarginMarginLeft = (window.innerWidth / 2 - CustomCmpData.length * customCmpDataItemWidth / 2) + 'px';

    if (["ApplicationConfig", 'AppServicesManager', 'Modeling', "PageDesign","DevTask", "MembersManager", "GitHistory", "QueryBuilder", "DictManager", "DevConfig"].indexOf(title) > -1) {
      CoustomComponents =
        <Menu
          mode="horizontal" //导航栏列出来显示
          selectedKeys={[title]}
          theme="light"
        >
          {CustomCmpData.map(item => {
            return <Menu.Item key={item.key} style={{width: `${customCmpDataItemWidth}px`}}>
              <Link to={item.to}>{item.text}</Link>
            </Menu.Item>
          })}
        </Menu>
    }

    let helpData = [
      {
        title: '提交工单',
        icon: 'Bill',
        onClick: () => {
          open(`${routePath}/track`)
        },
      },
      {
        title: '产品文档',
        icon: 'Log',
        onClick: () => {
          window.open('https://byk-doc.k8s.billjc.com/');
        },
      }
    ]
    let HelpDropDown = () => (
      <HelpContainer>
        <div className='header'>
          帮助指引
        </div>
        <div className='content'>
          {helpData.map((item, index) => {
            return <div className='item' onClick={item.onClick}>
              <div className='icon'>{getIconPark(item.icon)}</div>
              <div className='title'>{item.title}</div>
            </div>
          })}
        </div>
      </HelpContainer>
    );

    let UserDropDown1 = (props: any) => (
      <ul className="dropdown-menu list-group dropdown-persist">
        <li className="list-group-item dropdown-footer">
          <Button type="link" onClick={() => this.infoSet()}>个人信息</Button>
        </li>
        <li className="list-group-item dropdown-footer">
          <Button type="link" onClick={() => this.logOut()}>退出登录</Button>
        </li>
      </ul>
    );

    // 显示登录
    let showLogin = () => {
      let pathname:any=window.location.pathname;
      if(pathname=="/DevTask"){
        return false;
      }
      return this.props.mode != 'EDIT' || this.props.isMyTask
    }

    return (
      <Layout
        className='x-header-layout'
        style={{
          // display: 'flex',
          borderBottom: "1px solid #E4E8EB",
          position: "relative",
          zIndex: 1,
          height: "65px",
          flexDirection: 'row',
        }}
      >
        <div className="x-header-logo-space c-edit4">
          {logoComponents}
        </div>

        <div className='editMenu'
             style={{position: 'absolute', marginLeft: `${customCmpMarginMarginLeft}`, width: '100%',}}>
          {CoustomComponents}
        </div>
        {titleCompont()}

        {!showLogin() && (
          <Popover
            className='helpPopover'
            placement="bottomRight"
            content={<HelpDropDown/>}
            trigger="click"
          >
            <a
              style={{
                position: 'absolute',
                right: 0,
                marginRight: '12px',
                marginTop: '24px',
              }}
              className="dropdown-toggle"
            >
              {getIconPark("Help")}
            </a>
          </Popover>
        )
        }

        <div
          style={{
            position: 'absolute',
            right: 0,
            marginRight: '12px',
            marginTop: '8px',
          }}
        >
          {showLogin() && (
            <div>
              <Badge style={{position: "relative"}}>
                <Avatar src={images1} size={48}>
                  {this.props.username}
                </Avatar>
                <span style={{
                  position: "absolute",
                  top: "18px",
                  left: "0px",
                  width: "48px",
                  color: "white",
                  textAlign: "center",
                  wordBreak: "keep-all"
                }}>
                  {cutString(this.props.username, 3)}
                  </span>
              </Badge>

              <Popover
                className="x-fr x-mr20"
                placement="bottomRight"
                content={<UserDropDown1/>}
                trigger="click"
              >
                <a className="dropdown-toggle">
                  <img style={{
                    marginTop: '12px',
                    marginLeft: '6px',
                    width: '18px',
                    height: '18px',
                  }} src={user_drop}/>
                </a>
              </Popover>

            </div>
          )}
        </div>

        <ModalWrapper title="个人信息" open={this.state.isModalOpen} onOk={this.showModal} onCancel={this.handleCancel}
                      footer={[]}>
          <Row>
            <Col className="gutter-row" md={12}>
              <Descriptions layout="vertical">
                <Descriptions.Item label="姓名">{this.props.username}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col className="gutter-row" md={12}>
              <Button type="link" className="x-fr"
                      onClick={() => this.showModal1(this.props.username, 1)}>编辑</Button>
            </Col>
          </Row>
          <Divider/>
          <Descriptions layout="vertical">
            <Descriptions.Item label="手机号">{this.props.userMobile}</Descriptions.Item>
          </Descriptions>
          <Divider/>
          <Row>
            <Col className="gutter-row" md={12}>
              <Descriptions layout="vertical">
                <Descriptions.Item label="账号密码">已设置</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col className="gutter-row" md={12}>
              <Button type="link" className="x-fr" onClick={() => this.showModal1("", 3)}>修改密码</Button>
            </Col>
          </Row>

          <Divider/>
          <Row>
            <Col className="gutter-row" md={12}>
              <Descriptions layout="vertical">
                <Descriptions.Item label="邮箱">{this.props.userEmail}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col className="gutter-row" md={12}>
              <Button type="link" className="x-fr"
                      onClick={() => this.showModal1(this.props.userEmail, 2)}>编辑</Button>
            </Col>
          </Row>
        </ModalWrapper>

        <ModalWrapper title={this.state.cfg.title} open={this.state.cfg.visable} onOk={this.showModal1}
                      onCancel={this.handleCancel1} footer={[]}>
          <Row>
            <Col className="gutter-row" md={24}>
              <Form ref={this.formRef1} layout={"vertical"} onFinish={this.updateData}>
                {this.state.cfg.mode == 1 && (
                  <>
                    <Form.Item label="姓名" name="name"
                               rules={[{required: true, message: "请输入!"}]}>
                      <Input placeholder="请输入姓名" maxLength={100}/>
                    </Form.Item>
                  </>
                )}
                {this.state.cfg.mode == 2 && (
                  <>
                    <Form.Item label="邮箱" name="email"
                               rules={[{required: true, message: "请输入!"},
                                 {
                                   pattern: pattern.email,
                                   message: "请输入正确的邮箱"
                                 }]}>
                      <Input placeholder="请输入邮箱"/>
                    </Form.Item>
                  </>
                )}
                {this.state.cfg.mode == 3 && (
                  <>
                    <Form.Item label="旧密码" name="srcPassord"
                               rules={[{required: true, message: "请输入!"}]}>
                      <Input.Password placeholder="请输入"/>
                    </Form.Item>
                    <Form.Item label="新密码" name="newPassword"
                               rules={[{required: true, message: "请输入!"}]}>
                      <Input.Password placeholder="请输入" minLength={8}/>
                    </Form.Item>
                    <Form.Item label="确认新密码" name="rePassword"
                               rules={[
                                 {required: true, message: "请输入!"},
                                 {
                                   validator: (rule, value, callback) => {
                                     if (value && value !== this.formRef1.current.getFieldValue('newPassword')) {
                                       callback('两次输入的密码不一致！');
                                     } else {
                                       callback();
                                     }
                                   }
                                 },
                               ]}>
                      <Input.Password placeholder="请输入" minLength={8}/>
                    </Form.Item>
                  </>
                )}

                <div style={{width: "100%", textAlign: "center"}}>
                  <Button onClick={this.handleCancel1}>取消</Button>
                  <Button className="x-ml10" type="primary" htmlType="submit">
                    确定
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalWrapper>
        <JoinUs ref={this.joinRef} source="header"/>
      </Layout>

    )
  }
}

const mapStateToProps = (state: AppState) => ({
  editUrl: state.XReducers.editUrl == '/'&&state.ui.users.currentUser ? localStorage.getItem(state.ui.users.currentUser?.id+'_editUrl') : state.XReducers.editUrl,
  users: state.ui.users.currentUser,
  userId: state.ui.users.currentUser?.id,
  username: state.ui.users.currentUser?.username,
  userEmail: state.ui.users.currentUser?.email,
  userMobile: state.ui.users.currentUser?.mobile,
  profileObj: state.ui.users.currentUser?.profileObj,
  mode: state.entities.app.mode,
  isMyTask: getIsMyTask(state),
  previewMenuNum: state.XReducers.previewMenuNum,
  projectId: state.XReducers.projectId
});

const mapDispatchToProps = (dispatch: any) => ({
  setProjectId: (data: string) => {
    dispatch({
      type: X_PROJECT_ID,
      data
    })
  },
  setTenantId: (data: string) => {
    dispatch({
      type: X_TENANTID,
      data
    })
  },
  setEditUrl: (data: string, appId: string) => {
    dispatch({
      type: X_Edit_Url,
      data
    })
    dispatch({
      type: X_SUBAPPID,
      data: appId
    })
  },
  setModeNone: () => {
    dispatch(setAppMode(APP_MODE.NONE))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Head);
