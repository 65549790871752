import _ from "lodash";

export default function Config(dataTree:any, appId:any, tenant:any): string {
  let defaultPage = _.find(dataTree.pageList, { isDefault: true });
  return `import Taro from '@tarojs/taro';
import { AtForm, AtInput, AtButton } from 'taro-ui';
import './index.scss';
import React, {useState} from "react";
import Api from "../../api";
export default function Index() {
  const [uname, setUname] = useState<string|number>('13397179230');
  const [pwd, setPwd] = useState<string|number>('123456');
  const onSubmit = async ()=> {
    console.log('onSubmit', uname, pwd);
    let params: any = {};
    params.username = uname;
    params.password = pwd;
    params.appId = "${appId}";
    params.tenant = "${tenant}";
    let result: any = await Api.post("/byk/${tenant}/subapp/admin/enter", params);
    if(result.success){
      
      Taro.navigateTo({
        url: '/pages/${defaultPage.slug}/index',
      })
    }
  }
  return (
    <AtForm onSubmit={onSubmit}>
      <AtInput name='uname' title='账号' type='text' placeholder='单行文本' value={uname}
               onChange={(v)=>{
                 setUname(v);
               }}
      />
      <AtInput name='pwd' title='密码' type='password' placeholder='单行文本' value={pwd}
               onChange={(v)=>{
                 setPwd(v);
               }}
      />
      <AtButton type="primary" formType='submit'>登录</AtButton>
    </AtForm>
  )
}
  `;
}
