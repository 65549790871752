import {Button, Col, Form, Input, Layout, Row, Select, Space, Steps} from "antd";
import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {getModelMetaSelectOptions} from "@byk/pages/modeling/ModelData/api";
import _ from "lodash";
import DmTable from "@byk/pages/modeling/ModelData/DmTable";
import {buildSearchOptions, buildTableProps} from "./config";
import {publishModelObj} from "@byk/pages/modeling/Publish/PublishModel";
import DmdModal from "@byk/pages/modeling/ModelData/DmdModal";
import styled from "styled-components";

const {Content} = Layout;

const formItemLayout = {
  labelCol: {span: 10},
  wrapperCol: {span: 14},
}

const Dmd: React.FC<any> = forwardRef((props, ref) => {
  const dmdModelRef = useRef<any>();

  const tableRef = useRef<any>();
  const [form] = Form.useForm();
  const [modelOptions, setModelOptions] = useState<any[]>([])
  const [inScriptData, setInScriptData] = useState<any>({})           // 要加入脚本的数据
  const [modelMap, setModelMap] = useState<any>();                              // 缓存动态模型数据，key：id

  // 模型变化，还原状态
  const [model, setModel] = useState<any>()                                       // 当前模型
  const [tableProps, setTableProps] = useState<any>({})                 // 当前模型的Table选项
  const [searchOptions, setSearchOptions] = useState<any[]>([])         // 当前模型的查询条件选项

  const [searchOptionVal, setSearchOptionVal] = useState<any[]>([])   // 当前模型的选择的查询条件
  const selectedIdsRef = useRef<any>([]);                             // 当前选择的数据

  // 组件间共享， Dmd组件与useCallback创建的Crud组件
  const searchDataRef = useRef<any>({});
  const idsRef = useRef<any>([]);

  useEffect(() => {
    let mp: any = getModelMetaSelectOptions(props.models)
    setModelMap(_.mapKeys(props.models, 'description') || {})
    setModelOptions(mp)
  }, [props.models])

  useEffect(() => {
    publishModelObj.setScriptData({...inScriptData})
  }, [inScriptData])

  const openDmdModal = () => {
    dmdModelRef.current?.showModal(model, inScriptData[model.id])
  }

  const onModelChange = async (value: any) => {
    let model = modelMap[value]
    setModel(model)
    setTableProps(buildTableProps(model))
    setSearchOptions(buildSearchOptions(model))
    selectedIdsRef.current = []
    setSearchOptionVal([])

    setTimeout(() => {
      tableRef.current?.setSearchInIds(inScriptData[model.id])
      tableRef.current?.getTableList({...searchDataRef.current});
    }, 300)
  }

  const onFinish = (v: any) => {
    for (let val of Object.keys(v)) {
      if (v[val] == undefined) {
        v[val] = '';
      }
    }
    doRefreshTable(JSON.parse(JSON.stringify(v)));
  }

  const doRefreshTable = (searchData: any = {}) => {
    searchDataRef.current = searchData
    if (model?.id && idsRef.current.length > 0) {
      tableRef.current?.getTableList({...searchData});
    }
  }

  /**
   * 加入脚本
   * @param data
   */
  const addToScript = (ids: any) => {
    idsRef.current = ids
    inScriptData[model.id] = ids
    setInScriptData({...inScriptData})
    tableRef.current?.setSearchInIds(ids)
    tableRef.current?.getTableList({...searchDataRef.current});
  }

  /**
   * 从脚本删除
   * @param data
   */
  const removeFromScript = () => {
    let key = model.id + ''
    let modelDataIds = inScriptData[key] || []
    let newIds: any = []
    _.forEach(modelDataIds, id => {
      if (!selectedIdsRef.current.includes(id)) {
        newIds.push(id)
      }
    })

    inScriptData[key] = newIds
    setInScriptData({...inScriptData})

    tableRef.current?.setSearchInIds(newIds)
    tableRef.current?.getTableList({...searchDataRef.current});
  }

  const removeAllFromScript = () => {
    let key = model.id + ''
    inScriptData[key] = []
    setInScriptData({...inScriptData})
    tableRef.current?.setSearchInIds([])
    tableRef.current?.getTableList({...searchDataRef.current});
  }

  const Crud = useCallback(() => {
    const selectedDataCallback = async (data: any, callback: any) => {
      let dataIds: any = []
      _.forEach(data || [], item => dataIds.push(item.id))
      selectedIdsRef.current = dataIds
      callback(dataIds)
    }

    return (
      <DmTable
        byIdsFlag={true}
        ref={tableRef}
        {...tableProps}
        scroll={{y: props.scrollY - 103}}
        selectedDataCallback={selectedDataCallback}
      />
    )
  }, [tableProps])


  return (
    <>
      <Layout>
        <Content style={{margin: 0, height: "100%",}}>
          <StepDiv className={'x-mb10'} style={{borderBottom: '1px solid gray', paddingBottom: '5px', marginTop: '-10px',}}>
            <span className='step'><span className='dot'></span> Step1 </span>
            <span>选择模型：
            <Select
              allowClear
              style={{width: '160px'}}
              showSearch
              onChange={onModelChange}
              placeholder="选择模型"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={modelOptions}
            />
            </span>

            <span className='step'><span className='line'></span><span className='dot'></span> Step2 </span>
            <Button className='selectDataBtn' type={"primary"} disabled={!model?.id} onClick={openDmdModal}>选择模型数据</Button>
          </StepDiv>

          {model?.id &&
              <div>
                  <Form
                      style={{paddingRight: '100px'}}
                      layout={"inline"}
                      {...formItemLayout}
                      ref={props.formRef}
                      form={form}
                      autoComplete="off"
                      onFinish={onFinish}
                  >
                      <Row style={{width: '100%'}}>
                        {searchOptionVal.map((sv: any) => {
                            let item: any = _.head(_.filter(searchOptions, item => item.value == sv))
                            return (
                              <Col span={8}>
                                <Form.Item label={item.label} name={item.value}>
                                  <Input />
                                </Form.Item>
                              </Col>
                            )
                          }
                        )}

                          <Col span={24} className={'x-mb10'}>
                              <Button className='x-ml10' onClick={removeFromScript}>
                                  从变更脚本移除
                              </Button>
                              <Button className='x-ml10' onClick={removeAllFromScript}>
                                  全部移除
                              </Button>
                          </Col>
                      </Row>
                  </Form>

                  <Crud />

              </div>
          }
        </Content>
      </Layout>

      <DmdModal ref={dmdModelRef} addToScript={addToScript} scrollY = {props.scrollY}></DmdModal>
    </>
  )
})

export default Dmd;

const StepDiv = styled.div`
  .step {
    color: rgba(23, 119, 255, 1);
    margin-right: 20px;
  }

  .dot {
    background: rgba(23, 119, 255, 1);
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .ant-select-selector {
    border-radius: 5px !important;
  }

  .line {
    display: inline-block;
    width: 137px;
    border-bottom: 2px solid #D4DFEE;
    margin-left: 23px;
    margin-right: 10px;
    margin-bottom: 2px;
  }

  .selectDataBtn[disabled] {
    background: #f5f5f5 !important;
  }
`
