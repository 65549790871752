//width = (rightColumn - leftColumn) * parentColumnSpace;
//height = (bottomRow - topRow) * parentRowSpace
//top = topRow * parentRowSpace
//left = leftColumn * parentColumnSpace
export default function Config ():string{

  return `import styled from "styled-components";
  import { View } from '@tarojs/components'
const WidgetWrapper = styled(View).withConfig({
  shouldForwardProp: (prop) => !['rightColumn', 'leftColumn', 'topRow', 'bottomRow', 'parentRowSpace', 'parentColumnSpace', 'bodyWidth'].includes(prop),
})<{
  rightColumn: number;
  topRow: number;
  leftColumn: number;
  bottomRow: number;
  parentRowSpace: number;
  parentColumnSpace: number;
  bodyWidth: number;
}>\`
  position: absolute;
  width: \${({rightColumn, leftColumn, parentColumnSpace, bodyWidth})=> ((rightColumn - leftColumn) * parentColumnSpace * (bodyWidth/375))}px;
  height: \${({bottomRow, topRow, parentRowSpace})=> ((bottomRow - topRow) * parentRowSpace)}px;
  top: \${({topRow, parentRowSpace})=> topRow * parentRowSpace}px;
  left: \${({leftColumn, parentColumnSpace})=> leftColumn * parentColumnSpace}px;
\`;

export default WidgetWrapper;
  `;
}


