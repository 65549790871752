import * as React from "react";
import Filter from "@byk/pages/QueryBuilder/lib/queries/structured/Filter";
import cx from "classnames";
import SidebarHeader from "@byk/pages/QueryBuilder/components/ui/SidebarHeader";
import OperatorSelector from "@byk/pages/QueryBuilder/components/ui/FilterPopover/OperatorSelector";
import {FilterOperator} from "@byk/pages/QueryBuilder/lib/metadata/types/deprecated-types";

export interface Props {
  className?: any;
  showFieldPicker?: boolean;
  forceShowOperatorSelector?: boolean;
  filter: Filter;
  onFilterChange: (filter: any[]) => void;
  onBack: () => void;
};

const FilterPopoverHeader: React.FC<Props> =
  ({
     className,
     showFieldPicker,
     forceShowOperatorSelector,
     filter,
     onFilterChange,
     onBack,
   }: Props) => {
    const dimension = filter.dimension();
    if (!dimension) {
      return null;
    }

    const field = dimension.field();
    const operator: FilterOperator = field.filterOperator(filter.operatorName());

    const showOperatorSelector = forceShowOperatorSelector ?? !field.isBoolean();
    const showHeader = showFieldPicker || showOperatorSelector;
    const showOperatorSelectorOnOwnRow = !showFieldPicker;

    const setOperator = (operator: FilterOperator) => {
      if (filter.operatorName() !== operator.name) {
        onFilterChange(filter.setOperator(operator.name));
      }
    };

    return showHeader ? (
      <div
        className={cx(className, "text-medium p1", {
          "flex align-center": !showOperatorSelectorOnOwnRow,
        })}
      >
        {showFieldPicker && (
          <SidebarHeader
            className={cx("text-default py1", {
              pr2: !showOperatorSelectorOnOwnRow,
            })}
            title={field.displayName(true)}
            onBack={onBack}
          />
        )}
        {showOperatorSelector && (
          <div style={{
            position: "absolute",
            right: "15px"
          }}>
            <OperatorSelector
              className={cx("flex-no-shrink block", {
                "ml-auto": !showOperatorSelectorOnOwnRow,
                my1: showOperatorSelectorOnOwnRow,
              })}
              operator={operator}
              onOperatorChange={setOperator}
              operators={filter.filterOperators(operator.name) || []}/>
          </div>
        )}
      </div>
    ) : null;
  }

export default FilterPopoverHeader;
