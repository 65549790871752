import type { ReactNode } from "react";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AnalyticsUtil from "utils/AnalyticsUtil";
import { initExplorerEntityNameEdit } from "actions/explorerActions";
import {
  clonePageInit,
  deletePage,
  setPageAsDefault, setPageAsLogin,
  updatePage,
} from "actions/pageActions";
import styled from "styled-components";
import { Icon } from "design-system";
import {
  CONTEXT_EDIT_NAME,
  CONTEXT_CLONE,
  CONTEXT_SET_AS_HOME_PAGE,
  CONTEXT_DELETE,
  CONFIRM_CONTEXT_DELETE,
  createMessage,
  CONTEXT_SETTINGS, CONTEXT_SET_AS_LOGIN_PAGE, CONTEXT_SET_AS_LOGIN_PAGE_CANCEL, CONTEXT_SET_AS_HOME_PAGE_CANCEL,
} from "@appsmith/constants/messages";
import { openAppSettingsPaneAction } from "actions/appSettingsPaneActions";
import { AppSettingsTabs } from "pages/Editor/AppSettingsPane/AppSettings";
import {
  hasCreatePagePermission,
  hasDeletePagePermission,
  hasManagePagePermission,
} from "@appsmith/utils/permissionHelpers";
import { getPageById } from "selectors/editorSelectors";
import { getCurrentApplication } from "@appsmith/selectors/applicationSelectors";
import type { AppState } from "@appsmith/reducers";
import ContextMenu from "pages/Editor/Explorer/ContextMenu";
import type { TreeDropdownOption } from "pages/Editor/Explorer/ContextMenu";
import {Modal} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons/lib";
const { confirm } = Modal;
const CustomLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function PageContextMenu(props: {
  pageId: string;
  name: string;
  applicationId: string;
  className?: string;
  pageType?: string;
  isDefaultPage: boolean;
  isLoginPage?: boolean;
  isHidden: boolean;
}) {
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const deleteConfirm = () => {
    confirm({
      title: '删除分组?',
      icon: <ExclamationCircleFilled />,
      content: '确定删除分组及分组下的子页面吗？删除后不可恢复，请谨慎操作！',
      onOk() {
        deletePageCallback();
      }
    });
  };
  /**
   * delete the page
   *
   * @return void
   */
  const deletePageCallback = useCallback((): void => {
    dispatch(deletePage(props.pageId));
    AnalyticsUtil.logEvent("DELETE_PAGE", {
      pageName: props.name,
    });
  }, [dispatch]);

  /**
   * sets the page as default
   *
   * @return void
   */
  const setPageAsDefaultCallback = useCallback((flug:boolean): void => {
    dispatch(setPageAsDefault({
      pageId: props.pageId,
      appId: props.applicationId,
      flug: flug
    }));
  }, [dispatch]);
  const setPageAsLoginCallback = useCallback((flug: boolean): void => {
    dispatch(setPageAsLogin({
      pageId: props.pageId,
      appId: props.applicationId,
      flug: flug
    }));
  }, [dispatch]);

  /**
   * edit the page name
   *
   * @return void
   */
  const editPageName = useCallback(
    () => dispatch(initExplorerEntityNameEdit(props.pageId)),
    [dispatch, props.pageId],
  );

  /**
   * clone the page
   *
   * @return void
   */
  const clonePage = useCallback(
    () => dispatch(clonePageInit(props.pageId)),
    [dispatch, props.pageId],
  );

  /**
   * sets the page hidden
   *
   * @return void
   */
  const setHiddenField = useCallback(
    () =>
      dispatch(
        updatePage({
          id: props.pageId,
          name: props.name,
          isHidden: !props.isHidden,
        }),
      ),
    [dispatch, props.pageId, props.name, props.isHidden],
  );

  const openAppSettingsPane = () =>
    dispatch(
      openAppSettingsPaneAction({
        type: AppSettingsTabs.Page,
        pageId: props.pageId,
      }),
    );

  const pagePermissions =
    useSelector(getPageById(props.pageId))?.userPermissions || [];

  const userAppPermissions = useSelector(
    (state: AppState) => getCurrentApplication(state)?.userPermissions ?? [],
  );

  const canCreatePages = hasCreatePagePermission(userAppPermissions);

  const canManagePages = hasManagePagePermission(pagePermissions);

  const canDeletePages = hasDeletePagePermission(pagePermissions);

  const optionsTree = [
    canManagePages && {
      value: "rename",
      onSelect: editPageName,
      label: createMessage(CONTEXT_EDIT_NAME),
    },

    (!props.isLoginPage && !props.isDefaultPage) &&
    canManagePages && {
      value: "setlogin",
      onSelect: ()=>{
        setPageAsLoginCallback(true);
      },
      label: createMessage(CONTEXT_SET_AS_LOGIN_PAGE),
    },
    props.isLoginPage &&
    canManagePages && {
      value: "setloginCancel",
      onSelect: ()=>{
        setPageAsLoginCallback(false);
      },
      label: createMessage(CONTEXT_SET_AS_LOGIN_PAGE_CANCEL),
    },
    (!props.isLoginPage && !props.isDefaultPage) &&
      canManagePages && {
        value: "setdefault",
        onSelect: ()=>{
          setPageAsDefaultCallback(true);
        },
        label: createMessage(CONTEXT_SET_AS_HOME_PAGE),
      },
    (!props.isLoginPage && !props.isDefaultPage && props.pageType == 'Group') &&
      canDeletePages && {
        className: "t--apiFormDeleteBtn single-select",
        value: "delete group",
        onSelect: () => {
          deleteConfirm();
        },
        label: createMessage(CONTEXT_DELETE),
        intent: "danger",
      },
    (!props.isLoginPage && !props.isDefaultPage  && props.pageType == 'Page') &&
      canDeletePages && {
        className: "t--apiFormDeleteBtn single-select",
        confirmDelete: confirmDelete,
        value: "delete",
        onSelect: () => {
          confirmDelete ? deletePageCallback() : setConfirmDelete(true);
        },
        label: confirmDelete
          ? createMessage(CONFIRM_CONTEXT_DELETE)
          : createMessage(CONTEXT_DELETE),
        intent: "danger",
      },
  ].filter(Boolean);

  return optionsTree?.length > 0 ? (
    <ContextMenu
      className={props.className}
      optionTree={optionsTree as TreeDropdownOption[]}
      setConfirmDelete={setConfirmDelete}
    />
  ) : null;
}

export default PageContextMenu;
