import React from "react";
import styled from "styled-components";
import {IntentColors} from "constants/DefaultTheme";
import type {Alignment, IRef} from "@blueprintjs/core";
import {Classes, ControlGroup} from "@blueprintjs/core";
import type {ComponentProps} from "widgets/BaseComponent";
import moment from "moment-timezone";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import type {DatePickerType} from "../constants";
import {datePickerShowTime, isTimePicker, pickerMap, TimePrecision, YYYY, YYYY_MM_DD} from "../constants";
import type {TextSize} from "constants/WidgetConstants";
import {Colors} from "constants/Colors";
import ErrorTooltip from "components/editorComponents/ErrorTooltip";
import {createMessage, DATE_WIDGET_DEFAULT_VALIDATION_ERROR,} from "@appsmith/constants/messages";
import {LabelPosition} from "components/constants";
import {parseDate} from "./utils";
import {lightenColor, PopoverStyles} from "widgets/WidgetUtils";
import LabelWithTooltip, {labelLayoutStyles,} from "widgets/components/LabelWithTooltip";
import {required} from "utils/validation/common";
import { DatePicker,TimePicker} from "antd";
import "./index.css";
import {hexToRgb, uuid2} from "@byk/utils/Utils";
import {Input} from "antd";
import {FontStyleTypes} from "@design-system/widgets-old";
import {getAntdDatePickerPopupStyled} from "../../widgetStyled";

const DATEPICKER_POPUP_CLASSNAME = "datepickerwidget-popup";

const DatePickerWrapper = styled(DatePicker)<any>`
  input {
    border: none !important;
  }
`

function hasFulfilledRequiredCondition(
  isRequired: boolean | undefined,
  value: any,
) {
  // if the required condition is not enabled then it has fulfilled
  if (!isRequired) return true;

  return !required(value);
}

const StyledControlGroup = styled(ControlGroup)<{
  isValid: boolean;
  compactMode: boolean;
  labelPosition?: LabelPosition;
  borderRadius: string;
  boxShadow?: string;
  accentColor: string;
  isReadOnly:boolean;
  it_color?: string;
  it_size?: string;
  it_style?: string;
  ib_bgColor?: string;
  ib_borderColor?: string;
}>`
  ${labelLayoutStyles}

  /**
    When the label is on the left it is not center aligned
    here set height to auto and not 100% because the input
    has fixed height and stretch the container.
  */
    ${({labelPosition}) => {
      if (labelPosition === LabelPosition.Left) {
        return `
          height: auto !important;
          align-items: stretch;
        `;
      }
    }}

  &&& {
     .ant-picker-input > input {
      font-size: ${({it_size}) => {
        return (it_size ? `${it_size}` : "14px") + ' !important'
      }};
           font-style: ${({it_style}) => {
        return (it_style?.includes(FontStyleTypes.ITALIC) ? "italic" : "") + ' !important'
      }};
           font-weight: ${({it_style}) => {
        return (it_style?.includes(FontStyleTypes.BOLD) ? "bold" : "") + ' !important'
      }};
     }
    .ant-picker {
      color: ${({it_color}) => it_color ? it_color : 'var(--wds-color-text)'} !important;
      background:  ${({ib_bgColor}) => ib_bgColor ? ib_bgColor : 'var(--wds-color-bg)'} !important;
      border-radius: ${({borderRadius}) => borderRadius} !important;
      box-shadow: ${({boxShadow}) => `${boxShadow}`} !important;
      border: 1px solid;
      border-color: ${({isValid, ib_borderColor}) =>
        !isValid
          ? `var(--wds-color-border-danger);`
          : ib_borderColor ? ib_borderColor : `var(--wds-color-border);`} !important;
      width: 100%;
      height: 100%;
      min-height: 32px;
      align-items: center;
      transition: none;

      &:active:not(:disabled) {
        border-color: ${({accentColor, isValid}) => !isValid ? `var(--wds-color-border-danger)` : accentColor};
      }

      &:hover:not(:disabled) {
        border-color: ${({isValid}) => !isValid ? `var(--wds-color-border-danger-hover)` : `var(--wds-color-border-hover)`};
      }

      &:focus:not(:disabled) {
        outline: 0;
        border: 1px solid;
        border-color: ${({accentColor, isValid}) => !isValid ? `var(--wds-color-border-danger-focus) !important` : accentColor};
        box-shadow: ${({accentColor, isValid}) => `0px 0px 0px 2px ${ isValid ? lightenColor(accentColor) : "var(--wds-color-border-danger-focus-light)" } !important;`};
      }

      &:focus-within {
        border-color: ${({accentColor}) => accentColor} !important;
        box-shadow: ${({accentColor}) => `0px 0px 0px 2px ${ lightenColor(accentColor)} !important;`};
      }
    }

    .${Classes.INPUT}:disabled {
      background: var(--wds-color-bg-disabled);
      color: var(--wds-color-text-disabled);
    }

    .${Classes.INPUT}:not(:disabled)::placeholder {
      color: var(--wds-color-text-light);
    }

    .${Classes.INPUT}::placeholder {
      color: var(--wds-color-text-disabled-light);
    }

    .${Classes.INPUT_GROUP} {
      display: block;
      margin: 0;
    }

    .${Classes.CONTROL_GROUP} {
      justify-content: flex-start;
    }
  }
  &&& {
    input {
    ${({isReadOnly, isValid, theme}) => {
        if (!isReadOnly) {
          return `
            border: 1px solid;
            border-color: ${() => !isValid ? IntentColors.danger : Colors.HIT_GRAY};
            box-shadow: none;
           `;
        }
      }}
    }
  }
`;

export const DateInputWrapper = styled.div<{
  compactMode: boolean;
  labelPosition?: LabelPosition;
}>`
  display: flex;
  &&& {
    flex-grow: 0;
  }
  width: 100%;
`;

class DatePickerComponent extends React.Component<DatePickerComponentProps,
  DatePickerComponentState> {
  constructor(props: DatePickerComponentProps) {
    super(props);
    this.state = {
      selectedDate: props.selectedDate,
      isSelectedDate: false,
      checkIsValid: props.checkIsValid,
    };
  }

  componentDidUpdate(prevProps: DatePickerComponentProps) {
    // prevProps.selectedDate can undefined and moment(undefined) returns now
    if (
      this.props.selectedDate !== this.state.selectedDate &&
      (!moment(this.props.selectedDate).isSame(
          moment(prevProps.selectedDate),
          "seconds",
        ) ||
        (!prevProps.selectedDate && this.props.selectedDate))
    ) {
      this.setState({selectedDate: this.props.selectedDate});
    }
  }

  getValidDate = (date: string, format: string) => {
    const _date = moment(date, format);
    return _date.isValid() ? _date.toDate() : undefined;
  };

  getConditionalPopoverProps = (props: DatePickerComponentProps) => {
    if (typeof props.isPopoverOpen === "boolean") {
      return {
        isOpen: props.isPopoverOpen,
      };
    }
    return {};
  };

  render() {
    const {
      compactMode,
      isDisabled,
      isLoading,
      isRequired,
      labelAlignment,
      labelPosition,
      labelStyle,
      labelText,
      labelTextColor,
      labelTextSize,
      labelTooltip,
      labelWidth,
      placeholderText,
      dateFormat,
      selectedDate,
      it_color,
      it_size,
      it_style,
      ib_bgColor,
      ib_borderColor,
      ib_iconColor,
      accentColor,
    } = this.props;
    const key=uuid2();
    const timePickerFlag = isTimePicker(dateFormat);

    const minDate = !!this.props.minDate ? moment(this.props.minDate, this.props.dateFormat) : null;
    const maxDate = !!this.props.maxDate ? moment(this.props.maxDate, this.props.dateFormat) : null;
    const _selectedDate = !!this.props.selectedDate ? moment(this.props.selectedDate, this.props.dateFormat) : null;

    const isValid = this.isValidDate(this.state.selectedDate, timePickerFlag)
    const value = isValid ? this.state.selectedDate && new Date(this.state.selectedDate) : null;

    const hasFulfilledRequired = hasFulfilledRequiredCondition(
      isRequired,
      value,
    );

    const getIsValid = () => {
      if (this.state.checkIsValid === undefined) { // 说明是第一次加载组件
        return this.state.isSelectedDate ? isValid && hasFulfilledRequired : true;
      } else {
        return isValid && hasFulfilledRequired;
      }
    };


    let checkIsValid = getIsValid();

    // 动态生成CSS样式
    const dynamicStyles = `
      .datePicker2Widget__popover {
        ${getAntdDatePickerPopupStyled(accentColor)}
      }
    `;

    return (
      <StyledControlGroup
        accentColor={this.props.accentColor}
        borderRadius={this.props.borderRadius}
        boxShadow={this.props.boxShadow}
        compactMode={this.props.compactMode}
        data-testid="datepicker-container"
        fill
        isValid={checkIsValid}
        isReadOnly={this.props.isReadonly}
        labelPosition={this.props.labelPosition}
        it_color={it_color}
        it_size={it_size}
        it_style={it_style}
        ib_bgColor={ib_bgColor}
        ib_borderColor={ib_borderColor}
      >
        {labelText && (
          <LabelWithTooltip
            alignment={labelAlignment}
            className={`datepicker-label`}
            color={labelTextColor}
            compact={compactMode}
            cyHelpTextClassName="datepicker-tooltip"
            disabled={isDisabled}
            fontSize={labelTextSize}
            fontStyle={labelStyle}
            helpText={labelTooltip}
            isDynamicHeightEnabled={this.props.isDynamicHeightEnabled}
            loading={isLoading}
            position={labelPosition}
            text={labelText}
            width={labelWidth}
            isRequired={isRequired}
          />
        )}
        {
          this.props.isReadonly ? (<div>
            <Input readOnly={true} bordered={false} value={value && this.formatDate(value) || ''}
                   style={{
                     color: it_color ||"#000000",
                     backgroundColor: ib_bgColor ||"#FFFFFF",
                     fontSize: it_size,
                     fontWeight: it_style?.includes(FontStyleTypes.BOLD) ? "bold" : "",
                     fontStyle: it_style?.includes(FontStyleTypes.ITALIC) ? "italic" : "",
                     borderRadius: this.props.borderRadius,
                   }}
            />
          </div>) : <DateInputWrapper
            compactMode={compactMode}
            labelPosition={labelPosition}
          >
            <style>{dynamicStyles}</style>
            <ErrorTooltip
              isOpen={!isValid}
              message={createMessage(DATE_WIDGET_DEFAULT_VALIDATION_ERROR) || ""}
            >
              <DatePickerWrapper
                popupClassName="datePicker2Widget__popover"
                key={key}
                style={{width:"100%"}}
                value={this.state.selectedDate ? moment(this.state.selectedDate,dateFormat) : undefined}
                onChange={(date:any, dateString:any)=>this.onChange(date,dateString, dateFormat)}
                picker={pickerMap[dateFormat]}
                showTime={datePickerShowTime(dateFormat)}
                showNow={!!datePickerShowTime(dateFormat)}
                showToday={!timePickerFlag}
                format={dateFormat}
                placeholder={this.state.selectedDate? '' : this.props.placeholderText}
                disabledDate = {(current:any) => {
                    return !timePickerFlag && current && (current < moment(minDate) || current > moment(maxDate));
                  }
                }

              />
            </ErrorTooltip>
          </DateInputWrapper>
        }

        <PopoverStyles
          accentColor={this.props.accentColor}
          borderRadius={this.props.borderRadius}
          portalClassName={`${DATEPICKER_POPUP_CLASSNAME}-${this.props.widgetId}`}
        />
      </StyledControlGroup>
    );
  }

  isValidDate = (selectedDate: any, timePickerFlag:boolean): boolean => {
    const minDate = !!this.props.minDate ? moment(this.props.minDate, this.props.dateFormat) : null;
    const maxDate = !!this.props.maxDate ? moment(this.props.maxDate, this.props.dateFormat) : null;

    let isValid = true;
    const parsedCurrentDate = !!selectedDate ? moment(selectedDate, this.props.dateFormat) : null;
    if (parsedCurrentDate) {
      if (timePickerFlag) {
        if (minDate) {
          isValid =  !parsedCurrentDate.isBefore(minDate);
        }

        if (isValid && maxDate) {
          isValid =  !parsedCurrentDate.isAfter(maxDate);
        }
      } else {
        if (minDate && minDate.isValid()) {
          if (!parsedCurrentDate.isSame(minDate, "day") && parsedCurrentDate.isBefore(minDate)) {
            isValid = false;
          }
        }
        if (isValid && maxDate && maxDate.isValid()) {
          if (!parsedCurrentDate.isSame(maxDate, "day") && parsedCurrentDate.isAfter(maxDate)) {
            isValid = false;
          }
        }
      }
    }

    if (!isValid && this.props?.onDateOutOfRange) {
      this.props.onDateOutOfRange();
    }

    return isValid;
  };

  formatDate = (date: Date): string => {
    const dateFormat = this.props.dateFormat || YYYY_MM_DD;
    let fmtDate = moment(date).format(dateFormat);
    return fmtDate;
  };

  parseDate = (dateStr: string): Date | null => {
    //when user clears date field the value of dateStr will be empty
    //and that means user is clearing date field
    if (!dateStr) {
      return null;
    } else {
      const dateFormat = this.props.dateFormat || YYYY_MM_DD;
      return parseDate(dateStr, dateFormat);
    }
  };

  onChange = (date:any, dateString:any,dateFormat:any) => {
    this.setState({
      selectedDate: dateString,
    });
    let date1:any = date ? new Date(date) : null;
    const {onDateSelected} = this.props;
    onDateSelected(date1,true)
  };

  /**
   * checks if selelectedDate is null or not,
   * sets state and calls props onDateSelected
   * if its null, don't call onDateSelected
   * update internal state while changing month/year to update calender
   *
   * @param selectedDate
   */
  onDateSelected = (selectedDate: Date | null, isUserChange: boolean) => {
    if (isUserChange) {
      // @ts-ignore
      this.setState({isSelectedDate: true});
      const {onDateSelected} = this.props;
      const date = selectedDate ? this.formatDate(selectedDate) : "";
      this.setState({
        selectedDate: date,
      });
      onDateSelected(date, this.state.checkIsValid || true);
    }
  };
}

interface DatePickerComponentProps extends ComponentProps {
  compactMode: boolean;
  labelText: string;
  labelPosition?: LabelPosition;
  labelAlignment?: Alignment;
  labelWidth?: number;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  labelStyle?: string;
  dateFormat: string;
  selectedDate?: string;
  minDate?: string;
  maxDate?: string;
  timezone?: string;
  datePickerType: DatePickerType;
  isDisabled: boolean;
  isReadonly: boolean;
  isDynamicHeightEnabled?: boolean;
  onDateSelected: (selectedDate: string, checkIsValid: boolean) => void;
  isLoading: boolean;
  withoutPortal?: boolean;
  closeOnSelection: boolean;
  shortcuts: boolean;
  firstDayOfWeek?: number;
  timePrecision: TimePrecision;
  inputRef?: IRef<HTMLInputElement>;
  borderRadius: string;
  boxShadow?: string;
  accentColor: string;
  labelTooltip?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onPopoverClosed?: (e: unknown) => void;
  isPopoverOpen?: boolean;
  onDateOutOfRange?: () => void;
  isRequired?: boolean;
  placeholderText?: string;
  checkIsValid?: boolean;
}

interface DatePickerComponentState {
  selectedDate?: string;
  isSelectedDate?: boolean;
  checkIsValid?: boolean;
}

export default DatePickerComponent;
