export default function Config ():string{
  return `
import {applyMiddleware, legacy_createStore as createStore} from "redux";
import XReducer from './reducers';
import createSagaMiddleware from "redux-saga";
const sagaMiddleware = createSagaMiddleware();
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import rootSaga from './sagas';

export default createStore(
  XReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware)
  )
);

sagaMiddleware.run(rootSaga);
  `;
}
