import React, {useState} from "react";
import 'antd/dist/antd.min.css'; //显示样式，不加显示不出来

import {Button, Col, Form, Input, Row, Select} from 'antd';
import {TrackContextFn} from "@byk/pages/Track/index";
import styled from "styled-components";
import {tableProps} from "./tableConfig";
import TableCmp from "./TableCmp";
import {ContentPageFlagType} from "@byk/pages/Track/constant";
import {model} from "@byk/pages/Track/model";


export const FormWrapper = styled(Form)`
  padding-left: 25px;
  .ant-input {
    border-radius: 5px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }

  .resetBtn {
    margin-left: 8px;
    background-color: #F2F3F5;
  }

  .ant-btn {
    border: none;
  }
`

const TableContainer = styled.div`
  padding-left: 25px;
  margin-right: 32px;
  .btnBar {
    margin-bottom: 18px;

    button {
      height: 40px;
    }
  }

  .ant-btn-link[disabled] {
    color: rgba(0,0,0,.25) !important;
  }
`

const TrackList = () => {
  const {setContentPageFlag, stateOptions, typeOptions, getProject, setIssue, closeIssue} = TrackContextFn();
  const [form] = Form.useForm();
  const [searchParam, setSearchParam] = useState<any>({});

  const createTrack = () => {
    setIssue({});
    setContentPageFlag(ContentPageFlagType.CREATE);
    getProject();
  }

  const searchIssue = () => {
    let params = form.getFieldsValue();
    setSearchParam(params)
  }

  const reset = () => {
    form.resetFields();
    setSearchParam({});
  }

  const viewDetail = async (issue: any) => {
    let issueDetail: any = await model.getIssueDetailData(issue);
    setIssue(issueDetail);
    setContentPageFlag(ContentPageFlagType.DETAIL);
  }

  return (<>
      <div className='breadcrumb'>我的工单</div>

      <FormWrapper form={form}>
        <Row gutter={24}>
          <Col xs={12} sm={8} md={6}>
            <Form.Item label="标题" labelCol={{span: 8}} name='summary'>
              <Input style={{width: "100%"}}/>
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item label="工单类型" labelCol={{span: 8}} name='type'>
              <Select
                allowClear={true}
                options={typeOptions}
                style={{width: "100%"}}/>
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item label="工单状态" labelCol={{span: 8}} name='state'>
              <Select
                allowClear={true}
                options={stateOptions}
                style={{width: "100%"}}/>
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Button type="primary" onClick={searchIssue}>查询</Button>
            <Button className='resetBtn' onClick={reset}>重置</Button>
          </Col>
        </Row>
      </FormWrapper>

      <TableContainer>
        <div className='btnBar'>
          <Button type='primary' onClick={createTrack}>提交工单</Button>
        </div>

        <TableCmp {...tableProps(viewDetail, closeIssue)} searchParam={searchParam}/>
      </TableContainer>
    </>
  )
}

export default TrackList;
