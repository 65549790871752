import prettier from "prettier/standalone";
import prettierBabel from "prettier/parser-babel";

export default async function prettierFormat(temp:string){
  let _temp = await prettier.format(temp, {
    parser: 'babel',
    plugins: [prettierBabel],
    semi: false,
    singleQuote: true,
  });
  return _temp;
}
