import React, {useEffect, useState} from "react";
import Api from "@byk/api/Api";

import { Button, Form, Input, Card, message } from "antd";
import history from "utils/history";
import {connect, useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {X_PREVIEW_USER} from "@byk/store/XReducers";
import localStorage from "../../../../utils/localStorage";
import {ReduxActionTypes} from "../../../../ce/constants/ReduxActionConstants";
import {AppState} from "../../../../ce/reducers";
import { routePath } from "@byk/routes";
import {getUrlParams} from "@byk/utils/Utils";

const Login = (props:{
  setPreviewUser: (data:any) => void
}) => {
  const dispatch = useDispatch();
  let urlParams = getUrlParams();
  const [appName, setAppName] = useState(urlParams.appName);
  const {appInfo, mainPages:previewPageLists} = useSelector((state:AppState)=>{
    return state.XReducers.previewUser;
  })
  const onFinish = async (values: any) => {
    //let pwd = encryptedData(values.password);
    let params: any = {};
    params.username = values.username;
    params.password = values.password;//pwd.toString();
    params.appId = appInfo.appId;
    params.tenant = appInfo.tenant;

    let result: any = await Api.post(`/byk/${appInfo.tenant}/subapp/admin/enter`, params);
    if (result.success) {
      message.success("登录成功!");
      dispatch({
        type: X_PREVIEW_USER,
        data: result.ext
      })
      dispatch({
        type: ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
        payload: result.ext.user||{},
      })
      dispatch({
        type: ReduxActionTypes.FETCH_APPLICATION_INIT,
        payload: {
          applicationId: params.appId
        },
      })

      if(!_.isEmpty(previewPageLists)){
        let toPage:string = 'systemRole', toPageId:string = '9999';
        let defaultPage = _.find(previewPageLists, {defaultPage: true});
        if(defaultPage){
          toPageId = defaultPage.id;
          toPage = defaultPage.slug;
        }

        if(toPageId === '9999'){
          history.push(`${routePath}/app/${appInfo.appId}/${toPage}-${toPageId}/system?appId=${urlParams.appId}&appName=${urlParams.appName}`);
        }else{
          history.push(`${routePath}/app/${appInfo.appId}/${toPage}-${toPageId}?appId=${urlParams.appId}&appName=${urlParams.appName}`);
        }
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <main
        style={{
          width: "100vw",
          height: "100vh",
          display: "table",
          alignItems: "center",
          justifyItems: "center",
          textAlign: "center",
          verticalAlign: "middle",
          paddingTop: "100px"
        }}
      >
        <Card title={<p className="x-login-title">{appName}</p>} hoverable className="x-login-card">
          <div className="x-w100 x-tl">
            <span className="x-login-span">登录</span>
          </div>

          <Form
            name="basic"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{
              username: "admin",
              password: "123456",
            }}
            className="x-main-login-app"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=" "
              colon={false}
              name="username"
              rules={[
                {
                  required: true, message: '请输入手机号!'
                },
              ]}
            >
              <Input placeholder="请输入手机号" className="x-login-text" />
            </Form.Item>

            <Form.Item
              label=" "
              colon={false}
              name="password"
              rules={[{ required: true, message: "请输入密码!" }]}
            >
              <Input.Password placeholder="请输入密码" className="x-login-text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button type="primary" htmlType="submit" className="x-login-btn" style={{ width: "100%", height: "50px" }}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </main>
    </>
  )

};


const mapDispatchToProps = (dispatch: any) => ({
  setPreviewUser: (data:any)=>{
    dispatch({
      type: X_PREVIEW_USER,
      data
    })
  }
});
export default connect(null, mapDispatchToProps)(Login);
