import type {JSXElementConstructor} from "react";
import React from "react";
import type {IconProps} from "constants/IconConstants";
import {IconWrapper} from "constants/IconConstants";
import {importSvg} from "design-system-old";

const JoinFullOuter = importSvg(() => import("assets/icons/queryBuilder/join_full_outer.svg"));
const JoinInner = importSvg(() => import("assets/icons/queryBuilder/join_inner.svg"));
const JoinLeftOuter = importSvg(() => import("assets/icons/queryBuilder/join_left_outer.svg"));
const JoinRightOuter = importSvg(() => import("assets/icons/queryBuilder/join_right_outer.svg"));
const Close = importSvg(() => import("assets/icons/queryBuilder/close.svg"));
const AddData = importSvg(() => import("assets/icons/queryBuilder/add_data.svg"));
const Filter = importSvg(() => import("assets/icons/queryBuilder/filter.svg"));
const List = importSvg(() => import("assets/icons/queryBuilder/list.svg"));
const Sort = importSvg(() => import("assets/icons/queryBuilder/sort.svg"));
const Sum = importSvg(() => import("assets/icons/queryBuilder/sum.svg"));
const Add = importSvg(() => import("assets/icons/queryBuilder/add.svg"));
const ChevronDown = importSvg(() => import("assets/icons/queryBuilder/chevrondown.svg"));
const ChevrondLeft = importSvg(() => import("assets/icons/queryBuilder/chevronleft.svg"));
const ChevronRight = importSvg(() => import("assets/icons/queryBuilder/chevronright.svg"));
const ChevronUp = importSvg(() => import("assets/icons/queryBuilder/chevronup.svg"));
const Table = importSvg(() => import("assets/icons/queryBuilder/table.svg"));
const Label = importSvg(() => import("assets/icons/queryBuilder/label.svg"));
const ArrowDown = importSvg(() => import("assets/icons/queryBuilder/arrow_down.svg"));
const ArrowLeft = importSvg(() => import("assets/icons/queryBuilder/arrow_left.svg"));
const ArrowLeftToLine = importSvg(() => import("assets/icons/queryBuilder/arrow_left_to_line.svg"));
const ArrowRight = importSvg(() => import("assets/icons/queryBuilder/arrow_right.svg"));
const ArrowSplit = importSvg(() => import("assets/icons/queryBuilder/arrow_split.svg"));
const ArrowUp = importSvg(() => import("assets/icons/queryBuilder/arrow_up.svg"));
const Question = importSvg(() => import("assets/icons/queryBuilder/question.svg"));

export const QueryBuilderIcons: {
  [id: string]: JSXElementConstructor<IconProps>;
} = {
  Question: (props: IconProps) => (
    <IconWrapper {...props}>
      <Question/>
    </IconWrapper>
  ),
  ArrowDown: (props: IconProps) => (
    <IconWrapper {...props}>
      <ArrowDown/>
    </IconWrapper>
  ),
  ArrowLeft: (props: IconProps) => (
    <IconWrapper {...props}>
      <ArrowLeft/>
    </IconWrapper>
  ),
  ArrowLeftToLine: (props: IconProps) => (
    <IconWrapper {...props}>
      <ArrowLeftToLine/>
    </IconWrapper>
  ),
  ArrowRight: (props: IconProps) => (
    <IconWrapper {...props}>
      <ArrowRight/>
    </IconWrapper>
  ),
  ArrowSplit: (props: IconProps) => (
    <IconWrapper {...props}>
      <ArrowSplit/>
    </IconWrapper>
  ),
  ArrowUp: (props: IconProps) => (
    <IconWrapper {...props}>
      <ArrowUp/>
    </IconWrapper>
  ),
  Label: (props: IconProps) => (
    <IconWrapper {...props}>
      <Label/>
    </IconWrapper>
  ),
  Table: (props: IconProps) => (
    <IconWrapper {...props}>
      <Table/>
    </IconWrapper>
  ),
  ChevronUp: (props: IconProps) => (
    <IconWrapper {...props}>
      <ChevronUp/>
    </IconWrapper>
  ),
  ChevronRight: (props: IconProps) => (
    <IconWrapper {...props}>
      <ChevronRight/>
    </IconWrapper>
  ),
  ChevrondLeft: (props: IconProps) => (
    <IconWrapper {...props}>
      <ChevrondLeft/>
    </IconWrapper>
  ),
  ChevronDown: (props: IconProps) => (
    <IconWrapper {...props}>
      <ChevronDown/>
    </IconWrapper>
  ),
  Add: (props: IconProps) => (
    <IconWrapper {...props}>
      <Add/>
    </IconWrapper>
  ),
  AddData: (props: IconProps) => (
    <IconWrapper {...props}>
      <AddData/>
    </IconWrapper>
  ),
  Filter: (props: IconProps) => (
    <IconWrapper {...props}>
      <Filter/>
    </IconWrapper>
  ),
  List: (props: IconProps) => (
    <IconWrapper {...props}>
      <List/>
    </IconWrapper>
  ),
  Sort: (props: IconProps) => (
    <IconWrapper {...props}>
      <Sort/>
    </IconWrapper>
  ),
  Sum: (props: IconProps) => (
    <IconWrapper {...props}>
      <Sum/>
    </IconWrapper>
  ),
  Close: (props: IconProps) => (
    <IconWrapper {...props}>
      <Close/>
    </IconWrapper>
  ),
  JoinFullOuter: (props: IconProps) => (
    <IconWrapper {...props}>
      <JoinFullOuter/>
    </IconWrapper>
  ),
  JoinInner: (props: IconProps) => (
    <IconWrapper {...props}>
      <JoinInner/>
    </IconWrapper>
  ),
  JoinLeftOuter: (props: IconProps) => (
    <IconWrapper {...props}>
      <JoinLeftOuter/>
    </IconWrapper>
  ),
  JoinRightOuter: (props: IconProps) => (
    <IconWrapper {...props}>
      <JoinRightOuter/>
    </IconWrapper>
  ),
};

export type QueryBuilderIconName = keyof typeof QueryBuilderIcons;

export function getIcon(name: string, props: any) {
  let Icon = QueryBuilderIcons[name];
  return <Icon {...props}/>
}
