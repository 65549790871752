import React, {useEffect, useReducer, useRef, useState} from 'react';
import {Button, Drawer, Form, message, Select, Space} from "antd";
import styled from "styled-components";
import _ from "lodash";
import {ApiOutlined, DisconnectOutlined} from "@ant-design/icons/lib";
import Api from "@byk/api/Api";
import MappingEdit from "@byk/pages/MyTask/MappingEdit";
import PopupRightPage from "@byk/pages/MyTask/PopupRightPage";
import {X_WIDGET_PROP_POPUP} from "@byk/store/XReducers";
import {useDispatch} from "react-redux";
export const popup_mapping_mark = '_~_';//常量，上下文的间隔符
const MainWrapper = styled.div`
  display: flex;
  min-height: 100%;
  .left{
    width: 600px;
    height: calc(100vh - 70px);
    overflow-y: auto;
    .pd10{
      padding: 10px;
    }
  }
  .right{
    width: calc(100% - 600px);
    height: calc(100vh - 70px);
    overflow-y: auto;
    padding: 10px;
    border-left: 1px solid #f0f0f0;
    background: #fff;
    .ant-tag{
      margin-bottom: 6px;
    }
  }
  .ant-tree-switcher{
    align-self: center;
  }
  .x-right-box{
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .1);
  }
  .x-right-focus{
    box-shadow: 0px 0px 5px rgba(255, 155, 78, 1);
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  .mapping-title{
    font-size: 20px;
  }
  .mapping-task{
    padding: 4px 10px;
    background: #006CF2;
    font-weight: normal;
    border-radius: 4px;
    color: #fff;
    margin-left: 10px;
  }
`
const initState = {
  open: false,//侧滑
  mapping: true,//输入/输出 true输入 false 输出
  serviceList: [],//服务配置下拉
  pageElement: [],//页面元素树结构
  mappingInList: [],//服务输入映射下拉
  mappingOutList: [],//服务输出映射下拉
  mappingIn: {},//输入映射
  mappingOut: {},//输出映射
  btnLoading: false
};

const subTbAttrPrefix = '__subAttr__';

const reducer = (state: any, action: any) => {
  const {type, payload} = action;
  switch (type) {
    case 'acOpen':
      return {...state, open: payload};
    case 'acMapping':
      return {...state, mapping: payload};
    case 'acServiceList':
      return {...state, serviceList: payload};
    case 'acPageElement':
      return {...state, pageElement: payload};
    case 'acMappingInList':
      return {...state, mappingInList: payload};
    case 'acMappingOutList':
      return {...state, mappingOutList: payload};
    case 'acMappingIn':
      return {...state, mappingIn: payload};
    case 'acMappingOut':
      return {...state, mappingOut: payload};
    case 'acBtnLoading':
      return {...state, btnLoading: payload};
    default:
      return state;
  }
};

function PopupMapping(props: any) {
  const {isOpenPopup, openPopupRecord, updateIsOpenPopup, refreshTable} = props;
  const [state, dispatch] = useReducer(reducer, initState);
  const globalDispatch = useDispatch();
  const cacheServiceList = useRef([]);//缓存服务列表
  const saveParams = useRef<any>({});
  const [form] = Form.useForm();
  const reqValues = useRef({});//缓存初始入参值
  const resValues = useRef({});//缓存初始出参值
  const isOpenCorrelation = useRef(false);//是否开启自动关联
  const nameToId = useRef<Record<string, string>>({});//缓存页面结构
  const idToLabel = useRef<Record<string, string>>({});//缓存页面结构
  const idToName = useRef<Record<string, string>>({});//缓存页面结构
  const idToTopName = useRef<Record<string, string>>({});//缓存页面结构
  const [modalList, setModalList] = useState<{
    widgetName: string,
    widgetId: string
  }[]>([])
  const cacheSaveParams = (key: string, values: any) => {
    saveParams.current = {
      ...saveParams.current,
      [key]: values
    };
  };
  const reset = () => {
    form.resetFields();
    dispatch({
      type: 'acPageElement',
      payload: []
    })
    //清除映射
    setMappingListOpt(null);
  }
  const closePopup = () => {
    updateIsOpenPopup(false);
    refreshTable&&refreshTable()
    reset();
  };
  const _context = `__context`;
  const _constant = `__constant`;
  //值回显处理方法
  const clearInitMapping = (obj: any) => {
    let newObj: any = {};
    _.each(obj, (value: any, key: any) => {
      let _v = value.split('.');
      if (_.isEmpty(_v)) {
        return true;
      }
      _v = _v.splice(2);
      _v.pop();

      _v = _v.join('.');
      let params: any = {};
      if (key.startsWith(_context)) {
        params.type = '__context';
        params.contextValue = key.split(popup_mapping_mark).splice(-1).join();
      } else if (key.startsWith(_constant)) {
        params.type = '__constant';
        params.constantValue = key.split(popup_mapping_mark).splice(-1).join();
      } else {
        params.type = '__page';
        params.pageValue = key;
        //通过组件key转化为widgetLabel
        let _name = idToName.current[key]?.split('.')?.[0];
        let _label = idToLabel.current[key];
        params.pageLabel = `${_name}${_label ? ('.' + _label) : ''}`;
      }

      let isSubTableAttr = false;
      let keyArr = key.split('.');
      let _vArr = _v.split('.');
      let o2mAttr, o2mItemValue, o2mItemLabel;
      if (_vArr.indexOf('o2m') == 1 && keyArr.length >= 3) {
        isSubTableAttr = true;
        if (keyArr.length == 3) {
          o2mItemLabel = keyArr[2];
        } else if (keyArr.length == 4) {
          o2mItemLabel = [keyArr[2], keyArr[3]];
        }
        o2mAttr = _vArr[0];
        o2mItemValue = _vArr[2];
      }

      if (isSubTableAttr) {
        let o2mObj = newObj[o2mAttr][newObj[o2mAttr].length - 1];
        o2mObj[o2mItemValue] = o2mItemLabel;
      } else {
        if (newObj[_v]) {
          newObj[_v].push(params)
        } else {
          newObj[_v] = [params];
        }
      }
    })
    return newObj;
  }
  useEffect(() => {
    if (!_.isEmpty(openPopupRecord) && isOpenPopup) {
      Api.get(`/byk/platform/rest/AppAction/list`, {id: openPopupRecord.actionId})
        .then((resAction: any) => {
          if (resAction.success) {
            const result = resAction.result?.[0];
            if (!result) {
              return;
            }
            //初始值--表单
            result.serviceId && form.setFieldValue('serviceId', result.serviceId);
            let requestFieldMappings = _.isEmpty(result.requestFieldMappings) ?
              {} : JSON.parse(result.requestFieldMappings);
            let responseFieldMappings = _.isEmpty(result.responseFieldMappings) ?
              {} : JSON.parse(result.responseFieldMappings);
            const pageFieldsFormat = eval("(" + result.pageFieldsFormat + ")").dsl.children;

            let _modals:any = [];
            const modalsFn = (childs:any)=>{
              _.each(childs, (i:any)=>{
                if(i.type === 'MODAL_WIDGET'){
                  _modals.push({
                    widgetName: i.widgetName,
                    widgetId: i.widgetId
                  })
                }
                i.children&&modalsFn(i.children);
              })
            }
            modalsFn(pageFieldsFormat);
            setModalList(_modals);

            let _nameToId: any = {};
            let _idToName: any = {};
            let _idToLabel: any = {};
            let _idToTopName: any = {};
            let _proList: any = [];
            const filterType = ['TABLE_WIDGET_ACTION_COLUMNS'];
            const subTableSelectCol:any = {};
            const pageFn = (pageData: any) => {
              for (let key in pageData) {
                let i:any = pageData[key];
                if (filterType.includes(i.type)) {
                  return true;
                }
                _nameToId[i.widgetName] = i.widgetId;
                _idToName[i.widgetId] = i.widgetName;
                _idToTopName[i.widgetId] = (!i.topWidgetName || i.topWidgetName === 'null') ? '' : i.topWidgetName;
                let widgetNamePoint = i.widgetName.split('.');
                let _label = i.label ?? '';
                if ((!i.label || i.label === 'null' || i.label.startsWith('{{')) && widgetNamePoint.length > 1) {
                  _label = widgetNamePoint.pop();
                }
                _idToLabel[i.widgetId] = _label;

                let _widgetName = i.widgetName;
                if ('SUB_TABLE_WIDGET' == i.type) {
                  let arr = i.widgetName.split('.');
                  _widgetName = arr[arr.length -1];
                  _idToName[i.widgetId] = _widgetName;
                  _idToLabel[i.widgetId] = null;
                  _label = _widgetName;
                } else if ('SUB_TABLE_WIDGET_COLUMNS' == i.type) {
                  if (i.columnType == 'select') {
                    let isValue = true;
                    let _widgetId = i.widgetId.replace("_value", "");
                    if (i.widgetId.endsWith("_label")){
                      isValue = false;
                      _widgetId = i.widgetId.replace("_label", "");
                    }
                    if (!subTableSelectCol[_widgetId]) {
                      subTableSelectCol[_widgetId] = {...i, widgetId:_widgetId, options:{}}
                    }
                    if (isValue) {
                      subTableSelectCol[_widgetId].options.value = i.widgetId;
                    } else {
                      subTableSelectCol[_widgetId].options.label = i.widgetId;
                    }
                    _label = i.topWidgetName + "." +i.label.split('.')[0];
                    if (Object.keys(subTableSelectCol[_widgetId].options).length == 2) {
                      _proList.push({
                        ...subTableSelectCol[_widgetId],
                        widgetId: _widgetId,
                        label: _label,
                        pid: i.parentId,
                        key: _widgetId,
                      })
                    }
                    continue;
                  } else {
                    let arr = i.widgetName.split('.');
                    if (arr.length == 2) {
                      arr[1] = i.label;
                      _label = arr.join('.');
                    }
                  }
                }

                _proList.push({
                  widgetId: i.widgetId,
                  widgetName:_widgetName,
                  topWidgetName: (!i.topWidgetName || i.topWidgetName === 'null') ? '' : i.topWidgetName,
                  label: _label,
                  type: i.type,
                  key: i.key,
                  pid: i.parentId,
                  columnType: i.columnType,
                })
                i.children && pageFn(i.children);
              }
            };
            pageFn(pageFieldsFormat);
            globalDispatch({
              type: X_WIDGET_PROP_POPUP,
              data: {
                propList: _proList
              }
            })
            nameToId.current = _nameToId;
            idToName.current = _idToName;
            idToTopName.current = _idToTopName;
            idToLabel.current = _idToLabel;
            reqValues.current = requestFieldMappings;
            resValues.current = responseFieldMappings;
            Api.get(`/byk/platform/rest/LowcodeService/list`, {tenant: openPopupRecord.tenant})
              .then((resService: any) => {
                if (resService.success) {
                  let list = resService.result;
                  let _l: any = _.map(list, (item: any) => {
                    return {
                      label: item.code + "-【" + item.name + "】",
                      description: item.name,
                      value: item.id,
                      code: item.code,
                      paramObject: item.paramObject,
                      resultObject: item.resultObject,
                      id: item.id,
                    }
                  })
                  dispatch({
                    type: 'acServiceList',
                    payload: _l
                  })
                  cacheServiceList.current = list;
                  serviceChange(result.serviceId, true);
                }
              });
          }
        });
    }

  }, [openPopupRecord.id, isOpenPopup]);

  const setMappingListOpt = (v: any, bol?: boolean) => {
    if (!v) {//服务为空时清除下拉和已设置的映射
      dispatch({
        type: 'acMappingInList',
        payload: []
      })
      dispatch({
        type: 'acMappingOutList',
        payload: []
      })
      return;
    }
    let selectService: any = _.find(cacheServiceList.current, {id: v});
    let inValue: any = {};
    let outValue: any = {};
    if (bol) {
      inValue = clearInitMapping(reqValues.current);
      outValue = clearInitMapping(resValues.current);
    }

    let mappingInList = (selectService?.paramObject || []).filter((i: any) => {
      return i.name != 'includeList' && i.name != 'includeRelated';
    }).map((i: any) => {
      let childrenNode: any = null;
      if (i.children) {
        childrenNode = (i.children || []).map((j: any) => {
          return {
            label: `${j.name}-【${j.description}】`,
            description: j.description,
            value: subTbAttrPrefix + j.name,
            type: j.type,
          }
        })
      }

      return {
        label: `${i.name}-【${i.description}】`,
        description: i.description,
        value: i.name,
        type: i.type,
        children: inValue[i.name] ? inValue[i.name].map((i: any) => {
          return {
            ...i,
            childrenNode,
          }
        }) : null,
        childrenNode,
      }
    })
    let mappingOutList = (selectService?.resultObject || []).map((i: any) => {
      let childrenNode: any = [];
      if (i.children) {
        childrenNode = (i.children || []).map((j: any) => {
          return {
            label: `${j.name}-【${j.description}】`,
            description: j.description,
            value: subTbAttrPrefix + j.name,
            type: j.type,
          }
        })
      }

      return {
        label: `${i.name}-【${i.description}】`,
        description: i.description,
        value: i.name,
        type: i.type,
        children: outValue[i.name] ? outValue[i.name].map((i: any) => {
          return {
            ...i,
            childrenNode,
          }
        }) : null,
        childrenNode,
      }
    })
    dispatch({
      type: 'acMappingInList',
      payload: mappingInList
    })
    dispatch({
      type: 'acMappingOutList',
      payload: mappingOutList
    })
  }
  const getServiceId = () => {
    return form.getFieldValue("serviceId");
  };

  const serviceChange = (v: any, bol?: boolean) => {
    isOpenCorrelation.current = !bol;
    setMappingListOpt(v, bol);
  }
  useEffect(() => {
    if (getServiceId() && isOpenCorrelation.current) {
      isOpenCorrelation.current = false;
      correlation(true);//每次服务变更后，自动映射一次
    }
  }, [state.mappingInList, state.mappingOutList]);

  const reMappingFn = (values: any, serviceId: any) => {
    let obj: any = {};
    if (values) {
      _.each(values, (value: any, key: any) => {
        _.each(value, (i: any) => {
          let _v = `service${serviceId}.service${serviceId}.${i.field}.${i.fieldType}`;
          if (i.type == '__page' && i.pageValue) {
            obj[`${i.pageValue}`] = _v;
            if (i.fieldType == 'o2m' && i.childrenNode) {
              i.childrenNode.forEach((item: any) => {
                let itemValue = i[item.value];
                if (itemValue) {
                  if (itemValue.lastIndexOf('.') > -1) {
                    itemValue = itemValue.substring(itemValue.lastIndexOf('.') + 1, itemValue.length)
                  }
                  let _sv = `${_v}.${item.value}.${item.type}`;
                  if (itemValue.join) {
                    obj[`__subTable.${i.pageValue}.${itemValue.join('.')}`] = _sv
                  } else {
                    obj[`__subTable.${i.pageValue}.${itemValue}`] = _sv
                  }
                }
              })
            }
          }
          if (i.type == '__constant' && i.constantValue) {
            obj[`__constant${popup_mapping_mark}${i.field}${popup_mapping_mark}${i.constantValue}`] = _v;
          }
          if (i.type == '__context' && i.contextValue) {
            obj[`__context${popup_mapping_mark}${i.field}${popup_mapping_mark}${i.contextValue}`] = _v;
          }
        })
      })
    }
    return obj;
  }
  const save = (bool?: boolean) => {
    form.validateFields().then(() => {
      const serviceId = form.getFieldValue("serviceId");
      const {code: serviceCode} = _.find(state.serviceList, {value: serviceId});
      let requestFieldMappings = reMappingFn(saveParams.current.in, serviceId);
      let responseFieldMappings = reMappingFn(saveParams.current.out, serviceId);

      let params: any = {
        id: openPopupRecord.actionId,
        serviceId,
        serviceCode,
        requestFieldMappings: JSON.stringify(requestFieldMappings),
        responseFieldMappings: JSON.stringify(responseFieldMappings)
      };

      dispatch({
        type: 'acBtnLoading',
        payload: true
      })

      Api.put(`/byk/platform/rest/AppAction`, params).then((res: any) => {
        if (res.success) {
          message.success(res.message);
          bool && closePopup();
        }
      }).finally(() => {
        dispatch({
          type: 'acBtnLoading',
          payload: false
        })
      });
    });
  }
  //自动映射规则 表字段的description与组件的label相等时匹配。优先第一个匹配。
  const reValueToKey = (mappingList: any) => {
    const newMappingList = mappingList.map((i: any) => {
      let _i = {...i};
      //先匹配label
      _.each(idToLabel.current, (v, k) => {
        if (v === i.description) {
          let _t = idToName.current[k].split('.')[0];
          let _l = idToLabel.current[k];
          _i = {
            ...i,
            children: [{
              type: "__page",
              pageLabel: _t + (_l ? '.' + _l : ''),
              pageValue: k
            }]
          }
          return false;
        }
      })
      if (!_i.children) {
        //匹配 key
        _.each(nameToId.current, (v, k) => {
          //widgetName 等于 字段名
          if (k === i.value || k.endsWith(`.${i.value}`)) {
            let _t = idToName.current[v].split('.')[0];
            let _l = idToLabel.current[v];
            _i = {
              ...i,
              children: [{
                type: "__page",
                pageLabel: _t + (_l ? '.' + _l : ''),
                pageValue: v
              }]
            }
            return false;
          }
        })
        if (!_i.children) {
          //匹配 label 对 表字段
          _.each(idToLabel.current, (v, k) => {
            //label 与表字段相同
            if (v === i.value) {
              let _t = idToName.current[k].split('.')[0];
              let _l = idToLabel.current[k];
              _i = {
                ...i,
                children: [{
                  type: "__page",
                  pageLabel: _t + (_l ? '.' + _l : ''),
                  pageValue: k
                }]
              }
              return false;
            }
          })
        }
      }
      return _i;
    })
    return newMappingList;
  }
  const correlation = (bol?: boolean, isInput?: boolean) => {
    form.validateFields().then(() => {
      let _cIn: any = [];
      let _cOut: any = [];
      if (bol) {
        _cIn = reValueToKey(state.mappingInList);
        _cOut = reValueToKey(state.mappingOutList);
      } else {
        if (isInput) {
          _cIn = state.mappingInList.map((i: any) => {
            return {
              ...i,
              children: null
            }
          })
        } else {
          _cOut = state.mappingOutList.map((i: any) => {
            return {
              ...i,
              children: null
            }
          })
        }
      }
      const inEqual = _.isEqual(_cIn, state.mappingInList);
      const outEqual = _.isEqual(_cOut, state.mappingOutList);

      if (!inEqual || !outEqual) {
        if (!(bol == false && isInput == false)) {
          dispatch({
            type: 'acMappingInList',
            payload: _cIn
          })
        }

        if (!(bol == false && isInput == true)) {
          dispatch({
            type: 'acMappingOutList',
            payload: _cOut
          })
        }

      }
    })
  }
  const TitleRender = () => {
    return (
      <TitleWrapper>
        <h3 className={`mapping-title`}>服务映射配置</h3>
        {
          openPopupRecord && openPopupRecord.id != -1 && (
            <div className={`mapping-task`}>{openPopupRecord.taskDescription}</div>
          )
        }

      </TitleWrapper>
    )
  }

  return (<>
    <Drawer
      title={<TitleRender/>}
      width={'95%'}
      onClose={closePopup}
      open={isOpenPopup}
      bodyStyle={{padding: 0}}
      extra={
        <Space>
          <Button loading={state.btnLoading} type="primary" onClick={() => {
            save();
          }}>保存</Button>
          <Button loading={state.btnLoading} onClick={() => {
            save(true)
          }}>保存并关闭</Button>
        </Space>
      }
    >
      <MainWrapper>
        <div className={`left`}>
          <Form form={form} style={{padding: '10px 10px 0 10px'}}>
            <Form.Item label={`选择服务`} name="serviceId" rules={[{required: true, message: "请输入!"}]}>
              <Select
                allowClear showSearch
                onChange={(v: any) => {
                  serviceChange(v);
                }}
                placeholder={`请选择服务`}
                filterOption={(input: string, option: any) => {
                  return option.label.indexOf(input) >= 0
                }}
                options={state.serviceList}
              />
            </Form.Item>
          </Form>
          <div style={{padding: '0 10px 10px 10px'}}>
            <Button onClick={() => {
              correlation(true);
            }} type="primary" size="small" style={{width: "auto"}} icon={<ApiOutlined/>}>
              自动映射
            </Button>
            <Button danger onClick={() => {
              correlation(false, true);
            }} size="small" style={{width: "auto", marginLeft: "10px"}} icon={<DisconnectOutlined/>}>
              取消输入映射
            </Button>
            <Button danger onClick={() => {
              correlation(false, false);
            }} size="small" style={{width: "auto", marginLeft: "10px"}} icon={<DisconnectOutlined/>}>
              取消输出映射
            </Button>
          </div>
          <MappingEdit dir={`in`} mapping={state.mappingInList} service={getServiceId()} title={`输入参数映射`}
                       cacheSaveParams={(key: string, values: any) => {
                         cacheSaveParams(key, values);
                       }}/>
          <MappingEdit dir={`out`} isAdd={true} mapping={state.mappingOutList} service={getServiceId()}
                       title={`输出参数映射`} cacheSaveParams={(key: string, values: any) => {
            cacheSaveParams(key, values);
          }}/>
        </div>
        <PopupRightPage key={`key_${isOpenPopup}`} modalList={modalList} isOpenPopup={isOpenPopup}
                        openPopupRecord={openPopupRecord}/>
      </MainWrapper>
    </Drawer>
  </>)
}

export default PopupMapping;
