import styled, { createGlobalStyle } from "styled-components";
import { get, startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import React, {useCallback, useEffect, useState} from "react";

import ThemeCard from "./ThemeCard";
import {
  AppThemingMode,
  getAppThemingStack,
  getSelectedAppTheme,
} from "selectors/appThemingSelectors";
import {
  resetThemeAction,
  setAppThemingModeStackAction,
  updateSelectedAppThemeAction,
} from "actions/appThemingActions";
import SettingSection from "./SettingSection";
import SaveThemeModal from "./SaveThemeModal";
import type { AppTheme } from "entities/AppTheming";
import AnalyticsUtil from "utils/AnalyticsUtil";
import ThemeFontControl from "./controls/ThemeFontControl";
import ThemeColorControl from "./controls/ThemeColorControl";
import { Classes as CsClasses } from "design-system-old";
import {
  Button,
  Menu,
  MenuContent,
  MenuTrigger,
  MenuItem, Tooltip, ToggleButton,
} from "design-system";
import ThemeBoxShadowControl from "./controls/ThemeShadowControl";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import ThemeBorderRadiusControl from "./controls/ThemeBorderRadiusControl";
import { capitalizeFirstLetter } from "utils/helpers";
import ThemeFormControl from "./controls/ThemeFormControl";
import {JS_TOGGLE_DISABLED_MESSAGE} from "../../../ce/constants/messages";
import classNames from "classnames";
import {Input} from "antd";

const THEMING_BETA_CARD_POPOVER_CLASSNAME = `theming-beta-card-popover`;

const SubText = styled.p`
  font-size: var(--ads-v2-font-size-4);
  line-height: 1rem;
  font-weight: var(--ads-v2-font-weight-normal);
  color: var(--ads-v2-color-fg);
`;

const PopoverStyles = createGlobalStyle`
.${THEMING_BETA_CARD_POPOVER_CLASSNAME} .bp3-popover-content {
  padding: 10px 12px;
  border-radius: 0px;
  background-color: #FFF !important;
  color:   #090707 !important;
  box-shadow: none !important;
}

.${THEMING_BETA_CARD_POPOVER_CLASSNAME} .${CsClasses.BP3_POPOVER_ARROW_BORDER},
.${THEMING_BETA_CARD_POPOVER_CLASSNAME} .${CsClasses.BP3_POPOVER_ARROW_FILL} {
  fill: #FFF !important;
  stroke: #FFF !important;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 20%), 0px 2px 10px rgb(0 0 0 / 10%);
}
`;

const SectionTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #364252;
`;

const SectionTitle2 = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #364252;
  padding-left: 16px;
  border-top: 1px solid #CDD5DF;
  margin-top: 13px;
  padding-top: 7px;
`;

const ToggleButtonWrapper = styled(ToggleButton)<{
  top:any, left:any, position?:any
}>`
  margin-left: 2px;
   ${({position}) => position && `
      position: ${position}
   `};
  top: ${({top}) => top};
  left: ${({left}) => left};
`

export const ThemeInputJsWrapper = styled(Input)`
  height: 36px;
  border: 1px solid var(--ads-v2-color-border);
  border-radius: 4px;
  background: rgb(255, 255, 255);
  color: rgb(75, 72, 72);

  &:hover, &:focus {
    border-color: var(--ads-v2-color-border-emphasis);
    box-shadow: none;
  }
`
const ButtonWrapper = styled(Button)`
  div {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
`

enum ThemeWithJsEnum {
  APP_BOX_SHADOW = "appBoxShadow",
  APP_BORDER_RADIUS ="appBorderRadius",
  FORM_LABEL_FONT_COLOR ="fontColor1",
  FORM_LABEL_FONT_SIZE ="fontSize1",
  FORM_LABEL_FONT_STYLE ="fontStyle1",
  FORM_INPUT_TEXT_FONT_COLOR ="fontColor2",
  FORM_INPUT_TEXT_FONT_SIZE ="fontSize2",
  FORM_INPUT_TEXT_FONT_STYLE ="fontStyle2",
  FORM_INPUT_BORDER_BG_COLOR ="bgColor",
  FORM_INPUT_BORDER_BG_BORDER_COLOR ="bgBorderColor",
}

function ThemeEditor() {
  const dispatch = useDispatch();
  const applicationId = useSelector(getCurrentApplicationId);
  const selectedTheme = useSelector(getSelectedAppTheme);
  const themingStack = useSelector(getAppThemingStack);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [dynamicFlag, setDynamicFlag] = useState<any>({});

  /**
   * customizes the current theme
   */
  const updateSelectedTheme = useCallback(
    (theme: AppTheme) => {
      AnalyticsUtil.logEvent("APP_THEMING_CUSTOMIZE_THEME", {
        themeId: theme.id,
        themeName: theme.name,
      });

      dispatch(updateSelectedAppThemeAction({ applicationId, theme }));
    },
    [updateSelectedAppThemeAction],
  );

  /**
   * sets the mode to THEME_EDIT
   */
  const onClickChangeThemeButton = useCallback(() => {
    AnalyticsUtil.logEvent("APP_THEMING_CHOOSE_THEME");

    dispatch(
      setAppThemingModeStackAction([
        ...themingStack,
        AppThemingMode.APP_THEME_SELECTION,
      ]),
    );
  }, [setAppThemingModeStackAction]);

  /**
   * open the save modal
   */
  const onOpenSaveModal = useCallback(() => {
    AnalyticsUtil.logEvent("APP_THEMING_SAVE_THEME_START");

    setSaveModalOpen(true);
  }, [setSaveModalOpen]);

  /**
   * on close save modal
   */
  const onCloseSaveModal = useCallback(() => {
    setSaveModalOpen(false);
  }, [setSaveModalOpen]);

  /**
   * resets theme
   */
  const onResetTheme = useCallback(() => {
    dispatch(resetThemeAction());
  }, [dispatch, resetThemeAction]);

  const getCapitalizeFirstLetterDesc = (name:any) => {
    let desc = capitalizeFirstLetter(startCase(name));
    return desc == "App font" ? "应用字体" : desc;
  }

  useEffect(() => {
    let isDynamic:any = {};
    (selectedTheme.properties.dynamicPropertyPathList || []).forEach(item => {
      isDynamic[item] = true;
    });
    setDynamicFlag({...isDynamic});
  }, [selectedTheme]);

  const toggleDynamicProperty = (key:any, isDynamic:boolean) => {
    let _dynamicPropertyPathList:string[] = [...selectedTheme.properties.dynamicPropertyPathList];
    if (isDynamic) {
      if (_dynamicPropertyPathList.includes(key)) {
        _dynamicPropertyPathList.splice(_dynamicPropertyPathList.indexOf(key), 1);
      }
    } else {
      if (!_dynamicPropertyPathList.includes(key)) {
        _dynamicPropertyPathList.push(key);
      }
    }

    if (key == ThemeWithJsEnum.APP_BOX_SHADOW) {
      updateSelectedTheme({
        ...selectedTheme,
        properties: {
          ...selectedTheme.properties,
          dynamicPropertyPathList: [..._dynamicPropertyPathList],
          boxShadow: {
            ...selectedTheme.properties.boxShadow,
            appBoxShadow: '',
          }
        }
      })
    } else if (key == ThemeWithJsEnum.APP_BORDER_RADIUS) {
      updateSelectedTheme({
        ...selectedTheme,
        properties: {
          ...selectedTheme.properties,
          dynamicPropertyPathList: [..._dynamicPropertyPathList],
          borderRadius: {
            ...selectedTheme.properties.borderRadius,
            appBorderRadius: '',
          }
        }
      })
    } else {
      let formType:"label" | "inputText" | "inputBorder" = 'inputBorder';
      let typeKey = key;
      if (key == ThemeWithJsEnum.FORM_LABEL_FONT_COLOR
        || key == ThemeWithJsEnum.FORM_LABEL_FONT_SIZE || key == ThemeWithJsEnum.FORM_LABEL_FONT_STYLE) {
        formType = 'label';
        typeKey = key.substring(0, key.length - 1);
      } else if (key == ThemeWithJsEnum.FORM_INPUT_TEXT_FONT_COLOR
        || key == ThemeWithJsEnum.FORM_INPUT_TEXT_FONT_SIZE || key == ThemeWithJsEnum.FORM_INPUT_TEXT_FONT_STYLE) {
        formType = 'inputText';
        typeKey = key.substring(0, key.length - 1);
      }

      updateSelectedTheme({
        ...selectedTheme,
        properties: {
          ...selectedTheme.properties,
          dynamicPropertyPathList: [..._dynamicPropertyPathList],
          form: {
            ...selectedTheme.properties.form,
            [formType]: {
              ...selectedTheme.properties.form[formType],
              [typeKey]: '',
            }
          }
        }
      });
    }

    setDynamicFlag({...dynamicFlag, [key]:!isDynamic});
  }

  const buildJsToolTip = useCallback((key:string, top:string, left:string, position?: string) => {
      return <Tooltip
        content={JS_TOGGLE_DISABLED_MESSAGE}
      >
        <ToggleButtonWrapper
          left={left}
          top={top}
          position={position}
          className={classNames("t--js-toggle", {
            "is-active": !!dynamicFlag[key],
          })}
          icon="js-toggle-v2"
          isSelected={ !!dynamicFlag[key]}
          onClick={() => toggleDynamicProperty(key, !!dynamicFlag[key])}
          size="sm"
        />
      </Tooltip>
    }, [dynamicFlag]);

  return (
    <>
      <header className="px-4 space-y-2">
        <div className="flex items-center justify-between">
          <SectionTitle>当前主题</SectionTitle>
          <div>
            <Menu>
              <MenuTrigger>
                <Button
                  isIconButton
                  kind="tertiary"
                  size="md"
                  startIcon="context-menu"
                />
              </MenuTrigger>
              <MenuContent align="end" className="t--save-theme-menu">
                <MenuItem onClick={onOpenSaveModal} startIcon="save">
                  保存主题
                </MenuItem>
                <MenuItem onClick={onResetTheme} startIcon="arrow-go-back">
                  重置主题
                </MenuItem>
              </MenuContent>
            </Menu>
          </div>
        </div>
        <ThemeCard theme={selectedTheme}>
          <aside
            className={`absolute left-0 top-0 bottom-0 right-0 items-center justify-center hidden group-hover:flex  backdrop-filter bg-gray-900 bg-opacity-50 backdrop-blur-sm `}
          >
            <ButtonWrapper
              className="t--change-theme-btn"
              onClick={onClickChangeThemeButton}
              size="md"
            >
              选择主题
            </ButtonWrapper>
          </aside>
        </ThemeCard>
      </header>
      <main className="mt-1">
        <SectionTitle2>主题样式</SectionTitle2>
        {/* FONT  */}
        <SettingSection className="px-4 py-3" isDefaultOpen title="字体">
          {Object.keys(selectedTheme.config.fontFamily).map(
            (fontFamilySectionName: string, index: number) => {
              return (
                <section className="space-y-2" key={index}>
                  <SubText>
                    {getCapitalizeFirstLetterDesc(startCase(fontFamilySectionName))}
                  </SubText>
                  <ThemeFontControl
                    options={get(
                      selectedTheme,
                      `config.fontFamily.${fontFamilySectionName}`,
                      [],
                    )}
                    sectionName={fontFamilySectionName}
                    selectedOption={get(
                      selectedTheme,
                      `properties.fontFamily.${fontFamilySectionName}`,
                    )}
                    theme={selectedTheme}
                    updateTheme={updateSelectedTheme}
                  />
                </section>
              );
            },
          )}
        </SettingSection>
        {/* COLORS */}
        <SettingSection
          className="px-4 py-3 border-t"
          isDefaultOpen
          title="颜色"
        >
          <section className="space-y-2">
            <ThemeColorControl
              theme={selectedTheme}
              updateTheme={updateSelectedTheme}
            />
          </section>
        </SettingSection>

        {/* BUTTONS */}
        {/*<SettingSection*/}
        {/*  className="px-4 py-3 border-t"*/}
        {/*  isDefaultOpen*/}
        {/*  title="按钮"*/}
        {/*>*/}
        {/*  <section className="space-y-2">*/}
        {/*    <ThemeButtonControl*/}
        {/*      theme={selectedTheme}*/}
        {/*      updateTheme={updateSelectedTheme}*/}
        {/*    />*/}
        {/*  </section>*/}
        {/*</SettingSection>*/}

        {/* TABLE */}
        <SettingSection
          className="px-4 py-3 border-t"
          isDefaultOpen
          title="表单组件"
        >
          <section className="space-y-2">
            <ThemeFormControl
              theme={selectedTheme}
              updateTheme={updateSelectedTheme}
              buildJsToolTip={buildJsToolTip}
              dynamicFlag={dynamicFlag}
            />
          </section>
        </SettingSection>

        {/* BORDER RADIUS */}
        <SettingSection
          className="px-4 py-3 border-t "
          isDefaultOpen
          title="边框圆角"
        >

          {buildJsToolTip(ThemeWithJsEnum.APP_BORDER_RADIUS, "-16px", "66px", "absolute")}

          {dynamicFlag[ThemeWithJsEnum.APP_BORDER_RADIUS] && <>
            <ThemeInputJsWrapper
              defaultValue={selectedTheme.properties.borderRadius.appBorderRadius}
              key={ThemeWithJsEnum.APP_BORDER_RADIUS}
              onBlur={(e) => {
                updateSelectedTheme({
                  ...selectedTheme,
                  properties: {
                    ...selectedTheme.properties,
                    borderRadius: {
                      ...selectedTheme.properties.borderRadius,
                      appBorderRadius: e.target.value,
                    },
                  },
                });
              }}/>
          </>
          }

          {!dynamicFlag[ThemeWithJsEnum.APP_BORDER_RADIUS] &&  Object.keys(selectedTheme.config.borderRadius).map(
            (borderRadiusSectionName: string, index: number) => {
              return (
                <section className="space-y-2" key={index}>
                  <ThemeBorderRadiusControl
                    options={get(
                      selectedTheme,
                      `config.borderRadius.${borderRadiusSectionName}`,
                      {},
                    )}
                    sectionName={borderRadiusSectionName}
                    selectedOption={get(
                      selectedTheme,
                      `properties.borderRadius.${borderRadiusSectionName}`,
                    )}
                    theme={selectedTheme}
                    updateTheme={updateSelectedTheme}
                  />
                </section>
              );
            },
          )}
        </SettingSection>

        {/* BOX SHADOW */}
        <SettingSection
          className="px-4 py-3 border-t "
          isDefaultOpen
          title="阴影"
        >
          {buildJsToolTip(ThemeWithJsEnum.APP_BOX_SHADOW, "-16px","34px", "absolute")}

          {dynamicFlag[ThemeWithJsEnum.APP_BOX_SHADOW] && <>
            <ThemeInputJsWrapper
              defaultValue={selectedTheme.properties.boxShadow.appBoxShadow}
              key={ThemeWithJsEnum.APP_BOX_SHADOW}
              onBlur={(e) => {
              updateSelectedTheme({
                ...selectedTheme,
                properties: {
                  ...selectedTheme.properties,
                  boxShadow: {
                    ...selectedTheme.properties.boxShadow,
                    appBoxShadow: e.target.value,
                  },
                },
              });
            }}/>
          </>
          }

          {!dynamicFlag[ThemeWithJsEnum.APP_BOX_SHADOW] && Object.keys(selectedTheme.config.boxShadow).map(
            (boxShadowSectionName: string, index: number) => {
              return (
                <section key={index}>
                  <ThemeBoxShadowControl
                    options={get(
                      selectedTheme,
                      `config.boxShadow.${boxShadowSectionName}`,
                      {},
                    )}
                    sectionName={boxShadowSectionName}
                    selectedOption={get(
                      selectedTheme,
                      `properties.boxShadow.${boxShadowSectionName}`,
                    )}
                    theme={selectedTheme}
                    updateTheme={updateSelectedTheme}
                  />
                </section>
              );
            },
          )}
        </SettingSection>
      </main>

      <SaveThemeModal isOpen={isSaveModalOpen} onClose={onCloseSaveModal} />
      <PopoverStyles />
    </>
  );
}

export default ThemeEditor;
