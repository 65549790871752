import {NotebookStepUiComponentProps} from "@byk/pages/QueryBuilder/components/notebook/types";
import React from "react";
import {NotebookCell} from "../../NotebookCell";
import LimitInput from "@byk/pages/QueryBuilder/components/ui/LimitInput";

const LimitStep: React.FC<NotebookStepUiComponentProps> =
  ({
     step,
     color,
     query,
     updateQuery,
   }: NotebookStepUiComponentProps) => {

    const {stageIndex} = step;

    const limit = query.limit();
    const value = typeof limit === "number" ? limit : "";

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextLimit = parseInt(e.target.value, 0);
      if (nextLimit >= 1) {
        await updateQuery(query.updateLimit(nextLimit));
      }
    };

    return (
      <NotebookCell color={color}>
        <LimitInput
          className="mb1"
          type="number"
          value={value}
          placeholder={"输入一个限制"}
          small
          onChange={handleChange}
        />
      </NotebookCell>
    );

  };
export default LimitStep;
