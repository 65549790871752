import _ from "lodash";
import {StateVK} from "@byk/pages/Track/constant";
import {issuesApi} from "@byk/pages/Track/trackApi";

const getOptionsByAlias = (project: any, aliases: any): any[] => {
  let _options: any = []
  if (project && project.fields) {
    let typeField: any = _.find(project.fields, (item: any) => item.field.aliases == aliases);
    if (typeField && typeField.bundle && typeField.bundle.values) {
      _options = typeField.bundle.values.map((item: any) => {
        return {
          value: item.name,
          label: item.name,
          data: item,
        }
      })
    }
  }

  return _options;
}

const getOptionsByName = (project: any, name: any): any[] => {
  let _options: any = []
  if (project && project.fields) {
    let isState = name == 'State';
    let stateField: any = _.find(project.fields, (item: any) => item.field.name == 'State');
    if (stateField && stateField.bundle && stateField.bundle.values) {
      _options = stateField.bundle.values.map((item: any) => {
        return {
          value: isState ? StateVK[item.name] : item.name,
          label: isState ? StateVK[item.name] : item.name,
          data: item,
        }
      })
    }
  }

  return _options;
}

const convertIssueFormData = (issueDetail: any) => {
  let files: any = getFileList4Attachments(issueDetail.attachments);

  return {
    id: issueDetail.id,
    summary: issueDetail.summary,
    description: issueDetail.description,
    state: issueDetail.state,
    type: issueDetail.type,
    mobile: issueDetail.mobile,
    files,
  }
}

const getFileList4Attachments = (attachments: any) => {
  let files: any = [];
  if (attachments) {
    attachments.forEach((item: any) => {
      let url = item.url;
      let thumbUrl = item.thumbnailURL;
      if (!url.startsWith('http')) {
        url = issuesApi.baseUrl + url;
        thumbUrl = issuesApi.baseUrl + thumbUrl;
      }
      files.push({
        id: item.id,
        uid: item.id,
        name: item.name,
        url: url,
        thumbUrl: thumbUrl,
      })
    })
  }

  return files;
}

const getIssueDetailData = async (issue: any) => {
  let issueDetail: any = await issuesApi.getIssueDetail(issue.id) || {}
  let comments: any = await issuesApi.getActivitiesPage(issue.id) || [];
  issueDetail.type = issue.type;
  issueDetail.state = issue.state;
  issueDetail.mobile = issue.mobile;
  issueDetail.comments = comments.reverse();
  return issueDetail;
}

export const model = {
  getOptionsByAlias,
  getOptionsByName,
  convertIssueFormData,
  getFileList4Attachments,
  getIssueDetailData,
}
