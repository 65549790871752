import React from "react";
import TableList from "@byk/pages/components/TableList";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, message } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import localStorage, {getTenantId} from "utils/localStorage";
import history from "utils/history";
import { CheckCircleOutlined } from '@ant-design/icons';
import _ from "lodash";
import {appTenantClass} from "@byk/utils/Utils";

interface DataType {
  id: React.Key;
}

class RoleManagerList extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  tableAddRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.tableAddRef = React.createRef();
    //this.focusTextInput = this.focusTextInput.bind(this);
  }

  state = {
    roleId: -1,
    tenantId: getTenantId(),
    visibleLoadTableModal: false,
    initParams: {
      includeRelated: true,
      includeList: true,
      tenantId: getTenantId(),
      status: "activing"
    },
    isAdmin:this.props.isAdmin
  };


  openLoadTableModal = () => {
    this.setState({
      visibleLoadTableModal: true
    });
  };
  async componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.init();
  };
  callback(data: any) {
    console.log('执行回调', data);
    this.setState({ roleId: data.id }, () => {
      this.init();
    });

  };

  async init() {
    let params: any = {};
    params.includeRelated = true;
    params.includeList = true;
    params.tenantRole = this.state.roleId;
    params.tenantId = this.state.tenantId;
    if (this.tableRef.current) {
      this.tableRef.current.getTableList(params);
    }
  };

  render() {
    // api
    const apiGet: string = "/byk/platform/rest/UserTenant/list";
    const apiPost: string = "/byk/platform/rest/UserTenant";
    const apiPut: string = "/byk/platform/rest/UserTenant";
    const apiDel: string = "/byk/platform/rest/UserTenant";
    const apiUserGet: string = "/byk/platform/rest/SysUser/list";
    //表格refresh
    const refreshTable = (params: any = {}) => {
      // params.includeRelated = true;
      // params.includeList = true;
      // if (this.tableRef.current) {
      //   this.tableRef.current.getTableList(params);
      // }
    };
    //const formRef = useRef<any>();
    const showDrawer = () => {
      this.setState({ open: true });
    };

    const onClose = () => {
      this.setState({ open: false });
    };
    //保存成功后
    const afterSaveEvent = (params?: any) => {
      let _r = params.result[0];
      Modal.success({
        title: '保存成功',
        icon: <CheckCircleOutlined />,
        content: '',
        okText: '服务编排',
        cancelText: '返回列表',
        onOk() { redirectUrl(_r) },
      });
    };
    //改造提交的data
    const reformData = (params?: any, type?: any) => {
      return params;
    };
    const redirectUrl = (record: any) => {
      history.push(`/ServiceSetup?id=${record.id}`);
    };
    //状态变更
    const UpdateStatus = async (record: any, action: any) => {
      let status = null;
      if (action == 1) {
        status = "inProgress";
      }
      if (action == 4) {
        let result: any = await Api.get(apiActionGet, { id: record.actionId });
        let serviceCode = null;
        if (result.success) {
          if (result.result && result.result.length > 0) {
            let actionInfo = result.result[0];
            serviceCode = actionInfo.serviceCode;
          }
          else {
            serviceCode = "";
          }
        }
        this.refs.formRef.resetFields();
        this.setState({ initialValues: { id: record.actionId, serviceCode: serviceCode }, actionId: record.actionId })
        showDrawer();
      }
      let result: any = await Api.put(apiPost, { id: record.id, status: status });
      if (result.success) {
        refreshTable();
      }
    };
    const onFinish = async (values: any) => {
      console.log("values", values)
      values.id = this.state.actionId;
      let result1: any = await Api.put(apiActionPost, values);;
      if (result1.success) {
        message.success("保存成功!");
        onClose();
        refreshTable();
      }
    };

    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let data:any={id:record.id,tenantRole:null}
      let result: any = await Api.put(apiPut, data);
      if (result.success) {
        this.init();
      }
    };

    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 80,
      },

      {
        title: "姓名",
        dataIndex: "userName",
        key: "userName",
        width: 150,
      },
      {
        title: "角色",
        dataIndex: "tenantRoleName",
        key: "tenantRoleName",
        width: 150,
      },
      {
        title: "备注名",
        dataIndex: "alias",
        key: "alias",
      },
      {
        title: "手机号",
        dataIndex: "userMobile",
        key: "userMobile",
        width: 120,
      },
      {
        title: "邮箱",
        dataIndex: "userEmail",
        key: "userEmail",
        width: 200,
      }
    ];

    const columnsIsAdmin: ColumnsType = [
      {
        title: "操作1",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Popconfirm placement="left" title={"确定移除该成员吗？"} onConfirm={() => deleteClick(record)} okText="确定" cancelText="取消">
                <Button type="link" style={{ color: "red" }}>
                  移除成员
                </Button>
              </Popconfirm>
          </Space>
        ),
        width: 100,
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      getCheckboxProps: (record: DataType) => ({
        // disabled: record.name === 'Disabled User', // Column configuration not to be checked
        // name: record.name,
      }),
    };

    const tableProps = {
      // columns:this.state.isAdmin? _.concat(columns, columnsIsAdmin):columns,
      columns:columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      rowSelection: {
        type: "checkbox",
        ...rowSelection,
      },
      initParams: this.state.initParams,
    };

    //添加成员
    //表格配置
    const columns1: ColumnsType = [
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "邮箱",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
      }
    ];
    const tableProps1 = {
      columns: columns1,
      api: apiUserGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
    };
    const handleOk = () => {

    }

    const handleCancel = () => {
      this.setState({ visibleLoadTableModal: false });
    }
    return (
      <>
        <div className="x-main-box">

          <div style={{ width: "100%" }}>
            {/* <Button className="x-fr" style={{ marginTop: "-75px", marginRight: "100px" }}>
              移除
            </Button> */}

            {/* <Button type="primary" onClick={() => this.openLoadTableModal()} className="x-fr x-ml20" style={{ marginTop: "-65px" }}>
              添加成员
            </Button> */}
            </div>
          <TableList ref={this.tableRef} {...tableProps} />

          <Modal title="添加成员" cancelText="取消" okText="确定" width="90%" open={this.state.visibleLoadTableModal} onOk={handleOk} onCancel={handleCancel}>
            <TableList ref={this.tableAddRef} {...tableProps1} />
          </Modal>
        </div>
      </>
    );
  }
}
export default withRouter(RoleManagerList);
