import {Layout, Modal} from "antd";
import React, {useRef, useState} from "react";
import TableList from "@byk/pages/components/TableList";
import {Content} from "antd/lib/layout/layout";
import SearchForm from "@byk/pages/components/SearchForm";
import styled from "styled-components";
import {modalProps, searchProps, tableProps} from "./config";
import Api from "@byk/api/Api";
import {Dependency_CRUD_URL} from "@byk/pages/PackageManager/api";
import XModal from "@byk/pages/components/XModal";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }
`

const PackageManager = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<any>();
  const modalRef = useRef<any>();
  const [isOpen, setIsOpen] = useState<any>(false);

  function showMsg(record: any) {
    setIsOpen(true);
  }

  // 表格refresh
  const doRefreshTable = (searchData?: any) => {
    tableRef.current?.getTableList({...searchData});
  }

  const editClick = async (record: any) => {
    modalRef.current?.edit(record);
  };

  const deleteClick = async (record: any) => {
    let {id} = record;
    let result: any = await Api.delete(Dependency_CRUD_URL, [id]);
    if (result.success) {
      doRefreshTable();
    }
  };

  return (
    <>
      <Layout>
        <Content style={{
          padding: 24,
          margin: 0,
          height: "100%",
        }}>
          <div style={{marginTop: "8px"}}>
            <SearchForm ref={searchRef} {...searchProps(doRefreshTable)} />
            <XModal ref={modalRef} {...modalProps(doRefreshTable)} />
            <TableList ref={tableRef} {...tableProps(editClick, deleteClick)} />
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default PackageManager;
