import _ from "lodash";
import widgetTaroToValue from "./widgetTaroToValue";
export const mapping = '.mapping.';
export interface regType {
  widgetTypeMap: string,
  widgetType: string,
  key: string,
  event: string,
  widgetTree: any,
  widgetId: any,
  widgetData: any,
  widgetDataSize: number,
}

const fn:any = {
  ...widgetTaroToValue,
  'CHECKBOX_GROUP_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData, event, widgetType, widgetId } = regs;
    if(widgetType === 'CHECKBOX_GROUP_WIDGET'&&(event==='onLoad'||event==='onDropdownOpen')){
      return [{
        widgetId,
        propertyName: 'options',
        propertyValue: widgetData||[]
      }]
    }else{
      let _key = key.substring(key.lastIndexOf('.')+1);//属性key
      let _widgetName = key.substring(0, key.indexOf('.'));
      const _keyToProp:Record<string, string> = {
        'value': 'selectedValues',
        'label': 'selectedLabels'
      }
      return [{
        widgetId: widgetTree[_widgetName]?.widgetId,
        propertyName: _keyToProp?.[_key],
        propertyValue: widgetData[0]?.[_widgetName]
      }]
    }
  },
  'TEXT_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'text',
      propertyValue: widgetData[0]?.[key]??""
    }]
  },
  'INPUT_WIDGET_V2': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'inputText',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'SELECT_WIDGET': (regs:regType)=>{
    const {widgetType, key, event, widgetId, widgetTree, widgetData} = regs;
    if(widgetType === 'SELECT_WIDGET'&&(event==='onLoad'||event==='onDropdownOpen')){
      return [{
        widgetId,
        propertyName: 'options',
        propertyValue: widgetData||[]
      }]
    }else{
      let _key = key.substring(key.lastIndexOf('.')+1);//属性key
      let _widgetName = key.substring(0, key.indexOf('.'));
      const _keyToProp:Record<string, string> = {
        'value': 'selectedOptionValue',
        'label': 'selectedOptionLabel'
      }
      return [{
        widgetId: widgetTree[_widgetName]?.widgetId,
        propertyName: _keyToProp?.[_key],
        propertyValue: widgetData[0]?.[key]||""
      }]
    }
  },
  'MULTI_SELECT_WIDGET_V2': (regs:regType)=>{
    const {widgetType, key, event, widgetId, widgetTree, widgetData} = regs;
    let _widgetName = key.substring(0, key.indexOf('.'));
    if(widgetType === 'MULTI_SELECT_WIDGET_V2'&&(event==='onLoad'||event==='onDropdownOpen')){
      let options:any = [];
      if(widgetData&&widgetData.map){
        options = widgetData.map((i:any)=>{
          return {
            label: _.isArray(i[`${_widgetName}.label`])?i[`${_widgetName}.label`].join(''):i[`${_widgetName}.label`],
            value: _.isArray(i[`${_widgetName}.value`])?i[`${_widgetName}.value`].join(''):i[`${_widgetName}.value`],
          }
        })
      }
      return [{
        widgetId,
        propertyName: 'options',
        propertyValue: options
      }]
    }else{
      let _widgetId = widgetTree[_widgetName]?.widgetId;
      let _singleData = widgetData[0];
      let _labels:any = _singleData[`${_widgetName}.label`];
      let _values:any = _singleData[`${_widgetName}.value`];
      if(_.isEmpty(_labels)||_.isEmpty(_values)){
        return [];
      }
      let selectedOptions:any = [];
      _.each(_values, (v,idx)=>{
        selectedOptions.push({
          value: v,
          id: v,
          label: _labels[idx]
        })
      })
      return [{
        widgetId: _widgetId,
        propertyName: 'selectedOptions',
        propertyValue: selectedOptions||[]
      }]
    }
  },
  'CASCADER_WIDGET': (regs:regType)=>{
    const {widgetType, key, event, widgetId, widgetTree, widgetData} = regs;
    if(widgetType === 'CASCADER_WIDGET'&&(event==='onLoad'||event==='onDropdownOpen')){
      return [{
        widgetId,
        propertyName: 'currentData',
        propertyValue: widgetData
      }]
    }else{
      let _widgetName = key.substring(0, key.indexOf('.'));
      let _widgetId = widgetTree[_widgetName]?.widgetId;
      return [{
        widgetId: _widgetId,
        propertyName: 'value',
        propertyValue: widgetData[0]?.[_widgetName]||[]
      }]
    }
  },
  'TABLE_WIDGET_ANTD': (regs:regType)=>{
    const {event, widgetId, widgetData, widgetDataSize, widgetTree} = regs;
    let widgets = _.values(widgetTree);
    let table = _.find(widgets, { widgetId});
    let tableName = table.widgetName;
    let tableData:any = [];
    _.each(widgetData, (i:any)=>{
      let i1:any = {};
      _.each(i, (v, k)=>{
        let field = k;
        if(k.startsWith(tableName)){
          field = k.replace(`${tableName}.`, '');
        }
        i1[field] = v;
      })
      tableData.push(i1);
    })
    if(event === 'onLoad'){
      return [{
        widgetId,
        propertyName: 'tableData',
        propertyValue: tableData
      },{
        widgetId,
        propertyName: 'total',
        propertyValue: widgetDataSize||tableData.length
      }]
    }
  },
  'SUB_TABLE_WIDGET': (regs:regType)=>{
    const {widgetId, widgetData, widgetDataSize, key, widgetTree} = regs;
    let _widgetName = key;
    if(key.indexOf('.')>0){
      _widgetName = _widgetName.substring(0, key.indexOf('.'));
    }
    return [{
      widgetId: widgetTree[_widgetName]?.widgetId,
      propertyName: 'value',
      propertyValue: widgetData[0]?.[_widgetName]||[]
    },{
      widgetId: widgetTree[_widgetName]?.widgetId,
      propertyName: 'total',
      propertyValue: widgetDataSize||widgetData.length
    }]
  },
  'DATE_PICKER_WIDGET2': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'value',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'RANGEDATEPICKER_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'value',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'CHECKBOX_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'isChecked',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'RADIO_GROUP_WIDGET': (regs:regType)=>{
    const {widgetType, key, event, widgetId, widgetTree, widgetData} = regs;
    if(widgetType === 'RADIO_GROUP_WIDGET'&&event==='onLoad'){
      return [{
        widgetId,
        propertyName: 'options',
        propertyValue: widgetData
      }]
    }else{
      let _widgetName = key.substring(0, key.indexOf('.'));
      return [{
        widgetId: widgetTree[_widgetName]?.widgetId,
        propertyName: 'selectedOptionValue',
        propertyValue: widgetData[0]?.[key]||""
      }]
    }
  },
  'SWITCH_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'isSwitchedOn',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'SWITCH_GROUP_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'selectedValuesArray',
      propertyValue: widgetData[0]?.[key]||[]
    }]
  },
  'INPUTNUMBER_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'value',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'SLIDER_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'value',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'AMAP_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'value',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'UPLOAD_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'value',
      propertyValue: widgetData[0]?.[key]||[]
    }]
  },
  'UPLOAD_IMAGE_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'value',
      propertyValue: widgetData[0]?.[key]||[]
    }]
  },
  'X_IMAGE_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    let name = key, value='';
    if(key&&key.indexOf&&key.indexOf(mapping)>0){
      [name, value] = key.split(mapping);
    }
      //description
    return [{
      widgetId: widgetTree[name]?.widgetId,
      propertyName: value,
      propertyValue: widgetData[0]?.[key]||[]
    }]
  },
  'RICH_TEXT_EDITOR_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'text',
      propertyValue: widgetData[0]?.[key]||""
    }]
  },
  'LIST_WIDGET_V2': (regs:regType)=>{
    const { widgetData, widgetId,widgetDataSize } = regs;
    return [{
      widgetId,
      propertyName: 'listData',
      propertyValue: widgetData
    },{
      widgetId,
      propertyName: 'total',
      propertyValue: widgetDataSize||widgetData.length
    }]
  },
  'CAROUSEL_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId } = regs;
    return [{
      widgetId,
      propertyName: 'imageData',
      propertyValue: widgetData
    }]
  },
  'AMAP_VIEW_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData
    }]
  },
  'MARQUEE_WIDGET_MAPPING_CONFIG': (regs:regType)=>{
    const { widgetData, widgetId } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData
    }]
  },
  'DYNAMICFORMS_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData
    }]
  },
  'X_ECHARTS_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId, key } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData?.[0]?.[key]
    }]
  },
  'X_ECHARTS_LINE_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId, key } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData?.[0]?.[key]
    }]
  },
  'X_ECHARTS_RADAR_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId, key } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData?.[0]?.[key]
    }]
  },
  'X_ECHARTS_PIE_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId, key } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData?.[0]?.[key]
    }]
  },
  'X_ECHARTS_GAUGE_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId, key } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData?.[0]?.[key]
    }]
  },
  'X_ECHARTS_FUNNEL_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId, key } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData?.[0]?.[key]
    }]
  },
  'X_ECHARTS_MAP_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId, key } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData?.[0]?.[key]
    }]
  },



  'PHONE_INPUT_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData } = regs;
    let value = widgetData[0]?.[key];
    let text = value?.substring(value.indexOf(')')+1);
    let code = value?.substring(1, value.indexOf(')'));
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'text',
      propertyValue: text||""
    },{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'dialCode',
      propertyValue: code||""
    }]
  },
}

const widgetToValue = (regs: regType)=>{
  const {widgetType, widgetTypeMap, widgetTree, key:widgetName, widgetId, widgetData} = regs;

  if(widgetType === 'LIST_WIDGET_V2'){
    let arr:any = [];
    arr = _.concat(arr, fn[widgetType]?fn[widgetType](regs):[])
    /*if(widgetType !== widgetTypeMap){
      arr = _.concat(arr, fn[widgetTypeMap]?fn[widgetTypeMap](regs):[]);
    }*/
    return arr;//fn[widgetType]?fn[widgetType](regs):[];
  }else if(widgetTypeMap === 'TABLE_WIDGET_ANTD_COLUMNS'){
    return fn['TABLE_WIDGET_ANTD'](regs);
  }else if(widgetTypeMap === 'SUB_TABLE_WIDGET'){
    return fn['SUB_TABLE_WIDGET'](regs);
  }else if(widgetTypeMap?.endsWith('_VALUE')){
    let _type = widgetTypeMap.split('_VALUE')[0];
    return fn[_type](regs);
  }else{
    return fn[widgetTypeMap]?fn[widgetTypeMap](regs):[]
  }
};


export default widgetToValue;
