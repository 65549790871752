//表格配置
import {ColumnsType} from "antd/lib/table";
import moment from "moment";
import {Button, Space} from "antd";
import React from "react";
import {LISE_DEPLOY_URL} from "@byk/pages/AppDeploy/api";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";

/**
 * Table配置项
 * @param deleteCallback
 */
export function tableProps(downloadCallback: any, viewCallback: any, oneKeyDeploy: any) {
  const columns: ColumnsType = [
    {
      title: '发布标题',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    {
      title: '发布版本',
      dataIndex: 'version',
      key: 'version',
      width: '15%',
    },
    {
      title: '发布方式',
      dataIndex: 'deployType',
      key: 'deployType',
      width: '15%',
      render: (_, record: any) => (
        record.deployType == 1 ? '数据发布' : '增量发布'
      ),
    },
    {
      title: '发布人',
      dataIndex: 'deployPerson',
      key: 'deployPerson',
      width: '10%',
    },
    {
      title: '发布应用',
      dataIndex: 'deployApp',
      key: 'deployApp',
      width: '25%',
    },
    {
      title: '发布时间',
      dataIndex: 'deployTime',
      key: 'deployTime',
      width: '10%',
      render: (_, record: any) => (
        moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => viewCallback(record)}>
            详情
          </Button>
          <Button type="link" onClick={() => downloadCallback(record)}>
            下载资源包
          </Button>
          <Button type="link" onClick={() => oneKeyDeploy(record)}>
            一键部署
          </Button>
        </Space>
      ),
      width: 100
    }
  ]

  const tableProps = {
    columns: columns,
    api: LISE_DEPLOY_URL,
    pagination: {
      currPage: 1,
      pageSize: 10
    },
    initParams: {
      appId: ModelingApi.getAppIdRaw()
    }
  }

  return tableProps
}

/**
 * 搜索配置项
 * @param refreshTable
 */
export function searchProps(refreshTable: any) {
  const searchConfig: any[] = [
    {
      itemType: 'input',
      placeholder: '',
      name: 'title',
      label: '发布标题',
      allowClear: true,
      col: 6
    },
    {
      itemType: 'input',
      placeholder: '',
      name: 'version',
      label: '发布版本',
      allowClear: true,
      col: 6
    },
    {
      itemType: 'input',
      placeholder: '',
      name: 'deployType',
      label: '发布方式',
      allowClear: true,
      col: 6
    },
    {
      itemType: 'input',
      placeholder: '',
      name: 'deployPerson',
      label: '发布人',
      allowClear: true,
      col: 6
    },
    {
      itemType: 'DatePicker',
      picker:'datetime',
      placeholder: '',
      name: 'deployTime',
      label: '发布时间',
      allowClear: true,
      col: 6
    },
  ]

  const searchProps = {
    option: searchConfig,
    onSearch: refreshTable,
    btnBoolean: true
  }

  return searchProps
}


