import {select} from "redux-saga/effects";
import {getAppData, getCanvasWidgets} from "../../selectors/entitiesSelector";
import API from "../../api/Api";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../utils/localStorage";
import _ from "lodash";
import {popup_mapping_mark} from "@byk/pages/MyTask/PopupMapping";
import store from "../../store";
import {X_PREVIEW_USER} from "@byk/store/XReducers";
import {ReduxActionTypes} from "../../ce/constants/ReduxActionConstants";
import {getUrlParams} from "@byk/utils/Utils";
import {showAlertFun} from "../../ce/sagas/ActionExecution/XActionExecutionSagas";
import setWidgetValue from "../../ce/sagas/ActionExecution/SetWidgetValue";

function actionIdDict(dictId:any, apiTarget:any, actionId:any, callback?:any){
  API.get(`${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`, {
    dict: dictId,
    enable:true,
    asc: "sort,createDate"
  }).then((res:any)=>{
    const handleDictResult = res.result&&res.result.map((i:any)=>{
      return {
        id: i.id,
        label: i.name,
        value: i.code,
        specialMark: true
      }
    });
    callback&&callback({
      data: handleDictResult||[],
      widgetId: apiTarget,
      event: actionId
    })
  })
}
// @ts-ignore
export function* reEventsActions(actions:any, reqParams:any, callback?:any):Record<string, unknown>{
  const {actionId, dictId, currPage, pageSize, action, apiTarget} = actions;
  const appData = yield select(getAppData);
  if(actionId === 'dict'){
    actionIdDict(dictId, apiTarget, actionId, callback);
    return ;
  }
  let canvasDsl: Record<string, unknown&{ widgetName: string }> = yield select(getCanvasWidgets);
  API.get(`${apiPath}/${getTenant()}/subapp/AppAction`, {
    id: actionId
  }).then((res:any)=>{
    const _action:any = res;
    if(_.isEmpty(_action.result)){
      showAlertFun({
        msg: 'AppAction 数据异常',
        type: 'error'
      })
      return ;
    }
    let {url, requestMsgFields, requestType, serviceCode, responseMsgFields, elementId, pageName, widgetId, widgetName, event} = _action.result?.[0];
    let reqMsgFieldsObj = requestMsgFields&&requestMsgFields!=='null'?JSON.parse(requestMsgFields):[];
    let resMsgFieldsObj = responseMsgFields&&responseMsgFields!=='null'?JSON.parse(responseMsgFields):[];
    let newReqValues = {
      ...reqParams
    };
    let newReqParams:any = {};
    let _page:string[] = [];
    _.each(reqMsgFieldsObj, (i:any)=>{
      if(i.startsWith&&i.startsWith('__')){
        return true;
      }
      let key = i;
      if(_.isObject(i)){
        key = _.keys(i)[0];
      }
      _page.push(key);
    });
    let _context = reqMsgFieldsObj.filter((i:any)=>{
      return i.startsWith&&i.startsWith('__context');
    });
    let _constant = reqMsgFieldsObj.filter((i:any)=>{
      return i.startsWith&&i.startsWith('__constant');
    });
    //匹配入参
    //常量
    if(!_.isEmpty(_constant)){
      _.each(_constant, (i:any)=>{
        newReqParams[i] = i.split(popup_mapping_mark).splice(-1).join();
      })
    }
    //上下文
    if(!_.isEmpty(_context)){
      _.each(_context, (i:any)=>{
        let _iKey = '';
        let _value_arr:any = i.split(popup_mapping_mark);
        let _v2 = _value_arr[2];
        if(_v2.startsWith('User.')){
          _iKey = _v2.replace('User.', 'user.');
        }else if(_v2.startsWith('Url.param.')){
          _iKey = _v2.replace('Url.param.', 'URL.queryParams.');
        }else{
          //
        }
        if(_iKey){
          newReqParams[i] = _.get(appData, `${_iKey}`)
        }

      })
    }
    //页面元素
    if(!_.isEmpty(_page)){
      const reqMapFn = (data:any, obj:any)=>{
        _.each(data, (key:any)=>{
          if(typeof key == 'string'){
            let _key:any = key;
            let _values:any = {
              ...newReqValues,
              ...newReqValues.apiParams
            }
            if(_key.endsWith('currPage')&&requestType=='get'&&currPage){
              obj[key] = currPage;
            }else if(_key.endsWith('pageSize')&&requestType=='get'&&pageSize){
              obj[key] = pageSize;
            }else{
              if(requestType=='put'){
                obj[key] = (_values[_key]===''||_values[_key]===undefined)?null:_values[_key];
              }
              if(requestType!='put'&&_values[_key]!==''&&_values[_key]!==undefined&&_values[_key]!==null){
                obj[key] = _values[_key];
              }
            }
          }else if(typeof key == 'object'){
            _.each(key, (value, valueKey)=>{
              obj[valueKey] = {};
              reqMapFn(value, obj[valueKey]);
            })
          }
        })
      };
      reqMapFn(_page, newReqParams);
    }
    //是否绑定服务得入参处理 动态表单和配置的入参与映射入参做合并
    if(_.isEmpty(serviceCode)){
      newReqParams = {
        ...newReqValues,
        ...newReqValues?.globalContext,
        ...newReqValues?.apiParams
      }
      delete newReqParams.globalContext;
      delete newReqParams.apiParams;
    }else{
      let mappingPageParams:Record<string, unknown> = {};
      //取页面元素入参
      if(!_.isEmpty(_page)){
        _.each(_page, (i:any)=>{
          if(['get', 'exported'].includes(requestType)){
            mappingPageParams[i] = newReqParams[i]??'';
          }else{
            mappingPageParams[i] = newReqParams[i] === ''?null:newReqParams[i];
          }

        })
      }
      //取常量和上下文入参
      _.each(newReqParams, (v:any, k:any)=>{
        if(k.startsWith('__context')||k.startsWith('__constant')){
          mappingPageParams[k] = v;
        }
      })
      if(newReqValues.globalContext){
        mappingPageParams = {
          ...mappingPageParams,
          ...newReqValues.globalContext
        }
      }
      //动作框里的输入参数
      if(newReqValues.eventParams){
        mappingPageParams = {
          ...mappingPageParams,
          ...newReqValues.eventParams
        }
      }
      newReqParams = mappingPageParams;
    }
    if(action=="exported"){
      newReqParams.export=`${pageName || Date.now()}.xlsx`;
      API.methods("exported", `${apiPath}/${getTenant()}/${url}`, newReqParams).then((url:any)=>{
        callback&&callback(url);
      });
    }else{
      API.methods(requestType, `${apiPath}/${getTenant()}/${url}`, newReqParams).then((res2:any)=>{
        const res2Data = res2.data?.body?.data[0];
        //接口报错直接返回
        if(!res2Data.success){
          callback&&callback({
            data: [],
            size: res2Data.size,
            success: res2Data.success,
            message: res2Data.message
          });
        }else if(requestType == 'delete'){//跳过回写store
          callback&&callback({
            data: null,
            size: null,
            success: res2Data.success,
            message: res2Data.message
          });
        }else{
          //输出字段对应的组件类型
          let widgetTypeMap:Record<string, string> = {};
          if(res2Data?.['__widgetTypeMap__']){
            widgetTypeMap = JSON.parse(res2Data['__widgetTypeMap__']);
          }
          if(res2Data&&res2Data.ext){
            const { user } = res2Data.ext;
            if(!_.isEmpty(user)){
              store.dispatch({
                type: X_PREVIEW_USER,
                data: {...res2Data.ext}
              })
              store.dispatch({
                type: ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
                payload: user||{},
              })
              store.dispatch({
                type: ReduxActionTypes.FETCH_APPLICATION_INIT,
                payload: {
                  applicationId: getUrlParams().appId
                },
              })
            }
          }
          if(typeof res2Data == 'string'){
            let widgetId:string = elementId?.split('_')[0];
            let widgetName = canvasDsl[widgetId]?.widgetName||'';
            showAlertFun({
              msg: `${widgetName} ${res2Data}`,
              type: 'error'
            })
          }
          //begin 处理接口输出映射
          const res2DataResult:Array<any> = res2Data?.result||[];
          let keyMap:Record<string, any> = {//默认值，不需映射
            id: "id",
            label: "label",
            value: "value",
            key: "key",
            children: "children",
            field: 'field',
            fieldName: 'fieldName',
            fieldType: 'fieldType',
            options: 'options',
            required: 'required',
            message: 'message'
          };
          _.each(resMsgFieldsObj, (values:any)=>{
            if(_.isString(values)){
              keyMap[values] = (values.endsWith('.label')||values.endsWith('.value')||values.indexOf('mapping')>0)?values:(values.split('.').splice(-1).join());
            }
            if(_.isObject(values)){
              _.each(values, (v:any, key:any)=>{
                _.each(v, (i:any)=>{
                  keyMap[i] = i;
                })
              })

            }
          })

          //end
          let respondData:any = {
            size: res2Data.size,
            success: res2Data.success,
            message: res2Data.message,
            widgetId,
            widgetName,
            event,
            widgetTypeMap,
            serviceCode
          };
          if(_.isEmpty(serviceCode)){
            return {
              data: res2DataResult,
              ...respondData
            };
          }
          //输出时把对应类型的值转换
          let res2DataResultHandle:Array<any> = setWidgetValue({
            res2DataResult,
            keyMap,
            widgetTypeMap
          });

          callback&&callback({
            data: res2DataResultHandle,
            ...respondData
          });
          ////
        }
      });
    }
  })
}
