import React from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, message, Row, Form, Col, Input } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import moment from 'moment';

import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/theme/ambiance.css';
import 'codemirror/addon/display/autorefresh';
import Editors from "@byk/pages/ApplicationManager/RuleManagement/codeEdit";
import { apiPath } from "@byk/routes";
import { getTenant } from "utils/localStorage";


// api
const apiGet: string = `${apiPath}/${getTenant()}/rest/DroolsRule/list`;
const apiPost: string =`${apiPath}/${getTenant()}/rest/DroolsRule`;
const apiPut: string = `${apiPath}/${getTenant()}/rest/DroolsRule`;
const apiDel: string = `${apiPath}/${getTenant()}/rest/DroolsRule`;

class RuleManagement extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  editorRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.editorRef = React.createRef();
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.formRef = React.createRef();
    //this.focusTextInput = this.focusTextInput.bind(this);
  }
  
  state = {
    record: null,
    visible: false,
    rows: [],
    roleData: [],
    editData: null,
    //
    title: '全部成员',
    type: 1,
    isModalForm: false,
    initParams: {
    },
    formCfg: {
      title: "编辑",
      okText: "确定",
      type: null
    },
    roleList: [],
    titeType: { "a": "编辑", "b": "编辑", "c": "新增" },
    btnTextType: { "a": "保存", "b": "保存", "c": "保存" },
    rule: ""
  };
  async componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.init();
  }

  callback(_data: any) {
    this.setState({}, () => {
      this.init();
    });

  };

  init() {
    let params: any = this.state.initParams;

    this.setState({ initParams: params }, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };
  showModal(type: string) {
    console.log(this.editorRef.current);
    this.setState({ isModalForm: true },()=>{
      let _data: any = this.state.formCfg.data;
      let rule=""
      let initData:any={};
      if (type == "b") {
        initData={
          id: _data.id, name: _data.name,
          description: _data.description, rule: _data.rule
        }
        rule=_data.rule;
      }
      else{
        initData={
          id: null, name: "",
          description: "", rule: ""
        };
      }
      setTimeout(() => {
        this.formRef.current?.setFieldsValue(initData);
        this.editorRef.current.setValue(rule);
      }, 300);
    });

  };

  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data,
      }
    }, () => { this.showModal(type); });

    //this.formRef.current.resetFields();
  };
  handleOk1 = () => {
    this.setState({ isModalForm: false });
  };

  handleCancel = () => {
    this.setState({ isModalForm: false });
  };
  //更新
  onFinish = async(value: any) => {
    //console.log(this.editorRef.current.editor.getValue());
    value.rule=this.state.rule;
    let _data: any = {};
    let cfg: any = this.state.formCfg;
    let result: any = null;
    //创建
    if (cfg.type == "c") {
      _data.name = value.name;
      _data.description = value.description;
      _data.rule = value.rule;
      result = await Api.post(apiPost, _data);
    }
    //编辑
    if (cfg.type == "b") {
      _data.id = cfg.data.id;
      _data.name = value.name;
      _data.description = value.description;
      _data.rule = value.rule;
      result = await Api.put(apiPost, _data);
    }
    console.log("_data", _data);
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel();
      this.init();
    }
    else {
      message.error(result.message);
    }

  }
  changeCode = (CodeMirror: any, changeObj: any, value: any) => {
    debugger;
    if (!value) return;
    // 获取 CodeMirror.doc.getValue()
    // 赋值 CodeMirror.doc.setValue(value) // 会触发 onChange 事件，小心进入无线递归。
    this.setState({ rule: value });
  };
  onChange = (editor: any, data: any, value: any) => {
    this.setState({rule:value});
  }

  setRule = (value: any) => {
    this.setState({rule:value});
  }

  render() {

    //表格refresh
    const refreshTable = (params?: any) => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    };


    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };
    //编辑按钮
    const EditClick = async (record: any) => {
      let result: any = await Api.get(apiPost, { id: record.id });
      if (result.success) {
        if (result.result.length > 0) {
          let _record = result.result[0];
          let { id, name, description, rule } = record;
          this.modalRef.current?.edit({
            id, name, description, rule
          });
        }
      }
    };


    //查询项配置
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "规则名称",
        allowClear: true,
        col: 8,
      },
    ];
    const searchProps = {
      option: searchConfig,
      onSearch: refreshTable,
      btnBoolean: true,
    };



    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 100,
      },
      {
        title: "规则名称",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "规则描述",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        render: (_, record: any) => (
          moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <Space size="middle">
            <Button type="link" onClick={() => { this.openForm("b", record) }}>
              编辑
            </Button>
            <Popconfirm
              placement="left"
              title={"确认删除吗？"}
              onConfirm={() => deleteClick(record)}
              okText="删除"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </Space>
        ),
        width: 100,
      },
    ];
    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      firstLoad: true
    };


    return (
      <>
        <div className="x-main-box">
          <SearchForm ref={this.searchRef} {...searchProps} />
          <div style={{ width: "100%", height: "50px" }}>
            {/* <Button className="x-fr" style={{ marginTop: "-75px", marginRight: "100px" }}>
              移除
            </Button> */}

            <Button type="primary" onClick={() => this.openForm("c", {})} className="x-fr">
              新增
            </Button>
          </div>
          <TableList ref={this.tableRef} {...tableProps} />

          <Modal destroyOnClose={true}  className="z-code-mirror"  title={this.state.formCfg.title} width={"100%"} open={this.state.isModalForm} onOk={() => this.showModal} onCancel={this.handleCancel} footer={[]}>
            <Row>
              <Col className="gutter-row" md={24}>
                <Form ref={this.formRef} labelCol={{ span: 3 }}
                  wrapperCol={{ span: 18 }} onFinish={this.onFinish}>
                  <Form.Item label="规则名称" name="name"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <Input placeholder="" maxLength={50} />
                  </Form.Item>
                  <Form.Item label="规则描述" name="description"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <Input placeholder=""  maxLength={100} />
                  </Form.Item>
                  <Form.Item label="规则" name="rule"
                    rules={[{ required: false, message: "请输入!" }]}>
                      <Editors  getValue={this.setRule}  ref={this.editorRef} />
                  </Form.Item>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Button onClick={this.handleCancel}>取消</Button>
                    <Button className="x-ml10" type="primary" htmlType="submit">
                      {this.state.formCfg.okText}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}
export default withRouter(RuleManagement);
