import withWidgetProps from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XText from "../../components/widgets/XText";';
  begin += `<XText propertyPath={${i.propertyPath}}`;
  begin += getWidgetStyles(i);
  if(i.onClick){
    begin += ` onClick={()=>{
                      evalEvent['${i.widgetName}Click'](dataTree, dispatch);
                    }}`;
  }
  begin += `>${i.label}</XText>`;
  return {begin, head};
};
