import withWidgetProps from "./utils";
import _ from "lodash";
import getWidgetStyles from "../../../utils/getWidgetStyles";
import {_grid} from "../../../reducers/dataTree";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../../../../../utils/localStorage";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    handle = '',
    useLoad = '',
    head='import XListView from "../../components/widgets/XListView";';
  if(i.onLoad){
    useLoad += `evalEvent['${i.widgetName}Load'](dataTree, dispatch);`;
  }
  handle += `const listData${i.widgetName}:any = ()=>{ 
     let widget = _.get(dataTree, ${i.propertyPath});
     let listData = widget.listData;
     if(_.isEmpty(listData)){
        return [];
      }else if(_.isString(listData)){
        return JSON.parse(listData);
      }else{
        return listData;
      }
   };`;
  begin += `<XListView propertyPath={${i.propertyPath}}>`;
  const container = _.get(i, 'children.0.children.0');
  const containerH = (container.bottomRow - container.topRow) * 10;
  const children = _.get(i, 'children.0.children.0.children.0.children');
  begin += '{';
  begin +=    `listData${i.widgetName}().map((currentItem:any, idx:number)=>{`;
  begin +=      'return (';
  begin +=        `<View className="x-list-item" key={idx} style={{ height: "${containerH}px" }}>`;

  const calcPosition = (child:any)=>{
    let parentColumnSpace = (i.rightColumn - i.leftColumn) * i.parentColumnSpace/_grid;
    let parentRowSpace = 10;
    let width = (child.rightColumn - child.leftColumn)*parentColumnSpace;
    let height = (child.bottomRow - child.topRow)*parentRowSpace;
    let left = child.leftColumn*parentColumnSpace + 2;
    let top = child.topRow*parentRowSpace + 2;
    return JSON.stringify({
      width: width + 'px',
      height: height + 'px',
      left: left + 'px',
      top: top + 'px',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center'
    })
  };

  const reText = (str:string, suffix = '')=>{
    if(str&&str.replace&&str.startsWith('{{')){
      let s = str.replace('{{', '').replace('}}', '');
      if(suffix){
        return `{ '${suffix}'+${s} }`
      }
      return `{${s}}`;
    }
    return str;

  }
  _.each(children, (j:any)=>{
    head += '&';
    begin += `<View style={${calcPosition(j)}}>`;


    if(j.type === 'TEXT_WIDGET'||j.type === 'AT_TEXT_WIDGET'){
      head +='import XText from "../../components/widgets/XText";';
      begin += `<XText`;
      begin += getWidgetStyles(j);
      begin += `>${reText(j.text||j.label)}</XText>`;
    }else if(['IMAGE_WIDGET'].includes(j.type)){
      head +='import { Image } from "@tarojs/components";';
      begin += `<Image`;
      begin += ` mode='widthFix' src=${reText(j.image)} />`;
    }else if(['X_IMAGE_WIDGET'].includes(j.type)){
      head +='import { Image } from "@tarojs/components";';
      begin += `<Image`;
      let imgUrl = `'https://byk-test.billjc.com${apiPath}/${getTenant()}/file/${j.value}'`;
      if(j.value&&j.value.startsWith('{')){
        imgUrl = reText(j.value, 'https://byk-test.billjc.com');
      }
      if(j.value&&j.value.startsWith('/byk')){
        imgUrl = `'https://byk-test.billjc.com${j.value}'`;
      }
      begin += ` mode='widthFix' src=${imgUrl} />`;
    }else if(j.type === 'AT_ICON_WIDGET'){
      head +='import XIcon from "../../components/widgets/XIcon";';
      begin += `<XIcon `;
      if(j.onClick){
        begin += ` onClick={()=>{
                      evalEvent['${j.widgetName}Click'](dataTree, dispatch);
                    }}`;
      }
      begin += ` iconName="${j.iconName}" color="${j.color}" size={'${j.size}'} />`;
    }else if(j.type === 'AT_BUTTON_WIDGET'){
      head +='import XButton from "../../components/widgets/XButton";';
      begin += `<XButton`;
      begin += getWidgetStyles(i);
      begin += `>${reText(j.label)}</XButton>`;
    }
    begin += `</View>`;
  })
  begin +=        '</View>';
  begin +=        ')';
  begin +=      '})';
  begin +=    '}';
  begin += `</XListView>`;
  return {begin, head, handle, useLoad};
};
