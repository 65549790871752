import React, { forwardRef, useRef } from 'react';

import type { Any } from 'react-spring';
import { Button } from 'antd';
import EditableTable from '../components/EditableTableWidget';


const App: React.FC<Any> = forwardRef((props: any, ref: any) => {
  const editRef = useRef<any>();
  const columns = [
    {
      title: '姓名',
      dataIndex: '姓名',
      width: '25%',
      editable: true,
      inputType: "text",
      required: false
    },
    {
      title: '年龄',
      dataIndex: '年龄',
      width: '15%',
      editable: true,
      inputType: "number",
      required: true
    },
    {
      title: '地址',
      dataIndex: '地址',
      width: '40%',
      editable: true,
      inputType: "text",
      required: false
    }
  ];

  const data = [
    {
      key: '1',
      id: 11,
      姓名: 'John Brown',
      年龄: 32,
      地址: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      id: 12,
      姓名: 'Jim Green',
      年龄: 42,
      地址: 'London No. 1 Lake Park',
    },
  ]
  const onclick = () => {
    let aaa = editRef.current?.getData();
    console.log("aaa", aaa)
  }
  return (
    <>
      <div>
        <Button type="primary" onClick={onclick}>onclick</Button><br />
        <EditableTable ref={editRef} cols={columns} data={data} />
      </div>
    </>
  )

});
export default App;
