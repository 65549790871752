import React, {useCallback, useMemo, useState} from "react";

import {
  ColumnPickerContainer,
  ColumnPickerHeaderContainer,
  ColumnPickerHeaderTitle,
  ColumnPickerHeaderTitleContainer,
  InfoIconContainer,
} from "./AggregationPicker.styled";
import StructuredQuery, {getFieldReference} from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import QueryColumnPicker from "@byk/pages/QueryBuilder/components/QueryColumnPicker/QueryColumnPicker";
import AccordionList from "@byk/pages/QueryBuilder/components/ui/AccordionList/AccordionList";
import {getIcon, QueryBuilderIcons} from "../../../../../icons/QueryBuilderIcons";

const DEFAULT_MAX_HEIGHT = 610;

interface AggregationPickerProps {
  className?: string;
  query: StructuredQuery;
  operators: any[];
  hasExpressionInput?: boolean;
  maxHeight?: number;
  onSelect: (operator: any) => void;
  onClose?: () => void;
  legacyClause: any;
  stageIndex?: number;
  extraProps: any;
}

type Section = {
  name: string;
  key: string;
  items: any[];
  icon?: string;
};

function isOperatorListItem(item: any) {
  return "operator" in item;
}

function aggregationClause(operator: any, column: any) {
  operator.value[1] = getFieldReference(column.field, column.field.table?.alias);
  return operator;
}

export function AggregationPicker(
  {
    className,
    query,
    operators,
    hasExpressionInput = true,
    maxHeight = DEFAULT_MAX_HEIGHT,
    onSelect,
    onClose,
    stageIndex,
    legacyClause,
    extraProps,
  }: AggregationPickerProps) {

  const [operator, setOperator] = useState<any>(query.getInitialAggregationOperator(stageIndex));
  const canUseExpressions = false;

  const sections = useMemo(() => {
    const sections: Section[] = [];

    const metrics: any[] = query.availableMetrics();

    if (operators.length > 0) {
      sections.push({
        key: "basic-metrics",
        name: "基础指标",
        items: operators.map(operator =>
          getOperatorListItem(query, operator),
        ),
        icon: "table2",
      });
    }

    if (metrics.length > 0) {
      sections.push({
        key: "common-metrics",
        name: "通用指标",
        items: metrics.map((metric: any) =>
          getMetricListItem(query, metric),
        ),
        icon: "star",
      });
    }

    if (hasExpressionInput && canUseExpressions) {
      sections.push({
        key: "custom-expression",
        name: "自定义表达式",
        items: [],
        icon: "sum",
      });
    }

    return sections;
  }, [query, operators, hasExpressionInput]);

  const checkIsItemSelected = useCallback(
    (item: any) => item.selected,
    [],
  );

  const handleOperatorSelect = useCallback(
    (item: any) => {
      console.log("AggregationPicker--handleOperatorSelect", item);
      if (item.operator.requiresField) {
        setOperator(item.operator);
      } else {
        onSelect(item.operator);
        onClose?.();
      }
    },
    [onSelect, onClose],
  );

  const handleResetOperator = useCallback(() => {
    setOperator(null);
  }, []);

  const handleColumnSelect = useCallback(
    (column: any) => {
      if (!operator) {
        return;
      }
      const clause = aggregationClause(operator, column);
      onSelect(clause);
      onClose?.();
    },
    [operator, onSelect, onClose],
  );

  const handleMetricSelect = useCallback(
    (item: any) => {
      onSelect(item.metric);
      onClose?.();
    },
    [onSelect, onClose],
  );

  const handleChange = useCallback(
    (item: any) => {
      if (isOperatorListItem(item)) {
        handleOperatorSelect(item);
      } else {
        handleMetricSelect(item);
      }
    },
    [handleOperatorSelect, handleMetricSelect],
  );


  // if (isEditingExpression) {
  //   return (
  //     <ExpressionWidget
  //       query={query}
  //       name={AGGREGATION.getName(legacyClause)}
  //       expression={AGGREGATION.getContent(legacyClause)}
  //       withName
  //       startRule="aggregation"
  //       header={<ExpressionWidgetHeader onBack={closeExpressionEditor}/>}
  //       onChangeExpression={handleExpressionChange}
  //       onClose={closeExpressionEditor}
  //     />
  //   );
  // }

  if (operator && operator?.requiresField) {
    const columnGroups = query.aggregationOperatorGroupColumns(operator);
    return (
      <ColumnPickerContainer
        className={className}
        data-testid="aggregation-column-picker"
      >
        <ColumnPickerHeader onClick={handleResetOperator}>
          {operator.name}
        </ColumnPickerHeader>
        <QueryColumnPicker
          query={query}
          stageIndex={stageIndex}
          columnGroups={columnGroups}
          maxHeight={maxHeight}
          checkIsColumnSelected={checkColumnSelected}
          onSelect={handleColumnSelect}
          onClose={onClose}
          extraProps={extraProps}/>
      </ColumnPickerContainer>
    );
  }

  function handleSectionChange(section: any) {
    console.log("AggregationPicker--->handleSectionChange", section);
  }

  return (
    <AccordionList
      className={className}
      sections={sections}
      maxHeight={maxHeight}
      alwaysExpanded={false}
      onChange={handleChange}
      onChangeSection={handleSectionChange}
      itemIsSelected={checkIsItemSelected}
      renderItemName={renderItemName}
      renderItemDescription={omitItemDescription}
      extraProps={extraProps}
    />
  );
}

function ColumnPickerHeader(
  {
    children,
    onClick,
  }: {
    children: React.ReactNode;
    onClick: () => void;
  }) {
  return (
    <ColumnPickerHeaderContainer>
      <ColumnPickerHeaderTitleContainer onClick={onClick} aria-label={"返回"}>
        <QueryBuilderIcons.ChevrondLeft color="#949aab" width={18} height={18}/>
        <ColumnPickerHeaderTitle>{children}</ColumnPickerHeaderTitle>
      </ColumnPickerHeaderTitleContainer>
    </ColumnPickerHeaderContainer>
  );
}

function renderItemName(item: any) {
  return item.name;
}

function omitItemDescription() {
  return null;
}

function renderItemExtra(item: any) {
  if (item.description) {
    return (
      <InfoIconContainer>
        {getIcon("question", {width: 18, height: 18})}
      </InfoIconContainer>
    );
  }
  return null;
}

function getOperatorListItem(
  query: StructuredQuery,
  operator: any,
): any {
  const operatorInfo = query.displayOperatorInfo(operator);
  return {
    ...operatorInfo,
    operator,
  };
}

function getMetricListItem(
  query: StructuredQuery,
  metric: any,
): any {
  const metricInfo = query.displayMetricInfo(metric);
  return {
    ...metricInfo,
    metric,
  };
}

function checkColumnSelected(columnInfo: any) {
  return !!columnInfo.selected;
}
