import withWidgetProps from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XImagePicker from "../../components/widgets/XImagePicker";';
  begin += `<XImagePicker className="x-image-picker" propertyPath={${i.propertyPath}}`;
  begin += getWidgetStyles(i);
  begin += `>${i.label}</XImagePicker>`;
  return {begin, head};
};
