export default function Config ():string{
  return `import { Image, View } from '@tarojs/components';
import React from "react";
import XBase from "./XBase";

function XImage(props: any) {
  const { propertyPath, imgUrl, description } = props;

  return (
    <XBase propertyPath={propertyPath}>
      <Image mode='widthFix' src={imgUrl} />
      {
          description &&
          <View style={{position:'absolute',left: 0, right: 0, bottom: 0, height: '20px', lineHeight: '20px', padding: '0 10px', backgroundColor: 'rgba(0,0,0,.8)'}}>{description}</View>
        }
    </XBase>)
}
export default XImage;
  `;
}
