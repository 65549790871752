import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import {Checkbox, Space, Table} from "antd";
import _ from "lodash";
import PublishPropertyDataChange from "@byk/pages/modeling/Publish/PublishPropertyDataChange";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {ModalWrapper} from "@byk/pages/modeling/styled";

interface IProps {
  ref?: any,
  update: (model: any) => void,
  isView: boolean,
}

const PublishDataMaintenance: React.FC<IProps> = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
      showModal
    }));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [model, setModel] = useState<any>({});
    const [properties, setProperties] = useState<any[]>([]);
    const publishPropertyDataChangeRef = useRef<any>();

    const showModal = (model: any) => {
      let dataMaintains = _.filter(model.properties || [],
        pi => pi.dataMaintain || pi.addFlag || pi.deleteFlag || pi.updateFlag
      )
      setModel(model);
      setProperties(dataMaintains || []);
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const openDataChange = (record: any) => {
      setTimeout(() => {
        publishPropertyDataChangeRef.current.showModal(model, record);
      }, publishPropertyDataChangeRef.current ? 0 : 100);
    }

    const deleteData = ({target: {checked}}: CheckboxChangeEvent, record: any) => {
      _.forEach(model.properties, item => {
        if (item.id == record.id) {
          item.deleteFlag = checked;
        }
      })

      setModel({...model});
      props.update(model);
    }

    const columns = [
      {
        title: '模型',
        dataIndex: 'model',
        key: 'model',
      },
      {
        title: '属性',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '修改描述',
        dataIndex: 'diffMsg',
        key: 'diffMsg',
      },
      {
        title: '脚本变更数据处理',
        key: 'action',
        render: (_: any, record: any) => (
          <>
            {record.dataMaintain && (
              <Space size="middle">
                <Checkbox disabled={model.isView || true} checked={record.deleteFlag}
                          onChange={(evt) => deleteData(evt, record)}>删除字段后添加字段</Checkbox>
              </Space>
            )
            }
          </>
        )
      },
    ];

    return (
      <>
        <ModalWrapper
          className={"createModel"}
          onCancel={handleCancel} open={isModalOpen}
          footer={null}
          maskClosable={false}
          title={'模型(' + model.description + ')变更数据处理'}
          width={'80%'}
          bodyStyle={{
            padding: 5,
            height: 400,
          }}
        >
          <Table dataSource={properties} columns={columns} pagination={false}/>
        </ModalWrapper>

        <PublishPropertyDataChange ref={publishPropertyDataChangeRef} update={props.update}/>
      </>
    );
  }
)

export default PublishDataMaintenance;
