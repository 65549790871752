import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import type {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router-dom";
import type {BuilderRouteParams} from "constants/routes";
import {GIT_BRANCH_QUERY_KEY} from "constants/routes";
import type {AppState} from "@appsmith/reducers";
import MainContainer from "./MainContainer";
import {
  getCurrentApplicationId,
  getCurrentPageId,
  getIsEditorInitialized,
  getIsEditorLoading,
  getIsPublishingApplication,
  getPublishingError,
} from "selectors/editorSelectors";
import type {InitializeEditorPayload} from "actions/initActions";
import {initEditor, resetEditorRequest} from "actions/initActions";
import {editorInitializer} from "utils/editor/EditorUtils";
import CenteredWrapper from "components/designSystems/appsmith/CenteredWrapper";
import {getCurrentUser} from "selectors/usersSelectors";
import type {User} from "constants/userConstants";
import RequestConfirmationModal from "pages/Editor/RequestConfirmationModal";
import * as Sentry from "@sentry/react";
import {getTheme, ThemeMode} from "selectors/themeSelectors";
import styled, {ThemeProvider} from "styled-components";
import type {Theme} from "constants/DefaultTheme";
import GlobalHotKeys from "./GlobalHotKeys";
import {fetchPage, updateCurrentPage} from "actions/pageActions";
import {getSearchQuery} from "utils/helpers";
import {loading} from "selectors/onboardingSelectors";
import {getIsBranchUpdated} from "../utils";
import {APP_MODE} from "entities/App";
import MultiPaneContainer from "pages/Editor/MultiPaneContainer";
import {isMultiPaneActive} from "selectors/multiPaneSelectors";
import {Spinner} from "design-system";
import ToggleModeButton from "./ToggleModeButton";
import Head from "@byk/pages/components/Header";
import CanvasContainer from "./WidgetsEditor/CanvasContainer";
import EditorContextProvider from "../../components/editorComponents/EditorContextProvider";
import SwitchSubApp from "@byk/pages/components/Header/SwitchSubApp";
import {Button} from "antd";
import AlignTools from "./AlignTools";

const SwitchApp = styled.div`
  margin-left: 17px;
  span {
    font-family: SourceHanSansSC;
    font-weight: 500;
    font-size: 14px;
    color: rgb(76, 86, 100);
    font-style: normal;
    letter-spacing: 0px;
    line-height: 23px;
    text-decoration: none;
  }
`

type EditorProps = {
  toAppId?: string;
  toPageId?: string;
  currentApplicationId?: string;
  currentApplicationName?: string;
  initEditor: (payload: InitializeEditorPayload) => void;
  isPublishing: boolean;
  isEditorLoading: boolean;
  isEditorInitialized: boolean;
  isEditorInitializeError: boolean;
  errorPublishing: boolean;
  loadingGuidedTour: boolean;
  user?: User;
  lightTheme: Theme;
  resetEditorRequest: () => void;
  fetchPage: (pageId: string) => void;
  updateCurrentPage: (pageId: string) => void;
  handleBranchChange: (branch: string) => void;
  currentPageId?: string;
  pageLevelSocketRoomId: string;
  isMultiPane: boolean;
};

type Props = EditorProps & RouteComponentProps<BuilderRouteParams>;

class Editor extends Component<Props> {
  public state = {
    registered: false,
  };

  componentDidMount() {
    editorInitializer().then(() => {
      this.setState({registered: true});
    });

    const {
      location: {search},
    } = this.props;
    const branch = getSearchQuery(search, GIT_BRANCH_QUERY_KEY);

    let {applicationId, pageId} = this.props.match.params;
    let {toAppId, toPageId} = this.props;
    if (toAppId && toPageId) {
      applicationId = toAppId;
      pageId = toPageId
    }
    if (pageId)
      this.props.initEditor({
        applicationId,
        pageId,
        branch,
        mode: APP_MODE.EDIT,
      });
  }

  shouldComponentUpdate(nextProps: Props, nextState: { registered: boolean }) {
    const isBranchUpdated = getIsBranchUpdated(
      this.props.location,
      nextProps.location,
    );

    return (
      isBranchUpdated ||
      nextProps.toPageId !== this.props.toPageId ||
      nextProps.toAppId !== this.props.toAppId ||
      nextProps.currentApplicationName !== this.props.currentApplicationName ||
      nextProps.match?.params?.pageId !== this.props.match?.params?.pageId ||
      nextProps.currentApplicationId !== this.props.currentApplicationId ||
      nextProps.isEditorInitialized !== this.props.isEditorInitialized ||
      nextProps.isPublishing !== this.props.isPublishing ||
      nextProps.isEditorLoading !== this.props.isEditorLoading ||
      nextProps.errorPublishing !== this.props.errorPublishing ||
      nextProps.isEditorInitializeError !==
      this.props.isEditorInitializeError ||
      nextProps.loadingGuidedTour !== this.props.loadingGuidedTour ||
      nextState.registered !== this.state.registered
    );
  }

  componentDidUpdate(prevProps: Props) {
    let {applicationId, pageId} = this.props.match.params || {};
    let {pageId: prevPageId} = prevProps.match.params || {};
    let {toAppId, toPageId} = this.props;
    let {toPageId: prevToPageId} = prevProps;
    if (toAppId && toPageId) {
      applicationId = toAppId;
      pageId = toPageId;
      prevPageId = prevToPageId as string;
    }

    const isBranchUpdated = getIsBranchUpdated(
      this.props.location,
      prevProps.location,
    );

    const branch = getSearchQuery(
      this.props.location.search,
      GIT_BRANCH_QUERY_KEY,
    );
    const prevBranch = getSearchQuery(
      prevProps.location.search,
      GIT_BRANCH_QUERY_KEY,
    );

    const isPageIdUpdated = pageId !== prevPageId;

    // to prevent re-init during connect
    if (prevBranch && isBranchUpdated && pageId) {
      this.props.initEditor({
        applicationId,
        pageId,
        branch,
        mode: APP_MODE.EDIT,
      });
    } else {
      /**
       * First time load is handled by init sagas
       * If we don't check for `prevPageId`: fetch page is re triggered
       * when redirected to the default page
       */
      if (prevPageId && pageId && isPageIdUpdated) {
        this.props.updateCurrentPage(pageId);
        this.props.fetchPage(pageId);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetEditorRequest();
  }

  public render() {
    if (
      !this.props.isEditorInitialized ||
      !this.state.registered ||
      this.props.loadingGuidedTour
    ) {
      return (
        <CenteredWrapper
          style={{height: `calc(100vh - ${theme.smallHeaderHeight})`}}
        >
          <Spinner size="lg"/>
        </CenteredWrapper>
      );
    }
    //我的任务，映射时右侧侧页面
    if (this.props.toAppId && this.props.toPageId) {
      return (
        <EditorContextProvider renderMode="CANVAS">
          <CanvasContainer
            isAppSettingsPaneWithNavigationTabOpen={false}
            isPreviewMode={false}
            shouldShowSnapShotBanner={false}
          />
        </EditorContextProvider>
      )
    }
    return (
      <ThemeProvider theme={theme}>
        <div>
          <Helmet>
            <meta charSet="utf-8"/>
            <title>
              {`${this.props.currentApplicationName} |`} Editor | Byk
            </title>
          </Helmet>
          <GlobalHotKeys>
            <Head title="PageDesign"/>
            <div className="c-edit3" style={{
              display: "flex",
              justifyContent: "space-between",
              height: "42px",
              borderBottom: '1px solid #E0E8EB',
              alignItems: "center",
            }}>
              <SwitchApp>
                <span>切换应用:</span>
                <SwitchSubApp/>
              </SwitchApp>
              <AlignTools/>
              <ToggleModeButton/>
            </div>
            {this.props.isMultiPane ? (
              <MultiPaneContainer/>
            ) : (
              <MainContainer/>
            )}
          </GlobalHotKeys>
        </div>
        <RequestConfirmationModal/>
      </ThemeProvider>
    );
  }
}

const theme = getTheme(ThemeMode.LIGHT);

const mapStateToProps = (state: AppState) => ({
  currentApplicationId: getCurrentApplicationId(state),
  errorPublishing: getPublishingError(state),
  isPublishing: getIsPublishingApplication(state),
  isEditorLoading: getIsEditorLoading(state),
  isEditorInitialized: getIsEditorInitialized(state),
  user: getCurrentUser(state),
  currentApplicationName: state.ui.applications.currentApplication?.name,
  currentPageId: getCurrentPageId(state),
  loadingGuidedTour: loading(state),
  isMultiPane: isMultiPaneActive(state),
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    initEditor: (payload: InitializeEditorPayload) =>
      dispatch(initEditor(payload)),
    resetEditorRequest: () => dispatch(resetEditorRequest()),
    fetchPage: (pageId: string) => dispatch(fetchPage(pageId)),
    updateCurrentPage: (pageId: string) => dispatch(updateCurrentPage(pageId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(Editor)),
);
