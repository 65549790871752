import * as React from "react";
import Filter from "@byk/pages/QueryBuilder/lib/queries/structured/Filter";
import {Input} from "antd";

export interface Props {
  filter: Filter;
  setValue: (index: number, value: any) => void,
  setValues: (values: any[]) => void,
  onCommit: any,
  className: string,
  minWidth?: number,
  maxWidth?: number,
  checkedColor?: string,
};

const DefaultPicker: React.FC<Props> =
  ({
     filter,
     setValue,
     setValues,
     onCommit,
     className,
     minWidth,
     maxWidth,
     checkedColor,
   }: Props) => {

    const operator = filter.operator();
    if (!operator) {
      return <div className={className}/>;
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      console.log('Change:', e.target.value);
      setValue(0, e.target.value);
    };

    return (
      <Input onChange={onChange}/>
    );
  }

export default DefaultPicker;
