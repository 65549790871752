import React, { useRef, useState, useEffect } from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import XModal from "@byk/pages/components/XModal";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Tree } from "antd";
import { JSEncrypt } from "jsencrypt";
import Api from "@byk/api/Api";
import axios from "axios";

import { DataNode, TreeProps } from "antd/es/tree";
import { any, object } from "prop-types";
import { render } from "@testing-library/react";

export default class extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    //this.focusTextInput = this.focusTextInput.bind(this);
  }
  state = {
    record: null,
    visible: false,
    rows: [],
    roleData: [],
    editData: null,
    treeData: [],
    parent: 0,
    codesOpt: [],
  };
  //表格refresh
  refreshTable = async (params?: any) => {
    let result = await Api.get("/byk/platform/rest/SysMenu/list", {
      asc: "parent,sort,id",
    });
    if (result.success) {
      this.setState({ treeData: result.result });
    }
  };
  bindAllEntity = async () => {
    let result = await Api.get("/byk/allEntity");
    if (result.success) {
      let selectData = [];
      for (var i = 0; i < result.result.length; i++) {
        let _i = result.result[i];
        selectData.push({
          label: _i.entity + "-" + _i.name,
          entity: _i.entity,
          options: [
            { value: `ENTITY:${_i.entity}:GET`, label: `${_i.entity}(查询)` },
            { value: `ENTITY:${_i.entity}:POST`, label: `${_i.entity}(新增)` },
            { value: `ENTITY:${_i.entity}:PUT`, label: `${_i.entity}(修改)` },
            {
              value: `ENTITY:${_i.entity}:DELETE`,
              label: `${_i.entity}(删除)`,
            },
          ],
        });
      }
      this.setState({ codesOpt: result.result });
    }
  };
  async componentDidMount() {
    this.refreshTable();
    this.bindAllEntity();
  }

  //const Crud = () => {
  render() {
    // api
    const apiGet: string = "/byk/platform/rest/SysMenu/list";
    const apiPost: string = "/byk/platform/rest/SysMenu";
    const apiPut: string = "/byk/platform/rest/SysMenu";
    const apiDel: string = "/byk/platform/rest/SysMenu";
    const apiRoleGet: string = "/byk/platform/rest/SysMenu/list";
    //表格refresh
    const refreshTable = async (params?: any) => {
      let result = await Api.get("/byk/platform/rest/SysMenu/list", {
        asc: "parent,sort,id",
      });
      if (result.success) {
        this.setState({ treeData: result.result });
      }
    };
    //编辑按钮
    const EditClick = async (record: any) => {
      let result = await Api.get(apiPost, { id: record.id });
      if (result.success) {
        if (result.result.length > 0) {
          let _record = result.result[0];
          let { id, name, type, code, url, sort, label, description } = record;
          this.modalRef.current?.edit({
            id,
            name,
            type,
            code,
            url,
            sort,
            label,
            description,
          });
        }
      }
    };
    const add = (data?: any) => {
      this.setState({ parent: 0 });
      this.setState({ parent: data.id });
      this.modalRef.current?.add();
    };
    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result = await Api.delete(apiDel, [id]);
      if (result.success) {
        refreshTable();
      }
    };

    //新增表单项
    const ModalConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "菜单名称",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "Radio",
        placeholder: "请输入",
        name: "type",
        label: "类型",
        allowClear: true,
        mode: "multiple", //multiple | tags
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
        data: [
          { label: "菜单", value: "menu" },
          { label: "操作", value: "operation" },
        ],
        fieldNames: { label: "name", value: "id" },
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "code",
        label: "权限码",
        allowClear: true,
        col: 24,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "url",
        label: "链接",
        allowClear: true,
        col: 24,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "sort",
        label: "顺序",
        allowClear: true,
        col: 24,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "label",
        label: "标识",
        allowClear: true,
        col: 24,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "description",
        label: "描述",
        allowClear: true,
        col: 24,
      },
    ];
    //改造提交的data
    const reformData = (params?: any, type?: any) => {
      if (type == 0) {
        if (this.state.parent != 0) {
          params.parent = this.state.parent;
        }
      }
      return params;
    };
    //modal 表单配置
    const modalProps = {
      apiPost,
      apiPut,
      option: ModalConfig,
      refreshTable,
      reformData,
    };
    const treeDataConfig = {
      treeData: [],
    };
    return (
      <>
        <div className="x-main-box">
          <XModal ref={this.modalRef} {...modalProps} />
          <Tree
            showLine
            defaultExpandAll={true}
            defaultExpandedKeys={["0-0-0"]}
            fieldNames={{ title: "name", key: "id", children: "children" }}
            treeData={this.state.treeData}
            titleRender={(data: any) => {
              if (data.children) {
                return (
                  <div>
                    {data.name +
                      (data.code == "" ? "" : "   [" + data.code + "]")}
                    <Button
                      type="link"
                      size="small"
                      className="x-ml5"
                      onClick={() => add(data)}
                    >
                      新增
                    </Button>
                    <Button
                      type="link"
                      onClick={() => EditClick(data)}
                      className="x-ml5"
                      size="small"
                    >
                      编辑
                    </Button>

                    <Button
                      type="link"
                      onClick={() => deleteClick(data)}
                      disabled={data.children.length > 0 ? true : false}
                      className="x-ml5"
                      size="small"
                    >
                      删除
                    </Button>
                  </div>
                );
              }
            }}
          />
        </div>
      </>
    );
  }
}
