import React from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import type { ColumnsType } from "antd/es/table";
import { Button, } from "antd";

import { withRouter } from "react-router-dom";
import moment from 'moment';
import PopupMapping from "@byk/pages/MyTask/PopupMapping";
import localStorage, {getProjectId, getTenant} from "utils/localStorage";
import { ControlOutlined } from "@ant-design/icons";
import { apiPath } from "@byk/routes";
import {appTenantClass} from "@byk/utils/Utils";

class AppActionManager extends React.Component {
  searchRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.tableRef = React.createRef();
  }
  state = {
    record: null,
    visible: false,
    projectId: getProjectId(),
    tenant: getTenant(),
    initParams: {
      tenant: getTenant(),
      queryMode: "allLike",
      actionType:"API",
      includeRelated:true,
      desc: "updateDate"
    },
    openPopupRecord: {},
    isOpenPopup: false,
    key: "1"
  };
  async componentDidMount() {

  }

  render() {
    const apiGet: string = `${apiPath}/platform/rest/AppAction/list`;
    const refreshTable = (params?: any) => {
      // if (this.tableRef.current) {
      //   this.tableRef.current.getTableList(params);
      // }
      let s: any = searchParam();
      if (this.tableRef?.current) {
        this.tableRef.current.getTableList({ ...params, ...s });
      }
    };

    const searchParam = () => {
      let params = { ...this.searchRef.current.getSearchData(), ...this.state.initParams };
      return params;
    }


    //查询项配置
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "description",
        label: "描述",
        allowClear: true,
        col: 6,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "pageId.name",
        label: "页面名称",
        allowClear: true,
        col: 6,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "serviceId.name",
        label: "服务名称",
        allowClear: true,
        col: 6,
      }
    ];

    //新增表单项
    const ModalConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入111",
        name: "code",
        label: "服务编码",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "服务名",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "category",
        label: "分类",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "textarea",
        placeholder: "请输入",
        name: "description",
        label: "服务描述",
        allowClear: true,
        maxlength: 1000,
        height: 80,
        col: 24,
      },
    ];

    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 70,
      },
      {
        title: "请求类型",
        dataIndex: "requestTypeName",
        key: "requestTypeName",
        width: 90,
      },
      {
        title: "页面名称",
        dataIndex: "pageName",
        key: "pageName",
        width: 120,
      },
      {
        title: "elementId",
        dataIndex: "elementId",
        key: "elementId"
      },
      {
        title: "服务名称",
        dataIndex: "serviceName",
        key: "serviceName",
      },
      {
        title: "服务描述",
        dataIndex: "serviceDescription",
        key: "serviceDescription",
        width: 120,
      },
      {
        title: "接口描述",
        dataIndex: "description",
        key: "description",
        render: (text, record) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {text}
          </div>
        ),
      },

      {
        title: "最后修改时间",
        dataIndex: "updateDate",
        key: "updateDate",
        render: (_, record: any) => (
          moment(record.updateDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <Button type="primary" shape="circle" icon={<ControlOutlined />} onClick={() => openPopupMapping(record)}>
          </Button>
        ),
        width: 80,
      },
    ];
    const searchProps = {
      option: searchConfig,
      //onSearch: refreshTable,
      onSearch: (p: any) => {
        refreshTable(p);
      },
      btnBoolean: true,
      btnWrapBoolean: false
    };
    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
      searchParam: searchParam
    };


    const openPopupMapping = (_record: any) => {
      let record: any = {
        id: _record.id,
        actionId: _record.id,
        appId: _record.appId,
        pageId: _record.pageId,
        taskName: "",
        taskDescription: _record.description,
        tenant: _record.tenant,
        isFromAction: true
      }
      this.setState({
        isOpenPopup: true,
        openPopupRecord: record
      })
    }
    const updateIsOpenPopup = (bool: boolean) => {
      this.setState({
        isOpenPopup: bool,
        //key: uuid2()
      },()=>{
        refreshTable();
      })
    }
    return (
      <>
        <PopupMapping updateIsOpenPopup={updateIsOpenPopup} openPopupRecord={this.state.openPopupRecord} isOpenPopup={this.state.isOpenPopup} />
        <div key={this.state.key} className="x-main-box">
          <SearchForm ref={this.searchRef} {...searchProps} />
          <TableList ref={this.tableRef} {...tableProps} />
        </div>
      </>
    );
  }
}
export default withRouter(AppActionManager);
