import React from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  ModalBody,
} from "design-system";

interface DeleteThemeModalProps {
  isOpen: boolean;
  onClose(): void;
  onDelete(): void;
}

function DeleteThemeModal(props: DeleteThemeModalProps) {
  const { isOpen, onClose, onDelete } = props;

  return (
    <Modal
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
      open={isOpen}
    >
      <ModalContent
        id="delete-theme-modal"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        style={{ width: "640px" }}
      >
        <ModalHeader>
          确认
        </ModalHeader>
        <ModalBody>
          <Text kind="action-l">
            确定删除这个风格主题吗？这个操作不可恢复
          </Text>
        </ModalBody>
        <ModalFooter>
          <div className="flex gap-3">
            <Button kind="secondary" onClick={onClose} size="md">
              取消
            </Button>
            <Button kind="error" onClick={onDelete} size="md">
              删除
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteThemeModal;
