import React, {useCallback} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Button, Tooltip} from "design-system";

import type {AppState} from "@appsmith/reducers";
import {APP_MODE} from "entities/App";

import {getAppMode} from "@appsmith/selectors/applicationSelectors";
import {setPreviewModeInitAction} from "actions/editorActions";
import {
  getCurrentApplicationId,
  getCurrentIframeId,
  getDefaultPageId,
  getDefaultPageSlug,
  previewModeSelector,
} from "selectors/editorSelectors";

import {isExploringSelector} from "selectors/onboardingSelectors";
import {createMessage, EDITOR_HEADER} from "@appsmith/constants/messages";
import {ReduxActionTypes} from "../../ce/constants/ReduxActionConstants";
import {routePath} from "@byk/routes";
import OutputCode from "../../components/outputCode";

function ToggleModeButton(props: {
  publishApplication: (appId: string, pageId: string) => void,
  applicationId: string
}) {
  const {applicationId} = props;
  const dispatch = useDispatch();
  const isExploring = useSelector(isExploringSelector);
  const isPreviewMode = useSelector(previewModeSelector);
  const appMode = useSelector(getAppMode);
  const defaultPageId = useSelector(getDefaultPageId);
  const defaultPageSlug = useSelector(getDefaultPageSlug);
  const mode = useSelector((state: AppState) => state.entities.app.mode);
  const {name: appName} = useSelector((state: AppState) => {
    return {...state.ui.applications.currentApplication};
  })
  const isViewMode = mode === APP_MODE.PUBLISHED;
  const onClickPreviewModeButton = useCallback(() => {
    dispatch(setPreviewModeInitAction(!isPreviewMode));
  }, [dispatch, setPreviewModeInitAction, isPreviewMode]);

  if (isExploring || isViewMode) return null;
  const previewLogin = () => {
    //预览时修改byk为byk-preview
    let _host = location.origin;
    if (_host.indexOf('localhost') < 0) {
      let a1: any = _host.split('.');
      a1.splice(0, 1, `${a1[0]}-preview`);
      _host = a1.join('.');
    }
    let params = `?appId=${applicationId}&appName=${appName}`;
    if(!defaultPageId||!defaultPageSlug){
      window.open(`${_host}${routePath}/app/${applicationId}/systemRole-9999/system${params}`);
    }else{
      window.open(`${_host}${routePath}/app/${applicationId}/${defaultPageSlug}-${defaultPageId}${params}`);

    }
  }
  return (
    <div style={{display: "flex", justifyContent: "flex-end", width: '290px'}}>
      <div style={{marginRight: '12px'}}>
        <Tooltip
          content={
            <>
              {createMessage(EDITOR_HEADER.previewTooltip.text)}
              <span style={{marginLeft: 20}}>
            {createMessage(EDITOR_HEADER.previewTooltip.shortcut)}
          </span>
            </>
          }
          isDisabled={appMode !== APP_MODE.EDIT}
          placement="bottom"
        >

          <Button
            data-testid={`${isPreviewMode ? "preview" : "edit"}-mode`}
            kind="tertiary"
            onClick={previewLogin}
            size="md"
            startIcon="play-circle-line"
          >
            {createMessage(EDITOR_HEADER.previewTooltip.text)}
          </Button>
        </Tooltip>
        <OutputCode />
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  applicationId: getCurrentApplicationId(state)
})
const mapDispatchToProps = (dispatch: any) => ({
  publishApplication: (applicationId: string, pageId: string) => {
    dispatch({
      type: ReduxActionTypes.PUBLISH_APPLICATION_INIT,
      payload: {
        applicationId,
        pageId
      },
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ToggleModeButton);
