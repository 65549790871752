import {NotebookStepUiComponentProps} from "@byk/pages/QueryBuilder/components/notebook/types";
import React from "react";
import ClauseStep from "@byk/pages/QueryBuilder/components/notebook/steps/ClauseStep";
import {SortDirectionButton} from "@byk/pages/QueryBuilder/components/notebook/steps/SortStep/SortStep.styled";
import {QueryBuilderIcons} from "../../../../../../../icons/QueryBuilderIcons";
import QueryColumnPicker from "@byk/pages/QueryBuilder/components/QueryColumnPicker/QueryColumnPicker";

const SortStep: React.FC<NotebookStepUiComponentProps> =
  ({
     color,
     query,
     updateQuery,
     readOnly,
     isLastOpened,
   }: NotebookStepUiComponentProps) => {

    const clauses = query.orderBys() || [];

    const checkColumnSelected = (
      columnInfo: any,
      orderByIndex?: number,
    ) => {
      return (
        typeof orderByIndex === "number" &&
        columnInfo.orderByPosition === orderByIndex
      );
    };

    function getColumnGroups(orderByIndex: number | undefined) {
      return query.groupColumns();
    }

    function handleUpdateOrderByField(orderBy: any, column: any) {
      console.log("step-sort：handleUpdateOrderByField --》", column);
    }

    async function handleAddOrderBy(column: any) {
      console.log("step-sort：handleAddOrderBy --》", column);
      await updateQuery(query.addOrderBy(column.field, "asc"));
    }

    async function handleRemoveOrderBy(item: any, index: number) {
      console.log("step-sort：handleRemoveOrderBy --》", item, index);
      await updateQuery(query.removeOrderBy(index));
    }

    async function handleToggleOrderByDirection(clause: any) {
      await updateQuery(query.changeDirection(clause));
    }

    return (
      <ClauseStep
        items={clauses}
        readOnly={readOnly}
        color={color}
        isLastOpened={isLastOpened}
        renderName={(clause: any) => (
          <SortDisplayName
            displayInfo={query.sortDisplayName(clause)}
            onToggleSortDirection={() => handleToggleOrderByDirection(clause)}
          />
        )}
        renderPopover={(orderBy, orderByIndex) => (
          <QueryColumnPicker
            query={query}
            columnGroups={getColumnGroups(orderByIndex)}
            checkIsColumnSelected={(item: any) =>
              checkColumnSelected(item, orderByIndex)
            }
            onSelect={async (column: any) => {
              const isUpdate = orderBy != null;
              if (isUpdate) {
                handleUpdateOrderByField(orderBy, column);
              } else {
                await handleAddOrderBy(column);
              }
            }}
            extraProps={{
              style: {color: "rgb(136, 191, 77)"},
              width: "350px"
            }}
          />
        )}
        onRemove={handleRemoveOrderBy}
      />
    );

  };

interface SortDisplayNameProps {
  displayInfo: any;
  onToggleSortDirection: () => void;
}


function SortDisplayName(
  {
    displayInfo,
    onToggleSortDirection,
  }: SortDisplayNameProps) {
  return (
    <SortDirectionButton
      onClick={event => {
        event.stopPropagation();
        onToggleSortDirection();
      }}
    >
      {displayInfo.direction === "asc" ? <QueryBuilderIcons.ArrowUp/> : <QueryBuilderIcons.ArrowDown/>}
      <span>{displayInfo.longDisplayName}</span>
    </SortDirectionButton>
  );
}

export default SortStep;
