import React, {useState} from "react";
import {SegmentedControl} from "design-system";
import {tailwindLayers} from "constants/Layers";
import {useDispatch, useSelector} from "react-redux";
import {jsCollectionIdURL} from "RouteBuilder";
import {getCurrentPageId} from "selectors/editorSelectors";
import history, {NavigationMethod} from "utils/history";
import EntityExplorer from "../../pages/Editor/Explorer/EntityExplorer";
import {getJSCollections} from "../../selectors/entitiesSelector";
import _ from "lodash";
import {createNewJSCollection} from "../../actions/jsPaneActions";

const options = [
  {
    value: "explorer",
    label: "UI元素",
  },
  {
    value: "JS",
    label: "JS",
  },
];

function SidebarPageTab() {
  const dispatch = useDispatch();
  const pageId = useSelector(getCurrentPageId);
  const [active, setActive] = useState('explorer');

  const onChange = (value: string) => {
    setActive(value)
  };

  /*创建js begin*/
  const jsActions = useSelector(getJSCollections);
  const createNewJs = () => {
    let currentJsAction = jsActions.filter(i => {
      return i.config.pageId == pageId;
    });
    if (_.isEmpty(currentJsAction)) {
      dispatch(createNewJSCollection(pageId, "ACTION_SELECTOR"));
    } else {
      const navigateToUrl = jsCollectionIdURL({
        pageId,
        collectionId: currentJsAction[0]['config'].id,
        params: {},
      });
      history.push(navigateToUrl, {
        invokedBy: NavigationMethod.EntityExplorer
      });
    }
  }
  /*end*/

  return (
    <div
      className={`flex-1 flex flex-col overflow-hidden ${tailwindLayers.entityExplorer}`}
    >
      <div className={`flex-shrink-0`}>
        <SegmentedControl
          onChange={onChange}
          options={options}
          value={active}
        />
      </div>
      {active === "explorer" && <EntityExplorer isActive={active === "explorer"}/>}
      {active === "JS" && (
        <div onClick={createNewJs} style={{padding: '5px 10px', backgroundColor: '#f4f4f4'}}>
          JS
        </div>
      )}
    </div>
  );
}

export default SidebarPageTab;
