import {Button, Form, Input} from 'antd';
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import FormItem from "antd/es/form/FormItem";
import {ModalWrapper} from '../styled';

export interface IProps {
  ref: any,
  update: (model: any) => void;
  isView: boolean,
}

const RenameTableName: React.FC<IProps> = forwardRef((props, ref) => {
      useImperativeHandle(ref, () => ({
        showModal
      }));

      const formRef = useRef<any>();
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [model, setModel] = useState<any>(false);
      const [tableName, setTableName] = useState<any>('');

      const showModal = (model: any) => {
        setModel(model);
        setTableName(model.renameTable || '')
        formRef.current?.setFieldsValue({tableName: model.renameTable || ''});
        setIsModalOpen(true);
      };

      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const onConfirm = () => {
        model.renameTable = tableName;
        props.update(model);
        setIsModalOpen(false);
      }

      return (
        <>
          <ModalWrapper
            className={"createModel"}
            onCancel={handleCancel} open={isModalOpen}
            footer={null}
            maskClosable={false}
            title={"重新命名表名"}
            width={300}
            bodyStyle={{
              padding: 5,
              height: "120px",
            }}
          >
            <Form ref={formRef}>
              <FormItem
                label="表名"
                name='tableName'
                rules={[{
                  pattern: /^(?![0-9]+$)[A-Za-z_][A-Za-z_0-9]{0,}$/,
                  message: '只能是字母,数据和下划线组合且不能以数字开头!'
                }]}
              >
                <Input disabled={props.isView} onChange={(evt) => setTableName(evt.target.value)}/>
              </FormItem>
            </Form>

            <Button
              type={"primary"} style={{float: "right", margin: '5px'}}
              onClick={onConfirm}
              disabled={props.isView}
            >
              确定
            </Button>
          </ModalWrapper>
        </>
      );
    }
  )
;

export default RenameTableName;
