import * as Sentry from "@sentry/react";
import React from "react";
import { Route, Switch } from "react-router";
import XImportExcelWidget from "../../ce/sagas/ActionExecution/XImportExcelWidget";
import {VIEWER_PATH, VIEWER_PATH_SYSTEM} from "../../constants/routes";
import ViewerCanvas from "./ViewerCanvas";
import RoleManagerIndex from "@byk/pages/AppSystemInit/RoleManager";

const SentryRoute = Sentry.withSentryRouting(Route);

function MainContainer() {
  return (
    <>
      <Switch key={VIEWER_PATH}>
        <SentryRoute component={RoleManagerIndex} exact path={VIEWER_PATH_SYSTEM} />
        <SentryRoute component={ViewerCanvas} exact path={VIEWER_PATH} />
      </Switch>
      {/* 导入弹层 */}
      <XImportExcelWidget />
    </>
  );
}

MainContainer.displayName = "ViewerMainContainer";

export default MainContainer;
