import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout } from 'antd';
import BusinessPage from "@byk/pages/System/FieldManager/main";

const { Content, Sider } = Layout;

export default class Home extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         theme: "dark",
    //         current: "home"
    //     };
    // }

    // state = {
    //     collapsed: false,
    // };

    // toggleCollapsed = () => {
    //     this.setState({
    //         collapsed: !this.state.collapsed,
    //     });
    // };

    render() {
        return (
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head />
                    <Layout>
                        <Sider className="Sider-Navbar"><Navbar  /></Sider>
                        <Content><BusinessPage /></Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

