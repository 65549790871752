export default function Config ():string{
  return `import {AtIcon} from "taro-ui";
import React from "react";
import XBase from "./XBase";

function XIcon(props: any) {
  const { propertyPath, color, size, iconName, onClick } = props;
  const customStyle:any = {
    fontSize: size+'px', 
    color
  }
  return (
    <XBase propertyPath={propertyPath}>
      <AtIcon onClick={()=>{
        onClick&&onClick();
      }} customStyle={customStyle} value={iconName} />
    </XBase>)
}
export default XIcon;
  `;
}
