import {CSSProperties, HTMLAttributes, ReactNode} from "react";
import {ViewSectionRoot} from "./ViewSection.styled";
import Subhead from "@byk/pages/QueryBuilder/components/ui/type/Subhead";
import React from "react";

interface ViewSectionProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

const ViewSection = ({
                       className,
                       style,
                       children,
                     }: ViewSectionProps) => (
  <ViewSectionRoot className={className} style={style}>
    {children}
  </ViewSectionRoot>
);

interface ViewHeadingProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const ViewHeading = ({children, ...props}: ViewHeadingProps) => (
  <Subhead {...props}>{children}</Subhead>
);

interface ViewSubHeadingProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const ViewSubHeading = ({children, ...props}: ViewSubHeadingProps) => (
  <div className="text-medium text-bold" {...props}>
    {children}
  </div>
);

export default ViewSection;
