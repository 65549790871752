export default function Config ():string{
  return `import {AtList, AtListItem} from "taro-ui";
import React from "react";
import {useSelector} from "react-redux";
import XBase from "./XBase";
import _ from "lodash";

function XList(props: any) {
  const { propertyPath, style } = props;
  const listData = useSelector((state:any)=>{
    let dataTree = state.dataTreeReducer.dataTree;
    let widget = _.get(dataTree, propertyPath);
    let wData = widget.currentData;
    if(_.isEmpty(wData)){
      return [];
    }else if(_.isString(wData)){
      return JSON.parse(wData);
    }else{
      return wData;
    }
  })
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <AtList customStyle={customStyle}>
        { 
          listData.map((i:any, idx:number)=>{
            return <AtListItem key={idx} title={i.title} note={i.description} thumb={i.thumb} arrow='right' />
          })
         }
        </AtList>
    </XBase>)
}
export default XList;
  `;
}
