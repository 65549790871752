import React, {useCallback, useMemo, useState} from "react";
import {Container} from "./NotebookSteps.styled"
import NotebookStep from "@byk/pages/QueryBuilder/components/notebook/NotebookStep";
import {NotebookStep as INotebookStep, OpenSteps,} from "../types"
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {getQuestionSteps} from "@byk/pages/QueryBuilder/components/notebook/lib/steps";

interface NotebookStepsProps {
  className?: string;
  query: StructuredQuery;
  updateQuery: (query: any) => Promise<void>;
}

function getInitialOpenSteps(query: StructuredQuery): OpenSteps {
  const isNew = !query?.modelId();
  return isNew
    ? {
      "0:filter": false,
      "0:summarize": false,
    }
    : {};
}

function NotebookSteps({
                         className,
                         query,
                         updateQuery,
                       }: NotebookStepsProps) {
  const [lastOpenedStep, setLastOpenedStep] = useState<string | null>(null);
  const [openSteps, setOpenSteps] = useState<OpenSteps>(
    getInitialOpenSteps(query),
  );

  const steps = useMemo(() => {
    return getQuestionSteps(query, openSteps);
  }, [openSteps]);

  const handleStepOpen = useCallback((id: string) => {
    setOpenSteps(openSteps => ({...openSteps, [id]: true}));
    setLastOpenedStep(id);
  }, []);

  const handleStepClose = useCallback((id: string) => {
    setOpenSteps(openSteps => ({...openSteps, [id]: false}));
    setLastOpenedStep(lastOpenedStep =>
      lastOpenedStep === id ? null : lastOpenedStep,
    );
  }, []);

  const handleQueryChange = useCallback(async (step: INotebookStep, query: StructuredQuery) => {
      console.log("更新查询 -- [step, query]", step, query);
      const datasetQuery = query.dataQuery();
      const stepUpdateQuery = step.update(datasetQuery);
      handleStepClose(step.id);

      await updateQuery(query);
    },
    [handleStepClose],
  );

  return (
    <Container className={className}>
      {steps?.map((step: INotebookStep, index: any) => {
        const onChange = async (query: any) =>
          await handleQueryChange(step, query);

        return (
          <NotebookStep
            key={step.id + index}
            step={step}
            updateQuery={onChange}
            openStep={handleStepOpen}
            query={query}/>
        );
      })}
    </Container>
  );
}

export default NotebookSteps;
