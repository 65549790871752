import React from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout, Tree, Button, Row, Col, PageHeader, Popconfirm } from 'antd';
import BusinessPage from "@byk/pages/Tenant/RoleManager/main";

import { withRouter } from "react-router-dom";
import XModal from "@byk/pages/components/XModal";
import Api from "@byk/api/Api";
import logo from "../../../assets/svg/logo.svg";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
const { Content, Sider } = Layout;
import localStorage, {getTenantId} from "utils/localStorage";
import { getAuthority } from "@byk/utils/authority";
import {appTenantClass} from "@byk/utils/Utils";
const roleDataList: any = [
    { name: "员工", id: "staff" },
    { name: "项目经理", id: "manager" },
    { name: "前端开发", id: "frontend" },
    { name: "后端开发", id: "backend" },
    { name: "测试", id: "test" },
    { name: "业务经理", id: "BA" },
    // {name:"管理员",id:"admin"},
];
class RoleManager extends React.Component {
    modalRef: React.RefObject<any>;
    childRef: React.RefObject<any>;
    constructor(props: any) {
        super(props);
        this.modalRef = React.createRef();
        this.childRef = React.createRef();
    }
    state = {
        RoleData: [
        ],
        TenantId: getTenantId(),
        roleTitle: "角色成员",
        isAdmin: getAuthority().tenant == 1,
    };


    //async
    async componentDidMount() {
        this.init();
    }
    async init() {
        let result: any = await Api.get("/byk/platform/rest/TenantRole/list", { tenantId: this.state.TenantId });
        if (result.success) {
            let _r: any = result.result;
            let _r1: any = _r.filter((item: any) => {
                return item.roleCode != "admin";
            })
            let _r2: any = _r.filter((item: any) => {
                return item.roleCode == "admin";
            })
            this.setState({ RoleData: [..._r1, ..._r2] });
        }
    }
    changeTable(data: any) {
        this.setState({ roleTitle: data.roleName }, () => {
            this.childRef.callback(data);
        });
    }
    render() {
        const apiGet: string = "/byk/platform/rest/TenantRole/list";
        const apiPost: string = "/byk/platform/rest/TenantRole";
        const apiPut: string = "/byk/platform/rest/TenantRole";
        const apiDel: string = "/byk/platform/rest/TenantRole";
        //编辑按钮
        const addClick = () => {
            this.modalRef.current?.add();
        };
        const refreshTable = async () => {
            this.init();
        };

        //编辑按钮
        const EditClick = async (record: any) => {
            let result: any = await Api.get(apiPost, { id: record });
            if (result.success) {
                if (result.result.length > 0) {
                    let _record = result.result[0];
                    let { id, roleName, roleCode, description } = _record;
                    this.modalRef.current?.edit({
                        id, roleName, roleCode, description
                    });
                }
            }
        };
        //删除
        const deleteClick = async (record: any) => {
            let { id } = record;
            let result: any = await Api.delete(apiDel, [id]);
            if (result.success) {
                this.init();
            }
        };
        //新增表单项
        const ModalConfig: any[] = [
            {
                itemType: "input",
                placeholder: "请输入",
                name: "roleName",
                label: "角色名",
                allowClear: true,
                col: 24,
                maxLength: 100,
                rules: [{ required: true, message: "请输入!" }],
            },
            {
                itemType: "select",
                placeholder: "请输入",
                name: "projectJob",
                label: "对应岗位",
                allowClear: true,
                col: 24,
                rules: [{ required: true, message: "请输入!" }],
                data: roleDataList,
            },
            {
                itemType: "textarea",
                placeholder: "请输入",
                name: "description",
                label: "备注",
                allowClear: true,
                maxLength: 200,
                col: 24,

            }
        ];

        //改造提交的data
        const reformData = (params?: any, type?: any) => {
            if (type == 0) {
                params.tenantId = this.state.TenantId;
            }
            params.roleCode = params.projectJob;
            return params;
        };
        //modal 表单配置
        const modalProps = {
            apiPost,
            apiPut,
            option: ModalConfig,
            refreshTable,
            isShowAdd: false,
            reformData
        };

        const titleComponent = (item: any) => {
            return <div className={"x-menu-normal  x-menu-default"} onClick={() => { this.changeTable(item) }} style={{ position: "relative", justifyContent: "left", border: "none" }} >{item.roleName}
                {this.state.isAdmin && item.roleCode != "admin" && <>
                    <Popconfirm placement="right" title={"确认删除吗？"} onConfirm={() => deleteClick(item)} okText="确定" cancelText="取消">
                        <DeleteOutlined style={{ border: "none", position: "absolute", right: "3px", color: "#1890ff",padding:"2px"}} />
                        {/* <Button style={{ border: "none", position: "absolute", right: "0px",color:"#1890ff" }} shape="circle" icon={<DeleteOutlined />} /> */}
                    </Popconfirm>
                    {/* <Button style={{ border: "none", position: "absolute", right: "25px",color:"#1890ff" }} onClick={() => { EditClick(item.id) }} shape="circle" icon={<FormOutlined />} /> */}
                    <FormOutlined style={{ border: "none", position: "absolute", right: "25px", color: "#1890ff" ,padding:"2px"}} onClick={() => { EditClick(item.id) }} />

                </>}


            </div>;
        };

        return (
            <div >
                <Layout>
                    {/*引入头部 */}
                    <Head title="TenantRoleManager" logoSrc={`${logo}?3`} />
                    <Layout>
                        <Sider className="Sider-Navbar"><Navbar title="TenantRoleManager" /></Sider>
                        <Content>
                            <PageHeader
                                className="site-page-header"
                                title="角色管理"
                            />
                            <Layout>
                                <Sider style={{ width: "300px" }} className="x-border-right" >
                                    {this.state.isAdmin && <Button className="x-mt20" type="link" onClick={() => addClick()}>+新增角色</Button>}
                                    <Tree
                                        style={{ marginTop: "10px" }}
                                        showLine
                                        defaultExpandAll={true}
                                        defaultExpandedKeys={["0-0-0"]}
                                        fieldNames={{ title: "roleName", key: "id", children: "children" }}
                                        treeData={this.state.RoleData}
                                        titleRender={(data: any) => {
                                            return (
                                                <div style={{ width: "170px" }}>
                                                    <Row>
                                                        <Col span={24}  >
                                                            {titleComponent(data)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        }}
                                    />
                                </Sider>
                                <Content>
                                    <PageHeader
                                        className="site-page-header"
                                        title={this.state.roleTitle}
                                    />
                                    <BusinessPage isAdmin={this.state.isAdmin} onRef={node => this.childRef = node} />
                                </Content>
                            </Layout>
                        </Content>
                    </Layout>
                </Layout>
                <XModal ref={this.modalRef} {...modalProps} />
            </div>
        );
    }
}

export default withRouter(RoleManager);
