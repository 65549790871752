import React, {useState} from "react";
import {SqlButton} from "./ConvertQueryButton.styled";
import {message, Modal} from "antd";
import NativeCodePanel from "@byk/pages/QueryBuilder/components/view/NativeCodePanel";
import {useSelector} from "react-redux";
import {getQuery} from "@byk/pages/QueryBuilder/selectors";
import Schemas from "@byk/pages/QueryBuilder/lib/api/schemas";

const ConvertQueryButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const query = useSelector(getQuery)
  const [sql, setSql] = useState<any>("");
  const [sqlMessage, setSqlMessage] = useState<any>("");

  const handleClick = async () => {
    if (!query.valid()) {
      message.warning('请选择数据！');
    } else {
      let result = await Schemas.genSql(query);
      if (result.success) {
        if (result && result.result) {
          setSql(result.result[0]);
          setSqlMessage(result.message);
        }
      }
      setOpen(true);
    }
  };

  const onCloseModal = () => {
    setOpen(false);
  }

  return (
    <>
      <SqlButton
        onClick={handleClick}
        aria-label='查看SQL'
      >
        <svg className="Icon Icon-sql e1ufrucr0 e621b520 css-151etsu e621b521" viewBox="0 0 32 32" width="16"
             height="16" fill="currentcolor" role="img" aria-label="sql icon" fill-rule="evenodd">
          <path
            d="M4,0 L28,0 C30.209139,-4.05812251e-16 32,1.790861 32,4 L32,28 C32,30.209139 30.209139,32 28,32 L4,32 C1.790861,32 2.705415e-16,30.209139 0,28 L0,4 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L4,0 Z M6,6 C4.8954305,6 4,6.8954305 4,8 L4,26 C4,27.1045695 4.8954305,28 6,28 L26,28 C27.1045695,28 28,27.1045695 28,26 L28,8 C28,6.8954305 27.1045695,6 26,6 L6,6 Z M14,20 L25,20 L25,24 L14,24 L14,20 Z M14,13.5 L8,17 L8,10 L14,13.5 Z"></path>
        </svg>
      </SqlButton>


      <Modal open={open}
             footer={null}
             closable={false}
             bodyStyle={{
               padding: '5px',
               paddingBottom: '35px'
             }}>
        <NativeCodePanel onCloseModal={onCloseModal} value={sql} sqlMessage={sqlMessage}/>
      </Modal>
    </>
  );
};

export default ConvertQueryButton;
