import type { ChangeEvent } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { JSAction, JSCollection } from "entities/JSCollection";
import CloseEditor from "components/editorComponents/CloseEditor";
import MoreJSCollectionsMenu from "../Explorer/JSActions/MoreJSActionsMenu";
import type { DropdownOnSelect } from "design-system-old";
import { Tooltip, Select, Cascader } from "antd";
import {
  CodeEditorBorder,
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/editorComponents/CodeEditor/EditorConfig";
import JSObjectNameEditor from "./JSObjectNameEditor";
import {
  setActiveJSAction,
  setJsPaneConfigSelectedTab,
  startExecutingJSFunction,
  updateJSCollectionBody,
} from "actions/jsPaneActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import type { ExplorerURLParams } from "@appsmith/pages/Editor/Explorer/helpers";
import JSResponseView from "components/editorComponents/JSResponseView";
import _, { isEmpty } from "lodash";
import Api from 'api/Api';
import equal from "fast-deep-equal/es6";
import { ENTITY_TYPE } from "entities/DataTree/dataTreeFactory";
import { JSFunctionRun } from "./JSFunctionRun";
import type { AppState } from "@appsmith/reducers";
import {
  getActiveJSActionId,
  getIsExecutingJSAction,
  getJSActions,
  getJSCollectionParseErrors,
} from "selectors/entitiesSelector";
import type { JSActionDropdownOption } from "./utils";
import {
  convertJSActionToDropdownOption,
  getActionFromJsCollection,
  getJSActionOption,
  getJSFunctionLineGutter,
  getJSPropertyLineFromName,
} from "./utils";
import JSFunctionSettingsView from "./JSFunctionSettings";
import {
  Form,
  FormWrapper,
  TabbedViewContainer,
} from "./styledComponents";
import { getJSPaneConfigSelectedTab } from "selectors/jsPaneSelectors";
import type { EventLocation } from "utils/AnalyticsUtil";
import {
  hasDeleteActionPermission,
  hasExecuteActionPermission,
  hasManageActionPermission,
} from "@appsmith/utils/permissionHelpers";
import {
  setCodeEditorCursorAction,
  setFocusableInputField,
} from "actions/editorContextActions";
import history from "utils/history";
import { CursorPositionOrigin } from "reducers/uiReducers/editorContextReducer";
import LazyCodeEditor from "components/editorComponents/LazyCodeEditor";
import styled from "styled-components";
import { showDebuggerFlag } from "selectors/debuggerSelectors";
import { Tab, TabPanel, Tabs, TabsList } from "design-system";
import { JSEditorTab } from "reducers/uiReducers/jsPaneReducer";
import SearchSnippets from "../../common/SearchSnippets";
import { batchUpdateMultipleWidgetProperties } from "../../../actions/controlActions";

interface JSFormProps {
  jsCollection: JSCollection;
}

type Props = JSFormProps;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 64px);
  width: 100%;
`;

const SecondaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  &&& {
    .ads-v2-tabs,
    &.js-editor-tab {
      height: 100%;
    }
    .js-editor-tab .js-editor {
      padding-top: var(--ads-v2-spaces-4);
    }
  }
`;

function JSEditorForm({ jsCollection: currentJSCollection }: Props) {
  const theme = EditorTheme.LIGHT;
  const dispatch = useDispatch();
  const { pageId } = useParams<ExplorerURLParams>();
  const { hash } = useLocation();
  const [appActions, setAppActions] = useState([]);
  const [jsActionsList, setJsActionsList] = useState([])
  const [disableRunFunctionality, setDisableRunFunctionality] = useState(false);
  const reducerActions = useSelector(
    (state: AppState) => state.entities.jsActions,
  );
  const currentCanvasDSL = useSelector(state=>state.entities.canvasWidgets);

  // Currently active response (only changes upon execution)
  const [activeResponse, setActiveResponse] = useState<JSAction | null>(null);
  const parseErrors = useSelector(
    (state: AppState) =>
      getJSCollectionParseErrors(state, currentJSCollection.name),
    equal,
  );
  const jsActions = useSelector(
    (state: AppState) => getJSActions(state, currentJSCollection.id),
    equal,
  );
  const activeJSActionId = useSelector((state: AppState) =>
    getActiveJSActionId(state, currentJSCollection.id),
  );

  const activeJSAction = getActionFromJsCollection(
    activeJSActionId,
    currentJSCollection,
  );

  const [selectedJSActionOption, setSelectedJSActionOption] =
    useState<JSActionDropdownOption>(
      getJSActionOption(activeJSAction, jsActions),
    );

  const isExecutingCurrentJSAction = useSelector((state: AppState) =>
    getIsExecutingJSAction(
      state,
      currentJSCollection.id,
      selectedJSActionOption.data?.id || "",
    ),
  );


  useEffect(() => {
    if (hash) {
      // Hash here could mean to navigate (set cursor/focus) to a particular function
      // If the hash has a function name in this JS object, we will set that
      const actionName = hash.substring(1);
      const position = getJSPropertyLineFromName(
        currentJSCollection.body,
        actionName,
      );
      if (position) {
        // Resetting the focus and position based on the cmd click navigation
        dispatch(setFocusableInputField(`${currentJSCollection.name}.body`));
        dispatch(
          setCodeEditorCursorAction(
            `${currentJSCollection.name}.body`,
            position,
            CursorPositionOrigin.Navigation,
          ),
        );
        // Replace to remove the hash and set back the original URL
        history.replace(window.location.pathname + window.location.search);
      }
    }
  }, [hash]);

  const isChangePermitted = hasManageActionPermission(
    currentJSCollection?.userPermissions || [],
  );
  const isExecutePermitted = hasExecuteActionPermission(
    currentJSCollection?.userPermissions || [],
  );
  const isDeletePermitted = hasDeleteActionPermission(
    currentJSCollection?.userPermissions || [],
  );

  // Triggered when there is a change in the code editor
  const handleEditorChange = (valueOrEvent: ChangeEvent<any> | string) => {
    const value: string =
      typeof valueOrEvent === "string"
        ? valueOrEvent
        : valueOrEvent.target.value;

    dispatch(updateJSCollectionBody(value, currentJSCollection.id));
  };

  // Executes JS action
  const executeJSAction = (jsAction: JSAction, from: EventLocation) => {
    setActiveResponse(jsAction);
    if (jsAction.id !== selectedJSActionOption.data?.id)
      setSelectedJSActionOption(convertJSActionToDropdownOption(jsAction));
    dispatch(
      setActiveJSAction({
        jsCollectionId: currentJSCollection.id || "",
        jsActionId: jsAction.id || "",
      }),
    );
    dispatch(
      startExecutingJSFunction({
        collectionName: currentJSCollection.name || "",
        action: jsAction,
        collectionId: currentJSCollection.id || "",
        from: from,
      }),
    );
  };

  const handleActiveActionChange = useCallback(
    (jsAction: JSAction) => {
      if (!jsAction) return;

      // only update when there is a new active action
      if (jsAction.id !== selectedJSActionOption.data?.id) {
        setSelectedJSActionOption(convertJSActionToDropdownOption(jsAction));
      }
    },
    [selectedJSActionOption],
  );

  const JSGutters = useMemo(
    () =>
      getJSFunctionLineGutter(
        jsActions,
        executeJSAction,
        !parseErrors.length,
        handleActiveActionChange,
        isExecutePermitted,
      ),
    [jsActions, parseErrors, handleActiveActionChange, isExecutePermitted],
  );

  const handleJSActionOptionSelection: DropdownOnSelect = (value) => {
    if (value) {
      const jsAction = getActionFromJsCollection(value, currentJSCollection);
      if (jsAction) {
        setSelectedJSActionOption({
          data: jsAction,
          value,
          label: jsAction.name,
        });
      }
    }
  };

  const handleRunAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | KeyboardEvent,
    from: EventLocation,
  ) => {
    event.preventDefault();
    if (
      !disableRunFunctionality &&
      !isExecutingCurrentJSAction &&
      selectedJSActionOption.data
    ) {
      executeJSAction(selectedJSActionOption.data, from);
    }
  };

  useEffect(() => {
    if (parseErrors.length || isEmpty(jsActions)) {
      setDisableRunFunctionality(true);
    } else {
      setDisableRunFunctionality(false);
    }
  }, [parseErrors, jsActions, activeJSActionId]);

  useEffect(() => {
    // update the selectedJSActionOption when there is addition or removal of jsAction or function
    setSelectedJSActionOption(getJSActionOption(activeJSAction, jsActions));
  }, [jsActions, activeJSActionId]);

  const blockCompletions = useMemo(() => {
    if (selectedJSActionOption.label) {
      const funcName = `${selectedJSActionOption.label}()`;
      return [
        {
          parentPath: "this",
          subPath: funcName,
        },
        {
          parentPath: currentJSCollection.name,
          subPath: funcName,
        },
      ];
    }
    return [];
  }, [selectedJSActionOption.label, currentJSCollection.name]);

  const selectedConfigTab = useSelector(getJSPaneConfigSelectedTab);

  // Debugger render flag
  const showDebugger = useSelector(showDebuggerFlag);

  const setSelectedConfigTab = useCallback((selectedTab: JSEditorTab) => {
    dispatch(setJsPaneConfigSelectedTab(selectedTab));
  }, []);

  useEffect(()=>{
    const promise = Api.get(`/byk/platform/rest/AppAction/list`, {
      pageId,
      actionType: 'requirement'
    });
    promise.then((res:any)=>{
      let result = res.result;
      let newResult:any = [];
      _.each(result, (i:any)=>{
        let [widgetId, widgetEvent] = i.elementId.split('_');
        let eventStr = currentCanvasDSL[widgetId]?.[widgetEvent];
        let _value:any = [];
        if(!isEmpty(eventStr)){
          let eventObj = JSON.parse(eventStr);
          _.each(eventObj, (j:any)=>{
            if(j.actionId == i.id){
              _value = j.jsPath||[];
            }
          })
        }
        newResult.push({
          id: i.id,
          actionId: i.id,
          elementName: i.elementName,
          elementId: i.elementId,
          widgetId,
          widgetEvent,
          description: i.description,
          value: _value
        })
      })
      //console.log('newResult', newResult);
      setAppActions(newResult);
    })
  }, [pageId]);
  useEffect(()=>{
    //初始化 jslist
    let jsOpt:any = [];
    _.each(reducerActions, (i:any)=>{
      if(i.config.pageId == pageId){
        jsOpt.push({
          value: i.config.name,
          label: i.config.name,
          children: i.config.actions.map((j:any)=>{
            return {
              value: j.name,
              label: j.name
            }
          })
        })
      }
    })
    setJsActionsList(jsOpt);
  }, [reducerActions])
  const handleChange = (value:any, item:any )=> {
    let _appActions = [...appActions];
    _.each(_appActions, (i:any)=>{
      if(i.elementId === item.elementId){
        i.value = value;
      }
    });
    setAppActions(_appActions);
    //修改page-dsl
    const {actionId, widgetId, widgetEvent} = item;
    //console.log(actionId, widgetId, widgetEvent);
    let eventStr = currentCanvasDSL[widgetId][widgetEvent];
    //console.log('-------------------------------', eventStr);
    let modify:any = {}
    if(!isEmpty(eventStr)){
      let eventObj = JSON.parse(eventStr);
      _.each(eventObj, (j:any)=>{
        if(j.actionId == actionId){
          j.jsPath = value;
        }
      })
      modify[widgetEvent] = JSON.stringify(eventObj);
    }

    //触发保存
    dispatch(batchUpdateMultipleWidgetProperties([
      {
        updates: {
          modify
        },
        widgetId
      }
    ]));
  }
  const AppActionsList = ()=>{
    return (
      <div style={{margin:'30px 0 0 20px', border: '1px solid #ccc', borderBottom: 'none'}}>
        {
          appActions.map((i:any)=>{
            return (
              <div key={i.id} style={{padding:'20px 10px', borderBottom: '1px solid #ccc'}}>
                <div style={{fontWeight: 'bold', marginBottom:'5px'}}>{i.elementName}</div>
                <Tooltip title={i.description}>
                  <div>
                    <span>{i.description.substring(0, 10)}</span>
                    <span style={{color: '#3987FF', cursor: 'pointer'}}> 详情</span>
                  </div>
                </Tooltip>
                <Cascader style={{marginTop: '5px'}} value={i.value} onChange={(value)=>{
                  handleChange(value, i)
                }} options={jsActionsList} placeholder="绑定js" />
              </div>
            )
          })
        }
      </div>
    );
  }
  return (
    <FormWrapper>
      <CloseEditor />
      <div style={{display: 'flex'}}>
        <div style={{width: '300px'}}>
          <AppActionsList />
        </div>
        <Form onSubmit={(event) => event.preventDefault()}>
          <Wrapper>
            <div className="flex flex-1">
              <SecondaryWrapper>
                <TabbedViewContainer isExecuting={isExecutingCurrentJSAction}>
                  <Tabs
                    defaultValue={JSEditorTab.CODE}
                    onValueChange={(string) =>
                      setSelectedConfigTab(string as JSEditorTab)
                    }
                    value={selectedConfigTab}
                  >
                    <TabsList>
                      <Tab
                        data-testid={`t--js-editor-` + JSEditorTab.CODE}
                        value={JSEditorTab.CODE}
                      >
                        Code
                      </Tab>
                      <Tab
                        data-testid={`t--js-editor-` + JSEditorTab.SETTINGS}
                        value={JSEditorTab.SETTINGS}
                      >
                        Settings
                      </Tab>
                    </TabsList>
                    <TabPanel value={JSEditorTab.CODE}>
                      <div className="js-editor-tab">
                        <LazyCodeEditor
                          blockCompletions={blockCompletions}
                          border={CodeEditorBorder.NONE}
                          borderLess
                          className={"js-editor"}
                          customGutter={JSGutters}
                          dataTreePath={`${currentJSCollection.name}.body`}
                          disabled={!isChangePermitted}
                          folding
                          height={"100%"}
                          hideEvaluatedValue
                          input={{
                            value: currentJSCollection.body,
                            onChange: handleEditorChange,
                          }}
                          isJSObject
                          jsObjectName={currentJSCollection.name}
                          mode={EditorModes.JAVASCRIPT}
                          placeholder="Let's write some code!"
                          showLightningMenu={false}
                          showLineNumbers
                          size={EditorSize.EXTENDED}
                          tabBehaviour={TabBehaviour.INDENT}
                          theme={theme}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value={JSEditorTab.SETTINGS}>
                      <div className="js-editor-tab">
                        <JSFunctionSettingsView
                          actions={jsActions}
                          disabled={!isChangePermitted}
                        />
                      </div>
                    </TabPanel>
                  </Tabs>
                </TabbedViewContainer>
              </SecondaryWrapper>
            </div>
          </Wrapper>
        </Form>
      </div>
    </FormWrapper>
  );
}

export default JSEditorForm;
