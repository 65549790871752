import React, {useEffect, useState} from "react";
import type {ControlProps} from "./BaseControl";
import BaseControl from "./BaseControl";
import {Button, Drawer, Select} from "antd";
import {EditOutlined} from "@ant-design/icons/lib";
import DictManager from '@byk/pages/AppSystemInit/DictManager';
import Api from "@byk/api/Api";
import localStorage, {getTenant} from "utils/localStorage";
import {useDispatch} from "react-redux";
import {syncBatchUpdateWidgetMetaProperties} from "../../actions/metaActions";
import {apiPath} from "@byk/routes";

export const getSysDataDict = (params: any = {}) => {
  return Api.get(`${apiPath}/${getTenant()}/subapp/SysDataDict/list`, params);
}
export const getSysDataDictItem = (params: any = {}) => {
  params.enable=true;
  return Api.get(`${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`, params);
}

export const getSysDataDictItemAsync = async (params: any = {}) => {
  params.enable = true;
  let result = await Api.get(`${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`, params);
  return result;
}

const SelectDict = (props: any) => {
  const {
    valueChange,
    placeholderText,
    selectId,
    widgetProperties,
    mark,
    propertyName
  } = props;
  //alert(mark);
  const {
    widgetId,
    defaultOptionValue,
    defaultSelectedValues,
    type
  } = widgetProperties;
  const [open, setOpen] = useState(false);
  const [selectData, setSelectData] = useState<any>([]);
  const [selectValue, setSelectValue] = useState();
  const [isSubTable, setIsSubTable] = useState(mark&&mark=="subTableProperty");
  const [dictItems, setDicItems] = useState([]);
  let defaultVal = defaultOptionValue || defaultSelectedValues;
  if (defaultOptionValue && defaultOptionValue.indexOf('{{') > -1) {
    defaultVal = undefined;
  }
  if (defaultSelectedValues && defaultSelectedValues.indexOf && defaultSelectedValues.indexOf('{{') > -1) {
    defaultVal = undefined;
  }

  const [dictItemDefaultValue, setDictItemDefaultValue] = useState(defaultVal);
  const isMulti = type === 'MULTI_SELECT_WIDGET_V2' || type === 'CHECKBOX_GROUP_WIDGET';
  const dispatch = useDispatch();
  const openDictPopup = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    getDicts();
  }

  const handleChange = (value: any) => {
    if (value == 'edit') {
      setSelectValue(selectValue);
      openDictPopup();
    }else if(value === undefined){
      //清空字典选项时，清除字典onLoad和默认值
      setSelectValue(undefined);
      setDictItemDefaultValue(undefined);
      valueChange({
        dictOnLoad: undefined,
        selectId: undefined
      })
    } else {
      //每次字典选项改变时，重置字典选项的子项
      setDictItemDefaultValue(undefined);
      //doValueChange(undefined);
      //设置选项值和查询选项值对应的子项
      getDictsItems(value, true);
    }
  }
  const getDictsItems = (value:any, bol:boolean=false)=>{
    setSelectValue(value);
    getSysDataDictItem({dict: value, asc: "sort,createDate"}).then((res: any) => {
      if (res.success) {
        let dictItems = res.result.map((i: any) => {
          return {
            id: i.id,
            label: i.name,
            value: i.code
          }
        })
        let _onLoad = {
          name: '',
          children: [
            {
              type: 'block',
              children: [
                {
                  type: 'action',
                  actionConfig: {
                    "action": "dict",
                    "message": "",
                    "actionId": "dict",
                    "dictId": value,
                    "apiTarget": widgetId,
                  }
                }
              ]
            }
          ]
        };
        //设置选项的子项
        setDicItems(dictItems);
        //回写到组件上的属性
        let _m:any={
          dictOnLoad: _onLoad,
          selectId: value
        };
        if(bol){
          _m.defaultOptionValue = [];
          _m.defaultSelectedValues = [];
        }

        dispatch(syncBatchUpdateWidgetMetaProperties([{
          widgetId,
          propertyName,
          propertyValue: dictItems,
        }]))

        if(isSubTable){
          _m={};
          const lastIndex = propertyName.lastIndexOf('.');
          let _selectIdProperty:any=(lastIndex !== -1 ? propertyName.substring(0, lastIndex) : propertyName)+".selectId";
          _m[propertyName]=value;
          _m[_selectIdProperty]=value;
        }
        valueChange(_m);
      }
    });
  }
  const getDicts = ()=>{
    //初始化字典选项
    getSysDataDict({
      tenant: getTenant(),
      asc: "sort,createDate"
    }).then((res: any) => {
      if (res.success) {
        let selectMap = res.result.map((i: any) => {
          return {
            id: i.id,
            label: i.name,
            value: i.id
          }
        })
        selectMap.push({
          value: 'edit',
          label: (
            <Button style={{paddingLeft: '0', paddingRight: '0'}} type="link" icon={<EditOutlined/>}>编辑数据字典</Button>),
        })

        setSelectData(selectMap)

      }
    })
  }
  useEffect(() => {
    getDicts();
  }, []);

  useEffect(()=>{
    //有字典选项且有值时，默认选项值
    if(selectData.length>0&&selectId){
      getDictsItems(selectId);
    }
  }, [selectData]);

  const handleChangeDefault = (value: any) => {
    doValueChange(value)
  }

  const doValueChange = (value: any) => {
    setDictItemDefaultValue(value)
    valueChange({
      defaultOptionValue: value,
      defaultSelectedValues: value,
    });
  }

  return (
    <>
      <Select
        placeholder={placeholderText}
        value={selectValue}
        style={{width: '100%'}}
        onChange={handleChange}
        options={selectData}
        allowClear
      />
      <Drawer width='80%' title="编辑数据字典" placement="right" open={open} onClose={onClose}>
        <DictManager hideHead={true} hideMenu={true}/>
      </Drawer>
      {
        (!isSubTable)&&
        <>
          <p style={{marginTop:"8px"}}>默认选定值</p>
          <div>
            <Select
              mode={isMulti ? 'multiple' : undefined}
              style={{width: '100%'}}
              options={dictItems}
              onChange={handleChangeDefault}
              value={dictItems&&dictItems.length>0?dictItemDefaultValue:undefined}
              allowClear
            />
          </div>
        </>
      }

    </>

  )
}


class SelectDictControl extends BaseControl<SelectDictControlProps> {
  render() {
    return <SelectDict {...this.props} valueChange={this.valueChange}/>;
  }

  valueChange = (result: any) => {
    this.batchUpdateProperties(result);
  }

  static getControlType() {
    return "SELECT_DICT_CONTROL";
  }
}

export interface SelectDictControlProps extends ControlProps {
  placeholderText?: string,
  selectId?: any
}

export default SelectDictControl;
