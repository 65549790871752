import {ColumnItem, ToggleItem} from "./FieldPicker.styled";
import StackedCheckBox from "@byk/pages/QueryBuilder/components/ui/StackedCheckBox";
import CheckBox from "@byk/pages/QueryBuilder/components/ui/CheckBox";
import React from "react";
import {ColumnDisplayInfo} from "@byk/pages/QueryBuilder/components/notebook/types";

interface FieldPickerProps {
  columnsInfo: ColumnDisplayInfo[];
  isAll: boolean;
  isNone: boolean;
  isDisabledDeselection?: boolean;
  onToggle: (columnIndex: number, isSelected: boolean) => void;
  onSelectAll: () => void;
  onSelectNone: () => void;
}

export const FieldPicker = ({
                              columnsInfo,
                              isAll,
                              isNone,
                              isDisabledDeselection,
                              onToggle,
                              onSelectAll,
                              onSelectNone,
                            }: FieldPickerProps) => {
  const handleLabelToggle = () => {
    if (isAll) {
      onSelectNone();
    } else {
      onSelectAll();
    }
  };

  return (
    <ul>
      <ToggleItem>
        <StackedCheckBox
          className=""
          label={isAll ? `全部不选` : `全部选中`}
          checked={isAll}
          indeterminate={!isAll && !isNone}
          onChange={handleLabelToggle}
        />
      </ToggleItem>
      {columnsInfo.map((columnInfo, columnIndex) => (
        <ColumnItem key={columnIndex}>
          <CheckBox
            checked={columnInfo.selected}
            label={columnInfo.description}
            disabled={columnInfo.selected && isDisabledDeselection}
            onChange={event => onToggle(columnIndex, event.target.checked)}
          />
        </ColumnItem>
      ))}
    </ul>
  );
};
