export type DatePickerType = "DATE_PICKER" | "DATE_RANGE_PICKER";
export const YYYY = "YYYY"
export const YYYY_MM = "YYYY-MM"
export const YYYY_MM_DD = "YYYY-MM-DD"
export const YYYY_MM_DD_HH_MM = "YYYY-MM-DD HH:mm"
export const YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss"
export const HH_MM_SS = "HH:mm:ss"
export const HH_MM = "HH:mm"
export const MM_DD_YYYY = "MM/DD/YYYY"
export const MM_DD_YYYY_HH_MM = "MM/DD/YYYY HH:mm"
export const MM_DD_YYYY_HH_MM_SS = "MM/DD/YYYY HH:mm:ss"

export const pickerMap:any = {
  "YYYY":"year",
  "YYYY-MM":"month",
  "YYYY-MM-DD":"date",
  "YYYY-MM-DD HH:mm":"date",
  "YYYY-MM-DD HH:mm:ss":"date",
  "HH:mm:ss":"time",
  "HH:mm":"time",
  "MM/DD/YYYY":"date",
  "MM/DD/YYYY HH:mm":"date",
  "MM/DD/YYYY HH:mm:ss":"date",
}

export enum TimePrecision {
  NONE = "None",
  MILLISECOND = "millisecond",
  MINUTE = "minute",
  SECOND = "second",
}

export const isTimePicker = (format:string)=>{
  return pickerMap[format] == 'time'
}

export const datePickerShowTime = (format:string) => {
  if (!isTimePicker(format)) {
    if (format.indexOf("HH:mm:ss") > -1) {
      return {format: 'HH:mm:ss'};
    }else if (format.indexOf("HH:mm") > -1) {
      return {format: 'HH:mm'};
    }
  }

  return false;
}

