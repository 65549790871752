import React, { Component } from "react";
import BusinessPage from "@byk/pages/MyTask/index";
import { withRouter } from "react-router-dom";

class DevTask extends React.Component {
    render() {
        return (
            <>
               <BusinessPage source="DevTask"/>      
            </>
        );
    }
}

export default withRouter(DevTask);