import {useMount} from "react-use";
import {useDispatch, useSelector} from "react-redux";
import "@byk/pages/QueryBuilder/lib/css/index.css";
import View from "../components/view/View";
import {getQuery} from "@byk/pages/QueryBuilder/selectors";
import Schemas from "@byk/pages/QueryBuilder/lib/api/schemas";
import {QUERY_STRUCT} from "@byk/store/modelingReducers";
import React, {useEffect, useState} from "react";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";

function QueryBuilder() {
  const [query, setQuery] = useState<StructuredQuery>(useSelector(getQuery));
  const [cacheFlag, setCacheFlag] = useState(false);

  const updateQueryCallback = (query: StructuredQuery) => {
    setQuery(query);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: QUERY_STRUCT, data: query
    })
  }, [cacheFlag, query]);

  useMount(async () => {
    if (!query.hasModels()) {
      let models = await Schemas.fetchModelList();
      query.setModels(models);
      setQuery(query);
      setCacheFlag(true);
    }
  });

  return (
    <>
      {query.hasModels() &&
        <View className={'mt2'} query={query} updateQuery={updateQueryCallback}/>
      }

      {!query.hasModels() &&
        <div style={{
          marginLeft: "30px",
          marginTop: "30px"
        }}>该应用没有模型......</div>
      }
    </>
  );
}

export default QueryBuilder;
