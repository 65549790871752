export default function Config ():string{
  return `
  {
  "miniprogramRoot": "./dist",
  "projectname": "taroApp",
  "description": "",
  "appid": "touristappid",
  "setting": {
    "urlCheck": true,
    "es6": false,
    "enhance": false,
    "compileHotReLoad": false,
    "postcss": false,
    "minified": false
  },
  "compileType": "miniprogram"
}

  `;
}
