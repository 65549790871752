import Notebook from "@byk/pages/QueryBuilder/components/notebook/Notebook";
import {NotebookContainer} from "./QueryViewNotebook.styled";

const delayBeforeNotRenderingNotebook = 10;

const QueryViewNotebook = ({isNotebookContainerOpen, ...props}) => {
  return (
    <NotebookContainer
      isOpen={isNotebookContainerOpen}
      transitionTime={delayBeforeNotRenderingNotebook}
    >
      <Notebook {...props} />
    </NotebookContainer>
  );
};

export default QueryViewNotebook;
