import React, { useRef, useState, useEffect } from 'react';
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import XModal from "@byk/pages/components/XModal";
import type { ColumnsType } from 'antd/es/table';
import { Space, Button } from 'antd';
import Api from '@byk/api/Api';
import axios from 'axios';

const Crud = () => {
    const searchRef = useRef<any>();
    const modalRef = useRef<any>();
    const tableRef = useRef<any>();

    //表格refresh
    const refreshTable = (params?: any) => {
        debugger;
        tableRef.current?.getTableList(params);
    };

    //编辑按钮
    const EditClick = (record: any) => {
        let { id, name, code } = record;
        modalRef.current?.edit({
            id,
            name,
            code
        });
    };
    // api
    const apiGet: string = '/byk/public/sysTenant';
    const apiPost: string = '/byk/public/addSysTenant';
    const apiPut: string = '/byk/public/updateSysTenant';
    const apiDel: string = '/byk/public/deleteSysTenant';
    //删除
    const deleteClick = async (record: any) => {
        let { id } = record;
        let result = await Api.delete(apiDel, [id]);
        if (result.success) {
            refreshTable();
        }
    };
    //查询项配置
    const searchConfig: any[] = [
        {
            itemType: 'input',
            placeholder: '请输入',
            name: 'code',
            label: '提交人',
            allowClear: true,
            col: 8
        }
    ];
    const searchProps = {
        option: searchConfig,
        onSearch: refreshTable,
        btnBoolean: true
    };

    //新增表单项
    const ModalConfig: any[] = [
        {
            itemType: 'input',
            placeholder: '请输入',
            name: 'code',
            label: '组织代码',
            allowClear: true,
            col: 24,
            rules: [{ required: true, message: 'Please input your username!' }]
        },
        {
            itemType: 'input',
            placeholder: '请输入',
            name: 'name',
            label: '名称',
            allowClear: true,
            col: 24,
            /*rules: [
                         { required: true, message: '学生编码不能为空' },
                         { validator: (rule:any, value:any) => {
                             let reg = new RegExp(/[\u4E00-\u9FA5]/);
                             if(reg.test(value)) {
                                 return Promise.reject('学生编码不能为汉字');
                             } else {
                                 return Promise.resolve();
                             }
                         }},
                   ]*/
        }/*,
                 {
                              itemType: 'Upload',
                              picker: '',
                              placeholder: '请输入',
                              name: 'aaa',
                              label: 'aaa',
                              allowClear: true,
                              col: 24,
                              action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
                            onChange(file:any) {
                                if (file.status !== 'uploading') {
                                    console.log(file);
                                }
                            },
                            defaultFileList: [
                                {
                                    uid: '1',
                                    name: 'xxx.png',
                                    status: 'done',
                                    response: 'Server Error 500',
                                    url: 'http://www.baidu.com/xxx.png',
                                }
                            ]
                 },*/

    ];

    //表格配置
    const columns: ColumnsType = [
        {
            title: '编码',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => EditClick(record)}>
                        编辑
                    </Button>
                    <Button type="link" onClick={() => deleteClick(record)}>
                        删除
                    </Button>
                </Space>
            ),
            width: 100
        }
    ];
    const tableProps = {
        columns: columns,
        api: apiGet,
        pagination: {
            currPage: 1,
            pageSize: 10
        }
    };

    //modal 表单配置
    const modalProps = {
        apiPost,
        apiPut,
        option: ModalConfig,
        refreshTable
    };
    return (
        <>
            <div className='x-main-box'>
                <SearchForm ref={searchRef} {...searchProps} />
                <XModal ref={modalRef} {...modalProps} />
                <TableList ref={tableRef} {...tableProps} />
            </div>
        </>
    )
}

export default Crud;
