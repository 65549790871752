import React, { useState } from "react";

import type { AppTheme } from "entities/AppTheming";
import {Option, Select, Text, ToggleButtonGroup, Tooltip} from "design-system";
import ColorPickerComponent from "components/propertyControls/ColorPickerComponentV2";
import {TabController} from "../TabController";
import {ThemeInputJsWrapper} from "../ThemeEditor";

interface ThemeTableControlProps {
  theme: AppTheme;
  updateTheme: (theme: AppTheme) => void;
  buildJsToolTip: (key:string, top:string, left:string) => any;
  dynamicFlag:any;
}

export const fontSizeOptions = [
  {
    label: "S",
    value: "0.875rem",
    subText: "0.875rem",
  },
  {
    label: "M",
    value: "1rem",
    subText: "1rem",
  },
  {
    label: "L",
    value: "1.25rem",
    subText: "1.25rem",
  },
  {
    label: "XL",
    value: "1.875rem",
    subText: "1.875rem",
  },
  {
    label: "XXL",
    value: "3rem",
    subText: "3rem",
  },
  {
    label: "3XL",
    value: "3.75rem",
    subText: "3.75rem",
  },
]

function ThemeFormControl(props: ThemeTableControlProps) {
  const {theme, updateTheme, buildJsToolTip, dynamicFlag} = props;
  const userDefinedForm:any = theme.properties.form;
  const [type, setType] = useState<"label" | "inputText" | "inputBorder">("label");

  const tabControllerProps = {
    options: [
      {
        label: "标签文字",
        value: "label",
      },
      {
        label: "输入文字",
        value: "inputText",
      },
      {
        label: "输入框",
        value: "inputBorder",
      },
    ],
    defaultValue: "label",
    onClick: (value: any) => {
      setType(value);
    }
  }

  const changeThemeValue = (key:any, value:string,) => {
    updateTheme({
      ...theme,
      properties: {
        ...theme.properties,
        form: {
          ...theme.properties.form,
          [type]: {
            ...theme.properties.form[type],
            [key]: value,
          }
        },
      },
    });
  }

  const getThemeWithJsEnum = (key:string) => {
    if (type == 'label') {
      return key + '1';
    } else if (type == 'inputText') {
      return key + '2';
    } else {
      return key;
    }
  }

  let fontColorKey = getThemeWithJsEnum("fontColor");
  let fontSizeKey = getThemeWithJsEnum("fontSize");
  return (
    <div className="space-y-2">
      <div className="pt-1 space-y-1">
        {TabController(tabControllerProps)}
      </div>
      {type != 'inputBorder' && <>
          <div className="pt-1 space-y-1">
            <h3>字体颜色
              {buildJsToolTip(fontColorKey,"0px", "0px")}
            </h3>
            {dynamicFlag[fontColorKey] && <>
              <ThemeInputJsWrapper
                defaultValue={userDefinedForm[type].fontColor}
                key={fontColorKey}
                onBlur={(e) => {
                  changeThemeValue("fontColor", e.target.value);
                }}/>
              </>
            }
            {!dynamicFlag[fontColorKey] && <ColorPickerComponent
              autoFocus={false}
              changeColor={(color: string) => changeThemeValue("fontColor", color)}
              color={userDefinedForm[type].fontColor}
              isOpen={false}
              key={'btnTextColor'}
              portalContainer={
                document.getElementById("app-settings-portal") || undefined
              }
            />}
          </div>
          <div className="pt-1 space-y-1">
            <h3>字体大小
              {buildJsToolTip(fontSizeKey,"0px", "0px")}
            </h3>
            {dynamicFlag[fontSizeKey] && <>
              <ThemeInputJsWrapper
                defaultValue={userDefinedForm[type].fontSize}
                key={fontSizeKey}
                onBlur={(e) => {
                  changeThemeValue("fontSize", e.target.value );
                }}/>
            </>
            }
            {!dynamicFlag[fontSizeKey] && <Select
              placeholder={''}
              className="t--dropdown-option"
              value={userDefinedForm[type].fontSize}
              onChange={(value: any) => changeThemeValue("fontSize",value)}
            >
              {fontSizeOptions.map((option, index) => (
                <Option key={index} value={option.value}>
                  <div className="w-full flex justify-between items-end">
                    <Text kind="action-m">{option.label}</Text>
                    <Text kind="action-s">{option.subText}</Text>
                  </div>
                </Option>
              ))}
            </Select>
            }
          </div>
          <div className="pt-1 space-y-1">
          <h3>加粗&斜体</h3>
          <ToggleButtonGroup
            options={[
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ]}
            onClick={(value: string) => {
              let fontStyle = userDefinedForm[type].fontStyle;
              let values:any = fontStyle.split(",");//.filter((i:any)=> !!i);
              if (values.indexOf(value) > -1) {
                values.splice(values.indexOf(value), 1);
              } else {
                values.push(value);
              }
              updateTheme({
                ...theme,
                properties: {
                  ...theme.properties,
                  form: {
                    ...theme.properties.form,
                    [type]: {
                      ...theme.properties.form.label,
                      fontStyle: values.join(","),
                    }
                  },
                },
              });

            }}
            values={userDefinedForm[type].fontStyle?.split(",")}
          />
        </div>
        </>
      }

      {type == 'inputBorder' && <>
          <div className="pt-1 space-y-1">
            <h3>背景色
              {buildJsToolTip("bgColor","0px", "0px")}
            </h3>
            {dynamicFlag.bgColor && <>
              <ThemeInputJsWrapper
                defaultValue={userDefinedForm[type].bgColor}
                key={'bgColor'}
                onBlur={(e) => {
                  changeThemeValue("bgColor", e.target.value);
                }}/>
            </>
            }
            {!dynamicFlag.bgColor && <ColorPickerComponent
              autoFocus={false}
              changeColor={(color: string) => changeThemeValue("bgColor", color)}
              color={userDefinedForm.inputBorder.bgColor}
              isOpen={false}
              key={'bgColor'}
              portalContainer={
                document.getElementById("app-settings-portal") || undefined
              }
            />
            }
          </div>
          <div className="pt-1 space-y-1">
          <h3>边框颜色
            {buildJsToolTip("bgBorderColor","0px", "0px")}
          </h3>
          {dynamicFlag.bgBorderColor && <>
            <ThemeInputJsWrapper
              defaultValue={userDefinedForm[type].bgBorderColor}
              key={'bgBorderColor'}
              onBlur={(e) => {
                changeThemeValue("bgBorderColor", e.target.value);
              }}/>
          </>
          }
          {!dynamicFlag.bgBorderColor && <ColorPickerComponent
            autoFocus={false}
            changeColor={(color: string) => changeThemeValue("bgBorderColor", color)}
            color={userDefinedForm.inputBorder.bgBorderColor}
            isOpen={false}
            key={'bgBorderColor'}
            portalContainer={
              document.getElementById("app-settings-portal") || undefined
            }
          />
          }
        </div>
        </>
      }
    </div>
  );
}

export default ThemeFormControl;
