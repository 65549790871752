import {Table} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {issuesApi} from "@byk/pages/Track/trackApi";
import {StateVK} from "./constant";
import {useSelector} from "react-redux";
import {AppState} from "../../../ce/reducers";

export interface TableProps {
  columns: any,
  api: string,
  pagination?: any,
  initParams?: any,
  firstLoad?: boolean,
  ref?: any,
  scroll?: any,
  callback?: (res?: any) => any,
  dataSource?: (params?: any) => any,
  searchParam?: (params?: any) => any,
  isSavePage?: boolean,
  savePageKey?: any,
  isSearchByOldPage?: boolean,
}

const TableCmp: React.FC<TableProps> = forwardRef((
  {
    columns,
    api,
    initParams,
    pagination,
    scroll,
    callback,
    firstLoad,
    dataSource,
    searchParam,
    isSavePage,
    savePageKey,
    isSearchByOldPage
  }, ref) => {
  useImperativeHandle(ref, () => ({
    getTableList,
  }));
  const [tableData, setTableData] = useState(dataSource || []);
  const [loading, setLoading] = useState(false);
  const [paginationProps, setPaginationProps] = useState({
    ...pagination,
    onChange: (page: number) => handlePageChange(page), //改变页码的函数
  });
  const userInfo: any = useSelector((state: AppState) => {
    return state.entities.app.user || {};
  });

  //查询
  const getTableList = async (params: any = {}, pageParam: any = {}) => {
    params['Byk用户ID'] = userInfo.id;
    let pageInfo = await issuesApi.getPageInfo(params);
    pageInfo.current = pageParam.current || 1;

    setLoading(true);
    let issueList: any = await issuesApi.getIssueByPage(pageParam, params) || [];
    setLoading(false);

    setTableData(issueList.map((item: any) => {
      return {
        ...item,
        state: StateVK[item.state],
        key: item.id,
      };
    }));

    setPaginationProps(() => ({
      ...pageInfo,
      onChange: (page: number) => handlePageChange(page), //改变页码的函数
      showTotal: () => (
        <span>总共{pageInfo.total}项</span>
      ),
    }));
  };

  // 改变页码的回调 page代表页码数 pageSize代表每页条数
  const handlePageChange = async (page: number) => {
    await getTableList(searchParam, {...paginationProps, current: page});
  };

  useEffect(() => {
    !firstLoad && getTableList(searchParam, {current: 1});
  }, [firstLoad, searchParam]);

  return (
    <Table
      size='middle'
      columns={columns} bordered={true} loading={loading} scroll={scroll}
      dataSource={tableData}
      pagination={paginationProps}
      rowKey={(() => Math.random())}/>
  );
});

export default TableCmp;
