import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import EditorMapping from "@byk/pages/MyTask/EditorMapping";
import {useDispatch, useSelector} from "react-redux";
import {
  X_MYTASK_MAPPING,
  X_MYTASK_MAPPING_STATUS,
  X_MYTASK_SELECT_MODAL,
  X_WIDGET_PROP_POPUP
} from "@byk/store/XReducers";
import {Popover} from 'antd';
import "./PopupRightPage.css";
import Canvas from "../../../pages/Editor/Canvas";
import {getCanvasWidth} from "../../../selectors/editorSelectors";
import {getIsAutoLayout} from "../../../selectors/canvasSelectors";
import {getCanvasWidgetsStructure} from "../../../selectors/entitiesSelector";
import equal from "fast-deep-equal/es6";
import {getIsMyTask} from "../../../sagas/selectors";
import CanvasContainer from "../../../pages/Editor/WidgetsEditor/CanvasContainer";

const LeftTitle = styled.div`
  width: 100%;
  padding: 0 20px;
  height: 70px;
  display: flex;
  align-items: center;
  line-height:30px;
  .title{
    font-size: 20px;
  }
  .des{
    font-size: 14px;
    color: #888;
    margin-left: 20px;
  }
`;
const UlPopup = styled.div<{
  x: number,
  y: number
}>`
  position: absolute;
  z-index: 9999;
  left: ${({x}) => x}px;
  top: ${({y}) => y}px;
  &>ul{
    border: 1px solid #d9d9d9;
    background: #fff;
    max-height: 380px;
    overflow-y: auto;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.34);
    border-radius: 5px;
  }
  li{
    padding: 4px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &:hover{
      background: #f4f4f4;
    }
  }
`;
const ModalListWrapper = styled.ul`
  display: flex;
  li{
    margin-right: 20px;
    padding: 10px 0;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    &.active{
      color: #006cf2;
      border-color: #006cf2;
    }
  }
`

function PopupRightPage(props: any) {
  const {openPopupRecord, modalList, isOpenPopup} = props;
  const widgetPropPopup = useSelector((state: any) => {
    return state.XReducers.widgetPropPopup;
  })
  const myTaskMappingStateStatus = useSelector((state: any) => {
    return state.XReducers.myTaskMappingStatus;
  });
  const myTaskSelectModal = useSelector((state: any) => {
    return state.XReducers.myTaskSelectModal;
  });
  const isFocusRef = useRef<boolean>(myTaskMappingStateStatus);
  const inPopup = useRef<boolean>(false);

  const dispatch = useDispatch();
  useEffect(() => {
    isFocusRef.current = myTaskMappingStateStatus;
  }, [myTaskMappingStateStatus])

  useEffect(() => {
    //监听页面点击，取消选择状态
    const listenerClick = (ev: any) => {
      if (['cursor'].includes(ev.target.className)) {
        return;
      }

      if (!inPopup.current) {
        if (isFocusRef.current) {
          dispatch({
            type: X_MYTASK_MAPPING_STATUS,
            data: false
          });
        }
        dispatch({
          type: X_WIDGET_PROP_POPUP,
          data: {
            visible: false
          }
        })
      }
    }
    window.addEventListener('click', listenerClick)
    return () => {
      window.removeEventListener('click', listenerClick);
    }
  }, [])
  const setMyTaskSelectModal = (widgetId: string) => {
    dispatch({
      type: X_MYTASK_SELECT_MODAL,
      data: widgetId
    })
  }

  const selectAndDispatch = (i:any) => {
    if (i.options) {
      popOptions(i);
    } else {
      dispatch({
        type: X_MYTASK_MAPPING,
        data: {
          widgetLabel: `${i.label}`,
          widgetId: `${i.widgetId}`,
          widgetName: `${i.widgetName}`,
          topWidgetName: `${i.topWidgetName}`,
          widgetType: `${i.type}`,
          isChecked: true
        }
      })
      dispatch({
        type: X_WIDGET_PROP_POPUP,
        data: {
          visible: false
        }
      })
    }
  }

  const popOptions = (i:any) => {
    return <ul className='ulOptions' onMouseLeave={() => inPopup.current = false} onMouseEnter={() => inPopup.current = true}>
      <li key={i.options.label} onClick={() => selectAndDispatch({...i,label: i.label + '.标签', widgetId: i.options.label, options:null})}>标签</li>
      <li key={i.options.value} onClick={() => selectAndDispatch({...i,label: i.label + '.值', widgetId: i.options.value, options:null})}>值</li>
    </ul>
  }
  const canvasWidth = useSelector(getCanvasWidth);
  const isAutoLayout = useSelector(getIsAutoLayout);
  const widgetsStructure = useSelector(getCanvasWidgetsStructure, equal);
  const isMyTask = useSelector(getIsMyTask);
  return (
    <div className={`right`}>
      <LeftTitle>
        <h3 className={`title`}>页面展示</h3>
        {
          myTaskMappingStateStatus && <span className={`des`}>点击选择页面元素</span>
        }
      </LeftTitle>
      <ModalListWrapper>
        <li className={myTaskSelectModal == '' ? 'active' : ''} onClick={() => {
          setMyTaskSelectModal('');
        }}>Page
        </li>
        {
          modalList && modalList.map((i: any) => {
            return (
              <li key={i.widgetId} className={myTaskSelectModal == i.widgetId ? `active` : ``} onClick={() => {
                setMyTaskSelectModal(i.widgetId);
              }}>{i.widgetName}</li>
            )
          })
        }
      </ModalListWrapper>

      <div className={myTaskMappingStateStatus ? 'x-right-box x-right-focus' : 'x-right-box'}>
        {/*{
          !isMyTask
          &&isOpenPopup
          &&<Canvas
            canvasWidth={canvasWidth}
            isAutoLayout={isAutoLayout}
            pageId={openPopupRecord.pageId}
            widgetsStructure={widgetsStructure}
          />
        }*/}
        {
          !isMyTask
          &&isOpenPopup
          &&<CanvasContainer isPreviewMode={false} shouldShowSnapShotBanner={false} />
        }
        {
          isMyTask
          &&openPopupRecord
          && openPopupRecord.appId
          && openPopupRecord.pageId
          && (<EditorMapping toAppId={openPopupRecord.appId} toPageId={openPopupRecord.pageId}/>)
        }

      </div>
      {
        widgetPropPopup && widgetPropPopup.visible && (
          <UlPopup x={widgetPropPopup.x - 120} y={widgetPropPopup.y - 15}>
            <ul onMouseLeave={() => inPopup.current = false} onMouseEnter={() => inPopup.current = true}>
            {
              widgetPropPopup.propList.filter((i: any) => {
                if ('SUB_TABLE_WIDGET' == i.type && i.widgetId == widgetPropPopup.widgetId) {
                  return true;
                } else if ('SUB_TABLE_WIDGET_COLUMNS' == i.type) {
                  return i.widgetName.startsWith(widgetPropPopup.widgetName)
                } else {
                  return i.widgetName.startsWith(widgetPropPopup.widgetName+'.')
                    &&i.widgetName!==widgetPropPopup.widgetName;
                }
              }).map((i: any, idx: number) => {
                if (i.options) {
                  return <Popover overlayClassName="x-popup-right-page-popover" className='PopupRightPagePopover' placement="rightTop" content={popOptions(i)} trigger="hover">
                    <li key={idx}>
                      {i.label}<span style={{marginLeft:"10px"}}>{i.options ?  '>'  : ''}</span>
                    </li>
                  </Popover>
                } else {
                   return <li key={idx} onClick={() => selectAndDispatch(i)}>{i.label}</li>
                  }
              })
            }
            </ul>
          </UlPopup>
        )
      }
    </div>
  )
}

export default PopupRightPage;
