export default function Config ():string{
  return `
import {X_SET_TOAST} from "../reducers/toast";
import {X_SET_BYK} from "../reducers/byk";
import {X_SET_DATATREE} from "../reducers/dataTree";

export const toastAction = (payload:any) => {
  return {
    type: X_SET_TOAST,
    payload
  };
};

export const bykAction = (payload:any) => {
  return {
    type: X_SET_BYK,
    payload
  };
};

export const dataTeeAction = (payload:any) => {
  return {
    type: X_SET_DATATREE,
    payload
  };
};

  `;
}
