import React from "react";
import type {AppState} from "@appsmith/reducers";
import 'antd/dist/antd.min.css'; //显示样式，不加显示不出来

import {Select} from 'antd';
import Api from "@byk/api/Api";
import {X_Edit_Url, X_SUBAPPID} from '@byk/store/XReducers';
import {connect} from "react-redux";
import {setAppMode} from "../../../../actions/pageActions";
import {APP_MODE} from "entities/App";
import styled from "styled-components";
import localStorage, {getAppId, getProjectId, getTenant} from "utils/localStorage";
import { getCookie, setCookie } from "../../../../utils/CookieUtils";
import _, { set } from "lodash";
import {apiPath, routePath} from "@byk/routes";
import {appTenantClass} from "@byk/utils/Utils";
const SelectWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;
  border-radius: 5px;
  background: #F3F6FC;
  line-height: 32px;
  height: 32px;

  .ant-select-selection {
    height: 100%;
  }

  .ant-select {
    height: 32px;
    width: 176px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after {
    line-height: 32px;
  }
`

class SwitchSubApp extends React.Component<any> {
  state = {
    subAppList: [],
    appId: null,
    projectName: localStorage.getItem('projectName') || '',
  };

  componentDidMount() {
    this.bindSubAppList().then();
  }

  bindSubAppList = async () => {
    let projectId = getProjectId();
    let title = this.props.title;
    let isGetInfo = ["TenantInfo"].indexOf(title) < 0

    let userId = localStorage.getItem('userId');

    if (projectId != null && isGetInfo) {
      let res: any = await Api.get("/byk/platform/rest/SysProject", {id: projectId});
      let list = res.result[0]['sysApps'];
      this.setState({subAppList: list});
      let storageSubApp: any = getAppId();
      let cookieSubAppId = getCookie("appId_" + projectId + "_" + userId);
      if (storageSubApp == null) {
        if (cookieSubAppId != null) {
          let cookieSubApps = list.filter((item: any) => {
            return item.id == cookieSubAppId
          });

          if (cookieSubApps.length > 0) {
            localStorage.setItem(appTenantClass.getUserId()+'_appId', cookieSubAppId);
            this.setState({ appId: parseInt(cookieSubAppId) });
          } else {
            localStorage.setItem(appTenantClass.getUserId()+'_appId', list[0].id);
            this.setState({ appId: parseInt(list[0].id) });
          }

        } else {
          localStorage.setItem(appTenantClass.getUserId()+'_appId', list[0].id);
          this.setState({ appId: parseInt(list[0].id) });
        }
      } else {
        localStorage.setItem(appTenantClass.getUserId()+'_appId', storageSubApp);
        this.setState({appId: parseInt(storageSubApp)});
      }
    }
  }

  handleChange = (val: any, record: any) => {
    localStorage.setItem(appTenantClass.getUserId()+'_tenantId', val);
    localStorage.setItem(appTenantClass.getUserId()+'_tenantName', record.tenantName);
    window.location.reload();
  };

  handleChange1 = (val: any, record: any) => {
    localStorage.setItem(appTenantClass.getUserId()+'_appId', val);
    this.editApp(record);
  };

  editApp = async (record: any) => {
    let prefix_url = `${apiPath}/platform/switchTenant`;
    let switchTenant: any = await Api.get(prefix_url, { tenant: getTenant() });
    await this.setEditUrl(record, (url?: string) => {
      if(url){
        window.location.href = url;
      }

    })
  }

  setEditUrl = async (record: any, cb?: (url?: string) => void) => {
    let defaultSubAppId = record.id;
    let tenant = record.tenant;
    let userId = localStorage.getItem('userId');
    setCookie("appId_" + record.projectId + "_" + userId, defaultSubAppId);
    let cookiePageId = getCookie("pageId_" + record.id + "_" + userId);

    let resultPage: any = await Api.get("/byk/platform/rest/Page/list", {
      appId: defaultSubAppId
    });

    let setPageId = resultPage.result[0]['id'];
    let setPageName = resultPage.result[0]['slug'];

    if (cookiePageId != null) {
      let pageList = resultPage.result.filter((item: any) => {
        return item.id == cookiePageId
      });
      if (pageList.length > 0) {
        setPageId = pageList[0].id;
        setPageName = pageList[0].slug;
      }
    }

    let defaultPage = {
      pageId: setPageId,
      pageName: setPageName//['name']
    }
    if (resultPage.success) {
      setCookie("appId_" + record.projectId + "_" + userId, defaultSubAppId);
      setCookie("pageId_" + defaultSubAppId + "_" + userId, setPageId);
      let edit_url = `${routePath}/app/${defaultSubAppId}/${defaultPage.pageName}-${defaultPage.pageId}/edit`;
      this.props.setEditUrl(edit_url, defaultSubAppId);
      localStorage.setItem(appTenantClass.getUserId()+'_projectId', record.projectId);
      this.setState({appId: parseInt(defaultSubAppId)});
      localStorage.setItem(appTenantClass.getUserId()+'_appId', defaultSubAppId);
      localStorage.setItem(appTenantClass.getUserId()+'_editUrl', edit_url);
      localStorage.setItem(appTenantClass.getUserId()+'_tenant', tenant);
      cb && cb(edit_url);
    }
  }

  render() {
    return (
      <SelectWrapper>
        <Select
          bordered={false}
          size="large"
          key={"sub" + this.state.appId ? '1' : '2'}
          defaultActiveFirstOption
          onChange={(val, record) => this.handleChange1(val, record)}
          value={this.state.appId}
          fieldNames={{label: 'name', value: 'id'}}
          options={this.state.subAppList}
        />
      </SelectWrapper>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  editUrl: state.XReducers.editUrl == '/' ? localStorage.getItem('editUrl') : state.XReducers.editUrl,
  mode: state.entities.app.mode,
});

const mapDispatchToProps = (dispatch: any) => ({
  setEditUrl: (data: string, appId: string) => {
    dispatch({
      type: X_Edit_Url,
      data
    })
    dispatch({
      type: X_SUBAPPID,
      data: appId
    })
  },
  setModeNone: () => {
    dispatch(setAppMode(APP_MODE.NONE))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SwitchSubApp);
