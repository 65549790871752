export default function Config ():string{
  return `
import axios from 'axios';
import Taro from "@tarojs/taro";
const config: any = {
  baseURL: 'https://byk-test.billjc.com',
  timeout: 20*1000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
};

const axiosInstance = axios.create(config);
axiosInstance.interceptors.response.use(
  (response: any) => {
    let cookies = response.headers['set-cookie'];
    if(cookies){
      Taro.setStorageSync('cookies', cookies[0]);
    }
    let bodyData = response.data.data?.body?.data;
    if(bodyData){//编排配置的接口返回的数据格式
      if(bodyData[0]?.success&&response?.config.method!='get'){
        Taro.showToast({ title: bodyData[0]?.message })
      }
      return bodyData;
    }else if(!response.data.success&&response.data.message){
      Taro.showToast({ title: response.data.message, icon: 'none' })
    }
    return response.data;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

class Api {
  static get(
    url: string,
    params: any
  ) {
    let paramsData:any = { 
      params,
      headers: {
        'Cookie': Taro.getStorageSync('cookies')
      }
    };
    return axiosInstance.get(url, paramsData);
  }
  static post(
    url: string,
    params: any
  ) {
    return axiosInstance.post(url, params);
  }
  static methods(
    type: string,
    url: string,
    params: any
  ) {
    return Api[type](url, params);
  }
}

export default Api;
  `;
}
