export default function Config ():string{
  return `
  import {X_SET_DATATREE_ACTION} from "../reducers/dataTree";

const ActionTypes = {
  X_ACTION_EXECUTION: "X_ACTION_EXECUTION",
  X_ACTION_BYK: "X_ACTION_BYK",
  X_SET_DATATREE_ACTION: X_SET_DATATREE_ACTION,
  X_SHOWALERT: "showAlert",
  X_LINKAGE: "linkage"
};
export const ReduxActionTypes = {
  ...ActionTypes
};

  `;
}
