import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import Api from "@byk/api/Api";
import {NUMBER, STRING} from "@byk/pages/QueryBuilder/constants";
import {PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";

function getAppId() {
  return ModelingApi.getTenant();
  // return 10112;
}

class Schemas {
  static fetchModelList = async () => {
    let result: any = await ModelingApi.doGetModelList(getAppId());
    if (result && result.result) {
      return result.result;
    } else {
      return [];
    }
  };

  static fetchModel = async (modelId: any) => {
    let result: any = await ModelingApi.doGetModelList(modelId);
    if (result && result.result) {
      return result.result;
    } else {
      return [];
    }
  };

  static fetchModelProperties = async (modelId: number | string) => {
    let result: any = await ModelingApi.getModelPropertiesByModelId(modelId);
    if (result && result.result) {
      let properties: any[] = result.result;
      let ret: any[] = [];
      properties.forEach(item => {
        let type = item.type;
        if (type != PROPERTY_REL_TYPE.O2M) {
          if ("integer" == type || "bigint" == type) {
            item['base_type'] = NUMBER;
          } else {
            item['base_type'] = STRING;
          }

          ret.push(item);
        }
      })
      return ret;
    } else {
      return [];
    }
  };

  /**
   {
  "modelId": "7",
  "aggregation": [
    ["count"],
    ["count",["field","10",null]],
    ["max",["field","10",null]],
    ["max",["field","10",null]],
    ["max",["field","10",null]],
    ["min",["field","10","LoginUser"]]
  ],
  "breakout": [
    ["field","10"],
    ["field","10","LoginUser"]
  ],
  "filter": [
    "and",
    ["=",["field","10"],"test"],
    ["like",["field","10"],"test"],
    ["BETWEEN",["field","10"],[123,345]],
    [">","field","10","LoginUser"],13],
    ["IN",["field","10"],[123,345]],
    ["NOT_IN",["field","10"],["123","345"]],
    ["or",
      ["between",["field","10"],["2020-01-01","2020-03-31"]],
      [">",["field","10"],"2019-12-25"]]
  ],
  "joins": [
    {
      "modelId": "7",
      "subQuery": null,
      "joinAlias": "LoginUser",
      "fields": [
        ["field","10","LoginUser"]
        ],
      "conditions": [
        ["and",["field","10"],["field","10","LoginUser"]],
        [">",["field","10"],["field","10","LoginUser"]]
      ],
      "strategy": "LEFT_JOIN"
    }
  ],
  "orderBy": [
    ["desc",["field","10",null]],
    ["asc",["field","10","LoginUser"]]
  ],
  "fields": [
    ["field","10"]
  ]
}
   */

  static apiSavaOrUpdateStructuredQuery: string = '/byk/platform/rest/StructuredQuery';
  static saveStructuredQuery = async (structuredQuery: StructuredQuery) => {
    let structuredQueryVO = structuredQuery.dataQuery();
    let param = {
      structuredQueryVO,
      appId: getAppId(),
      id: structuredQueryVO.id,
      title: structuredQueryVO.title,
      description: structuredQueryVO.description
    }
    let result: any;
    if (structuredQueryVO.id) {
      result = await Api.put(this.apiSavaOrUpdateStructuredQuery, param);
    } else {
      result = await Api.post(this.apiSavaOrUpdateStructuredQuery, param);
    }

    if (result.success) {
      if (result && result.result) {
        return result.result[0];
      } else {
        return [];
      }
    }
  };

  static apiGenSql: string = '/byk/platform/queryBuilder/genSql';
  static genSql = async (structuredQuery: StructuredQuery) => {
    let structuredQueryVO = structuredQuery.dataQuery();
    let param = {
      ...structuredQueryVO,
      tenant: getAppId(),
    }
    let result: any = await Api.post(this.apiGenSql, param);
    return result;
  };
}

export default Schemas;
