import _ from "lodash";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";

export class PublishModelObj {
  public sql: string = '';
  public updates: any = [];
  public news: any = [];
  public deletes: any = [];
  public lastUpdateTime: any;
  public isView: any;
  public models: any = [];
  private scriptData = {};

  /**
   * 前台交互修改配置项，重新生成SQL
   * @param diffData
   */
  constructor(diffData: any = {}) {
    this.updates = diffData.updates || [];
    this.news = diffData.news || [];
    this.deletes = diffData.deletes || [];
    this.lastUpdateTime = diffData.lastUpdateTime || -2;
    this.isView = true;
    this.models = diffData.models || [];
  }

  getPublishData() {
    return {
      diffVO: {
        updates: this.updates,
        news: this.news,
        deletes: this.deletes,
        sql: this.sql,
        lastUpdateTime: this.lastUpdateTime,
      }
    }
  }

  updateModel(model: any, type: any) {
    let models = [];
    if ('UPDATE' == type) {
      models = this.updates;
    }
    if ('DELETE' == type) {
      models = this.deletes;
    }
    if ('NEW' == type) {
      models = this.news;
    }

    let reModels: any[] = [];
    _.forEach(models || [], item => {
      if (item.modelId == model.modelId) {
        reModels.push({...model});
      } else {
        reModels.push(item);
      }
    })

    if ('UPDATE' == type) {
      this.updates = reModels;
    }
    if ('DELETE' == type) {
      this.deletes = reModels;
    }
    if ('NEW' == type) {
      this.news = reModels;
    }
  }

  addBeforeOrAfterChangeSql(item: any, fileSql: any, isBefore: boolean) {
    let changeType = isBefore ? 'changeBeforeSqlFileCode' : 'changeAfterSqlFileCode';
    let changeTypeAttr = isBefore ? 'changeBeforeSql' : 'changeAfterSql';
    let fileCode = item[changeType] || ''
    item[changeTypeAttr] = fileSql[fileCode] || ''
    return item[changeTypeAttr];
  }

  async setSql() {
    let res: any = await ModelingApi.doPublishChangeSqlFromFile(this.getPublishData());
    let fileSql: any = {};
    if (res.success && res.result) {
      fileSql = res.result[0] || {};
    }

    let newSql = '';
    // 新增的模型
    _.forEach(this.news || [], item => {
      newSql += this.addBeforeOrAfterChangeSql(item, fileSql, true)
      let createSql = item.createSql.replace("` (`", "` (\n`");
      createSql = createSql.replaceAll(",  `", ",  \n`");
      createSql = createSql.replaceAll("PRIMARY KEY", "\nPRIMARY KEY");
      createSql = createSql.replaceAll(")) ", ")\n) ");
      newSql += createSql + "\n";
      if (item.fullDataInit && item.dataSql) {
        newSql += item.dataSql + "\n";
      }
      newSql += this.addBeforeOrAfterChangeSql(item, fileSql, true)
    })

    newSql += newSql.length > 0 ? "\n" : "";
    // 删除的模型
    _.forEach(this.deletes || [], item => {
      newSql += this.addBeforeOrAfterChangeSql(item, fileSql, true)
      if (item.renameTable) {
        newSql += item.renameTableSql.replace('${NEW_NAME}', item.renameTable) + '\n';
      } else {
        newSql += item.dropSql + '\n';
      }
      newSql += this.addBeforeOrAfterChangeSql(item, fileSql, false)
    })

    newSql += newSql.length > 0 ? "\n" : "";
    // 变更的模型
    _.forEach(this.updates || [], item => {
      newSql += this.addBeforeOrAfterChangeSql(item, fileSql, true)
      let dropColumn: any = [];
      let addColumn: any = [];
      _.forEach(item.properties || [], pItem => {
        pItem.deleteFlag && pItem.dropSql && dropColumn.push(pItem.dropSql);
        pItem.modifySql && addColumn.push(pItem.modifySql);
      });

      let tableName: any = item.dbName;
      let alterSql: any = '';
      _.forEach(dropColumn, di => alterSql += di + ',\n');
      _.forEach(addColumn, di => alterSql += di + ',\n');
      let lastIdx = alterSql.lastIndexOf(",\n");
      if (lastIdx > 0) {
        alterSql = alterSql.substring(0, lastIdx) + ';'
      }

      let modelSql = 'ALTER TABLE ' + tableName + ' \n' + alterSql;

      newSql += modelSql + '\n';
      newSql += this.addBeforeOrAfterChangeSql(item, fileSql, false)
    })

    this.sql = newSql;

    if (_.keys(this.scriptData).length > 0) {
      let res: any = await ModelingApi.doPublishModelDataSql(this.scriptData);
      if (res.success && res.result) {
        let dataSql = res.result[0] || '';
        if (dataSql.length > 0) {
          this.sql += '\n';
          this.sql += dataSql;
        }
      }
    }
  }

  setScriptData(scriptData: any = {}) {
    this.scriptData = {...scriptData};
    return this;
  }
}
