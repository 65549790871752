export default function Config ():string{
  return `import React, {useState} from "react";
import XBase from "./XBase";
import {View} from "@tarojs/components"; 
import {AtImagePicker} from "taro-ui";

function XImagePicker(props: any) {
  const {propertyPath, style, className} = props;
  const _style = style||{};
  const boxStyle = {
    ..._style
  }
  const [files, setFiles] = useState([]);
  const onChange = (files:any)=>{
    setFiles(files);
  }
  
  return (
    <XBase propertyPath={propertyPath}>
      <View style={boxStyle} className={className}>
        <AtImagePicker showAddBtn={files.length===0} files={files} onChange={onChange} />
      </View>
    </XBase>)
}

export default XImagePicker;
  `;
}
