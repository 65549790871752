
import {DSLWidget} from "../../widgets/constants";
import {traverseDSLAndMigrate} from "../WidgetMigrationUtils";
import {WidgetProps} from "../../widgets/BaseWidget";
import _ from "lodash";
//value uploadValue, isDecription, decription
export const migrateXImageValue = (
  currentDSL: DSLWidget,
) => {
  return traverseDSLAndMigrate(currentDSL, (widget: WidgetProps) => {
    if (widget.type === "X_IMAGE_WIDGET") {
      let value = widget.value;
      widget.isDescription = false;
      widget.description = '';
      widget.uploadValue = _.isArray(value)?value:[];
      widget.value = _.isArray(value)&&!_.isEmpty(value[0])?value[0]['url']:'';
      widget.mappingConfig = {
        description: {
          id: 'description',
            index: 0,
            label: '描述',
            type: "text"
        },
        value: {
          id: 'value',
            index: 1,
            label: '图片',
            type: "text"
        }
      };
    }
  });
};
