export default function Config ():string{
  return `import {useDispatch, useSelector} from "react-redux";
export default function useDataTreeToValue(){
  const dispatch = useDispatch();
  const dataTree = useSelector((state:any)=>{
    return state.dataTreeReducer.dataTree;
  });
  
  const evalEvent = (widgetName:string, eventName:string, pageName:string)=>{
    import(\`../../pages/\${pageName}/event\`).then(module=>{
      module.default[\`\${widgetName}\${eventName}\`](dataTree[pageName], dispatch);
    });
  }
  return {
    evalEvent,
    dataTree
  }
}

  `;
}
