import React, {ReactNode, useEffect, useRef, useState} from "react";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {
  ActionButtonsWrapper,
  Container,
  ExpressionFieldWrapper,
  FieldLabel,
  FieldWrapper,
  Footer,
  RemoveLink
} from "@byk/pages/QueryBuilder/components/notebook/steps/ExpressionStep/ExpressionWidget.styled";
import {Button, Input} from "antd";
import ExpressionEditorTextField from "./ExpressionEditorTextField";
import {isNotNull} from "@byk/pages/QueryBuilder/utils";
import {isExpression} from "@byk/pages/QueryBuilder/lib/expressions";

export interface ExpressionWidgetProps {
  query: StructuredQuery;
  expression: any;
  name?: string;
  withName?: boolean;
  startRule?: string;
  reportTimezone?: string;
  header?: ReactNode;

  onChangeExpression: (name: string, expression: any) => void;
  onRemoveExpression?: (name: string) => void;
  onClose?: () => void;
}

const ExpressionWidget: React.FC<ExpressionWidgetProps> = (
  {
    query,
    name,
    expression: initialExpression,
    withName = false,
    startRule,
    reportTimezone,
    header,
    onChangeExpression,
    onRemoveExpression,
    onClose
  }: ExpressionWidgetProps) => {
  const [newName, setNewName] = useState(name || "");
  const [expression, setExpression] = useState<any | null>(
    initialExpression || null,
  );
  const [error, setError] = useState<string | null>(null);
  const helpTextTargetRef = useRef(null);
  const isValidExpression = !!expression && isExpression(expression);
  const [valid, setValid] = useState(!error && isValidExpression);

  useEffect(() => {
    setValid(!error && (!!expression && isExpression(expression)) && (!!withName ? newName : true));
  }, [expression, error, newName]);

  function handleCommit(expression: any) {
    if (valid && isNotNull(expression)) {
      onChangeExpression(newName, expression);
      onClose && onClose();
    }
  }

  const handleExpressionChange = (parsedExpression: any) => {
    setExpression(parsedExpression);
    setError(null);
  };

  return (
    <Container>
      <ExpressionFieldWrapper>
        <div ref={helpTextTargetRef}>
          <ExpressionEditorTextField
            helpTextTarget={helpTextTargetRef.current}
            expression={expression}
            startRule={startRule}
            name={newName}
            query={query}
            reportTimezone={reportTimezone}
            textAreaId="expression-content"
            onChange={handleExpressionChange}
            onCommit={handleCommit}
            onError={(errorMessage: string) => setError(errorMessage)}
          />
        </div>
      </ExpressionFieldWrapper>

      {withName && (
        <FieldWrapper>
          <FieldLabel htmlFor="expression-name">给它取个名字</FieldLabel>
          <Input
            id="expression-name"
            type="text"
            value={newName}
            placeholder={`一些超棒的、值得记录的东西`}
            onChange={event => setNewName(event.target.value)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                handleCommit(expression);
              }
            }}
          />
        </FieldWrapper>
      )
      }

      <Footer>
        <ActionButtonsWrapper>
          {onClose && <Button onClick={onClose}>取消</Button>}
          <Button
            style={{
              borderRadius: "5px"
            }}
            type={valid ? "primary" : "default"}
            disabled={!valid}
            onClick={() => handleCommit(expression)}
          >
            {name ? "更新" : "完成"}
          </Button>

          {name && onRemoveExpression ? (
            <RemoveLink
              onlyText
              onClick={() => {
                onRemoveExpression(name);
                onClose && onClose();
              }}
            >{"删除"}
            </RemoveLink>
          ) : null}
        </ActionButtonsWrapper>
      </Footer>

    </Container>
  );
}

export default ExpressionWidget;
