import styled from "styled-components";
import {Form} from "antd";
import {hexToRgba} from "design-system-old";
import {hexToRgb} from "@byk/utils/Utils";

export const getAntdTreeSelectPopupStyled = (accentColor?:string) => `
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      background-color:  ${hexToRgb(accentColor, 0.1)} !important;
  }
`

export const getAntdSelectPopupStyled = (accentColor?:string) => `
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color:  ${hexToRgb(accentColor, 0.1)} !important;
  }
`

export const getAntdCascaderPopupStyeld = (accentColor?:string) => `
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background-color:  ${hexToRgb(accentColor, 0.1)} !important;
  }

  .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
    background-color: ${accentColor} !important;
    border-color: ${accentColor} !important;
  }

  .ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
  .ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
  .ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
     border-color: ${accentColor} !important;
  }

  .ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
    background-color: ${accentColor} !important;
  }
`

export const getAntdDatePickerPopupStyled = (accentColor?:string) => `
  .ant-picker-header-view button:hover, .ant-picker-now-btn:hover {
      color: ${accentColor} !important;
   }

  .ant-picker-today-btn {
    color: ${accentColor} !important;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
      background: ${hexToRgb(accentColor, 0.1)};
  }

  .ant-btn-primary {
    color: #fff;
    border-color: ${accentColor} !important;
    background: ${accentColor} !important;
  }

  .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: ${accentColor} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid ${accentColor} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
     background: ${hexToRgb(accentColor, 0.1)};
  }

  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: ${hexToRgb(accentColor, 0.3)};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${hexToRgb(accentColor, 0.1)};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed ${accentColor};
    border-bottom: 1px dashed ${accentColor};
  }
 `;


const getAntdButtonStyled = (primaryBtnColor?:string, secondBtnColor?:string) => `
  .ant-btn-default {
    &:hover{
      color: ${secondBtnColor} !important;
      border-color: ${secondBtnColor} !important;
    }
  }
  .ant-btn-primary{
    background-color: ${primaryBtnColor} !important;
    border-color: ${primaryBtnColor} !important;
    &:hover{
      opacity: .8;
    }
  }
`;

export const AntdBtnContainerDiv = styled.div<{
  primaryBtnColor?:string;
  secondBtnColor?: string;
}>`
  ${({primaryBtnColor, secondBtnColor}) => getAntdButtonStyled(primaryBtnColor , secondBtnColor)}
`;

export const AntdFormItemWrapper = styled(Form.Item)<{
  accentColor:string;
}> `
  .ant-upload .ant-btn:focus, .ant-btn:hover {
      &:hover{
        color: ${({accentColor}) => accentColor} !important;
        border-color: ${({accentColor}) => accentColor} !important;
    }
  }

  .ant-input:hover,
  .ant-input-number:hover,
  .ant-picker:hover,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-radio-checked .ant-radio-inner, .ant-radio-checked::after,
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${({accentColor}) => accentColor} !important;
  }

  .ant-radio-inner::after {
    background-color: ${({accentColor}) => accentColor} !important;
  }

  .ant-input-focused, .ant-input:focus,
  .ant-input-number-focused, .ant-input-number:focus,
  .ant-picker-focused,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${({accentColor}) => accentColor} !important;
    box-shadow:  ${({accentColor}) => `0 0 0 2px ${hexToRgba(accentColor, 0.1)}} !important`};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({accentColor}) => accentColor} !important;
    border-color: ${({accentColor}) => accentColor} !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({accentColor}) => accentColor} !important;
  }

  .ant-slider-handle.ant-tooltip-open {
    border-color:${({ accentColor }) => { return `${accentColor} !important` }};
  }
  .ant-slider-handle {border:${({ accentColor }) => { return `2px solid ${hexToRgb(accentColor, 0.7)} !important` }}; }
  .ant-slider-track  {background-color:${({ accentColor }) => { return hexToRgb(accentColor, 0.4)}}; }
  .ant-slider:hover .ant-slider-track {background-color:${({ accentColor }) => { return hexToRgb(accentColor, 0.7)}}; }
  .ant-slider-handle:focus {
    box-shadow:${({ accentColor }) => { return `0 0 0 5px  ${hexToRgb(accentColor, 0.1)} !important` }};
  }

  .ant-switch-checked {
    background-color:  ${({accentColor}) => accentColor} !important;
  }
`;
