import React, {lazy} from 'react';
import * as Sentry from "@sentry/react";
import {Route} from "react-router-dom";

const Login = lazy(() => import('@byk/pages/Login'));
const register = lazy(() => import('@byk/pages/register'));
const JoinUs = lazy(() => import('@byk/pages/JoinUs/JoinUs'));
const SearchComponent = lazy(() => import('@byk/pages/SearchComponent'));
const Application = lazy(() => import('@byk/pages/ApplicationManager/Application/index'));
const LogOnline = lazy(() => import('@byk/pages/ApplicationManager/LogManagement/LogOnline'));
const ApplicationConfig = lazy(() => import('@byk/pages/ApplicationManager/Config/index'));
const MembersManager = lazy(() => import('@byk/pages/MembersManager/index'));
const Role = lazy(() => import('@byk/pages/System/RoleManager/index'));
const User = lazy(() => import('@byk/pages/System/UserManager/index'));
const Menu = lazy(() => import('@byk/pages/System/MenuManager/index'));
const SysData = lazy(() => import('@byk/pages/System/DataManager/index'));
const SysField = lazy(() => import('@byk/pages/System/FieldManager/index'));
const AppServicesManager = lazy(() => import('@byk/pages/ServicesManager/AppServicesManager/index'));
const AppActionManager = lazy(() => import('@byk/pages/ServicesManager/AppActionManager/index'));
const ComponentsManager = lazy(() => import('@byk/pages/ComponentsManager/index'));
const ScheduledTaskManagement = lazy(() => import('@byk/pages/ApplicationManager/ScheduledTaskManagement/index'));
const RuleManagement = lazy(() => import('@byk/pages/ApplicationManager/RuleManagement/index'));
const LogManagement = lazy(() => import('@byk/pages/ApplicationManager/LogManagement/index'));
const SystemConfig = lazy(() => import('@byk/pages/ApplicationManager/SysConfig/index'));
const Internationalize = lazy(() => import('@byk/pages/ApplicationManager/Internationalize/index'));
const SysFile = lazy(() => import('@byk/pages/ApplicationManager/SysFile/index'));
const ServiceSetup = lazy(() => import('@byk/pages/ServicesManager/ServiceSetup/index'));
const Home = lazy(() => import('@byk/pages/Home/index'));
const MyTask = lazy(() => import('@byk/pages/MyTask/index'));
const DevTask = lazy(() => import('@byk/pages/DevTask/index'));
const Modeling = lazy(() => import('@byk/pages/modeling/index'));
const PageDesign = lazy(() => import('@byk/pages/PageDesign/index'));
const TenantInfo = lazy(() => import('@byk/pages/Tenant/TenantInfo/index'));
const TenantRoleManager = lazy(() => import('@byk/pages/Tenant/RoleManager/index'));
const TenantMembers = lazy(() => import('@byk/pages/Tenant/Member/index'));
const DictManager = lazy(() => import('@byk/pages/AppSystemInit/DictManager/index'));
const QueryBuilder = lazy(() => import('@byk/pages/QueryBuilder/index'));
const GitHistory = lazy(() => import('@byk/pages/ApplicationManager/Git/index'));
const TaskLog = lazy(() => import('@byk/pages/TaskLog/index'));
const ProjectRecycle = lazy(() => import('@byk/pages/ProjectRecycle/index'));
const appLogin = lazy(() => import('@byk/pages/AppSystemInit/Login/Login'));
const appUsers = lazy(() => import('@byk/pages/AppSystemInit/UserManager/main'));
const AppDeploy = lazy(() => import('@byk/pages/AppDeploy/index'));
const Demo = lazy(() => import('@byk/pages/Demo/Demo1'));

const Demo2 = lazy(() => import('@byk/pages/Demo/Demo2'));
const Demo3 = lazy(() => import('@byk/pages/Demo/Demo3'));
const Track = lazy(() => import('@byk/pages/Track/index'));
const PackageManager = lazy(() => import('@byk/pages/PackageManager'));
const SysAppRole = lazy(() => import('@byk/pages/ApplicationManager/SysAppRole/index'));
const SysAppRoleEdit = lazy(() => import('@byk/pages/ApplicationManager/SysAppRole/indexEdit'));
const SysAppUser = lazy(() => import('@byk/pages/ApplicationManager/SysAppUser/index'));

const SentryRoute = Sentry.withSentryRouting(Route);

export const routePath = process.env.REACT_APP_ROUTE_URL;
export const apiPath = process.env.REACT_APP_API_URL;
export default function AppRouters() {
    return (
      <>
        <SentryRoute component={Home} exact path={'/'} />
        <SentryRoute component={Login} exact path={`${routePath}/login`} />
        <SentryRoute component={register} exact path={`${routePath}/register`} />
        <SentryRoute component={JoinUs} exact path={`${routePath}/Join`} />
        <SentryRoute component={Application} exact path={`${routePath}/application`} />
        <SentryRoute component={LogOnline} exact path={`${routePath}/LogOnline`} />
        <SentryRoute component={ApplicationConfig} exact path={`${routePath}/ApplicationConfig`} />
        <SentryRoute component={MembersManager} exact path={`${routePath}/MembersManager`} />
        <SentryRoute component={SearchComponent} exact path={`${routePath}/edit`} />
        <SentryRoute component={Role} exact path={`${routePath}/sysRole`} />
        <SentryRoute component={User} exact path={`${routePath}/sysUser`} />
        <SentryRoute component={Menu} exact path={`${routePath}/sysMenu`} />
        <SentryRoute component={SysData} exact path={`${routePath}/sysData`} />
        <SentryRoute component={SysField} exact path={`${routePath}/SysField`} />
        <SentryRoute component={AppServicesManager} exact path={`${routePath}/AppServicesManager`} />
        <SentryRoute component={AppActionManager} exact path={`${routePath}/AppActionManager`} />
        <SentryRoute component={MyTask} exact path={`${routePath}/MyTask`} />
        <SentryRoute component={MyTask} exact path={`${routePath}/DevTask`} />
        <SentryRoute component={ComponentsManager} exact path={`${routePath}/ComponentsManager`} />
        <SentryRoute component={ScheduledTaskManagement} exact path={`${routePath}/ScheduledTaskManagement`} />
        <SentryRoute component={RuleManagement} exact path={`${routePath}/RuleManager`} />
        <SentryRoute component={LogManagement} exact path={`${routePath}/LogManager`} />
        <SentryRoute component={SystemConfig} exact path={`${routePath}/SysConfig`} />
        <SentryRoute component={Internationalize} exact path={`${routePath}/SysInternationalize`} />
        <SentryRoute component={SysFile} exact path={`${routePath}/SysFile`} />
        <SentryRoute component={SysAppRole} exact path={`${routePath}/SysAppRole`} />
        <SentryRoute component={SysAppRoleEdit} exact path={`${routePath}/SysAppRoleEdit`} />
        <SentryRoute component={SysAppRoleEdit} exact path={`${routePath}/SysAppRoleAdd`} />
        <SentryRoute component={SysAppUser} exact path={`${routePath}/SysAppUser`} />
        <SentryRoute component={ServiceSetup} exact path={`${routePath}/ServiceSetup`} />
        <SentryRoute component={User} exact path={`${routePath}/index`} />
        <SentryRoute component={Modeling} exact path={`${routePath}/modeling`} />
        <SentryRoute component={PageDesign} exact path={`${routePath}/PageDesign`} />
        <SentryRoute component={TenantInfo} exact path={`${routePath}/TenantInfo`} />
        <SentryRoute component={TenantRoleManager} exact path={`${routePath}/TenantRoleManager`} />
        <SentryRoute component={DictManager} exact path={`${routePath}/DictManager`} />
        <SentryRoute component={TenantMembers} exact path={`${routePath}/TenantMember`} />
        <SentryRoute component={QueryBuilder} exact path={`${routePath}/queryBuilder`} />
        <SentryRoute component={GitHistory} exact path={`${routePath}/GitHistory`} />
        <SentryRoute component={appLogin} exact path={`${routePath}/appLogin`} />
        <SentryRoute component={appUsers} exact path={`${routePath}/appUsers`} />
        <SentryRoute component={AppDeploy} exact path={`${routePath}/AppDeploy`} />
        <SentryRoute component={Demo} exact path={`${routePath}/Demo`} />
        <SentryRoute component={Demo2} exact path={`${routePath}/Demo2`} />
        <SentryRoute component={Demo3} exact path={`${routePath}/Demo3`} />
        <SentryRoute component={Track} exact path={`${routePath}/track`} />
        <SentryRoute component={TaskLog} exact path={`${routePath}/TaskLog`} />
        <SentryRoute component={PackageManager} exact path={`${routePath}/PackageManager`} />
        <SentryRoute component={ProjectRecycle} exact path={`${routePath}/ProjectRecycle`} />
      </>
    )
}


