import React, {useState, useEffect, useRef} from "react";
import { batchUpdateMultipleWidgetProperties } from "../../../actions/controlActions";
import { useDispatch, useSelector } from "react-redux";
import {Button, Select, notification, Drawer, Tooltip, Popconfirm, List} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Api from 'api/Api';
import {
  getCurrentApplicationId,
  getCurrentLayoutId,
  getCurrentPageId,
  getCurrentPageName
} from "../../../selectors/editorSelectors";
import {findNode} from "../../../utils/treeUtils";
import styled, {css} from "styled-components";
import {PlusCircleFilled, QuestionCircleFilled} from "@ant-design/icons/lib";
import { uuid4 } from "@sentry/utils";
import XPDict from "./XPropertyPaneDict";
import PaneAction from "./XPropertyPaneAction";
import PanelCondition from "./XPropertyPanelCondition";
import PopupMapping from "@byk/pages/MyTask/PopupMapping";
import {X_EDITEVENT_ISOPENPOPUP} from "@byk/store/XReducers";

export const SpaceBetween = css`
  display: flex;
  justify-content: space-between;
`;
const EventUlWrapper = styled.ul`
  li{
    margin: 10px 0 10px 0;
    padding: 10px;
    border: 1px solid rgba(222, 222, 222, 1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 1);
  }
  .eventTitle{
    ${SpaceBetween};
  }
  .eventName{
    font-weight: bold;
  }
`;
export const CardWrapper = styled.div<{
  titleBgColor?: string;
  width?: string
}>`
  width: ${({width}) => width||170}px;
  margin: 0 0 40px 0;
  background: #fff;
  position: relative;
  z-index: 2;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  overflow:hidden;
  .card-title{
    display: flex;
    align-items: center;
    padding: 6px 10px;
    color: #fff;
    background-color: ${({titleBgColor})=> titleBgColor||`#6F7880`};
  }
  .card-box{
    padding: 10px;
    .x-event-error{
      border-color: #f00!important;
    }
  }
`;
const BorderTop = styled.div`
  position: relative;
  :before{
    content: '';
    left: 0;
    top: 20px;
    position: absolute;
    width: 100%;
    height: 1px;
    background: #adb0b8;
  }
`;
const IfWrapper = styled(BorderTop)`
  :after{
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 20px;
    background: #fff;
  }
`;
const ElseWrapper = styled(BorderTop)`
  :after{
    content: '';
    position: absolute;
    top: 21px;
    bottom: 0;
    right: -1px;
    width: 1px;
    background: #fff;
  }
  .hideBeforeLine{
    width: 1px;
    position: absolute;
    left: 0;
    top: 21px;
    bottom: 0;
    background-color: #fff;
  }
`

const AddWrapper = styled.div`
  padding: 7px 20px;
  color: #a4abbb;
  font-size: 18px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  background-color: #fff;
  &:hover{
    color: #6a758e;
  }
  :before{
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
    right: 0;
    height: 1px;
    background: #adb0b8;
  }
  span{
    position: relative;
    z-index: 2;
    cursor: pointer;
    background-color: #fff;
  }
`;
const ActionWrapper = styled.div`
  border-right: 1px solid #adb0b8;
  position:relative;
  :before{
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #adb0b8;
  }
`;
export const AddEventUl = styled.ul`
  li {
    cursor: pointer;
  }
`;
const ActionBox = styled.div`
  .actionWrapper{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 20px;
      right: 0;
      height: 1px;
      background: #adb0b8;
    }
    &:after{
      content: '';
      position: absolute;
      left: 20px;
      top: 20px;
      bottom: 0;
      width: 1px;
      background: #adb0b8;
    }
    .borderRight{
      border-right: none;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        height: 1px;
        background: #adb0b8;
      }
    }
    .borderAfter:last-child .borderRight:after{
      background: #fff;
      z-index: 2;
    }
    .borderAfter .if-box{
      position: relative;
      z-index: 3;
    }

    .add-btn-box{
      z-index: 3;
    }
    .action-fail-box:before{
      width: 20px;
    }
    .action-fail-box:after{
      content: '';
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: 0;
      top: 21px;
      width: 1px;
      background: #fff;
    }
    
    
    .action-fail-addbtn-0 .add-btn-wrapper,
    .action-success-addbtn-0 .add-btn-wrapper
    {
      padding-right: 0;
    }
    .action-success-box{
      padding-left: 0;
      &:before{
        width: 20px;
      }
    }
    .action-fail-box, .action-success-box{
      &>.x-last-add-btn:last-child{
        .add-btn-wrapper{
          padding-right: 0;
        }
      }
    }
    .tag-success, .tag-fail{
      span{
        width: 60px;
        text-align: center;
        padding: 4px 0;
        margin-top: 8px;
        display: inline-block;
        border-radius: 3px;
        background-color: rgba(6, 178, 8, 1);
        color: #fff;
        font-size: 12px;
      }
    }
    .tag-fail{
      span{
        background-color: rgba(222, 76, 1, 1);
      }
    }
    
  }

`
const XPropertyPanne = (props: {
  config: any,
  id: any,
  panel?: any,
  theme?: any,
  type?: any
})=>{
  const dispatch = useDispatch();
  const pageId = useSelector(getCurrentPageId);
  const pageName = useSelector(getCurrentPageName);
  const layoutId = useSelector(getCurrentLayoutId);
  const appId = useSelector(getCurrentApplicationId);
  const {config, id} = props;
  const [eventConfig, setEventConfig] = useState([]);
  const [openEvent, setOpenEvent] = useState(false);
  const [openPopupRecord, setOpenPopupRecord] = useState({});
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const setIsOpenPopupFn = (v:boolean)=>{
    let _v:boolean = !!v;
    setIsOpenPopup(_v);
    dispatch({
      type: X_EDITEVENT_ISOPENPOPUP,
      data: _v
    })
  }
  //当前组件
  const widgets = useSelector(state=>state.entities.canvasWidgets);
  const canvasWidgetsStructure = useSelector(state=>state.entities.canvasWidgetsStructure);
  const currWidgets = widgets[id];

  //新增AppAction , 一个动作对应一个AppAction
  const actionParams:any = (actionMethods:string)=> {
    if(['api', ...XPDict.actionMethods].includes(actionMethods)){
      return {
        "taskType": "backendTask",//backendTask
        "triggerMode": "API",
        "actionType": "API",
        "description": "apiDescription",
        "requestType": actionMethods
      };
    }
    if(actionMethods == 'special'){
      return {
        "taskType": "both",//frontendTask
        "triggerMode": "onClick",
        "actionType": "requirement",
        "description": "specialDes"
      }
    }
    return {
      "taskType": "frontendTask",//frontendTask
      "triggerMode": "onClick",
      "actionType": "frontend",
      "description": "frontDescription"
    }
  };
  const cacheEditData = useRef([]);//缓存编辑初始数据，在保存时收集删除的actionId
  const reOldActionId:any = (data:any)=>{
    const oldActions = collectActions(data);
    return oldActions.map((i:any)=>{
      return i.actionId;
    })
  }
  const createAppAction = async (values: any)=>{
    let addParams:any = [];
    let uptParams:any = [];
    let delParams:any = [];
    let beforeActionId:any = reOldActionId(cacheEditData.current);
    let afterActionId:any = [];
    _.each(values, (value, key)=>{
      if(value&&value[0]){
        _.each(value, (i:any, idx:number)=>{
          //动作类型 api后端任务 special 特性处理，前端任务
          const actionsParams = actionParams(i.action);
          const { taskType, triggerMode, actionType, requestType } = actionsParams;
          if(i.actionId){
            afterActionId.push(i.actionId);
            uptParams.push({
              id: i.actionId,
              taskType,
              elementLabel: `${pageName}_${currWidgets.widgetName}_${currWidgets.labelText||currWidgets.text||currWidgets.label||currWidgets.labelCn||currWidgets.widgetName}_${id}_${key}_${XPDict.actionMethods.includes(i.action)?'api':i.action}_${XPDict.actionMethods.includes(i.action)?i.action:'null'}_${idx}`,
              requestType,
              triggerMode,
              actionType,
              description: i[actionsParams['description']],
              widgetId: id,
              widgetLabel: `${currWidgets.labelText||currWidgets.text||currWidgets.label||currWidgets.labelCn||currWidgets.widgetName}`,
              widgetName: currWidgets.widgetName,
              event: key,
              method: `${XPDict.actionMethods.includes(i.action)?'api':i.action}`,
              httpMethod: `${XPDict.actionMethods.includes(i.action)?i.action:'null'}`,
              count: idx
            });
          }else{
            addParams.push({
              taskType,
              elementId: i.uid?`${i.uid}`:`${id}_${key}_${idx}_${Date.now()}`,
              elementName: `${pageName}_${currWidgets.widgetName}`,
              elementLabel: `${pageName}_${currWidgets.widgetName}_${currWidgets.labelText||currWidgets.text||currWidgets.label||currWidgets.labelCn||currWidgets.widgetName}_${id}_${key}_${XPDict.actionMethods.includes(i.action)?'api':i.action}_${XPDict.actionMethods.includes(i.action)?i.action:'null'}_${idx}`,
              pageId,
              layoutId,
              appId,
              requestType,
              triggerMode,
              actionType,
              description: i[actionsParams['description']],
              widgetId: id,
              widgetLabel: `${currWidgets.labelText||currWidgets.text||currWidgets.label||currWidgets.labelCn||currWidgets.widgetName}`,
              widgetName: currWidgets.widgetName,
              event: key,
              method: `${XPDict.actionMethods.includes(i.action)?'api':i.action}`,
              httpMethod: `${XPDict.actionMethods.includes(i.action)?i.action:'null'}`,
              count: idx
            });
          }

        })
      }else{
        return true;
      }
    })
    delParams = _.difference(beforeActionId, afterActionId);//删掉的actionId集合
    //console.log(addParams, uptParams, delParams);
    let proxyAll:any = [];
    if(!_.isEmpty(addParams)){
      proxyAll.push(Api.post('/byk/platform/rest/AppAction', addParams));
    }
    if(!_.isEmpty(uptParams)){
      proxyAll.push(Api.put('/byk/platform/rest/AppAction', uptParams));
    }
    if(!_.isEmpty(delParams)){
      proxyAll.push(Api.deleteByk('/byk/platform/rest/AppAction', delParams));
    }
    return Api.all(proxyAll);
  };
  //get 动作时绑定组件数据到 store
  const setWidgetData = (currWidgets:any)=>{
    let updateBind:any = [];
    if(['LIST_WIDGET_V2'].includes(currWidgets.type)){
      //canvasWidgetsStructure  dsl结构
      //canvasWidgets  dsl的组件key-value对象 widgetId: dsl
      //currWidgets 当前组件
      //当前组件去dsl结构里匹配自己的子节点
      //遍历子节点 并用widgetId 和 type 组成数据绑定的变更对象
      let currWidgetId = currWidgets.widgetId;
      let currDSL = findNode(canvasWidgetsStructure, 'widgetId', currWidgetId);
      if(currDSL&&currDSL.length>0){
        //list组件绑定接口时绑定数据
        if(['LIST_WIDGET_V2'].includes(currWidgets.type)){
          /*updateBind.push({
            "widgetId": currWidgetId,
            "updates": {
              "modify": {
                "listData": `[]`
              }
            }
          })*/
          const updateBindListFn = (arr:any)=> {
            _.each(arr, (i: any) => {
              if(i.cancelBind===false){
                return true;
              }
              if (['TEXT_WIDGET'].includes(i.type)) {
                updateBind.push({
                  "widgetId": i.widgetId,
                  "updates": {
                    "modify": {
                      "text": `{{currentItem["${widgets[i.widgetId].widgetName}"]}}`
                    }
                  }
                })
              }
              if (['AT_TEXT_WIDGET'].includes(i.type)) {
                updateBind.push({
                  "widgetId": i.widgetId,
                  "updates": {
                    "modify": {
                      "label": `{{currentItem["${widgets[i.widgetId].widgetName}"]}}`
                    }
                  }
                })
              }
              if (['IMAGE_WIDGET', 'X_IMAGE_WIDGET'].includes(i.type)) {
                updateBind.push({
                  "widgetId": i.widgetId,
                  "updates": {
                    "modify": {
                      "description": `{{currentItem["${widgets[i.widgetId].widgetName}.mapping.description"]}}`,
                      "value": `{{currentItem["${widgets[i.widgetId].widgetName}.mapping.value"]}}`,
                    }
                  }
                })
              }
              if (['VIDEO_WIDGET'].includes(i.type)) {
                updateBind.push({
                  "widgetId": i.widgetId,
                  "updates": {
                    "modify": {
                      "url": `{{currentItem["${widgets[i.widgetId].widgetName}"]}}`
                    }
                  }
                })
              }
              if(['INPUT_WIDGET_V2'].includes(i.type)){
                updateBind.push({
                  "widgetId": i.widgetId,
                  "updates": {
                    "modify": {
                      "defaultText": `{{currentItem["${widgets[i.widgetId].widgetName}"]}}`
                    }
                  }
                })
              }
              if(['DATE_PICKER_WIDGET2'].includes(i.type)){
                updateBind.push({
                  "widgetId": i.widgetId,
                  "updates": {
                    "modify": {
                      "defaultDate": `{{currentItem["${widgets[i.widgetId].widgetName}"]}}`
                    }
                  }
                })
              }
              i.type != 'LIST_WIDGET_V2'&&i.children&&updateBindListFn(i.children);
            })
          }
          updateBindListFn(currDSL);
        }
      }
    }
    return updateBind;
  }

  useEffect(()=>{
    //事件集合
    setEventConfig(config[0]?.children.filter((i:any)=>{
      return i.controlType === 'ACTION_SELECTOR';
    }));
  }, []);
  // console.log(config);
  // console.log(id);
  // console.log(panel);
  // console.log(theme);
  // console.log(type);

  const showModal = ()=>{
    setOpenEvent(true);
  };
  const closeModal = ()=>{
    setOpenEvent(false);
  };


  const saveDSL = (eventParams:any)=>{
    /*
    * [{
    *   widgetId:
    *   updates: {
    *     modify: {
    *       属性名： 属性值
    *     }
    *   }
    * }]
    * */
    dispatch(batchUpdateMultipleWidgetProperties(eventParams));
  }
  const [eventData, setEventData] = useState<any>({
    name: undefined,
    status: 'add',
    label: '触发事件',
    children: []
  });
  //点击属性栏的删除
  const delProxy = (params:any[])=>{
    return Api.deleteByk('/byk/platform/rest/AppAction', params);
  }
  const deleteEvent = (eventStr:string)=>{
    //console.log('deleteEvent>>', eventStr, currWidgets[eventStr]);

    //删除appAction 对应的actionId
    let allDeleteIds:any[] = [];
    const fn = (cData:any)=>{
      _.each(cData, (i:any)=>{
        if(i.type == 'action'&&i.actionConfig&&i.actionConfig.actionId){
          allDeleteIds.push(i.actionConfig.actionId);
        }
        i.children&&fn(i.children);
      })
    };
    fn(_.get(currWidgets, `${eventStr}.children`));
    if(!_.isEmpty(allDeleteIds)){
      delProxy(allDeleteIds).then(()=>{
        notification["success"]({
          message: '删除事件',
          description:
            '保存成功！',
        });
      });
    }
    //

    //修改dsl
    saveDSL([{
      updates: {
        modify: {
          [eventStr]: null
        }
      },
      widgetId: id
    }])

  }
  //转换事件串兼容旧数据
  const eventToData = (data:any)=>{
    if(_.isString(data)&&(data.startsWith('{')||data.startsWith('['))){
      let oldChild = JSON.parse(data);
      let newChild:any = [];
      oldChild.map((i:any)=>{
        let pid = uuid4();
        newChild.push({
          name: 'block',
          type: 'block',
          uid: pid,
          children: [
            {
              name: 'action',
              type: 'action',
              uid: uuid4(),
              actionConfig: {
                ...i
              },
              pid
            },
            {
              name: 'success',
              type: 'success',
              uid: uuid4(),
              pid
            },
            {
              name: 'fail',
              type: 'fail',
              uid: uuid4(),
              pid
            }
          ]
        })
      })
      return {
        children: newChild
      }
    }else{
      return data;
    }
  }
  //编辑时设置对应的数据
  const editPopup = (eventStr:string)=>{
    const reData:any = eventToData(_.get(currWidgets, eventStr));
    cacheEditData.current = reData.children;
    setEventData({
      ...reData,
      status: 'edit',
      name: eventStr
    })
    showModal();
  }
  const eventDataRef = useRef({
    status: false,
    cacheEventData: {}
  });
  const eventChange = (value:any)=>{
    setEventData({
      ...eventData,
      name: value
    })
  }
  const updateEventData = (uid:any, newValues:any, key:string, isState?:boolean)=>{
    let cloneEventData:any = _.cloneDeep(eventDataRef.current.cacheEventData);
    const upFun = (data:any)=>{
      _.each(data, (i:any)=>{
        if(i.uid == uid){
          i[key] = {
            ...newValues
          }
        }else{
          i.children&&upFun(i.children);
        }
      })
    };
    upFun(cloneEventData.children);
    eventDataRef.current = {
      status: true,
      cacheEventData: cloneEventData
    }
    if(isState){
      setEventData(cloneEventData);
    }
  };
  useEffect(()=>{
    eventDataRef.current = {
      status: false,
      cacheEventData: eventData
    }
  }, [eventData]);
  const reNode = (num:number)=>{
    let pid = uuid4();
    if(num == 0){
      return {
        name: 'block',
        type: 'block',
        uid: pid,
        children: [
          {
            name: 'if',
            type: 'if',
            uid: uuid4(),
            pid
          },
          {
            name: 'else',
            type: 'else',
            uid: uuid4(),
            pid
          }
        ]
      };
    }else{
      return {
        name: 'block',
        type: 'block',
        uid: pid,
        children: [
          {
            name: 'action',
            type: 'action',
            uid: uuid4(),
            pid
          },
          {
            name: 'success',
            type: 'success',
            uid: uuid4(),
            pid
          },
          {
            name: 'fail',
            type: 'fail',
            uid: uuid4(),
            pid
          }
        ]
      };
    }
  }
  const updateIdToData = (data:any, uid:any, cb:any)=>{
    _.each(data, (i:any)=>{
      if(!i){
        return false;
      }else if(i.uid == uid){
        cb&&cb(data, i);
      }else{
        i.children&&updateIdToData(i.children, uid, cb);
      }
    })
  };
  const delEventData = (uid:any, val:any)=>{
    let cloneEventData:any = _.cloneDeep(eventDataRef.current.cacheEventData);
    //console.log('delEventData>>>', uid, val);
    if(val==1){//删除if，保留children
      updateIdToData(cloneEventData.children, uid, (p:any, c:any)=>{
        if(!_.isEmpty(c.children)){
          if(c.children[0]['type'] == 'block'){//删除if里的if，替换子节点并更换pid
            p.splice(0, p.length, ...c.children[0]['children'].map((i:any)=>{
              return {
                ...i,
                pid: c.pid
              }
            }));
            setEventData(cloneEventData);
          }
        }else{
          updateIdToData(cloneEventData.children, c.pid, (parent:any, child:any)=>{
            let _idx = _.findIndex(parent, { uid: child.uid});
            parent.splice(_idx, 1);
            setEventData(cloneEventData);
          })
        }
      })
    }else if(val==2){//删除全部
      updateIdToData(cloneEventData.children, uid, (p:any, c:any)=>{
        updateIdToData(cloneEventData.children, c.pid, (parent:any, child:any)=>{
          let _idx = _.findIndex(parent, { uid: child.uid});
          parent.splice(_idx, 1);
          setEventData(cloneEventData);
        })
      })
    }else{
      updateIdToData(cloneEventData.children, uid, (parent:any, child:any)=>{
        let _idx = _.findIndex(parent, {uid: child.uid});
        parent.splice(_idx, 1);
        setEventData(cloneEventData);
      })
    }
  }
  const addElseIf = (uid:any, idx:number)=>{
    let cloneEventData:any = _.cloneDeep(eventDataRef.current.cacheEventData);
    let _children:any = {
      name: 'elseIf',
      type: 'elseIf',
      uid: uuid4(),
    };
    const setAddData = (data:any)=>{
      _.each(data, (i:any)=>{
        if(i.uid == uid){
          data.splice(idx!+1, 0, _children);
        }else{
          i.children&&setAddData(i.children);
        }
      })
    };
    setAddData(cloneEventData.children);
    setEventData(cloneEventData);
  }
  const addEventData = (num:number, uid:any, idx?:number, isBefore?:any, type?:string)=>{//0 条件 1 动作 uid 节点
    console.log('num:', num, 'uid:', uid, 'idx:', idx, 'type:', type, isBefore);
    let cloneEventData:any = _.cloneDeep(eventDataRef.current.cacheEventData);
    let _children:any = reNode(num);
    if(!uid){
      cloneEventData.children.splice(idx!+1, 0, _children);
    }else{
      const setAddData = (data:any)=>{
        _.each(data, (i:any)=>{
          if(i.uid == uid){
            if(['if', 'elseIf', 'else', 'success', 'fail'].includes(i.type)){//if elseIf else 加到子级
              if(!i.children){
                i.children = [];
              }
              i.children.splice(idx!, 0, _children);
            }else{
              data.splice(idx!+1, 0, _children);
            }
          }else{
            if(type == 'success'){
              i.actionConfig&&i.actionConfig.success&&setAddData(i.actionConfig.success);
            }else if(type == 'fail'){
              i.actionConfig&&i.actionConfig.fail&&setAddData(i.actionConfig.fail);
            }else{
              i.children&&setAddData(i.children);
            }
          }
        })
      };
      setAddData(cloneEventData.children);
    }
    console.log('cloneEventData>>', cloneEventData);
    setEventData(cloneEventData);
  }
  const AddBtn = (props:any)=>{
    const { uid, isBefore, idx, type, className } = props;
    return (
      <div className={`add-btn-box ${className}`}>
        <AddWrapper className="add-btn-wrapper">
          <Tooltip trigger={`click`} title={
            <AddEventUl>
              <li onClick={()=>{
                addEventData(0, uid, idx, isBefore, type)
              }}>添加条件 <Tooltip title={`设置条件后，满足条件才会执行后面的动作`}><QuestionCircleFilled /></Tooltip>
              </li>
              <li onClick={()=>{
                addEventData(1, uid, idx, isBefore, type)
              }}>添加动作 <QuestionCircleFilled /></li>
            </AddEventUl>
          }>
            <PlusCircleFilled />
          </Tooltip>
        </AddWrapper>
      </div>
    );
  }
  //是否是大节点
  const ReActionWrapper = (props:any)=>{
    let {action, children, childrenNode} = props;
    if(childrenNode&&childrenNode.type == 'if'){
      let isChild = _.isEmpty(childrenNode.children);
      return <div className={isChild?'borderAfter':''}><ActionWrapper>{children}</ActionWrapper></div>;
    }
    return (
      <ActionBox>
        <div className={XPDict.actionMethods.includes(action)?'actionWrapper':''}>{children}</div>
      </ActionBox>
    );
  }
  const recursionEvent = (data:any, mark?:string)=>{
    return (
      <>
        {
          data.map((i:any, idx:number)=>{
            return (
              <>
                <ReActionWrapper type={i.type} action={i.children&&i.children[0].actionConfig&&i.children[0].actionConfig.action} childrenNode={i.children&&i.children[0]}>
                  <>
                    {
                      i.children&&i.children.map((j:any, jdx:number)=>{
                        return (
                          <>
                            {
                              j.type == 'action'&& (
                                <PaneAction recursionEvent={recursionEvent} AddBtn={AddBtn} updateEventData={updateEventData} delEventData={delEventData} data={j} />
                              )
                            }
                            {
                              i.children[0]['actionConfig']
                              &&i.children[0]['actionConfig']['action']
                              &&XPDict.actionMethods.includes(i.children[0]['actionConfig']['action'])
                              &&j.type == 'success' &&
                              (
                                <BorderTop className="action-success-box" style={{display:'flex', marginLeft: '20px', paddingLeft: '20px'}}>
                                  <div className="tag-success"><span>成功</span></div>
                                  <AddBtn className={j.children&&j.children.length>0?"action-add-btn":"action-add-btn action-success-addbtn-0"} uid={j.uid} idx={0} />
                                  {
                                    j.children && (
                                      <>
                                        { recursionEvent(j.children, 'success') }
                                      </>
                                    )
                                  }
                                </BorderTop>
                              )
                            }
                            {
                              i.children[0]['actionConfig']
                              &&i.children[0]['actionConfig']['action']
                              &&XPDict.actionMethods.includes(i.children[0]['actionConfig']['action'])
                              &&j.type == 'fail' &&
                              (
                                <BorderTop className="action-fail-box" style={{display:'flex', marginLeft: '20px', paddingLeft: '20px'}}>
                                  <div className="tag-fail"><span>失败</span></div>
                                  <AddBtn  className={j.children&&j.children.length>0?"fail-add-btn":"fail-add-btn action-fail-addbtn-0"} uid={j.uid} idx={0} />
                                  {
                                    j.children && (
                                      <>
                                        { recursionEvent(j.children, 'fail') }
                                      </>
                                    )
                                  }
                                </BorderTop>
                              )
                            }
                            { (j.type == 'if') &&
                            (
                              <IfWrapper className="if-border-top" style={{display:'flex'}}>
                                <>
                                  <div className="if-box">
                                    <CardWrapper width={`550`}>
                                      <PanelCondition uid={j.uid} idx={jdx} addElseIf={addElseIf} delEventData={delEventData} type="if" data={j} updateEventData={updateEventData} />
                                    </CardWrapper>
                                  </div>
                                  <AddBtn className="if-add-btn" uid={j.uid} idx={0} />
                                </>
                                {
                                  j.children && (
                                    <>
                                      { recursionEvent(j.children, mark) }
                                    </>
                                  )
                                }
                              </IfWrapper>
                            )
                            }
                            {
                              j.type == 'elseIf' &&
                              (
                                <BorderTop className="if-border-top" style={{display:'flex', marginLeft: '20px', paddingLeft: '20px'}}>
                                  <div>
                                    <CardWrapper width={`550`}>
                                      <PanelCondition uid={j.uid} idx={jdx} addElseIf={addElseIf} delEventData={delEventData} type="elseIf" data={j} updateEventData={updateEventData} />
                                    </CardWrapper>
                                  </div>
                                  <AddBtn className="else-if-add-btn" uid={j.uid} idx={0} />
                                  {
                                    j.children && (
                                      <>
                                        { recursionEvent(j.children, mark) }
                                      </>
                                    )
                                  }
                                </BorderTop>
                              )
                            }
                            {
                              j.type == 'else'&&(
                                <ElseWrapper className="else-border-top" style={{display:'flex', marginLeft: '20px', paddingLeft: '20px'}}>
                                  <div>
                                    <CardWrapper width={`400`}>
                                      <div className={`card-title`}>
                                        <div>当不满足其他条件时/else</div>
                                      </div>
                                      <div className={`card-box`}>

                                      </div>
                                    </CardWrapper>
                                  </div>
                                  <div className={`hideBeforeLine`} />
                                  <AddBtn className="else-add-btn" uid={j.uid} idx={0} />
                                  {
                                    j.children && (
                                      <>
                                        { recursionEvent(j.children, mark) }
                                      </>
                                    )
                                  }
                                </ElseWrapper>
                              )
                            }

                          </>
                        )
                      })
                    }
                  </>
                </ReActionWrapper>
                {
                  <AddBtn className="block-add-btn x-last-add-btn" uid={i.uid} idx={idx} />
                }
              </>
            )
          })
        }

      </>
    )
  };
  const collectActions = (data:any)=>{
    let _collectActions:any = [];
    const fn = (ida:any)=>{
      _.each(ida, (i:any)=>{
        if(i.type == 'action'&&i.actionConfig){
          _collectActions.push({
            uid: i.uid,
            ...i.actionConfig
          });
        }
        i.children&&fn(i.children);
      })
    };
    fn(data);
    return _collectActions;
  }
  const save = async ()=>{
    console.log('save***', eventDataRef);
    if(document.getElementsByClassName('x-event-error').length>0){
      console.log('error');
      return ;
    }
    closeModal();
    let valuesData:any = _.cloneDeep(eventDataRef.current.cacheEventData);
    let isBind:boolean = false;
    const _key = valuesData.name;
    let _values: any = collectActions(valuesData.children);
    const res:any = await createAppAction({
      [_key]: _values
    });
    let _uids:any = {};
    _.each(res, (j:any)=>{
      _.each(j.result, (i:any)=>{
        _uids[i.elementId] = i.id;
      })
    });
    const fn = (ida:any)=>{
      _.each(ida, (i:any)=>{
        if(i.type == 'action'&&i.actionConfig&&_uids[i.uid]){
          i.actionConfig = {
            ...i.actionConfig,
            actionId: _uids[i.uid],
            apiTarget: currWidgets.widgetId
          }
          if(i.actionConfig.action == 'get'&&!isBind){
            isBind = true;
          }
        }
        i.children&&fn(i.children);
      })
    };
    fn(valuesData.children);
    let modifyKey = valuesData.name.split('.').slice(-1)[0];
    let modify:any = {
      [modifyKey]: valuesData
    };
    let {propertySectionPath} = config[0];
    if(propertySectionPath){//层级事件绑定
      let cloneCurrWidget = _.cloneDeep(currWidgets);
      let childrenItem = _.get(cloneCurrWidget, propertySectionPath);
      childrenItem = {
        ...childrenItem,
        ...modify
      }
      let parentKey = propertySectionPath.split('.')[0];
      _.set(cloneCurrWidget, propertySectionPath, childrenItem);
      modify = {
        [parentKey]: cloneCurrWidget[parentKey]
      }
    }

    let eventParams = [
      {
        updates: {
          modify
        },
        widgetId: id
      }
    ];
    //saveDSL(eventParams);//绑定dsl事件修改
    //return ;
    //有get动作时
    let cacheParams:any = [];
    let dslParams = setWidgetData(currWidgets);
    //api接口需要绑定数据，回写dsl，变更时的参数
    if(isBind&&!_.isEmpty(dslParams)){
      //合并2个参数
      let allParams:any = _.concat([], eventParams, dslParams);
      _.each(allParams, (i)=>{
        let cache = { ...i };
        let modify = {};
        //是否已存在
        let isRepeat = _.find(cacheParams, { widgetId: i.widgetId });
        //相同的widgetId
        let isRepeatArr = _.filter(allParams, j=>{
          return i.widgetId == j.widgetId;
        });
        //不存在
        if(!isRepeat){
          _.each(isRepeatArr, k=>{
            modify = {
              ...modify,
              ...k.updates.modify
            }
          })
        }else{
          return true;
        }
        //
        if(!_.isEmpty(modify)){
          cache = {
            widgetId: i.widgetId,
            updates: {
              modify
            }
          }
        }
        cacheParams.push(cache);
      })
    }else{
      cacheParams = eventParams;
    }
    console.log('save', cacheParams);
    saveDSL(cacheParams);
  }
  const btnAddEvent = ()=>{
    //点击添加事件，弹层并重置数据
    setEventData({
      name: undefined,
      status: 'add',
      label: '触发事件',
      children: []
    });
    cacheEditData.current = [];
    showModal();
  }
  // 这个函数递归地遍历对象的子节点，并收集所有的 actionConfig
  const collectActionConfigs=(node:any)=> {
    let actionConfigs:any = [];
    // 如果当前节点有 actionConfig，则将其添加到数组中
    if (node&&node.actionConfig) {
      actionConfigs.push(node.actionConfig);
    }
    // 如果当前节点有 children，递归遍历它们
    if (node&&node.children) {
      node.children.forEach((child:any) => {
        actionConfigs = actionConfigs.concat(collectActionConfigs(child));
      });
    }
    return actionConfigs;
  }
  const getActionList=(eventStr:any)=>{
    const reData:any = eventToData(_.get(currWidgets, eventStr));
    const config:any = collectActionConfigs(reData);
    return config;
  }
  const serviceClick=(eventStr:any)=>{
    const reData:any = eventToData(_.get(currWidgets, eventStr));
    const config:any = collectActionConfigs(reData);
    //console.log(reData);
    //console.log(config);
  }
  const openPopupMapping = async (_id: any) => {
    setIsOpenPopupFn(true);
    let res: any = await Api.get("/byk/platform/rest/AppAction", {id: _id});
    if (res.success) {
      if (res.result.length > 0) {
        let _record = res.result[0];
        let record: any = {
          id: _record.id,
          actionId: _record.id,
          appId: _record.appId,
          pageId: _record.pageId,
          taskName: "",
          taskDescription: _record.description,
          tenant: _record.tenant,
          isFromAction: true
        }
        setOpenPopupRecord(record);
      }
    }

  }
  const updateIsOpenPopup = (bool: boolean) => {
    setIsOpenPopupFn(bool);
  }
  //const popRef = useRef<any>();
  const openaaa=(id:any)=>{

    // setIsOpenPopupFn(true);
    // popRef.current.openDialog(id);
    openPopupMapping(id);
  }

  const popRef: React.RefObject<any>= React.createRef();
  return (
    <>
    <PopupMapping  updateIsOpenPopup={updateIsOpenPopup}
      openPopupRecord={openPopupRecord} isOpenPopup={isOpenPopup} />

    <div style={{margin: "10px"}}>
      <Drawer
        title={
          <div style={{display:'flex', justifyContent: 'space-between'}}>
            <div>事件设置</div>
            <div>
              <Button onClick={closeModal} style={{marginRight: '10px'}}>取消</Button>
              <Button onClick={save} type={`primary`}>保存</Button>
            </div>
          </div>
        }
        open={openEvent}
        placement={`bottom`}
        height='80%'
        closable={false}
      >
        <div style={{maxWidth: '100%', display: 'flex', overflowX: 'auto'}}>
          {/*触发事件*/}
          <div>
            <CardWrapper titleBgColor={`#06B207`}>
              <div className={`card-title`}>
                <div>触发事件</div>
              </div>
              <div className={`card-box`}>
                <Select disabled={eventData.status == 'edit'} placeholder="=请选择=" value={eventData.name} onChange={eventChange} style={{width: '100%'}} options={eventConfig.filter((j:any)=>{
                  return !currWidgets[j.label];
                }).map((i:any)=>{
                  return {
                    label: i.label,
                    value: i.propertyName||i.label
                  }
                })} />
              </div>
            </CardWrapper>
          </div>

          <AddBtn className="parent-add-btn" isBefore={true} />

          {
            recursionEvent(eventData.children)
          }
          <div className={`end-box`} style={{paddingTop: '4px'}}>
            <Button disabled>● 结束</Button>
          </div>
        </div>


      </Drawer>

      <Button type={`primary`} onClick={btnAddEvent}>添加事件</Button>
      <EventUlWrapper>
        {
          eventConfig.map((i:any)=>{
            const _list:any=getActionList(i.propertyName);
            const list:any=_list.filter((m:any)=>{
              return ['get','post','put','delete','imported','exported'].indexOf(m.action)>-1;
            })
            return (
              <>
                {
                  _.get(currWidgets, i.propertyName)&&(
                    <li key={i.label} style={{position:"relative"}}>
                      <div className={`eventTitle`}>
                        <div>事件</div>
                        <div>
                          <EditOutlined onClick={()=>{
                            editPopup(i.propertyName);
                          }} />
                          <Popconfirm
                            title="确认删除吗?"
                            onConfirm={()=>{
                              deleteEvent(i.propertyName);
                            }}
                            okText="确认"
                            cancelText="取消"
                          >
                            <DeleteOutlined style={{marginLeft: '10px'}} />
                          </Popconfirm>
                          {
                            list&&list.length>0&&
                          <Tooltip trigger={"focus"} color={"white"} placement="bottom" title={()=>{

                            return (
                              <>
                                <p style={{color:"gray"}}>该事件对应有{list.length}个任务，请选择要配置服务的任务:</p>
                                <ul style={{width:"100%",padding:8}}>
                                  {
                                    list.map((item:any,idx:any)=>{
                                      return <li key={`${i.propertyName}_${item.actionId}`}>
                                        <Button type="link" onClick={()=>{openaaa(item.actionId)}}>{item.apiDescription}</Button>
                                      </li>
                                    })
                                  }
                                </ul>
                              </>
                            )
                          }}>
                            <Button type="link" style={{position:"absolute",bottom:0,right:0}} onClick={()=>{serviceClick(i.propertyName)}}>服务配置</Button>
                          </Tooltip>
                          }
                        </div>
                      </div>
                      <div className={`eventName`}>
                        {i.label}
                      </div>
                    </li>
                  )
                }
              </>
            )
          })
        }
      </EventUlWrapper>
    </div>
    </>
  )
};
export default XPropertyPanne;
