export default function Config ():string{
  return `import {AtButton} from "taro-ui";
import React from "react";
import XBase from "./XBase";

function XButton(props: any) {
  const { children, type, propertyPath, onClick, style } = props;
  const _style = style||{};
  const customStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <AtButton customStyle={customStyle}
        type={type||'primary'}
        onClick={(ev: any) => {
          onClick&&onClick(ev);
        }}
        >{children}</AtButton>
    </XBase>)
}
export default XButton;
  `;
}
