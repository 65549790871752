import React, { useRef, useState,useEffect, forwardRef, useImperativeHandle } from "react";
import Api from "@byk/api/Api";

import { Button, Form, Input, Card, message, Space, Modal, Select } from "antd";
import create_org from "@byk/assets/svg/create-org.svg";
import history from "utils/history";
import { ArrowLeftOutlined } from '@ant-design/icons';
import localStorage from "utils/localStorage";
import {appTenantClass, getUrlParams} from "@byk/utils/Utils";
import _ from "lodash";
import { routePath } from "@byk/routes";

export interface IProps {
  ref?: any,
  source?:any,
  doAction1?: (v?: any) => void;
  doAction2?: (v?: any) => void;
}
//const SearchForm: React.FC<IProps> = forwardRef((props, ref) => {
const Crud: React.FC<IProps> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showModal1,showModal2
  }))
  const {doAction1, doAction2,source} = props;
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [hyData, setHyData] = useState([]);
  const [scaleData, setScaleData] = useState([]);
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  useEffect(() => {

    openFormByAction();
  }, [])

  const bindDict=()=>{
    bindHy();
    bindScale();
  }
  const bindHy = async () => {
    let result: any = await Api.get("/byk/platform/dict/"+'行业');
    if (result.success) {
      setHyData(result.result);
    }
  };
  const openFormByAction=()=>{
    let p=getUrlParams();
    if(!_.isEmpty(p.action)){
      let _action=p.action;
      if(_action=="create"){
        showModal2()
      }
      if(_action=="join"){
        showModal1()
      }
    }
  }
  const bindScale = async () => {
    let result: any = await Api.get("/byk/platform/dict/"+'组织规模');
    if (result.success) {
      setScaleData(result.result);
    }
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const showModal2 = () => {
    bindDict();
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  // const onFinish = (values: any) => {
  //   console.log("Success:", values);
  //   message.success("登录成功！");
  //   //Api.post('/api/v1/login', values);
  //   Api.get("/api/v1/users/me");
  // };
  const refForm = useRef<any>();

  const toLogin = () => {
    let result = Api.get("/byk/platform/system/exit", null);
    localStorage.clear();
    history.push(`${routePath}/login`);
  };

  const onFinish = async (values: any) => {
    let params = new FormData();
    params.append("code", values.code);
    let result: any = await Api.post("/byk/platform/tenant/join", params);
    if (result.success) {
      message.success("申请提交成功,请等待管理员审批!",3,function(){
        if(source&&source=="header"){
          window.location.href = routePath;
        }
        else{
          toLogin();
        }
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish1 = async (values: any) => {
    let result: any = await Api.post("/byk/platform/rest/SysTenant", values);
    if (result.success) {
      message.success("创建成功!");
      handleCancel2();
      let _r=result.result[0];
      localStorage.setItem(appTenantClass.getUserId()+'_tenantId', _r.id);
      localStorage.setItem(appTenantClass.getUserId()+'_tenantName', _r.name);
      window.location.href = "/";
    }
    else {
      message.error(result.message);
    }
  };
  const onFinishFailed1 = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>

    {source!="header"&&(
      <main
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            justifyContent:"center",
            textAlign: "center"
          }}
        >
          <Space align="center">
            <div style={{ width: "100%" }} className="x-login-card">
              {/* <span>你还未加入任何组织</span><span>返回登录</span> */}
              <div className="x-w100 zIndexMax" style={{ height: "50px" }}>
                <span className="x-fl x-f24 x-bold">你还未加入任何组织</span>
                {/* <Space align="center" className="x-fr" style={{position: "relative",
                right: "-15px"}}  onClick={() => toLogin()}>
                <ArrowLeftOutlined  style={{postion:"re"}} />
                  <Button className="x-fr" size="large" type="link" >返回登录</Button>
                </Space> */}
                <Button className="x-fr" size="large" type="link" onClick={() => toLogin()} icon={<ArrowLeftOutlined />} >返回登录</Button>
              </div>
              <Card hoverable className="x-joinUs-card" onClick={() => showModal2()}>
                <p style={{ lineHeight: "108px" }}>
                  <Space align="center" className="x-vcenter" style={{ height: "100%" }}>
                    <img src={create_org} alt="" className="x-join-img" width="48px" height="38px" />
                    <span className="x-joinUs-span">创建组织</span>
                  </Space>

                </p>

              </Card>
              <Card hoverable className="x-joinUs-card" onClick={() => showModal1()} style={{ marginTop: "50px" }} >
                <p style={{ lineHeight: "108px" }}>
                  <Space align="center" className="x-vcenter" style={{ height: "100%" }}>
                    <img src={create_org} alt="" className="x-join-img" width="48px" height="38px" />
                    <span className="x-joinUs-span">加入组织</span>
                  </Space>
                </p>
                {/* <img src={create_org} alt="" /> <span className="x-joinUs-span">加入组织</span> */}
              </Card>
            </div>
          </Space>

      </main>)
    }


      <>
        <Modal title="加入组织" width="450px" cancelText="取消" okText="确定" open={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1} footer={[
          ]}>
          <Form
            name="basic1"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: "100%" }}
            initialValues={{
              code: "",
            }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <p className="x-f18  x-bold"> 请填写组织代码</p>
              <p className="x-f14 x-mt10">可从管理员或已经加入组织的同事处获取组织</p>
            </Form.Item>
            <Form.Item
              label=" 组织机构代码"
              colon={false}
              name="code"
              rules={[
                {
                  required: true,message: '请输入组织机构代码!'
                },
              ]}
            >
              <Input placeholder="请输入组织机构代码" style={{width:"100%"}} className="x-login-text" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button type="primary" htmlType="submit" className="x-login-btn" style={{ width: "100%", height: "50px" }}>
                加入
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal title="创建组织" width="450px" cancelText="取消" okText="确定" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2} footer={[

        ]}>
          <Form
            name="basic2"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: "100%" }}
            initialValues={{
              code: "",
            }}
            layout="vertical"
            onFinish={onFinish1}
            onFinishFailed={onFinishFailed1}
            autoComplete="off"
          >
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <p className="x-f18  x-bold"> 请填写组织信息</p>
              <p className="x-f14 x-mt10">当前账号将默认为组织的管理员</p>
            </Form.Item>
            <Form.Item
              label=" 组织名称"
              colon={false}
              name="name"
              rules={[
                {
                  required: true,message: '请输入组织名称!'
                },
              ]}
            >
              <Input placeholder="请输入组织名称" style={{width:"100%"}} maxLength={100} className="x-login-text" />
            </Form.Item>
            <Form.Item
              label=" 行业"
              colon={false}
              name="industry"
              rules={[
                {
                  required: true,message: '请选择行业!'
                },
              ]}
            >
              <Select
                className="x-login-text"
                placeholder="请选择行业"
                fieldNames={{ label: 'name', value: 'code' }}
                options={hyData}
              />
            </Form.Item>
            <Form.Item
              label=" 规模"
              colon={false}
              name="scale"
              rules={[
                {
                  required: true,message: '请选择规模!'
                },
              ]}
            >
              <Select
                className="x-login-text"
                placeholder="请选择规模"
                fieldNames={{ label: 'name', value: 'code' }}
                options={scaleData}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button type="primary" htmlType="submit" className="x-login-btn" style={{ width: "100%", height: "50px" }}>
                创建
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    </>
  )

})

export default Crud;
