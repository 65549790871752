export default function Config ():string{
  return `import React from "react";
import XBase from "./XBase";
import {Text, View} from "@tarojs/components";

function XText(props: any) {
  const {propertyPath, children, onClick, style} = props;
  const _style = style||{};
  const boxStyle = {
    ..._style,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }
  return (
    <XBase propertyPath={propertyPath}>
      <View style={boxStyle}  onClick={(ev: any) => {
        onClick&&onClick(ev);
      }}>
        <Text style={{width: '100%'}}>{children}</Text>
      </View>
    </XBase>)
}

export default XText;
  `;
}
