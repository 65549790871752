export default function Config ():string{
  return `import {AtInput} from "taro-ui";
import React, { useEffect, useState } from "react";
import {ReduxActionTypes} from "../../constants/ReduxActionConstants";
import {connect} from "react-redux";
import XBase from "./XBase";

function XInput(props: any) {
  const {title, type, propertyPath, placeholder, pattern, onChange, isRequired, setDataTree, defaultValue, style} = props;
  const [value, setValue] = useState('');
  const [status, setStatus] = useState(false);
  const patternFn = (v:any)=>{
    const reg = new RegExp(pattern);
    if(v===''){ 
       setStatus(isRequired);
    }else{ 
      setStatus((!reg.test(v)));
    }
  };
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  useEffect(()=>{
    setValue(defaultValue??'');
    setDataTree(\`\${propertyPath}.value\`, defaultValue??'');
  }, [defaultValue]);
  return (
    <XBase propertyPath={propertyPath}>
      <AtInput customStyle={customStyle}
        error={status}
        type={type||'text'}
        title={title}
        value={value}
        onChange={(value: any) => {
          setDataTree(\`\${propertyPath}.value\`, value);
          setValue(value);
          pattern&&patternFn(value);
          onChange&&onChange(value);
        }}
        placeholder={placeholder}
       name={propertyPath}/>
    </XBase>)
}

const dispatchToProps = (dispatch: any) => {
  return {
    setDataTree: (key: string, value: any) => {
      dispatch({
        type: ReduxActionTypes.X_SET_DATATREE_ACTION,
        payload: {
          key,
          value,
        },
      })
    }
  }
}
export default connect(null, dispatchToProps)(XInput);
  `;
}
