import * as React from "react";
import Filter from "@byk/pages/QueryBuilder/lib/queries/structured/Filter";
import DefaultPicker from "@byk/pages/QueryBuilder/components/ui/FilterPopover/DefaultPicker";

export interface Props {
  className?: any;
  filter: Filter;
  onFilterChange: (filter: any[]) => void;
  onCommit: (filter: any[]) => void;

  minWidth?: number;
  maxWidth?: number;
  primaryColor?: string;
  checkedColor?: string;
};

const FilterPopoverPicker: React.FC<Props> =
  ({
     className,
     filter,
     onFilterChange,
     onCommit,
     minWidth,
     maxWidth,
     checkedColor,
   }: Props) => {

    const setValue = (index: number, value: any) => {
      onFilterChange(filter.setArgument(index, value));
    };

    const setValues = (values: any[]) => {
      onFilterChange(filter.setArguments(values));
    };

    return (
      <DefaultPicker
        className={className}
        filter={filter}
        setValue={setValue}
        setValues={setValues}
        onCommit={onCommit}
        minWidth={minWidth}
        maxWidth={maxWidth}
        checkedColor={checkedColor}
      />
    );
  }

export default FilterPopoverPicker;
