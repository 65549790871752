import React from "react";
import localStorage from "utils/localStorage";

import type {AppState} from "@appsmith/reducers";
import 'antd/dist/antd.min.css';
import {Divider, Layout, Menu} from 'antd';
import {Link} from "react-router-dom";
import {
  ApiOutlined,
  ClusterOutlined,
  DeleteOutlined,
  FileOutlined,
  HomeOutlined,
  LaptopOutlined,
  LayoutOutlined,
  SettingOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import {connect} from "react-redux";
import { getAuthority } from "@byk/utils/authority";
import { routePath } from "@byk/routes";
import {appTenantClass} from "@byk/utils/Utils";

const {SubMenu} = Menu;
const Sider = Layout;//使用定义

class Navbar extends React.Component<any> {
  //export default class Navbar extends React.Component {
  constructor(props: any) {
    super(props);
  }

  state = {
    isOpen: false,
    tenantName: localStorage.getItem(appTenantClass.getUserId()+'_tenantName'),
    isAppAdmin:getAuthority().project==1,
  };
  subStr = (val: any, length: any) => {
    if (val.length > 10) {
      return val.substring(0, 9) + "..."
    } else {
      return val
    }
  }
  open = (url: any) => {
    window.open(url);
  }

  render() {
    const isOpen = this.props.isOpen;
    //const userInfo=this.props.users.users[0];
    let defaultOpenArray = ["sysUser"];
    //debugger;
    let title = this.props.title;
    let CoustomMenu = null;
    if (["sysUser",].indexOf(title) > -1) {
      CoustomMenu = <Menu
        mode="inline" //导航栏列出来显示
        // mode="horizontal"  //导航栏隐藏起来显示
        defaultSelectedKeys={['home']}
        defaultOpenKeys={defaultOpenArray}
        selectedKeys={[this.props.current]}
        theme="light"
        id="navBarMenu"
      >
        {/* <Menu.Item key="home" icon={<HomeOutlined />}>
                <Link to="/home">首页</Link>
            </Menu.Item> */}

        <SubMenu key="sub1" icon={<LaptopOutlined/>} title="系统管理">
          {

          }
          <Menu.Item key="sysUser">
            <Link to={`${routePath}/sysUser`}>用户管理</Link>
          </Menu.Item>
          <Menu.Item key="sysRole">
            <Link to={`${routePath}/sysRole`}>角色管理</Link>
          </Menu.Item>
          <Menu.Item key="sysMenu">
            <Link to={`${routePath}/sysMenu`}>菜单管理</Link>
          </Menu.Item>
          <Menu.Item key="sysData">
            <Link to={`${routePath}/sysData`}>数据权限管理</Link>
          </Menu.Item>
          <Menu.Item key="sysFieldRule">
            <Link to={`${routePath}/sysField`}>字段权限管理</Link>
          </Menu.Item>
          <Menu.Item key="application">
            <Link to={`${routePath}/application`}>应用管理</Link>
          </Menu.Item>
          <Menu.Item key="AppServicesManager">
            <Link to={`${routePath}/AppServicesManager`}>应用服务</Link>
          </Menu.Item>
          <Menu.Item key="ComponentsManager">
            <Link to={`${routePath}/ComponentsManager`}>自定义组件</Link>
          </Menu.Item>
        </SubMenu>

      </Menu>
    }
    if (["byk", 'ApplicationConfig', 'MyTask', 'TenantMembers1', 'ProjectRecycle'].indexOf(title) > -1) {
      CoustomMenu = <Menu
        mode="inline" //导航栏列出来显示
        // mode="horizontal"  //导航栏隐藏起来显示
        defaultSelectedKeys={['byk']}
        defaultOpenKeys={defaultOpenArray}
        selectedKeys={[title]}
        theme="light"
        id="navBarMenu"
      >
        <Menu.Item key="byk" icon={<HomeOutlined/>}>
          <Link to={routePath}>主页</Link>
        </Menu.Item>
        <Menu.Item key="MyTask" icon={<FileOutlined/>}>
          <Link to={`${routePath}/MyTask`}>我的任务</Link>
        </Menu.Item>
        <Menu.Item key="TenantRoleManager1" icon={<UserSwitchOutlined/>}>
          {/* <Link onClick={()=>open(`${routePath}/TenantMember")} to="">成员管理</Link> */}
          <Link to={`${routePath}/TenantMember`}>成员管理</Link>
        </Menu.Item>
        <Menu.Item key="TenantInfo" icon={<ClusterOutlined/>}>
          {/* <Link  onClick={()=>open(`${routePath}/TenantInfo")} to="">组织管理</Link> */}
          <Link to={`${routePath}/TenantInfo`}>组织管理</Link>
        </Menu.Item>
        <Divider/>
        <Menu.Item key="ProjectRecycle" icon={<DeleteOutlined/>}>
          {/* <Link  onClick={()=>open(`${routePath}/TenantInfo")} to="">组织管理</Link> */}
          <Link to={`${routePath}/ProjectRecycle`}>项目回收站</Link>
        </Menu.Item>
      </Menu>
    }
    if (['TenantInfo', 'TenantRoleManager', 'TenantMemberManager'].indexOf(title) > -1) {
      CoustomMenu = <>
        {/* <Button className="x-mr20" icon={<HomeOutlined />} size="middle">
                    {this.state.tenantName}
                </Button> */}
        <Link to={routePath}>
          <p className="z-nav-tenantName" title={this.state.tenantName}><HomeOutlined className="z-nav-HomeIcon"/><span
            className="z-nav-tenantName-span">{this.subStr(this.state.tenantName, 10)}</span></p>
        </Link> <Menu
        mode="inline" //导航栏列出来显示
        // mode="horizontal"  //导航栏隐藏起来显示
        defaultSelectedKeys={['byk']}
        defaultOpenKeys={defaultOpenArray}
        selectedKeys={[title]}
        theme="light"
        id="navBarMenu"
      >
        <Menu.Item key="byk" icon={<ClusterOutlined/>}>
          <Link to="" className="x-disabled">组织</Link>
        </Menu.Item>
        <Menu.Item key="TenantInfo">
          <Link className="x-ml25" to={`${routePath}/TenantInfo`}>组织信息</Link>
        </Menu.Item>
        <Menu.Item key="TenantRoleManager">
          <Link className="x-ml25" to={`${routePath}/TenantRoleManager`}>角色管理</Link>
        </Menu.Item>
        <Menu.Item key="AppServicesManager5" icon={<UserSwitchOutlined/>}>
          <Link to="" className="x-disabled">人员</Link>
        </Menu.Item>
        <Menu.Item key="TenantMemberManager">
          <Link className="x-ml25" to={`${routePath}/TenantMember`}>成员管理</Link>
        </Menu.Item>
      </Menu>
      </>
    }
    if (["ApplicationConfig", "GitHistory", "DictManager", 'ScheduledTaskManagement', 'SysConfig', 'Sysfile', 'SysRule', 'SysLog', 'SysInternationalize', 'TaskLog','SysAppUser','SysAppRole'].indexOf(title) > -1) {
      CoustomMenu = <Menu
        mode="inline" //导航栏列出来显示
        // mode="horizontal"  //导航栏隐藏起来显示
        defaultSelectedKeys={['ApplicationConfig']}
        defaultOpenKeys={defaultOpenArray}
        selectedKeys={[title]}
        theme="light"
        id="navBarMenu"
      >
        <Menu.Item key="SysAppUser" icon={<TeamOutlined />}>
          <Link to={`${routePath}/SysAppUser`}>用户管理</Link>
        </Menu.Item>
        <Menu.Item key="SysAppRole" icon={<UserAddOutlined />}>
          <Link to={`${routePath}/SysAppRole`}>角色管理</Link>
        </Menu.Item>
        {/* {
          this.state.isAppAdmin&&<>
            <Menu.Item key="SysAppUser" icon={<SettingOutlined/>}>
              <Link to=`${routePath}/SysAppUser">用户管理</Link>
            </Menu.Item>
            <Menu.Item key="SysAppRole" icon={<SettingOutlined/>}>
              <Link to=`${routePath}/SysAppRole">角色管理</Link>
            </Menu.Item>
          </>
        } */}

        <Menu.Item key="SysConfig" icon={<SettingOutlined/>}>
          <Link to={`${routePath}/SysConfig`}>系统配置</Link>
        </Menu.Item>
        <Menu.Item key="Sysfile" icon={<FileOutlined />}>
          <Link to={`${routePath}/Sysfile`}>文件管理</Link>
        </Menu.Item>
        <Menu.Item key="SysRule" icon={<SettingOutlined/>}>
          <Link to={`${routePath}/RuleManager`}>规则管理</Link>
        </Menu.Item>
        <Menu.Item key="SysLog" icon={<SettingOutlined/>}>
          <Link to={`${routePath}/LogManager`}>日志中心</Link>
        </Menu.Item>
        <Menu.Item key="SysInternationalize" icon={<SettingOutlined/>}>
          <Link to={`${routePath}/SysInternationalize`}>应用国际化</Link>
        </Menu.Item>
        <Menu.Item key="DictManager" icon={<SettingOutlined/>}>
          <Link to={`${routePath}/DictManager`}>数据字典管理</Link>
        </Menu.Item>
        <Menu.Item key="ScheduledTaskManagement" icon={<LayoutOutlined/>}>
          <Link to={`${routePath}/ScheduledTaskManagement`}>定时任务管理</Link>
        </Menu.Item>
        {/* <Menu.Item key="TaskLog" icon={<FileOutlined/>}>
          <Link to={`${routePath}/TaskLog`}>任务日志</Link>
        </Menu.Item> */}
      </Menu>
    }
    if (["ApplicationConfig", "GitHistory"].indexOf(title) > -1) {
      CoustomMenu = <Menu
        mode="inline" //导航栏列出来显示
        // mode="horizontal"  //导航栏隐藏起来显示
        defaultSelectedKeys={['ApplicationConfig']}
        defaultOpenKeys={defaultOpenArray}
        selectedKeys={[title]}
        theme="light"
        id="navBarMenu"
      >
        <Menu.Item key="ApplicationConfig" icon={<SettingOutlined/>}>
          <Link to={`${routePath}/ApplicationConfig`}>应用配置</Link>
        </Menu.Item>
        <Menu.Item key="GitHistory" icon={<SettingOutlined/>}>
          <Link to={`${routePath}/GitHistory`}>Git库历史版本</Link>
        </Menu.Item>
      </Menu>
    }
    if (["AppServicesManager", 'AppActionManager', 'ComponentsManager', 'PackageManager'].indexOf(title) > -1) {
      CoustomMenu = <Menu
        mode="inline" //导航栏列出来显示
        // mode="horizontal"  //导航栏隐藏起来显示
        defaultSelectedKeys={['ApplicationConfig']}
        defaultOpenKeys={defaultOpenArray}
        selectedKeys={[title]}
        theme="light"
        id="navBarMenu"
      >
        <Menu.Item key="AppServicesManager" icon={<ApiOutlined/>}>
          <Link to={`${routePath}/AppServicesManager`}>应用服务</Link>
        </Menu.Item>
        <Menu.Item key="AppActionManager" icon={<LayoutOutlined/>}>
          <Link to={`${routePath}/AppActionManager`}>接口管理</Link>
        </Menu.Item>
        <Menu.Item key="ComponentsManager" icon={<LayoutOutlined/>}>
          <Link to={`${routePath}/ComponentsManager`}>自定义组件</Link>
        </Menu.Item>
        <Menu.Item key="PackageManager" icon={<LayoutOutlined/>}>
          <Link to={`${routePath}/PackageManager`}>应用依赖包</Link>
        </Menu.Item>


      </Menu>
    }
    {/*这里isOpen是用来判断这个下拉导航栏是否展开，可以控制默认展开与否
        和点击子菜单时父菜单是否展开以及已经展开的情况下点击其他菜单是否收起*/
    }
    // isOpen ? defaultOpenArray = ['sub1'] : defaultOpenArray = ['sub1'];
    return (
      <Sider className="site-layout-background" id="siderNav">
        {CoustomMenu}
      </Sider>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  users: state.ui.users
});

const mapDispatchToProps = (dispatch: any) => ({
  // getCurrentUser: () => dispatch(getCurrentUser()),
  // getFeatureFlags: () => dispatch(fetchFeatureFlagsInit()),
  // getCurrentTenant: () => dispatch(getCurrentTenant(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

