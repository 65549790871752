import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout, Input, PageHeader, Tabs } from 'antd';
import BusinessPage from "@byk/pages/Tenant/Member/main";

import localStorage, {getTenantId} from "utils/localStorage";
import logo from "../../../assets/svg/logo.svg";
import TabPane from "antd/lib/tabs/TabPane";
import { getAuthority } from "@byk/utils/authority";
import {appTenantClass} from "@byk/utils/Utils";
const { Content, Sider } = Layout;
const { Search } = Input;

export default class TenantMembers extends Component {
  modalRef: React.RefObject<any>;
  childRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.modalRef = React.createRef();
    this.childRef = React.createRef();
  }
  state = {
    TenantId: getTenantId(),
    roleTitle: "角色成员",
    type: "1",
    name: '',
    isAdmin:getAuthority().tenant==1,
  };


  async componentDidMount() {

  }

  changeTable(data: any) {
    this.setState({ roleTitle: data.roleName });
    this.childRef.callback(data);
  }
  searchClick(val: any) {
    this.setState({ name: val }, () => {
      this.callTable();
    });

  }
  changeType(val: any) {
    this.setState({ type: val });
  }
  callTable() {
    let { name, type } = this.state;
    this.childRef.callback({ name, type });
  }
  render() {
    return (
      <div >
        <Layout>
          {/*引入头部 */}
          <Head title="TenantMembers" logoSrc={`${logo}?2`} />
          <Layout>
            <Sider className="Sider-Navbar"><Navbar title="TenantMemberManager" /></Sider>
            <Content>
              <PageHeader
                className="site-page-header"
                title="成员管理"
              />
              <Layout>
                <Content>
                  <div className="x-main-box">
                    <Tabs activeKey={this.state.type} size="middle" destroyInactiveTabPane={true} onTabClick={(key: any) => this.changeType(key)}>
                      <TabPane tab="全部成员" key={"1"} forceRender={false}>
                        <BusinessPage type={this.state.type}  isAdmin={this.state.isAdmin} onRef={node => this.childRef = node} />
                      </TabPane>
                      {this.state.isAdmin&&<>
                        <TabPane tab="待审批" key={"2"} forceRender={false}>
                          <BusinessPage  type={this.state.type}  isAdmin={this.state.isAdmin} onRef={node => this.childRef = node} />
                        </TabPane>
                      </>}
                    </Tabs>
                  </div>
                </Content>
              </Layout>
            </Content>
          </Layout>
        </Layout>

      </div>
    );
  }
}
