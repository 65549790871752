import {Button, Layout, Modal} from "antd";
import React, {useRef, useState} from "react";
import {downloadPublish, oneKeyDeploy} from "@byk/pages/AppDeploy/api";
import SearchForm from "../components/SearchForm";
import {searchProps, tableProps} from "@byk/pages/AppDeploy/config";
import AppDeployModal from "@byk/pages/AppDeploy/AppDeployModal";
import TableList from "@byk/pages/components/TableList";
import Spin from "antd/es/spin";
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";
import history from "../../../utils/history";
import styled from "styled-components";

const {Content} = Layout;

const ContentWrapper = styled(Content)`
  padding: 24;
  margin: 0;
  height: 100%;
  color: #151515;

  .breadcrumb {
      color: #101010;
      padding-left: 25px;
      padding-bottom: 18px;
      padding-top: 18px;

      button {
        height: 33px;
        width: 80px;
        padding: 0px;
        span.iconPark {
          height: 13px;
        }
      }

      .title {
        margin-left: 12px;
        font-size: 20px;
      }
    }
  }

  button.reset {
    background-color: #DEDEDE;
  }
`

const Crud = () => {
  const searchRef = useRef<any>();
  const modalRef = useRef<any>();
  const tableRef = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [deployOk, setDeployOk] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [msg, setMsg] = useState<any>();
  const [url, setUrl] = useState<any>();

  // 表格refresh
  const doRefreshTable = (searchData?: any) => {
    tableRef.current?.getTableList({...searchData});
  }

  // 详情按钮
  const doViewClick = (record: any) => {
    modalRef.current.showDetail(record)
  }

  // 下载资源包
  const doDownloadClick = async (record: any) => {
    await downloadPublish(record.appId, record.id)
  }

  const doOneKeyDeploy = async (record: any) => {
    setIsDone(false)
    setIsOpen(true)
    setDeployOk(false)
    setMsg(null)
    setUrl(null)
    let res: any = await oneKeyDeploy(record)
    if (res.success) {
      let msg = res.message;
      let url = '';
      if (msg.indexOf("：") > 0) {
        url = msg.substring(msg.indexOf("：") + 1, msg.length);
      }
      setUrl(url);
      setDeployOk(true)
    } else {
      setMsg(res.message)
      setDeployOk(false)
    }
    setIsDone(true)
  }

  const OneKeyLoading = () => {
    const onCancel = () => {
      setIsOpen(false)
    }
    return (
      <Modal
        footer={null} maskClosable={false}
        open={isOpen} onCancel={onCancel}
        bodyStyle={{height: "115px"}}
      >
        <Spin tip='应用发布中...' spinning={!isDone}>
          <div style={{padding: "20px", color: deployOk ? "" : "red"}}>
            {deployOk && (
              <>
                应用发布成功
                {url &&
                <Button type={'link'} onClick={() => window.open(url, "_blank")}>点击访问</Button>
                }
              </>
            )}
            {!deployOk && msg}
          </div>
        </Spin>
      </Modal>
    )
  }

  const goBack = () => {
    history.goBack();
  }

  return (
    <>
      <Layout>
        <ContentWrapper>
          <div style={{marginTop: "8px"}}>
            <div className='breadcrumb'>
              <Button icon={getIconPark('Left')} onClick={goBack}>返回</Button>
              <span className='title'>应用发布</span>
            </div>
            <SearchForm ref={searchRef} {...searchProps(doRefreshTable)} />
            <div className='x-ml25'>
              <AppDeployModal ref={modalRef} option={null} refreshTable={doRefreshTable}/>
              <TableList ref={tableRef} {...tableProps(doDownloadClick, doViewClick, doOneKeyDeploy)} />
            </div>
          </div>
        </ContentWrapper>
        <OneKeyLoading/>
      </Layout>
    </>
  )
}

export default Crud;
