import React, {useEffect, useState} from "react";
import {NotebookStepUiComponentProps} from "../../types";
import {NotebookCell, NotebookCellItem} from "@byk/pages/QueryBuilder/components/notebook/NotebookCell";
import {DataSourceSelector} from "@byk/pages/QueryBuilder/components/DataSelector/DataSelector";
import {
  FieldPickerContentContainer,
  FieldsPickerIcon
} from "@byk/pages/QueryBuilder/components/notebook/FieldsPickerIcon";
import {FIELDS_PICKER_STYLES} from "@byk/pages/QueryBuilder/components/notebook/Notebook.styled";
import PopoverWithTrigger from "../../../ui/PopoverWithTrigger";
import {FieldPicker} from "@byk/pages/QueryBuilder/components/ui/FieldPicker";
import Schemas from "@byk/pages/QueryBuilder/lib/api/schemas";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {useDispatch} from "react-redux";
import {QUERY_STRUCT} from "@byk/store/modelingReducers";

export const DataStep = ({
                           step,
                           color,
                           query,
                           updateQuery,
                         }: NotebookStepUiComponentProps) => {
  const [canSelectTableColumns, setCanSelectTableColumns] = useState(false);
  const [table, setTable] = useState<any>((query && query.modelId) ? query.modelId() : -1);
  const [fields, setFields] = useState<any[]>([]);
  const [syncQueryFlag, setSyncQueryFlag] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: QUERY_STRUCT,
      data: query
    })
  }, [syncQueryFlag]);

  const upTable = async (t: any) => {
    let properties = query.metaProperties(t.id);
    if (!properties) {
      properties = await Schemas.fetchModelProperties(t.id);
      query.setMetaProperties(t.id, properties);
      setSyncQueryFlag(!syncQueryFlag);
    }

    function initFields(properties: any[]) {
      let fieldsArr: any[] = [];
      properties.forEach(item => {
        fieldsArr.push({...item});
      })

      fieldsArr.forEach((item: { selected: boolean; }) => item.selected = true);
      setFields(fieldsArr);
    }

    initFields(properties);
    setCanSelectTableColumns(true);

    setTable(t);
    await updateQuery(query.setModelId(t.id));
  }

  return (
    <NotebookCell color={color}>
      <NotebookCellItem
        color={color}
        containerStyle={FIELDS_PICKER_STYLES.notebookItemContainer}
        rightContainerStyle={FIELDS_PICKER_STYLES.notebookRightItemContainer}
        inactive={!table}
        data-testid="data-step-cell"
        right={
          canSelectTableColumns && (
            <DataFieldsPicker
              fields={fields}
              query={query}
              stageIndex={step.stageIndex}
              updateQuery={updateQuery}
            />
          )
        }
      >
        <DataSourceSelector
          hasTableSearch
          query={query}
          selectedTableId={table?.id}
          setSourceTableFn={async (table: any) => {
            await upTable(table);
          }
          }
          isInitiallyOpen={false}
          triggerElement={
            <FieldPickerContentContainer>
              {(table && table.description) ? table.description : '选择初始数据'}
            </FieldPickerContentContainer>
          }
        />
      </NotebookCellItem>
    </NotebookCell>
  );
};


interface DataFieldsPickerProps {
  fields: any[];
  stageIndex: number;
  query: StructuredQuery;
  updateQuery: (query: any) => Promise<void>;
}

const DataFieldsPicker = ({
                            fields,
                            query,
                            updateQuery,
                          }: DataFieldsPickerProps) => {

  console.log('4. DataFieldsPicker-->', query, fields);
  const [checkboxData, setCheckboxData] = useState<any[]>([]);
  const [isAll, setIsAll] = useState(true);
  const [isNone, setIsNone] = useState(false);
  const [isDisabledDeselection, setIsDisabledDeselection] = useState(false);

  const setCheckBoxState = () => {
    let allSelect = () => checkboxData.every(columnsInfo => columnsInfo.selected);
    let noneSelect = () => checkboxData.every(columnsInfo => !columnsInfo.selected);
    let disabledDeselection = () => checkboxData.filter(item => item.selected).length <= 1;
    setIsAll(allSelect);
    setIsNone(noneSelect);
    setIsDisabledDeselection(disabledDeselection);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (query.valid()) {
      setCheckBoxState();
      query.setFields(checkboxData.filter(item => item.selected));
      dispatch({
        type: QUERY_STRUCT,
        data: query
      })
    }
  }, [checkboxData]);


  useEffect(() => {
    setCheckBoxState();
    setCheckboxData(checkboxData => (fields));
  }, [fields])

  const handleToggle = async (changedIndex: number, isSelected: boolean) => {
    let data = [...checkboxData];
    data[changedIndex].selected = isSelected;
    setCheckboxData(data);
    await updateQuery(query);
  };

  const handleSelectAll = async () => {
    let data = [...checkboxData];
    data.forEach(item => item.selected = true);
    setCheckboxData(data);
    await updateQuery(query);
  };

  const handleSelectNone = async () => {
    let data = [...checkboxData];
    data.forEach(item => item.selected = false);
    data[0].selected = true;
    setCheckboxData(data);
    await updateQuery(query);
  };

  return (
    <PopoverWithTrigger
      triggerStyle={FIELDS_PICKER_STYLES.trigger}
      triggerElement={FieldsPickerIcon}
    >
      <FieldPicker
        columnsInfo={checkboxData}
        isAll={isAll}
        isNone={isNone}
        isDisabledDeselection={isDisabledDeselection}
        onToggle={handleToggle}
        onSelectAll={handleSelectAll}
        onSelectNone={handleSelectNone}
      />
    </PopoverWithTrigger>
  );
};
