import withWidgetProps, {handleTextFun} from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XNavBar from "../../components/widgets/XNavBar";';
  begin += `<XNavBar propertyPath={${i.propertyPath}} color='${i.color}' title='${i.label}' `;
  if(i.onClickRgIconSt){
    begin += ` onClickRgIconSt={()=>{
                      evalEvent['${i.widgetName}ClickRgIconSt'](dataTree, dispatch);
                    }}`;
  }
  begin += getWidgetStyles(i);
  begin += ' />';
  return { begin, head };
};
