import React from "react";
import {CodeContainer, CodeText,} from "./NativeCodePanel.styled";
import {Button} from "antd";
import styled from "styled-components";

interface NativeCodePanelProps {
  value: string;
  sqlMessage: string;
  onCloseModal: () => void
}

const ButtonContainer = styled.div`
margin-top: 5px;
float: right;
`;

const NativeCodePanel = ({value, sqlMessage, onCloseModal}: NativeCodePanelProps) => {
  return (
    <>
      <CodeContainer>
        <CodeText>{formatSQL(value)}</CodeText>
      </CodeContainer>

      <ButtonContainer>
        <Button type={"primary"} size={"small"} onClick={onCloseModal}>关闭</Button>
      </ButtonContainer>

      <div style={{marginTop: "10px"}}>
        {sqlMessage}
      </div>
    </>
  );
};

export default NativeCodePanel;

function formatSQL(sql: string) {
  sql = sql.replace(/\sFROM/, "\nFROM");
  sql = sql.replace(/\sLEFT JOIN/, "\nLEFT JOIN");
  sql = sql.replace(/\sWHERE/, "\nWHERE");
  sql = sql.replace(/\sGROUP BY/, "\nGROUP BY");
  sql = sql.replace(/\sORDER BY/, "\nORDER BY");
  sql = sql.replace(/\sLIMIT/, "\nLIMIT");
  sql = sql.replace(/\sAND\s/, "\n   AND ");
  sql = sql.replace(/\sOR\s/, "\n    OR ");
  return sql;
}
