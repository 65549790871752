import type {
  ChangeSelectedAppThemeAction,
  DeleteAppThemeAction,
  FetchAppThemesAction,
  FetchSelectedAppThemeAction,
  SaveAppThemeAction,
  UpdateSelectedAppThemeAction,
} from "actions/appThemingActions";
import { updateisBetaCardShownAction } from "actions/appThemingActions";
import type { ReduxAction } from "@appsmith/constants/ReduxActionConstants";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "@appsmith/constants/ReduxActionConstants";
import ThemingApi from "api/AppThemingApi";
import { all, takeLatest, put, select } from "redux-saga/effects";
import { toast } from "design-system";
import {
  CHANGE_APP_THEME,
  createMessage,
  DELETE_APP_THEME,
  SAVE_APP_THEME,
  SET_DEFAULT_SELECTED_THEME,
} from "@appsmith/constants/messages";
import { ENTITY_TYPE } from "entities/AppsmithConsole";
import { updateReplayEntity } from "actions/pageActions";
import {getAppMode, getCanvasWidgets} from "selectors/entitiesSelector";
import { getCurrentUser } from "selectors/usersSelectors";
import type { User } from "constants/userConstants";
import { getBetaFlag, setBetaFlag, STORAGE_KEYS } from "utils/storage";
import type { UpdateWidgetPropertyPayload } from "actions/controlActions";
import { batchUpdateMultipleWidgetProperties } from "actions/controlActions";
import { getPropertiesToUpdateForReset } from "entities/AppTheming/utils";
import type { AppTheme } from "entities/AppTheming";
import type { CanvasWidgetsReduxState } from "reducers/entityReducers/canvasWidgetsReducer";
import {
  getCurrentApplicationId,
  selectApplicationVersion,
} from "selectors/editorSelectors";
import { find } from "lodash";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";
import { getAllPageIds } from "./selectors";
import type { SagaIterator } from "@redux-saga/types";
import type { AxiosPromise } from "axios";
import { APP_MODE } from "entities/App";
import {ResponseMeta} from "api/ApiResponses";

type ApiResponse<T = unknown> = {
  responseMeta: ResponseMeta;
  result: T;
  code?: string;
};

export const DEFAULT_THEME = {
    name: "Default",
    displayName: "Modern",
    config: {
      colors: {
        primaryColor: "#553DE9",
        backgroundColor: "#F8FAFC",
      },
      borderRadius: {
        appBorderRadius: {
          none: "0px",
          M: "0.375rem",
          L: "1.5rem"
        }
      },
      boxShadow: {
        appBoxShadow: {
          none: "none",
          S: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
          M: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          L: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
        }
      },
      fontFamily: {
        appFont: ["System Default", "Nunito Sans", "Poppins", "Inter", "Montserrat", "Noto Sans", "Open Sans", "Roboto", "Rubik", "Ubuntu"]
      }
    },
    properties: {
      colors: {
        primaryColor: "#006cf2",
        backgroundColor: "#F8FAFC",
        textColor: "#18181b",
      },
      // button: {
      //   bgColor: {
      //     primary: '#553DE9',
      //     default: '#553DE9',
      //     text:'white',
      //   },
      //   fontColor: {
      //     primary: 'white',
      //     default: '#553DE9',
      //     text:'#553DE9',
      //   },
      //   fontSize:  {
      //     primary: 'S',
      //     default: 'S',
      //     text:'S',
      //   },
      //   fontStyle:  {
      //     primary: [],
      //     default: [],
      //     text:[],
      //   },
      // },
      form: {
        label: {
          fontColor: '#090707',
          fontSize: "0.875rem",  // S
          fontStyle: "",
        },
        inputText: {
          fontColor: '#090707',
          fontSize: "0.875rem",  // S
          fontStyle: "",
        },
        inputBorder: {
          bgColor: '#FFFFFF',
          bgBorderColor: '#e0dede',
        },
      },
      borderRadius: {
        appBorderRadius: "0.375rem"
      },
      boxShadow: {
        appBoxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"
      },
      fontFamily: {
        appFont: "Nunito Sans"
      },
      dynamicPropertyPathList: [],
    },
  // stylesheet: {
    //   AUDIO_RECORDER_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   BUTTON_WIDGET: {
    //     buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   BUTTON_GROUP_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none",
    //     childStylesheet: {
    //       button: {
    //         buttonColor: "{{appsmith.theme.colors.primaryColor}}"
    //       }
    //     }
    //   },
    //   CAMERA_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   CHART_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     fontFamily: "{{appsmith.theme.fontFamily.appFont}}"
    //   },
    //   CHECKBOX_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}"
    //   },
    //   CHECKBOX_GROUP_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}"
    //   },
    //   CONTAINER_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   CIRCULAR_PROGRESS_WIDGET: {
    //     "fillColor": "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}"
    //   },
    //   CURRENCY_INPUT_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   PHONE_INPUT_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   DATE_PICKER_WIDGET2: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   FILE_PICKER_WIDGET_V2: {
    //     buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   FORM_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   FORM_BUTTON_WIDGET: {
    //     buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   ICON_BUTTON_WIDGET: {
    //     buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   IFRAME_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   IMAGE_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   INPUT_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   INPUT_WIDGET_V2: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   JSON_FORM_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    //     submitButtonStyles: {
    //       buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //       borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //       boxShadow: "none"
    //     },
    //     resetButtonStyles: {
    //       buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //       borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //       boxShadow: "none"
    //     },
    //     childStylesheet: {
    //       ARRAY: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none",
    //         "cellBorderRadius": "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         "cellBoxShadow": "none"
    //       },
    //       OBJECT: {
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none",
    //         cellBorderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         cellBoxShadow: "none"
    //       },
    //       CHECKBOX: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}"
    //       },
    //       CURRENCY_INPUT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       DATEPICKER: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       EMAIL_INPUT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       MULTISELECT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       MULTILINE_TEXT_INPUT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       NUMBER_INPUT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       PASSWORD_INPUT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       PHONE_NUMBER_INPUT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       RADIO_GROUP: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         boxShadow: "none"
    //       },
    //       SELECT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       SWITCH: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         boxShadow: "none"
    //       },
    //       TEXT_INPUT: {
    //         accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       }
    //     }
    //   },
    //   LIST_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   MAP_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   MAP_CHART_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    //     fontFamily: "{{appsmith.theme.fontFamily.appFont}}"
    //   },
    //   MENU_BUTTON_WIDGET: {
    //     menuColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   MODAL_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   MULTI_SELECT_TREE_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   MULTI_SELECT_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   MULTI_SELECT_WIDGET_V2: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   DROP_DOWN_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   PROGRESSBAR_WIDGET: {
    //     fillColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}"
    //   },
    //   PROGRESS_WIDGET: {
    //     fillColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}"
    //   },
    //   CODE_SCANNER_WIDGET: {
    //     buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   RATE_WIDGET: {
    //     activeColor: "{{appsmith.theme.colors.primaryColor}}"
    //   },
    //   RADIO_GROUP_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     boxShadow: "none"
    //   },
    //   RICH_TEXT_EDITOR_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   STATBOX_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   SWITCH_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     boxShadow: "none"
    //   },
    //   SWITCH_GROUP_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}"
    //   },
    //   SELECT_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   TABLE_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    //     childStylesheet: {
    //       button: {
    //         buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       menuButton: {
    //         menuColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       iconButton: {
    //         buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       }
    //     }
    //   },
    //   TABLE_WIDGET_V2: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    //     childStylesheet: {
    //       button: {
    //         buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       menuButton: {
    //         menuColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       iconButton: {
    //         buttonColor: "{{appsmith.theme.colors.primaryColor}}",
    //         borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         boxShadow: "none"
    //       },
    //       editActions: {
    //         saveButtonColor: "{{appsmith.theme.colors.primaryColor}}",
    //         saveBorderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //         discardButtonColor: "{{appsmith.theme.colors.primaryColor}}",
    //         discardBorderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}"
    //       }
    //     }
    //   },
    //   TABS_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   TEXT_WIDGET: {
    //     truncateButtonColor: "{{appsmith.theme.colors.primaryColor}}",
    //     fontFamily: "{{appsmith.theme.fontFamily.appFont}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}"
    //   },
    //   VIDEO_WIDGET: {
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}"
    //   },
    //   SINGLE_SELECT_TREE_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}",
    //     borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    //     boxShadow: "none"
    //   },
    //   CATEGORY_SLIDER_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}"
    //   },
    //   NUMBER_SLIDER_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}"
    //   },
    //   RANGE_SLIDER_WIDGET: {
    //     accentColor: "{{appsmith.theme.colors.primaryColor}}"
    //   }
    // },
    // new: false,
    isSystemTheme: false,
  };

/**
 * init app theming
 */
export function* initAppTheming() {
  try {
    const user: User = yield select(getCurrentUser);
    const { email } = user;
    if (email) {
      const appThemingBetaFlag: boolean = yield getBetaFlag(
        email,
        STORAGE_KEYS.APP_THEMING_BETA_SHOWN,
      );

      yield put(updateisBetaCardShownAction(appThemingBetaFlag));
    }
  } catch (error) {}
}

/**
 * fetches all themes of the application
 *
 * @param action
 */
// eslint-disable-next-line
export function* fetchAppThemes(action: ReduxAction<FetchAppThemesAction>) {
  try {
    const { applicationId } = action.payload;
    const response: ApiResponse<AppTheme> = yield ThemingApi.fetchThemes(
      applicationId,
    );

    yield put({
      type: ReduxActionTypes.FETCH_APP_THEMES_SUCCESS,
      payload: response.result,
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_APP_THEMES_ERROR,
      payload: { error },
    });
  }
}

export function* fetchAppSelectedTheme(
  // eslint-disable-next-line
  action: ReduxAction<FetchSelectedAppThemeAction>,
): SagaIterator | AxiosPromise {
  const { applicationId } = action.payload;
  const mode: APP_MODE = yield select(getAppMode);

  const pageIds = yield select(getAllPageIds);
  const userDetails = yield select(getCurrentUser);
  const applicationVersion = yield select(selectApplicationVersion);
  try {
    // eslint-disable-next-line
    const response: ApiResponse<AppTheme[]> = yield ThemingApi.fetchSelected(
      applicationId,
      mode,
    );

    let themes:any = response.result  || [];
    if (themes.length == 0) {
      const response: ApiResponse<AppTheme> = yield ThemingApi.saveTheme(
        applicationId, DEFAULT_THEME
      );
      themes = response.result;
    }

    if (themes && themes.length == 1) {
      yield put({
        type: ReduxActionTypes.FETCH_SELECTED_APP_THEME_SUCCESS,
        payload: themes[0],
      });
    } else {
      Sentry.captureException("Unable to fetch the selected theme", {
        level: Severity.Critical,
        extra: {
          pageIds,
          applicationId,
          applicationVersion,
          userDetails,
          themeResponse: response,
        },
      });

      // If the response.data is undefined then we set selectedTheme to default Theme
      yield put({
        type: ReduxActionTypes.SET_DEFAULT_SELECTED_THEME_INIT,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_SELECTED_APP_THEME_ERROR,
      payload: { error },
    });
  }
}

/**
 * updates the selected theme of the application
 *
 * @param action
 */
export function* updateSelectedTheme(
  action: ReduxAction<UpdateSelectedAppThemeAction>,
) {
  // eslint-disable-next-line
  const { shouldReplay = true, theme, applicationId } = action.payload;
  const canvasWidgets: CanvasWidgetsReduxState = yield select(getCanvasWidgets);

  try {
    yield ThemingApi.updateTheme(applicationId, theme);

    yield put({
      type: ReduxActionTypes.UPDATE_SELECTED_APP_THEME_SUCCESS,
      payload: theme,
    });

    if (shouldReplay) {
      yield put(
        updateReplayEntity(
          "canvas",
          { widgets: canvasWidgets, theme },
          ENTITY_TYPE.WIDGET,
        ),
      );
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_SELECTED_APP_THEME_ERROR,
      payload: { error },
    });
  }
}

/**
 * changes eelcted theme
 *
 * @param action
 */
export function* changeSelectedTheme(
  action: ReduxAction<ChangeSelectedAppThemeAction>,
) {
  const { applicationId, shouldReplay = true, theme } = action.payload;
  const canvasWidgets: CanvasWidgetsReduxState = yield select(getCanvasWidgets);

  try {
    yield ThemingApi.changeTheme(applicationId, theme);

    yield put({
      type: ReduxActionTypes.CHANGE_SELECTED_APP_THEME_SUCCESS,
      payload: theme,
    });

    // shows toast
    toast.show(createMessage(CHANGE_APP_THEME, theme.displayName), {
      kind: "success",
    });

    if (shouldReplay) {
      yield put(
        updateReplayEntity(
          "canvas",
          { widgets: canvasWidgets, theme },
          ENTITY_TYPE.WIDGET,
        ),
      );
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_SELECTED_APP_THEME_ERROR,
      payload: { error },
    });
  }
}

/**
 * save and create new theme from  selected theme
 *
 * @param action
 */
export function* saveSelectedTheme(action: ReduxAction<SaveAppThemeAction>) {
  const { applicationId, name } = action.payload;

  try {
    const response: ApiResponse<AppTheme[]> = yield ThemingApi.saveAsTheme(
      applicationId,
      { name },
    );

    yield put({
      type: ReduxActionTypes.SAVE_APP_THEME_SUCCESS,
      payload: response.result[0],
    });

    // shows toast
    toast.show(createMessage(SAVE_APP_THEME, name), {
      kind: "success",
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.SAVE_APP_THEME_ERROR,
      payload: { error },
    });
  }
}

/**
 * deletes custom saved theme
 *
 * @param action
 */
export function* deleteTheme(action: ReduxAction<DeleteAppThemeAction>) {
  const { name, themeId } = action.payload;

  try {
    yield ThemingApi.deleteTheme(themeId);

    yield put({
      type: ReduxActionTypes.DELETE_APP_THEME_SUCCESS,
      payload: { themeId },
    });

    // shows toast
    toast.show(createMessage(DELETE_APP_THEME, name), {
      kind: "success",
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.DELETE_APP_THEME_ERROR,
      payload: { error },
    });
  }
}

function* closeisBetaCardShown() {
  try {
    const user: User = yield select(getCurrentUser);
    const { email } = user;
    if (email) {
      yield setBetaFlag(email, STORAGE_KEYS.APP_THEMING_BETA_SHOWN, true);
    }
  } catch (error) {}
}

/**
 * resets widget styles
 */
function* resetTheme() {
  try {
    const canvasWidgets: CanvasWidgetsReduxState = yield select(
      getCanvasWidgets,
    );
    const propertiesToUpdate: UpdateWidgetPropertyPayload[] =
      getPropertiesToUpdateForReset(canvasWidgets);

    if (propertiesToUpdate.length) {
      yield put(batchUpdateMultipleWidgetProperties(propertiesToUpdate));
    }
  } catch (error) {}
}

/**
 * sets the selectedTheme to default theme when Selected Theme API fails
 */
function* setDefaultSelectedThemeOnError() {
  const applicationId: string = yield select(getCurrentApplicationId);
  try {
    // Fetch all system themes
    const response: ApiResponse<AppTheme[]> = yield ThemingApi.fetchThemes(
      applicationId,
    );

    // Gets default theme
    const theme = find(response.result, { name: "Default" });

    if (theme) {
      // Update API call to set current theme to default
      yield ThemingApi.changeTheme(applicationId, theme);
      yield put({
        type: ReduxActionTypes.FETCH_SELECTED_APP_THEME_SUCCESS,
        payload: theme,
      });
      // shows toast
      toast.show(createMessage(SET_DEFAULT_SELECTED_THEME, theme.displayName), {
        kind: "warning",
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.SET_DEFAULT_SELECTED_THEME_ERROR,
      payload: { error },
    });
  }
}
export default function* appThemingSaga() {
  yield all([takeLatest(ReduxActionTypes.INITIALIZE_EDITOR, initAppTheming)]);
  yield all([
    takeLatest(ReduxActionTypes.FETCH_APP_THEMES_INIT, fetchAppThemes),
    takeLatest(ReduxActionTypes.RESET_APP_THEME_INIT, resetTheme),
    takeLatest(
      ReduxActionTypes.FETCH_SELECTED_APP_THEME_INIT,
      fetchAppSelectedTheme,
    ),
    takeLatest(
      ReduxActionTypes.UPDATE_SELECTED_APP_THEME_INIT,
      updateSelectedTheme,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_SELECTED_APP_THEME_INIT,
      changeSelectedTheme,
    ),
    takeLatest(ReduxActionTypes.SAVE_APP_THEME_INIT, saveSelectedTheme),
    takeLatest(ReduxActionTypes.DELETE_APP_THEME_INIT, deleteTheme),
    takeLatest(ReduxActionTypes.CLOSE_BETA_CARD_SHOWN, closeisBetaCardShown),
    takeLatest(
      ReduxActionTypes.SET_DEFAULT_SELECTED_THEME_INIT,
      setDefaultSelectedThemeOnError,
    ),
  ]);
}
