import type {ReactNode} from "react";
import React from "react";
import styled from "styled-components";
import type {ContainerStyle} from "widgets/ContainerWidget/component";
import type {Color} from "constants/Colors";
import _ from "lodash";

export enum BoxShadowTypes {
  NONE = "NONE",
  VARIANT1 = "VARIANT1",
  VARIANT2 = "VARIANT2",
  VARIANT3 = "VARIANT3",
  VARIANT4 = "VARIANT4",
  VARIANT5 = "VARIANT5",
}

export type BoxShadow = keyof typeof BoxShadowTypes;

export interface WidgetStyleContainerProps {
  widgetId: string;
  containerStyle?: ContainerStyle;
  children?: ReactNode;
  borderColor?: Color;
  backgroundColor?: Color;
  backgroundImage?: string | any;
  borderWidth?: number;
  borderRadius?: number;
  boxShadow?: BoxShadow;
  className?: string;
  selected?: boolean;
  direction?: string;
  isCircle?: boolean;
  width?: number;
  height?: number;
  objectFit?: string;
  accentColor:string;
}


const WidgetStyle = styled.div<WidgetStyleContainerProps>`
  width:  ${({height}) => (_.isEmpty(height) ? "100%" : `${height}px`)};
  height:  ${({height}) => (_.isEmpty(height) ? "100%" : `${height}px`)};
  border-radius: ${({borderRadius, isCircle}) => (isCircle ? "9999px" : borderRadius)};
  box-shadow: ${(props) => props.boxShadow} !important;
  border-width: ${(props) => props.borderWidth || 0}px;
  border-color: ${(props) => props.borderColor || "transparent"};
  outline: ${(props) =>
  props.selected
    ? `${props.borderWidth || 1}px solid ${props.accentColor} !important`
    : ""};
  border-style: solid;
  background-color: ${(props) => props.backgroundColor || "transparent"};
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-repeat: no-repeat;
  background-size: ${({objectFit}) => objectFit};
  background-position: center;
  display: block;
  overflow: hidden;

  & > div {
    height: 100%;
    width: 100%;
  }
`;

// wrapper component for apply styles on any widget boundary
function WidgetStyleContainer(props: WidgetStyleContainerProps) {
  return (
    <WidgetStyle {...props} data-testid={`container-wrapper-${props.widgetId}`}>
      {props.children}
    </WidgetStyle>
  );
}

export default WidgetStyleContainer;
