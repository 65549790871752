import React from "react";
import type {AppState} from "@appsmith/reducers";
import 'antd/dist/antd.min.css'; //显示样式，不加显示不出来

import {Avatar, Badge, Button, Col, Descriptions, Divider, Form, Input, message, Modal, Popover, Row} from 'antd';
import logo from "../../../../byk/assets/svg/logo.svg";
import images1 from "@byk/assets/images/avatar.jpg";
import history from "utils/history";
import Api from "@byk/api/Api";
import {X_Edit_Url, X_SUBAPPID, X_TENANTID} from '@byk/store/XReducers';
import {connect} from "react-redux";
import encryptedData from "@byk/utils/encryptedData";
import {setAppMode} from "../../../../actions/pageActions";
import {APP_MODE} from "entities/App";
import user_drop from "@byk/assets/images/user_drop.png";
import localStorage from "utils/localStorage";
import { routePath } from "@byk/routes";
class LogIn extends React.Component<any> {
  formRef1: React.RefObject<any>;
  formRef2: React.RefObject<any>;
  formRef3: React.RefObject<any>;
  joinRef: React.RefObject<any>;

  constructor(props: any) {
    super(props);
    this.formRef1 = React.createRef();
    this.formRef2 = React.createRef();
    this.formRef3 = React.createRef();
    this.joinRef = React.createRef();
  }

  state = {
    userInfo: null,
    isModalOpen: false,
    defalutTenant: null,
    isModalOpenUserName: false,
    appId: null,
    cfg: {
      title: "修改姓名",
      mode: 1,
      visable: false
    },
    logoSrc: this.props.logoSrc || logo
  };

  logOut = () => {
    Api.get("/byk/platform/system/exit", null);
    localStorage.clear();
    history.push(`${routePath}/login`);
  };

  showModal() {
    this.setState({isModalOpen: true});
  };

  infoSet() {
    this.showModal();
  };

  handleOk = () => {
    this.setState({isModalOpen: false});
  };

  handleCancel = () => {
    this.setState({isModalOpen: false});
  };

  showModal1(obj?: any, mode?: any) {
    const _cfg = {
      ...this.state.cfg,
      title: (mode == 1 ? "修改姓名" : (mode == 2 ? "修改邮箱" : "重置密码")),
      mode: mode,
      visable: true
    }
    this.setState({cfg: _cfg}, () => {
      const defalutValues: any = {};
      //let key:string=(mode==1?"name":"email");
      if (mode == 1) {
        defalutValues["name"] = obj;
      } else if (mode == 2) {
        defalutValues["email"] = obj;
      }
      this.formRef1.current?.setFieldsValue(defalutValues);
    });
  };

  infoSet1() {
    this.showModal();
  };

  handleOk1 = () => {
    this.setState({isModalOpenUserName: false});
  };

  handleCancel1 = () => {
    let cfg = {...this.state.cfg, visable: false};
    this.setState({cfg: cfg});
  };

  updateData = async (value: any) => {
    value.id = this.props.users.users[0]?.id;
    if (this.state.cfg.mode == 3) {
      value.password = encryptedData(value.password);
    }
    let result: any = await Api.put("/byk/platform/rest/SysUser", value);
    if (result.success) {
      message.success("修改成功!");
      this.updateValue();
    } else {
      message.error(result.message);
    }
  }

  updateValue = () => {
    window.location.reload()
  }

  componentDidMount() {
    this.bind();
  }

  bind() {
    if (this.props.users.users[0] != undefined) {
      let user = this.props.users.users[0];
      this.setState({userInfo: user});
      localStorage.setItem('userId', user.id);
    }
  }

  render() {
    let UserDropDown1 = (props: any) => (
      <ul className="dropdown-menu list-group dropdown-persist">
        <li className="list-group-item dropdown-footer">
          <Button type="link" onClick={() => this.infoSet()}>个人信息</Button>
        </li>
        <li className="list-group-item dropdown-footer">
          <Button type="link" onClick={() => this.logOut()}>退出登录</Button>
        </li>
      </ul>
    );

    return (
      <>
        <div>
          <Badge dot>
            <Avatar src={images1} size={48}>
              {this.props.users.users[0]?.name}
            </Avatar>
          </Badge>

          <Popover
            className="x-fr x-mr20"
            placement="bottomRight"
            content={<UserDropDown1/>}
            trigger="click"
          >
            <a className="dropdown-toggle">
              <img style={{
                marginTop: '12px',
                marginLeft: '6px',
                width: '18px',
                height: '18px',
              }} src={user_drop}/>
            </a>
          </Popover>

        </div>

        <Modal title="个人信息" open={this.state.isModalOpen} onOk={this.showModal} onCancel={this.handleCancel}
               footer={[]}>
          <Row>
            <Col className="gutter-row" md={12}>
              <Descriptions layout="vertical">
                <Descriptions.Item label="姓名">{this.props.users.users[0]?.name}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col className="gutter-row" md={12}>
              <Button type="link" className="x-fr"
                      onClick={() => this.showModal1(this.props.users.users[0]?.name, 1)}>编辑</Button>
            </Col>
          </Row>
          <Divider/>
          <Descriptions layout="vertical">
            <Descriptions.Item label="手机号">{this.props.users.users[0]?.mobile}</Descriptions.Item>
          </Descriptions>
          <Divider/>
          <Row>
            <Col className="gutter-row" md={12}>
              <Descriptions layout="vertical">
                <Descriptions.Item label="账号密码">已设置</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col className="gutter-row" md={12}>
              <Button type="link" className="x-fr" onClick={() => this.showModal1("", 3)}>重置密码</Button>
            </Col>
          </Row>

          <Divider/>
          <Row>
            <Col className="gutter-row" md={12}>
              <Descriptions layout="vertical">
                <Descriptions.Item label="邮箱">{this.props.users.users[0]?.email}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col className="gutter-row" md={12}>
              <Button type="link" className="x-fr"
                      onClick={() => this.showModal1(this.props.users.users[0]?.email, 2)}>编辑</Button>
            </Col>
          </Row>
        </Modal>

        <Modal title={this.state.cfg.title} open={this.state.cfg.visable} onOk={this.showModal1}
               onCancel={this.handleCancel1} footer={[]}>
          <Row>
            <Col className="gutter-row" md={24}>
              <Form ref={this.formRef1} layout={"vertical"} onFinish={this.updateData}>
                {this.state.cfg.mode == 1 && (
                  <>
                    <Form.Item label="姓名" name="name"
                               rules={[{required: true, message: "请输入!"}]}>
                      <Input placeholder="请输入姓名"/>
                    </Form.Item>
                  </>
                )}
                {this.state.cfg.mode == 2 && (
                  <>
                    <Form.Item label="邮箱" name="email"
                               rules={[{required: true, message: "请输入!"}]}>
                      <Input placeholder="请输入邮箱"/>
                    </Form.Item>
                  </>
                )}
                {this.state.cfg.mode == 3 && (
                  <>
                    <Form.Item label="新密码" name="password"
                               rules={[{required: true, message: "请输入!"}]}>
                      <Input.Password placeholder="请输入" minLength={8}/>
                    </Form.Item>
                    <Form.Item label="确认新密码" name="repassword"
                               rules={[{required: true, message: "请输入!"}]}>
                      <Input.Password placeholder="请输入" minLength={8}/>
                    </Form.Item>
                  </>
                )}

                <div style={{width: "100%", textAlign: "center"}}>
                  <Button onClick={this.handleCancel1}>取消</Button>
                  <Button className="x-ml10" type="primary" htmlType="submit">
                    确定
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  editUrl: state.XReducers.editUrl == '/' ? localStorage.getItem('editUrl') : state.XReducers.editUrl,
  users: state.ui.users,
  mode: state.entities.app.mode,
});
const mapDispatchToProps = (dispatch: any) => ({
  setTenantId: (data: string) => {
    dispatch({
      type: X_TENANTID,
      data
    })
  },
  setEditUrl: (data: string, appId: string) => {
    dispatch({
      type: X_Edit_Url,
      data
    })
    dispatch({
      type: X_SUBAPPID,
      data: appId
    })
  },
  setModeNone: () => {
    dispatch(setAppMode(APP_MODE.NONE))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
