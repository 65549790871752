import _ from "lodash";

export default function Config (pageLists:any):string{
  const pageList = Object.values(pageLists).filter((i:any)=>{
    return i.slug !== 'frameDesignPage';
  });
  const slugs = pageList.map((i:any)=>{
    return i.slug;
  });
  const _pages:string[] = ['pages/login/index', 'pages/homePage/index'];
  _.each(slugs, (i:string)=>{
    if(i!='homePage'){
      _pages.push(`pages/${i}/index`);
    }
  });
  const _pagesJsonStr:string = JSON.stringify(_pages, null, 2);
  return `export default defineAppConfig({
  pages: ${_pagesJsonStr},
  window: {
    backgroundTextStyle: 'light',
    navigationBarBackgroundColor: '#fff',
    navigationBarTitleText: 'WeChat',
    navigationBarTextStyle: 'black'
  }
})
  `;
}
