export default function Config ():string{
  return `
import _ from "lodash";

interface RegType {
  res2DataResult: Array<any>,
  keyMap: Record<string, any>,
  widgetTypeMap: Record<string, string>
}

export default function setWidgetValue(regs:RegType):Array<any>{
  let res2DataResultHandle:Array<any> = [];
  const {res2DataResult, keyMap} = regs;
  _.each(res2DataResult, (i:any, index:number)=>{
    res2DataResultHandle[index] = {};
    _.each(i, (value:any, key:any)=>{
      if(keyMap[key]){
        if(Object.keys(keyMap).includes(key) && value == null){
          return true;
        }
        let cacheValue:any = value;
        res2DataResultHandle[index][keyMap[key]] = key==='id'?cacheValue?.toString():cacheValue;
      }
    })
  })
  return res2DataResultHandle;
}

  `;
}
