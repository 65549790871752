import React, {useContext, useEffect, useState} from "react";
import 'antd/dist/antd.min.css'; //显示样式，不加显示不出来

import {Layout, Space} from 'antd';
import byk_log from "@byk/assets/images/byk_logo_track.png";
import styled from "styled-components";
import LogIn from "../components/Header/LogIn";
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";
import TrackForm from "@byk/pages/Track/TrackForm";
import TrackList from "@byk/pages/Track/TrackList";
import {issuesApi} from "@byk/pages/Track/trackApi";
import {ContentPageFlagType} from "@byk/pages/Track/constant";
import TrackDetail from "@byk/pages/Track/TrackDetail";
import {model} from "@byk/pages/Track/model";
import { routePath } from "@byk/routes";
import {Link} from "react-router-dom";

const LayoutWrapper = styled(Layout)`
  display: flex;
  border-bottom: 1px solid rgb(228, 232, 235);
  position: relative;
  z-index: 1;
  height: 64px;
  flex-direction: row;
  background: #006CF2 !important;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: white;
    height: 26px;
    line-height: 26px;
    display: inline-block;
    margin-bottom: 5px;
    border-left: 2px solid;
    padding-left: 17px;
    margin-left: 9px;
  }
`

const LogInWrapper = styled.div`
  position: absolute;
  right: 0;
  margin-right: 12px;
  margin-top: 8px;
}
`

const ContentBox = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - ${(props) => props.theme.smallHeaderHeight});

  .left {
    width: 200px;
    height: 100%;
    border-right: 1px solid #E2E2E2;

    li {
      display: flex;
      margin: 12px 10px;
      background: rgb(242, 242, 242);
      align-items: center;
      font-size: 14px;
      color: #101010;
      cursor: pointer;

      .iconPark {
        padding: 12px 8px 12px 20px;
      }
    }
  }

  .content {
    height: 100%;
    width: 100%;

    .breadcrumb {
      color: #101010;
      font-size: 18px;
      padding-left: 25px;
      padding-bottom: 18px;
      padding-top: 18px;

      button {
        height: 36px;
        width: 67px;
        padding: 0px;
        margin-right: 11px;

        span.iconPark {
          height: 13px;
        }
      }
    }
  }
`

const TrackContext = React.createContext<any>({});

export const TrackContextFn = () => {
  const context = useContext(TrackContext);
  return context;
}

const Index = () => {
  const [contentPageFlag, setContentPageFlag] = useState<any>(ContentPageFlagType.LIST);
  const [project, setProject] = useState<any>(null);
  const [files, setFiles] = useState([]);
  const [typeOptions, setTypeOptions] = useState<any>([]);
  const [stateOptions, setStateOptions] = useState<any>([]);
  const [issue, setIssue] = useState<any>(null);

  useEffect(() => {
    getProject();
  }, [])

  const getProject = () => {
    issuesApi.getProjects((data: any) => {
      setProject(data);
      setOptions(data);
    })
  }

  const setOptions = (project: any) => {
    setTypeOptions(model.getOptionsByAlias(project, 'type'))
    setStateOptions(model.getOptionsByName(project, 'State'))
  }

  const getContent = () => {
    if (!project) {
      return <>  </>
    }

    if (contentPageFlag == ContentPageFlagType.CREATE) {
      return <TrackForm/>
    } else if (contentPageFlag == ContentPageFlagType.LIST) {
      return <TrackList/>
    } else if (contentPageFlag == ContentPageFlagType.DETAIL) {
      return <TrackDetail/>
    } else {
      return <></>
    }
  }

  const closeIssue = (id: any) => {
    issuesApi.closeIssue(id, project.fieldsMap, () => {
      setContentPageFlag(ContentPageFlagType.LIST);
    });
  }

  const getContext = () => {
    return {
      setContentPageFlag, project, files, setFiles,
      stateOptions, typeOptions, setIssue, issue,
      getProject,
      closeIssue,
    }
  }

  return (
    <TrackContext.Provider value={getContext()}>
      <LayoutWrapper className='x-header-layout'>
        <div className="c-edit4">
          <Space align="center" style={{height: "100%"}}>
            <Space align="center">
              <Link to={`${routePath}`}>
                <img src={byk_log} style={{width: "88px", height: "24px", marginLeft: "30px"}} alt=""/>
              </Link>
            </Space>
            <Space align="center">
              <div className='title'>用户反馈中心</div>
            </Space>
          </Space>
        </div>

        <LogInWrapper>
          <LogIn/>
        </LogInWrapper>

      </LayoutWrapper>

      <ContentBox>
        <div className='left'>
          <ul>
            <li>{getIconPark('FileEditingOne')} 我的工单</li>
          </ul>
        </div>

        <div className='content'>
          {getContent()}
        </div>

      </ContentBox>

    </TrackContext.Provider>
  )
}

export default Index;
