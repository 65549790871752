import React, { useRef, useState, useEffect } from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import XModal from "@byk/pages/components/XModal";
import type { ColumnsType } from "antd/es/table";
import { Space, Button } from "antd";
import { JSEncrypt } from "jsencrypt";
import Api from "@byk/api/Api";
import axios from "axios";

import { object } from "prop-types";
import encryptedData from "@byk/utils/encryptedData";

export default class extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    //this.focusTextInput = this.focusTextInput.bind(this);
  }
  state = {
    record: null,
    visible: false,
    rows: [],
    roleData: [],
    editData: null,
  };
  async componentDidMount() {
    let result:any = await Api.get("/byk/platform/rest/SysRole/list");
    if (result.success) {
      this.setState({ roleData: result.result });
    }
  }

  //const Crud = () => {
  render() {
    // api
    const apiGet: string = "/byk/platform/rest/SysUser/list";
    const apiPost: string = "/byk/platform/rest/SysUser";
    const apiPut: string = "/byk/platform/rest/SysUser";
    const apiDel: string = "/byk/platform/rest/SysUser";
    const apiRoleGet: string = "/byk/platform/rest/SysRole/list";
    //表格refresh
    const refreshTable = (params?: any) => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    };
    //改造提交的data
    const reformData = (params?: any, type?: any) => {
      if (type == 0) {
        params.password = encryptedData(params.password);
      }
      else{
        if(params.password==""){
          delete params.password;
        }
        else{
          params.password = encryptedData(params.password);
        }
      }
      return params;
    };
    //编辑按钮
    const EditClick = async (record: any) => {
      let result:any = await Api.get(apiPost, { id: record.id });
      if (result.success) {
        if (result.result.length > 0) {
          let _record = result.result[0];
          let _roles = _record.sysRoles.map((item?: any) => {
            return item.id;
          });
          record.roles = _roles;
          let { id, username, password, roles, name, email, mobile } = record;
          this.modalRef.current?.edit({
            id,
            username,
            password:"",
            roles,
            name,
            email,
            mobile,
          });
        }
      }
    };

    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result:any = await Api.delete(apiDel, [id]);
      if (result.success) {
        refreshTable();
      }
    };
    //查询项配置
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "username",
        label: "用户名",
        allowClear: true,
        col: 8,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "姓名",
        allowClear: true,
        col: 8,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "email",
        label: "邮箱",
        allowClear: true,
        col: 8,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "mobile",
        label: "手机号",
        allowClear: true,
        col: 8,
      },
    ];
    const searchProps = {
      option: searchConfig,
      onSearch: refreshTable,
      btnBoolean: true,
    };

    //新增表单项
    const ModalConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "username",
        label: "用户名",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "password",
        placeholder: "请输入",
        name: "password",
        label: "密码",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "select",
        placeholder: "请输入",
        name: "roles",
        label: "角色",
        allowClear: true,
        mode: "multiple", //multiple | tags
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
        data: this.state.roleData,
        fieldNames: { label: "name", value: "id" },
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "姓名",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "email",
        label: "Email",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "mobile",
        label: "手机号",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
    ];

    //表格配置
    const columns: ColumnsType = [
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "邮箱",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
      },
      {
        title: "操作",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <Button type="link" onClick={() => EditClick(record)}>
              编辑
            </Button>
            <Button type="link" onClick={() => deleteClick(record)}>
              删除
            </Button>
          </Space>
        ),
        width: 100,
      },
    ];
    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
    };

    //modal 表单配置
    const modalProps = {
      apiPost,
      apiPut,
      option: ModalConfig,
      refreshTable,
      reformData,
    };
    return (
      <>
        <div className="x-main-box">
          <SearchForm ref={this.searchRef} {...searchProps} />
          <XModal ref={this.modalRef} {...modalProps} />
          <TableList ref={this.tableRef} {...tableProps} />
        </div>
      </>
    );
  }
}
