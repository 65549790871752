import {Component} from "react";
import QueryViewNotebook from "@byk/pages/QueryBuilder/components/view/QueryViewNotebook";
import {
  QueryBuilderContentContainer,
  QueryBuilderViewHeaderContainer,
  QueryBuilderViewRoot
} from "@byk/pages/QueryBuilder/components/view/View.styled";
import NewQuestionHeader from "@byk/pages/QueryBuilder/components/view/NewQuestionHeader";

class View extends Component {
  renderHeader = () => {
    return (
      <QueryBuilderViewHeaderContainer>
        <NewQuestionHeader
          className="spread"
        />
      </QueryBuilderViewHeaderContainer>);
  };

  render() {
    return (
      <QueryBuilderViewRoot style={{
        height: "600px",
        width: "800px"

      }}>
        {this.renderHeader()}

        <QueryBuilderContentContainer>
          <QueryViewNotebook
            isNotebookContainerOpen={true}
            {...this.props}
          />)
        </QueryBuilderContentContainer>
      </QueryBuilderViewRoot>
    );
  }
}

export default View;
