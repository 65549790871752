export default function Config ():string{
  return `import {AtList, AtListItem} from "taro-ui";
import {Picker} from "@tarojs/components";
import React, {useState} from "react";
import {ReduxActionTypes} from "../../constants/ReduxActionConstants";
import {connect, useSelector} from "react-redux";
import XBase from "./XBase";

function XPicker(props: any) {
  const {propertyPath, setDataTree, style} = props;
  const widgetData = useSelector((state:any)=>{
    let dataTree = state.dataTreeReducer.dataTree;
    let widget = _.get(dataTree, propertyPath);
    return widget;
  })
  const [value, setValue] = useState<boolean>(widgetData.value);
  const onDateChange = (e:any)=>{
    setValue(e.detail.value);
    setDataTree(\`\${propertyPath}.value\`, e.detail.value);
  }
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <Picker customStyle={customStyle} mode='date' onChange={onDateChange} value={value}>
        <AtList>
          <AtListItem title={widgetData.label} extraText={value} />
        </AtList>
      </Picker>
    </XBase>)
}

const dispatchToProps = (dispatch: any) => {
  return {
    setDataTree: (key: string, value: any) => {
      dispatch({
        type: ReduxActionTypes.X_SET_DATATREE_ACTION,
        payload: {
          key,
          value,
        },
      })
    }
  }
}
export default connect(null, dispatchToProps)(XPicker);
  `;
}
