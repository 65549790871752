import React, { useEffect, useRef, useState } from 'react';
import AMapLoader from '@amap/amap-jsapi-loader';
import securityKey from '@byk/utils/securityKey';
import ChooseMap from "@byk/pages/components/MapWidget";
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
const MapComponent = () => {
  const mapRef = useRef(null);
  const chooseMapRef = useRef(null);
  const [map, setMap] = useState<any>(null);
  const [text, setText] = useState<any>(null);
  const [center, setCenter] = useState([114.40717, 30.478593]);
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    console.log(11111);
    window._AMapSecurityConfig = {
      securityJsCode: securityKey.securityJsCode
    };
    AMapLoader.load({
      key: securityKey.key,
      version: '2.0',
      plugins: ['AMap.Geocoder', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Driving', 'AMap.ToolBar'], // 需要预加载的插件
    }).then((AMap) => {
      const initialMap = new AMap.Map(mapRef.current, {
        center: center, // 地图中心点
        zoom: 14, // 初始缩放级别
      });

      initialMap.addControl(new AMap.ToolBar());//添加缩放工具栏

      //点击地图获取经纬度坐标及地址---------------------start
      var geocoder = new AMap.Geocoder({
        //city: "010", //城市设为北京，默认：“全国”
      });
      var marker = new AMap.Marker();
      initialMap.on('click', function (e: any) {
        let lnglat = [e.lnglat.lng, e.lnglat.lat];
        regeoCode(lnglat);
        setCenter(lnglat);
        searchNearByCenter(lnglat);//画圈搜索
        console.log("点击地图选取位置value", e.lnglat);
      });

      const regeoCode = (lnglat: any) => {
        initialMap.add(marker);
        marker.setPosition(lnglat);
        geocoder.getAddress(lnglat, function (status: any, result: any) {
          if (status === 'complete' && result.regeocode) {
            var address = result.regeocode.formattedAddress;
            //document.getElementById('address').value = address;
            let obj = { lnglat: lnglat, address: address };
            console.log(obj);
          } else {
            console.log('根据经纬度查询地址失败');
          }
        });
      }
      //点击地图获取经纬度坐标及地址---------------------end

      //添加自定义点标记 Marker-------------------------------------------start
      const markerContent = `<div class="custom-content-marker">
        <img src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png">
        <div class="close-btn" onclick="clearMarker()">X</div>
        </div>`
      const positionMarker = new AMap.LngLat(114.409046, 30.478901); //Marker 经纬度
      const marker1 = new AMap.Marker({
        position: positionMarker,
        content: markerContent, //将 html 传给 content
        offset: new AMap.Pixel(-13, -30), //以 icon 的 [center bottom] 为原点
      });
      //document.querySelector(".close-btn").onclick = clearMarker;//绑定点击事件
      initialMap.add(marker1);
      const clearMarker = () => {
        initialMap.remove(marker1); //清除 marker
      }
      //添加自定义点标记 Marker---------------------------------------------end


  

      //周围搜索---------------------------------------------start
      const placeSearch = new AMap.PlaceSearch({
        map: initialMap,
        pageSize: 10, // 单页显示结果条数
        pageIndex: 1, // 页码
        panel: "panel"
      });

      const searchNearByCenter = (center: any) => {
        placeSearch.searchNearBy('', center, 200, function (status: any, result: any) {
          if (status === 'complete' && result.info === 'OK') {
            setLocations(result.poiList.pois); // 设置搜索到的地址列表  
          }
        });
      }
      //周围搜索---------------------------------------------end

      setMap(initialMap);

      //输入框关键字搜索---------------------------------------------start
      // 实例化自动完成插件
      const autoComplete = new AMap.Autocomplete({
        input: "z-amap-autocomplete-input",  // 绑定输入框的DOM ID
      });
      const placeSearch2 = new AMap.PlaceSearch({
        map: initialMap
      });

      // 监听选中地址事件
      autoComplete.on('select', function (e: any) {
        console.log(e);
        setInput(e.poi.name);
        if (e.poi && e.poi.location) {
          initialMap.setZoom(18); // 调整地图缩放级别
          initialMap.setCenter(e.poi.location); // 地图中心移动到选中地址
          initialMap.add(marker);
          marker.setPosition(e.poi.location);
          //placeSearch2.search(e.poi.name); // 同时执行地点搜索
        }
      });
      //输入框关键字搜索---------------------------------------------end

    }).catch(e => {
      console.log(e);
    });

    return () => {
      if (map) {
        map.destroy();
      }
    };
  }, []);

 

  const showModel = () => {
    chooseMapRef.current?.showModal();
  }
  const setValue = (v: any) => {
    setText(v)
  }

  return (
    <>
      {/* <Button type="dashed" onClick={showModel}>设置定位</Button> */}
      <span>{text?.address}</span>
      {/* <ChooseMap ref={chooseMapRef} callback={setValue} data={null}></ChooseMap> */}
      <Input size="large" id="z-amap-autocomplete-input" value={input} allowClear
        className='z-amap-autocomplete-input'
        onChange={e => setInput(e.target.value)}
        placeholder="请输入地址" prefix={<SearchOutlined />} />
      {/* <input
        id="z-amap-autocomplete-input"
        value={input}
        onChange={e => setInput(e.target.value)}
        placeholder="请输入地址"
        style={{ width: '300px', marginTop: '10px' }}
      /> */}

      <div id="panel" className='map_panel'>

      </div>
      {/* {suggestions.length > 0 && (
        <ul>
          {suggestions.map((item: any, index: any) => (
            <li key={index} onClick={() => setInput(item.name)}>
              {item.name}
            </li>
          ))}
        </ul>
      )} */}
      <div ref={mapRef} style={{ width: '100vw', height: '100vh' }}></div>
    </>
  );
};

export default MapComponent;
