import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Button, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {ReduxActionTypes} from "../../ce/constants/ReduxActionConstants";
import {getPositionsSelectedWidgets} from "../../sagas/selectors";
import _ from "lodash";
import {getCanvasWidgets} from "../../selectors/entitiesSelector";
import {getSelectedWidgets} from "../../selectors/ui";
import {getAlignIcon} from "../../icons/AlignIcons";
import styled from "styled-components";

const DivWrapper = styled.div`
  button {
    border:none  !important ;
  }

  button:focus, button:hover {
   border:none  !important ;
  }
`;

const iconProp = {
  color: '#686565', width: 16, height: 16, onClick: () => {
  }
};

enum AlignTypes {
  TOP = 'topRow',
  BOTTOM = 'bottomRow',
  LEFT = 'leftColumn',
  RIGHT = 'rightColumn',
  VC = 'verticalCenter',
  HC = 'horizontallyCenter',
  HEQ = 'horizontallyEq',
  VEQ = 'verticalCentEq',
}

const AlignTypesArr = [AlignTypes.TOP, AlignTypes.BOTTOM, AlignTypes.LEFT, AlignTypes.RIGHT, AlignTypes.VC, AlignTypes.HC, AlignTypes.HEQ, AlignTypes.VEQ]

/**
 * 检查选择的组件是否可以移动
 */
const checkCollision = (p1: any, p2: any, p1_1: any, p2_2: any) => {
  if (
    (p1 <= p1_1 && p2 > p1_1)
    || (p1 < p2_2 && p2 >= p2_2)
    || (p1 >= p1_1 && p2 <= p2_2)
    || (p1 <= p1_1 && p2 >= p2_2)
  ) {
    return true;
  }
  return false;
}
const getSortName = (type: AlignTypes) => {
  switch (type) {
    case AlignTypes.VC:
    case AlignTypes.TOP:
    case AlignTypes.VEQ:
      return AlignTypes.TOP;

    case AlignTypes.BOTTOM:
      return AlignTypes.BOTTOM;

    case AlignTypes.LEFT:
    case AlignTypes.HC:
    case AlignTypes.HEQ:
      return AlignTypes.LEFT;

    case AlignTypes.RIGHT:
      return AlignTypes.RIGHT;
  }
}

/**
 * 检查组件是否有重叠
 */
const checkNotCollision = (draggedBlocksToUpdate: any = [], rowCheck: any) => {
  for (let i = 0; i < draggedBlocksToUpdate.length; i++) {
    let widget1 = draggedBlocksToUpdate[i].updateWidgetParams.payload;
    for (let j = 0; j < draggedBlocksToUpdate.length; j++) {
      let widget2 = draggedBlocksToUpdate[j].updateWidgetParams.payload;
      if (i != j) {
        if (rowCheck) { // 1. 检查横向
          let columnOk = checkCollision(widget1.leftColumn, widget1.rightColumn, widget2.leftColumn, widget2.rightColumn);
          if (columnOk) {
            return false;
          }
        } else { // 2. 检查纵向
          let rowOk = checkCollision(widget1.topRow, widget1.bottomRow, widget2.topRow, widget2.bottomRow);
          if (rowOk) {
            return false;
          }
        }
      }
    }
  }

  return true;
}

const widgetCollisionWithDraggedBlocksToUpdate = (draggedBlocksToUpdate: any, widgets: any, selectedWidgets: any) => {
  if (draggedBlocksToUpdate.length > 0) {
    let keys = _.keys(widgets);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let widget1 = widgets[key];
      if (key == '0' || selectedWidgets.indexOf(key) > -1) {
        continue;
      }

      for (let j = 0; j < draggedBlocksToUpdate.length; j++) {
        let widget2 = draggedBlocksToUpdate[j].updateWidgetParams.payload;
        if (widget2.parentId == widget1.parentId) {
          if (checkCollision(widget1.leftColumn, widget1.rightColumn, widget2.leftColumn, widget2.rightColumn)
            && checkCollision(widget1.topRow, widget1.bottomRow, widget2.topRow, widget2.bottomRow)
          ) {
            return false;
          }
        }
      }
    }

    return true;
  }

  return false;
}

const pushDraggedBlocksToUpdate = (draggedBlocksToUpdate: any, widget: any) => {
  draggedBlocksToUpdate.push({
    "widgetId": widget.widgetId,
    "updateWidgetParams": {
      "operation": "MOVE",
      "widgetId": widget.widgetId,
      "payload": {
        "leftColumn": widget.leftColumn,
        "topRow": widget.topRow,
        "bottomRow": widget.bottomRow,
        "rightColumn": widget.rightColumn,
        "parentId": widget.parentId,
        "newParentId": widget.parentId,
      }
    }
  });
}

const AlignTools: React.FC<any> = forwardRef((
  props, ref
) => {
  useImperativeHandle(ref, () => ({}));

  const dispatch = useDispatch();
  const selectedWidgets = useSelector(getSelectedWidgets);
  const positions: any[] = useSelector(getPositionsSelectedWidgets);
  const widgets = useSelector(getCanvasWidgets);
  const [canMove, setCanMove] = useState<any>({});

  useEffect(() => {
    if (selectedWidgets && selectedWidgets.length > 1) {
      let _canMoveFlag: any = {};
      AlignTypesArr.forEach(type => {
        let draggedBlocksToUpdate = buildDispatchDraggedBlocks(type);
        let rowCheck = false;
        if ([AlignTypes.HEQ, AlignTypes.HC, AlignTypes.TOP, AlignTypes.BOTTOM].indexOf(type) > -1) {
          rowCheck = true;
        }
        if (draggedBlocksToUpdate
          && checkNotCollision(draggedBlocksToUpdate, rowCheck)
          && widgetCollisionWithDraggedBlocksToUpdate(draggedBlocksToUpdate, widgets, selectedWidgets)) {
          _canMoveFlag[type] = true;
        } else {
          _canMoveFlag[type] = false;
        }
      });
      setCanMove({..._canMoveFlag})
    } else {
      setCanMove({})
    }
  }, [selectedWidgets])

  const dispatchDraggedBlocksToUpdate = (draggedBlocksToUpdate: any) => {
    if (draggedBlocksToUpdate && draggedBlocksToUpdate.length > 1) {
      dispatch({
        type: ReduxActionTypes.WIDGETS_MOVE,
        payload: {
          draggedBlocksToUpdate,
          canvasId: draggedBlocksToUpdate[0].updateWidgetParams.payload.parentId,
        },
      });
    }
  }

  const buildDispatchDraggedBlocks = (type: AlignTypes) => {
    if (positions && positions.length > 1) {
      let _positions = _.cloneDeep(positions);
      let draggedBlocksToUpdate: any = [];
      let _positionsByAlignType = _.sortBy(_positions, [getSortName(type)]);
      // 框选组件的最左，最右，最上，最下值, 垂直居中，横向居中
      let leftColumn = -1, rightColumn = -1, topRow = -1, bottomRow = -1, vCenter = -1, hCenter = -1;
      let sumColumn = 0, sumRow = 0, heq = -1, veq = -1;

      _positions.forEach(item => {
        leftColumn = leftColumn < 0 ? item.leftColumn : leftColumn < item.leftColumn ? leftColumn : item.leftColumn;
        rightColumn = rightColumn < 0 ? item.rightColumn : rightColumn > item.rightColumn ? rightColumn : item.rightColumn;
        topRow = topRow < 0 ? item.topRow : topRow < item.topRow ? topRow : item.topRow;
        bottomRow = bottomRow < 0 ? item.bottomRow : bottomRow > item.bottomRow ? bottomRow : item.bottomRow;
        sumColumn += item.rightColumn - item.leftColumn;
        sumRow += item.bottomRow - item.topRow;
      })

      vCenter = leftColumn + (rightColumn - leftColumn) / 2;
      hCenter = topRow + (bottomRow - topRow) / 2;
      heq = (rightColumn - leftColumn - sumColumn) / (positions.length - 1);
      veq = (bottomRow - topRow - sumRow) / (positions.length - 1);

      let heqTemp = leftColumn;
      let veqTemp = topRow;
      for (let i = 0; i < _positionsByAlignType.length; i++) {
        let widget = _positionsByAlignType[i];
        switch (type) {
          case AlignTypes.LEFT:
            pushDraggedBlocksToUpdate(draggedBlocksToUpdate,
              {
                ...widget,
                leftColumn: leftColumn,
                rightColumn: leftColumn + widget.rightColumn - widget.leftColumn,
              });
            break;
          case AlignTypes.RIGHT:
            pushDraggedBlocksToUpdate(draggedBlocksToUpdate,
              {
                ...widget,
                leftColumn: rightColumn - (widget.rightColumn - widget.leftColumn),
                rightColumn: rightColumn,
              });
            break;
          case AlignTypes.BOTTOM:
            pushDraggedBlocksToUpdate(draggedBlocksToUpdate,
              {
                ...widget,
                topRow: bottomRow - (widget.bottomRow - widget.topRow),
                bottomRow: bottomRow,
              });
            break;
          case AlignTypes.TOP:
            pushDraggedBlocksToUpdate(draggedBlocksToUpdate,
              {
                ...widget,
                topRow: topRow,
                bottomRow: topRow + widget.bottomRow - widget.topRow,
              });
            break;
          case AlignTypes.VC:
            let column: any = widget.rightColumn - widget.leftColumn;
            let offsetLeft = parseInt(String(column / 2));
            let offsetRight = offsetLeft;
            if (column % 2 > 0) {
              offsetRight += 1;
            }
            pushDraggedBlocksToUpdate(draggedBlocksToUpdate,
              {
                ...widget,
                leftColumn: vCenter - offsetLeft,
                rightColumn: vCenter + offsetRight,
              });
            break;
          case AlignTypes.HC:
            let row: any = widget.bottomRow - widget.topRow;
            let offsetTop = parseInt(String(row / 2));
            let offsetBottom = offsetTop;
            if (row % 2 > 0) {
              offsetBottom += 1;
            }
            pushDraggedBlocksToUpdate(draggedBlocksToUpdate,
              {
                ...widget,
                topRow: hCenter - offsetTop,
                bottomRow: hCenter + offsetTop,
              });
            break;
          case AlignTypes.HEQ:
            pushDraggedBlocksToUpdate(draggedBlocksToUpdate,
              {
                ...widget,
                leftColumn: heqTemp,
                rightColumn: heqTemp + (widget.rightColumn - widget.leftColumn),
              });
            heqTemp += (widget.rightColumn - widget.leftColumn + heq);
            break;
          case AlignTypes.VEQ:
            pushDraggedBlocksToUpdate(draggedBlocksToUpdate,
              {
                ...widget,
                topRow: veqTemp,
                bottomRow: veqTemp + (widget.bottomRow - widget.topRow),
              });
            veqTemp += (widget.bottomRow - widget.topRow + veq);
            break;
        }
      }

      return draggedBlocksToUpdate;
    }

    return null;
  }

  const doMove = (type: AlignTypes, rowCheck: any) => {
    let draggedBlocksToUpdate = buildDispatchDraggedBlocks(type);
    if (draggedBlocksToUpdate
      && checkNotCollision(draggedBlocksToUpdate, rowCheck)
      && widgetCollisionWithDraggedBlocksToUpdate(draggedBlocksToUpdate, widgets, selectedWidgets)) {
      dispatchDraggedBlocksToUpdate(draggedBlocksToUpdate)
    }
  }

  return (
    <DivWrapper>
      <Button type={"text"} icon={getAlignIcon("Heq", {...iconProp, disabled: !canMove['horizontallyEq']})}
              onClick={() => canMove['horizontallyEq'] && doMove(AlignTypes.HEQ, true)}/>

      <Button type={"text"} icon={getAlignIcon("Veq", {...iconProp, disabled: !canMove['verticalCentEq']})}
              onClick={() => canMove['verticalCentEq'] && doMove(AlignTypes.VEQ, false)}/>

      <Button type={"text"} icon={getAlignIcon("Left", {...iconProp, disabled: !canMove['leftColumn']})}
              onClick={() => canMove['leftColumn'] && doMove(AlignTypes.LEFT, false)}/>

      <Button type={"text"} icon={getAlignIcon("Hc", {...iconProp, disabled: !canMove['horizontallyCenter']})}
              onClick={() => canMove['horizontallyCenter'] && doMove(AlignTypes.HC, true)}/>

      <Button type={"text"} icon={getAlignIcon("Right", {...iconProp, disabled: !canMove['rightColumn']})}
              onClick={() => canMove['rightColumn'] && doMove(AlignTypes.RIGHT, false)}/>

      <Button type={"text"} icon={getAlignIcon("Top", {...iconProp, disabled: !canMove['topRow']})}
              onClick={() => canMove['topRow'] && doMove(AlignTypes.TOP, true)}/>

      <Button type={"text"} icon={getAlignIcon("Vc", {...iconProp, disabled: !canMove['verticalCenter']})}
              onClick={() => canMove['verticalCenter'] && doMove(AlignTypes.VC, false)}/>

      <Button type={"text"} icon={getAlignIcon("Bottom", {...iconProp, disabled: !canMove['bottomRow']})}
              onClick={() => canMove['bottomRow'] && doMove(AlignTypes.BOTTOM, true)}/>
    </DivWrapper>
  )

})

export default AlignTools;

