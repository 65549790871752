export default function Config ():string{
  return `
  import type { UserConfigExport } from "@tarojs/cli";
export default {
  mini: {},
  h5: {
    
  }
} satisfies UserConfigExport



  `;
}
