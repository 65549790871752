import React, {useRef, useState} from 'react';
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components//TableList";
import XModal from "@byk/pages/components/XModal";
import type {ColumnsType} from 'antd/es/table';
import {Button, Layout, Space} from 'antd';

import "./css/Modeling.css"
import ModelingApi from "./lib/ModelingApi";
import {graphModel, MX} from "@byk/pages/modeling/MxGraph/ModelingMx";
import {EyeOutlined, UnorderedListOutlined} from "@ant-design/icons";
import CreateModel from "@byk/pages/modeling/MxGraph/CreateModel";
import {DelConfirm} from "@byk/pages/modeling/MxGraph/DelConfirm";

const {Content} = Layout;

const Crud = () => {
  const searchRef = useRef<any>();
  const modalRef = useRef<any>();
  const tableRef = useRef<any>();
  const delConfirmRef = useRef<any>();
  const editModelRef = useRef<any>();
  const [isList, setIsList] = useState(false);
  const tenant = ModelingApi.getTenant();

  //表格refresh
  const refreshTable = (params?: any) => {
    tableRef.current?.getTableList({...params, tenant});
  };

  const reformData = (params?: any, type?: any) => {
    return {...params, tenant};
  };
  //删除
  const deleteClick = async (id: any) => {
    let result: any = await ModelingApi.doDeleteModel(id);
    if (result.success) {
      refreshTable();
    }
  };
  //查询项配置
  const searchConfig: any[] = [
    {
      itemType: 'input',
      placeholder: '请输入',
      name: 'name',
      label: '模型名称',
      allowClear: true,
      col: 8
    },
    {
      itemType: 'input',
      placeholder: '请输入',
      name: 'description',
      label: '模型描述',
      allowClear: true,
      col: 8
    },
    {
      itemType: 'input',
      name: 'tenant',
      allowClear: true,
      col: 1,
      hidden: true,
      initialValue: tenant
    },
  ];
  const searchProps = {
    option: searchConfig,
    onSearch: refreshTable,
    btnBoolean: true
  };

  //新增表单项
  const ModalConfig: any[] = [
    {
      itemType: 'select2',
      placeholder: '',
      name: 'tag',
      label: '模型标签',
      allowClear: true,
      col: 24,
      data: [],
      rules: [{required: true, message: '模型标签必填！'}]
    },
    {
      itemType: 'input',
      placeholder: '',
      name: 'name',
      label: '模型名称',
      allowClear: true,
      col: 24,
      rules: [{required: true, message: '模型名称必填！'}]
    },
    {
      itemType: 'input',
      placeholder: '',
      name: 'description',
      label: '模型描述',
      allowClear: true,
      col: 24,
      rules: [
        {required: true, message: '模型描述必填！'},
      ]
    },
  ];

  //表格配置
  const columns: ColumnsType = [
    {
      title: '模型名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '模型描述',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle">
          <Button type="link" onClick={() => delConfirmRef.current?.showModal(record.id)}>
            删除
          </Button>
        </Space>
      ),
      width: 100
    }
  ];

  const tableProps = {
    columns: columns,
    api: ModelingApi.apiList,
    pagination: {
      currPage: 1,
      pageSize: 10
    },
    initParams: {
      tenant: ModelingApi.getTenant(),
    }
  };

  //modal 表单配置
  const modalProps = {
    apiPost: ModelingApi.apiPost,
    apiPut: ModelingApi.apiPut,
    reformData,
    option: ModalConfig,
    refreshTable
  };

  /**
   * 图，列表显示切换
   */
  const switchView = () => {
    setIsList(!isList);
  }

  return (
    <>
      <Layout>
        <Content style={{margin: 0, height: "100%",}}>
          {isList && <>
            {/*<div>*/}
            {/*  <Button icon={<EyeOutlined rev={undefined}/>} onClick={switchView}*/}
            {/*          style={{zIndex: 2, float: "right", margin: "7px 28px 0 0"}}/>*/}
            {/*</div>*/}

            <div style={{marginTop: "8px"}}>
              <SearchForm ref={searchRef} {...searchProps} />
              <XModal ref={modalRef} {...modalProps} isShowAdd={false}/>
              <TableList ref={tableRef} {...tableProps} />
            </div>

            <CreateModel ref={editModelRef} graphModel={graphModel}/>
          </>
          }

          {!isList && <>
            {/*<div>*/}
            {/*  <Button icon={<UnorderedListOutlined rev={undefined}/>} onClick={switchView}*/}
            {/*          style={{zIndex: 2, float: "right", margin: "7px 28px 0 0"}}/>*/}
            {/*</div>*/}
            <MX/>
          </>
          }
        </Content>

      </Layout>

      <DelConfirm delCb={deleteClick} ref={delConfirmRef}/>
    </>
  )
}

export default Crud;
