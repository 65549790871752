import * as Sentry from "@sentry/react";

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ConversionButton from "../CanvasLayoutConversion/ConversionButton";
import {MainContainerLayoutControl} from "../MainContainerLayoutControl";
import {
  getCurrentPageBgColor,
  getCurrentPageId,
  getCurrentPageIframePage,
  getIsAutoLayout,
  isAutoLayoutEnabled,
} from "selectors/editorSelectors";
import styled from "styled-components";
import SketchPicker from "../MenuFrame/SketchPicker";
import {updatePage} from "../../../actions/pageActions";
import XPropertyPanne from "../PropertyPane/XPropertyPane";
import {Radio, Tabs} from "antd";
import {Tab, TabPanel, TabsList} from "design-system";
import {StyledTabs} from "design-system/build/Tab/Tab.styles";
import ThemeSettings from "../MenuFrame/ThemeSettings";

const Title = styled.p`
  color: var(--ads-v2-color-fg);
`;
const MainHeading = styled.h3`
  color: var(--ads-v2-color-fg-emphasis);
`;
const EventConfig = [
  {
    children: [
      {
        controlType: "ACTION_SELECTOR",
        helpText: "when the page is onload",
        isBindProperty: true,
        isJSConvertible: true,
        isTriggerProperty: true,
        label: "onLoad",
        propertyName: "onLoad",
      }
    ],
    sectionName: "Events"
  }
];

const tabs = ["pageSettings", "pageEvent", "themeMgr"];

export function CanvasPropertyPane() {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0)

  const isAutoLayoutFeatureEnabled = useSelector(isAutoLayoutEnabled);
  const pageBgColor = useSelector(getCurrentPageBgColor);
  const isIframePage:boolean = useSelector(getCurrentPageIframePage);
  const [bgColor, setBgColor] = useState(pageBgColor);
  const [iframeChangeValue, setIframeChangeValue] = useState<boolean>(true);
  const currentPageId = useSelector(getCurrentPageId);
  const isAutoLayout = useSelector(getIsAutoLayout);
  const colorChange = (color: string) => {
    setBgColor(color);
    //console.log('&&&&', color, pageBgColor, currentPageId);
    dispatch(updatePage({
      id: currentPageId,
      backgroundColor: color
    }));
  }
  const iframeChange = (value: any) => {
    setIframeChangeValue(value);
    dispatch(updatePage({
      id: currentPageId,
      frameDesignPageFlag: value,
      iframePage: value,
    }));
  }
  useEffect(() => {
    pageBgColor && setBgColor(pageBgColor);
  }, [pageBgColor]);
  useEffect(() => {
    setIframeChangeValue(isIframePage);
  }, [isIframePage]);
  return (
    <StyledTabs
      onValueChange={(value) => {
        setSelectedIndex(tabs.indexOf(value) || 0);
      }}
      value={tabs[selectedIndex]}
    >
      <TabsList>
        <Tab value={tabs[0]}>页面设置</Tab>
        <Tab value={tabs[1]}>页面事件</Tab>
        <Tab value={tabs[2]}>主题设置</Tab>
      </TabsList>
      <TabPanel value={tabs[0]}>
        <div className="relative ">
          <div className="mt-3 space-y-6">
            <div className="px-4 space-y-2">
              {/*{!isAutoLayout && (
                <>
                  <Title className="text-sm">画布大小</Title>
                  <MainContainerLayoutControl/>
                </>
              )}
              {isAutoLayoutFeatureEnabled && <ConversionButton/>}*/}

              <div style={{display: 'flex'}}>
                <div style={{position: 'relative', top: 3}}>背景色：</div>
                <SketchPicker value={bgColor} onChange={(color: string) => {
                  colorChange(color);
                }}/>
              </div>
              <div style={{display: 'flex'}}>
                <div style={{position: 'relative', top: 3}}>是否显示框架：</div>
                <Radio.Group onChange={(ev) => {
                  iframeChange(ev.target.value);
                }} value={iframeChangeValue}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={tabs[1]}>
        <XPropertyPanne config={EventConfig} id={`0`}/>
      </TabPanel>
      <TabPanel value={tabs[2]}>
        <ThemeSettings/>
      </TabPanel>
    </StyledTabs>
  );
}

CanvasPropertyPane.displayName = "CanvasPropertyPane";

export default Sentry.withProfiler(CanvasPropertyPane);
