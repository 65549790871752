import withWidgetProps from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XSwitch from "../../components/widgets/XSwitch";';
  begin = begin + `
        <XSwitch propertyPath={${i.propertyPath}} `;
  begin += getWidgetStyles(i);
  begin += ' />';
  return {begin, head};
};
