import React, {useEffect, useState} from 'react';
import {Cascader, Form, Input, Select, Tooltip} from "antd";
import XPDict from "./XPropertyPaneDict";
import TextArea from "antd/es/input/TextArea";
import {useSelector} from "react-redux";
import {getCurrentPageId} from "../../../selectors/editorSelectors";
import {getModalDropdownList} from "../../../selectors/widgetSelectors";
import {AppState} from "../../../ce/reducers";
import _ from "lodash";
import {MoreOutlined} from "@ant-design/icons/lib";
import {AddEventUl, CardWrapper} from "./XPropertyPane";
import styled from "styled-components";
import WidgetsEvents from "../../../widgets/WidgetsEvents";
import XTreeSelectPage from "../../../components/utils/XTreeSelectPage";

const CallbackWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding:4px 10px;
  border-radius: 0px 0px 5px 5px;
  background-color: rgba(235, 243, 255, 1);
`;

const Container = styled.div`
  .ant-form-item {
    margin-bottom: 16px;
  }
`;

function PaneAction(props: any) {
  const {data, updateEventData, delEventData, AddBtn, recursionEvent} = props;
  const {uid, actionConfig} = data;
  const [form] = Form.useForm();
  const onValuesChange = (changeValues: any, allValues: any) => {
    //console.log('changeValues++', changeValues);
    //console.log('allValues++', allValues);
    let _o:any={};
    if(changeValues.action&&['navigateUrl','navigateTo'].indexOf(changeValues.action)>-1){
      _o.navigateBlank=changeValues.action== 'navigateTo'?"SAME_WINDOW":"NEW_WINDOW";
    }
    let _data:any={...allValues,..._o};
    form.setFieldsValue(_data);
    updateEventData(uid, _data, 'actionConfig', !!changeValues.action);
  };
  const pageId = useSelector(getCurrentPageId);
  const jsActions = useSelector((state: AppState) => {
    let _js = state.entities.jsActions||[];
    let jsOpt:any = [];
    _.each(_js, (i:any)=>{
      if(i.config.pageId == pageId){
        jsOpt.push({
          value: i.config.name,
          label: i.config.name,
          children: i.config.actions.map((j:any)=>{
            return {
              value: j.name,
              label: j.name
            }
          })
        })
      }
    })
    return jsOpt;
  });
  const lianDongMuBiao = useSelector(state=>{
    let ldmb:any = [];
    let _widgets = state.entities.canvasWidgets;
    _.each(_widgets, (value:any, key:any)=>{
      if (XPDict.liandongWidgets.includes(value.type)) {
        if (key !== 0) {
          let events = [];
          let eventKey: any = XPDict.liandongWidgetsEventKey[value.type];
          if (eventKey && WidgetsEvents[eventKey] && WidgetsEvents[eventKey][0]) {
            events = WidgetsEvents[eventKey][0].children;
          }

          ldmb.push({
            id: value.widgetId,
            value: value.widgetId,//value.key,
            key: value.widgetId,//value.key,
            label: value.widgetName,
            lianDongEvents: events.map((i: any) => {
              return {
                label: i.label,
                value: i.propertyName,
              }
            })
          })
        }
      }
    })
    return ldmb;
  });
  const [linkageEventOptions, setLinkageEventOptions] = useState<any>([]);
  //弹层列表
  const modalDropdownList = useSelector(getModalDropdownList);
  useEffect(()=>{
    let _config = actionConfig?{...actionConfig}:{};
    if(_config.apiMethods){
      _config.action = _config.apiMethods;
    }
    ///初始表单值回显
    form&&form.setFieldsValue&&form.setFieldsValue({
      ..._config
    });
    //如果是组件联动。设置联动事件的下拉
    if(actionConfig&&actionConfig.linkageId&&actionConfig.action == 'linkage'){
      let _linkageEventOptions = _.find(lianDongMuBiao, { value: actionConfig.linkageId });
      if(_linkageEventOptions){
        setLinkageEventOptions(_linkageEventOptions.lianDongEvents);
      }

    }

  }, [])
  return (
    <Container>
      <CardWrapper className="action-box" width="320" titleBgColor={`rgba(63, 122, 255, 1)`}>
        <div className={`card-title`}>
          <div style={{flex: 1}}>设置动作</div>
          <Tooltip trigger={`click`} title={
            <AddEventUl>
              <li onClick={() => {
                delEventData(uid, 2);
              }
              }>删除动作
              </li>
            </AddEventUl>
          }>
            <MoreOutlined/>
          </Tooltip>
        </div>
        <div className={`card-box`}>
          <Form form={form} onValuesChange={onValuesChange} labelCol={{span: 8}} wrapperCol={{span: 16}}>
            <Form.Item name="action" label="动作" required>
              <Select options={XPDict.actions}/>
            </Form.Item>
            <Form.Item name="actionId" label="隐藏的actionId" hidden>
              <Input/>
            </Form.Item>
            {/*页面交互--打开页面*/}
            <Form.Item noStyle dependencies={['action']}>
              {({getFieldValue}) =>
                getFieldValue('action') === 'navigateTo' || getFieldValue('action') === 'navigateUrl' ? (
                  <>
                    {getFieldValue('action') == 'navigateTo' &&
                    <Form.Item name="navigatePage" label="目标页面">
                       <XTreeSelectPage />
                    </Form.Item>
                    }

                    {getFieldValue('action') == 'navigateUrl' &&
                    <Form.Item name="navigateURL" label="目标超链接">
                      <Input/>
                    </Form.Item>
                    }

                    <Form.Item name="navigateBlank" label="打开方式">
                      <Select placeholder="=请选择=" defaultValue={getFieldValue('action') == 'navigateTo'?"SAME_WINDOW":"NEW_WINDOW"} style={{width: "100%"}}  options={XPDict.navigateBlank}/>
                    </Form.Item>

                    <Form.Item name="navigateParams" label="URL传参" tooltip={'用于两个页面跳转间的数据传递'}>
                      <TextArea rows={4} placeholder='请输入传递参数，格式参考：{"id":currentItem.id}'/>
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
            {/*弹框消息--打开弹框*/}
            <Form.Item noStyle dependencies={['action']}>
              {({ getFieldValue }) =>
                (getFieldValue('action') === 'showModal'||getFieldValue('action') === 'closeModal') ? (
                  <>
                    <Form.Item name="modalName" label="弹层名称">
                      <Select placeholder="=请选择=" style={{ width: "100%" }}
                              options={modalDropdownList} />
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
            {/*弹框消息--消息提醒*/}
            <Form.Item noStyle dependencies={['action']}>
              {({ getFieldValue }) =>
                getFieldValue('action') === 'showAlert' ? (
                  <>
                    <Form.Item name="message" label="内容">
                      <Input />
                    </Form.Item>
                    <Form.Item name="type" label="类别">
                      <Select style={{ width: "100%" }} placeholder="=请选择="
                              options={XPDict.showAlertType}
                      />
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
            {/*数据交互*/}
            <Form.Item noStyle dependencies={['action']}>
              {({ getFieldValue }) =>
                XPDict.actionMethods.includes(getFieldValue('action')) ? (
                  <>
                    <Form.Item hidden={true} name="apiTarget" label="数据目标" >
                      <Input />
                    </Form.Item>
                    <Form.Item name="apiDescription" label="说明" rules={[{ required: true, message: '请输入' }]} >
                      <TextArea rows={4} placeholder="请输入说明" />
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
            {/*控制组件--组件已有动作*/}
            <Form.Item noStyle dependencies={['action']}>
              {({ getFieldValue }) =>
                getFieldValue('action') === 'linkage' ? (
                  <>
                    <Form.Item name="linkageId" label="目标组件" rules={[{ required: true, message: '请输入' }]}>
                      <Select placeholder="=请选择=" style={{ width: "100%" }}
                              options={lianDongMuBiao}
                              onChange={(value, option:any) => {
                                  setLinkageEventOptions(option.lianDongEvents || []);
                                  let onLoad = _.find(option.lianDongEvents, {value:'onLoad'}) || null;
                                  if(onLoad){
                                    form.setFieldValue("linkageEvent", 'onLoad');
                                    onValuesChange({linkageEvent: "onLoad"}, form.getFieldsValue());
                                  }else{
                                    form.setFieldValue("linkageEvent", '');
                                    onValuesChange({linkageEvent: ""}, form.getFieldsValue());
                                  }

                              }}
                      />
                    </Form.Item>
                    <Form.Item name="linkageEvent"
                               label="目标组件事件" rules={[{ required: true, message: '请输入' }]}>
                      <Select placeholder="=请选择=" style={{ width: "100%" }}
                              options={linkageEventOptions}
                      />
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
            {/*其他--执行就是函数*/}
            <Form.Item noStyle dependencies={['action']}>
              {({ getFieldValue }) =>
                getFieldValue('action') === 'js' ? (
                  <>
                    <Form.Item name="jsPath"
                               label="JS函数" >
                      <Cascader options={jsActions} placeholder="Please select" />
                    </Form.Item>
                  </>
                ) : null
              }
            </Form.Item>
            {/*其他--特殊处理*/}
            <Form.Item noStyle dependencies={['action', 'jsPath']}>
              {({ getFieldValue }) =>
                getFieldValue('action') === 'special' ? (
                  <>
                    <Form.Item name="specialDes" label="说明" >
                      <TextArea rows={4} />
                    </Form.Item>
                    {getFieldValue('jsPath')&&(
                      <span style={{display:'inline-block', padding:'5px 10px', backgroundColor:'#000', color:'#fff'}}>{getFieldValue('jsPath').join('.')}</span>
                    )}
                  </>
                ) : null
              }
            </Form.Item>
          </Form>
        </div>
        {/*数据交互_回调*/}
        {
          actionConfig
          && XPDict.actionMethods.includes(actionConfig.action)
          && (
            <CallbackWrapper>
              <div>回调设置</div>
              {/*<div>收起</div>*/}
            </CallbackWrapper>
          )
        }
      </CardWrapper>
    </Container>


  )
}
export default PaneAction;
