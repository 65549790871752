export default function Config ():string{
  return `
import React from 'react';
import { useLaunch } from '@tarojs/taro';
import { Provider } from "react-redux";
import store from './store';
import 'taro-ui/dist/style/index.scss';
import './app.scss';

function App(props:any) {
  useLaunch(() => {
    console.log('App launched.')
  })
  return (
    <Provider store={store}>
      {props.children}
    </Provider>
  )
}
export default App
  `;
}
