export default function Config ():string{
  return `
  import {all, put, takeLatest} from "redux-saga/effects";
import {ReduxActionTypes} from "../constants/ReduxActionConstants";
import {bykAction} from "../actions";


export function* setByk(action:any): any {
  yield put(bykAction({
    ...action.payload
  }))
}

export function* watchXActionByk() {
  yield all([
    takeLatest(
      ReduxActionTypes.X_ACTION_BYK,
      setByk
    ),
  ]);
}


  `;
}
