import React, { Component, useRef } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout, Tabs, List, Menu, Input, Tree, Button, Dropdown, Row, Col, PageHeader, Space, Modal, Form, message, Popconfirm } from 'antd';
import BusinessPage from "@byk/pages/AppSystemInit/DictManager/main";

import { withRouter } from "react-router-dom";
import XModal from "@byk/pages/components/XModal";
import Api from "@byk/api/Api";
import logo from "../../../assets/svg/logo.svg";
import { DeleteOutlined, FormOutlined, PlusCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import {appTenantClass, cutString} from "@byk/utils/Utils";
const { Content, Sider } = Layout;
const { Search } = Input;
import localStorage, {getTenant, getTenantId} from "utils/localStorage";
import { apiPath } from "@byk/routes";
class DictManager extends React.Component<any> {
  modalRef: React.RefObject<any>;
  childRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  apiPost = `${apiPath}/${getTenant()}/subapp/SysDataDict`;
  apiGet = `${apiPath}/${getTenant()}/subapp/SysDataDict/list`;
  constructor(props: any) {
    super(props);
    this.modalRef = React.createRef();
    this.childRef = React.createRef();
    this.formRef = React.createRef();
  }
  state = {
    RoleData: [
      { roleName: "HRSAAS", id: "1", code: "1", children: [] },
    ],
    TenantId: getTenantId(),
    roleTitle: "角色成员",
    type: 1,
    name: '',
    isModalForm: false,
    formCfg: {
      title: "",
      okText: "确定",
      type: null
    },
    titeType: { "a": "新增字典", "b": "修改字典名称" },
    btnTextType: { "a": "保存", "b": "确定" },
    dictDataList: [],
    initDictDataList:[],
    dict: null
  };


  //async
  async componentDidMount() {
    //this.init();
    this.getDictList();
    //this.callTable();
  }


  searchClick(val: any) {
    //console.log(val);
    //debugger;
      let _list=[...this.state.initDictDataList];
      let _l=_list.filter((item:any)=>{
        return item.name.indexOf(val)>-1;
      })
      this.setState({dictDataList:_l});
  }
  changeType(val: any) {
    this.setState({ dict: val });
    this.childRef.callback(val);
  }
  callTable() {
    let { name, type } = this.state;
    this.childRef.callback({ name, type });
  }
  showModal1(type: string) {
    this.setState({ isModalForm: true }, () => {
      let _data: any = this.state.formCfg.data;
      if (type == "a") {
        this.formRef.current?.setFieldsValue({ name: "" });
      }
      else {
        this.formRef.current?.setFieldsValue({ name: _data.name });
      }
    });
  };
  handleCancel1 = () => {
    this.setState({ isModalForm: false });
  };
  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data
      }
    }, () => { this.showModal1(type); });

    //this.formRef.current.resetFields();
  };
  //删除
  deleteClick = async (record: any) => {
    let { id } = record;
    let result: any = await Api.delete(this.apiPost, [id]);
    if (result.success) {
      this.getDictList();
    }
  };
  //更新
  updateForm = async (value: any) => {
    let _data: any = {};
    let cfg: any = this.state.formCfg;
    let result: any = null;
    _data.name = value.name;
    if (cfg.type == "a") {

      result = await Api.post(this.apiPost, _data);
    }
    if (cfg.type == "b") {
      _data.id = cfg.data.id;
      result = await Api.put(this.apiPost, _data);
    }
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel1();
      this.getDictList(cfg.type, result.result[0].id);
    }
    else {
      message.error(result.message);
    }

  };

  getDictList = async (type?: string, dictId?: any) => {
    let result: any = await Api.get(this.apiGet, { tenant: getTenant() });
    if (result.success) {
      let dict = -1;
      if (result.result.length > 0) {
        dict = result.result[0].id;
      }
      if (!_.isEmpty(type)) {
        dict = dictId;
      }
      this.setState({ dictDataList: result.result,initDictDataList:result.result }, () => {
        this.changeType(dict);
      });

    }
  };
  render() {

    const dictListComponent = this.state.dictDataList.map((item: any, index) => {

      return <div className={"x-menu-normal " + (this.state.dict == item.id ? " x-menu-active" : " x-menu-default")} style={{ position: "relative" }} onClick={() => this.changeType(item.id)}>
        <span title={item.name}>{cutString(item.name, 9)}</span>
        <Popconfirm placement="right" title={"确认删除吗？"} onConfirm={() => this.deleteClick(item)} okText="确定" cancelText="取消">
          <Button style={{ border: "none", position: "absolute", right: "0px" }} shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>

        <Button style={{ border: "none", position: "absolute", right: "25px" }} onClick={() => { this.openForm("b", item) }} shape="circle" icon={<FormOutlined />} />
      </div>;
    });

    return (
      <div >
        <Layout>
          {/*引入头部 */}
          {
            !this.props.hideHead && <Head title="ApplicationConfig" logoSrc={`${logo}?2`} />
          }

          <Layout>
            {
              !this.props.hideMenu && <Sider className="Sider-Navbar"><Navbar title="DictManager" /></Sider>
            }
            <Content>
              <PageHeader
                className="site-page-header"
                title="数据字典管理"
              />
              <Layout>
                <Sider style={{ width: "300px" }} className="x-border-right" >
                  <Row>
                    <Col span={12}><span className="x-fl x-blod">{/*字典*/}</span></Col>
                    <Col span={12} style={{ textAlign: "right", padding: "5px" }}>
                      <span style={{ cursor: "pointer" }} onClick={() => this.openForm("a", {})}><PlusCircleOutlined />&nbsp;新增</span>
                    </Col>
                  </Row>
                  <Modal title={this.state.formCfg.title} open={this.state.isModalForm} onOk={() => this.showModal1} onCancel={this.handleCancel1} footer={[]}  getContainer=".x-sys-manage-wrapper">
                    <Row>
                      <Col className="gutter-row" md={24}>
                        <Form ref={this.formRef} layout={"vertical"} onFinish={this.updateForm}>

                          <Form.Item label="字典名称" name="name"
                            rules={[{ required: true, message: "请输入字典名称!" }]}>
                            <Input placeholder="请输入字典名称" maxLength={50} />
                          </Form.Item>
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <Button onClick={this.handleCancel1}>取消</Button>
                            <Button className="x-ml10" type="primary" htmlType="submit">
                              保存
                            </Button>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Modal>
                  <div style={{ padding: "5px" }}>
                    <Search
                      placeholder="请输入"
                      allowClear
                      onChange={(val: any) => this.searchClick(val.target.value)}
                      onSearch={(val: any) => this.searchClick(val)}
                      className="x-mb10"
                      style={{ width: "100%" }}
                    />
                    <br></br>
                    <div style={{overflow:"hidden",overflowY:"auto",maxHeight:"calc(100vh - 230px)"
                  }}>
                      {dictListComponent}
                    </div>
                  </div>
                </Sider>
                <Content>
                  <BusinessPage onRef={node => this.childRef = node} />
                </Content>
              </Layout>
            </Content>
          </Layout>
        </Layout>

      </div>
    );
  }
}

export default withRouter(DictManager);
