import { Checkbox, Col, Collapse, Form, Input, Radio, Row, Select, Switch } from "antd";
import SketchPicker from "./SketchPicker";
import React, { useEffect, } from "react";
import { borderOpt, fontWeightOpt } from './StyleOption';
import styled from "styled-components";
export const XTitle = styled.div`
  font-weight: bold;
`;

export const XCheckbox = (props: any) => {
  const { value, onChange } = props;
  function onCheckboxChange(ev: any) {
    onChange(ev.target.checked);
  }
  return (
    <Checkbox checked={value} onChange={onCheckboxChange} />
  )
}
const { Panel } = Collapse;
const TopStyles = (props: any) => {
  const { layout, defaultValues, onValuesChangeTop } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      top: {
        height: "60",
        fontSize: '14',
        selectFontSize: '14',
        align: 'left',
        position: 'static',
        float: false,
        ...defaultValues.top,
      },
    })
  }, [defaultValues]);
  function onValuesChange(values: any) {
    onValuesChangeTop(values);
  }
  return (
    <Form form={form} layout="vertical" name="top" onValuesChange={onValuesChange}>
      <Collapse defaultActiveKey={['1']} ghost expandIconPosition={"end"}>
        <Panel header="菜单" key="1" forceRender={true}>
          <Form.Item name={['top', 'align']} label="菜单对齐方式" labelAlign="left" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Radio.Group optionType="button" buttonStyle="outline" className="x-radio-group3" style={{width:"100%"}}>
              <Radio value={'left'}>居左</Radio>
              <Radio value={'center'}>居中</Radio>
              <Radio value={'right'}>居右</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name={['top', 'position']}  label="固定在顶部" labelAlign="left" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Radio.Group  optionType="button" buttonStyle="outline"  className="x-radio-group2" style={{width:"100%"}}>
              <Radio value={'fixed'}>是</Radio>
              <Radio value={'static'}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={['top', 'float']} label="漂浮" labelAlign="left" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Radio.Group   optionType="button" buttonStyle="outline"  className="x-radio-group2" style={{width:"100%"}}>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={['top', 'height']} label="导航高度" labelAlign="left" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Input suffix="px" />
          </Form.Item>
          <Row style={{ alignItems: 'center',marginTop:"15px"  }}>
            <Col span={24}>背景色</Col>
          </Row>
          <Row style={{ alignItems: 'center',marginTop:"5px" }}>
            <Col span={3}>
              <Form.Item name={['top', 'isBgColor']} label="" style={{ marginBottom: 0 }}>
                <XCheckbox />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['top', 'backgroundColor']} label="" style={{ marginBottom: 0 }}>
                <SketchPicker />
              </Form.Item>
            </Col>
            <Col span={17}></Col>
          </Row>
          <Form.Item name={['top', 'width']} label="菜单宽度" labelAlign={'left'} style={{ margin: '10px 0 0 0' }}>
            <Input suffix="px" />
          </Form.Item>
          <Form.Item name={['top', 'fontSize']} label="字体大小" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Input style={{ width: '100%' }} suffix="px" />
          </Form.Item>

          <Form.Item name={['top', 'fontColor']} label="字体颜色" style={{ marginBottom: 0, marginTop: "15px" }}>
            <SketchPicker />
          </Form.Item>

          <Form.Item name={['top', 'fontWeight']} label="粗体/斜体" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Radio.Group   optionType="button" buttonStyle="outline"  className="x-radio-group2" style={{width:"100%"}}
              options={fontWeightOpt}>
            </Radio.Group>
            {/* <Select
              defaultValue="normal"
              style={{ width: '100px' }}
              options={fontWeightOpt}
            /> */}
          </Form.Item>
        </Panel>
        <Panel header="选中项" key="2" forceRender={true}>
         <Row style={{ alignItems: 'center',marginTop:"15px"  }}>
            <Col span={24}>背景色</Col>
          </Row>
          <Row style={{ alignItems: 'center',marginTop:"5px" }}>
          <Col span={3}>
              <Form.Item name={['top', 'selectIsBgColor']} label="" style={{ marginBottom: 0 }}>
                <XCheckbox />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['top', 'selectBackgroundColor']} label="" style={{ marginBottom: 0 }}>
                <SketchPicker />
              </Form.Item>
            </Col>
            <Col span={17}></Col>
          </Row>


          {/* <Row style={{ alignItems: 'center' }}>
            <Col span={3}>
              <Form.Item name={['top', 'selectIsBgColor']} label="" style={{ marginBottom: 0 }}>
                <XCheckbox />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['top', 'selectBackgroundColor']} label="" style={{ marginBottom: 0 }}>
                <SketchPicker />
              </Form.Item>
            </Col>
            <Col span={17}>选中背景色</Col>
          </Row> */}
          {/* <div>文本</div>
          <Row>
            <Col span={3}> */}
              {/* <Form.Item name={['top', 'selectFontColor']} label="" style={{ marginBottom: 0 }}>
                <SketchPicker />
              </Form.Item> */}
            {/* </Col>
            <Col span={12}> */}
              {/* <Form.Item name={['top', 'selectFontSize']} label="" style={{ marginBottom: 0 }}>
                <Input style={{ width: '120px' }} suffix="px" />
              </Form.Item> */}
            {/* </Col>
            <Col span={9}> */}
              {/* <Form.Item name={['top', 'selectFontWeight']} label="" style={{ marginBottom: 0 }}>
                <Select
                  defaultValue="normal"
                  style={{ width: '100px' }}
                  options={fontWeightOpt}
                />
              </Form.Item> */}
            {/* </Col>
          </Row> */}
          <Form.Item name={['top', 'selectFontSize']} label="字体大小" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Input style={{ width: '100%' }} suffix="px" />
          </Form.Item>
          <Form.Item name={['top', 'selectFontColor']} label="字体颜色" style={{ marginBottom: 0, marginTop: "15px" }}>
            <SketchPicker />
          </Form.Item>

          <Form.Item name={['top', 'selectFontWeight']} label="粗体/斜体" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Radio.Group   optionType="button" buttonStyle="outline"  className="x-radio-group2" style={{width:"100%"}}
              options={fontWeightOpt}>
            </Radio.Group>
            {/* <Select
              defaultValue="normal"
              style={{ width: '100px' }}
              options={fontWeightOpt}
            /> */}
          </Form.Item>
        </Panel>
      </Collapse>
    </Form>
  )
};
export default TopStyles;
