import {Button, Col, Divider, Form, Input, InputRef, Row, Select} from 'antd'
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'

import {IProps} from "@byk/pages/components/SearchForm"
import {ModalWrapper} from "@byk/pages/AppDeploy/styled";
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";
import Api from "@byk/api/Api";
import _, {isEmpty} from "lodash";
import TextArea from "antd/lib/input/TextArea";
import {Dependency_List_URL} from "@byk/pages/PackageManager/api";
import ModelingApi from '../modeling/lib/ModelingApi';
import UploadWidget from "@byk/pages/components/UploadWidget";
import {getTenant} from "../../../utils/localStorage";

const apiCategory: string = "/byk/platform/service/getComponentTree";

const getCategoryOptions = async () => {
  let result: any = await Api.get(apiCategory) || [];
  let cusCmpRes = result['自定义'];
  let cusCmpOptions: any = [];
  _.keys(cusCmpRes).forEach(key => {
    cusCmpOptions.push({
      label: key,
      value: key,
    })
  });
  return cusCmpOptions;
}

const getDependencyOptions = async () => {
  let res: any = await Api.get(Dependency_List_URL, {tenant: ModelingApi.getTenant()});
  let options: any = [];
  if (res.success && res.result) {
    _.forEach(res.result || [], item => {
      if (item.code) {
        options.push({
          name: item.name,
          id: item.name,
        });
      }
    })
  }
  return options;
}

const formItemLayout = {
  labelCol: {span: 6},
  wrapperCol: {span: 18},
};

interface Props extends IProps {
  refreshTable: (searchData?: any) => any,
  categoryDic: any,
  modalRef: any,
  apiPost: any,
  apiPut: any,
}

const FormModal: React.FC<Props> = forwardRef((
  {
    refreshTable,
    categoryDic,
    modalRef,
    apiPost,
    apiPut,
  }, ref) => {

  useImperativeHandle(ref, () => ({
    showModal,
    handleCancel,
    edit,
  }))

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [data, setData] = useState<any>({});
  const [files, setFiles] = useState<any>([]);
  const [type, setType] = useState<any>();
  const formRef = useRef<any>();

  useEffect(() => {
    let type = data && data.type;
    let id = data && data.id;
    setIsUpload(['source', 'clazz'].indexOf(type || '') > -1);
    setIsNew(id ? false : true)
  }, [data])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const add = async () => {
    formRef.current?.resetFields();
    setData(null);
    setFiles([]);
    formRef.current?.setFieldValue("type", 'script');
    setIsUpload(false);
    showModal()
  }

  const edit = async (record: any = {}) => {
    if (record.dependencies && record.dependencies.split) {
      record.dependencies = record.dependencies.split(',');
    }
    isEmpty(record.dependencies) && (record.dependencies = [])
    await formRef.current?.setFieldsValue(record);
    setData(record);
    showModal()
  }

  const getSearchData = async () => {
    try {
      const v = await formRef.current.validateFields();
      for (let val of Object.keys(v)) {
        if (v[val] == undefined) {
          v[val] = '';
        }
        if (val == 'dependencies' && v[val] && v[val].slice) {
          v[val] = v[val].join(',');
        }
      }
      return {...data, ...v};
    } catch (errorInfo) {
      console.warn(errorInfo);
      return null;
    }
  }

  const handleOk = async () => {
    let value: any = await getSearchData();
    if (value) {
      let result: any = null;
      value.tenant = getTenant();
      if (value.id) {
        result = await Api.put(apiPost, value) || {};
      } else {
        result = await Api.post(apiPost, value) || {};
      }

      if (result?.success) {
        doRefreshTable();
      }
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  const doRefreshTable = () => {
    handleCancel()
    refreshTable();
  }

  return (
    <>
      <div className='x-mt10 x-mb10'>
        <Button type="primary" onClick={add}>新增</Button>
      </div>

      <ModalWrapper
        forceRender={true}
        maskClosable={false}
        cancelText={'取消'}
        title={isNew ? '新增' : '编辑'}
        okText={isNew ? "保存" : "更新"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form ref={formRef} autoComplete="off" className="flex-auto">
          {
            SelectRender({
              placeholder: "请输入",
              name: "type",
              label: "组件类型",
              allowClear: true,
              rules: [{required: true, message: "请输入!"}],
              data: categoryDic,
              value: type,
              onChange: (value: any, option: any) => {
                setIsUpload(value == 'source' || value == 'clazz')
                setType(value);
                setFiles([]);
                formRef.current?.resetFields();
                formRef.current.setFieldValue("type", value);
              }
            })
          }

          <div style={{display: isUpload ? 'none' : 'block'}}>
            {
              InputRender({
                placeholder: "请输入",
                name: "code",
                label: "组件编号",
                allowClear: true,
                rules: [{required: !isUpload, message: "请输入!"}],
              })
            }

            {
              InputRender({
                placeholder: "请输入",
                name: "name",
                label: "组件名称",
                allowClear: true,
                rules: [{required: !isUpload, message: "请输入!"}],
              })
            }

            {
              SelectWitchAddItem({
                placeholder: "请选择",
                name: "category",
                label: "分类",
                addOneLabel: '增加分类',
                allowClear: true,
                data: getCategoryOptions,
                rules: [{required: !isUpload, message: '请输入！'}],
              })
            }
          </div>

          {
            SelectRender({
              placeholder: "请选择",
              name: "dependencies",
              label: "依赖包",
              allowClear: true,
              mode: 'multiple',
              data: getDependencyOptions,
            })
          }

          <div style={{display: isUpload ? 'none' : 'block'}}>
            {
              InputTextareaRender({
                placeholder: "",
                name: "data",
                label: "组件脚本",
                allowClear: true,
                maxlength: 100000,
                height: 120,
                rules: [{required: !isUpload, message: "请输入!"}],
                background:"#202020"
              })
            }

            {
              InputTextareaRender({
                placeholder: "请输入",
                name: "description",
                label: "组件描述",
                allowClear: true,
                maxlength: 1000,
                height: 80,
              })
            }

            {
              InputTextareaRender({
                placeholder: "",
                name: "attrs",
                label: "组件属性",
                allowClear: true,
                maxlength: 1000,
                background:"#202020",
                height: 120,
              })
            }
          </div>

          <div style={{display: isUpload ? 'block' : 'none'}}>
            {
              UploadRender({
                name: "data",
                label: "代码文件",
                allowClear: true,
                accept: type == 'source' ? '.java' : '.class',
                rules: [{required: isUpload, message: "请上传文件"}],
                files: files,
                onChange: (_data: any) => {
                  setFiles(_data);
                  let code = null;
                  if (_data && _data.length > 0) {
                    code = _data[0].code;
                  }
                  formRef.current.setFieldValue("data", code);
                }
              })
            }
          </div>
        </Form>
      </ModalWrapper>
    </>
  )
})

export default FormModal

const InputRender = (item: any) => {
  return (
    <Form.Item
      {...formItemLayout}
      label={item.label} name={item.name} rules={item.rules}
      initialValue={item.initialValue}>
      <Input hidden={item.hidden} disabled={item.disabled} maxLength={item.maxLength} placeholder={item.placeholder}/>
    </Form.Item>
  );
};

const SelectWitchAddItem = (item: any) => {
  const [items, setItems] = useState<any>([]);
  const [name, setName] = useState('');
  const [addFlag, setAddFlag] = useState<boolean>(false);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (typeof item.data == 'function') {
      item.data().then((opts: any) => {
        setItems(opts);
      });
    } else {
      setItems(item.data || []);
    }
  }, [item.data])

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    let exist = items.findIndex((item: any) => item.value == name) >= 0
    if (!exist && name) {
      setItems([...items, {label: name, value: name}]);
    }
    setName('');
    setAddFlag(false);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const cancel = () => {
    setName('');
    setAddFlag(false);
  }

  return (
    <Form.Item
      {...formItemLayout}
      label={item.label} name={item.name} rules={item.rules}>
      <Select
        placeholder={item.placeholder}
        options={items}
        dropdownRender={(menu: any) => (
          <>
            {menu}
            <Divider style={{margin: '8px 0'}}/>
            {!addFlag &&
            <Button
              style={{display: 'flex', alignItems: 'center'}}
              type={"link"}
              icon={getIconPark('AddOne')} onClick={() => setAddFlag(true)}
            >
              <span style={{marginLeft: '5px'}}>{item.addOneLabel}</span>
            </Button>
            }
            {addFlag &&
            <Row style={{margin: '0 5px'}}>
              <Col span={18}>
                <Input
                  ref={inputRef}
                  value={name}
                  onChange={onNameChange}
                />
              </Col>
              <Col span={6} style={{display: 'flex'}}>
                <Button style={{padding: 0, marginLeft: '5px'}} type={"link"} onClick={addItem}>确认</Button>
                <Button style={{padding: 0, marginLeft: '5px'}} type={"link"} onClick={cancel}><span
                  style={{color: 'gray'}}>取消</span></Button>
              </Col>
            </Row>
            }
          </>
        )}
      />
    </Form.Item>
  );
};

const SelectRender = (item: any) => {
  const [items, setItems] = useState<any>([]);
  let fieldNames = {label: 'name', value: 'id'};
  let filterKey = "name";
  if (item.fieldNames != undefined) {
    fieldNames = item.fieldNames;
    filterKey = item.fieldNames.label;
  }

  useEffect(() => {
    if (typeof item.data == 'function') {
      item.data().then((opts: any) => {
        setItems(opts);
      });
    } else {
      setItems(item.data || []);
    }
  }, [item.data])

  return (
    <Form.Item
      {...formItemLayout}
      label={item.label} name={item.name} rules={item.rules}>
      <Select
        value={item.value}
        placeholder={item.placeholder}
        mode={item.mode}
        style={{width: "100%"}}
        options={items}
        showSearch
        fieldNames={fieldNames}
        allowClear
        showArrow
        filterOption={(input: any, option: any) =>
          (option[filterKey] ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={(value, option) => item.onChange && item.onChange(value, option)}
      />
    </Form.Item>
  );
}

const InputTextareaRender = (item: any) => {
  return (
    <Form.Item
      {...formItemLayout}
      label={item.label} name={item.name} rules={item.rules}>
      <TextArea maxLength={item.maxLength} style={{height: item.height,background:item.background||"white",color:item.background?"white":"black"}} placeholder={item.placeholder}/>
    </Form.Item>
  )
};

const UploadRender = (item: any) => {
  return (
    <Form.Item
      {...formItemLayout}
      label={item.label} name={item.name} rules={item.rules} valuePropName="checked">
      <UploadWidget
        onEmit={(data: any) => {
          item.onChange && item.onChange(data);
        }}
        accept={item.accept}
        files={item.files}
        isByk={true}
        fileSize={2} fileCount={1} btnText='点击上传文件'
      />
      <div style={{
        position: 'absolute',
        top: '6px',
        right: '85px',
        color: 'gray',
      }}>
        请上传{item.accept}文件
      </div>
    </Form.Item>
  );
};
