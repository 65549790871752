import React, { useRef, useState, useEffect } from "react";
import TableList from "@byk/pages/components/TableList";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, Tooltip, Drawer, Form, Input, message, Row, Col, Select, Popover, Divider, Layout, List, Checkbox, Table } from "antd";
import Api from "@byk/api/Api";

import { Link, withRouter } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import type { integer } from "vscode-languageserver-types";

import _ from "lodash";
import localStorage, {getProjectId, getProjectName, getTenantId} from "utils/localStorage";
import { getAuthority } from "@byk/utils/authority";
import { routePath } from "@byk/routes";
import {appTenantClass} from "@byk/utils/Utils";
const { Content, Sider, Header, Footer } = Layout;
interface DataType {
  id: React.Key;
}

class MemberManagerList extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  tableAddRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.tableAddRef = React.createRef();
    this.formRef = React.createRef();
  }

  state = {
    roleId: -1,
    tenantId: getTenantId(),
    projectId: getProjectId(),
    projectName: getProjectName(),
    visibleLoadTableModal: false,
    title: '全部成员',
    type: 1,
    isModalForm: false,
    initParams: {
      includeRelated: true,
      includeList: true,
      projectId: getProjectId(),
    },
    initParams1: {
      includeRelated: true,
      includeList: true,
      projectId: getProjectId(),
    },
    roleList: [],
    adminRoleList: [],
    powerList: [],
    count: 0,
    noJoinData: [],
    joinData: [{ a: 1 }],
    roleJoinList: {},
    powerJoinList: {},
    checkList: {},
    isAdmin:getAuthority().project==1,
  };

  async bindRoleList() {
    let result: any = await Api.get("/byk/platform/rest/TenantRole/list", { tenantId: this.state.tenantId });
    if (result.success) {
      let _r: any = result.result;
      //let _roleList=_r.filter((item:any)=>{return item.projectJob!="admin"});
      this.setState({ roleList:_r,adminRoleList:_r });
    }
  }
  async bindPowerList() {
    let result: any = await Api.get("/byk/platform/app/getAppRoles", {});
    if (result.success) {
      let _r: any = result.result;
      this.setState({ powerList: result.result });
    }
  }

  async bindNojoin() {
    let res: any = await Api.get('/byk/platform/app/getNoJoinMembers', {
      projectId: this.state.projectId, includeRelated: true,
      includeList: true
    });;
    if (res.success) {
      let _d = [...res.result];
      let _check: any = {};
      _.each(_d, (itm) => {
        //itm.checked = false;
        _check["checked" + itm.id] = false;
      })
      this.setState({ noJoinData: _d, powerJoinList: {}, roleJoinList: {}, checkList: _check });
      console.log(_d);
    }
  };

  openLoadTableModal = () => {
    this.bindNojoin();
    this.setState({
      joinData: [],
      visibleLoadTableModal: true
    });
  };
  componentDidMount() {
    this.bindRoleList();
    this.bindPowerList();
    this.bindNojoin();

  };

  init() {
    let params: any = {...this.state.initParams};
    this.setState({ initParams: params }, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };



  render() {
    // api
    const apiGet: string = "/byk/platform/rest/UserApp/list";
    const apiPost: string = "/byk/platform/rest/UserApp";
    const apiPut: string = "/byk/platform/rest/UserApp";
    const apiDel: string = "/byk/platform/rest/UserApp";
    const apiUserGet: string = "/byk/platform/rest/SysUser/list";
    //表格refresh
    const refreshTable = (params: any = {}) => {

    };


    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };

    //角色选择
    const handleRoleChange1 = (val: any, record: any) => {
      let _roleJoinList: any = { ...this.state.roleJoinList };
      _roleJoinList["role" + record.id] = val;
      this.setState({ roleJoinList: _roleJoinList });
    };

    //权限选择
    const handlePowerChange1 = (val: any, record: any) => {
      let _powerJoinList: any = { ...this.state.powerJoinList };
      _powerJoinList["power" + record.id] = val;
      this.setState({ powerJoinList: _powerJoinList });
    };

    //角色选择
    const handleRoleChange = async (val: any, record: any) => {
      let _data: any = {};
      _data.id = record.id;
      _data.appRole = val;
      let result: any = await Api.put(apiPut, _data);
      if (result.success) {
        this.init();
      }
    };
    //条数
    const setCount = (res: any) => {
      this.setState({ count: res.size })
    }
    //权限选择
    const handlePowerChange = async (val: any, record: any) => {
      let _data: any = {};
      _data.id = record.id;
      _data.auths = val;
      let result: any = await Api.put(apiPut, _data);
      if (result.success) {
        this.init();
      }
    };

    const content = (record: any) => (
      <div style={{ width: "250px", 
      height:!record.tenantAdminAble?"290px":"inherit",
      padding:"10px"}}>
        <p className="x-bold">管理者</p>
        <p>可以开发应用、发布应用，还可以维护项目信息、管理项目成员、删除项目</p>
        <Divider />
        <p className="x-bold">开发者</p>
        <p>只可以开发应用、发布应用</p>
        {!record.tenantAdminAble&&<>
          <Divider />
          <p>
            <Popconfirm placement="left" title={"确定移除该成员吗？"} onConfirm={() => deleteClick(record)} okText="确定" cancelText="取消">
              <Button type="link" className="x-fr" style={{ color: "red", marginBottom: "50px" }}>
                移除成员
              </Button>
            </Popconfirm>
          </p>
        </>}
      </div>

    );
    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 80,
      },

      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
        width: 150,
      },

      {
        title: "备注名",
        dataIndex: "alias",
        key: "alias",
      },
      {
        title: "手机号",
        dataIndex: "userMobile",
        key: "userMobile",
        width: 120,
      },
      {
        title: "邮箱",
        dataIndex: "userEmail",
        key: "userEmail",
        width: 200,
      },
      {
        title: "角色",
        dataIndex: "tenantRoleName",
        key: "tenantRoleName",
        width: 150,
        render: (_, record: any) => (
          <>
            <Space >
              <Select
               disabled
                //disabled={record.tenantAdminAble}
                style={{ width: 220 }}
                placeholder="请选择角色"
                value={record.appRole}
                key={"role" + record.id}
                fieldNames={{ label: 'roleName', value: 'id' }}
                options={record.tenantAdminAble?this.state.adminRoleList:this.state.roleList}
                onChange={(val) => handleRoleChange(val, record)}
              />
            </Space>
          </>
        ),
      },
      {
        title: "权限",
        dataIndex: "tenantRoleName",
        key: "tenantRoleName",
        width: 150,
        render: (_, record: any) => (
          <>
            <Space >
              <Popover placement="left" content={content(record)} trigger="click"  style={{ width: "300px",padding:"10px" }}>
                <Select
                  // disabled={record.tenantAdminAble}
                  style={{ width: 220 }}
                  placeholder="请选择权限"
                  value={record.auths}
                  key={"power" + record.id}
                  fieldNames={{ label: 'roleName', value: 'roleId' }}
                  options={this.state.powerList}
                  onChange={(val) => handlePowerChange(val, record)}
                />
              </Popover>
            </Space>
          </>
        ),
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      getCheckboxProps: (record: DataType) => ({
        // disabled: record.name === 'Disabled User', // Column configuration not to be checked
        // name: record.name,
      }),
    };

    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: {includeRelated:true,includeList:true,projectId:this.state.projectId},
      callback: (res: any) => setCount(res)
    };

    //添加成员
    //表格配置
    const columns1: ColumnsType = [
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "邮箱",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
      }
    ];

    const columnsJoin = [
      {
        title: "姓名",
        dataIndex: "userName",
        key: "userName",
        width: 150,
      },

      {
        title: "备注名",
        dataIndex: "alias",
        key: "alias",
      },
      {
        title: "手机号",
        dataIndex: "userMobile",
        key: "userMobile",
        width: 120,
      },
      {
        title: "邮箱",
        dataIndex: "userEmail",
        key: "userEmail",
        width: 200,
      },
      {
        title: "角色",
        dataIndex: "tenantRoleName",
        key: "tenantRoleName",
        width: 150,
        render: (_: any, record: any) => (
          <>
            <Space >
              <Select
                style={{ width: 220 }}
                disabled
                placeholder="请选择角色"
                defaultValue={this.state.roleJoinList["role" + record.id]}
                value={this.state.roleJoinList["role" + record.id]}
                key={"role" + record.id}
                fieldNames={{ label: 'roleName', value: 'id' }}
                options={this.state.roleList}
                onChange={(val) => handleRoleChange1(val, record)}
              />
            </Space>
          </>
        ),
      },
      {
        title: "权限",
        dataIndex: "tenantRoleName",
        key: "tenantRoleName",
        width: 150,
        render: (_, record: any) => (
          <>
            <Space >

              <Select
                style={{ width: 220 }}
                placeholder="请选择权限"
                value={this.state.powerJoinList["power" + record.id]}
                key={"power" + record.id}
                fieldNames={{ label: 'roleName', value: 'roleId' }}
                options={this.state.powerList}
                onChange={(val) => handlePowerChange1(val, record)}
              />

            </Space>
          </>
        ),
      }
    ];


    const tableProps1 = {
      columns: columns1,
      api: apiUserGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      }
    };
    const handleOkJoin = async () => {
      let _data: any = this.state.joinData;
      let _d: any = [];
      const powerJoinList: object = { ...this.state.powerJoinList };
      const roleJoinList: object = { ...this.state.roleJoinList };
      console.log("_olddata", _data);
      _.each(_data, (item: any, idx: any) => {
        let d: object = {
          tenantId: this.state.tenantId, userId: item.userId, projectId: this.state.projectId,
          alias:item.alias,appRole: roleJoinList["role" + item.id], auths: powerJoinList["power" + item.id]
        };
        _d.push(d);
      })
      console.log("_power", powerJoinList);
      console.log("_role", roleJoinList);
      console.log("_data", _d);
      //return ;
      let res: any = await Api.post("/byk/platform/rest/UserApp", _d);;
      if (res.success) {
        message.success("添加成功!");
        handleCancel();
        this.init();
      }

    }
    const onLeftChange = (e: any, record: any) => {;
      let _data: any = [...this.state.joinData];
      let val: boolean = e.target.checked;
      if (val) {
        record.appRole =null;
        record.auths = null;
        let _roleJoinList: any = { ...this.state.roleJoinList };
        _roleJoinList["role" + record.id] =  record.tenantRole;
        _roleJoinList["power" + record.id] = null;
        this.setState({ roleJoinList: _roleJoinList });
        _data.push(record);
      }
      else {
        let _d: any = _data.filter((item: any, idx: integer) => {
          return item.id != record.id;
        })
        _data = _d;
      }
      this.setState({ joinData: _data });
    }

    const handleCancel = () => {
      this.setState({ visibleLoadTableModal: false });
    }
    return (
      <>
        <div className="x-main-box">
          <span className="x-title"><Link to={routePath}><Button className="x-mr20" icon={<LeftOutlined />} size="middle">
            返回
          </Button></Link>{this.state.projectName}项目成员管理</span>
          <Row gutter={100} className="x-mt20 x-mb20">
            <Col span={12}>
              <span>{this.state.count}名应用成员</span>
            </Col>
            <Col span={12}>
            {this.state.isAdmin&&<>
              <Button type="primary" onClick={() => this.openLoadTableModal()} className="x-fr x-ml20" style={{ marginTop: "-15px" }}>
                添加成员
              </Button>
            </>}
            </Col>
          </Row>

          <TableList ref={this.tableRef} {...tableProps} />

          <Modal title="添加成员" destroyOnClose={true} cancelText="取消" okText="确定" width="90%" open={this.state.visibleLoadTableModal} onOk={() => handleOkJoin()} onCancel={handleCancel}>
            <Layout>
              <Layout>
                <Sider style={{ width: "300px", minWidth: "350px" }}>
                  <p className="x-bold">未加入的成员</p>
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.noJoinData}
                    renderItem={(item: any) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<><Checkbox value={this.state.checkList["checked" + item.id]} key={"checked" + item.id} onChange={(val) => onLeftChange(val, item)}></Checkbox><span style={{ paddingLeft: "10px" }} className="x-bold">
                            {/* {item.name + "(" + item.alias + ")"} */}
                            { item.alias==""?item.name:`${item.name}(${item.alias})`}
                            </span></>}
                          description={<p style={{ paddingLeft: "27px" }}>{item.userMobile}</p>}
                        />
                      </List.Item>
                    )}
                  />
                </Sider>
                <Content>
                  {/* <TableList ref={this.tableAddRef} {...tableProps1} /> */}
                  <Table dataSource={this.state.joinData} columns={columnsJoin} />;
                </Content>
              </Layout>

            </Layout>

          </Modal>
        </div>
      </>
    );
  }
}
export default withRouter(MemberManagerList);
