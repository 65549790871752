import {NotebookStepUiComponentProps} from "@byk/pages/QueryBuilder/components/notebook/types";
import React from "react";
import {
  StepContainer,
  StepLabel,
  StepRoot
} from "@byk/pages/QueryBuilder/components/notebook/steps/SummarizeStep/SummarizeStep.styled";
import AggregateStep from "@byk/pages/QueryBuilder/components/notebook/steps/AggregateStep/AggregateStep";
import BreakoutStep from "@byk/pages/QueryBuilder/components/notebook/steps/BreakoutStep/BreakoutStep";

const SummarizeStep: React.FC<NotebookStepUiComponentProps> =
  ({
     color,
     query,
     updateQuery,
     isLastOpened,
     ...props
   }: NotebookStepUiComponentProps) => {

    return (
      <StepRoot>
        <StepContainer>
          <AggregateStep
            color={color}
            query={query}
            updateQuery={updateQuery}
            isLastOpened={isLastOpened}
            {...props}
          />
        </StepContainer>
        <StepLabel color={color}>通过</StepLabel>
        <StepContainer>
          <BreakoutStep
            color={color}
            query={query}
            updateQuery={updateQuery}
            isLastOpened={false}
            {...props}
          />
        </StepContainer>
      </StepRoot>
    );

  };
export default SummarizeStep;
