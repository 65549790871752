import withWidgetProps from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XProgress from "../../components/widgets/XProgress";';
  begin += `<XProgress propertyPath={${i.propertyPath}} color='${i.color}' percent={${i.percent}} isHidePercent={${i.isHidePercent}} `;
  begin += getWidgetStyles(i);
  begin += ' />';
  return { begin, head };
};
