import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import type {RouteComponentProps} from "react-router";
import {withRouter} from "react-router";
import type {AppViewerRouteParams, BuilderRouteParams,} from "constants/routes";
import * as Sentry from "@sentry/react";
import {initLanguage, initLanguageMenu, initLanguagePage, initLanguageText} from "actions/initActions";
import AppViewerMenu from "./AppViewerMenu";
import Api from "../../api/Api";
import localStorage from "../../utils/localStorage";
import ViewerMainContainer from "./ViewerMainContainer";
import { getTenant } from "utils/localStorage";
import {appTenantClass} from "@byk/utils/Utils";

export type AppViewerProps = RouteComponentProps<BuilderRouteParams>;

type Props = AppViewerProps & RouteComponentProps<AppViewerRouteParams>;

function AppViewer(props: Props) {
  const dispatch = useDispatch();
  const { applicationId, pageId } = {
    ...props.match.params,
    applicationId: props.match.params.applicationSlug||''
  };
  const projectId = useSelector((state:any)=>{
    return state.ui.applications?.currentApplication?.projectId;
  })
  useEffect(()=>{
    //菜单数据
    Api.get(`/byk/${appTenantClass.getTenant()}/subapp/SubappI18N/list`, {
      appId: applicationId,
      //pageId: pageId,
      textType: 'Menu'
    }).then((res:any)=>{
      if(res.success){
        dispatch(initLanguageMenu(res.result));
      }
    })
    //文本数据
    Api.get(`/byk/${appTenantClass.getTenant()}/subapp/SubappI18N/list`, {
      appId: applicationId,
      textType: 'Menu',
      exp: 'eq,notEq'
    }).then((res:any)=>{
      if(res.success){
        dispatch(initLanguageText(res.result));
      }
    })
  }, [applicationId]);

  useEffect(()=>{
    //语种数据
    if(!projectId){
      return ;
    }
    Api.get(`/byk/${appTenantClass.getTenant()}/subapp/SubappLocale/list`, {
      projectId: projectId,
      asc:'sort',
    }).then((res:any)=>{
      if(res.success){
        dispatch(initLanguage(res.result));
      }
    })
  }, [projectId])

  useEffect(() => {
    //页面国际化数据
    Api.get(`/byk/${appTenantClass.getTenant()}/subapp/SubappPageI18n/list`, {
      appId: applicationId,
      pageId: pageId
    }).then((res:any)=>{
      if(res.success){
        dispatch(initLanguagePage(res.result));
      }
    })
  }, [pageId]);

  return (
    <>
      <AppViewerMenu applicationId={applicationId} pageId={pageId}>
        <ViewerMainContainer />
      </AppViewerMenu>
    </>
  );
}

export default withRouter(Sentry.withProfiler(AppViewer));
