import styled from "styled-components";
import Icon from "@byk/pages/components/Icon";
import {color} from "@byk/pages/QueryBuilder/lib/colors";
import IconButtonWrapper from "@byk/pages/QueryBuilder/components/ui/IconButtonWrapper";

export const SqlButton = styled(IconButtonWrapper)`
  color: ${color("text-dark")};
  padding: 0.5rem;

  &:hover {
    color: ${color("brand")};
    background-color: ${color("bg-medium")};
  }
`;

export const SqlIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
`;
