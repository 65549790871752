import withWidgetProps from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    useLoad = '',
    head='import XList from "../../components/widgets/XList";';
  if(i.onLoad){
    useLoad += `evalEvent['${i.widgetName}Load'](dataTree, dispatch);`;
  }
  begin = begin + `
        <XList propertyPath={${i.propertyPath}} `;
  begin += getWidgetStyles(i);
  begin += ' />';
  return {begin, useLoad, head};
};
