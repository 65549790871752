import React, {useEffect, useState} from "react";
import styled, {ThemeProvider} from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import type {RouteComponentProps} from "react-router";
import {withRouter} from "react-router";
import type {AppState} from "@appsmith/reducers";
import type {AppViewerRouteParams, BuilderRouteParams,} from "constants/routes";
import {GIT_BRANCH_QUERY_KEY} from "constants/routes";
import {getAppViewHeaderHeight, getIsInitialized,} from "selectors/appViewSelectors";
import EditorContextProvider from "components/editorComponents/EditorContextProvider";
import AppViewerPageContainer from "./AppViewerPageContainer";
import {editorInitializer} from "utils/editor/EditorUtils";
import * as Sentry from "@sentry/react";
import {getCurrentPageBgColor, getCurrentPageDescription, getViewModePageList} from "selectors/editorSelectors";
import {getThemeDetails, ThemeMode} from "selectors/themeSelectors";
import {getSearchQuery} from "utils/helpers";
import {getSelectedAppTheme} from "selectors/appThemingSelectors";
import {setAppViewHeaderHeight} from "actions/appViewActions";
import {showPostCompletionMessage} from "selectors/onboardingSelectors";
import {CANVAS_SELECTOR} from "constants/WidgetConstants";
import {fetchPublishedPage} from "actions/pageActions";
import usePrevious from "utils/hooks/usePrevious";
import {getIsBranchUpdated} from "../utils";
import {APP_MODE} from "entities/App";
import {initAppViewer} from "actions/initActions";
import {WidgetGlobaStyles} from "globalStyles/WidgetGlobalStyles";
import useWidgetFocus from "utils/hooks/useWidgetFocus/useWidgetFocus";
import HtmlTitle from "./AppViewerHtmlTitle";
import type {ApplicationPayload} from "@appsmith/constants/ReduxActionConstants";
import {getCurrentApplication} from "@appsmith/selectors/applicationSelectors";

import {ReduxActionTypes} from "@appsmith/constants/ReduxActionConstants";
import {getAppStoreData} from "../../selectors/entitiesSelector";
import _ from "lodash";

const AppViewerBody = styled.section<{
  hasPages: boolean;
  headerHeight: number;
  showGuidedTourMessage: boolean;
}>`
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
  --view-mode-header-height: ${({ headerHeight }) => headerHeight}px;
`;

const AppViewerBodyContainer = styled.div<{
  width?: string;
  backgroundColor: string;
}>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: ${({ backgroundColor }) => backgroundColor};
`;

export type AppViewerProps = RouteComponentProps<BuilderRouteParams>;

type Props = AppViewerProps & RouteComponentProps<AppViewerRouteParams>;

const DEFAULT_FONT_NAME = "System Default";

function ViewerCanvas(props: Props) {
  const dispatch = useDispatch();
  const { pathname, search } = props.location;
  const { applicationId, pageId } = {
    ...props.match.params,
    applicationId: props.match.params.applicationSlug||''
  };
  const [registered, setRegistered] = useState(false);
  const isInitialized = useSelector(getIsInitialized);
  const pages = useSelector(getViewModePageList);
  const selectedTheme = useSelector(getSelectedAppTheme);
  const lightTheme = useSelector((state: AppState) =>
    getThemeDetails(state, ThemeMode.LIGHT),
  );
  const showGuidedTourMessage = useSelector(showPostCompletionMessage);
  const headerHeight = useSelector(getAppViewHeaderHeight);
  const branch = getSearchQuery(search, GIT_BRANCH_QUERY_KEY);
  const prevValues = usePrevious({ branch, location: props.location, pageId });
  const pageDescription = useSelector(getCurrentPageDescription);
  const currentApplicationDetails: ApplicationPayload | undefined = useSelector(
    getCurrentApplication,
  );
  const pageBgColor = useSelector(getCurrentPageBgColor)

  const focusRef = useWidgetFocus();
  const currentStore:any = useSelector(getAppStoreData);


  /**
   * initializes the widgets factory and registers all widgets
   */
  useEffect(()=>{
    editorInitializer().then(() => {
      setRegistered(true);
    });
    dispatch(
      initAppViewer({
        applicationId,
        branch,
        pageId,
        mode: APP_MODE.PUBLISHED,
      }),
    );
  }, [applicationId])
  useEffect(() => {
    //currentStore 清楚缓存, 保留字典数据，清掉页面组件数据 specialMark:true
    let cacheStore:any = {};
    if(currentStore){
      _.each(currentStore, (value, key)=>{
        if(value&&value[0]&&value[0]['specialMark']){
          cacheStore[key] = value;
        }
      });
    }
    dispatch({
      type: ReduxActionTypes.UPDATE_APP_STORE,
      payload: cacheStore,
    })

  }, [pageId]);

  /**
   * initialize the app if branch, pageId or application is changed
   */
  useEffect(() => {
    const prevBranch = prevValues?.branch;
    const prevLocation = prevValues?.location;
    const prevPageId = prevValues?.pageId;
    let isBranchUpdated = false;
    if (prevBranch && prevLocation) {
      isBranchUpdated = getIsBranchUpdated(props.location, prevLocation);
    }

    const isPageIdUpdated = pageId !== prevPageId;

    if (prevBranch && isBranchUpdated && (applicationId || pageId)) {
      dispatch(
        initAppViewer({
          applicationId,
          branch,
          pageId,
          mode: APP_MODE.PUBLISHED,
        }),
      );
    } else {
      /**
       * First time load is handled by init sagas
       * If we don't check for `prevPageId`: fetch page is retriggered
       * when redirected to the default page
       */
      if (prevPageId && pageId && isPageIdUpdated) {
        dispatch(fetchPublishedPage(pageId, true));
      }
    }
  }, [branch, pageId, applicationId, pathname]);

  useEffect(() => {
    const header = document.querySelector(".js-appviewer-header");

    dispatch(setAppViewHeaderHeight(header?.clientHeight || 0));
  }, [pages.length, isInitialized]);

  /**
   * returns the font to be used for the canvas
   */
  const appFontFamily =
    selectedTheme.properties.fontFamily.appFont === DEFAULT_FONT_NAME
      ? "inherit"
      : selectedTheme.properties.fontFamily.appFont;

  /**
   * loads font for canvas based on theme
   */
  useEffect(() => {
    document.body.style.fontFamily = `${appFontFamily}, sans-serif`;

    return function reset() {
      document.body.style.fontFamily = "inherit";
    };
  }, [selectedTheme.properties.fontFamily.appFont]);
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <EditorContextProvider renderMode="PAGE">
          <WidgetGlobaStyles
            fontFamily={selectedTheme.properties.fontFamily.appFont}
            primaryColor={selectedTheme.properties.colors.primaryColor}
          />
          <HtmlTitle
            description={pageDescription}
            name={currentApplicationDetails?.name}
          />
          <AppViewerBodyContainer
            backgroundColor={pageBgColor ||selectedTheme.properties.colors.backgroundColor}
          >
            <AppViewerBody
              className={CANVAS_SELECTOR}
              hasPages={pages.length > 1}
              headerHeight={headerHeight}
              ref={focusRef}
              showGuidedTourMessage={showGuidedTourMessage}
            >
              {isInitialized && registered && <AppViewerPageContainer />}
            </AppViewerBody>
          </AppViewerBodyContainer>
        </EditorContextProvider>
      </ThemeProvider>
    </>
  );
}

export default withRouter(Sentry.withProfiler(ViewerCanvas));
