import {Button, Form, Input, message, Select, Spin} from 'antd'
import React, {forwardRef, useImperativeHandle, useState} from 'react'

import FormItem from "antd/es/form/FormItem";
import {ModalWrapper} from "@byk/pages/AppDeploy/styled";

const formItemLayout = {
  labelCol: {span: 5},
  wrapperCol: {span: 15},
};

const loadingStyle = {
  width: '468px',
  zIndex: 1,
  height: '155px',
  display: 'inline-block',
  lineHeight: '155px'
}

const PublishModal: React.FC<any> = forwardRef((
  props, ref) => {
  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSave, setIsSave] = useState(false)

  const showModal = async () => {
    form.resetFields()
    setIsSave(false)
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onConfirm = () => {
    setIsSave(true)
    form.validateFields().then(
      async (values: any) => {
        values.appId = props.appId.join();
        let res: any = await props.appResObj.publish(values)
        if (res.success) {
          message.success('保存成功')
          props.refreshTable && props.refreshTable()
          setIsModalOpen(false)
          setIsSave(false)
        } else {
          message.error(res.message)
          setIsSave(false)
        }
      }
    ).catch(() => {
      setIsSave(false)
    })
  }

  return (
    <>
      <ModalWrapper
        forceRender={true}
        footer={null}
        title={'发布信息'}
        open={isModalOpen}
        onCancel={handleCancel}
        width={480}
        style={{top: '20px', bottom: '20px',}}
        bodyStyle={{height: 175, padding: "10px"}}
      >
        <div>
          {isSave && <Spin style={{...loadingStyle, position: "absolute"}}/>}
          <Form form={form}>
            <FormItem
              {...formItemLayout}
              label="标题"
              name='title'
              rules={[{required: true, message: "请输入标题!"}]}
            >
              <Input/>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="发布类型"
              name='deployType'
              rules={[{required: true, message: "请输入发布类型!"}]}
            >
              <Select
                style={{margin: 0}}
                showArrow={false}
                options={[
                  {label: '数据发布', value: '1',},
                  // { label: 'CICD 流水线发布', value: '2', },
                  // { label: '源码发布', value: '3', },
                ]}
              />
            </FormItem>
          </Form>

          <Button
            type={"primary"} style={{float: "right", margin: '5px'}}
            onClick={onConfirm}
            disabled={isSave}
          >
            确定
          </Button>
        </div>
      </ModalWrapper>
    </>
  )
})

export default PublishModal
