export default function Config ():string{
  return `
interface XState {
  data: Record<string, any>
}
const initState: XState = {
  data: {
    "_mark": 'byk_data'
  }
}

export const X_SET_BYK = 'X_SET_BYK';
export const X_SET_BYK_ACTION = 'X_SET_BYK_ACTION';

export default function XReducers(state=initState, action: any) {
  const {type, payload} = action;
  switch (type) {
    case X_SET_BYK:
      return {
        data: {
          ...state.data,
          ...payload.data
        }
      }
    default:
      return state
  }
}

  `;
}
