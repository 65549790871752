import {AtGrid} from "taro-ui";
import React from "react";

export default function Config ():string{
  return `import {AtGrid} from "taro-ui";
import React from "react";
import {useSelector} from "react-redux";
import XBase from "./XBase";
import _ from "lodash";

function XGrid(props: any) {
  const { propertyPath, style } = props;
  const widgetData = useSelector((state:any)=>{
    let dataTree = state.dataTreeReducer.dataTree;
    let widget = _.get(dataTree, propertyPath);
    widget = {
      ...widget,
      currentData: widget.currentData.map((i:any)=>{
        return {
          ...i,
          image: i.url,
          value: i.description
        }
      })
    }
    return widget;
  });
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <AtGrid customStyle={customStyle} columnNum={widgetData.columnNum} data={widgetData.currentData} />
    </XBase>)
}
export default XGrid;
  `;
}
