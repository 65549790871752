export default function Config ():string{
  return `
import { combineReducers } from "redux";
import Toast from './toast';
import Byk from './byk';
import DataTree from './dataTree';
const entityReducer = combineReducers({
  toastReducer: Toast,
  bykReducer: Byk,
  dataTreeReducer: DataTree
});

export default entityReducer;

  `;
}
