export default function Config ():string{
  return `import {AtProgress} from "taro-ui";
import React from "react";
import XBase from "./XBase";

function XNavBar(props: any) {
  const { propertyPath, color, isHidePercent, percent, style } = props;
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <AtProgress customStyle={customStyle} color={color} isHidePercent={isHidePercent} percent={percent} />
    </XBase>)
}
export default XNavBar;
  `;
}
