import { Checkbox, Col, Collapse, Form, Input, Radio, Row, Select } from "antd";
import SketchPicker from "./SketchPicker";
import React, { useEffect,  } from "react";
import {borderOpt, fontWeightOpt} from './StyleOption';
import {XCheckbox, XTitle} from './TopStyles';

const LeftStyles = (props:any)=>{
  const {layout, defaultValues, onValuesChangeLeft} = props;
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  useEffect(()=>{
    form.setFieldsValue({
      left: {
        width: '200',
        height: '30',
        fontSize: '14',
        childFontSize: '14',
        selectFontSize: '14',
        ...defaultValues.left
      }
    })
  }, [defaultValues]);
  function onValuesChange(values:any){
    onValuesChangeLeft(values);
  }
  return (
    <Form form={form}  layout="vertical" name="left" onValuesChange={onValuesChange}>
      <Collapse defaultActiveKey={['1']} ghost expandIconPosition={"end"}>
        <Panel header="父菜单" key="1" forceRender={true}>
          <Form.Item name={['left', 'width']} label="导航宽度" labelAlign="left" style={{marginBottom: 0}}>
            <Input suffix="px" />
          </Form.Item>

          <Row style={{ alignItems: 'center',marginTop:"15px"  }}>
            <Col span={24}>背景色</Col>
          </Row>
          <Row style={{ alignItems: 'center',marginTop:"5px" }}>
            <Col span={3}>
              <Form.Item name={['left', 'isBgColor']} label="" style={{ marginBottom: 0 }}>
                <XCheckbox />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['left', 'backgroundColor']} label="" style={{ marginBottom: 0 }}>
                <SketchPicker />
              </Form.Item>
            </Col>
            <Col span={17}></Col>
          </Row>
          <Form.Item name={['left', 'height']} label="菜单高度" labelAlign={'left'} style={{margin: '10px 0 0 0'}}>
            <Input suffix="px" />
          </Form.Item>
          <Form.Item name={['left', 'fontColor']} label="字体颜色"  style={{ marginBottom: 0, marginTop: "15px" }}>
            <SketchPicker />
          </Form.Item>
          <Form.Item name={['left', 'fontSize']} label="字体大小" style={{marginBottom: 0, marginTop: "15px"}}>
            <Input style={{width: '100%'}} suffix="px" />
          </Form.Item>
          <Form.Item name={['left', 'fontWeight']} label="粗体/斜体" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Radio.Group   optionType="button" buttonStyle="outline"  className="x-radio-group2" style={{width:"100%"}}
              options={fontWeightOpt}>
            </Radio.Group>
            {/* <Select
              defaultValue="normal"
              style={{ width: '100px' }}
              options={fontWeightOpt}
            /> */}
          </Form.Item>
        </Panel>
        <Panel header="子菜单" key="2" forceRender={true}>
          <Form.Item name={['left', 'childWidth']} label="菜单高度" labelAlign="left" style={{marginBottom: 0}}>
            <Input suffix="px" />
          </Form.Item>

          <Row style={{ alignItems: 'center',marginTop:"15px"  }}>
            <Col span={24}>背景色</Col>
          </Row>
          <Row style={{ alignItems: 'center',marginTop:"5px" }}>
            <Col span={3}>
              <Form.Item name={['left', 'childIsBgColor']} label="" style={{ marginBottom: 0 }}>
                <XCheckbox />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['left', 'childBackgroundColor']} label="" style={{ marginBottom: 0 }}>
                <SketchPicker />
              </Form.Item>
            </Col>
            <Col span={17}></Col>
          </Row>
          
          <Form.Item name={['left', 'childFontColor']} label="字体颜色"  style={{ marginBottom: 0, marginTop: "15px" }}>
            <SketchPicker />
          </Form.Item>
          <Form.Item name={['left', 'childFontSize']} label="字体大小" style={{marginBottom: 0, marginTop: "15px"}}>
            <Input style={{width: '100%'}} suffix="px" />
          </Form.Item>
          <Form.Item name={['left', 'childFontWeight']} label="粗体/斜体" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Radio.Group   optionType="button" buttonStyle="outline"  className="x-radio-group2" style={{width:"100%"}}
              options={fontWeightOpt}>
            </Radio.Group>
          </Form.Item>

        </Panel>
        <Panel header="选中项" key="3" forceRender={true}>
          <Row style={{ alignItems: 'center',marginTop:"15px"  }}>
            <Col span={24}>背景色</Col>
          </Row>
          <Row style={{ alignItems: 'center',marginTop:"5px" }}>
            <Col span={3}>
              <Form.Item name={['left', 'selectIsBgColor']} label="" style={{ marginBottom: 0 }}>
                <XCheckbox />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['left', 'selectBackgroundColor']} label="" style={{ marginBottom: 0 }}>
                <SketchPicker />
              </Form.Item>
            </Col>
            <Col span={17}></Col>
          </Row>

          <Form.Item name={['left', 'selectFontColor']} label="字体颜色"  style={{ marginBottom: 0, marginTop: "15px" }}>
            <SketchPicker />
          </Form.Item>
          <Form.Item name={['left', 'selectFontSize']} label="字体大小" style={{marginBottom: 0, marginTop: "15px"}}>
            <Input style={{width: '100%'}} suffix="px" />
          </Form.Item>
          <Form.Item name={['left', 'selectFontWeight']} label="粗体/斜体" style={{ marginBottom: 0, marginTop: "15px" }}>
            <Radio.Group   optionType="button" buttonStyle="outline"  className="x-radio-group2" style={{width:"100%"}}
              options={fontWeightOpt}>
            </Radio.Group>
          </Form.Item>

        </Panel> 
      </Collapse>
      

      {/* <div style={{margin: '10px 0',borderTop: '1px solid #ccc'}} />
      <XTitle>选中项</XTitle>
      <Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['left', 'selectIsBgColor']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['left', 'selectBackgroundColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>选中背景色</Col>
      </Row>
      <div>文本</div> */}
      {/* <Row>
        <Col span={3}>
          <Form.Item name={['left', 'selectFontColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['left', 'selectFontSize']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['left', 'selectFontWeight']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="normal"
              style={{width:'100px'}}
              options={fontWeightOpt}
            />
          </Form.Item>
        </Col>
      </Row> */}

      {/* <div>文本</div>
      <Row>
        <Col span={3}>
          <Form.Item name={['left', 'childFontColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['left', 'childFontSize']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['left', 'childFontWeight']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="normal"
              style={{width:'100px'}}
              options={fontWeightOpt}
            />
          </Form.Item>
        </Col>
      </Row> */}

      {/* <div style={{margin: '10px 0',borderTop: '1px solid #ccc'}} />
      <XTitle>子菜单</XTitle>
      <Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['left', 'childIsBgColor']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['left', 'childBackgroundColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>背景色</Col>
      </Row>
      <Form.Item name={['left', 'childWidth']} label="菜单高度" labelAlign={'left'} style={{margin: '10px 0 0 0'}}>
        <Input suffix="px" />
      </Form.Item> */}
 
            

      {/* <Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['left', 'isBgColor']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['left', 'backgroundColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>背景色</Col>
      </Row> */}
      
      {/* <div>文本</div>
      <Row>
        <Col span={3}>
          <Form.Item name={['left', 'fontColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['left', 'fontSize']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['left', 'fontWeight']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="normal"
              style={{width:'100px'}}
              options={fontWeightOpt}
            />
          </Form.Item>
        </Col>
      </Row> */}
      
    </Form>
  )
};
export default LeftStyles;
