import React, { useState } from "react";
import { Button, Form, Input, Tabs, message, Divider } from "antd";

import Api from "@byk/api/Api";
import history from "utils/history";
import localStorage, {getAppId, getProjectId} from "utils/localStorage";
import { EditOutlined } from "@ant-design/icons";
import XModal from "@byk/pages/components/XModal";
import {appTenantClass} from "@byk/utils/Utils";

export default class extends React.Component {
  formRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.formRef = React.createRef();
    this.modalRef = React.createRef();
  }
  state = {
    isModalOpen: false,
    setIsModalOpen: false,
    title: "新增应用",
    appData: [],
    projectId: getProjectId(),
    subAppData: [],
    defaultSubId: getAppId(),
    defaultSubData: {},
    defaultConfigId: null,
    files: [{ code: 1, filename: "a.exe" }, { code: 2, filename: "b.exe" }],
    formValueObj: {},
    isEdit: false,
    Url: "",
    previewUrl: ""
  };
  showModal = () => {
    this.setState({ isModalOpen: true });
  };
  handleOk = () => {
    this.setState({ isModalOpen: false });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  apiGet: string = "/byk/platform/rest/SysApplication/list";
  apiPost: string = "/byk/platform/rest/SubApplication";
  apiPut: string = "/byk/platform/rest/SubApplication";
  apiDel: string = "/byk/platform/rest/SubApplication";
  apiAppConfigGet: string = "/byk/platform/rest/AppConfig/list";
  apiAppUrlGet: string = "/byk/platform/subapp/admin/getEngineAppUrl";
  apiAppConfigPost: string = "/byk/platform/rest/AppConfig";
  apiAppConfigPut: string = "/byk/platform/rest/AppConfig";
  apiAppConfigDel: string = "/byk/platform/rest/AppConfig";

  onFinish = async (values: any) => {
    values.appId = this.state.defaultSubId;
    let result1: any = null;

    if (this.state.defaultConfigId != null) {
      values.id = this.state.defaultConfigId;
      delete values.appId;
      result1 = await Api.put(this.apiAppConfigPost, values);
    }
    else {
      delete values.id;
      result1 = await Api.post(this.apiAppConfigPost, values);
    }

    if (result1.success) {
      message.success("保存成功!");
    }
  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  componentDidMount() {
    let id = this.state.projectId;
    this.setState({ projectId: id });
    this.refreshTable(id);
  }
  refreshTable = async (params?: any) => {
    let defaultSubId = this.state.defaultSubId;
    let result: any = await Api.get(this.apiGet, { id: defaultSubId });
    if (result.success) {
      this.setState({ subAppData: result.result });
      if (result.result.length > 0) {
        this.setState({ defaultSubData: result.result[0] });
        //this.setState({ defaultSubId: result.result[0].id });
        this.bindForm(defaultSubId);
        this.bindUrl(defaultSubId);
      }
    }
    console.log("subAppData", this.state.subAppData);
  };
  redirectUrl = (record: any) => {
    history.push(`/ApplicationConfig?id=${record.id}`);
  };
  setFormValue = (key: string) => {
    this.setState({ defaultSubId: key });
  };
  onChange = (key: string) => {
    this.setState({ defaultSubId: key });
    let _d = this.state.subAppData.filter((item: any) => {
      return item.id == key;
    });
    this.setState({ defaultSubData: _d[0] });
    this.bindForm(key);
    //form.resetFields()
  };
  bindUrl = async (key: string) => {
    let result: any = await Api.get(this.apiAppUrlGet, { appId: key });
    if (result.success) {
      this.setState({ Url: result.url, previewUrl: result.previewUrl })
    }
  }
  bindForm = async (key: string) => {
    //清空表单
    this.formRef.current?.resetFields();
    let result: any = await Api.get(this.apiAppConfigGet, { appId: key });
    if (result.success) {
      let formValue: any = {};
      if (result.result.length > 0) {
        let _d = result.result[0];
        //let { repositoryAddress, dbAddress, dbUser, dbPassword, appPath,certificatePath } = _d;
        this.setState({ defaultConfigId: _d["id"] });
        formValue["name"] = this.state.defaultSubData.name;
        formValue["repositoryAddress"] = _d["repositoryAddress"];
        formValue["dbAddress"] = _d["dbAddress"];
        formValue["dbUser"] = _d["dbUser"];
        formValue["dbPassword"] = _d["dbPassword"];
        formValue["appPath"] = _d["appPath"];
        formValue["id"] = _d["id"]
        this.setState({ formValueObj: formValue })
        // formValue["certificatePath"] = _d["certificatePath"];
      }
      else {
        formValue["name"] = this.state.defaultSubData.name;
        this.setState({ defaultConfigId: null });
      }
      this.formRef.current?.setFieldsValue(formValue);
    }

  }
  //formRef = react.Ref();
  render() {
    const setUploadData = (data: any) => {
      //this.formRef.current.set
      //this.formRef.current.setFieldsValue(data);
      // logoRef.current =isClear?"": url;
      // save();
    }
    //改造提交的data
    const reformData = (params?: any, type?: any) => {
      if (type == 0) {
        params.tenantId = this.state.TenantId;
      }
      params.roleCode = params.projectJob;
      return params;
    };
    //编辑按钮
    const EditClick = () => {
      this.modalRef.current?.edit({
        ...this.state.formValueObj
      });
    };
    //新增表单项
    const ModalConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "应用名称",
        allowClear: true,
        col: 24,
        labelCol: 4,
        maxLength: 100,
        rules: [{ required: false, message: "请输入!" }],
        disabled: true
      },
      {
        itemType: "textarea",
        placeholder: "请输入",
        name: "repositoryAddress",
        label: "代码库",
        allowClear: true,
        col: 24,
        labelCol: 4,
        maxLength: 100,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "textarea",
        placeholder: "请输入",
        name: "dbAddress",
        label: "数据库地址",
        allowClear: true,
        col: 24,
        labelCol: 4,
        maxLength: 100,
        height: 100,
        disabled: true
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "dbUser",
        label: "数据库用户",
        allowClear: true,
        col: 24,
        labelCol: 4,
        maxLength: 100,
        disabled: true
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "dbPassword",
        label: "数据库密码",
        allowClear: true,
        maxLength: 200,
        col: 24,
        labelCol: 4,
        disabled: true
      }
    ];
    //modal 表单配置
    const modalProps = {
      apiPost: this.apiAppConfigPost,
      apiPut: this.apiAppConfigPut,
      option: ModalConfig,
      refreshTable: this.refreshTable,//:()=>{this.refreshTable},
      isShowAdd: false,
      reformData,
      editTitle: "编辑数据库配置",
      propsExt: { width: 900 }
    };
    const setLogoRef = (url: string, isClear: boolean = false) => {
      // logoRef.current =isClear?"": url;
      // save();
    }
    return (
      <div className="x-main-box">
        <div style={{ width: "100%", position: "relative" }}>
          <p className="ant-page-header-heading-title">数据库配置</p>
          <Button style={{ position: "absolute", top: "0px", left: "90px" }} onClick={EditClick} icon={<EditOutlined />} type="link">编辑</Button>
        </div>
        <Form
          ref={this.formRef}
          name="form"
          style={{ marginTop: "20px" }}
          //form={form}
          size="small"
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          initialValues={{
            sysApps: [{ name: "", size: "", frameStyle: "" }], // 默认有一行
          }}
          autoComplete="off"
        >
          <Form.Item
            label="应用名称"
            name="name"
            rules={[{ required: false, message: "请输入!" }]}
          >
            {this.state.formValueObj["name"]}
            {/* <Input disabled={true} /> */}
          </Form.Item>
          <Form.Item
            label="代码库"
            name="repositoryAddress"
            rules={[{ required: false, message: "请输入!" }]}
          >
            {this.state.formValueObj["repositoryAddress"]}
            {/* <Input /> */}
          </Form.Item>
          <Form.Item
            label="数据库地址"
            name="dbAddress"
            rules={[{ required: false, message: "请输入!" }]}
          >
            {this.state.formValueObj["dbAddress"]}
            {/* <Input disabled /> */}
          </Form.Item>
          <Form.Item
            label="数据库用户"
            name="dbUser"
            rules={[{ required: false, message: "请输入!" }]}
          >
            {this.state.formValueObj["dbUser"]}
            {/* <Input disabled/> */}
          </Form.Item>
          <Form.Item
            label="数据库密码"
            name="dbPassword"
            rules={[{ required: false, message: "请输入!" }]}
          >
            {this.state.formValueObj["dbPassword"]}
            {/* <Input disabled/> */}
          </Form.Item>
          {/* <Form.Item
            label="应用目录地址"
            name="appPath"
            rules={[{ required: false, message: "请输入!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="应用证书配置"
            name="certificatePath"
            rules={[{ required: false, message: "请输入!" }]}
          >
            <Input />
          </Form.Item> */}
          {/* <Form.Item label="上传附件" name="data"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <UploadWidget onEmit={(data: string) => {
                      setUploadData(data);
                    }} onEmitClear={() => {
                      setLogoRef("", true);
                    }}  accept={".jpg,.jpeg,.png"}  files={this.state.files}  fileSize={2}  fileCount={5} btnText={"点击上传文件"}/>

          </Form.Item> */}
          {/* <Form.Item label="上传附件" name="data"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <UploadImageWidget onEmit={(data: string) => {
                      setUploadData(data);
                    }} onEmitClear={() => {
                      setLogoRef("", true);
                    }}  accept={".jpg,.jpeg,.png"}  files={[]}  fileSize={2}  fileCount={5} btnText={"点击上传文件"}/>

          </Form.Item> */}
          {/* <div style={{ width: "100%", textAlign: "center" }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button className="x-ml10" onClick={this.handleCancel}>发布应用</Button>
          </div> */}
        </Form>
        <Divider />
        {/*<div style={{ width: "100%", position: "relative" }}>
          <p className="ant-page-header-heading-title">环境配置</p>
          <p style={{ fontSize: "12px", color: "#969696", marginTop: "8px" }}>应用发布成功后，生成的预览地址</p>
          <Form
            ref={this.formRef}
            name="form1"
            size="small"
            style={{ marginTop: "20px" }}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            autoComplete="off"
          >
            <Form.Item
              label="应用发布预览地址"
              name="name"
            >
              <a target="_blank" href={this.state.Url}>
                <Button type="link">{this.state.Url}</Button>
              </a>
            </Form.Item>
            <Form.Item
              label="应用开发预览地址"
              name="name1"
            >
              <a target="_blank" href={this.state.previewUrl}>
                <Button type="link">{this.state.previewUrl}</Button>
              </a>
            </Form.Item>
          </Form>
        </div>*/}
        <XModal ref={this.modalRef} {...modalProps} />
      </div>
    );
  }
}


