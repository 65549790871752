import React, {forwardRef, useImperativeHandle} from "react";
import {Button, Divider, Upload} from "antd";
import {TrackContextFn} from "@byk/pages/Track/index";
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";
import styled from "styled-components";
import {ContentPageFlagType, StateType} from "@byk/pages/Track/constant";
import moment from "moment";
import {issuesApi} from "@byk/pages/Track/trackApi";
import TrackForm from "@byk/pages/Track/TrackForm";
import {model} from "@byk/pages/Track/model";
import TrackFormComment from "@byk/pages/Track/TrackFormComment";

const DetailContainer = styled.div`
  margin-right: 27px;
  padding-left: 25px;

  .headerBar {
    display: flex;
    justify-content: space-between;

    .summary {
      height: 41px;
      color: rgba(9, 7, 7, 1);
      font-size: 28px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
    }

    button {
      margin-left: 12px;
      height: 40px;
      .iconPark {
        height: 13px;
      }
    }

    .editBtn  {
      color: #006CF2 !important;
      border-color: #006CF2 !important;
    }

    .closeBtn  {
      color: red !important;
      border-color: red !important;
    }
  }

  .subHeader {
    display: flex;
    margin-top: 16px;
    font-family: SourceHanSansSC-regular;

   .created {
      height: 20px;
      color: rgba(146, 146, 146, 1);
   }

   .type {
      margin-left: 15px;
      padding-left: 25px;
      border-left: solid 1px #CFCFCF;
      height: 20px;
      color: rgba(9, 7, 7, 1);
   }

   .state {
      margin-left: 22px;
      padding-left: 16px;
      border-left: solid 1px #CFCFCF;
      height: 20px;
      color: rgba(9, 7, 7, 1);
   }
  }

  .description {
    margin: 18px 0;
    color: #090707;
    font-family: SourceHanSansSC-regular;
  }

  .files {
    .ant-btn {
       padding: 0px !important;
     }

     button[title='Remove file'] {
        display: none;
      }
  }
`

const CommentContainer = styled.div`
   margin: 21px 0 21px 45px;

  .header {
    padding-left: 9px;
    border-left: 6px solid #006CF2;
    color: rgba(16, 16, 16, 1);
    font-size: 16px;
    font-family: SourceHanSansSC-medium;
  }

  .btnBar {
    margin: 20px 0;

    .ant-btn {
      margin: 20px 0px;
      width: 113px;
      height: 40px;
      border-radius: 4px!important;
    }
  }

  .commentItem {
    .itemHeader {
      display: flex;
      color: #717171;
      margin: 20px 0px;
      align-items: center;

      .itemHeaderText {
        margin: 0px 9px 0px 6px;
      }
    }

    .commentItemContent {
      background: #F0F0F0;
      border-radius: 10px;
      padding: 24px;
      margin-right: 30px;
      position: relative;

      button[title='Remove file'] {
        display: none;
      }

      .commentItemEdit {
        position: absolute;
        right: 13px;
        top: 15px;
        color: #006CF2;

        .ant-btn, .ant-btn:active, .ant-btn:focus {
          outline: 0;
          width: 113px;
          height: 40px;
        }
      }
    }
  }
`

const TrackDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({}));
  const {setContentPageFlag, issue, closeIssue} = TrackContextFn();
  const isClose = issue?.state === StateType.Close;
  const [editFlag, setEditFlag] = React.useState(false);
  const trackFormRef = React.useRef<any>();
  const trackFormCommentRef = React.useRef<any>();
  const [attachments, setAttachments] = React.useState<any[]>(issue.attachments);
  const [commentList, setCommentList] = React.useState<any[]>(issue.comments);
  const issueFormData = model.convertIssueFormData(issue);

  const goBack = () => {
    setContentPageFlag(ContentPageFlagType.LIST);
  }

  const openEdit = () => {
    setTimeout(() => {
      trackFormRef?.current.openEdit(issueFormData);
    }, trackFormRef?.current ? 0 : 300);
    setEditFlag(true);
  }

  const openComment = (comment: any = {}) => {
    function _openComment(draftId: any, comment: any) {
      setTimeout(() => {
        trackFormCommentRef?.current.openComment(draftId, comment);
      }, trackFormCommentRef?.current ? 0 : 300);
    }

    if (comment.id) { // 编辑
      _openComment(null, comment);
    } else { // 新建
      issuesApi.getIssueDraftCommentId(issue.id).then((draftId: any) => {
        _openComment(draftId, comment);
      })
    }

  }

  const closeEdit = () => {
    setEditFlag(false);
  }

  return (<>
      <div className='breadcrumb'><Button icon={getIconPark('Left')} onClick={goBack}>返回</Button>工单详情</div>
      <DetailContainer>
        <div className='headerBar'>
          <div className='summary'>{issue?.summary}</div>
          {!isClose && <div>
            <Button icon={getIconPark('Edit')} disabled={isClose} className='editBtn' onClick={openEdit}>编辑工单</Button>
            <Button icon={getIconPark('CloseOne')}  disabled={isClose} className='closeBtn'
                    onClick={() => closeIssue(issue.id)}>关闭工单</Button>
          </div>
          }
        </div>

        <div className='subHeader'>
          <div className='created'>{issue?.created && moment(issue.created).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div className='type'>{issue?.type}</div>
          <div className='state'>{issue?.state}</div>
        </div>
        <div className='description'>
          {issue?.description}
        </div>

        <div className='files'>
          {attachments &&
          <Upload listType='picture-card' fileList={model.getFileList4Attachments(attachments)}/>
          }
        </div>
      </DetailContainer>

      {editFlag &&
      <TrackForm ref={trackFormRef} isModal={true} data={issueFormData} closeEdit={closeEdit}
                 setAttachments={setAttachments}/>}
      <Divider/>
      <CommentContainer>
        <div className='header'>回复信息</div>
        {commentList && commentList.map((item: any) => {
          let attachments = item.attachments;
          return <div className='commentItem'>
            <div className='itemHeader'>
              {getIconPark('Comments')}
              <div className='itemHeaderText'>{item.author.name == 'admin' ? '回复' : '问题补充'}</div>
              <div className='itemHeaderText'>{item.author.name == 'admin' ? 'admin' : '我的'}</div>
              <div
                className='itemHeaderTextCreated'>{item.created && moment(item.created).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
            <div className='commentItemContent'>
              {/*<div className='commentItemEdit'>*/}
              {/*  <Button type="link" icon={getIconPark('Edit')} onClick={() => {*/}
              {/*    openComment(item)*/}
              {/*  }}>编辑</Button>*/}
              {/*</div>*/}

              <div>{item.text}</div>
              {attachments &&
              <Upload listType='picture-card' fileList={model.getFileList4Attachments(attachments)}/>
              }
            </div>

          </div>
        })}
        <div className='btnBar'>
          <Button type="primary" onClick={openComment}>补充问题</Button>
        </div>
      </CommentContainer>


      <TrackFormComment ref={trackFormCommentRef} setAttachments={setAttachments} setCommentList={setCommentList}/>
    </>
  )
});

export default TrackDetail;
