import { isString } from "lodash";
import { isDynamicValue } from "utils/DynamicBindingUtils";

const inputValueToString = (inputValue:any)=>{
  inputValue = inputValue??'';
  if(typeof inputValue == 'object'){
    inputValue = JSON.stringify(inputValue);
  }
  return isDynamicValue(inputValue)
}

export const showBindingPrompt = (
  showEvaluatedValue: boolean,
  inputValue: any,
  isHinterOpen: boolean,
): boolean => {
  const isDynamicInputValue = inputValue && inputValueToString(inputValue);
  const lastCharacterOfSlash =
    inputValue && isString(inputValue) && inputValue.slice(-1);
  return showEvaluatedValue && (!isString(inputValue) || (!inputValue && !isHinterOpen) || (!isDynamicInputValue && lastCharacterOfSlash !== "/"));
};
