import React, {useEffect, useState} from 'react';
import { Tree } from 'antd';
import {defaultPageIcon, loginPageIcon, pageIcon} from "../ExplorerIcons";
import {hasManagePagePermission} from "../../../../ce/utils/permissionHelpers";
import {setCookie} from "../../../../utils/CookieUtils";
import {EntityClassNames} from "../Entity";
import {StyledEntity} from "./index";
import {resolveAsSpaceChar} from "../../../../utils/helpers";
import {updatePage, updatePageSort} from "../../../../actions/pageActions";
import PageContextMenu from "./PageContextMenu";
import _ from "lodash";
import {useDispatch} from "react-redux";
import styled from 'styled-components';
const { TreeNode } = Tree;
const TreeWrapper = styled.div`
  .ant-tree-draggable-icon{ display: none; }
  .ant-tree .ant-tree-treenode{ width: 100%; }
  .ant-tree .ant-tree-node-content-wrapper{ flex: 1;}
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{ background: none; }
  .ant-tree-switcher{ line-height: 36px; }
`;
const noDragPageSlug = ['frameDesignPage', 'homePage']
const PageGroup = (props:any) => {
  const dispatch = useDispatch();
  const {pages} = props;
  const [treeData, setTreeData] = useState([]);
  const [noDragPageData, setNoDragPageData] = useState([]);
  useEffect(()=>{
    const frameAndHomePage = pages.filter((i:any)=>{
      return noDragPageSlug.includes(i.slug);
    });
    const otherPage = pages.filter((i:any)=>{
      return !noDragPageSlug.includes(i.slug);
    });
    setNoDragPageData(frameAndHomePage);
    setTreeData(otherPage);
  }, [pages])

  const onDrop = (info:any) => {
    if(info.node.type === 'Page'&&!info.dropToGap){
      return false;
    }
    const dragNode = info.dragNode;
    const dropNode = info.node;
    const dropPos = dropNode.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    let params = {
      id: dragNode.pageId,
      parent: dropPosition==0?dropNode.pageId:dropNode.parent,
      sort: dropNode.sort,
      sorts: 0
    };
    dispatch(updatePageSort(params));
  };


  const renderTreeNodes = (data:any) => {
    return data.map((item:any) => {
      if (item.children) {
        return (
          <TreeNode {...item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item}/>;
    });
  };

  const onTitleRender = (page:any) => {
    const icon = page.isDefault ? defaultPageIcon : (page.isLoginPage ? loginPageIcon : pageIcon);
    const isCurrentPage = props.currentPageId == page.pageId;
    const pagePermissions = page.userPermissions;
    const canManagePages = hasManagePagePermission(pagePermissions);
    if (isCurrentPage) {
      setCookie("pageId_" + props.applicationId + "_" + localStorage.getItem('userId'), page.pageId);
    }
    const contextMenu = (
      <PageContextMenu
        applicationId={props.applicationId as string}
        className={EntityClassNames.CONTEXT_MENU}
        isDefaultPage={page.isDefault}
        isLoginPage={page.isLoginPage}
        isHidden={!!page.isHidden}
        key={page.pageId + "_context-menu"}
        name={page.pageName}
        pageId={page.pageId}
        pageType={page.type}
      />
    );

    return (
      <StyledEntity
        action={() => props.switchPage(page)}
        active={isCurrentPage}
        canEditEntityName={canManagePages}
        className={`page ${isCurrentPage && "activePage"}`}
        contextMenu={page.slug == 'frameDesignPage' ? null : contextMenu}
        disabled={page.isHidden}
        entityId={page.pageId}
        icon={icon}
        isDefaultExpanded={isCurrentPage}
        key={page.pageId}
        name={page.pageName}
        onNameEdit={resolveAsSpaceChar}
        searchKeyword={""}
        step={1}
        updateEntityName={(id, name) =>
          updatePage({id, name, isHidden: !!page.isHidden})
        }
      />
    );
  };
  return (
    <TreeWrapper>
      <Tree
        defaultExpandAll={true}
        titleRender={onTitleRender}
      >
        {noDragPageData?.length && renderTreeNodes(noDragPageData)}
      </Tree>
      <Tree
        defaultExpandAll={true}
        draggable
        onDrop={onDrop}
        titleRender={onTitleRender}
      >
        {treeData?.length && renderTreeNodes(treeData)}
      </Tree>
    </TreeWrapper>
  );
};

export default PageGroup;


