export default function Config ():string{
  return `import {all, call, takeLatest} from "redux-saga/effects";
import {ReduxActionTypes} from "../constants/ReduxActionConstants";
import Taro from "@tarojs/taro";
interface ToastType {
  type: string,
  message: string
}
export const toast = (reg:ToastType)=>{
  Taro.showToast({
    title: reg.message||'no title',
    icon: reg.type == 'info'?'none':reg.type, // 可以是 'success'、'loading' 'none' 'error'
    duration: 2000, // 显示时长，默认为 1500 毫秒
    mask: true, // 是否显示遮罩，默认为 false
    success: function () {
      console.log('toast 已经显示')
    },
    fail: function () {
      console.log('toast 显示失败')
    },
    complete: function () {
      console.log('toast 显示结束')
    }
  })
}
export function* showAlert(action:any): any {
  console.log("showAlert的参数》》》", action);
  yield call(toast, action.payload);
}

export function* watchShowAlert() {
  yield all([
    takeLatest(
      ReduxActionTypes.X_SHOWALERT,
      showAlert
    ),
  ]);
}


  `;
}
