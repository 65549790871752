import {NotebookStepUiComponentProps} from "@byk/pages/QueryBuilder/components/notebook/types";
import React, {Fragment, useRef} from "react";
import {
  JoinClauseContainer,
  JoinClauseRoot,
  JoinClausesContainer,
  JoinConditionLabel,
  JoinDimensionControlsContainer,
  JoinStepRoot,
  JoinWhereConditionLabel,
  JoinWhereConditionLabelContainer,
  PrimaryJoinCell,
  RemoveJoinIcon,
  Row,
  SecondaryJoinCell
} from "./JoinStep.styled";
import Join from "@byk/pages/QueryBuilder/lib/queries/structured/Join";
import {NotebookCellAdd, NotebookCellItem} from "../../NotebookCell";
import JoinTypePicker from "@byk/pages/QueryBuilder/components/notebook/steps/JoinStep/JoinTypePicker";
import JoinTablePicker from "@byk/pages/QueryBuilder/components/notebook/steps/JoinStep/JoinTablePicker";
import JoinOptionsPicker from "@byk/pages/QueryBuilder/components/notebook/steps/JoinStep/JoinOptionsPicker";
import JoinDimensionPicker from "@byk/pages/QueryBuilder/components/notebook/steps/JoinStep/JoinDimensionPicker";

export const JOIN_OPERATOR_OPTIONS = [
  {label: "=", value: "="},
  {label: ">", value: ">"},
  {label: "<", value: "<"},
  {label: "≥", value: ">="},
  {label: "≤", value: "<="},
  {label: "≠", value: "!="},
];

const JoinStep: React.FC<NotebookStepUiComponentProps> =
  ({
     step,
     color,
     query,
     updateQuery,
     isLastOpened,
   }: NotebookStepUiComponentProps) => {
    const isSingleJoinStep = step.itemIndex != null;
    let joins = query.joins();

    if (isSingleJoinStep) {
      const join = joins[step.itemIndex];
      joins = join ? [join] : [];
    }

    if (joins.length === 0) {
      joins = [new Join({fields: "all"}, query.joins().length, query)];
    }

    return (
      <JoinStepRoot>
        <JoinClausesContainer>
          {joins.map((join, index) => {
            const isLast = index === joins.length - 1;
            return (
              <JoinClauseContainer key={index} isLast={isLast}>
                <JoinClause
                  join={join}
                  color={color}
                  showRemove={joins.length > 1}
                  updateQuery={updateQuery}
                />
              </JoinClauseContainer>
            );
          })}
        </JoinClausesContainer>
      </JoinStepRoot>
    );

  };
export default JoinStep;

interface JoinClausePropTypes {
  color: string;
  join: Join;
  showRemove: boolean;
  updateQuery: (query: any) => Promise<void>;
};

const JoinClause: React.FC<JoinClausePropTypes> =
  ({
     color,
     join,
     updateQuery,
     showRemove,
   }: JoinClausePropTypes) => {
    const joinDimensionPickersRef = useRef<any>([]);
    const parentDimensionPickersRef = useRef<any>([]);

    const query = join.query();
    if (!query) {
      return null;
    }

    const parentDimensions = join.parentDimensions();
    const parentDimensionOptions = join.parentDimensionOptions();
    const joinDimensions = join.joinDimensions();
    const joinDimensionOptions = join.joinDimensionOptions();

    const joinedTable = join.joinedTable();

    const joinConditions = join.getConditions();
    const displayConditions = joinConditions.length > 0 ? joinConditions : [[]];

    const hasAtLeastOneDimensionSelected = join.getDimensions().length > 0;

    let lhsTable;
    if (join.index() === 0) {
      lhsTable = join.parentTable();
    } else if (join.parentDimensions().length > 0) {
      lhsTable = join.parentDimensions()[0]?.field().table;
    }

    function onSourceTableSet(newJoin: Join) {
      if (!newJoin.parentDimensions().length) {
        setTimeout(() => {
          parentDimensionPickersRef.current[0]?.open();
        });
      }
    }

    async function onParentDimensionChange(index: any, fieldRef: any) {
      await updateQuery(
        join
          .setParentDimension({
            index,
            dimension: fieldRef,
          })
          .setDefaultAlias()
          .parent(),
      );
      if (!join.joinDimensions()[index]) {
        joinDimensionPickersRef.current[index]?.open();
      }
    }

    async function onJoinDimensionChange(index: any, fieldRef: any) {
      await updateQuery(
        join
          .setJoinDimension({
            index,
            dimension: fieldRef,
          })
          .parent(),
      );
    }

    async function addNewDimensionsPair(index: any) {
      await updateQuery(join.addEmptyDimensionsPair().parent());

      // Need to wait, so a new dimensions pair renders
      // and a corresponding ref is created, so we can reference it here
      setTimeout(() => {
        parentDimensionPickersRef.current[index]?.open();
      });
    }

    async function removeJoin() {
      await updateQuery(join.remove());
    }

    return (
      <JoinClauseRoot>
        <PrimaryJoinCell color={color}>
          <NotebookCellItem color={color}>
            {lhsTable?.description || "以前的结果"}
          </NotebookCellItem>

          <JoinTypePicker
            join={join}
            color={color}
            updateQuery={updateQuery}
          />

          <JoinTablePicker
            join={join}
            color={color}
            updateQuery={updateQuery}
            joinedTable={joinedTable}
            query={query}
            onSourceTableSet={onSourceTableSet}
          />
        </PrimaryJoinCell>

        {joinedTable && (
          <Fragment>
            <JoinWhereConditionLabelContainer>
              <JoinWhereConditionLabel/>
            </JoinWhereConditionLabelContainer>

            <SecondaryJoinCell
              color={color}
              padding={hasAtLeastOneDimensionSelected ? "8px" : undefined}
            >

              {displayConditions.map((condition, index) => {
                const isFirst = index === 0;
                const isLast = index === displayConditions.length - 1;
                const operator: any = condition[0] ?? JOIN_OPERATOR_OPTIONS[0];
                const operatorSymbol = JOIN_OPERATOR_OPTIONS.find(
                  o => o.value === operator.value,
                )?.label;

                async function removeParentDimension() {
                  await updateQuery(
                    join.setParentDimension({index, dimension: null}).parent(),
                  );
                }

                async function removeJoinDimension() {
                  await updateQuery(
                    join.setJoinDimension({index, dimension: null}).parent(),
                  );
                }

                async function removeDimensionPair() {
                  await updateQuery(join.removeCondition(index).parent());
                }

                async function updateOperator(value: any) {
                  await updateQuery(join.setOperator(index, value).parent());
                }

                return (
                  <JoinDimensionControlsContainer
                    key={index}
                    isFirst={isFirst}
                  >
                    <Row>
                      <JoinDimensionPicker
                        color={color}
                        query={query}
                        dimension={parentDimensions[index]}
                        options={parentDimensionOptions}
                        onChange={async (fieldRef) => {
                          await onParentDimensionChange(index, fieldRef)
                        }
                        }
                        onRemove={removeParentDimension}
                        ref={(ref: any) =>
                          (parentDimensionPickersRef.current[index] = ref)
                        }
                      />

                      <JoinConditionLabel>
                        <JoinOptionsPicker
                          color={color}
                          defaultValue={operator}
                          onChange={updateOperator}
                          join={join}
                        />
                      </JoinConditionLabel>
                    </Row>

                    <Row>
                      <JoinDimensionPicker
                        color={color}
                        query={query}
                        dimension={joinDimensions[index]}
                        options={joinDimensionOptions}
                        onChange={(options) =>
                          onJoinDimensionChange(index, options)
                        }
                        onRemove={removeJoinDimension}
                        ref={(ref: any) =>
                          (joinDimensionPickersRef.current[index] = ref)
                        }
                      />

                      {isLast ? (
                        <JoinDimensionsRightControl
                          isValidJoin={join.isValid()}
                          color={color}
                          isFirst={isFirst}
                          onAddNewDimensionPair={() =>
                            addNewDimensionsPair(index + 1)
                          }
                          onRemoveDimensionPair={removeDimensionPair}
                        />
                      ) : (
                        <JoinConditionLabel>{"和"}</JoinConditionLabel>
                      )}
                    </Row>
                  </JoinDimensionControlsContainer>
                )
              })}

            </SecondaryJoinCell>
          </Fragment>
        )}
      </JoinClauseRoot>
    );
  };

function JoinDimensionsRightControl(
  {
    isValidJoin,
    isFirst,
    color,
    onAddNewDimensionPair,
    onRemoveDimensionPair,
  }: any) {
  if (isValidJoin) {
    return (
      <NotebookCellAdd
        color={color}
        className="cursor-pointer ml-auto"
        onClick={onAddNewDimensionPair}
      />
    );
  }
  if (!isFirst) {
    return (
      <RemoveJoinIcon
        onClick={onRemoveDimensionPair}
      />
    );
  }
  return null;
}
