import React from "react";
import {getTenant} from "../../../../utils/localStorage";

export default class extends React.Component {
  constructor(props: any) {
    super(props);
  }
  state = {
    id: "",
    url:'/byk/platform/mxgraph/index.html'
  };
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    if(id!=null){
      this.setState({url:`/byk/platform/mxgraph/index.html?id=${id}`});
    } else {
      this.setState({url:`/byk/platform/mxgraph/index.html?tenant=${getTenant()}`});
    }
  }

  render() {
    return (
      <>
        <div className="c-main-box"  style={{ height: "calc(100vh - 65px)" }}>
          <iframe
            id="ServiceSetup" name="ServiceSetup"
            src={this.state.url}
            style={{ width: "100%", height: "inherit",overflowX:"auto",overflowY:"auto" }}
          ></iframe>
        </div>
      </>
    );
  }
}
