import React from "react";
import {
  CloseButton,
  HeaderIcon,
  HeaderRoot,
  HeaderTitleContainer,
  HeaderTitleContainerVariant,
} from "./SidebarHeader.styled";
import Icon from "@byk/pages/components/Icon/Icon";
import {LeftOutlined} from "@ant-design/icons";

type Props = {
  className?: string;
  title?: string;
  icon?: any;
  onBack?: () => void;
  onClose?: () => void;
};

function getHeaderVariant(
  {
    hasDefaultBackButton,
    hasOnBackHandler,
  }: {
    hasDefaultBackButton: boolean;
    hasOnBackHandler: boolean;
  }): HeaderTitleContainerVariant {
  if (hasDefaultBackButton) {
    return "default-back-button";
  }
  if (hasOnBackHandler) {
    return "back-button";
  }
  return "default";
}

function SidebarHeader({className, title, icon, onBack, onClose}: Props) {
  const hasDefaultBackButton = !title && !!onBack;

  const headerVariant = getHeaderVariant({
    hasDefaultBackButton,
    hasOnBackHandler: !!onBack,
  });

  return (
    <HeaderRoot className={className}>
      <HeaderTitleContainer
        variant={headerVariant}
        onClick={onBack}
      >
        {onBack && <LeftOutlined/>}
        {icon && <HeaderIcon className={icon}/>}
        {hasDefaultBackButton ? "返回" : title}
      </HeaderTitleContainer>
      {onClose && (
        <CloseButton onClick={onClose}>
          <Icon name="close" size={18}/>
        </CloseButton>
      )}
    </HeaderRoot>
  );
}

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default Object.assign(SidebarHeader, {Root: HeaderRoot});
