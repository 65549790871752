import * as React from "react";
import {useEffect, useState} from "react";
import Filter from "@byk/pages/QueryBuilder/lib/queries/structured/Filter";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import AccordionList from "@byk/pages/QueryBuilder/components/ui/AccordionList/AccordionList";
import {FieldDimension} from "@byk/pages/QueryBuilder/lib/metadata/Dimension";

export interface Props {
  style?: any,
  maxHeight?: any,
  dimension?: any,
  sections?: any,
  onChangeDimension: (dimension: FieldDimension) => void,
  onChangeOther: (item: { filter: Filter; query: StructuredQuery }) => void,
  width?: any,
  alwaysExpanded?: any,
}

const DimensionList: React.FC<Props> = (props: Props) => {
  const [sections, setSections] = useState(props.sections);
  useEffect(() => {
    setSections(sections.map((section: any) => ({
      ...section,
      items: section.items.map((item: any) => ({
        ...item,
        name: item.name || item.dimension?.displayName(),
        icon: item.icon || item.dimension?.icon(),
      })),
    })));

  }, []);

  const handleChange = (item: any) => {
    console.log("step-filter: DimensionList--handleChange ", item);
    const {dimension} = item;
    props.onChangeDimension(dimension);
  }

  const itemIsSelected = (item: any) => {
    return false;
  }

  const renderItemExtra = () => {
  }

  return (
    <AccordionList
      extraProps={{...props}}
      sections={sections}
      onChange={handleChange}
      itemIsSelected={itemIsSelected}
      renderItemExtra={renderItemExtra}
      getItemClassName={() => "hover-parent hover--display"}
    />
  );
}

export default DimensionList;
