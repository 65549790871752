import React, { useEffect, useRef } from 'react';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
import './codeMirror.css';

import CodeMirror from 'codemirror';
import _ from "lodash";

const treeData:any = {
  User: ['id', 'name'],
  Url: ['param']
};
function customHint(editor: any) {
  const cursor = editor.getCursor(); // 获取光标位置
  const token = editor.getTokenAt(cursor); // 获取光标处的标记
  let start = token.start;
  const end = cursor.ch;
  const line = cursor.line;
  const currentWord = token.string;
  const value = editor.getValue();
  if(currentWord == '.'){
    start += 1;
  }
  // 自定义提示列表
  // 你可以根据需要定义更复杂的逻辑来生成提示
  let hints: any[] = [];
  /*提示规则*/
  let valueArray = value.split('.');
  if(valueArray.length == 1){
    _.keys(treeData).map((i:any)=>{
      if(i.startsWith(valueArray[0])){
        hints.push(i);
      }
    })
  }
  if(valueArray.length == 2){
    let children = _.get(treeData, valueArray[0]);
    if(children){
      children.map((i:any)=>{
        if(i.startsWith(valueArray[1])){
          hints.push(i);
        }
      })
    }
  }

  return {
    list: hints, // 提示列表
    from: CodeMirror.Pos(line, start), // 提示列表的开始位置
    to: CodeMirror.Pos(line, end), // 提示列表的结束位置
  };
}

const XCodeMirror = (props:any) => {
  const {value, onChange} = props;
  const editorRef = useRef<HTMLTextAreaElement>(null);
  let editor:any;

  const includesKeys = ['ArrowUp', 'ArrowDown', 'Enter'];
  useEffect(() => {
    if (editorRef.current&&!editor) {
      editor = CodeMirror.fromTextArea(editorRef.current, {
        mode: 'javascript',
        theme: 'default',
        lineNumbers: false,
        extraKeys: {
          'Ctrl-Space': 'autocomplete',
          'Enter': ()=> {
            let hint = editor.state.completionActive; // 获取当前的提示状态
            if (hint && hint.selectedHint !== null) {
              hint.pick(); // 应用用户手动选中的提示项
              return CodeMirror.Pass; // 阻止默认的回车键行为
            }
            return CodeMirror.Pass; // 如果没有选中的提示项，继续传递回车键事件
          }
        }
      });


      editor.setOption('hintOptions', {
        completeSingle: false, // 是否在只有一个匹配项时立即补全
        closeOnUnfocus: true, // 是否在失去焦点时关闭提示框
        hint: customHint
      });


      editor.on('change', (instance:any) => {
        const v = instance.getValue();
        onChange(v);
      });

      editor.on('keydown', (cm:any, ev:any) => {
        if(!includesKeys.includes(ev.key)){
          editor.showHint({
            hint: customHint
          })
        }

      });

    }
  }, []);
  useEffect(()=>{
    if(editorRef.current&&value&&editor){
      setTimeout(() => {
        editor.setValue(value ?? '');
      }, 0);
    }
  }, [value])
  return (
    <textarea style={{display: 'none'}} ref={editorRef} />
  );
};

export default XCodeMirror;
