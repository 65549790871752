export default function Config ():string{
  return `
  module.exports = {
  presets: [
    ['taro', {
      framework: 'react',
      ts: true
    }]
  ]
}
  `;
}
