import React, {useCallback, useEffect, useMemo, useRef, useState,} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  getCurrentApplication,
  getCurrentApplicationId,
  getCurrentPage,
  getCurrentPageId,
} from "selectors/editorSelectors";
import Entity, {EntityClassNames} from "../Entity";
import history, {NavigationMethod} from "utils/history";
import {createNewPageFromEntities, updatePage} from "actions/pageActions";
import {defaultPageIcon, loginPageIcon, pageIcon} from "../ExplorerIcons";
import {ADD_PAGE_TOOLTIP, createMessage} from "@appsmith/constants/messages";
import type {Page} from "@appsmith/constants/ReduxActionConstants";
import {getNextEntityName} from "utils/AppsmithUtils";
import styled from "styled-components";
import PageContextMenu from "./PageContextMenu";
import {resolveAsSpaceChar} from "utils/helpers";
import {getExplorerPinned} from "selectors/explorerSelector";
import {setExplorerPinnedAction} from "actions/explorerActions";
import {selectAllPages} from "selectors/entitiesSelector";
import {builderURL} from "RouteBuilder";
import {saveExplorerStatus,} from "@appsmith/pages/Editor/Explorer/helpers";
import {tailwindLayers} from "constants/Layers";
import type {CallbackResponseType} from "utils/hooks/useResize";
import useResize, {DIRECTION} from "utils/hooks/useResize";
import AddPageContextMenu from "./AddPageContextMenu";
import AnalyticsUtil from "utils/AnalyticsUtil";
import {useLocation} from "react-router";
import {toggleInOnboardingWidgetSelection} from "actions/onboardingActions";
import {hasCreatePagePermission, hasManagePagePermission,} from "@appsmith/utils/permissionHelpers";
import type {AppState} from "@appsmith/reducers";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {theme} from "../../../../constants/DefaultTheme";
import SidebarPageTab from "../../../../components/editorComponents/SidebarPageTab ";
import { getCookie, setCookie } from "../../../../utils/CookieUtils";
import _ from "lodash";
import PageGroup from "./PageGroup";
import {findNodeById} from "../../../../utils/XUtils";
import {getAppId} from "../../../../utils/localStorage";
import {appTenantClass} from "@byk/utils/Utils";

const ENTITY_HEIGHT = 36;
const MIN_PAGES_HEIGHT = 60;

const moveToStyle = {
  padding: '0px 10px',
  background: '#F9F9F9 100%',
  borderRadius: '5px 5px 0 0',
  fontSize: '10px'
}

export const StyledEntity = styled(Entity)<{ pagesSize?: number }>`
  &.pages {
    & > div:not(.t--entity-item) > div > div {
      max-height: calc(100vh - ${(props) => props.theme.smallHeaderHeight} - ${(props) => props.theme.bottomBarHeight} - 100px);
      min-height: calc((100vh - ${(props) => props.theme.smallHeaderHeight} - ${(props) => props.theme.bottomBarHeight} - 100px) / 3);
      overflow-y: auto;
    }
  }

  &.page .${EntityClassNames.PRE_RIGHT_ICON} {
    width: 20px;
    right: 0;
  }

  &.page:hover {
    & .${EntityClassNames.PRE_RIGHT_ICON} {
      display: none;
    }
  }
  &.page > div{
    padding-left: 0;
    & > span:first-child{
      display: none;
    }
    & > div:nth-child(3){
      width: 180px;
    }
  }
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const ResizeHandler = styled.div`
  text-align: center;
  height: 2px;
  background:var(--ads-v2-color-border);
`;

function Pages() {
  const applicationId = useSelector(getCurrentApplicationId);
  const pages: Page[] = useSelector(selectAllPages);
  const currentPageId = useSelector(getCurrentPageId);
  const pinned = useSelector(getExplorerPinned);
  const dispatch = useDispatch();
  const pageResizeRef = useRef<HTMLDivElement>(null);
  const storedHeightKey = "pagesContainerHeight_" + applicationId;
  const storedHeight = localStorage.getItem(storedHeightKey);
  const location = useLocation();
  const [moveToUpFlag, setMoveToUpFlag] = useState<boolean>(true);
  const sidebarPageTabRef = useRef<any>();

  const resizeAfterCallback = (data: CallbackResponseType) => {
    sidebarPageTabRef.current.style.display = 'block';
    localStorage.setItem(storedHeightKey, data.height + 36 + ''); // 36是页面 bar的高度
  };

  const {mouseDown, setMouseDown} = useResize(
    pageResizeRef,
    DIRECTION.vertical,
    resizeAfterCallback,
  );

  useEffect(() => {
    if (pageResizeRef.current) {
      pageResizeRef.current.style.height = storedHeight + "px";
    }
  }, [pageResizeRef]);

  const switchPage = useCallback(
    (page: Page) => {
      const navigateToUrl = builderURL({
        pageId: page.pageId,
      });
      AnalyticsUtil.logEvent("PAGE_NAME_CLICK", {
        name: page.pageName,
        fromUrl: location.pathname,
        type: "PAGES",
        toUrl: navigateToUrl,
      });
      let userId = appTenantClass.getUserId();
      let appId = getAppId();
      if (appId != null) {
        setCookie("pageId_" + appId + "_" + userId, page.pageId);
      }

      dispatch(toggleInOnboardingWidgetSelection(true));
      history.push(navigateToUrl, {
        invokedBy: NavigationMethod.EntityExplorer,
      });
    },
    [location.pathname],
  );

  const [isMenuOpen, openMenu] = useState(false);
  const collectNames = useCallback((node:any, accumulator:any = [])=> {
    _.each(node, (i:any)=>{
      accumulator.push(i.slug);
      if (i.children && i.children.length > 0) {
        collectNames(i.children, accumulator);
      }
    })

    return accumulator;
  }, []);
  const createPageCallback = (pageId:any, type='Page') => {
    const name = getNextEntityName(
      type,
      collectNames(pages)
    );
    const currentPage:any = findNodeById(pages, pageId);//_.find(pages, { pageId: parseInt(pageId, 10) });
    let isGroup = currentPage?.type === 'Group';
    let parent = currentPage?.parent;
    if(isGroup){
      parent = currentPage?.pageId;
    }
    dispatch(createNewPageFromEntities(applicationId, name, type, parent, currentPage?.sort));
  };

  const onMenuClose = useCallback(() => openMenu(false), [openMenu]);

  /**
   * toggles the pinned state of sidebar
   */
  const onPin = useCallback(() => {
    dispatch(setExplorerPinnedAction(!pinned));
  }, [pinned, dispatch, setExplorerPinnedAction]);

  const onPageToggle = useCallback(
    (isOpen: boolean) => {
      saveExplorerStatus(applicationId, "pages", isOpen);
    },
    [applicationId],
  );

  const userAppPermissions = useSelector(
    (state: AppState) => getCurrentApplication(state)?.userPermissions ?? [],
  );

  const canCreatePages = hasCreatePagePermission(userAppPermissions);
  const pageElements = useMemo(
    () =>{
      return <PageGroup pages={pages} currentPageId={currentPageId} applicationId={applicationId}
                        switchPage={switchPage} />
    },
    [pages, currentPageId, applicationId, location.pathname],
  );
  const moveTo = () => {
    if (!pageResizeRef.current) {
      return;
    }

    let headerHeight = parseInt(theme.smallHeaderHeight.replaceAll("px", ''));
    let bottomBarHeight = parseInt(theme.bottomBarHeight.replaceAll("px", ''));
    let maxH = document.documentElement.clientHeight - headerHeight - bottomBarHeight;
    if (moveToUpFlag) {
      sidebarPageTabRef.current.style.display = 'none';
      setMoveToUpFlag(false)
      pageResizeRef.current.style.height = maxH + 'px';
    } else {
      setMoveToUpFlag(true)
      sidebarPageTabRef.current.style.display = 'block';
      pageResizeRef.current.style.height = (maxH / 3) + 'px';
    }
  }

  return (
    <div>
      <RelativeContainer>
        <StyledEntity
          addButtonHelptext={createMessage(ADD_PAGE_TOOLTIP)}
          alwaysShowRightIcon
          className="group pages"
          collapseRef={pageResizeRef}
          customAddButton={
            <AddPageContextMenu
              className={`${EntityClassNames.ADD_BUTTON} group pages`}
              createPageCallback={createPageCallback}
              onMenuClose={onMenuClose}
              openMenu={isMenuOpen}
            />
          }
          entityId="Pages"
          icon={""}
          isDefaultExpanded={true}
          name="页面"
          onClickPreRightIcon={onPin}
          onToggle={onPageToggle}
          pagesSize={ENTITY_HEIGHT * pages.length}
          searchKeyword={""}
          showAddButton={canCreatePages}
          step={0}
          disabled={true}
          isVisible={false}
        >
          {pageElements}
        </StyledEntity>
        <div
          className={`absolute -bottom-2 left-0 w-full h-2 group cursor-ns-resize ${tailwindLayers.resizer}`}
          onMouseDown={() => setMouseDown(true)}
          style={{'height': '5px'}}
        >
          <div style={{'textAlign': 'center'}}>
            {!moveToUpFlag && <UpOutlined style={{...moveToStyle}} onClick={() => moveTo()}/>}
            {moveToUpFlag && <DownOutlined style={{...moveToStyle}} width={10} height={10} onClick={() => moveTo()}/>}
          </div>
          <ResizeHandler
            className={
              `w-full h-1 bg-transparent hover:bg-transparent transform transition
               ${mouseDown ? "" : ""}
          `}
          />
        </div>
      </RelativeContainer>

      <div style={{marginTop: '26px'}} ref={sidebarPageTabRef}>
        <SidebarPageTab/>
      </div>
    </div>
  );
}

Pages.displayName = "Pages";

export default React.memo(Pages);
