import {NotebookStepUiComponentProps} from "@byk/pages/QueryBuilder/components/notebook/types";
import React from "react";
import ClauseStep from "@byk/pages/QueryBuilder/components/notebook/steps/ClauseStep";
import FilterPopover from "@byk/pages/QueryBuilder/components/ui/FilterPopover";

const FilterStep: React.FC<NotebookStepUiComponentProps> =
  ({
     step,
     color,
     query,
     updateQuery,
   }: NotebookStepUiComponentProps) => {
    let isLastOpened = false;
    return (
      <ClauseStep
        color={color}
        initialAddText="添加筛选条件来缩小你的答案范围"
        items={query.filters()}
        renderName={(item: any) => item.displayName()}
        renderPopover={(filter) => (
          <FilterPopover
            query={query}
            filter={filter}
            onChangeFilter={(newFilter: any) =>
              filter
                ? updateQuery(filter.replace(newFilter))
                : updateQuery(query.filter(newFilter))
            }
          />
        )}
        isLastOpened={isLastOpened}
        onRemove={filter => updateQuery(filter.remove())}
      />
    );

  };
export default FilterStep;
