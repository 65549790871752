import {mapping} from "../ce/sagas/ActionExecution/widgetToValue";
//验证是否是有效的json字符串
export function isValidJSON(str:string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
//处理映射时带.mapping.的key
export function deleteMapping(data:any) {
  try {
    return data.map((i:any)=>{
      let c = Object.entries(i);
      c = c.map(([key, val])=> [key.split(mapping)[1]||key, val]);
      return Object.fromEntries(c);
    })
  } catch (e) {
    return [];
  }
  return [];
}
//把一条数据插入到一个树结构对应的parent里
export function insertNodeIntoTree(treeData:any, newNode:any, parentId:any = null) {
  const findAndInsert:any = (nodes:any, parentId:any) => {
    let inserted = false;
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].key === parentId) {
        nodes[i].children = nodes[i].children || [];
        nodes[i].children.push(newNode);
        inserted = true;
        break;
      } else if (nodes[i].children && nodes[i].children.length > 0) {
        // 递归查找子节点
        inserted = findAndInsert(nodes[i].children, parentId);
        if (inserted) break;
      }
    }
    return inserted;
  };
  findAndInsert(treeData, parentId);
}
//删除一个树结构里的对应id和其子节点的数据
export function removeNodeAndChildren(treeData:any, nodeId:any, key='pageId') {
  const removeRecursively = (nodes:any, nodeId:any) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i][key] == nodeId) {
        nodes.splice(i, 1);
        return true;
      } else if (nodes[i].children) {
        if (removeRecursively(nodes[i].children, nodeId)) {
          if (nodes[i].children.length === 0) {
            delete nodes[i].children;
          }
          return true;
        }
      }
    }
    return false;
  };

  return removeRecursively(treeData, nodeId);
}
//通过id查询树结构里对应id的记录
export function findNodeById(treeData:any, nodeId:any, key='pageId') {
  const findRecursively:any = (nodes:any, nodeId:any) => {
    for (const node of nodes) {
      if (node[key] == nodeId) {
        return node;
      } else if (node.children) {
        const foundNode = findRecursively(node.children, nodeId);
        if (foundNode&&foundNode[key]) {
          return foundNode;
        }
      }
    }
    return {};
  };
  return findRecursively(treeData, nodeId);
}
//通过id ，修改对应树结构里id的name属性
export function updateNodeName(treeData:any, nodeId:any, newName:any, key='pageName') {
  const updateRecursively = (nodes:any) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].pageId == nodeId) {
        // 找到节点，更新名称
        nodes[i][key] = newName;
        return true;
      } else if (nodes[i].children) {
        // 如果当前节点有子节点，递归查找
        if (updateRecursively(nodes[i].children)) {
          return true;
        }
      }
    }
    return false;
  };
  return updateRecursively(treeData);
}
//把一个树结构里的某些属性提出来组成一个平级的结构
export function flattenTree(treeData:any, getKeys=['pageSlug', 'customSlug', 'pageId']) {
  const flattenedData:any = [];
  const traverse = (nodes:any) => {
    nodes.forEach((node:any) => {
      let pa:any = {};
      getKeys.forEach((i:string)=>{
        pa[i] = node[i];
      })
      flattenedData.push(pa);
      if (node.children && node.children.length > 0) {
        traverse(node.children);
      }
    });
  };
  traverse(treeData);
  return flattenedData;
}
