import {all, call, put, select} from "redux-saga/effects";
import {getAppStoreName} from "constants/AppConstants";
import localStorage from "utils/localStorage";
import {updateAppStore} from "actions/pageActions";
import AppsmithConsole from "utils/AppsmithConsole";
import {getAppStoreData} from "selectors/entitiesSelector";
import {getCurrentGitBranch} from "selectors/gitSyncSelectors";
import {getCurrentApplicationId} from "selectors/editorSelectors";
import type {AppStoreState} from "reducers/entityReducers/appReducer";
import {LOG_CATEGORY, Severity} from "entities/AppsmithConsole";
import moment from "moment";
import type {
  TClearStoreDescription,
  TRemoveValueDescription,
  TStoreValueDescription,
} from "workers/Evaluation/fns/storeFns";
import {ReduxActionTypes} from "../../ce/constants/ReduxActionConstants";
import {specialKey} from "../../workers/Evaluation/fns";

type StoreOperation =
  | TStoreValueDescription
  | TClearStoreDescription
  | TRemoveValueDescription;


// @ts-ignore
export function* handleStoreOperations(triggers: StoreOperation[]) {
  let handleTriggers:any = [...triggers];
  if(handleTriggers[0].payload.key === specialKey){
    yield put({
      type: ReduxActionTypes.X_ACTION_EXECUTION,
      payload: {
        dynamicString: handleTriggers[0].payload.value.dynamicString,
        globalContext: handleTriggers[0].payload.value.globalContext
      }
    })
    return ;
  }
  const applicationId: string = yield select(getCurrentApplicationId);
  const branch: string | undefined = yield select(getCurrentGitBranch);
  const appStoreName = getAppStoreName(applicationId, branch);
  const existingLocalStore = localStorage.getItem(appStoreName) || "{}";
  let parsedLocalStore = JSON.parse(existingLocalStore);
  let currentStore: AppStoreState = yield select(getAppStoreData);
  const logs: string[] = [];
  //api 处理，先查action，在发请求


  for (const t of handleTriggers) {
    const { type } = t;
    if (type === "STORE_VALUE") {
      const { key, persist, value, size } = t.payload;
      if(value != null){
        if (persist) {
          parsedLocalStore[key] = value;
          parsedLocalStore[`${key}Size`] = size;
        }
        currentStore[key] = value;
        logs.push(`storeValue('${key}', '${value}', ${persist})`);
      }
    } else if (type === "REMOVE_VALUE") {
      const { key } = t.payload;
      delete parsedLocalStore[key];
      delete currentStore[key];
      logs.push(`removeValue('${key}')`);
    } else if (type === "CLEAR_STORE") {
      parsedLocalStore = {};
      currentStore = {};
      logs.push(`clearStore()`);
    }
  }
  yield put(updateAppStore(currentStore));
  const storeString = JSON.stringify(parsedLocalStore);
  localStorage.setItem(appStoreName, storeString);
  AppsmithConsole.addLogs(
    logs.map((text) => ({
      text,
      severity: Severity.INFO,
      category: LOG_CATEGORY.USER_GENERATED,
      timestamp: moment().format("hh:mm:ss"),
      isExpanded: false,
    })),
  );
}
