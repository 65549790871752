const XPDict:any = {
  actions: [//动作下拉框选项
    {
      value: '',
      label: '=请选择=',
      disabled: true
    },
    {
      label: '页面交互',
      options: [
        {
          value: 'navigateTo',
          label: '打开页面',
        },
        {
          value: 'navigateUrl',
          label: '打开超链接',
        },
        {
          value: 'navigateBack',
          label: '返回上一页',
          disabled: true
        }
      ],
    },
    {
      label: '弹框消息',
      options: [
        {
          value: 'showModal',
          label: '打开弹框',
        },
        {
          value: 'closeModal',
          label: '关闭弹框',
        },
        {
          value: 'showAlert',
          label: '消息提醒',
        }
      ],
    },
    {
      label: '控制组件',
      options: [
        {
          value: 'ctrlShow',
          label: '显示/隐藏组件',
          disabled: true
        },
        {
          value: 'ctrlEnable',
          label: '启用/禁用组件',
          disabled: true
        },
        {
          value: 'ctrlWidget',
          label: '组件属性变化',
          disabled: true
        },
        {
          value: 'linkage',
          label: '组件联动'
        },
      ],
    },
    {
      label: '数据交互',
      options: [
        {
          value: 'get',
          label: '获取数据'
        },
        {
          value: 'post',
          label: '提交数据'
        },
        {
          value: 'put',
          label: '修改数据'
        },
        {
          value: 'delete',
          label: '删除数据'
        },
        {
          value: 'imported',
          label: '导入数据'
        },
        {
          value: 'exported',
          label: '导出数据'
        }
      ],
    },
    {
      label: '其他',
      options: [
        {
          value: 'js',
          label: '执行JS函数',
        },
        {
          value: 'special',
          label: '特殊处理',
        }
      ],
    }
  ],
  showAlertType: [//信息提示类型
    {
      value: '',
      label: '=请选择=',
      disabled: true
    },
    {
      value: 'info',
      label: '普通'
    },
    {
      value: 'error',
      label: '错误'
    }
  ],
  navigateBlank: [//页面跳转类型
    {
      value: '',
      label: '=请选择=',
      disabled: true
    },
    {
      value: 'NEW_WINDOW',
      label: '新窗口'
    },
    {
      value: 'SAME_WINDOW',
      label: '当前窗口'
    }
  ],
  conditionType: [//条件类型
    {
      value: '',
      label: '=请选择=',
      disabled: true
    },
    {
      value: '0',
      label: '等于'
    },
    {
      value: '1',
      label: '不等于'
    },
    {
      value: '2',
      label: '大于'
    },
    {
      value: '3',
      label: '小于'
    },
    {
      value: '4',
      label: '大于等于'
    },
    {
      value: '5',
      label: '小于等于'
    },
    {
      value: '6',
      label: '包含'
    },
    {
      value: '7',
      label: '不包含'
    },
    {
      value: '8',
      label: '为空'
    },
    {
      value: '9',
      label: '不为空'
    }
  ],
  actionMethods: ['get','post','put','delete','imported','exported'],
  liandongWidgets: ['X_ECHARTS_WIDGET', 'X_ECHARTS_LINE_WIDGET','X_ECHARTS_RADAR_WIDGET','X_ECHARTS_PIE_WIDGET','X_ECHARTS_GAUGE_WIDGET','X_ECHARTS_FUNNEL_WIDGET',
  'X_ECHARTS_MAP_WIDGET','CONTAINER_WIDGET', 'TABLE_WIDGET_V2', 'LIST_WIDGET_V2', 'DYNAMICFORMS_WIDGET', 'FORM_WIDGET', 'TABLE_WIDGET_ANTD',"SEARCH_FORM_WIDGET", 'AT_LIST_WIDGET'],
  liandongWidgetsEventKey: {
    'X_ECHARTS_WIDGET' : 'EchartsWidget',
    'X_ECHARTS_LINE_WIDGET' : 'EchartsLineWidget',
    'X_ECHARTS_RADAR_WIDGET':'EchartsRadarWidget',
    'X_ECHARTS_PIE_WIDGET':'EchartsPieWidget',
    'X_ECHARTS_GAUGE_WIDGET':'EchartsGaugeWidget',
    'X_ECHARTS_FUNNEL_WIDGET':'EchartsFunnelWidget',
    'X_ECHARTS_MAP_WIDGET':'EchartsMapWidget',
    'CONTAINER_WIDGET':'CONTAINER_WIDGET',
    'TABLE_WIDGET_V2':'TableWidgetV2',
    'LIST_WIDGET_V2':'ListWidgetV2',
    'DYNAMICFORMS_WIDGET':'DynamicFormsWidget',
    'FORM_WIDGET':'FormWidget',
    'SEARCH_FORM_WIDGET':'SearchFormWidget',
    'TABLE_WIDGET_ANTD':'TableWigetAntd',
    'AT_LIST_WIDGET':'AtListWidget',
  },
}

export default XPDict;
