import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout,PageHeader } from 'antd';
import BusinessPage from "@byk/pages/ServicesManager/AppActionManager/main";

const { Content, Sider } = Layout;

export default class Home extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         theme: "dark",
    //         current: "home"
    //     };
    // }

    // state = {
    //     collapsed: false,
    // };

    // toggleCollapsed = () => {
    //     this.setState({
    //         collapsed: !this.state.collapsed,
    //     });
    // };

    render() {
        return (
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head title="AppServicesManager" />
                    <Layout>
                        <Sider className="Sider-Navbar"><Navbar title="AppActionManager" /></Sider>
                        <Content>
                            <PageHeader className="site-page-header-main" title="接口管理"/>
                            <Layout>
                                <BusinessPage />
                            </Layout>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

