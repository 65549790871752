import React, {useState} from "react";
import {Tab, TabPanel, Tabs, TabsList} from "design-system";
import styled from "styled-components";
import Pages from "../../pages/Editor/Explorer/Pages";
import WidgetSidebar from "../../pages/Editor/WidgetSidebar";

const tabs = ["pages", "widgets"];

const StyledTabs = styled(Tabs)`
  > [role="tabpanel"] {
    margin-top: 0;
  }
  > [role="tablist"] {
    position: sticky;
    z-index: 3;
    background: var(--ads-v2-color-white);
    overflow: hidden;
    padding: var(--ads-v2-spaces-1) var(--ads-v2-spaces-4);
  }
`;

function SideBarTop() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <StyledTabs
      onValueChange={(value) => {
        setSelectedIndex(tabs.indexOf(value) || 0);
      }}
      value={tabs[selectedIndex]}
    >
      <TabsList>
        <Tab value={tabs[0]}>页面</Tab>
        <Tab value={tabs[1]}>组件库</Tab>
      </TabsList>
      <TabPanel value={tabs[0]}>
        <Pages/>
      </TabPanel>
      <TabPanel value={tabs[1]}>
        <WidgetSidebar isActive={selectedIndex === 1}/>
      </TabPanel>
    </StyledTabs>
  );
}

export default SideBarTop;

function useRef<T>() {
  throw new Error("Function not implemented.");
}

