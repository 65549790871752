import withWidgetProps from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../../../../../utils/localStorage";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XImage from "../../components/widgets/XImage";';
  const imgUrl = i.value&&i.value.startsWith('/byk')?i.value: `${apiPath}/${getTenant()}/file/${i.value}`;
  begin = begin + `<XImage `;
  if(i.isDescription){
    begin += ` description={${i.description}} `
  }
  begin += ` propertyPath={${i.propertyPath}} imgUrl={'https://byk-test.billjc.com${imgUrl}'} `;
  begin += getWidgetStyles(i);
  begin += ' />';
  return {begin, head};
};
