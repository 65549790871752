export default function Config (body:any){
  if(!body){
    return `export default {}`;
  }
  body = body.substring(14);
  body = body.slice(0, -1);
  body = body + `,listeners: [],
    addListeners(callback) {
      this.listeners.push(callback);
    },
    notifyChange() {
      this.listeners.forEach(callback => callback());
    }`;
  body = body + '}';
  return `
  const JSObject1 = ${body};
  export default JSObject1;
  `;
}
