import React, {forwardRef, useImperativeHandle} from "react";
import {Form, Modal} from "antd";
import {TrackContextFn} from "@byk/pages/Track/index";
import styled from "styled-components";
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";
import TextArea from "antd/es/input/TextArea";
import UploadFileBlobHttp from "@byk/pages/Track/UploadFileBlobHttp";
import {issuesApi} from "@byk/pages/Track/trackApi";
import {model} from "@byk/pages/Track/model";

const FormContainer = styled.div`
  padding-left: 25px;

  .ant-form-item {
    width: 882px;
  }

  .ant-form-vertical .ant-form-item-row {
    flex-direction: column;
    margin-left: 24px;
    margin-top: -27px;
  }

  .iconPark {
    display: inline-block;
    color: #717171;
  }

  .fileDesc {
    color: #717171;
  }
`
const TrackFormComment = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    openComment
  }));
  const {setAttachments, setCommentList} = props;
  const {issue} = TrackContextFn();
  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState<any>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [comment, setComment] = React.useState<any>(null);
  const [draftCommentId, setDraftCommentId] = React.useState(null);

  const onSubmit = () => {

    const _updateTrackDetail = async (issue: any) => {
      let issueDetail: any = await model.getIssueDetailData(issue);
      setAttachments && setAttachments(issueDetail.files);
      setCommentList && setCommentList(issueDetail.comments);
      handleCloseModal();
    }
    
    form.validateFields().then(async (values: any) => {
      let params = {
        "text": values.text,
      }
      if (draftCommentId) {
        issuesApi.addIssueComment(issue.id, draftCommentId, params, async (r: any) => {
          await _updateTrackDetail(issue)
        })
      } else {
        await issuesApi.updateComment(issue.id, comment.id, params);
        await _updateTrackDetail(issue)
      }
    })
  }

  const openComment = (draftCommentId: any, comment: any = {}) => {
    setComment(comment);
    let _fileList = model.getFileList4Attachments(comment?.attachments) || []
    setFileList(_fileList);
    form.setFieldValue('text', comment?.text || '');
    setDraftCommentId(draftCommentId);
    setIsOpen(true)
  }

  const handleCloseModal = () => {
    setIsOpen(false);
  }

  return (<>
      <Modal title='问题补充'
             cancelText='取消'
             okText='提交'
             maskClosable={false}
             open={isOpen} width={983} onCancel={handleCloseModal} onOk={onSubmit}>

        <FormContainer>
          <Form
            form={form}
            layout="vertical"
          >
            <div>
              {getIconPark('BookOpen')}
              <Form.Item
                label="补充描述" name='text'
                rules={[{required: true, message: '请输入补充描述'}]}
              >
                <TextArea rows={12}/>
              </Form.Item>
            </div>
            <div>
              {getIconPark('LinkOne')}
              <div className='ant-form-item-row'>
                <div>补充附件<span className='fileDesc'>（上传文件可以帮助工作人员快速定位到您的问题）</span></div>
                <UploadFileBlobHttp
                  defaultFileList={fileList}
                  onChange={(files: any) => {
                    setFileList(files);
                  }}
                  issueId={issue.id}
                  draftCommentId={draftCommentId}/>
              </div>
            </div>
          </Form>
        </FormContainer>
      </Modal>
    </>
  )
});

export default TrackFormComment;
