import * as React from "react";
import {color} from "@byk/pages/QueryBuilder/lib/colors";
import {NotebookStepUiComponentProps} from "../types";
import {DataStep} from "@byk/pages/QueryBuilder/components/notebook/steps/DataStep";
import JoinStep from "@byk/pages/QueryBuilder/components/notebook/steps/JoinStep/JoinStep";
import ExpressionStep from "@byk/pages/QueryBuilder/components/notebook/steps/ExpressionStep/ExpressionStep";
import FilterStep from "@byk/pages/QueryBuilder/components/notebook/steps/FilterStep/FilterStep";
import AggregateStep from "@byk/pages/QueryBuilder/components/notebook/steps/AggregateStep/AggregateStep";
import SummarizeStep from "@byk/pages/QueryBuilder/components/notebook/steps/SummarizeStep/SummarizeStep";
import BreakoutStep from "@byk/pages/QueryBuilder/components/notebook/steps/BreakoutStep/BreakoutStep";
import LimitStep from "@byk/pages/QueryBuilder/components/notebook/steps/LimitStep/LimitStep";
import SortStep from "@byk/pages/QueryBuilder/components/notebook/steps/SortStep/SortStep";

export type StepUIItem = {
  title: string;
  icon: String;
  priority?: number;
  transparent?: boolean;
  compact?: boolean;
  getColor: () => string;
  component: React.ComponentType<NotebookStepUiComponentProps>;
};

export const STEP_UI: Record<string, StepUIItem> = {
  data: {
    title: `数据`,
    icon: '',
    component: DataStep,
    getColor: () => color("brand"),
  },
  join: {
    title: `联合(Join)数据`,
    icon: "JoinLeftOuter",
    priority: 1,
    getColor: () => color("brand"),
    component: JoinStep as React.ComponentType<NotebookStepUiComponentProps>,
  },
  expression: {
    title: `自定义列`,
    icon: "AddData",
    component: ExpressionStep,
    transparent: true,
    getColor: () => color("bg-dark"),
  },
  filter: {
    title: `筛选器`,
    icon: "Filter",
    component: FilterStep,
    priority: 10,
    getColor: () => color("filter"),
  },
  summarize: {
    title: `汇总`,
    icon: "Sum",
    component: SummarizeStep,
    priority: 5,
    getColor: () => color("summarize"),
  },
  aggregate: {
    title: `聚合`,
    icon: "Sum",
    component: AggregateStep,
    priority: 5,
    getColor: () => color("summarize"),
  },
  breakout: {
    title: `Breakout`,
    icon: "segment",
    component: BreakoutStep,
    priority: 1,
    getColor: () => color("accent4"),
  },
  sort: {
    title: `排序`,
    icon: "Sort",
    component: SortStep,
    compact: true,
    transparent: true,
    getColor: () => color("bg-dark"),
  },
  limit: {
    title: `行数限制`,
    icon: "List",
    component: LimitStep,
    compact: true,
    transparent: true,
    getColor: () => color("bg-dark"),
  },
};
