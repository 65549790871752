export default function Config ():string{
  return `import React from "react";
import XBase from "./XBase";

function XListView(props: any) {
  const { propertyPath, children } = props;
  return (
    <XBase propertyPath={propertyPath}>
      {
        children
      }
    </XBase>)
}
export default XListView;
  `;
}
