import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAppThemes,
  getAppThemingStack,
  getSelectedAppTheme,
} from "selectors/appThemingSelectors";
import { ThemeCard } from "./ThemeCard";
import { SettingSection } from "./SettingSection";
import { setAppThemingModeStackAction } from "actions/appThemingActions";
import styled from "styled-components";
import { Link } from "design-system";

const SectionTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #364252;
`;

function ThemeSelector() {
  const dispatch = useDispatch();
  const themes = useSelector(getAppThemes);
  const themingStack = useSelector(getAppThemingStack);
  const selectedTheme = useSelector(getSelectedAppTheme);

  /**
   * goes to previous screen in the pane
   */
  const onClickBack = () => {
    dispatch(setAppThemingModeStackAction(themingStack.slice(0, -1)));
  };

  /**
   * stores user saved themes
   */
  const userSavedThemes = themes.filter(
    (theme) => theme.isSystemTheme === false,
  );

  /**
   * stores default system themes
   */
  const systemThemes = themes.filter((theme) => theme.isSystemTheme === true);

  return (
    <div className="relative">
      <section className="sticky top-0 items-center justify-between bg-white z-1 ">
        <Link
          className="px-3 py-2 space-x-1 t--theme-select-back-btn"
          kind="secondary"
          onClick={onClickBack}
          startIcon="back-control"
          to="#"
        >
          返回
        </Link>
        <SettingSection
          className="px-4 py-3 border-t border-b"
          isDefaultOpen={false}
          title="当前主题"
        >
          <ThemeCard theme={selectedTheme} />
        </SettingSection>
      </section>
      {userSavedThemes.length > 0 && (
        <section className="relative p-4 space-y-3">
          <SectionTitle >我的主题</SectionTitle>
          {userSavedThemes.map((theme) => (
            <ThemeCard
              deletable={!theme.isSystemTheme}
              key={theme.id}
              selectable
              theme={theme}
            />
          ))}
        </section>
      )}
      {/*<section className="relative p-4 space-y-3">*/}
      {/*  <SectionTitle >特色主题</SectionTitle>*/}
      {/*  {systemThemes.map((theme) => (*/}
      {/*    <ThemeCard*/}
      {/*      deletable={!theme.isSystemTheme}*/}
      {/*      key={theme.id}*/}
      {/*      selectable*/}
      {/*      theme={theme}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</section>*/}
    </div>
  );
}

export default ThemeSelector;
