import _ from "lodash";
import moment from "moment";

export default function getWidgetValue(widgetTree:any):Record<string, unknown>{
  let pageParams:any = {};
  _.each(widgetTree, (value:any, key:string)=>{
    //非页面组件传值类型
    if(['appsmith', 'pageList', 'MainContainer', 'JSObject1'].includes(key)
      ||['CONTAINER_WIDGET', 'CANVAS_WIDGET'].includes(value.type)){
      return true;
    }

    //需要传label和value的组件类型
    if(['SELECT_WIDGET', 'RADIO_GROUP_WIDGET'].includes(value.type)){
      //下拉框，单选框组，一个选项时 转为对应的label和value
      let metaValue = value.meta.selectedOptionValue||value.getValue;
      let metaLabel = value.meta.selectedOptionLabel||value.getLabel;
      pageParams[`${key}.value`] = metaValue?metaValue:null;
      pageParams[`${key}.label`] = metaLabel?metaLabel:null;
    }else if(['CHECKBOX_GROUP_WIDGET', 'MULTI_SELECT_WIDGET_V2'].includes(value.type)){
      //复选框，多选下拉框。多个选项时，转为，连接的字符串
      let _value = value.getValue&&value.getValue.join?value.getValue.join(','):null;
      let _label = value.getLabel&&value.getLabel.join?value.getLabel.join(','):null;
      pageParams[`${key}.value`] = _value;
      if((_value&&_label)||_value===_label){//初始赋值未改变时有value无label
        pageParams[`${key}.label`] = _label;
      }
    }else if(['CASCADER_WIDGET'].includes(value.type)){
      //级联组件单选时转为，连接的字符串。 多选时转为先，连接在；连接的字符串
      let _value = value.getValue?value.getValue:null;
      if(_value&&_.isString(_value[0])){
        pageParams[`${key}.value`] = value.getValue?.join(',');
        pageParams[`${key}.label`] = value.getLabel?.join(',');
      }
      if(_value&&_.isArray(_value[0])){
        pageParams[`${key}.value`] = value.getValue?.map((i:any) => i.join('-')).join(',');
        pageParams[`${key}.label`] = value.getLabel?.map((i:any) => i.join('-')).join(',');
      }
    }else if(['SWITCH_GROUP_WIDGET'].includes(value.type)){
      //开关组，没有label和value的组件，但是值是数组的转为,连接字符串
      pageParams[key] = value.getValue&&value.getValue.join(',');
    }else if(['UPLOAD_WIDGET', 'UPLOAD_IMAGE_WIDGET'].includes(value.type)){
      pageParams[key] = _.isObject(value.getValue)?JSON.stringify(value.getValue):value.getValue;
    }else if(['RANGEDATEPICKER_WIDGET'].includes(value.type)){
      //时间区间组件
      let rangValue = value.getValue;
      if(typeof rangValue === 'string'&&rangValue.includes('-')){
        let dateArr:any = rangValue.split('~');
        dateArr = dateArr.map((i:any)=>{
          return moment(i).valueOf();
        })
        pageParams[key] = dateArr.join('~');
      }else{
        pageParams[key] = _.isArray(rangValue)?rangValue.join('~'):rangValue;
      }
    }else if(['TABLE_WIDGET_ANTD'].includes(value.type)){
      //Table组件。选中行。选择集合。选中集合的key
      pageParams[`${key}.selectId`] = value.getSelectId;
      pageParams[`${key}.selectIds`] = value.getSelectIds;
      if(value.currentItem){//当前选中行的表头值
        _.each(value.currentItem, (tav:any, tak:any)=>{
          pageParams[`${key}.${tak}`] = tav;
        })
      }

    }else if(value.type == 'X_IMAGE_WIDGET'){
      let imageValue = value.getValue||{};
      if(_.isObject(imageValue)){
        imageValue = JSON.stringify(imageValue);
      }
      pageParams[key] = imageValue;
    }else{
      pageParams[key] = value.getValue;
    }
  })
  return pageParams;
}
