import AtRadio from './AtRadio';
import AtCheckbox from "./AtCheckbox";
import AtSwiper from "./AtSwiper";
import AtTextarea from "./AtTextarea";
import AtText from "./AtText";
import AtPicker from "./AtPicker";
import AtGrid from "./AtGrid";
import AtInputNumber from "./AtInputNumber";
import AtSwitch from "./AtSwitch";
import AtButton from "./AtButton";
import AtList from "./AtList";
import AtInput from "./AtInput";
import AtIcon from "./AtIcon";
import AtImage from "./AtImage";
import XList from "./XList";
import AtNavBar from "./AtNavBar";
import AtProgress from "./AtProgress";
import AtImagePicker from "./AtImagePicker";

const widgets:Record<string, any> = {
  'AT_IMAGE_PICKER_WIDGET': AtImagePicker,
  'AT_PROGRESS_WIDGET': AtProgress,
  'AT_NAVBAR_WIDGET': AtNavBar,
  'X_IMAGE_WIDGET': AtImage,
  'LIST_WIDGET_V2': XList,
  'AT_ICON_WIDGET': AtIcon,
  'AT_RADIO_WIDGET': AtRadio,
  'AT_CHECKBOX_WIDGET': AtCheckbox,
  'AT_SWIPER_WIDGET': AtSwiper,
  'AT_TEXTAREA_WIDGET': AtTextarea,
  'AT_TEXT_WIDGET': AtText,
  'AT_PICKER_WIDGET': AtPicker,
  'AT_GRID_WIDGET': AtGrid,
  'AT_INPUTNUMBER_WIDGET': AtInputNumber,
  'AT_SWITCH_WIDGET': AtSwitch,
  'AT_LIST_WIDGET': AtList,
  'AT_BUTTON_WIDGET': AtButton,
  'AT_INPUT_WIDGET': AtInput
};
export default widgets;
