import React, { useRef, useState, useEffect } from "react";

import {  withRouter } from "react-router-dom";

class AppPageDesign extends React.Component {

  constructor(props:any) {
    super(props);

  }
  state = {

  };
  async componentDidMount() {

  }

  render() {
    return (
      <>
        <div className="x-main-box" style={{height:"100vh"}}>
         <span>页面设计</span><br/>
         <span>页面设计</span><br/>
         <span>页面设计</span><br/>
         <span>页面设计</span><br/>
         <span>页面设计</span><br/>
         <span>页面设计</span><br/>
         <span>页面设计</span><br/>
        </div>
      </>
    );
  }
}
export default withRouter(AppPageDesign);
