//表格配置
import {ColumnsType} from "antd/lib/table";
import {Button, Popconfirm, Space} from "antd";
import React from "react";
import moment from "moment";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {Dependency_CRUD_URL, Dependency_List_URL} from "@byk/pages/PackageManager/api";
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";
import {CheckCircleOutlined} from "@ant-design/icons";

/**
 * Table配置项
 * @param deleteCallback
 */
export function tableProps(editClick: any, deleteClick: any) {
  const columns: ColumnsType<any> = [
    {
      title: 'name',
      dataIndex: 'name',
    },
    {
      title: 'groupId',
      dataIndex: 'groupId',
    },
    {
      title: 'artifactId',
      dataIndex: 'artifactId',
    },
    {
      title: 'version',
      dataIndex: 'version',
    },
    {
      title: '文件是否已下载到文件管理',
      dataIndex: 'code',
      render: (_, record: any) => {
        return <div style={{display:'flex', color:'green', justifyContent:'center'}}>
          {record.code && <CheckCircleOutlined />}
        </div>
      }
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      render: (_, record: any) => {
        return moment(record.createDate).format('YYYY-MM-DD hh:MM:ss');
      }
    },
    {
      title: '操作',
      dataIndex: 'x',
      render: (_, record) => (
        <Space size="middle">
          {/*<Button type="link" onClick={() => editClick(record)}>*/}
          {/*  编辑*/}
          {/*</Button>*/}
          <Popconfirm
            placement="left"
            title={"确认删除该组件？"}
            onConfirm={() => deleteClick(record)}
            okText="删除"
            cancelText="取消"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const tableProps = {
    columns: columns,
    api: Dependency_List_URL,
    pagination: {
      currPage: 1,
      pageSize: 10
    },
    initParams: {
      tenant: ModelingApi.getTenant()
    }
  }

  return tableProps
}

/**
 * 搜索配置项
 * @param refreshTable
 */
export function searchProps(refreshTable: any) {
  const searchConfig: any[] = [
    {
      name: 'groupId',
      label: 'groupId',
      itemType: 'input',
      placeholder: '',
      allowClear: true,
      col: 4
    },
    {
      name: 'artifactId',
      label: 'artifactId',
      itemType: 'input',
      placeholder: '',
      allowClear: true,
      col: 4
    },
    {
      name: 'version',
      label: 'version',
      itemType: 'input',
      placeholder: '',
      allowClear: true,
      col: 4
    },
  ]

  const searchProps = {
    option: searchConfig,
    onSearch: refreshTable,
    btnBoolean: true
  }

  return searchProps
}

export function modalProps(refreshTable: any) {
  //新增表单项
  const ModalConfig: any[] = [
    {
      itemType: "input",
      placeholder: "请输入",
      name: "groupId",
      label: "groupId",
      allowClear: true,
      col: 24,
      maxLength: 120,
      rules: [{required: true, message: "请输入!"}],
    },
    {
      itemType: "input",
      placeholder: "请输入",
      name: "artifactId",
      label: "artifactId",
      allowClear: true,
      col: 24,
      maxLength: 120,
      rules: [{required: true, message: "请输入!"}],
    },
    {
      itemType: "input",
      placeholder: "请输入",
      name: "version",
      label: "版本号",
      allowClear: true,
      col: 24,
      maxLength: 120,
      rules: [{required: true, message: "请输入!"}],
    },
  ];

  return {
    apiPost:Dependency_CRUD_URL,
    apiPut:Dependency_CRUD_URL,
    option: ModalConfig,
    refreshTable:refreshTable,
  };
}
