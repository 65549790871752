import React from "react";
import TableList from "@byk/pages/components/TableList";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, Form, Input, message, Row, Col, Select } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import localStorage, {getTenantId} from "utils/localStorage";
import pattern from "@byk/utils/pattern";
import history from "utils/history";
import SearchForm from "@byk/pages/components/SearchForm";
import _ from "lodash";
import { routePath } from "@byk/routes";
import {appTenantClass} from "@byk/utils/Utils";

interface DataType {
  id: React.Key;
}

class MemberManagerList extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  tableAddRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.tableAddRef = React.createRef();
    this.formRef = React.createRef();
  }

  state = {
    roleId: -1,
    tenantId: getTenantId(),
    visibleLoadTableModal: false,
    title: '全部成员',
    type: this.props.type,
    isModalForm: false,
    initParams: {
      includeRelated: true,
      includeList: true,
      tenantId: getTenantId(),
      status: this.props.type == "1" ? "activing" : "noActive"
    },
    formCfg: {
      title: "",
      okText: "确定",
      type: ""
    },
    roleList: [],
    titeType: { "a": "审批成员", "b": "修改角色", "c": "添加成员", "d": "移交管理员" },
    btnTextType: { "a": "通过", "b": "确定", "c": "确定", "d": "确定" },
    isAdmin: this.props.isAdmin,
    teamList: [],
    adminId:null,
  };

  async bindRoleList() {
    let result: any = await Api.get("/byk/platform/rest/TenantRole/list", { tenantId: this.state.tenantId });
    if (result.success) {
      let _r=result.result;
      let _roleList=_r.filter((item:any)=>{return item.projectJob!="admin"});
      let _adminList=_r.filter((item:any)=>{return item.projectJob=="admin"});
      this.setState({ roleList:_roleList,adminId:_adminList&&_adminList[0]&&_adminList[0].id });
    }
  }
  openLoadTableModal = () => {
    this.setState({
      visibleLoadTableModal: true
    });
  };
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.bindRoleList();
    this.bindTeamList();
    //this.init();
  };
  callback(_data: any) {
    this.setState({ type: _data }, () => {
      this.bindTitle();
      this.init();
    });
  };
  async bindTeamList() {
    let r: any = [];
    let result: any = await Api.get("/byk/platform/rest/UserTenant/list", { tenantId: this.state.tenantId, status: "activing",includeRelated:true });
    if (result.success) {
      let _result = result.result;
      _.each(_result, (item: any) => {
        r.push({ name: `${item.alias || item.name}[${item.userMobile}]`, id: item.userId })
      })
    }
    this.setState({ teamList: r })
  }
  bindTitle() {
    this.setState({ title: this.state.type == 1 ? "全部成员" : "待审批" })
  }

  init(params?: any) {
    if (this.tableRef.current) {
      this.tableRef.current.getTableList(params);
    }
    this.bindTeamList();
  };

  showModal1(type: string) {
    this.setState({ isModalForm: true }, () => {
      let _data: any = this.state.formCfg.data;
      if(type == "d"){
        this.formRef.current?.setFieldsValue({ userId: _data.userId,oldAdminNewTenantRole:null});
        return;
      }
      if (type != "c") {
        this.formRef.current?.setFieldsValue({ tenantRole: _data.tenantRole, alias: _data.alias });
      }
      else {
        this.formRef.current?.setFieldsValue({ tenantRole: null, alias: "", mobile: "" });
      }
    });
  };

  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data
      }
    }, () => { this.showModal1(type); });

    //this.formRef.current.resetFields();
  };
  handleOk1 = () => {
    this.setState({ isModalForm: false });
  };

  handleCancel1 = () => {
    this.setState({ isModalForm: false });
  };
  //更新
  updateForm = async (value: any) => {
    let _data: any = {};
    let cfg: any = this.state.formCfg;
    _data.id = cfg.data.id;
    _data.tenantRole = value.tenantRole;
    _data.alias = value.alias;
    //审批
    if (cfg.type == "a") {
      _data.status = "activing";
    }
    //移交管理员
    if(cfg.type == "d"){
      _data={id:cfg.data.id,tenantRole:this.state.adminId,oldAdminNewTenantRole:value.oldAdminNewTenantRole};
    }
    let result: any = null;
    if (cfg.type != "c") {
      result = await Api.put("/byk/platform/rest/UserTenant", _data);
    }
    else {
      delete _data.id;
      _data.tenantRole = _data.tenantRole.toString()
      _data.tenantId = this.state.tenantId;
      _data.mobile = value.mobile;
      //添加成员
      result = await Api.get("/byk/platform/tenant/addUser", _data);
    }
    console.log("_data", _data);
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel1();
      this.init();
      if(cfg.type == "d"){
        history.push(routePath);
      }
    }
    // else {
    //   message.error(result.message);
    // }
  }

  render() {
    // api
    const apiGet: string = "/byk/platform/rest/UserTenant/list";
    const apiDel: string = "/byk/platform/rest/UserTenant";
    const apiUserGet: string = "/byk/platform/rest/SysUser/list";
    //表格refresh
    const refreshTable = (params: any = {}) => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
      this.bindTeamList();
    };


    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
      else {
        message.error(result.message);
      }
    };

    //驳回
    const rejectClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };
    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 80,
      },

      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
        width: 150,
      },
      {
        title: "角色",
        dataIndex: "tenantRoleName",
        key: "tenantRoleName",
        width: 150,
      },
      {
        title: "备注名",
        dataIndex: "alias",
        key: "alias",
      },
      {
        title: "手机号",
        dataIndex: "userMobile",
        key: "userMobile",
        width: 120,
      },
      {
        title: "邮箱",
        dataIndex: "userEmail",
        key: "userEmail",
        width: 200,
      },
    ];

    const columnsIsAdmin: ColumnsType = [
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <>
            <Space style={{ display: record.status == "activing" ? '' : 'none' }} size="middle">
            {
                !record.adminAble&&<Button type="link" onClick={() => { this.openForm("b", record) }}>
                修改角色
              </Button>
              }

              {
                !record.adminAble&&<Button type="link" onClick={() => { this.openForm("d", record) }}>
                移交管理员
              </Button>
              }
              {
                !record.adminAble&&<Popconfirm placement="left" title={"确定移除该成员吗？"} onConfirm={() => deleteClick(record)} okText="确定" cancelText="取消">
                <Button type="link" style={{ color: "red" }}>
                  移除成员
                </Button>
              </Popconfirm>
              }

            </Space>
            <Space size="middle" style={{ display: record.status == "noActive" ? '' : 'none' }} >
              <Button type="link" onClick={() => { this.openForm("a", record) }}>
                通过
              </Button>
              <Popconfirm placement="left" title={`确定驳回账号【${record.userName}】的加入申请吗？`} onConfirm={() => rejectClick(record)} okText="确定" cancelText="取消">
                <Button type="link" style={{ color: "red" }}>
                  驳回
                </Button>
              </Popconfirm>
            </Space>
          </>
        ),
        width: 200,
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      getCheckboxProps: (record: DataType) => ({
        // disabled: record.name === 'Disabled User', // Column configuration not to be checked
        // name: record.name,
      }),
    };

    const tableProps = {
      columns: this.state.isAdmin ? _.concat(columns, columnsIsAdmin) : columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
    };

    //添加成员
    //表格配置
    const columns1: ColumnsType = [
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "邮箱",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
      }
    ];
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "姓名",
        allowClear: true,
        col: 6,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "sysUser.mobile",
        label: "手机号",
        allowClear: true,
        col: 6,
      }
    ];
    const searchProps = {
      option: searchConfig,
      onSearch: refreshTable,
      btnBoolean: true,
      btnWrapBoolean: false
      //btnWrapBoolean: stubFalse
    };
    const tableProps1 = {
      columns: columns1,
      api: apiUserGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
    };
    const handleOk = () => {

    }

    const handleCancel = () => {
      this.setState({ visibleLoadTableModal: false });
    }
    return (
      <>
        <div className="x-main-box">
          <div style={{ width: "100%" }}>
            <SearchForm ref={this.searchRef} {...searchProps} />
            {this.state.isAdmin && <>
              <Button type="primary" onClick={() => this.openForm("c", {})} className="x-fr x-ml20" style={{ marginBottom: "10px" }}>
                添加成员
              </Button>
            </>}
          </div>
          <TableList ref={this.tableRef} {...tableProps} />

          <Modal title="添加成员" cancelText="取消" okText="确定" width="90%" open={this.state.visibleLoadTableModal} onOk={handleOk} onCancel={handleCancel}>
            <TableList ref={this.tableAddRef} {...tableProps1} />
          </Modal>

          <Modal title={this.state.formCfg.title} open={this.state.isModalForm} onOk={() => this.showModal1} onCancel={this.handleCancel1} footer={[]}>
            <Row>
              <Col className="gutter-row" md={24}>
                <Form ref={this.formRef} layout={"vertical"} onFinish={this.updateForm}>
                  {this.state.formCfg.type == "c" && (
                    <>
                      <Form.Item label="成员账号" name="mobile"
                        rules={[{ required: true, message: "请输入成员账号!" },
                        {
                          pattern: pattern.mobile,
                          message: "请输入正确的手机号"
                        }]}>
                        <Input placeholder="请输入成员账号" maxLength={11} />
                      </Form.Item>
                    </>
                  )}
                   {this.state.formCfg.type == "d" && (
                    <>
                      <Form.Item label="将管理员移交给" name="userId"
                        rules={[{ required: true, message: "请输入!" }]}>
                        <Select
                          disabled
                          style={{ width: "100%" }}
                          placeholder="请选择成员"
                          allowClear
                          fieldNames={{ label: 'name', value: 'id' }}
                          options={this.state.teamList}
                        />
                      </Form.Item>
                      <Form.Item label="将原管理员用户的角色设置为" name="oldAdminNewTenantRole"
                        rules={[{ required: true, message: "请选择角色!" }]}>
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择角色"
                          allowClear
                          fieldNames={{ label: 'roleName', value: 'id' }}
                          options={this.state.roleList}
                        />
                      </Form.Item>
                    </>
                  )}
                  {['a', 'b', 'c'].indexOf(this.state.formCfg.type) > -1 && (
                    <>
                      <Form.Item label="角色" name="tenantRole"
                        rules={[{ required: true, message: "请输入!" }]}>
                        <Select
                          style={{ width: "100%" }}
                          placeholder="请选择角色"
                          allowClear
                          fieldNames={{ label: 'roleName', value: 'id' }}
                          options={this.state.roleList}
                        />
                      </Form.Item>
                      <Form.Item label="备注名" name="alias"
                        rules={[{ required: false, message: "请输入备注名!" }]}>
                        <Input placeholder="请输入备注名" maxLength={200} />
                      </Form.Item>
                    </>
                  )}

                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Button onClick={this.handleCancel1}>取消</Button>
                    <Button className="x-ml10" type="primary" htmlType="submit">
                      {this.state.formCfg.okText}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}
export default withRouter(MemberManagerList);
