import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/theme/ambiance.css';
import 'codemirror/addon/display/autorefresh';

import React, {forwardRef, useCallback, useImperativeHandle, useState} from 'react';
import {ModalWrapper} from '../styled';
import styled from "styled-components";

const CodeMirrorWrapper = styled(CodeMirror)`
  .CodeMirror {
    height: 590px;
  }
`

const PublishModelSql = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showModal
  }));
  const [content, setContent] = useState<any>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const showModal = (sql: any) => {
    setContent(sql);
    setIsModalOpen(true);
  };

  const onChange = (editor: any, data: any, value: any) => {
    setContent(value);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const options = {
    autoRefresh: true,
    lineWrapping: true,//自动换行，取消横向滚动条
    // lineNumbers: true,//显示行号
    mode: {name: "text/x-mysql"},
    extraKeys: {"Ctrl": "autocomplete"},
    theme: 'ambiance',
    height: '600px',
    readOnly: true,
  };

  const ShowSql = useCallback(() => {
    return (
      <CodeMirrorWrapper
        value={content}
        options={options}
        onChange={onChange}
      />);
  }, [content]);

  return (
    <ModalWrapper
      className={"publishModel"}
      onCancel={handleCancel} open={isModalOpen}
      okText={'发布模型'}
      footer={null}
      maskClosable={false}
      title={"动态模型发布(变更脚本)"}
      width={'60%'}
      bodyStyle={{
        padding: 5,
        height: 600,
      }}
    >
      <ShowSql/>
    </ModalWrapper>
  );
})

export default PublishModelSql;
