import styled from "@emotion/styled";
import {color} from "@byk/pages/QueryBuilder/lib/colors";

export const ToggleItem = styled.li`
  margin-bottom: 0.5rem;
  padding: 1rem 0.5rem 0.5rem;
  border-bottom: 1px solid ${color("border")};
`;

export const ColumnItem = styled.li`
  padding: 0 0.5rem 0.5rem;
`;
