import moment from "moment-timezone";
import {HelpText, HelpTextConfig} from "./types";
import {formatIdentifier, formatStringLiteral,} from "./";

const getDescriptionForNow: HelpTextConfig["description"] = (
  reportTimezone,
) => {
  const timezone = "UTC";
  const nowAtTimezone = getNowAtTimezone(timezone, reportTimezone);
  return "";
};

const getNowAtTimezone = (
  timezone: string | undefined,
  reportTimezone: string | undefined,
) =>
  timezone && reportTimezone
    ? moment().tz(reportTimezone).format("LT")
    : moment().format("LT");

const HELPER_TEXT_STRINGS: HelpTextConfig[] = [
  {
    name: "count",
    structure: "Count",
    description: () => "返回选定数据中的行数。",
  },
  {
    name: "cum-count",
    structure: "CumulativeCount",
    description: () => `突破中行的总和。`,
  },
  {
    name: "sum",
    structure: "Sum",
    description: () => `将该列的所有数值相加。`,
    args: [
      {
        name: `column`,
        description: `要求和的列或数字。`,
        example: formatIdentifier(`Subtotal`),
      },
    ],
  },
  {
    name: "cum-sum",
    structure: "CumulativeSum",
    description: () => `突破中列的滚动总和。`,
    args: [
      {
        name: `column`,
        description: `要求和的列或数字。`,
        example: formatIdentifier(`Subtotal`),
      },
    ],
  },
  {
    name: "distinct",
    structure: "Distinct",
    description: () => `这一列中的独立值的数量`,
    args: [
      {
        name: `column`,
        description: `要计数其唯一值的列。`,
        example: formatIdentifier(`Last Name`),
      },
    ],
  },
  {
    name: "stddev",
    structure: "StandardDeviation",
    description: () => `计算该列的标准偏差。`,
    args: [
      {
        name: `column`,
        description: `要获得标准偏差的数字列。`,
        example: formatIdentifier(`Population`),
      },
    ],
  },
  {
    name: "avg",
    structure: "Average",
    description: () => `返回该列中数值的平均值。`,
    args: [
      {
        name: `column`,
        description: `平均值的数值列。`,
        example: formatIdentifier(`Quantity`),
      },
    ],
  },
  {
    name: "median",
    structure: "Median",
    description: () => `返回指定列的中位数值。`,
    args: [
      {
        name: `column`,
        description: `平均值的数值列。`,
        example: formatIdentifier(`Quantity`),
      },
    ],
  },
  {
    name: "min",
    structure: "Min",
    description: () => `返回在该列中发现的最小的值。`,
    args: [
      {
        name: `column`,
        description: `要查找其最小值的数字列。`,
        example: formatIdentifier(`Salary`),
      },
    ],
  },
  {
    name: "max",
    structure: "Max",
    description: () => `返回在该列中发现的最大值。`,
    args: [
      {
        name: `column`,
        description: `要查找其最大值的数字列。`,
        example: formatIdentifier(`Age`),
      },
    ],
  },
  {
    name: "share",
    structure: "Share",
    description: () =>
      `返回数据中符合条件的行的百分比，为小数。`,
    args: [
      {
        name: `condition`,
        description: `应该评估为真或假的东西。`,
        example: `${formatIdentifier(`Source`)} = ${formatStringLiteral(
          `Google`,
        )}`,
      },
    ],
  },
  {
    name: "count-where",
    structure: "CountIf",
    description: () => `只计算条件为真的行。`,
    args: [
      {
        name: `condition`,
        description: `应该评估为真或假的东西。`,
        example: `${formatIdentifier(`Subtotal`)} > 100`,
      },
    ],
  },
  {
    name: "sum-where",
    structure: "SumIf",
    description: () =>
      `只对条件为真的行进行求和。`,
    args: [
      {
        name: `column`,
        description: `要求和的数字列。`,
        example: formatIdentifier(`Subtotal`),
      },
      {
        name: `condition`,
        description: `应该评估为真或假的东西。`,
        example: `${formatIdentifier(`订单状态`)} = ${formatStringLiteral(
          `无效`,
        )}`,
      },
    ],
  },
  {
    name: "var",
    structure: "Variance",
    description: () => `返回一个给定列的数字方差。`,
    args: [
      {
        name: `column`,
        description: `要获取方差的列或数字。`,
        example: formatIdentifier(`Temperature`),
      },
    ],
  },
  {
    name: "median",
    structure: "Median",
    description: () => `返回指定列的中位数值。`,
    args: [
      {
        name: `column`,
        description: `要获得中位数的列或数字。`,
        example: formatIdentifier(`Age`),
      },
    ],
  },
  {
    name: "percentile",
    structure: "Percentile",
    description: () =>
      `返回百分位值处的列的值。`,
    args: [
      {
        name: `column`,
        description: `要获得百分位数的列或数字。`,
        example: formatIdentifier(`Score`),
      },
      {
        name: `percentile-value`,
        description: `The value of the percentile.`,
        example: "0.9",
      },
    ],
  },
  {
    name: "lower",
    structure: "lower",
    description: () => `返回所有小写字母的文本字符串。`,
    args: [
      {
        name: `text`,
        description: `含有要转换为小写的数值的列。`,
        example: formatIdentifier(`Status`),
      },
    ],
  },
  {
    name: "upper",
    structure: "upper",
    description: () => `返回所有大写字母的文本。`,
    args: [
      {
        name: `text`,
        description: `含有要转换为大写的数值的列。`,
        example: formatIdentifier(`Status`),
      },
    ],
  },
  {
    name: "substring",
    structure: "substring",
    description: () => `返回所提供文本的一部分。`,
    args: [
      {
        name: `text`,
        description: `要返回一部分的列或文本。`,
        example: formatIdentifier(`Title`),
      },
      {
        name: `position`,
        description: `开始复制字符的位置。 索引位置从1开始。`,
        example: "1",
      },
      {
        name: `length`,
        description: `要返回的字符数。`,
        example: "10",
      },
    ],
    docsPage: "substring",
  },
  {
    name: "regex-match-first",
    structure: "regexextract",
    description: () =>
      `根据正则表达式提取匹配的子字符串。`,
    args: [
      {
        name: `text`,
        description: `要搜索的栏目或文本。`,
        example: formatIdentifier(`Address`),
      },
      {
        name: `regular_expression`,
        description: `要匹配的正则表达式。`,
        example: formatStringLiteral("[0-9]+"),
      },
    ],
    docsPage: "regexextract",
  },
  {
    name: "concat",
    structure: "concat",
    description: () => `将两个或更多的文字串组合在一起。`,
    args: [
      {
        name: `value1`,
        description: `要开始的栏目或文本。`,
        example: formatIdentifier(`Last Name`),
      },
      {
        name: `value2`,
        description: `这将被添加到value1的末尾。`,
        example: formatStringLiteral(", "),
      },
      {
        name: "…",
        description: `这将被添加到value2的末尾。`,
        example: formatIdentifier(`First Name`),
      },
    ],
    docsPage: "concat",
  },
  {
    name: "replace",
    structure: "replace",
    description: () => `Replaces a part of the input text with new text.`,
    args: [
      {
        name: `text`,
        description: `The column or text to search through.`,
        example: formatIdentifier(`Title`),
      },
      {
        name: `find`,
        description: `The text to find.`,
        example: formatStringLiteral(`Enormous`),
      },
      {
        name: `replace`,
        description: `The text to use as the replacement.`,
        example: formatStringLiteral(`Gigantic`),
      },
    ],
  },
  {
    name: "length",
    structure: "length",
    description: () => `Returns the number of characters in text.`,
    args: [
      {
        name: `text`,
        description: `The column or text you want to get the length of.`,
        example: formatIdentifier(`Comment`),
      },
    ],
  },
  {
    name: "trim",
    structure: "trim",
    description: () =>
      `Removes leading and trailing whitespace from a string of text.`,
    args: [
      {
        name: `text`,
        description: `The column or text you want to trim.`,
        example: formatIdentifier(`Comment`),
      },
    ],
  },
  {
    name: "rtrim",
    structure: "rtrim",
    description: () => `Removes trailing whitespace from a string of text.`,
    args: [
      {
        name: `text`,
        description: `The column or text you want to trim.`,
        example: formatIdentifier(`Comment`),
      },
    ],
  },
  {
    name: "ltrim",
    structure: "ltrim",
    description: () => `Removes leading whitespace from a string of text.`,
    args: [
      {
        name: `text`,
        description: `The column or text you want to trim.`,
        example: formatIdentifier(`Comment`),
      },
    ],
  },
  {
    name: "abs",
    structure: "abs",
    description: () =>
      `Returns the absolute (positive) value of the specified column.`,
    args: [
      {
        name: `column`,
        description: `The column or number to return absolute (positive) value of.`,
        example: formatIdentifier(`Debt`),
      },
    ],
  },
  {
    name: "floor",
    structure: "floor",
    description: () => `Rounds a decimal number down.`,
    args: [
      {
        name: `column`,
        description: `The column or number to round down.`,
        example: formatIdentifier(`Price`),
      },
    ],
  },
  {
    name: "ceil",
    structure: "ceil",
    description: () => `Rounds a decimal number up.`,
    args: [
      {
        name: `column`,
        description: `The column or number to round up.`,
        example: formatIdentifier(`Price`),
      },
    ],
  },
  {
    name: "round",
    structure: "round",
    description: () =>
      `Rounds a decimal number either up or down to the nearest integer value.`,
    args: [
      {
        name: `column`,
        description: `The column or number to round to nearest integer.`,
        example: formatIdentifier(`Temperature`),
      },
    ],
  },
  {
    name: "sqrt",
    structure: "sqrt",
    description: () => `Returns the square root.`,
    args: [
      {
        name: `column`,
        description: `The column or number to return square root value of.`,
        example: formatIdentifier(`Hypotenuse`),
      },
    ],
  },
  {
    name: "power",
    structure: "power",
    description: () => `Raises a number to the power of the exponent value.`,
    args: [
      {
        name: `column`,
        description: `The column or number raised to the exponent.`,
        example: formatIdentifier(`Length`),
      },
      {
        name: `exponent`,
        description: `The value of the exponent.`,
        example: "2",
      },
    ],
  },
  {
    name: "log",
    structure: "log",
    description: () => `Returns the base 10 log of the number.`,
    args: [
      {
        name: `column`,
        description: `The column or number to return the natural logarithm value of.`,
        example: formatIdentifier(`Value`),
      },
    ],
  },
  {
    name: "datetime-diff",
    structure: "datetimeDiff",
    description: () =>
      `Get the difference between two datetime values (datetime2 minus datetime1) using the specified unit of time.`,
    args: [
      {
        name: `datetime1`,
        description: `The column or expression with your datetime value.`,
        example: formatIdentifier(`Created At`),
      },
      {
        name: `datetime2`,
        description: `The column or expression with your datetime value.`,
        example: formatIdentifier(`Shipped At`),
      },
      {
        name: `unit`,
        description: `Choose from: ${"year"}, ${"quarter"}, ${"month"}, ${"week"}, ${"day"}, ${"hour"}, ${"minute"}, ${"second"}, or ${"millisecond"}.`,
        example: formatStringLiteral("month"),
      },
    ],
    docsPage: "datetimediff",
  },
  {
    name: "exp",
    structure: "exp",
    description: () =>
      `Returns Euler's number, e, raised to the power of the supplied number.`,
    args: [
      {
        name: `column`,
        description: `The column or number to return the exponential value of.`,
        example: formatIdentifier(`Interest Months`),
      },
    ],
  },
  {
    name: "contains",
    structure: "contains",
    description: () => `Checks to see if string1 contains string2 within it.`,
    args: [
      {
        name: `string1`,
        description: `The column or text to check.`,
        example: formatIdentifier(`Status`),
      },
      {
        name: `string2`,
        description: `The string of text to look for.`,
        example: formatStringLiteral(`Pass`),
      },
    ],
  },
  {
    name: "does-not-contain",
    structure: "doesNotContain",
    description: () =>
      `Checks to see if string1 does not contain string2 within it.`,
    args: [
      {
        name: `string1`,
        description: `The column or text to check.`,
        example: formatIdentifier(`Status`),
      },
      {
        name: `string2`,
        description: `The string of text to look for.`,
        example: formatStringLiteral(`Pass`),
      },
    ],
  },
  {
    name: "starts-with",
    structure: "startsWith",
    description: () =>
      `Returns true if the beginning of the text matches the comparison text.`,
    args: [
      {
        name: `text`,
        description: `The column or text to check.`,
        example: formatIdentifier(`Course Name`),
      },
      {
        name: `comparison`,
        description: `The string of text that the original text should start with.`,
        example: formatStringLiteral(`Computer Science`),
      },
    ],
  },
  {
    name: "ends-with",
    structure: "endsWith",
    description: () =>
      `Returns true if the end of the text matches the comparison text.`,
    args: [
      {
        name: `text`,
        description: `The column or text to check.`,
        example: formatIdentifier(`Appetite`),
      },
      {
        name: `comparison`,
        description: `The string of text that the original text should end with.`,
        example: formatStringLiteral(`hungry`),
      },
    ],
  },
  {
    name: "between",
    structure: "between",
    description: () =>
      `Checks a date or number column's values to see if they're within the specified range.`,
    args: [
      {
        name: `column`,
        description: `The date or numeric column that should be within the start and end values.`,
        example: formatIdentifier(`Created At`),
      },
      {
        name: `start`,
        description: `The beginning of the range.`,
        example: formatStringLiteral("2019-01-01"),
      },
      {
        name: `end`,
        description: `The end of the range.`,
        example: formatStringLiteral("2022-12-31"),
      },
    ],
  },
  {
    name: "interval",
    structure: "timeSpan",
    description: () => `Gets a time interval of specified length`,
    args: [
      {
        name: `number`,
        description: `Period of interval, where negative values are back in time.`,
        example: "7",
      },
      {
        name: `text`,
        description: `Type of interval like ${"day"}, ${"month"}, ${"year"}.`,
        example: formatStringLiteral("day"),
      },
    ],
  },
  {
    name: "time-interval",
    structure: "interval",
    description: () =>
      `Checks a date column's values to see if they're within the relative range.`,
    args: [
      {
        name: `column`,
        description: `The date column to return interval of.`,
        example: formatIdentifier(`Created At`),
      },
      {
        name: `number`,
        description: `Period of interval, where negative values are back in time.`,
        example: "-1",
      },
      {
        name: `text`,
        description: `Type of interval like ${"day"}, ${"month"}, ${"year"}.`,
        example: formatStringLiteral("month"),
      },
    ],
  },
  {
    name: "relative-datetime",
    structure: "relativeDateTime",
    description: () => `Gets a timestamp relative to the current time`,
    args: [
      {
        name: `number`,
        description: `Period of interval, where negative values are back in time.`,
        example: "-30",
      },
      {
        name: `text`,
        description: `Type of interval like ${"day"}, ${"month"}, ${"year"}.`,
        example: formatStringLiteral("day"),
      },
    ],
  },
  {
    name: "is-null",
    structure: "isnull",
    description: () => `Checks if a column is null`,
    args: [
      {
        name: `column`,
        description: `The column to check.`,
        example: formatIdentifier(`Discount`),
      },
    ],
    docsPage: "isnull",
  },
  {
    name: "not-null",
    structure: "notnull",
    description: () => `Checks if a column is not null`,
    args: [
      {
        name: `column`,
        description: `The column to check.`,
        example: formatIdentifier(`Discount`),
      },
    ],
  },
  {
    name: "is-empty",
    structure: "isempty",
    description: () => `Checks if a column is empty`,
    args: [
      {
        name: `column`,
        description: `The column to check.`,
        example: formatIdentifier(`Name`),
      },
    ],
    docsPage: "isempty",
  },
  {
    name: "not-empty",
    structure: "notempty",
    description: () => `Checks if a column is not empty`,
    args: [
      {
        name: `column`,
        description: `The column to check.`,
        example: formatIdentifier(`Name`),
      },
    ],
  },
  {
    name: "coalesce",
    structure: "coalesce",
    description: () =>
      `Looks at the values in each argument in order and returns the first non-null value for each row.`,
    args: [
      {
        name: `value1`,
        description: `The column or value to return.`,
        example: formatIdentifier(`Comments`),
      },
      {
        name: `value2`,
        description: `If value1 is empty, value2 gets returned if its not empty.`,
        example: formatIdentifier(`Notes`),
      },
      {
        name: "…",
        description: `If value1 is empty, and value2 is empty, the next non-empty one will be returned.`,
        example: formatStringLiteral(`No comments`),
      },
    ],
    docsPage: "coalesce",
  },
  {
    name: "case",
    structure: "case",
    description: () =>
      `Looksan expression against a list of cases and returns the corresponding value of the first matching case, with an optional default value if nothing else is met.`,
    args: [
      {
        name: `condition`,
        description: `Something that should evaluate to true or false.`,
        example: `${formatIdentifier(`Weight`)} > 200`,
      },
      {
        name: `output`,
        description: `The value that will be returned if the preceding condition is true.`,
        example: formatStringLiteral(`Large`),
      },
      {
        name: "…",
        description: `You can add more conditions to test.`,
        example: `${formatIdentifier(`Weight`)} > 150, ${formatStringLiteral(
          `Medium`,
        )}, ${formatStringLiteral(`Small`)}`,
      },
    ],
    docsPage: "case",
  },
  {
    name: "get-year",
    structure: "year",
    description: () =>
      `Takes a datetime and returns an integer with the number of the year.`,
    args: [
      {
        name: `column`,
        description: `The datetime column.`,
        example: formatIdentifier(`Created At`),
      },
    ],
  },
  {
    name: "get-quarter",
    structure: "quarter",
    description: () =>
      `Takes a datetime and returns an integer (1-4) with the number of the quarter in the year.`,
    args: [
      {
        name: `column`,
        description: `The datetime column.`,
        example: formatIdentifier(`Created At`),
      },
    ],
  },
  {
    name: "get-month",
    structure: "month",
    description: () =>
      `Takes a datetime and returns an integer (1-12) with the number of the month in the year.`,
    args: [
      {
        name: `column`,
        description: `The datetime column.`,
        example: formatIdentifier(`Created At`),
      },
    ],
  },
  {
    name: "get-week",
    structure: "week",
    description: () => `Extracts the week of the year as an integer.`,
    args: [
      {
        name: `column`,
        description: `The name of the column with your date or datetime value.`,
        example: formatIdentifier(`Created At`),
      },
      {
        name: `mode`,
        description: `Optional. The default is "ISO".
- ISO: Week 1 starts on the Monday before the first Thursday of January.
- US: Week 1 starts on Jan 1. All other weeks start on Sunday.
- Instance: Week 1 starts on Jan 1. All other weeks start on the day defined in your Metabase localization settings.
`,
        example: formatStringLiteral("iso"),
      },
    ],
  },
  {
    name: "get-day",
    structure: "day",
    description: () =>
      `Takes a datetime and returns an integer (1-31) with the number of the day of the month.`,
    args: [
      {
        name: `column`,
        description: `The datetime column.`,
        example: formatIdentifier(`Created At`),
      },
    ],
  },
  {
    name: "get-day-of-week",
    structure: "weekday",
    description: () =>
      `Takes a datetime and returns an integer (1-7) with the number of the day of the week.`,
    args: [
      {
        name: `column`,
        description: `The datetime column.`,
        example: formatIdentifier(`Created At`),
      },
    ],
  },
  {
    name: "get-hour",
    structure: "hour",
    description: () =>
      `Takes a datetime and returns an integer (0-23) with the number of the hour. No AM/PM.`,
    args: [
      {
        name: `column`,
        description: `The datetime column.`,
        example: formatIdentifier(`Created At`),
      },
    ],
  },
  {
    name: "get-minute",
    structure: "minute",
    description: () =>
      `Takes a datetime and returns an integer (0-59) with the number of the minute in the hour.`,
    args: [
      {
        name: `column`,
        description: `The datetime column.`,
        example: formatIdentifier(`Created At`),
      },
    ],
  },
  {
    name: "get-second",
    structure: "second",
    description: () =>
      `Takes a datetime and returns an integer (0-59) with the number of the seconds in the minute.`,
    args: [
      {
        name: `column`,
        description: `The datetime column.`,
        example: formatIdentifier(`Created At`),
      },
    ],
  },
  {
    name: "datetime-add",
    structure: "datetimeAdd",
    description: () => `Adds some units of time to a date or timestamp value.`,
    args: [
      {
        name: `column`,
        description: `The column with your date or timestamp values.`,
        example: formatIdentifier(`Created At`),
      },
      {
        name: `amount`,
        description: `The number of units to be added.`,
        example: "1",
      },
      {
        name: `unit`,
        description: `Choose from: ${"year"}, ${"quarter"}, ${"month"}, ${"week"}, ${"day"}, ${"hour"}, ${"minute"}, ${"second"}, or ${"millisecond"}.`,
        example: formatStringLiteral("month"),
      },
    ],
    docsPage: "datetimeadd",
  },
  {
    name: "datetime-subtract",
    structure: "datetimeSubtract",
    description: () =>
      `Subtracts some units of time to a date or timestamp value.`,
    args: [
      {
        name: `column`,
        description: `The column with your date or timestamp values.`,
        example: formatIdentifier(`Created At`),
      },
      {
        name: `amount`,
        description: `The number of units to be subtracted.`,
        example: "1",
      },
      {
        name: `unit`,
        description: `Choose from: ${"year"}, ${"quarter"}, ${"month"}, ${"week"}, ${"day"}, ${"hour"}, ${"minute"}, ${"second"}, or ${"millisecond"}.`,
        example: formatStringLiteral("month"),
      },
    ],
    docsPage: "datetimesubtract",
  },
  {
    name: "now",
    structure: "now",
    description: getDescriptionForNow,
  },
  {
    name: "convert-timezone",
    structure: "convertTimezone",
    description: () => `Convert timezone of a date or timestamp column.
We support tz database time zone names.
See the full list here: https://w.wiki/4Jx`,
    args: [
      {
        name: `column`,
        description: `The column with your date or timestamp values.`,
        example: formatIdentifier(`Created At`),
      },
      {
        name: `target`,
        description: `The timezone you want to assign to your column.`,
        example: formatStringLiteral("Asia/Ho_Chi_Minh"),
      },
      {
        name: `source`,
        description: `The current time zone. Only required for timestamps with no time zone.`,
        example: formatStringLiteral("UTC"),
      },
    ],
    docsPage: "converttimezone",
  },
];

export const getHelpText = (
  name: string,
  reportTimezone?: string,
): HelpText | undefined => {
  const helperTextConfig = HELPER_TEXT_STRINGS.find(h => h.name === name);

  if (!helperTextConfig) {
    return;
  }

  const {description} = helperTextConfig;

  return {
    ...helperTextConfig,
    example: getHelpExample(helperTextConfig),
    description: description(reportTimezone),
  };
};

const getHelpExample = ({structure, args}: HelpTextConfig): string => {
  const exampleParameters =
    args?.length && args.map(({example}: any) => example).join(", ");

  return `${structure}${exampleParameters ? `(${exampleParameters})` : ""}`;
};

export const getHelpDocsUrl = ({docsPage}: HelpText): string => {
  return docsPage
    ? `questions/query-builder/expressions/${docsPage}`
    : "questions/query-builder/expressions";
};
