//表格配置
import {ColumnsType} from "antd/lib/table";
import {Button, Space} from "antd";
import React from "react";
import {QUERY_URL} from "@byk/pages/ProjectRecycle/api";
import moment from "moment";
import {appTenantClass} from "@byk/utils/Utils";
import {getTenantId} from "../../../utils/localStorage";

/**
 * Table配置项
 * @param deleteCallback
 */
export function tableProps(restore: any, deleteProject: any, userId: any) {
  const columns: ColumnsType<any> = [
    {
      title: '序号',
      dataIndex: 'idx',
      key: 'idx',
      width: '80px',
      render: (text: any, record: any, index: any) => `${index + 1}`,
    },
    {
      title: '项目名称',
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: '项目描述',
      dataIndex: 'itemDesc',
      key: 'itemDesc',
    },
    {
      title: '应用数',
      dataIndex: 'appNum',
      key: 'appNum',
      width: '80px',
    },
    {
      title: '删除者',
      dataIndex: 'deleterName',
      key: 'deleterName',
      width: '160px',
    },
    {
      title: '删除时间',
      dataIndex: 'deleteDate',
      key: 'deleteDate',
      width: '180px',
      render: (_, record: any) => (
        moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
      ),
    },
    {
      title: '操作',
      dataIndex: 'x',
      width: '80px',
      render: (_, record) => {
        let inAdminIds = (record.adminIds?.split(",") || []).indexOf(userId + '') > -1;
        return (
          <>
            {inAdminIds && <Space size="middle">
              <Button type="link" onClick={() => restore(record)}>恢复</Button>
              <Button type="link" onClick={() => deleteProject(record)}>彻底删除</Button>
            </Space>
            }
          </>
        )
      },
    },
  ];

  const tableProps = {
    columns: columns,
    api: QUERY_URL,
    pagination: {
      currPage: 1,
      pageSize: 10
    },
    initParams: {
      related: true,
      tenantId: getTenantId()
    }
  }

  return tableProps
}

/**
 * 搜索配置项
 * @param refreshTable
 */
export function searchProps(refreshTable: any) {
  const searchConfig: any[] = [
    {
      name: 'itemName',
      label: '名称',
      itemType: 'input',
      placeholder: '',
      allowClear: true,
    },
  ]

  const searchProps = {
    option: searchConfig,
    onSearch: refreshTable,
    btnBoolean: true
  }

  return searchProps
}


