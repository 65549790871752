import {Input, Space, Tree} from "antd";
import {MinusOutlined, PaperClipOutlined, PlusOutlined} from "@ant-design/icons/lib";
import type {DataNode, TreeProps} from 'antd/es/tree';
import React, {useEffect, useRef, useState} from "react";
import {uuid4} from "@sentry/utils";
import Api from 'api/Api';
import {
  getCurrentApplicationId,
  getCurrentIframeId,
  getCurrentPageId,
  getFrameDesignLoyoutId,
} from "../../../selectors/editorSelectors";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {getCurrentPageWidgets} from "../../../selectors/entitiesSelector";
import _ from 'lodash';
import {useParams} from "react-router";
import {X_LINKPAGESET, X_LINKPANNELOFFSET, X_OPENLINKPANNEL} from "@byk/store/XReducers";
import axios from "axios";
import {AtIcon} from "taro-ui";
const XTitle = styled.div`
  font-size: 16px;
  display:flex;
  justify-content: space-between;
  margin: 10px 0;
`;
const XWrapper = styled.div<{ marginTop?: string, borderTop?: string }>`
  padding: 0 10px;
  margin-top: ${(props) => props.marginTop ?? 0};
  border-top: ${(props) => props.borderTop ?? 'none'};
`;


export const addMenuKey: any = (valueMenus: any) => {
  if (_.isEmpty(valueMenus)) {
    return [];
  }
  return _.sortBy(valueMenus, 'sort').map((i: any) => {
    return {
      ...i,
      key: i.menuKey || i.key,
      children: i.children && addMenuKey(i.children)
    }
  })
}

const MobileIndex = () => {
  const dispatch = useDispatch();
  const [gData, setGData] = useState([]);
  const gDataCache = useRef([]);
  const appId = useSelector(getCurrentApplicationId);
  const iframeId = useSelector(getCurrentIframeId);
  const currentPageId = useSelector(getCurrentPageId);
  const currentLayoutId = useSelector(getFrameDesignLoyoutId);
  const currentPage = useSelector(getCurrentPageWidgets);
  const paramsObj = useParams<{ pageId: string }>();
  const layoutId = useSelector(state => {
    const pagesList: any = state.entities.pageList.pages.filter((i: any) => {
      return i.pageId == paramsObj.pageId;
    });
    return pagesList?.[0]?.loyoutId;
  });
  const frameDSL = useSelector((state:any)=>{
    return state.XReducers.iframeDSL;
  });
  const linkPannel = useSelector((state: any) => {
    return state.XReducers.linkPannelSet;
  })
  const setLinkPannel = (data: any) => {
    dispatch({
      type: X_LINKPAGESET,
      data
    });
  }
  const setLinkOpen = (data: boolean) => {
    dispatch({
      type: X_OPENLINKPANNEL,
      data
    })
  }

  const loop = (
    data: any,
    key: React.Key,
    callback: (node: any, i: number, data: any) => void,
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children!, key, callback);
      }
    }
  };
  const onDrop: TreeProps['onDrop'] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const data = gData ? _.cloneDeep(gData) : [];
    // Find dragObject
    let dragObj: any;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      ((info.node as any).children || []).length > 0 && // Has children
      (info.node as any).expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: any[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setGData(data as any);
  };
  const temp: any = {
    title: "",
    children: [],
    actionType: '',
    topage: '',
    topageid: '',
    toUrl: '',
    topagetype: '',
    linkParams: '',
  };
  const onAdd = (key?: any) => {
    const data: any = gData ? _.cloneDeep(gData) : [];
    let menuKey = uuid4();
    if (key) {
      loop(data, key, (item) => {
        const children = Array.isArray(item.children) ? [...item.children] : []
        children.push({
          key: menuKey,
          menuKey,
          pid: item.key,
          ...temp
        })
        item.children = children
      });
    } else {
      data.push({
        key: menuKey,
        menuKey,
        ...temp
      })
    }
    setGData(data);
  };
  const onDelete = (key: any) => {
    const data = gData ? _.cloneDeep(gData) : [];
    loop(data, key, (_, index, arr) => {
      arr.splice(index, 1);
    });
    setGData(data as any);
  };
  const onOpen = (nodeData: any) => {
    //console.log(nodeData);
    setLinkPannel({
      ...linkPannel,
      key: nodeData.key,
      appid: appId,
      iframeid: iframeId,
      actionType: nodeData.actionType,
      topage: nodeData.topage,
      topageid: nodeData.topageid,
      toUrl: nodeData.toUrl,
      topagetype: nodeData.topagetype,
      linkParams: nodeData.linkParams,
      icon: nodeData.icon,
      save: false
    })
    setLinkOpen(true);
  }
  const onOpenMenu = (nodeData: any) => {
    setLinkPannelOffset({
      left: 'auto',
      right: '293px',
      top: '224px',
      bottom: 'auto',
      isIcon: true
    })
    onOpen(nodeData);
  }

  // input 改变
  const onChange = (e: any, key: any) => {
    const data: any = gData ? _.cloneDeep(gData) : [];
    loop(data, key, (item) => {
      item.title = e.target.value;
    });
    setGData(data);
  };
  useEffect(() => {
    if (linkPannel.key && linkPannel.save) {
      const data: any = gData ? _.cloneDeep(gData) : [];
      loop(data, linkPannel.key, (item) => {
        item.topage = linkPannel.topage;
        item.topageid = linkPannel.topageid;
        item.topagetype = linkPannel.topagetype;
        item.appid = linkPannel.appid;
        item.iframeid = linkPannel.iframeid;
        item.icon = linkPannel.icon;
        item.actionType = linkPannel.actionType;
        item.toUrl = linkPannel.toUrl;
        item.linkParams = linkPannel.linkParams;
      });
      setGData(data);
    }
  }, [linkPannel]);
  const gDataAddSort = (data: any, pid?: any) => {
    return data.map((i: any, index: number) => {
      return {
        ...i,
        menuKey: i.key,
        key: undefined,
        sort: index + 1,
        pid: pid ? pid : 0,
        children: gDataAddSort(i.children || [], i.key)
      }
    })
  };
  let source: any = null;
  const save = (threeActiveNum?:any, gDataInit?:any) => {
    if (!currentLayoutId && !layoutId) {
      return;
    }
    let params = {
      id: currentLayoutId || layoutId,
      pageId: currentPageId,
      dsl: {
        ...currentPage[0],
        menus: gDataAddSort(gDataInit||gData)
      }
    };
    //console.log('save------------------------------', params);
    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();
    Api.put(`/byk/platform/rest/LayoutConfig`,
      params,
      undefined,
      {cancelToken: source.token}
    ).then((res: any) => {
      let {dsl} = res?.result?.[0];
      dispatch({
        type: 'X_IFRAMEDSL',
        data: dsl
      })
    })
  }
  const setLinkPannelOffset = (offset: any) => {
    dispatch({
      type: X_LINKPANNELOFFSET,
      data: {...offset}
    })
  }
  useEffect(() => {
    if(frameDSL.menus){
      let _gData = addMenuKey(frameDSL.menus);
      gDataCache.current = _gData;
      setGData(_gData);
    }
  }, [frameDSL.menus]);

  const delaySave = () => {
    save();
  };

  useEffect(() => {
    const gDataIsEqual = _.isEqual(gData, gDataCache.current);
    if(!gDataIsEqual){
      delaySave();
      gDataCache.current = gData;
    }
  }, [gData]);

  return (
    <>
      <XWrapper marginTop={'10px'} borderTop={'1px solid #ccc'}>
        <XTitle>
          <div className="x_title_left">菜单</div>
          <div className="x_title_right" onClick={() => {
            onAdd();
          }}><PlusOutlined/></div>
        </XTitle>
        <Tree
          className="draggable-tree"
          defaultExpandAll={true}
          draggable
          blockNode
          onDrop={onDrop}
          treeData={gData.filter((i: any) => {
            return i.key !== 'other';
          })}
          titleRender={(nodeData: DataNode & { readonly: true }) => {
            return (
              <Space>
                {nodeData.readonly ? (
                  <>
                    {nodeData.title}
                  </>
                ) : (
                  <>
                    <Input
                      prefix={nodeData.icon ? (<AtIcon value={nodeData.icon as string} size='30' />) : null}
                      placeholder={'请输入'}
                      defaultValue={nodeData.title as string}
                      onChange={(e) => onChange(e, nodeData.key)}/>
                    <PaperClipOutlined onClick={() => onOpenMenu(nodeData)}/>
                    {/*<PlusOutlined onClick={() => onAdd(nodeData.key)}/>*/}
                    <MinusOutlined onClick={() => onDelete(nodeData.key)}/>
                  </>
                )}
              </Space>
            )
          }}
        />
      </XWrapper>
    </>
  );
};

export default MobileIndex;
