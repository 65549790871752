import {appPublish, appPublishDetail, appResByAppId, AppResType, DYNAMIC_MODEL} from "@byk/pages/AppDeploy/api";
import _ from "lodash";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {PublishModelObj} from "@byk/pages/modeling/lib/PublishModelObj";

class AppResObj {
  publishResData: AppResType[] = []
  publishResDataMap: any = {}
  status: boolean = false
  publishModelObj: PublishModelObj | undefined
  columnsByResType: any = {}
  isView: boolean = false

  async init() {
    let {resTypeList, columnsByResType} = await appResByAppId()
    this.publishResData = resTypeList
    this.columnsByResType = columnsByResType

    this.status = true;
    this.isView = false;
    let count = 0;
    _.forEach(this.publishResData, item => {
      count++
      if (item.rescType === DYNAMIC_MODEL) {
        this.publishModelObj = new PublishModelObj(item.ext)
        this.publishModelObj.isView = false
      }
      this.publishResDataMap[item.value] = item
    })
    return this
  }

  getResDataByType(type: string) {
    return this.publishResDataMap[type] || {}
  }

  getColumnsByType(type: string) {
    return this.columnsByResType[type] || []
  }

  /**
   * 版本发布
   */
  async publish(formData: any) {
    await this.publishModelObj?.setSql();
    let detail: any = []
    let modelData: any = {}
    _.forEach(this.publishResData, (item: any) => {
      if (item.rescType === DYNAMIC_MODEL) {
        if (this.publishModelObj) {
          modelData = this.publishModelObj
          modelData.sql = this.publishModelObj.sql
        }
      }

      detail = detail.concat([...item.data])
    })

    let _detail: any = []
    _.forEach(detail, item => {
      _detail.push({
        rescType: item.rescType,
        rescId: item.rescId,
        remark: item.remark,
        dataAction: item.dataAction,
        dataActionContent: item.dataActionContent,
      })
    })

    // deployType: "1",
    // title: "首次部署111-copy111",
    // version: 1,
    let params: any = {
      ...formData,
      tenant: ModelingApi.getTenant(),
      detail: _detail,
      modelData,
    }

    return await appPublish(params)
  }

  tableResData() {
    return _.filter(this.publishResData, item => item.isShow)
  }

  /**
   * 初始化发布详情
   * @param data
   */
  async initDetail(record: any) {
    let {resTypeList, columnsByResType} = await appPublishDetail(record)
    this.publishResData = resTypeList
    this.columnsByResType = columnsByResType
    this.status = true;
    _.forEach(this.publishResData, item => {
      this.publishResDataMap[item.value] = item
      if (item.rescType === DYNAMIC_MODEL) {
        this.publishModelObj = new PublishModelObj(item.ext)
        this.publishModelObj.isView = true
      }
    })

    this.isView = true
    return this
  }
}

export default AppResObj;
