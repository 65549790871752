const WidgetsEvents: any = {
  "AtNavBarWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the text is clicked",
          "propertyName": "onClickRgIconSt",
          "label": "onClickRgIconSt",
          "controlType": "ACTION_SELECTOR"
        }
      ]
    }
  ],
  "AtTextWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the text is clicked",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR"
        }
      ]
    }
  ],
  "AtButtonWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR"
        }
      ]
    }
  ],
  "AtIconWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR"
        }
      ]
    }
  ],
  "AtListWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR"
        }
      ]
    }
  ]
}

export default WidgetsEvents;
