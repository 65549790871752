import {Badge, Button, Checkbox, Menu, Modal, Spin, Table} from 'antd'
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'

import {IProps} from "@byk/pages/components/SearchForm"
import {DYNAMIC_MODEL, getAppOptionsByAppid} from "@byk/pages/AppDeploy/api";
import PublishModel from "@byk/pages/modeling/Publish/PublishModel";
import AppResObj from "@byk/pages/AppDeploy/AppResObj";
import PublishModal from "@byk/pages/AppDeploy/PublishModal";
import styled from "styled-components";
import {CheckboxValueType} from "antd/lib/checkbox/Group";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }
  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-footer {
    border-top: 1px solid rgba(217, 217, 217, 1) !important;
  }

  .appOption {
    margin-top: 21px;
    font-weight: bold;
    color: #101010;
    font-size: 14px;
    .label {
      margin-right: 10px;
    }
  }

  .ant-spin-container {
    height: 100%;
    width: calc(100% - 2px);
  }
`


const DivContainer = styled.div`
   width: 100%;
   height: 100%;
   display: flex;

  .resList {
    height: 100%;
    width: 200px;
    border-right: 1px solid rgba(217, 217, 217, 1);
    font-weight: bold;

    .resTitle {
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      text-align: left;
      padding-top: 14px;
      padding-left: 19px;
    }

    .menuList {
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: left;
      margin-left: 6px;
      overflow-y: auto;
      height: calc(100% - 40px);

      .ant-menu-title-content {
          transition: color 0.3s;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
      }

      .ant-badge-count {
        color: #006CF2;
        background: #EBF3FF;
        width: 38px;
        font-weight: bold;
      }
    }
  }

  .resContent {
    width: calc(100% - 220px) !important;
    margin: 14px;
    .detailTitle {
      font-weight: bold;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      text-align: left;
      margin-bottom: 16px;
    }
  }
`

interface ModalIProps extends IProps {
  refreshTable: (searchData?: any) => any,
}

const AppDeployModal: React.FC<ModalIProps> = forwardRef((
  {
    refreshTable,
  }, ref) => {

  useImperativeHandle(ref, () => ({
    showModal,
    showDetail,
    handleCancel,
    setConfirmLoading,
    add
  }))

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [formStatus, setFormStatus] = useState('add')
  const publishModelRef = useRef<any>()
  const publishFormRef = useRef<any>()
  const [showPublishModel, setShowPublishModel] = useState(false)
  const [curResType, setCurResType] = useState<any>();
  const [appResObj, setAppResObj] = useState<AppResObj | null>();
  const [isNew, setIsNew] = useState(false)
  const [appOptions, setAppOptions] = useState<any>([]);
  const [appValues, setAppValues] = useState<any>([]);
  const divContainerRef = useRef<any>();

  const showModal = async () => {
    setIsNew(true)
    setCurResType('')
    setIsModalOpen(true)
    setShowPublishModel(false)

    let initAppResObj = new AppResObj()
    await initAppResObj.init()

    setAppResObj(initAppResObj)
  }

  const showDetail = async (record: any) => {
    setCurResType(null)
    setIsModalOpen(true)
    setShowPublishModel(false)
    setIsNew(false)
    let initAppResObj = new AppResObj()
    await initAppResObj.initDetail(record)
    setAppResObj(initAppResObj)
  }

  const add = async () => {
    let _appOptions = await getAppOptionsByAppid();
    setAppOptions(_appOptions);
    let _defaultAppOption = _appOptions.find((item: any) => item.checked);
    setAppValues([_defaultAppOption?.value]);
    setFormStatus('add');
    await showModal()
  }

  const onChange = (checkedValues: CheckboxValueType[]) => {
    let _defaultAppOption = appOptions.find((item: any) => item.checked);
    if (_defaultAppOption && checkedValues.indexOf(_defaultAppOption.value) == -1) {
      setAppValues([_defaultAppOption.value, ...checkedValues]);
    } else {
      setAppValues(checkedValues);
    }
  };

  const handleOk = () => {
    setTimeout(() => {
      publishFormRef.current?.showModal()
    }, publishFormRef.current ? 0 : 100)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setConfirmLoading(false)
    setAppResObj(null)
    setIsNew(false)
  }

  const onClickItem = async (item: any) => {
    setCurResType(item)
    if (DYNAMIC_MODEL == item.value) {
      setTimeout(() => {
        publishModelRef.current.showModal(appResObj?.publishModelObj);
      }, publishModelRef.current ? 0 : 100);
      setShowPublishModel(true)
    } else {
      setShowPublishModel(false)
    }
  }

  const doRefreshTable = () => {
    handleCancel()
    refreshTable();
  }

  const getResDetail = useCallback(() => {
      let width = divContainerRef.current.offsetWidth - 295;
      let height = divContainerRef.current.offsetHeight - 132;
      let scroll = {
        x: width,
        y: height
      };

      let columns = appResObj?.getColumnsByType(curResType.value) || [];
      let data = appResObj?.getResDataByType(curResType.value).data || [];
      return <>
        <div className='detailTitle'>{curResType.label}</div>
        <Table
          className={"createModel"}
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={scroll}
        />
      </>
    },
    [curResType]
  );

  let scrollY = divContainerRef.current?.offsetHeight - 173;

  return (
    <>
      <div className='x-mt10 x-mb10'>
        <Button type="primary" onClick={add}>新增</Button>
      </div>
      <ModalWrapper
        maskClosable={false}
        forceRender={true}
        confirmLoading={confirmLoading}
        cancelText={'取消'}
        title={<>
          {formStatus === 'add' ? '新增发布' : '编辑发布'}
          <div className='appOption'>
            <span className='label'>请选择要发布的应用:</span>
            <Checkbox.Group
              options={appOptions}
              value={appValues}
              onChange={onChange}
            />
          </div>
        </>}
        okText={isNew ? "发布" : null}
        okButtonProps={{disabled: !appResObj?.status, hidden: !isNew}}
        open={isModalOpen} onOk={handleOk}
        onCancel={handleCancel}
        width={'80%'}
        style={{top: '20px', bottom: '20px',}}
        bodyStyle={{height: 'calc(100vh - 250px)', display: "flex", padding: "0px"}}
      >

        <DivContainer ref={divContainerRef}>
          <Spin spinning={!appResObj?.status}>
            <div className='resList'>
              <div className='resTitle'>发布资源一览</div>
              <Menu className='menuList'>
                {appResObj?.publishResData && appResObj?.tableResData().map((item: any) => {
                  return (
                    <Menu.Item key={item.value} onClick={() => onClickItem(item)} style={{display: "flex"}}>
                      <span>{item.label}</span>
                      {item.amount > 0 && <Badge count={item.amount} showZero/>}
                    </Menu.Item>
                  )
                })}
              </Menu>
            </div>
          </Spin>

          <div className='resContent'>
            {!showPublishModel && curResType && (
              getResDetail()
            )}

            {showPublishModel && (<PublishModel ref={publishModelRef} scrollY={scrollY}/>)}
          </div>

        </DivContainer>
      </ModalWrapper>

      <PublishModal appId={appValues} ref={publishFormRef} appResObj={appResObj} refreshTable={doRefreshTable}/>
    </>
  )
})

export default AppDeployModal
