export default function getWidgetStyles(i:any){
  let _style:string = '';
  if(i.color&&!['#000', 'undefined'].includes(i.color)){
    _style += `color: '${i.color}',`;
  }
  if(i.fontSize&&i.fontSize!=14){
    _style += `fontSize: '${i.fontSize}px',`;
  }
  if(i.bgColor&&i.bgColor!='undefined'&&i.bgColor!='transparent'){
    _style += `backgroundColor: '${i.bgColor}',`;
  }
  if(i.backgroundColor){
    _style += `backgroundColor: '${i.backgroundColor}',`;
  }
  if(i.borderColor){
    _style += `borderColor: '${i.borderColor}',`;
  }
  if(i.fontStyle&&i.fontStyle.includes('italic')){
    _style += `fontStyle: 'italic',`;
  }
  if(i.fontStyle&&i.fontStyle.includes('bold')){
    _style += `fontWeight: 'bold',`;
  }
  if(i.textAlign&&i.textAlign!='left'){
    _style += `textAlign: '${i.textAlign}',`;
  }
  if(i.isCircle){
    _style += `borderRadius: '50%',`;
  }
  if(_style){
    return ` style={{ ${_style} }} `;
  }else{
    return '';
  }

}
