export default function Config ():string{
  return `
  import {all, put, takeLatest} from "redux-saga/effects";
import {ReduxActionTypes} from "../constants/ReduxActionConstants";
import {dataTeeAction} from "../actions";

function* setDataTree(action:any): any {
  yield put(dataTeeAction({
    ...action.payload
  }))
}

export function* watchXActionDataTree() {
  yield all([
    takeLatest(
      ReduxActionTypes.X_SET_DATATREE_ACTION,
      setDataTree
    ),
  ]);
}



  `;
}
