import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout } from 'antd';
import BusinessPage from "@byk/pages/Tenant/TenantInfo/main";
import { uuid2 } from "@byk/utils/Utils";

const { Content, Sider } = Layout;

export default class Home extends Component {
    state = {
        key: uuid2(),
    };
    
    changeKey=()=>{
        this.setState({key: uuid2()});
    }
     render() {
        return (
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head title="TenantInfo" />
                    <Layout>
                        <Sider className="Sider-Navbar"><Navbar key={this.state.key} title="TenantInfo"  /></Sider>
                        <Content><BusinessPage callBack={()=>{this.changeKey()}}/></Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

