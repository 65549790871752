import withWidgetProps, {handleTextFun} from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XButton from "../../components/widgets/XButton";';
  begin += `<XButton propertyPath={${i.propertyPath}}`;
  begin += getWidgetStyles(i);
  if(i.onClick){
    begin += ` onClick={()=>{
                      evalEvent['${i.widgetName}Click'](dataTree, dispatch);
                    }}`;
  }
  begin += `>{${handleTextFun({
    str: i.label,
    widgetNames,
    record
  })}}</XButton>`;
  return { begin, head };
};
