import _ from "lodash";

/**
 * 获取所有动态模型定义
 */
export const getModelMetaSelectOptions = (models: any = []) => {
  let ret: any = []
  _.forEach(models, item => {
    ret.push({
      label: item.description,
      value: item.description,
    })
  })

  return ret
}
