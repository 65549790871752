import React, {useEffect, useState} from "react";
import PopoverWithTrigger from "@byk/pages/QueryBuilder/components/ui/PopoverWithTrigger";
import Join from "@byk/pages/QueryBuilder/lib/queries/structured/Join";
import {
  JoinTypeOptionRoot,
  JoinTypeSelectRoot
} from "@byk/pages/QueryBuilder/components/notebook/steps/JoinStep/JoinStep.styled";
import {JOIN_OPERATOR_OPTIONS} from "@byk/pages/QueryBuilder/components/notebook/steps/JoinStep/JoinStep";
import {FIELDS_PICKER_STYLES} from "@byk/pages/QueryBuilder/components/notebook/Notebook.styled";


export interface JoinOptionsPickerProps {
  join: Join,
  color: string,
  defaultValue: any,
  onChange: (value: any) => void;
}

const JoinOptionsPicker: React.FC<JoinOptionsPickerProps> =
  ({
     join,
     color,
     onChange,
     defaultValue,
   }: JoinOptionsPickerProps) => {
    const [curOption, setCurOption] = useState(defaultValue);

    useEffect(() => {
      setCurOption(defaultValue)
    }, [])

    return (
      <PopoverWithTrigger
        style={{
          width: "80px"
        }}
        triggerStyle={FIELDS_PICKER_STYLES.trigger}
        triggerElement={
          <div style={{
            display: "flex",
            padding: "6px 12px",
            backgroundColor: "#509EE3",
            borderRadius: "6px",
            color: "white",
            marginTop: "5px",
          }}>
            {curOption.value}
          </div>
        }
      >

        {({onClose}: any) => (
          <JoinTypeSelectRoot>
            {JOIN_OPERATOR_OPTIONS.map(option => (
              <JoinTypeOptionRoot
                key={option.value}
                isSelected={option.value === curOption.value}
                onClick={() => {
                  setCurOption(option);
                  onChange(option.value);
                  onClose();
                }}
              >
                {option.label}
              </JoinTypeOptionRoot>
            ))}
          </JoinTypeSelectRoot>
        )}

      </PopoverWithTrigger>
    );

  };
export default JoinOptionsPicker;

