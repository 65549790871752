import withWidgetProps from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XIcon from "../../components/widgets/XIcon";';
  begin += `<XIcon iconName="${i.iconName}" propertyPath={${i.propertyPath}} color="${i.color}" size={'${i.size}'} `;
  begin += getWidgetStyles(i);
  if(i.onClick){
    begin += ` onClick={()=>{
                      evalEvent['${i.widgetName}Click'](dataTree, dispatch);
                    }}`;
  }
  begin += ' />';
  return {begin, head};
};
