import React, {Component} from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import {Layout, PageHeader} from 'antd';
import BusinessPage from "@byk/pages/ProjectRecycle/main";
import {uuid2} from "@byk/utils/Utils";

const {Content, Sider} = Layout;

export default class Home extends Component {
  state = {
    key: uuid2(),
  };

  changeKey = () => {
    this.setState({key: uuid2()});
  }

  render() {
    return (
      <div>
        <Layout>
          {/*引入头部 */}
          <Head title="MyTask"/>
          <Layout>
            <Sider className="Sider-Navbar">
              <Navbar key={this.state.key} title="ProjectRecycle"/>
            </Sider>
            <Content>
              <PageHeader className="site-page-header-main" title="项目回收站"/>
              <BusinessPage callBack={() => {
                this.changeKey()
              }}/>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

