export default function Config ():string{
  return `
import {all, call, put, select, takeLatest} from "redux-saga/effects";
import {ReduxActionTypes} from "../constants/ReduxActionConstants";
import {toastAction} from "../actions";
import {X_SET_TOAST} from "../reducers/toast";
import getWidgetValue from "../utils/getWidgetValue";
import {reEventsActions} from "./reEventsActions";
import _ from "lodash";
function* showAlertApi({ message, status, isOpened }:{ message:string, status:string, isOpened: boolean }){
  yield put(toastAction({
    type: X_SET_TOAST,
    payload: {
      message,
      status,
      isOpened
    }
  }));
}

function* api(config:any, widgetTree:any) {
  let pageParams:Record<string, unknown> = yield call(getWidgetValue, widgetTree);
  try{
    const result:Record<string, unknown> = yield call(reEventsActions, { ...config }, {
      apiParams: {
        ...pageParams,
        ...config.apiParams
      },
      eventParams: {
        ...config.apiParams
      }
    });
  }catch(error:any){
    console.error('api_catch', error);
  }
}


function* postApi(config:any, widgetTree:any){
  console.log('post>>>>>>>config>', config);
  yield call(api, config, widgetTree);
}

const fn = (da:any, widgetTree:any)=>{
  let reJsStrFn = (data:any)=>{
    let jsStr = '';
    _.each(data, (i)=>{
      if(i.type == "block"){
        let {type, values, actionConfig:config, changeStatus, exp} = i.children[0];
        if(type == 'if'){
          _.each(i.children, j=>{
            if(j.type == "else"){
              jsStr += \`else{
\t\t\t\t\t\t  \${reJsStrFn(j.children)}
\t\t\t\t\t\t}\`;
            }else{
              let ifStr = "";
              if(changeStatus&&changeStatus.value === true){//表达式
                let v = exp?.value;
                if(v){
                  let arr1:any = v.split('&&');
                  arr1 = arr1.map((i:any)=>{
                    return \`widgetTree.\${i}\`;
                  });
                  arr1 = arr1.join('&&');
                  ifStr = arr1;
                }
              }else{
                if(!_.isEmpty(values)){
                  _.each(values, (value, key)=>{
                    ifStr += ifStr==""?"(":"||(";
                    _.each(value, (k)=>{
                      if(!ifStr.endsWith('(')){
                        ifStr += "&&"
                      }
                      if(k.b == '6'){
                        ifStr += \`widgetTree.\${k.a}.includes("\${k.c}")\`;
                      }else if(k.b == '7'){
                        ifStr += \`!widgetTree.\${k.a}.includes("\${k.c}")\`;
                      }else if(k.b == '8'){
                        ifStr += \`widgetTree.\${k.a}==""\`;
                      }else if(k.b == '9'){
                        ifStr += \`widgetTree.\${k.a}!=""\`;
                      }else{
                        ifStr += \`widgetTree.\${k.a}\${keyToValue[k.b]}"\${k.c}"\`;
                      }

                    })
                    ifStr += ")";
                  })
                }
              }
              jsStr += \`\${j.type == 'elseIf' ? 'else if' : j.type}(\${ifStr}){
\t\t\t\t\t\t  \${reJsStrFn(j.children)}
\t\t\t\t\t\t}\`;
            }
          })
        }
        if(type == 'action'){
          jsStr += \`yield call(\${config.action}Api, {
            "message":"\${config.message}",
            "type": "\${config.type}",
            "action": "\${config.action}",
            "actionId": "\${config.actionId}",
            "dictId": "\${config.dictId}",
            "navigateBlank": "\${config.navigateBlank}",
            "navigatePage": "\${config.navigatePage}",
            "navigatePageType": "\${config.navigatePageType}",
            "navigateURL": "\${config.navigateURL}",
            "navigateParams": \${config.navigateParams},
            "modalName": "\${config.modalName}",
            "apiTarget": "\${config.apiTarget}",
            "apiParams": \${config.apiParams || "{}"},
            "apiDescription": "\${config.apiDescription}",
            "linkageId": "\${config.linkageId}",
            "linkageEvent": "\${config.linkageEvent}",
            "jsPath": "\${config.jsPath}",
            "success": \${JSON.stringify(i.children[1])},
            "fail": \${JSON.stringify(i.children[2])},
            "currPage": widgetTree.currPage,
            "pageSize": widgetTree.pageSize
          }, widgetTree);\`;
        }
      }
    });
    return jsStr;
  }
  let jsStr = reJsStrFn(da);
  const funList = [postApi, widgetTree, call];
  const funListStr = ['postApi', 'widgetTree', 'call'];
  const jsFunction = new Function(...funListStr,  \`
    function* generate(){
      \${jsStr}
    }
    return [...generate()];
  \`);
  return jsFunction(...funList);
};

export function* xExecuteActionTriggers(action:any): any {
  let widgetTree = yield select((state:any)=>{
    return state.dataTreeReducer.dataTree;
  });
  let response: unknown[] = [];
  const {dynamicString} = action.payload;
  const js = dynamicString.children;
  yield* fn(js, widgetTree);
  return response;
}

export function* watchXActionExecutionSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.X_ACTION_EXECUTION,
      xExecuteActionTriggers
    ),
  ]);
}

  `;
}
