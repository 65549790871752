import React from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import TaroConfigFiles from './DefaultFiles';
import ReducersDataTree from "./project/src/reducers/dataTree";
import {useSelector} from "react-redux";
import {getDataTree} from "../../selectors/dataTreeSelectors";
import SrcAppConfig from "./project/src/AppConfig";
import PagesDslIndex from "./project/src/pages/dsl";
import PagesDslScss from "./project/src/pages/dsl/IndexScss";
import PagesDslConfig from "./project/src/pages/dsl/IndexConfig";
import PagesDslEvent from "./project/src/pages/dsl/event";
import PagesDslJSObject1 from "./project/src/pages/dsl/JSObject1";

import PagesLogin from "./project/src/pages/login/Index";
import PagesLoginScss from "./project/src/pages/login/IndexScss";
import PagesLoginConfig from "./project/src/pages/login/IndexConfig";
import {getCurrentApplicationId} from "../../selectors/editorSelectors";
import {getTenant} from "../../utils/localStorage";
import Api from "../../api/Api";
import _ from "lodash";
//import PagesIndex from "./project/src/pages/index/Index";

function dslToWidgetTree(page:any){
  let caches:any = {};
  let dsl = page.layouts[0].dsl.children;
  const fn = (arr:any)=>{
    _.each(arr, (i)=>{
      caches[i.widgetName] = {...i};
      i.children&&fn(i.children);
    });
  };
  fn(dsl);
  return caches;
}
// 生成多个tsx文件内容的函数
async function generateTSXFiles(dataTree: any, appId:any, tenant:any): Promise<{ [filename: string]: string }> {
  const files: { [filename: string]: string } = TaroConfigFiles();
  //获取pageList
  const pageLists:any = await Api.get(`/byk/${tenant}/subapp/Page/list`, {
    appId: appId,
    includeList: true
  });
  const pageList = pageLists.result;
  // 生成多个文件内容
  files['src/reducers/dataTree.ts'] = ReducersDataTree(dataTree, pageList);
  files['src/app.config.ts'] = SrcAppConfig(pageList);
  //files['src/pages/index/index.tsx'] = await PagesIndex(pageList);
  for (const j in pageList) {
    let i = pageList[j];
    if (i.slug !== 'frameDesignPage') {
      let jsResult:any = await Api.get(`/byk/platform/subapp/Collections/list`, { pageId: i.id });
      let jsBody = jsResult.result?.[0]?.body;
      let pageDataTree = {
        ...dataTree,
        ...dslToWidgetTree(i)
      }
      files[`src/pages/${i.slug}/index.tsx`] = await PagesDslIndex(i, pageDataTree, jsBody);
      files[`src/pages/${i.slug}/index.scss`] = PagesDslScss();
      files[`src/pages/${i.slug}/index.config.ts`] = PagesDslConfig(i);
      files[`src/pages/${i.slug}/event.ts`] = await PagesDslEvent(i, pageDataTree, tenant);
      files[`src/pages/${i.slug}/JSObject1.ts`] = PagesDslJSObject1(jsBody);
    }
  }

  files['src/pages/login/index.tsx'] = PagesLogin(dataTree, appId, tenant);
  files['src/pages/login/index.scss'] = PagesLoginScss();
  files['src/pages/login/index.config.ts'] = PagesLoginConfig();

  return files;
}

// 打包文件并下载
function downloadFiles(files: { [filename: string]: string }) {
  const zip = new JSZip();

  // 将生成的文件内容添加到压缩包中
  for (const filename in files) {
    if (files.hasOwnProperty(filename)) {
      zip.file(filename, files[filename]);
    }
  }

  // 生成压缩包
  zip.generateAsync({ type: 'blob' }).then((content: any) => {
    // 使用file-saver库保存文件
    saveAs(content, 'qtaro.zip');
  });
}

const OutputCode = () => {
  const dataTree = useSelector(getDataTree);
  const appId= useSelector(getCurrentApplicationId);
  const tenant = getTenant();
  // 处理点击事件
  const handleButtonClick = async () => {
    // 生成多个tsx文件
    const files = await generateTSXFiles(dataTree, appId, tenant);
    // 打包文件并下载
    downloadFiles(files);
  };

  return <button onClick={handleButtonClick}>出码1</button>;
};

export default OutputCode;
