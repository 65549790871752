import {Button, Tabs} from 'antd';
import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';

import PublishModelList from "@byk/pages/modeling/Publish/PublishModelList";
import PublishModelSql from "@byk/pages/modeling/Publish/PublishModelSql";
import {PublishModelObj} from "@byk/pages/modeling/lib/PublishModelObj";
import Dmd from "@byk/pages/modeling/ModelData/Dmd";
import styled from 'styled-components';

export interface IProps {
  ref: any,
  scrollY:any,
}

export let publishModelObj: PublishModelObj;

const PublishModel: React.FC<IProps> = forwardRef((props, ref) => {
      useImperativeHandle(ref, () => ({
        showModal
      }));

      const [newModels, setNewModels] = useState<any>([]);
      const [updateModels, setUpdateModels] = useState<any>([]);
      const [deleteModels, setDeleteModels] = useState<any>([]);
      const [isView, setIsView] = useState<boolean>(true);
      const [models, setModels] = useState<any>([]);
      const [sql, setSql] = useState<any>();
      const publishModelSqlRef = useRef<any>();
      const dmCrudRef = useRef<any>();

      const showModal = (publishModelObjParam: any) => {
        publishModelObj = publishModelObjParam || {};

        setNewModels(publishModelObj.news || []);
        setUpdateModels(publishModelObj.updates || []);
        setDeleteModels(publishModelObj.deletes || []);
        setSql(publishModelObj.sql || '');
        setModels(publishModelObj.models || []);
        setIsView(publishModelObjParam.isView);
      };

      const openPublishModelSql = async () => {
        await publishModelObj.setSql();
        setTimeout(() => {
          publishModelSqlRef.current.showModal(publishModelObj.sql);
        }, publishModelSqlRef.current ? 0 : 100);
      }

      const update = (model: any, type: any) => {
        publishModelObj.updateModel(model, type);
      }

      const UpdatePublishModelList = useCallback(() => {
        return <PublishModelList scroll={{y: props.scrollY}} models={updateModels} type={'UPDATE'} update={update} isView={isView}/>
      }, [updateModels, isView]);

      const DeletePublishModelList = useCallback(() => {
        return <PublishModelList scroll={{y: props.scrollY}} models={deleteModels} type={'DELETE'} update={update} isView={isView}/>
      }, [deleteModels, isView]);

      const NewPublishModelList = useCallback(() => {
        return <PublishModelList scroll={{y: props.scrollY}} models={newModels} type={'NEW'} update={update} isView={isView}/>
      }, [newModels, isView]);

      return (
        <>
          <DescDiv>
            <div className='detailTitle'>动态模型</div>
            <Button
              type={'primary'}
              style={{float: "right", margin: '0px'}}
              onClick={openPublishModelSql}
            >
              查看变更脚本
            </Button>
          </DescDiv>


          <Tabs style={{height: '100%',}} key={Math.random()}>
            <Tabs.TabPane tab="变更的模型" key="item-1">
              <UpdatePublishModelList/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="删除的模型" key="item-2">
              <DeletePublishModelList/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="新增的模型" key="item-3">
              <NewPublishModelList/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="模型数据" key="item-4">
              <Dmd ref={dmCrudRef} models={models} scrollY = {props.scrollY}/>
            </Tabs.TabPane>
          </Tabs>

          <PublishModelSql ref={publishModelSqlRef}/>
        </>
      );
    }
  )
;

export default PublishModel;

const DescDiv = styled.div`
    display: flex;
    justify-content: space-between;
`
