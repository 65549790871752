export default function Config ():string{
  return `import {Swiper, SwiperItem} from "@tarojs/components";
import React from "react";
import {useSelector} from "react-redux";
import XBase from "./XBase";
import _ from "lodash";
const styles = {
  'swiperWrapper': {
    height: '100%',
    overflow: 'hidden',
  },
  'swiperImg': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}
function XSwiper(props: any) {
  const { propertyPath, style } = props;
  const widgetData = useSelector((state:any)=>{
    let dataTree = state.dataTreeReducer.dataTree;
    let widget = _.get(dataTree, propertyPath);
    return widget;
  });
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <div style={styles.swiperWrapper}>
        { widgetData.imageData&&widgetData.imageData.length>0?(<Swiper
          customStyle={customStyle}
          indicatorColor='#999'
          indicatorActiveColor='#333'
          circular={widgetData.isCircular}
          indicatorDots={widgetData.isIndicatorDots}
          autoplay={widgetData.isAutoplay}>
          {
            widgetData.imageData.map((i:any)=>{
              return (
                <SwiperItem>
                  <img style={styles.swiperImg} src={i.url} alt="" />
                </SwiperItem>
              )
            })
          }
  
        </Swiper>):(
          <div>暂无数据</div>
        ) }
      </div>
    </XBase>)
}
export default XSwiper;
  `;
}
