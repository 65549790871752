import React, {useCallback, useEffect, useMemo, useState} from "react";
import _ from "lodash";
import history from "utils/history";
import styled from "styled-components";
import {connect, useDispatch, useSelector} from "react-redux";
import type {MenuProps} from "antd";
import {Dropdown, Input, Select, Space} from "antd";
import {addMenuKey} from "../Editor/MenuFrame";
import {X_LANGUAGE_CURR} from "@byk/store/XReducers";
import {getCookie, setCookie} from "../../utils/CookieUtils";
import {ReduxActionTypes} from "../../ce/constants/ReduxActionConstants";
import {getAppCurrUser, getAppLogout} from "../../ce/AppRouter";
import {getAllParentIds, LeftMenu, TopMenu} from "../Editor/AppEditMenu";
import {getCurrentPageShowMenus} from "../../selectors/editorSelectors";
import log from "loglevel";
import {routePath} from "@byk/routes";
import {getTenant} from "utils/localStorage";
import {appUrlParams, getContrastYIQ, getUrlParams} from "@byk/utils/Utils";
import Mail from "@icon-park/react/es/icons/Mail";
import Remind from "@icon-park/react/es/icons/Remind";

const HeadBox = styled.section<{
  backgroundColor: string,
  position: string,
  height: string
}>`
  display: flex;
  align-items: center;
  background-color: ${({backgroundColor}) => backgroundColor || '#f4f4f4'};
  position: ${({position}) => (position || 'relative')};
  z-index: 99;
  height: ${({height}) => height || 60}px;
  min-height: 60px;
  box-sizing: content-box;
  left: 0;
  top: 0;
  right: 0;

`
export const fixMenu = ['9999', '9998', '9997', '9996', '9995', '9994', '9993', '9992', '9991'];

export const historyPush = (reg: any) => {
  let {actionType, appId, toPage,menuKey, toPageId, toPageType, params, linkParams, toUrl} = reg;
  let urlParams = appUrlParams();
  if (params) {
    urlParams += params ?? ''
  }
  try {
    let linkParamsObj = JSON.parse(linkParams);
    if (typeof linkParamsObj === 'object') {
      if (linkParamsObj) {
        if (urlParams) {
          urlParams += '&'
        }
        _.keys(linkParamsObj).forEach(key => {
          urlParams += `${key}=${linkParamsObj[key]}&`
        })
      }
    }
  } catch (e) {
  }

  urlParams += `&_mk=${menuKey}`

  if ((!actionType || actionType == 'toPage'|| actionType == 'null') && appId && toPage && toPageId) {
    let url = `${routePath}/app/${appId}/${toPage}-${toPageId}`;
    if(urlParams){
      url += `${urlParams}`;
    }
    if (toPageType == 'SAME_WINDOW') {
      history.push(url);
    } else {
      window.open(url, "_blank");
    }
  } else if (actionType == 'toUrl' && toUrl) {
    if (!toUrl.startsWith('http')) {
      toUrl = 'http://' + toUrl;
    }
    if (urlParams) {
      toUrl += `${urlParams}`
    }
    if (toPageType == 'SAME_WINDOW') {
      window.open(toUrl, "_self");
    } else {
      window.open(toUrl, "_blank");
    }
  }
}
function IframeViewer(props:any){
  const {
    children,
    iframeDSL,
    filterMenusOther,
    menuOnclick,
    menuFocusKey,
    setMenuFocusKey,
    reOtherKey,
    globalSearch,
    filterMenusCode,
    xLanguageCurr,
    languageOpt,
    languageChange,
    items,
    user,
    toLogin
  } = props;
  const leftWidth = iframeDSL?.leftForm?.left?.width || 200;
  let bgColor:any=iframeDSL?.topForm?.top?.isBgColor ? (iframeDSL?.topForm?.top?.backgroundColor) : '';
  let fontColor:any=bgColor==""?"black":getContrastYIQ(bgColor);
  return (
    <>
      {
        iframeDSL?.topForm?.top?.position == 'fixed' &&
        !iframeDSL?.topForm?.top?.float && (
          <div style={{minHeight: '60px', height: `${iframeDSL?.topForm?.top?.height || 60}px`}}>444</div>
        )
      }
      <HeadBox className="js-appviewer-header" backgroundColor={bgColor}
               position={iframeDSL?.topForm?.top?.position}
               height={iframeDSL?.topForm?.top?.height}>
        <div style={{width: `${leftWidth}px`, boxSizing: 'border-box'}}>
          {
            iframeDSL.logo &&
            <img style={{width: '100%', height: '60px', margin: '0 auto', objectFit: 'contain'}}
                 src={`/byk/${getTenant()}/file/${iframeDSL.logo}`}
                 alt=""/>
          }
        </div>
        <div style={{flex: 1, height: '100%'}}>
          {
            [1, 2].includes(iframeDSL.menusType) &&
            <TopMenu initIframe={filterMenusOther(iframeDSL)}
                     itemClick={(nodeData: any) => {
                       menuOnclick(nodeData)
                     }}
                     menuFocusKey={menuFocusKey} setMenuFocusKey={setMenuFocusKey}
            />
          }
        </div>
        {/*搜索   通知   站内消息*/}
        <div style={{display: 'flex', alignItems: 'center'}}>
          {
            reOtherKey('global') && <Input style={{flex: 1}} placeholder={`请输入关键字`} onPressEnter={(e: any) => {
              globalSearch('global', `name=${e.target.value}`)
            }}/>
          }
          {
            reOtherKey('notify') && (
            <>
              <Space  align="center" className="x-head-notify-message" style={{gap:"2px",marginLeft:"20px",cursor:"pointer"}} onClick={() => {
                    globalSearch('notify');
                  }}>
                <Remind style={{color:fontColor}} theme="outline" size="18" />
                  <span  style={{margin: '0 4px', cursor: 'pointer',color:fontColor}}>
                    {filterMenusCode('notify')}
                  </span>
              </Space>
            </>)
          }
          {
            reOtherKey('message') && (
              <>
              <Space align="center"  className="x-head-notify-message" style={{gap:"2px",marginLeft:"20px",cursor:"pointer"}}  onClick={() => {
                  globalSearch('message');
                }}>
                <Mail style={{color:fontColor}} theme="outline" size="18" />
                <span style={{cursor: 'pointer',color:fontColor}}>{filterMenusCode('message')}
                </span>
              </Space>
            </>
            )
          }
        </div>
        {
          <Select style={{marginRight: '10px',marginLeft:"20px"}} value={xLanguageCurr} options={languageOpt} onChange={languageChange}/>
        }
        {
          user?.name != 'guest' &&
          <Dropdown menu={{items}} placement="bottomRight" arrow={{pointAtCenter: true}}>
            <div style={{display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer',color:fontColor}}>
              <img src={require(`@byk/assets/images/avatar-user.png`)} style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '6px'
              }} alt=""/>{user?.name}
            </div>
          </Dropdown>
        }
        {
          user?.name == 'guest' && <a onClick={() => {
            toLogin();
          }} style={{padding: '0 10px',color:fontColor}}>登录</a>
        }
      </HeadBox>
      <div style={{display: 'flex', flex: '1', zIndex:'3',
        height: `calc(100vh - ${iframeDSL&&iframeDSL.topForm&&iframeDSL.topForm.top&&iframeDSL.topForm.top.height?iframeDSL.topForm.top.height:60}px)`}}>
        {
          [0, 2].includes(iframeDSL.menusType) && (
            <LeftMenu initIframe={filterMenusOther(iframeDSL)}
                      itemClick={(nodeData: any) => {
                        menuOnclick(nodeData)
                      }}
                      menuFocusKey={menuFocusKey} setMenuFocusKey={setMenuFocusKey}
            />
          )
        }

        {children}
      </div>
    </>
  )
}

function AppViewerMenu(props: any) {
  const {
    children,
    applicationId,
    pageId,
    previewUser,
    xLanguage,
    setXLanguageCurr,
    xLanguageMenu,
    xLanguageText,
    xLanguageCurr
  } = props;
  const dispatch = useDispatch();
  const { permission, menu:userMenus, user, mainPages:pages } = previewUser;
  const [menuFocusKey, setMenuFocusKey] = useState<Array<string>>([]);
  const showMenus = useSelector(getCurrentPageShowMenus);
  const [iframeDSL, setIframeDSL] = useState<{
    menusType: number,
    menus: any[],
    topForm: any,
    leftForm: any,
    logo: string
  }>({
    menus: [],
    topForm: {},
    leftForm: {},
    menusType: 4,
    logo: ''
  });

  useEffect(()=>{
    const _mk = getUrlParams()._mk;
    const pathMenuKeyMap:any = {};
    if(!_.isEmpty(pages)&&_.isEmpty(iframeDSL.menus)){
      let iframePage = _.find(pages, { slug: 'frameDesignPage'});
      let defaultPage = _.find(pages, { defaultPage: true});
      let _iframeDSL: any = iframePage?.dsls;
      let cloneUserMenus:any = userMenus;
      if (!_.isEmpty(cloneUserMenus)) {
        const userMenusFn = (data: any) => {
          _.each(data, (i: any) => {
            i.appid = i.appid || i.appId;
            i.pid = i.pid || i.parentKey;
            i.topage = i.topage || i.toPage;
            i.topageid = i.topageid || i.toPageId;
            i.topagetype = i.topagetype || i.toPageType;
            if (!i.pkPath) {
              i.pkPath = [i.menuKey];
            }
            pathMenuKeyMap[i.menuKey] = i.pkPath;
            if (i.children) {
              for (let idx in i.children) {
                let c:any = i.children[idx]
                c.pkPath = [...i.pkPath, c.menuKey]
              }
              userMenusFn(i.children);
            }
          })
        };
        userMenus.sort((a: any, b: any) => a.sort - b.sort);
        const sortFn = (data: any) => {
          return data.map((i: any) => {
            return {
              ...i,
              children: sortFn(i.children.sort((a: any, b: any) => a.sort - b.sort))
            }
          })
        }
        cloneUserMenus = sortFn(userMenus);
        userMenusFn(cloneUserMenus);
        _iframeDSL = {
          ..._iframeDSL,
          menusType: _iframeDSL.menusType === undefined? 0:_iframeDSL.menusType,
          menus: addMenuKey(cloneUserMenus)
        };
        setIframeDSL(_iframeDSL);

        //设置默认菜单。
        let defaultPageId = defaultPage?.id;
        if (pathMenuKeyMap[_mk]) {
          setMenuFocusKey(pathMenuKeyMap[_mk]);
        } else if(!menuFocusKey && defaultPageId){
          let menuKeys = getAllParentIds(_iframeDSL.menus, defaultPageId, [], 'toPageId');
          menuKeys = _.isEmpty(menuKeys) ? ['systemmana'] : menuKeys;
          setMenuFocusKey(menuKeys);
        }
      }
    }

    //权限数据 在组件渲染时用到
    dispatch({
      type: 'X_USER_PERMISSION',
      data: permission ?? []
    })
  }, [applicationId, pageId, pages]);

  useEffect(() => {
    let defaultLang:any = xLanguage.find((item:any) => item.defaultLocale)
    let language: string = getCookie('language') || defaultLang?.localeString;
    setXLanguageCurr(language);
  }, [xLanguage]);

  const languageOpt = xLanguage.map((i: any) => {
    return {
      key: i.id,
      label: i.languageName,
      value: i.localeString
    }
  })

  const languageChange = (value: any) => {
    setCookie('language', value);
    setXLanguageCurr(value);
    dispatch({
      type: ReduxActionTypes.EXECUTE_PAGE_LOAD_ACTIONS
    })
  }

  //菜单点击事件，供子组件调用
  function menuOnclick(nodeData: any) {
    const {appId, toPageId, toUrl, toPage, toPageType, menuKey, readonly, actionType} = nodeData;
    let urlParams = appUrlParams();
    if(readonly){
      urlParams += `&_mk=${menuKey}`
      history.push(`${routePath}/app/${appId}/${toPage}-${toPageId}/system${urlParams}`);
    }else{
      historyPush({
        appId,
        toPageId,
        toUrl,
        toPage,
        toPageType,
        menuKey,
        readonly,
        actionType
      })
    }
  }

  const toLogin = () => {
    if(location.href.indexOf('/appLogin')>0){
      return ;
    }
    const loginPage:any = _.find(pages, {loginPage: true});
    const urlParams = appUrlParams('guest');
    if (!_.isEmpty(loginPage)) {//有设置登录页，跳转到设置登录
      const {id: toPageId, slug: toPage} = loginPage;
      history.push(`${routePath}/app/${applicationId}/${toPage}-${toPageId}${urlParams}`);
    } else {
      history.push(`${routePath}/appLogin${urlParams}`);
    }
  }

  const clearUser = () => {
    getAppLogout().then((res: any) => {
      if (res.success) {
        getAppCurrUser({
          appId: applicationId
        }).then(()=>{
          toLogin();
        })
      }
    })
  }

  const filterTextCode = (code:string)=>{
    //通过code转对应语言
    let lang:any = _.find(xLanguageText, { code, language: xLanguageCurr });
    let defLang:any = _.find(xLanguageText, { code, language: 'default' });
    return lang?.text||defLang?.text||"退出登录";
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" onClick={clearUser}>
          {filterTextCode('logout')}
        </a>
      ),
    }
  ];

  const reOtherKey = (key?: string) => {
    //取框架页里的other
    const otherMenu = _.find(userMenus, {menuKey: 'other'})||{};
    let otherMenuYes = _.filter(otherMenu.children, (i: any) => {
      return i.isVisable == 1 && i.menuCheck;
    })
    if(key){
      return _.find(otherMenuYes, {menuKey: key});
    }else{
      return otherMenuYes;
    }
  };
  const filterMenusOther = (data: any) => {
    let _menus = userMenus?.filter((i: any) => {
      return i.menuKey !== 'other';
    });
    let _copyMenus = _.cloneDeep(_menus);
    //多语言匹配 begin
    const matchLanguage = (_menusData: any) => {
      _.each(_menusData, (i: any) => {
        let matchRes = xLanguageMenu.filter((j: any) => {
          return j.language == xLanguageCurr && j.code == i.menuKey;
        });
        if (!_.isEmpty(matchRes) && matchRes[0]?.text) {
          i.title = matchRes[0]?.text;
        }
        if (i.children) {
          matchLanguage(i.children);
        }
      })
    }
    matchLanguage(_copyMenus);

    //多语言匹配 end
    return {
      ...data,
      menus: _copyMenus
    };
  };
  const filterMenusCode = (code: any) => {
    //通过code转对应语言
    let lang:any = _.find(xLanguageMenu, { code, language: xLanguageCurr });
    let defLang:any = _.find(xLanguageMenu, { code, language: 'default' });
    return lang?.text||defLang?.text;
  };

  const globalSearch = (key: string, params?: string) => {
    if(key){
      const {toPageId, toPage, appId, toPageType} = reOtherKey(key);
      if (!toPageId || !toPage || !appId ) {
        return false;
      }
      historyPush({
        appId,
        toPage,
        toPageId,
        toPageType,
        params
      })
    }
  }


  const isLoginPermission = useCallback(()=>{
    let loginPage = _.find(pages, { loginPage: true});
    return loginPage?.id == pageId;
  }, [pages, pageId]);

  const IframeViewerMemo = useMemo(
    ()=> {
      return <IframeViewer
        iframeDSL={iframeDSL}
    filterMenusOther={filterMenusOther}
    menuOnclick={menuOnclick}
    menuFocusKey={menuFocusKey}
    setMenuFocusKey={setMenuFocusKey}
    reOtherKey={reOtherKey}
    globalSearch={globalSearch}
    filterMenusCode={filterMenusCode}
    xLanguageCurr={xLanguageCurr}
    languageOpt={languageOpt}
    languageChange={languageChange}
    items={items}
    user={user}
    toLogin={toLogin}
      >{children}</IframeViewer>;
    }, [iframeDSL, pageId, menuFocusKey, xLanguageCurr]);

  if(!showMenus || isLoginPermission()){
    return <>{children}</>
  }
  if(_.isEmpty(userMenus)&&!_.isEmpty(pages)){
    toLogin();
    return null;
  }
  if(_.isEmpty(iframeDSL.menus)){
    return null;
  }
  return <>{IframeViewerMemo}</>
}

const mapStateToProps = (state: any) => ({
  previewUser: state.XReducers.previewUser,
  xLanguage: state.XReducers.xLanguage,
  xLanguageCurr: state.XReducers.xLanguageCurr,
  xLanguageMenu: state.XReducers.xLanguageMenu,
  xLanguageText: state.XReducers.xLanguageText
})
const mapDispatchToProps = (dispatch: any) => ({
  setXLanguageCurr: (value: string) => {
    dispatch({
      type: X_LANGUAGE_CURR,
      data: value
    })
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(AppViewerMenu);
