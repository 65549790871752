import {NotebookStepUiComponentProps} from "@byk/pages/QueryBuilder/components/notebook/types";
import React from "react";
import ClauseStep from "@byk/pages/QueryBuilder/components/notebook/steps/ClauseStep";
import ExpressionWidget from "@byk/pages/QueryBuilder/components/notebook/steps/ExpressionStep/ExpressionWidget";
import {ExpressionDimension} from "@byk/pages/QueryBuilder/lib/metadata/Dimension";

const ExpressionStep: React.FC<NotebookStepUiComponentProps> =
  ({
     color,
     query,
     updateQuery,
     isLastOpened,
     readOnly,
   }: NotebookStepUiComponentProps) => {
    const items: ExpressionDimension[] = query.expressions();
    return (
      <ClauseStep
        color={color}
        items={items}
        renderName={({name}) => name}
        readOnly={readOnly}
        renderPopover={(item: ExpressionDimension) => (
          <ExpressionWidget
            query={query}
            name={item?.name}
            expression={item?.expression}
            withName
            onChangeExpression={(newName: string, newExpression: any) => {
              item?.expression
                ? updateQuery(
                  query.updateExpression(newName, newExpression, item.name),
                )
                : updateQuery(query.addExpression(newName, newExpression));
            }}
          />
        )}
        isLastOpened={isLastOpened}
        onRemove={({name}) => updateQuery(query.removeExpression(name))}
      />
    );

  };
export default ExpressionStep;
