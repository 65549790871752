import React, {forwardRef, useImperativeHandle, useState} from 'react';

import {Checkbox, Table} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {ModalWrapper} from '../styled';

interface IProps {
  ref?: any,
  update: (model: any) => void,
  isView: boolean,
}

const PublishSelectData: React.FC<IProps> = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
      showModal
    }))
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [data, setData] = useState<any>([])
    const [model, setModel] = useState<any>([])

    const showModal = (model: any) => {
      setModel(model)
      setIsModalOpen(true)
    };

    const handleCancel = () => {
      setIsModalOpen(false)
    };

    const onFullData = ({target: {checked}}: CheckboxChangeEvent) => {
      model.fullDataInit = checked;
      setModel({...model});
      props.update(model);
    }

    const columns = [{}]

    return (
      <>
        <ModalWrapper
          onCancel={handleCancel} open={isModalOpen}
          footer={null}
          maskClosable={false}
          title={"初始化数据"}
          width={'80%'}
          bodyStyle={{
            padding: 5,
          }}
        >

          <Checkbox
            disabled={model.isView}
            checked={model.fullDataInit} onChange={(evt) => onFullData(evt)}>全量数据</Checkbox>

          <Table dataSource={data} columns={columns} pagination={false}/>
        </ModalWrapper>
      </>
    )
  }
)

export default PublishSelectData;
