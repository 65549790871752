import React, { Suspense, useEffect } from "react";
import history from "utils/history";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import {
  BASE_URL,
  BUILDER_CUSTOM_PATH,
  BUILDER_PATCH_PATH,
  BUILDER_PATH,
  BUILDER_PATH_DEPRECATED,
  PROFILE,
  VIEWER_CUSTOM_PATH,
  VIEWER_PATCH_PATH,
  VIEWER_PATH,
  VIEWER_PATH_DEPRECATED,
} from "constants/routes";
import EditorLoader from "pages/Editor/loader";
import AppViewerLoader from "pages/AppViewer/loader";
import LandingScreen from "../LandingScreen";
import ErrorPage from "pages/common/ErrorPage";
import PageNotFound from "pages/common/ErrorPages/PageNotFound";
import PageLoadingBar from "pages/common/PageLoadingBar";
import ErrorPageHeader from "pages/common/ErrorPageHeader";
import type { AppState } from "@appsmith/reducers";
import {connect, useDispatch, useSelector} from "react-redux";
import * as Sentry from "@sentry/react";
import { getSafeCrash, getSafeCrashCode } from "selectors/errorSelectors";
import UserProfile from "pages/UserProfile";
import { getCurrentUser } from "actions/authActions";
import {
  getCurrentUserLoading,
  selectFeatureFlags,
} from "selectors/usersSelectors";
import type { ERROR_CODES } from "@appsmith/constants/ApiConstants";
import { fetchFeatureFlagsInit } from "actions/userActions";
import type FeatureFlags from "entities/FeatureFlags";
import { getCurrentTenant } from "@appsmith/actions/tenantActions";
import {
  isTenantLoading,
} from "@appsmith/selectors/tenantSelectors";
import useBrandingTheme from "utils/hooks/useBrandingTheme";
import RouteChangeListener from "RouteChangeListener";
import BykRoutes, {apiPath} from "@byk/routes";
import Api from "@byk/api/Api";
import {X_PREVIEW_USER} from "@byk/store/XReducers";
import {ReduxActionTypes} from "./constants/ReduxActionConstants";
import {createNotification} from "./utils/notification.js";
import log from "loglevel";
import {appTenantClass, getUrlParams} from "@byk/utils/Utils";

export const SentryRoute = Sentry.withSentryRouting(Route);
export const loadingIndicator = <PageLoadingBar />;
import {Spinner} from "design-system";
import Centered from "../components/designSystems/appsmith/CenteredWrapper";

//应用获取菜单，权限，角色，用户信息
export const getAppCurrUser = async (params: {appId: string})=>{
  let appInfo:any = await Api.get(`${apiPath}/subapp/appInfo`, {
    appId: params.appId
  });
  let tenant = appInfo.result[0].tenant;
  let projectId = appInfo.result[0].projectId;
  let bykUrl = `${apiPath}/${tenant}`;
  appTenantClass.setTenant(tenant);
  appTenantClass.setAppId(params.appId);
  appTenantClass.setProjectId(projectId);
  return await Api.get(`${bykUrl}/subapp/admin/curruser`, {
    appId: params.appId,
    tenant
  })
};
//应用退出登录
export const getAppLogout = ()=>{
  return Api.get(`${apiPath}/${appTenantClass.getTenant()}/subapp/admin/logout`);
}
export function Routes() {
  return (
    <Switch>
      <SentryRoute
      component={LandingScreen}
      exact
      path={`${BASE_URL}aLogin`}
      />
      <SentryRoute component={UserProfile} path={PROFILE} />
      <SentryRoute component={EditorLoader} path={BUILDER_PATH_DEPRECATED} />
      <SentryRoute component={AppViewerLoader} path={VIEWER_PATH_DEPRECATED} />
      <SentryRoute component={EditorLoader} path={BUILDER_PATH} />
      <SentryRoute component={EditorLoader} path={BUILDER_CUSTOM_PATH} />
      <SentryRoute component={AppViewerLoader} path={VIEWER_PATH} />
      <SentryRoute component={AppViewerLoader} path={VIEWER_CUSTOM_PATH} />
      <Redirect from={BUILDER_PATCH_PATH} to={BUILDER_PATH} />
      <Redirect from={VIEWER_PATCH_PATH} to={VIEWER_PATH} />
      <BykRoutes />
      <SentryRoute component={PageNotFound} />
    </Switch>
  );
}

function AppRouter(props: {
  safeCrash: boolean;
  getCurrentUser: () => void;
  getFeatureFlags: () => void;
  getCurrentTenant: () => void;
  safeCrashCode?: ERROR_CODES;
  featureFlags: FeatureFlags;
}) {
  const { getCurrentTenant, getCurrentUser, getFeatureFlags } = props;
  const tenantIsLoading = useSelector(isTenantLoading);
  const currentUserIsLoading = useSelector(getCurrentUserLoading);
  const userId = useSelector((state:any)=>{
    return state.ui?.users?.currentUser?.id;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    let location = window.location.href;
    if(location.indexOf('-preview')>0){
      let urlParams = getUrlParams();
      const previewParamsAppId:string = urlParams.appId;
      if(previewParamsAppId){
        getAppCurrUser({
          appId: previewParamsAppId
        }).then((res:any)=>{
          if(res.success){
            dispatch({
              type: X_PREVIEW_USER,
              data: res.ext?.[0]||{}
            })
            dispatch({
              type: ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
              payload: res.ext?.[0]?.user||{},
            });
          }
        })
      }else{
        log.debug(`应用ID不存在${previewParamsAppId}`);
      }
    }

    if(location.indexOf('-preview')<0&&location.indexOf('/login')<0&&location.indexOf('/register')<0){
      getCurrentUser();
    }
    getFeatureFlags();
    getCurrentTenant();
  }, []);
  useEffect(()=>{
    if(userId){
      createNotification();
    }
  }, [userId])
  useBrandingTheme();

  // hide the top loader once the tenant is loaded
  useEffect(() => {
    if (tenantIsLoading === false && currentUserIsLoading === false) {
      const loader = document.getElementById("loader") as HTMLDivElement;

      if (loader) {
        loader.style.width = "100vw";

        setTimeout(() => {
          loader.style.opacity = "0";
        });
      }
    }
  }, [tenantIsLoading, currentUserIsLoading]);

  //if (tenantIsLoading || currentUserIsLoading) return null;

  if(!userId&&location.pathname!=='/login'){
    return (
      <Centered>
        <Spinner size="sm" />
      </Centered>
    )
  }
  return (
    <Router history={history}>
      <Suspense fallback={loadingIndicator}>
        <RouteChangeListener />
        {props.safeCrash && props.safeCrashCode ? (
          <>
            <ErrorPageHeader />
            <ErrorPage code={props.safeCrashCode} />
          </>
        ) : (
          <>
             {/*<AppHeader />*/}
            <Routes />
          </>
        )}
      </Suspense>
    </Router>
  );
}

const mapStateToProps = (state: AppState) => ({
  safeCrash: getSafeCrash(state),
  safeCrashCode: getSafeCrashCode(state),
  featureFlags: selectFeatureFlags(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getCurrentUser: () => dispatch(getCurrentUser()),
  getFeatureFlags: () => dispatch(fetchFeatureFlagsInit()),
  getCurrentTenant: () => dispatch(getCurrentTenant(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
