import { Switch } from 'antd';
import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from 'react';

const App = () => {
    const ws = useRef<WebSocket | null>(null);
    const [message, setMessage] = useState('');
    const [readyState, setReadyState] = useState('正在链接中');
    const [rdNum, SetRdNum] = useState<number>(0);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const chatListRef = useRef(null)
    /**
     * 伪随机函数，测试用
     *  */
    const getRandomInt = useCallback(() => {
        SetRdNum(Math.floor(Math.random() * Math.floor(999)));
    }, []);

    const scrollToEnd = () => {

        const containerElement = document.getElementById('msg'); // 根据实际情况修改ID或选择器
        if (containerElement) {
            containerElement.scrollTop = containerElement.scrollHeight;
        }
    };
    const openChange=(e:any)=>{
        setIsOpen(e);
    }

    const getUrl = (isDev:boolean=true) => {
        //'ws://byk.billjc.com/message/SysLog'
        let origin = window.location.origin+"/message/SysLog";
        let result=origin.replace(/https/,"wss").replace(/http/,"ws")
        if(isDev){
            result='ws://byk.billjc.com/message/SysLog';
        }
        return result;
    };

    const webSocketInit = useCallback(() => {
        let url=getUrl(false);
        console.log("url",url);
        const stateArr = [
            '正在链接中',
            '已经链接并且可以通讯',
            '连接正在关闭',
            '连接已关闭或者没有链接成功',
        ];
        if (!ws.current || ws.current.readyState === 3) {
            ws.current = new WebSocket(url);
            ws.current.onopen = _e =>
                setReadyState(stateArr[ws.current?.readyState ?? 0]);
            ws.current.onclose = _e =>
                setReadyState(stateArr[ws.current?.readyState ?? 0]);
            ws.current.onerror = e =>
                setReadyState(stateArr[ws.current?.readyState ?? 0]);
            ws.current.onmessage = e => {
                //setMessage(e.data);
                const newMessage = e.data;
                setMessage(prevMessages => [...prevMessages, newMessage]);
                scrollToEnd();
            };
        }
    }, [ws]);

    /**
     * 初始化 WebSocket
     * 且使用 WebSocket 原声方法获取信息
     *  */
    useLayoutEffect(() => {
        getRandomInt();
        webSocketInit();
        return () => {
            ws.current?.close();
        };
    }, [ws, getRandomInt, webSocketInit]);

 
    // 每3秒向服务器发送一个ping消息
    useEffect(() => {
        const interval = setInterval(() => {
            // 检查WebSocket状态，确保连接仍然是打开的
            if (ws.current?.readyState === WebSocket.OPEN) {
                ws.current.send('');
            }
        }, 3000);
        // 清理函数，当组件销毁时清除定时器
        return () => {
            clearInterval(interval);
        };
    }, [ws]);


    /*监听聊天数据的变化，改变聊天容器元素的 scrollTop 值让页面滚到最底部*/
    useEffect(() => {
        scrollToBottom()
    }, [message])    /*rightList 是自己渲染滚动的数据*/

    const isAtBottomOfDiv=(divId:any)=> {  
        const div = document.getElementById(divId);  
        if (!div) {  
          return false; // 如果找不到指定的div，返回false  
        }  
        const scrollTop = div.scrollTop;  
        const clientHeight = div.clientHeight;  
        const scrollHeight = div.scrollHeight;  
        console.log("scrollTop",scrollTop);
        console.log("windowHeight",clientHeight);
        console.log("documentHeight",scrollHeight);
        // 如果滚动位置加上视口高度大于或等于总高度，那么就在底部  
        return scrollTop + clientHeight >= scrollHeight;  
      }  

    const scrollToBottom = () => {   
        // 判断是否滚动到页面底部  
        // if (isAtBottomOfDiv("msg")) {  
        if (isOpen) {  
            console.log('已经滚动到页面底部');  
            // 在这里执行你的逻辑  
            let  divElement = document.getElementById('msg');
            divElement?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }           
      }; 

    return (
        <>
        <Switch style={{position:"absolute",right:"20px",bottom:"20px"}} checkedChildren="已开启自动滚动" checked={isOpen} onChange={(e:any)=>openChange(e)} unCheckedChildren="已关闭自动滚动" defaultChecked />
        <div className='App' style={{ backgroundColor: "#F4F5FA" }} ref={chatListRef}>
            {/* <div
                    onClick={() => {
                        scrollToBottom();
                    }}>
                    bottom
                </div>  */}
            <div style={{ backgroundColor: "#F4F5FA", fontSize: "14px", width: "100vw", height: "100vh" }}>
                <pre id="msg" style={{ backgroundColor: "#F4F5FA" }}>{message}</pre>
                {/* <div
                    onClick={() => {
                        scrollToEnd();
                    }}>
                    bottom
                </div>  */}
                {/* <div
                    onClick={() => {
                        scrollToEnd();
                    }}>
                    bottom
                </div> */}
                {/* <div>{readyState}</div> 
                <div
                    onClick={() => {
                        ws.current?.close();
                    }}>
                    Clone
                </div>
                <div
                    onClick={() => {
                        getRandomInt();
                        webSocketInit();
                    }}>
                    start
                </div>
                <div
                    onClick={() => {
                        if (ws.current?.readyState !== 1) {
                            console.log('尚未链接成功');
                            setMessage('正在链接');
                            return;
                        }
                        ws.current?.send(rdNum.toString());
                    }}>
                    ID:{rdNum}
                </div>
                */}
            </div>
        </div>
        </>
    );
};

export default App;