export const StateVK: any = {
  'Submitted': '已提交',
  'Open': '打开',
  'In Progress': '进行中',
  'To be discussed': '待讨论',
  'Reopened': '重新打开',
  'Can\'t Reproduce': '无法重现',
  'Duplicate': '重复',
  'Fixed': '已修复',
  'Won\'t fix': '不解决',
  'Incomplete': '不完整',
  'Obsolete': '过时',
  'Verified': '已验证',
  '已关闭': '已关闭',
}

export const ContentPageFlagType = {
  LIST: '1',
  DETAIL: '2',
  EDIT: '3',
  CREATE: '4',
}


// 更新工单用到
export const CustomFieldType = {
  'Priority': {name: 'Priority', type: 'SingleEnumIssueCustomField'},
  'State': {name: 'State', type: 'StateIssueCustomField'},
  '工单类型': {name: '工单类型', type: 'SingleEnumIssueCustomField'},
  '联系电话': {name: '联系电话', type: 'SimpleIssueCustomField'},
  'Byk用户ID': {name: 'Byk用户ID', type: 'SimpleIssueCustomField'},
}

export const StateType = {
  "NoReproduce": 'Can\'t Reproduce',
  "Duplicate": 'Duplicate',
  "Fixed": 'Fixed',
  "InProgress": 'In Progress',
  "Incomplete": 'Incomplete',
  "Open": 'Open',
  "Reopened": 'Reopened',
  "Submitted": 'Submitted',
  "TobeDiscussed": 'To be discussed',
  "Verified": 'Verified',
  "NoFix": 'Won\'t fix',
  "Close": '已关闭',
}
