export default function Config ():string{
  return `
{
  "miniprogramRoot": "./",
  "projectname": "taroApp",
  "appid": "testAppId",
  "setting": {
    "es6": false,
    "minified": false
  }
}
  `;
}
