export default function Config ():string{
  return `import {AtTextarea} from "taro-ui";
import React, {useState} from "react";
import {ReduxActionTypes} from "../../constants/ReduxActionConstants";
import {connect} from "react-redux";
import XBase from "./XBase";

function XTextarea(props: any) {
  const { propertyPath, setDataTree, style} = props;
  const [value, setValue] = useState('');
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <AtTextarea customStyle={customStyle} count={false} placeholder='请输入...' value={value} onChange={(v:string)=>{
        setDataTree(\`\${propertyPath}.value\`, value);
        setValue(v);
    }} />
    </XBase>)
}

const dispatchToProps = (dispatch: any) => {
  return {
    setDataTree: (key: string, value: any) => {
      dispatch({
        type: ReduxActionTypes.X_SET_DATATREE_ACTION,
        payload: {
          key,
          value,
        },
      })
    }
  }
}
export default connect(null, dispatchToProps)(XTextarea);
  `;
}
