import React, {Component} from "react";
import Head from "@byk/pages/components/Header";
import {Layout} from 'antd';

import AppDeploy from "@byk/pages/AppDeploy/AppDeploy";

const {Content} = Layout;

export default class Home extends Component {
  render() {
    return (
      <div>
        <Layout>
          {/*引入头部 */}
          <Head title="AppDeploy" />
          <Layout>
            {/* <Sider className="Sider-Navbar"><Navbar  /></Sider> */}
            <Content>
              <AppDeploy />
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}
