export default function Config ():string{
  return `import Taro from "@tarojs/taro";
import {View} from "@tarojs/components";
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import _ from 'lodash';

function XBase(props:any) {
  const {children, propertyPath, style, className} = props;
  const dataTree = useSelector((state: any) => {
    return state.dataTreeReducer.dataTree
  })
  const rePositionStyle = useMemo(()=>{
    const screenWidth = Taro.getSystemInfoSync().screenWidth;
    let scale = screenWidth / 375;
    const widgetProps = _.get(dataTree, propertyPath);
    if(!widgetProps){
      return {
        ...style
      };
    }
    let _style = {
      position: 'absolute',
      width: \`\${(widgetProps.rightColumn - widgetProps.leftColumn) * widgetProps.parentColumnSpace * scale}px\`,
      height: \`\${(widgetProps.bottomRow - widgetProps.topRow) * widgetProps.parentRowSpace}px\`,
      top: \`\${widgetProps.topRow * widgetProps.parentRowSpace}px\`,
      left: \`\${widgetProps.leftColumn * widgetProps.parentColumnSpace * scale}px\`
    };
    if(style){
      _style = {
        ..._style,
        ...style
      }
    }
    return _style;
  }, [propertyPath]);

  return (
    <View className={className} style={rePositionStyle}>{children}</View>
  )
}

export default XBase;


  `;
}
