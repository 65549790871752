import React, { Component } from "react";
import Head from "@byk/pages/components/Header";
import { Layout } from 'antd';

import BusinessPage from "@byk/pages/ServicesManager/ServiceSetup/main";
const { Content, Sider } = Layout;

export default class Home extends Component {
    render() {
        return (
            <>
                <Layout style={{ height: "100vh" }}>
                    <Head title="AppServicesManager" />
                    <Layout style={{ height: "-webkit-fill-available" }}>
                        <Content  style={{ height: "inherit" }}><BusinessPage /></Content>
                    </Layout>
                </Layout>
            </>
        );
    }
}

