import withWidgetProps, {handleTextFun} from "./utils";
import getWidgetStyles from "../../../utils/getWidgetStyles";

export default (config: any, widgetNames: any, record: any) => {
  let i = withWidgetProps(config, record),
    begin = '',
    head='import XInput from "../../components/widgets/XInput";';
  let pattern = handleTextFun({
    str: i.regex,
    widgetNames,
    record,
    isRegex: true
  });
  begin += `<XInput `;
  if(pattern){
    begin += `pattern={${pattern}}`;
  }
  begin += ` propertyPath={${i.propertyPath}} title={${handleTextFun({str: i.label, widgetNames, record})}} type={${handleTextFun({str: i.inputType})}} placeholder={${handleTextFun({str: i.placeholder, widgetNames, record})}}`;
  if(i.defaultValue){
    begin += `defaultValue={${handleTextFun({str: i.defaultValue, widgetNames, record})}}`;
  }
  begin += getWidgetStyles(i);
  begin += ` />`;
  return {begin, head};
};
