import React from "react";
import {Button,Form,Input,Select,message,PageHeader} from "antd";

import Api from "@byk/api/Api";
import localStorage, {getTenantId} from "utils/localStorage";
import { getAuthority } from "@byk/utils/authority";
import {appTenantClass} from "@byk/utils/Utils";

export default class extends React.Component {
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.formRef = React.createRef();
  }
  state = {
    tenantId: getTenantId(),
    hyData: [],
    scaleData: [],
    isAdmin:getAuthority().tenant==1,
  };

  apiGet: string = "/byk/platform/rest/SysTenant/list";
  apiPost: string = "/byk/platform/rest/SysTenant";
  apiPut: string = "/byk/platform/rest/SysTenant";
  apiDel: string = "/byk/platform/rest/SysTenant";
  onFinish = async (values: any) => {
    let result: any = await Api.put(this.apiPut, values);
    if (result.success) {
      localStorage.setItem(appTenantClass.getUserId()+'_tenantName',values.name);
      message.success("更新成功!");
      this.props.callBack&&this.props.callBack()
    }
  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    this.setState({ projectId: id });
    this.refreshTable(id,true);
  }
  bindHy = async () => {
    let result: any = await Api.get("/byk/platform/dict/" + '行业');
    if (result.success) {
      this.setState({ hyData: result.result });
    }
  };
  bindScale = async () => {
    let result: any = await Api.get("/byk/platform/dict/" + '组织规模');
    if (result.success) {
      this.setState({ scaleData: result.result });
    }
  };
  refreshTable = async (params?: any,isInit:boolean=false) => {
    let result: any = await Api.get(this.apiGet, { id: this.state.tenantId });
    if (result.success) {
      if(isInit){
        this.bindHy();
        this.bindScale();
      }
      if (result.result.length > 0) {
        setTimeout(() => {
          this.bindForm(result.result[0]);
        }, 400);
      }
    }
  };
  onChange = (key: string) => {
    this.setState({ defaultSubId: key });
    let _d = this.state.subAppData.filter((item: any) => {
      return item.id == key;
    });
    this.setState({ defaultSubData: _d[0] });
    this.bindForm(key);
  };
  bindForm = (data: any) => {
    //清空表单
    this.formRef.current?.resetFields();
    let formValue: any = {};
    formValue["name"] = data["name"];
    formValue["id"] = data["id"];
    formValue["code"] = data["code"];
    formValue["industry"] = data["industry"];
    formValue["scale"] = data["scale"];
    this.formRef.current?.setFieldsValue(formValue);
  }


  render() {
    return (
      <div>
        <div>
          <PageHeader
            className="site-page-header"
            title="组织信息"
          />
        </div>

        <div className="x-main-box">
          <Form
            ref={this.formRef}
            name="form"
            style={{ width: "700px" }}
            //form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            initialValues={{
              sysApps: [{ name: "", size: "", frameStyle: "" }], // 默认有一行
            }}
            autoComplete="off"
          >
            <Form.Item
              label="组织名称"
              name="name"
              rules={[{ required: true, message: "请输入!" }]}
            >
              <Input disabled={!this.state.isAdmin} />
            </Form.Item>
            <Form.Item
              label="组织代码"
              name="code"
              rules={[{ required: true, message: "请输入!" }]}
            >
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label="组织ID"
              name="id"
              rules={[{ required: true, message: "请输入!" }]}
            >
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label="所在行业"
              name="industry"
              rules={[{ required: true, message: "请输入!" }]}
            >
              {/* <Input /> */}
              <Select
                placeholder="请选择行业"
                disabled={!this.state.isAdmin}
                fieldNames={{ label: 'name', value: 'code' }}
                options={this.state.hyData}
              />
            </Form.Item>
            <Form.Item
              label="组织规模"
              name="scale"
              rules={[{ required: true, message: "请输入!" }]}
            >
              <Select
                disabled={!this.state.isAdmin}
                placeholder="请选择规模"
                fieldNames={{ label: 'name', value: 'code' }}
                options={this.state.scaleData}
              />
            </Form.Item>
            {this.state.isAdmin&&<>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button type="primary" htmlType="submit">
                  更新信息
                </Button>
              </div>
            </>}
          </Form>
        </div>
      </div>
    );
  }
}


