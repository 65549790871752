import {PlusOutlined} from '@ant-design/icons';
import type {UploadFile, UploadProps} from 'antd';
import {Upload} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {issuesApi} from "@byk/pages/Track/trackApi";

interface IProps {
  onChange: (files: any) => void;
  defaultFileList: any[];
  issueId?: any;
  draftCommentId?: any;
}

const UploadFileBlobHttp: React.FC<IProps> = (
  {
    onChange,
    defaultFileList,
    issueId,
    draftCommentId,
  }) => {
  const [fileList, setFileList] = useState<UploadFile[]>(defaultFileList);
  const blobUrlRef = useRef<any>({});

  useEffect(() => {
    setFileList(defaultFileList);
  }, [defaultFileList])

  const updateFileList = (file: any, isRemove: boolean = false) => {
    file.url = blobUrlRef.current[file.uid];
    let list: any = [] = fileList.filter((item: any) => {
      return item.uid != file.uid;
    });
    if (!isRemove) {
      list = [...list, file];
    }

    setFileList([...list]);
    onChange && onChange(list);
  }

  const onRemove = async (file: any) => {
    if (issueId && file.id) {
      if (draftCommentId) {
        await issuesApi.deleteDraftCommentAttachment(issueId, file.id)
      } else {
        await issuesApi.deleteAIssueAttachment(issueId, file.id)
      }
    }
    updateFileList(file, true);
  }

  const props: UploadProps = {
    fileList: defaultFileList,
    listType: 'picture-card',
    maxCount: 5,
    onRemove: onRemove,
    customRequest: async (option) => {
      let {onSuccess, file, onProgress} = option;
      if (issueId) {
        let newFile: any = {}
        if (draftCommentId) {
          newFile = await issuesApi.addDraftCommentAttachment(issueId, draftCommentId, file);
        } else {
          newFile = await issuesApi.addANewIssueAttachment(issueId, file);
        }
        // @ts-ignore
        file.id = newFile[0].id;
      }

      updateFileList(file)
      // 模拟进度更新
      setTimeout(() => {
        onProgress && onProgress({percent: 100});
        onSuccess && onSuccess("fake_upload_response");
      }, 1000);
    },

    beforeUpload: (file) => {
      const url = URL.createObjectURL(file);
      let key = file.uid;
      blobUrlRef.current[key] = url;
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );

  return (
    <Upload {...props} defaultFileList={[...defaultFileList]}>
      {fileList.length >= (props.maxCount || 3) ? null : uploadButton}
    </Upload>
  )
};

export default UploadFileBlobHttp;
