import {Button, Col, Form, Input, Layout, message, Modal, Row} from "antd";
import React, {useRef, useState} from "react";
import {Content} from "antd/lib/layout/layout";
import styled from "styled-components";
import TableList from "@byk/pages/components/TableList";
import {useSelector} from "react-redux";
import {tableProps} from "@byk/pages/ProjectRecycle/config";
import {DELETE_URL, RESTORE_URL} from "@byk/pages/ProjectRecycle/api";
import Api from "@byk/api/Api";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .btnContainer {
    Button {
      border-radius: 8px;
      margin:10px 8px 0 0;
    }
  }
`

const ProjectRecycle = () => {
  const tableRef = useRef<any>();
  const [isOpenRecycle, setIsOpenRecycle] = useState<any>(false);
  const [delLoading, setDelLoading] = useState(false);
  const [isOpenDel, setIsOpenDel] = useState<any>(false);
  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();

  const userId = useSelector((state: any) => {
    return state.ui?.users?.currentUser?.id;
  });

  function restore(record: any) {
    setData(record);
    setIsOpenRecycle(true);
  }

  async function doRestore() {
    let res: any = await Api.post(RESTORE_URL, {itemId: data.itemId})
    if (res.success) {
      setIsOpenRecycle(false);
      doRefreshTable();
    }
  }

  function deleteProject(record: any) {
    form.resetFields();
    setData(record);
    setIsOpenDel(true);
  }

  async function doDeleteProject() {
    form.validateFields().then(async (values) => {
      if (values.name != data.itemName) {
        message.warn("输入的项目名称不正确!");
        return false;
      } else {
        setDelLoading(true);
        let res: any = await Api.delete(DELETE_URL, {itemId: data.itemId})
        if (res.success) {
          setIsOpenDel(false);
          setDelLoading(false);
          message.success("删除成功!");
          form.resetFields();
          doRefreshTable();
        }
      }
    }).catch();
  }

  // 表格refresh
  const doRefreshTable = (searchData?: any) => {
    tableRef.current?.getTableList({...searchData});
  }

  const handleCancel = () => {
    setIsOpenRecycle(false);
    setIsOpenDel(false);
  }

  return (
    <>
      <Layout>
        <Content style={{
          padding: 24,
          margin: 0,
          height: "100%",
        }}>
          <div style={{marginTop: "8px"}}>
            {/*<SearchForm ref={searchRef} {...searchProps(doRefreshTable)} />*/}
            <TableList ref={tableRef} {...tableProps(restore, deleteProject, userId)} />
          </div>
        </Content>
      </Layout>

      <ModalWrapper
        onCancel={handleCancel}
        open={isOpenRecycle}
        footer={null}
        maskClosable={false}
        title={"恢复项目"}
        width={450}
        bodyStyle={{
          padding: '25 32',
          height: 165,
        }}
      >
        <span>确定恢复该项目吗？</span>

        <div className='btnContainer' style={{display: "flex", justifyContent: 'space-between', marginTop:'45px'}}>
          <div className='left'></div>
          <div className='right'>
            <Button onClick={handleCancel}>取消</Button>
            <Button type={"primary"} onClick={doRestore}>确定</Button>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper
        onCancel={handleCancel}
        open={isOpenDel}
        footer={null}
        maskClosable={false}
        title={<div style={{color: '#FF5454'}}>
          <ExclamationCircleOutlined color='#FF5454' style={{marginRight: '19px'}}/>{`彻底删除项目“${data?.itemName}”`}
        </div>}
        width={645}
        bodyStyle={{
          padding: '25 32',
          height: 240,
        }}
      >
        <Row className="x-mb20">
          <Col className="gutter-row" md={24}>
            <span className="x-bold" style={{fontSize: '16px'}}>确认彻底删除该项目？彻底删除后不可恢复，请谨慎操作！</span>
          </Col>
        </Row>
        <span className="x-bold" style={{fontSize: '14px', color:'#666666'}}>请输入项目名称，表示您确认彻底删除该项目</span>
        <Row>
          <Col className="gutter-row" md={24}>
            <Form form={form} layout={"vertical"}>
              <Form.Item
                name="name"
                rules={[{required: true, message: "请输入项目名称!"}]}>
                <Input
                  style={{
                    height: '47px',
                    borderRadius: '5px',
                    marginTop:'9px',
                  }}
                  placeholder="请输入项目名称" maxLength={200}/>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <div className='btnContainer' style={{display: "flex", justifyContent: 'space-between'}}>
          <div className='left'></div>
          <div className='right'>
            <Button onClick={handleCancel}>取消</Button>
            <Button loading={delLoading} type={"primary"} onClick={doDeleteProject}>彻底删除</Button>
          </div>
        </div>
      </ModalWrapper>
    </>
  )
}

export default ProjectRecycle;
