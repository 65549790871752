import type {JSXElementConstructor} from "react";
import React from "react";
import type {IconProps} from "constants/IconConstants";
import {IconWrapper} from "constants/IconConstants";
import {importSvg} from "design-system-old";

const Top = importSvg(() => import("assets/icons/align/top.svg"));
const Bottom = importSvg(() => import("assets/icons/align/bottom.svg"));
const Hc = importSvg(() => import("assets/icons/align/hc.svg"));
const Heq = importSvg(() => import("assets/icons/align/heq.svg"));
const Left = importSvg(() => import("assets/icons/align/left.svg"));
const Right = importSvg(() => import("assets/icons/align/right.svg"));
const Vc = importSvg(() => import("assets/icons/align/vc.svg"));
const Veq = importSvg(() => import("assets/icons/align/veq.svg"));

/* eslint-disable react/display-name */

export const AlignIcons: {
  [id: string]: JSXElementConstructor<IconProps>;
} = {
  Top: (props: IconProps) => (<IconWrapper {...props}> <Top/> </IconWrapper>),
  Bottom: (props: IconProps) => (<IconWrapper {...props}> <Bottom/> </IconWrapper>),
  Hc: (props: IconProps) => (<IconWrapper {...props}> <Hc/> </IconWrapper>),
  Heq: (props: IconProps) => (<IconWrapper {...props}> <Heq/> </IconWrapper>),
  Left: (props: IconProps) => (<IconWrapper {...props}> <Left/> </IconWrapper>),
  Right: (props: IconProps) => (<IconWrapper {...props}> <Right/> </IconWrapper>),
  Vc: (props: IconProps) => (<IconWrapper {...props}> <Vc/> </IconWrapper>),
  Veq: (props: IconProps) => (<IconWrapper {...props}> <Veq/> </IconWrapper>),
};

export type AlignIconName = keyof typeof AlignIcons;

export function getAlignIcon(name: string, props: any) {
  let Icon = AlignIcons[name];
  return <Icon {...props}/>
}
