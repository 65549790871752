import {Space, Table} from 'antd';
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import PublishDataMaintenance from "@byk/pages/modeling/Publish/PublishDataMaintenance";
import PublishSelectData from "@byk/pages/modeling/Publish/PublishSelectData";
import RenameTableName from "@byk/pages/modeling/Publish/RenameTableName";
import _ from "lodash";
import UploadSql from "@byk/pages/modeling/Publish/UploadSql";

interface IProps {
  ref?: any
  models: any,
  type: string,
  isView: boolean,
  scroll:any,
  update: (model: any, type: any) => void,
}

const PublishModelList: React.FC<IProps> = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({}));
    const [models, setModels] = useState<any>(props.models || []);
    const publishDataMaintenanceRef = useRef<any>();
    const publishSelectDataRef = useRef<any>();
    const renameTableNameRef = useRef<any>();

    const changeSql = (model: any, file: any, isBeforeChange: boolean) => {
      let fileCode = file ? file.code : null
      if (isBeforeChange) {
        model.changeBeforeSqlFileCode = fileCode
      } else {
        model.changeAfterSqlFileCode = fileCode
      }
      update(model);
    }

    const update = (model: any) => {
      let reModels: any[] = [];
      _.forEach(models || [], item => {
        if (item.modelId == model.modelId) {
          reModels.push({...model});
        } else {
          reModels.push(item);
        }
      })

      setModels(reModels);
      props.update(model, props.type);
    }

    const columns = [
      {
        title: '模型',
        dataIndex: 'description',
        key: 'model',
      },
      {
        title: '变更操作',
        key: 'action',
        width: 450,
        render: (_: any, record: any) => (
          <Space size="middle">
            <UploadSql callback={(file: any) => changeSql(record, file, true)} text={'变更前脚本'}/>
            <UploadSql callback={(file: any) => changeSql(record, file, false)} text={'变更后脚本'}/>
          </Space>
        )
      },
    ];

    return (
      <>
        <Table scroll={props.scroll} className={'createModel'} dataSource={models} columns={columns} pagination={false}/>

        <PublishDataMaintenance ref={publishDataMaintenanceRef} update={update} isView={props.isView}/>

        <PublishSelectData ref={publishSelectDataRef} update={update} isView={props.isView}/>

        <RenameTableName ref={renameTableNameRef} update={update} isView={props.isView}/>
      </>
    );
  }
)

export default PublishModelList;
