import React from "react";
import TableList from "@byk/pages/components/TableList";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, Form, Input, message, Row, Col,Switch } from "antd";
import Api from "@byk/api/Api";
import { withRouter } from "react-router-dom";
import localStorage, {getTenant, getTenantId} from "utils/localStorage";
import { apiPath } from "@byk/routes";
import {appTenantClass} from "@byk/utils/Utils";

interface DataType {
  id: React.Key;
}

class MemberManagerList extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  tableAddRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.tableAddRef = React.createRef();
    this.formRef = React.createRef();
  }

  state = {
    roleId: -1,
    tenantId: getTenantId(),
    visibleLoadTableModal: false,
    title: '全部成员',
    type: 1,
    isModalForm: false,
    initParams: {
      asc: "sort,createDate",
      i18n: false

    },
    formCfg: {
      title: "",
      okText: "确定",
      type: null
    },
    titeType: { "a": "审批成员", "b": "编辑字典项", "c": "新增字典项", "cc": "新增字典项" },
    btnTextType: { "a": "保存", "b": "保存", "c": "保存", "cc": "保存" },
    dict: -1
  };

  openLoadTableModal = () => {
    this.setState({
      visibleLoadTableModal: true
    });
  };
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.init();
  };
  callback(_data: any) {
    this.setState({ dict: _data }, () => {
      this.init();
    });

  };

  init() {
    let params: any = this.state.initParams;
    params.dict = this.state.dict;
    this.setState({ initParams: params }, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };

  showModal1(type: string) {
    this.setState({ isModalForm: true }, () => {
      let _data: any = this.state.formCfg.data;
      if (type == "c" || type == "cc") {
        this.formRef.current?.setFieldsValue({ name: "", code: "", enable: true });
      }
      if (type == "b") {
        this.formRef.current?.setFieldsValue({ name: _data.name, code: _data.code, id: _data.id, enable: _data.enable });
      }
    });
  };

  openForm(type: string, _data: any) {
    let dict=this.state.dict;
    if(type=="c"&&dict==-1){
      message.warn("请选择字典项！");
      return false;
    }
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data,
      }
    }, () => { this.showModal1(type); });
  };

  handleOk1 = () => {
    this.setState({ isModalForm: false });
  };

  handleCancel1 = () => {
    this.setState({ isModalForm: false });
  };

  //更新
  updateForm = async (value: any) => {
    let postUrl = `${apiPath}/${getTenant()}/subapp/SysDataDictItem`;
    let _data: any = {};
    let cfg: any = this.state.formCfg;

    let result: any = null;
    if (cfg.type == "c" || cfg.type == "cc") {
      _data.dict = this.state.dict;
      _data.name = value.name;
      _data.enable = value.enable;
      _data.code = value.code;
      //_data.sort=999999;
      if (cfg.type == "cc") {
        _data.sort = (cfg.data.sort || 0);
        _data.sorts = 0;
      }
      result = await Api.post(postUrl, _data);
    }
    if (cfg.type == "b") {
      _data.id = cfg.data.id;
      _data.name = value.name;
      _data.code = value.code;
      _data.enable = value.enable;
      result = await Api.put(postUrl, _data);
    }
    console.log("_data", _data);
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel1();
      this.init();
    }
    else {
      message.error(result.message);
    }

  }

  render() {
    // api
    const apiGet: string = `${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`;
    const apiPost: string = `${apiPath}/${getTenant()}/subapp/SysDataDictItem`;
    const apiPut: string = `${apiPath}/${getTenant()}/subapp/SysDataDictItem`;
    const apiDel: string = `${apiPath}/${getTenant()}/subapp/SysDataDictItem`;
    const apiUserGet: string = `${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`;


    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };

    //前移
    const MoveClick = async (record: any, type: any) => {
      let _data: any = {};
      let sorts = -1;
      if (type == 2) {
        sorts = 1
      }
      _data.id = record.id;
      _data.sorts = sorts;
      let result: any = await Api.put(apiPost, _data);
      if (result.success) {
        this.init();
      }
    };
    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 80,
      },

      {
        title: "字典项编码",
        dataIndex: "code",
        key: "code",
        width: 200,
      },
      {
        title: "字典项名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "是否启用",
        dataIndex: "enable",
        key: "enable",
        width: 150,
        render: (_, record: any) => (
          <>{record.enable ? "是" : "否"}</>
        )
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <>
            <Space size="middle">
              <Button type="link" onClick={() => { MoveClick(record, 1) }}>
                前移
              </Button>
              <Button type="link" onClick={() => { MoveClick(record, 2) }}>
                后移
              </Button>
              <Button type="link" onClick={() => { this.openForm("cc", record) }}>
                增行
              </Button>
              <Button type="link" onClick={() => { this.openForm("b", record) }}>
                编辑
              </Button>
              <Popconfirm placement="left" title={"确认删除该字典项吗？删除后使用该字典项的历史数据将会保留，但是该字典项不再可用。"} onConfirm={() => deleteClick(record)} okText="确定" cancelText="取消">
                <Button type="link" style={{ color: "red" }}>
                  删除
                </Button>
              </Popconfirm>
            </Space>
          </>
        ),
        width: 300,
      },
    ];


    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams
    };

    //添加成员
    //表格配置
    const columns1: ColumnsType = [
      {
        title: "用户名",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "邮箱",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "手机号",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
      }
    ];
    const tableProps1 = {
      columns: columns1,
      api: apiUserGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
    };
    const handleOk = () => {

    }

    const handleCancel = () => {
      this.setState({ visibleLoadTableModal: false });
    }
    return (
      <>
        <div className="x-main-box">

          <div style={{ width: "100%" }}>
            {/* <Button className="x-fr" style={{ marginTop: "-75px", marginRight: "100px" }}>
              移除
            </Button> */}

            <Button type="primary" onClick={() => this.openForm("c", {})} className="x-fr x-ml20" style={{ marginTop: "-65px" }}>
              新增
            </Button></div>
          <TableList ref={this.tableRef} {...tableProps} />

          <Modal title="添加成员" cancelText="取消" okText="确定" width="90%" open={this.state.visibleLoadTableModal} onOk={handleOk} onCancel={handleCancel}>
            <TableList ref={this.tableAddRef} {...tableProps1} />
          </Modal>

          <Modal title={this.state.formCfg.title} open={this.state.isModalForm} onOk={() => this.showModal1} onCancel={this.handleCancel1} footer={[]} getContainer=".x-sys-manage-wrapper">
            <Row>
              <Col className="gutter-row" md={24}>
                <Form ref={this.formRef} labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }} onFinish={this.updateForm}>
                  <Form.Item label="字典项编码" name="code"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                  <Form.Item label="字典项名称" name="name"
                    rules={[{ required: true, message: "请输入!" }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                  <Form.Item label="是否启用" name="enable"
                  >
                    <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked />
                  </Form.Item>

                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Button onClick={this.handleCancel1}>取消</Button>
                    <Button className="x-ml10" type="primary" htmlType="submit">
                      {this.state.formCfg.okText}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}
export default withRouter(MemberManagerList);
