export default function Config ():string{
  return `body,html{ font-size: 12px; }
  page,view{font-size: 24px;}
  .x-image-picker,.x-image-picker .at-image-picker{
    width: 100%; height: 100%;
  }
  .x-image-picker .at-image-picker__flex-box{
    display: block;height: 100%;
  }
  .x-list-item{
    width: 100%;
    padding: 4px;
    margin-bottom: 10px;
    border: 1px solid rgb(224, 222, 222);
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    position: relative;
  }
  image{
    width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
  .x-container{
    border: 1px solid rgb(224, 222, 222);
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  }
  .at-button{font-size: 0.6rem;}
  .at-progress__outer-inner-background{height:0.6rem;}
  `;
}
