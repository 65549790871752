import styled from "styled-components";
import React, {useState} from "react";

const TabContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  gap: var(--ads-v2-spaces-2);
  background-color: var(--ads-v2-color-bg-subtle);
  padding: var(--ads-v2-spaces-1);
  border-radius: calc(var(--ads-v2-border-radius) + var(--ads-v2-spaces-1));
  box-sizing: border-box;
  height: 32px;
  width: auto;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0%;
    position: relative;
    border: 1px solid transparent;
    border-radius: var(--ads-v2-border-radius);
    background-color: transparent;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
  }

  .selected {
    background-color: var(--ads-v2-color-bg);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
  }
`
export const TabController = (props:any) => {
  let {options, defaultValue, onClick: onClickCallBack} = props;
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const onClick = (value:any) => {
    setSelectedValue(value)
    onClickCallBack && onClickCallBack(value);
  }

  return (
    <TabContainer>
      {options.map((option: any) => {
        let isSelected = option.value === selectedValue;
        return (<div className={`item ${isSelected ? 'selected' : ''}`} onClick={() => onClick(option.value)}>
          <span>
            <span>{option.label}</span>
          </span>
        </div>)
      })}
    </TabContainer>
  )
}
