import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {color} from "@byk/pages/QueryBuilder/lib/colors";
import {breakpointMaxSmall} from "@byk/pages/QueryBuilder/lib/theme/media-queries";

export const QueryBuilderViewRoot = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color("bg-white")};
  height: 100%;
  position: relative;
`;

export const QueryBuilderContentContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  position: relative;

  ${breakpointMaxSmall} {
    justify-content: end;
  }
`;

export const QueryBuilderMain = styled.main<{ isSidebarOpen: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  flex-basis: 0;

  ${breakpointMaxSmall} {
    ${props =>
  props.isSidebarOpen &&
  css`
        display: none !important;
      `};
    position: relative;
  }
`;

export const QueryBuilderViewHeaderContainer = styled.div`
  flex-shrink: 0;
  background-color: ${color("bg-white")};
  position: relative;
  z-index: 3;
`;

export const NativeQueryEditorContainer = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid ${color("border")};
  z-index: 2;
`;
