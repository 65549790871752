import * as React from "react";
import cx from "classnames";
import Filter from "@byk/pages/QueryBuilder/lib/queries/structured/Filter";
import Button from "@byk/pages/QueryBuilder/components/ui/Button";

export interface Props {
  className?: string;
  filter: Filter;
  onFilterChange: (filter: any[]) => void;
  onCommit?: (() => void) | null;

  minWidth?: number;
  maxWidth?: number;
  isNew?: boolean;
};

const FilterPopoverFooter: React.FC<Props> =
  ({
     filter,
     isNew,
     onFilterChange,
     onCommit,
     className,
   }: Props) => {
    const containerClassName = cx(className, "flex align-center PopoverFooter");

    return (
      <div className={containerClassName}>
        {onCommit && (
          <Button
            style={{
              color: "#ffffff",
              background: "#509ee3",
              border: "1px solid #509ee3",
              borderRadius: "6px",
              padding: "5px",
              marginBottom: "10px",
              marginRight: "10px",
            }}
            primary
            disabled={!filter.isValid()}
            className="ml-auto"
            onClick={() => onCommit()}
          >
            {isNew ? "添加过滤器" : "更新过虑器"}
          </Button>
        )
        }
      </div>
    );
  }

export default FilterPopoverFooter;
