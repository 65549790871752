
import {regType} from "./widgetToValue";
import {deleteMapping} from "../../../utils/XUtils";

const widgetTaroToValue:any = {
  'AT_LIST_WIDGET': (regs:regType)=>{
    const { widgetData, widgetId } = regs;

    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: deleteMapping(widgetData)
    }]
  },
  'AT_LIST_WIDGET_MAPPING_CONFIG': (regs:regType)=>{
    const { widgetData, widgetId } = regs;
    return [{
      widgetId,
      propertyName: 'currentData',
      propertyValue: widgetData
    }]
  },
  'AT_TEXT_WIDGET': (regs:regType)=>{
    const { widgetTree, key, widgetData, widgetType } = regs;
    if(widgetType === 'LIST_WIDGET_V2'){
      return [{
        widgetId: widgetTree[key]?.widgetId,
        propertyName: 'label',
        propertyValue: `{{currentItem["${key}"]}}`
      },{
        widgetId: widgetTree[key]?.metaWidgetId,
        propertyName: 'label',
        propertyValue: `{{currentItem["${key}"]}}`
      }]
    }
    return [{
      widgetId: widgetTree[key]?.widgetId,
      propertyName: 'label',
      propertyValue: widgetData[0]?.[key]??""
    }]
  }
}



export default widgetTaroToValue;
