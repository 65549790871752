export default function Config ():string{
  return `
import _ from "lodash";
export default function getWidgetValue(widgetTree:any):Record<string, unknown>{
  let pageParams:any = {};
  _.each(widgetTree, (value:any, key:string)=>{
    if(['appsmith', 'pageList', 'MainContainer', 'JSObject1'].includes(key)
      ||['CONTAINER_WIDGET', 'CANVAS_WIDGET'].includes(value.type)){
      return true;
    }
    pageParams[key] = value.getValue||value.value;
  })
  return pageParams;
}

  `;
}
