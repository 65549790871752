import _ from "lodash";
import API from "api/Api";
import prettierFormat from "../../utils/prettierFormat";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../../../../utils/localStorage";

const keyToValue = ['==', '!=', '>', '<', '>=', '<=', '6', '7', '8', '9'];
const methodTypes = ['post', 'get', 'put', 'delete'];
const prefix = 'x_'
//appAction api生成
const apiFn = (config:any, widgetTree:any, record:any, actionApis:any)=>{
  if(methodTypes.includes(config.action)){
    const actionRes = actionApis[`${prefix}${config.actionId}`];
    if(_.isEmpty(actionRes.result)){
      return '';
    }
    let {apiParams} = config;
    let {url, requestMsgFields, requestType, serviceCode, responseMsgFields, elementId, pageName} = actionRes.result[0];
    let reqMsgFieldsObj = requestMsgFields?JSON.parse(requestMsgFields):[];
    let resMsgFieldsObj = responseMsgFields?JSON.parse(responseMsgFields):[];
    //console.log('apiParams', apiParams);
    //console.log('reqMsgFieldsObj', reqMsgFieldsObj);
    //console.log('resMsgFieldsObj', resMsgFieldsObj);
    let params:any = '';
    if(!_.isEmpty(reqMsgFieldsObj)){
      _.each(reqMsgFieldsObj, (j:any)=>{
        params += `${j}: widgetTree['${record.slug}'].${j}.value,`
      })
    }
    return `API.methods('${requestType}', '${apiPath}/${getTenant()}/${url}', {${params}})`;
  }else{
    let reg:any = {};
    switch(config.action){
      case 'showAlert':
        reg = { message: config.message, type: config.type };
        break;
      default:
        break;
    }
    if(config.action === 'linkage'){
      let onEventTo:any = { onLoad: 'Load' };
      let widgetName = _.find(_.values(widgetTree), { widgetId: config.linkageId}).widgetName;
      return `eventRun[\`${widgetName}${onEventTo[config.linkageEvent]}\`](widgetTree, dispatch);`;
    }else if(config.action === 'navigateTo'){
      let pageName = _.find(widgetTree.pageList, { pageId: config.navigatePage }).slug;
      return `Taro.navigateTo({
      url: '/pages/${pageName}/index',
    })`;
    }else if(config.action === 'js'){
      return `${config.jsPath.join('.')}();JSObject1.notifyChange();`;
    }else{
      return `dispatch({ type: '${config.action}', payload: ${JSON.stringify(reg)} });`;
    }

  }

}
const reActionIds = (da:any)=>{
  let ids:any = [];
  const recFn = (child:any)=>{
    _.each(child, (i:any)=>{
      if(i.type === 'action'&&methodTypes.includes(i.actionConfig.action)){
        ids.push(i.actionConfig.actionId)
      }
      i.children&&recFn(i.children);
    })
  }
  recFn(da);
  return ids;
}
//动作条件解析
const fn = async (da:any, widgetTree:any, record:any, tenant:any)=>{
  let actionIds:any[] = reActionIds(da);
  let actionApis:any = {};
  for (const i of actionIds){
    actionApis[`${prefix}${i}`] = await API.get(`/byk/${tenant}/subapp/AppAction`, {
      id: i
    });
  }
  let reJsStrFn = (data:any)=>{
    let jsStr = '';
    _.each(data, (i)=>{
      if(i.type == "block"){
        let {type, values, actionConfig:config, changeStatus, exp} = i.children[0];
        if(type == 'if'){
          _.each(i.children, j=>{
            if(j.type == "else"){
              jsStr += `else{
						  ${reJsStrFn(j.children)}
						}`;
            }else{
              let ifStr = "";
              if(changeStatus&&changeStatus.value === true){//表达式
                let v = exp?.value;
                if(v){
                  let arr1:any = v.split('&&');
                  arr1 = arr1.map((i:any)=>{
                    return `widgetTree.${i}`;
                  });
                  arr1 = arr1.join('&&');
                  ifStr = arr1;
                }
              }else{
                if(!_.isEmpty(values)){
                  _.each(values, (value, key)=>{
                    ifStr += ifStr==""?"(":"||(";
                    _.each(value, (k)=>{
                      if(!ifStr.endsWith('(')){
                        ifStr += "&&"
                      }
                      if(k.b == '6'){
                        ifStr += `widgetTree.${k.a}.includes("${k.c}")`;
                      }else if(k.b == '7'){
                        ifStr += `!widgetTree.${k.a}.includes("${k.c}")`;
                      }else if(k.b == '8'){
                        ifStr += `widgetTree.${k.a}==""`;
                      }else if(k.b == '9'){
                        ifStr += `widgetTree.${k.a}!=""`;
                      }else{
                        ifStr += `widgetTree.${k.a}${keyToValue[k.b]}"${k.c}"`;
                      }

                    })
                    ifStr += ")";
                  })
                }
              }
              jsStr += `${j.type == 'elseIf' ? 'else if' : j.type}(${ifStr}){
						  ${reJsStrFn(j.children)}
						}`;
            }
          })

        }
        if(type == 'action'){
          _.each(i.children, k=>{
            if(k.type == 'action'){
              let resAction = apiFn(config, widgetTree, record, actionApis);
              jsStr += resAction;
            }else if(k.type == 'success'&&k.children){
              jsStr += `.then((res)=>{
                ${reJsStrFn(k.children)}
              })`;
            }else if(k.type == 'fail'&&k.children){
              jsStr += `.catch((error)=>{
                ${reJsStrFn(k.children)}
              })`;
            }else{
              //
            }
          })

        }


      }
    })

    return jsStr;
  }
  let js = reJsStrFn(da);
  //console.log('jsStr>>>', js);
  return js;
};

export default async function Config (record:any, widgetTree:any, tenant:any):Promise<string>{
  const layouts:any = record.layouts[0];
  const dsl:any = layouts.dsl.children;
  let temp = ``;
  temp += `import API from "../../api";`;
  temp += `import _ from 'lodash';`;
  temp += `import Taro from '@tarojs/taro';`;
  temp += `import {X_SET_DATATREE_ACTION} from "../../reducers/dataTree";`;
  temp += `import JSObject1 from "./JSObject1";`;
  temp += `const eventRun:Record<string, unknown> = `;
  temp += `{`;

  const dslEvent = async (children:any)=>{
    for (const i of children) {
      if(i.onClick){
        let funBody = await fn(i.onClick.children, widgetTree, record, tenant);
        temp += `${i.widgetName}Click: (widgetTree, dispatch)=>{`;
        temp +=   funBody;
        temp += `},`;
      }
      if(i.onClickRgIconSt){
        let funBody = await fn(i.onClickRgIconSt.children, widgetTree, record, tenant);
        temp += `${i.widgetName}ClickRgIconSt: (widgetTree, dispatch)=>{`;
        temp +=   funBody;
        temp += `},`;
      }
      if(i.onLoad){
        let funBody = await fn(i.onLoad.children, widgetTree, record, tenant);
        temp += `${i.widgetName}Load: (widgetTree, dispatch)=>{`;
        temp +=   funBody;
        let _keyData = i.type === 'LIST_WIDGET_V2'?'listData':'currentData'
        temp += `.then(res=>{
          dispatch({
            type: X_SET_DATATREE_ACTION,
            payload: {
              key: '${record.slug}.${i.widgetName}.${_keyData}',
              value: res?.[0]?.result
            }
          })
        })`
        temp += `},`;
      }
      i.children&&await dslEvent(i.children);
    }
  }
  await dslEvent(dsl);

  temp += `};`;
  temp += `export default eventRun;`;
  //console.log('temp__', temp);
  temp = await prettierFormat(temp);
  return temp;
}
