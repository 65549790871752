export default function Config ():string{
  return `import {AtNavBar} from "taro-ui";
import React from "react";
import XBase from "./XBase";

function XNavBar(props: any) {
  const { propertyPath, color, title, onClickRgIconSt, style } = props;
  const _style = style||{};
  const customStyle = {
    ..._style
  };
  return (
    <XBase propertyPath={propertyPath}>
      <AtNavBar customStyle={customStyle} onClickRgIconSt={onClickRgIconSt} leftIconType='chevron-left' color={color} title={title} rightFirstIconType='bullet-list' />
    </XBase>)
}
export default XNavBar;
  `;
}
