export default function Config ():string{
  return `import {all, call, takeLatest} from "redux-saga/effects";
import {ReduxActionTypes} from "../constants/ReduxActionConstants";

export function* linkage(action:any): any {
  console.log("linkage的参数》》》", action);
}

export function* watchLinkage() {
  yield all([
    takeLatest(
      ReduxActionTypes.X_LINKAGE,
      linkage
    ),
  ]);
}


  `;
}
