import React, {useEffect, useState} from "react";
import Join from "@byk/pages/QueryBuilder/lib/queries/structured/Join";
import StructuredQuery, {getFieldReference} from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {NotebookCellItem} from "../../NotebookCell";
import {
  FieldPickerContentContainer,
  FIELDS_PICKER_STYLES,
  FieldsPickerIcon
} from "@byk/pages/QueryBuilder/components/notebook/FieldsPickerIcon";
import {DataSourceSelector} from "@byk/pages/QueryBuilder/components/DataSelector/DataSelector";
import {FieldPicker} from "@byk/pages/QueryBuilder/components/ui/FieldPicker";
import PopoverWithTrigger from "@byk/pages/QueryBuilder/components/ui/PopoverWithTrigger";
import Schemas from "@byk/pages/QueryBuilder/lib/api/schemas";
import {useDispatch} from "react-redux";
import {QUERY_STRUCT} from "@byk/store/modelingReducers";

export interface JoinTablePickerPropTypes {
  join: Join,
  query: StructuredQuery,
  joinedTable: any,
  color: string,
  updateQuery: (query: any) => Promise<void>;
  onSourceTableSet: (join: Join) => void;
}

const JoinTablePicker: React.FC<JoinTablePickerPropTypes> =
  ({
     join,
     query,
     joinedTable,
     color,
     updateQuery,
     onSourceTableSet,
   }: JoinTablePickerPropTypes) => {
    const [curJoin, setCurJoin] = useState(join);
    const [fields, setFields] = useState<any[]>([]);
    const [fetchProperties, setFetchProperties] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch({
        type: QUERY_STRUCT,
        data: query
      })
    }, [fetchProperties]);

    async function onChange(table: any) {
      console.log("JoinTablePicker--onChange", table);

      let properties = query.metaProperties(table.id);
      if (!properties) {
        properties = await Schemas.fetchModelProperties(table.id);
        query.setMetaProperties(table.id, properties);
        setFetchProperties(!fetchProperties);
      }

      function initFields(properties: any[]) {
        let fieldsArr: any[] = [];
        properties.forEach(item => {
          fieldsArr.push({...item});
        })

        fieldsArr.forEach((item: { selected: boolean; }) => item.selected = true);
        setFields(fieldsArr);
      }

      initFields(properties);
      const newJoin = join
        .setJoinSourceTableId(table.id)
        .setDefaultCondition()
        .setDefaultAlias();
      console.log("JoinTablePicker--onChange--join", newJoin, join);
      setCurJoin(newJoin);
      await updateQuery(newJoin.parent());
    }

    return (
      <NotebookCellItem
        color={color}
        inactive={!joinedTable}
        right={
          joinedTable && (
            <JoinFieldsPicker
              join={curJoin}
              fields={fields}
              updateQuery={updateQuery}
              triggerElement={FieldsPickerIcon}
              triggerStyle={FIELDS_PICKER_STYLES.trigger}
              joinedTable={joinedTable}
              query={query}
            />
          )
        }
        containerStyle={FIELDS_PICKER_STYLES.notebookItemContainer}
        rightContainerStyle={FIELDS_PICKER_STYLES.notebookRightItemContainer}
      >
        <DataSourceSelector
          hasTableSearch
          canChangeDatabase={false}
          selectedTableId={curJoin.joinSourceTableId()}
          setSourceTableFn={onChange}
          isInitiallyOpen={true}
          query={query}
          triggerElement={
            <FieldPickerContentContainer>
              {joinedTable ? joinedTable.description : '选择数据'}
            </FieldPickerContentContainer>
          }
        />
      </NotebookCellItem>
    );
  };

export default JoinTablePicker;


interface JoinFieldsPickerPropTypes {
  join: Join,
  query: StructuredQuery,
  joinedTable: any,
  triggerElement: any,
  triggerStyle: any,
  fields: any[],
  updateQuery: (query: any) => Promise<void>;
};

const JoinFieldsPicker: React.FC<JoinFieldsPickerPropTypes> =
  ({
     join,
     query,
     joinedTable,
     triggerElement,
     triggerStyle,
     fields,
     updateQuery,
   }: JoinFieldsPickerPropTypes) => {
    const [checkboxData, setCheckboxData] = useState<any[]>(fields);

    const handleToggle = async (changedIndex: number, isSelected: boolean) => {
      let data = [...checkboxData];
      data[changedIndex].selected = isSelected;
      setCheckboxData(data);

      let fieldRefs: any[] = [];
      data.forEach(item => {
        if (item.selected) {
          fieldRefs.push(getFieldReference(item, join.alias));
        }
      })
      await updateQuery(join.setFields(fieldRefs).parent());
    };

    const setAllCheckBoxData = (isAll: boolean) => {
      let data = [...checkboxData];
      data.forEach(item => item.selected = isAll);
      setCheckboxData(data);
    }

    useEffect(() => {
      setCheckboxData(fields);
    }, [fields])

    async function onSelectAll() {
      setAllCheckBoxData(true);
      await updateQuery(join.setFields("all").parent());
    }

    async function onSelectNone() {
      setAllCheckBoxData(false);
      await updateQuery(join.setFields("none").parent());
    }

    return (
      <PopoverWithTrigger
        triggerStyle={FIELDS_PICKER_STYLES.trigger}
        triggerElement={FieldsPickerIcon}
      >
        <FieldPicker
          columnsInfo={checkboxData}
          isAll={join.fields === "all"}
          isNone={join.fields === "none"}
          onToggle={handleToggle}
          onSelectAll={onSelectAll}
          onSelectNone={onSelectNone}
        />
      </PopoverWithTrigger>
    );
  }
