import _ from "lodash";



//表达式处理
/*
* str: 字符串
* widgetNames 组件widgetName集合，替换字符串中匹配的
* record 页面的属性
* isRegex 是否是正则表达式
* */
export function handleTextFun(regs:any) {
  const {str, widgetNames, record, isRegex} = regs;
  if(!str){
    return str;
  }
  let newValue = str;
  _.each(widgetNames, (j:any)=>{
    if(newValue.indexOf(j+'.')>=0){
      let pattern = `${j}.`;
      let regex = new RegExp(pattern, 'g');
      newValue = newValue.replace(regex, `dataTree.${record.slug}.${j}.`);
    }
  })
  let s = newValue.replace(/\\/g, '\\\\');

  if (s.indexOf&&s.indexOf('{{') >= 0 && s.indexOf('}}') > 0) {
    return s.replace(/{{/g, '').replace(/}}/g, '');
  } else {
    return `'${s}'`;
  }
}
function withWidgetProps(widgetConfig:any, page:any){
  let propertyPath = `'${page.slug}.${widgetConfig.widgetName}'`;
  return {
    ...widgetConfig,
    propertyPath
  };
}
export default withWidgetProps;
