import _ from "lodash";
import prettierFormat from "../../utils/prettierFormat";
import toWidgets from "./configToWidget";
export default async function Config (record:any, dataTree:any, jsBody:any):Promise<string>{
  const dsl:any = record.layouts[0].dsl;
  let JSObject1 = jsBody;
  let begin:string = '',
    binds:string = '',
    useLoad:string = '',
    handle:string = '',
    head:string = `import {View} from "@tarojs/components";
import {useDispatch, useSelector} from "react-redux";
import React, {useState, useEffect} from "react";
import _ from "lodash";
import evalEvent from './event';`;
  let temp:string = `
export default function Dsl${record.slug}(){
  const dispatch = useDispatch();
  const dataTree = useSelector((state:any)=>{
    return state.dataTreeReducer.dataTree;
  });
  `;
  if(JSObject1){
    head += `import JSObject from './JSObject1';`;
    let jsBody = JSObject1.substring(14);
    let jsJSON = eval('('+jsBody+')');

    let JSObjectProper:any = '';
    _.each(_.keys(jsJSON), (i:any)=>{
      if(typeof jsJSON[i] !== 'function'){
        JSObjectProper += `${i}: JSObject.${i},`;
      }
    })
    JSObjectProper = JSObjectProper.slice(0, -1);
    JSObjectProper = '{'+JSObjectProper+'}';
    temp += `const [JSObject1, setJSObject1] = useState(${JSObjectProper});`;
    temp += `useEffect(()=>{
    const onChange = () => {
      setJSObject1(${JSObjectProper})
    }
    JSObject.addListeners(onChange);
    return () => {
      JSObject.listeners = JSObject.listeners.filter(cb => cb !== onChange);
    };
  }, []);`
  }


  useLoad = 'useEffect(()=>{';
  const widgetNames:string[] = [];
  const widgetNameFn = (child:any)=>{
    _.each(child, (i:any)=>{
      widgetNames.push(i.widgetName);
      i.children&&widgetNameFn(i.children);
    })
  }
  widgetNameFn([dsl]);
  let widgetImportHead:any = [];
  const fn = (child:any)=>{
    _.each(child, (i:any)=>{
      if(i.type === 'CANVAS_WIDGET'){
        begin += '<View className="canvas-box">';
        fn(i.children);
        begin += '</View>';
      }else if(i.type === 'CONTAINER_WIDGET'){
        widgetImportHead.push('import XBase from "../../components/widgets/XBase";');
        begin += `<XBase style={{ backgroundColor: '${i.backgroundColor}', borderColor: '${i.borderColor}' }} propertyPath="${record.slug}.${i.widgetName}" className="x-container">`;
        fn(i.children);
        begin += '</XBase>';
      }else{
        let {begin:_begin, binds:_binds, useLoad:_useLoad, head:_head, handle:_handle} = toWidgets(i, widgetNames, record);
        begin = begin + _begin;
        binds = binds + _binds;
        handle = handle + _handle;
        useLoad = useLoad + _useLoad;
        widgetImportHead.push(..._head.split('&'));
        i.type !== 'LIST_WIDGET_V2'&&i.children&&fn(i.children);
      }
    })
  };
  fn([dsl]);
  widgetImportHead = _.uniqWith(widgetImportHead, (a, b)=> {
    return a === b;
  });
  head = head + widgetImportHead.join('');
  temp = head + temp;
  temp += binds;
  useLoad += '},[]);';
  temp += handle;
  temp += useLoad;
  temp += `return (`;
  temp += begin;
  temp += `)`;
  temp += `}`;

  temp = await prettierFormat(temp);
  return temp;
}
