import * as React from "react";
import {useEffect, useState} from "react";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import FilterPopoverHeader from "@byk/pages/QueryBuilder/components/ui/FilterPopover/FilterPopoverHeader";
import FilterPopoverFooter from "@byk/pages/QueryBuilder/components/ui/FilterPopover/FilterPopoverFooter";
import {color} from "@byk/pages/QueryBuilder/lib/colors";
import Filter from "@byk/pages/QueryBuilder/lib/queries/structured/Filter";
import DimensionList from "@byk/pages/QueryBuilder/components/DimensionList";
import {FilterPopoverSeparator} from "@byk/pages/QueryBuilder/components/ui/FilterPopover/FilterPopover.styled";
import {FieldDimension} from "@byk/pages/QueryBuilder/lib/metadata/Dimension";
import FilterPopoverPicker from "@byk/pages/QueryBuilder/components/ui/FilterPopover/FilterPopoverPicker";
import {QueryBuilderIcons} from "../../../../../../icons/QueryBuilderIcons";
import ExpressionWidget from "@byk/pages/QueryBuilder/components/notebook/steps/ExpressionStep/ExpressionWidget";
import {Expression} from "@byk/pages/QueryBuilder/lib/metadata/types";
import {isExpression} from "@byk/pages/QueryBuilder/lib/expressions";

const MIN_WIDTH = 300;
const MAX_WIDTH = 410;

export interface Props {
  className?: any;
  style?: any;
  fieldPickerTitle?: any;
  filter?: Filter;
  query: StructuredQuery;
  onChange?: (filter: any) => void;
  onChangeFilter: (filter: any) => void;
  onResize?: () => void;
  onClose?: () => void;

  noCommitButton?: boolean;
  showFieldPicker?: boolean;
  showOperatorSelector?: boolean;
  dateShortcutOptions?: any;
  showCustom?: boolean;
  isNew?: boolean;
  isTopLevel?: boolean;
  checkedColor?: string;
};


const FilterPopover: React.FC<Props> =
  ({
     isNew: isNewProp,
     filter: filterProp,
     style = {},
     showFieldPicker = true,
     showCustom = true,
     noCommitButton,
     className,
     query,
     showOperatorSelector,
     fieldPickerTitle,
     isTopLevel,
     dateShortcutOptions,
     checkedColor,
     onChange,
     onChangeFilter,
     onResize,
     onClose,
   }: Props) => {
    const [filter, setFilter] = useState<Filter>();
    const isNew = isNewProp || !filterProp?.operator();
    const [choosingField, setChoosingField] = useState(!filter);
    const [editingFilter, setEditingFilter] = useState<boolean>();

    useEffect(() => {
      if (filterProp) {
        setFilter(filterProp);
        setEditingFilter(true);
      }
    }, []);

    const handleFilterChange = (mbql: any[] = []) => {
      console.log("FilterPopover---handleFilterChange", mbql);
      const newFilter = filter ? filter.set(mbql) : new Filter(mbql, null, query);
      setFilter(newFilter);
    };

    const handleUpdateAndCommit = (newFilterMbql: any[]) => {
      const base = filter || new Filter([], null, query);
      const newFilter = base.set(newFilterMbql) as Filter;

      setFilter(newFilter);
      handleCommitFilter(newFilter, query);
    };

    const handleCommit = (newFilterMbql?: any[]) => {
      handleCommitFilter(
        newFilterMbql ? filter?.set(newFilterMbql) : filter,
        query,
      );
    };

    const onBack = () => {
      console.log('FilterPopover---onBack');
      setChoosingField(true);
    };

    function handleDimensionChange(dimension: FieldDimension) {
      console.log("step-filter: FilterPopover--handleDimensionChange ", dimension);
      const newFilter = !filter ? new Filter(
        [],
        null,
        query,
      ) : filter;

      setFilter(newFilter.setDimension(dimension.mbql(), true));
      setChoosingField(false);
    }

    function handleCommitFilter(newFilter: Filter, query: StructuredQuery) {
      if (newFilter && !(newFilter instanceof Filter)) {
        newFilter = new Filter(newFilter, null, query);
      }
      if (newFilter && newFilter.isValid() && onChangeFilter) {
        onChangeFilter(newFilter);
        if (typeof onClose === "function") {
          onClose();
        }
      }
    }

    const handleExpressionChange = (name: string, expression: Expression) => {
      if (isExpression(expression) && Array.isArray(expression)) {
        handleUpdateAndCommit(expression);
      }
    };

    const handleExpressionWidgetClose = () => {
      setEditingFilter(false);
    };

    if (editingFilter) {
      return (
        <ExpressionWidget
          query={query}
          expression={filter?.raw() as Expression | undefined}
          startRule="boolean"
          onChangeExpression={handleExpressionChange}
          onClose={handleExpressionWidgetClose}
        />
      );
    }

    let filterQuery = filter?.query() || query;

    const dimension = filter && filter.dimension();

    if (!filter || choosingField) {
      return (
        <div
          className={className}
          style={{minWidth: MIN_WIDTH, overflowY: "auto", ...style}}
        >
          <DimensionList
            style={{color: color("filter")}}
            maxHeight={Infinity}
            dimension={dimension}
            sections={filterQuery.filterFieldOptionSections(filter)}
            onChangeDimension={(dimension: FieldDimension) =>
              handleDimensionChange(dimension)
            }
            onChangeOther={(item: { filter: Filter; query: StructuredQuery }) => {
              handleCommitFilter(item.filter, item.query);
            }}
            width="100%"
            alwaysExpanded={isTopLevel}
          />

          <div
            style={{color: color("filter")}}
            className="List-section List-section--togglable"
            onClick={() => setEditingFilter(true)}
          >
            <div className="List-section-header mx2 py2 flex align-center hover-parent hover--opacity cursor-pointer">
              <span className="List-section-icon mr1 flex align-center">
                <QueryBuilderIcons.Filter color={color("filter")}/>
              </span>
              <h3 className="List-section-title text-wrap">
                自定义表达
              </h3>
            </div>
          </div>
        </div>
      );
    }

    const primaryColor = color("brand");

    return (
      <div className={className} style={{minWidth: MIN_WIDTH, ...style}}>
        <FilterPopoverHeader
          filter={filter}
          onFilterChange={handleFilterChange}
          onBack={onBack}
          showFieldPicker={showFieldPicker}
          forceShowOperatorSelector={showOperatorSelector}
        />
        <FilterPopoverSeparator/>

        <FilterPopoverPicker
          className="px1 pt1 pb1"
          filter={filter}
          onFilterChange={handleFilterChange}
          onCommit={handleCommit}
          maxWidth={MAX_WIDTH}
          primaryColor={primaryColor}
          checkedColor={checkedColor}
        />

        <FilterPopoverFooter
          className="px1 pb1"
          filter={filter}
          onFilterChange={handleFilterChange}
          onCommit={!noCommitButton ? handleCommit : null}
          isNew={!!isNew}
        />
      </div>
    );
  }

export default FilterPopover;
