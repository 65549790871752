import * as React from "react";
import {useRef} from "react";
import {FilterOperator} from "@byk/pages/QueryBuilder/lib/metadata/types/deprecated-types";
import PopoverWithTrigger from "@byk/pages/QueryBuilder/components/ui/PopoverWithTrigger";
import {DownOutlined} from "@ant-design/icons";
import VirtualList from "rc-virtual-list";
import {List} from "antd";

export interface Props {
  className: string,
  operator: FilterOperator,
  operators: FilterOperator[],
  onOperatorChange: (vlaue: any) => void,
};

const OperatorSelector: React.FC<Props> =
  ({
     className,
     operator,
     operators,
     onOperatorChange
   }: Props) => {
    const ref = useRef<any>();

    return (
      <PopoverWithTrigger
        ref={ref}
        triggerElement={
          <>
            <a>
              <button>
                <span>
                  <span>{operator.verboseName}</span>
                </span>
                <DownOutlined/>
              </button>
            </a>
          </>
        }
      >
        {({onClose}: any) => (
          <List>
            <VirtualList
              data={operators}
              itemKey="name"
            >
              {(item: any) => (
                <List.Item
                  style={{
                    borderRadius: "6px",
                    paddingLeft: "10px",
                    width: "160px"
                  }}
                  onClick={() => {
                    onOperatorChange(item);
                    onClose();
                  }}
                >
                  {item.verboseName}
                </List.Item>
              )}
            </VirtualList>
          </List>
        )}

      </PopoverWithTrigger>
    );
  }

export default OperatorSelector;
