import NotebookSteps from "./NotebookSteps";
import {NotebookRoot} from "./Notebook.styled";
import React from "react";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";

interface NotebookProps {
  className?: string;
  query: StructuredQuery;
  updateQuery: (query: StructuredQuery) => Promise<void>;
}

const Notebook = ({className, ...props}: NotebookProps) => {
  console.log('1. QueryBuilder--query', props);
  return (
    <NotebookRoot className={className}>
      <NotebookSteps {...props} />
    </NotebookRoot>
  );
};

export default Notebook;
