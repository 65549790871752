import securityKey from '@byk/utils/securityKey';
import React, { useEffect, useState, useRef } from 'react';

const MapSelector = ({ onLocationSelected, defaultCenter = { lng: 116.397428, lat: 39.90923 }, defaultZoom = 10, radius = 1000 }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // 加载高德地图API  
    const script = document.createElement('script');
    script.src = `https://webapi.amap.com/maps?v=1.4.15&key=${securityKey.key}&plugin=AMap.Geocoder,AMap.PlaceSearch`; // 替换YOUR_AMAP_API_KEY为你的API密钥  
    script.async = true;
    document.body.appendChild(script);

    // 监听高德地图API加载完成  
    script.onload = () => {
      initMap();
    };

    return () => {
      window.AMap = null; // 清理全局变量，避免内存泄漏  
    };
  }, []);

  const initMap = () => {
    // 初始化地图  
    const newMap = new window.AMap.Map(mapRef.current, {
      zoom: defaultZoom,
      center: defaultCenter,
    });

    // 初始化地点搜索插件  
    const placeSearch = new window.AMap.PlaceSearch({
      map: newMap,
      pageSize: 10, // 每页显示的结果条数  
      pageIndex: 1, // 页码  
    });

    // 假设你已经有了一个定位点（这里以defaultCenter为例）  
    // 根据定位点进行周边搜索  
    placeSearch.searchNearBy('', defaultCenter, radius, (status, result) => {
      if (status === 'complete' && result.info === 'OK') {
        setLocations(result.poiList.pois); // 设置搜索到的地址列表  
      }
    });

    // 添加点击地图事件监听器  
    newMap.on('click', (e) => {
      const { lng, lat } = e.lnglat;
      // 这里可以添加逻辑来根据点击的点进行搜索，或者只是简单地选择这个点  
      // 例如：  
      // handleLocationSelected({ lng, lat });  
    });

    setMap(newMap); // 将地图实例保存到状态中以备后用（如果需要的话）  
  };

  const handleLocationSelected = (location) => {
    onLocationSelected(location); // 通知父组件地点被选中  
  };

  // 渲染地图容器和搜索到的地址列表（这里只是简单示例，你需要根据实际情况设计UI）  
  return (
    <div>
      <div ref={mapRef} style={{ width: '100%', height: '400px' }} />
      <div>
        {locations.map((location, index) => (
          <div key={index} onClick={() => handleLocationSelected(location)}>
            {location.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapSelector;