import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import {Button, Form, Input, Modal, Select} from "antd";
import {TrackContextFn} from "@byk/pages/Track/index";
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";
import TextArea from "antd/es/input/TextArea";
import {issuesApi} from "@byk/pages/Track/trackApi";
import styled from "styled-components";
import UploadFileBlobHttp from "@byk/pages/Track/UploadFileBlobHttp";
import {ContentPageFlagType, CustomFieldType} from "@byk/pages/Track/constant";
import {useSelector} from "react-redux";
import {AppState} from "../../../ce/reducers";

const FormContainer = styled.div`
  padding-left: 25px;

  .ant-form-item {
    width: 882px;
  }

  .ant-form-vertical .ant-form-item-row {
    flex-direction: column;
    margin-left: 24px;
    margin-top: -27px;
  }

  .ant-input {
    border-radius: 5px;
    height: 40px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }

  .iconPark {
    display: inline-block;
    color: #717171;
  }
  .fileDesc {
    color: #717171;
  }

  .formBtnBar {
    margin-top: 10px;
    width: 880px;
    display: flex;
    justify-content: center;

    .iconPark {
      height: 13px;
    }

    button {
      width: 113px;
      height: 40px;
    }
  }
`

const TrackForm = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    openEdit
  }));
  const {closeEdit, isModal, setAttachments} = props;
  const {setContentPageFlag, project, files, setFiles, typeOptions, issue, setIssue} = TrackContextFn();
  const [form] = Form.useForm();
  const [type, setType] = React.useState<any>();
  const [fileList, setFileList] = React.useState<any>(files);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState<any>();
  const userInfo: any = useSelector((state: AppState) => {
    return state.entities.app.user || {};
  });

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
      setFiles(formData.files);
      setFileList(formData.files);
    }
  }, [formData]);

  const goBack = () => {
    setContentPageFlag(ContentPageFlagType.LIST);
  }

  const submitTrack = () => {
    form.validateFields().then(
      (values: any) => {
        let params: any = {
          project: {
            id: project.id,
          },
          summary: values.summary,
          description: values.description,
          fields: [
            {'name': CustomFieldType.工单类型.name, '$type': CustomFieldType.工单类型.type, 'value': {name: type.data.name}},
            {'name': CustomFieldType.联系电话.name, "$type": CustomFieldType.联系电话.type, "value": values.mobile},
            {'name': CustomFieldType.Byk用户ID.name, "$type": CustomFieldType.Byk用户ID.type, "value": userInfo.id},
          ]
        }

        let formData = new FormData();
        formData.append('feedbackForm', JSON.stringify(params));
        fileList.forEach((item: any) => formData.append('files', item));
        issuesApi.feedbackFormSubmit(formData, (r: any) => {
          goBack()
        });
      }
    )
  }

  const updateTrack = () => {
    form.validateFields().then(
      (values: any) => {
        let params: any = {
          summary: values.summary,
          description: values.description,
          fields: [
            getFiledValueForUpdate(CustomFieldType.工单类型, values.type),
            getFiledValueForUpdate(CustomFieldType.联系电话, values.mobile),
            getFiledValueForUpdate(CustomFieldType.Byk用户ID, userInfo.id),
          ]
        }

        issuesApi.updateIssue(formData.id, params, (r: any) => {
          handleCloseModal();
          setIssue({...issue, ...values});
        })
      }
    )
    setIsOpen(false);
    closeEdit();
  }

  const getFiledValueForUpdate = (customFieldType: any, formValue: any) => {
    let value = formValue;
    let filedMap = project.fieldsMap[customFieldType.name];
    if (filedMap.values) {
      value = {id: filedMap.values[formValue].id, value: formValue}
    }
    return {$type: customFieldType.type, id: project.fieldsMap[customFieldType.name].id, value,}
  }

  const openEdit = (formData: any) => {
    setFormData(formData);
    setIsOpen(true);
  }

  const handleChange = (value: any, option: any) => {
    setType(option)
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    closeEdit();
  }

  const getFormContent = () => {
    return (
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
        >
          <div>
            {getIconPark('Text')}
            <Form.Item
              label="标题" name='summary'
              rules={[{required: true, message: '请输入标题'}]}
            >
              <Input/>
            </Form.Item>
          </div>

          <div>
            {getIconPark('MoreApp')}
            <Form.Item
              label="工单类型" name='type'
              rules={[{required: true, message: '请选择工单类型'}]}
            >
              <Select
                onChange={handleChange}
                options={typeOptions}
              />
            </Form.Item>
          </div>
          <div>
            {getIconPark('PhoneTelephone')}
            <Form.Item
              label="联系电话" name='mobile'
              initialValue={!issue.id ? userInfo?.mobile : issue.mobile}
              rules={[{required: true, message: '请输入联系电话'}]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div>
            {getIconPark('BookOpen')}
            <Form.Item
              label="描述" name='description'
              rules={[{required: true, message: '请输入描述'}]}
            >
              <TextArea/>
            </Form.Item>
          </div>
          <div>
            {getIconPark('LinkOne')}
            <div className='ant-form-item-row'>
              <div>补充附件<span className='fileDesc'>（上传文件可以帮助工作人员快速定位到您的问题）</span></div>
              <UploadFileBlobHttp
                defaultFileList={[...fileList]}
                onChange={(files: any) => {
                  setFileList(files);
                  setAttachments && setAttachments(files);
                }}
                draftCommentId={null}
                issueId={issue.id}/>
            </div>
          </div>
        </Form>

        {!isModal && <div className='formBtnBar'>
          <Button type='primary' onClick={submitTrack}>提交</Button>
        </div>
        }
      </FormContainer>
    )
  }

  return (<>
      {!isModal &&
      <>
        <div className='breadcrumb'><Button icon={getIconPark('Left')} onClick={goBack}>返回</Button>提交工单</div>
        {getFormContent()}
      </>
      }

      {isModal && <>
        <Modal
          title='编辑工单'
          cancelText='取消'
          okText='保存'
          maskClosable={false}
          open={isOpen} width={983} onCancel={handleCloseModal} onOk={updateTrack}>
          {getFormContent()}
        </Modal>
      </>
      }

    </>
  )
});

export default TrackForm;
