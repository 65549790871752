export default function Config ():string{
  return `
import _ from 'lodash';
import {call} from "@redux-saga/core/effects";
import Api from "../api";
import setWidgetValue from "../utils/setWidgetValue";
export function* reEventsActions(actions:any, reqParams:any):Generator<Record<string, unknown>>{
  const {actionId, dictId, currPage, pageSize, action} = actions;

  const resAction: any = yield call(()=>{
    return Api.get('/byk/platform/subapp/AppAction', {
      id: actionId
    });
  });

  const _action:any = resAction as any;
  if(_.isEmpty(_action.result)){
    return {};
  }
  let {url, requestMsgFields, requestType, serviceCode, responseMsgFields, elementId,pageName} = _action.result?.[0];
  let reqMsgFieldsObj = requestMsgFields?JSON.parse(requestMsgFields):[];
  let resMsgFieldsObj = responseMsgFields?JSON.parse(responseMsgFields):[];
  let newReqValues = {
    ...reqParams
  };

  let newReqParams:any = {};
  let _page = reqMsgFieldsObj.filter((i:any)=>{
    return !i.startsWith('__');
  });

  //页面元素
  if(!_.isEmpty(_page)){
    const reqMapFn = (data:any, obj:any)=>{
      _.each(data, (key:any)=>{
        if(typeof key == 'string'){
          let _key:any = key;//key.split('.').splice(-1).join();
          if(_key.endsWith('currPage')&&requestType==='get'){
            obj[key] = currPage??1;//newReqValues[_key]||1;
          }else if(_key.endsWith('pageSize')&&requestType==='get'){
            obj[key] = pageSize??10;//newReqValues[_key]||10;
          }else if(newReqValues[_key]||newReqValues?.apiParams?.[_key]){
            obj[key] = newReqValues[_key]||newReqValues?.apiParams?.[_key];
          }else{
            //
          }
        }else if(typeof key == 'object'){
          _.each(key, (value, valueKey)=>{
            obj[valueKey] = {};
            reqMapFn(value, obj[valueKey]);
          })
        }
      })
    };
    reqMapFn(_page, newReqParams);
  }
  //是否绑定服务得入参处理 动态表单和配置的入参与映射入参做合并
  if(_.isEmpty(serviceCode)){
    newReqParams = {
      ...newReqValues,
      ...newReqValues?.apiParams
    }
    delete newReqParams.apiParams;
  }else{
    let mappingPageParams:Record<string, unknown> = {};
    //取页面元素入参
    if(!_.isEmpty(_page)){
      _.each(_page, (i:any)=>{
        if(['get', 'exported'].includes(requestType)){
          mappingPageParams[i] = newReqParams[i]??'';
        }else{
          mappingPageParams[i] = newReqParams[i] === ''?null:newReqParams[i];
        }

      })
    }
    //动作框里的输入参数
    if(newReqValues.eventParams){
      mappingPageParams = {
        ...mappingPageParams,
        ...newReqValues.eventParams
      }
    }
    newReqParams = mappingPageParams;
  }


  const res = yield call(()=>{
    return Api.methods(requestType, \`\${url}\`, newReqParams);
  });

  const res2:any = res as any;
  const res2Data = res2.data?.body?.data[0];
  //输出字段对应的组件类型
  let widgetTypeMap:Record<string, string> = {};
  if(res2Data?.['__widgetTypeMap__']){
    widgetTypeMap = JSON.parse(res2Data['__widgetTypeMap__']);
  }
  //接口报错直接返回
  if(!res2Data.success){
    return {
      data: [],
      size: res2Data.size,
      success: res2Data.success,
      message: res2Data.message
    };
  }


  //begin 处理接口输出映射
  const res2DataResult:Array<any> = res2Data?.result||[];
  let keyMap:Record<string, any> = {//默认值，不需映射
    id: "id",
    label: "label",
    value: "value",
    key: "key",
    children: "children",
    field: 'field',
    fieldName: 'fieldName',
    fieldType: 'fieldType',
    options: 'options',
    required: 'required',
    message: 'message'
  };
  _.each(resMsgFieldsObj, (values:any)=>{
    if(_.isString(values)){
      keyMap[values] = (values.endsWith('.label')||values.endsWith('.value'))?values:(values.split('.').splice(-1).join());
    }
  })
  //输出时把对应类型的值转换
  let res2DataResultHandle:Array<any> = yield call(setWidgetValue, {
    res2DataResult,
    keyMap,
    widgetTypeMap
  })
  //end
  return (_.isEmpty(serviceCode)||_.isEmpty(res2DataResultHandle))?{
    data: res2DataResult,
    size: res2Data.size,
    success: res2Data.success,
    message: res2Data.message
  }:{
    data: res2DataResultHandle,
    size: res2Data.size,
    success: res2Data.success,
    message: res2Data.message
  };
}

  `;
}
