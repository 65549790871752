import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Icon from "@byk/pages/components/Icon/Icon";

import {NotebookCell} from "./NotebookCell";
import React from "react";
import {DownOutlined} from "@ant-design/icons";

export const FieldPickerContentContainer = styled.div`
  padding: ${NotebookCell.CONTAINER_PADDING};
`;

const StyledIcon = styled(Icon)`
  opacity: 0.5;
`;

const propTypes = {
  isTriggeredComponentOpen: PropTypes.bool,
};

export function FieldsPickerIcon(
  {
    isTriggeredComponentOpen,
  }: {
    isTriggeredComponentOpen?: boolean;
  }) {
  return (
    <FieldPickerContentContainer data-testid="fields-picker">
      <DownOutlined/>
    </FieldPickerContentContainer>
  );
}

FieldsPickerIcon.propTypes = propTypes;

export const FIELDS_PICKER_STYLES = {
  notebookItemContainer: {
    padding: 0,
  },
  notebookRightItemContainer: {
    width: 37,
    height: 41,
    padding: 0,
  },
  trigger: {
    marginTop: 1,
  },
};
