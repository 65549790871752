import React from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import XModal from "@byk/pages/components/XModal";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm,Modal,Row,Col, message } from "antd";
import Api from "@byk/api/Api";

import {  withRouter } from "react-router-dom";
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';
import history from "utils/history";
import localStorage, {getProjectId, getTenant} from "utils/localStorage";
import { apiPath, routePath } from "@byk/routes";
import {appTenantClass} from "@byk/utils/Utils";

class AppServicesManager extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  constructor(props:any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
  }
  state = {
    record: null,
    visible: false,
    rows: [],
    editData: null,
    projectId: getProjectId(),
    tenant: getTenant(),
    initParams: {
      tenant: getTenant(),
      queryMode: "allLike",
      desc:"updateDate"
    },
  };
  async componentDidMount() {
  }

  render() {
    // api
    const apiGet: string = `${apiPath}/platform/rest/LowcodeService/list`;
    const apiPost: string = `${apiPath}/platform/rest/LowcodeService`;
    const apiPut: string = `${apiPath}/platform/rest/LowcodeService`;
    const apiDel: string = `${apiPath}/platform/rest/LowcodeService`;
    //表格refresh
    const refreshTable = (params?: any) => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    };
    //保存成功后
    const afterSaveEvent = (params?: any) => {
      let _r=params.result[0];
      Modal.success({
        title: '保存成功',
        icon: <CheckCircleOutlined />,
        content: '',
        okText: '服务编排',
        cancelText: '返回列表',
        onOk() {redirectUrl(_r)},
      });
    };
    //改造提交的data
    const reformData = (params?: any, type?: any) => {
      return params;
    };
    const searchParam=()=>{
      let params= {...this.searchRef.current.getSearchData(),...this.state.initParams};
      return params;
    }
    const readFile=(callback:any, accept:any, isBase64?:any)=>{
      let reader = new FileReader();
      reader.onload = function(e:any){
        let result = e.target.result;
        if(!isBase64)
          try{
            result = JSON.parse(result);
          } catch (e) {
          }
        callback(result);
      };
      let input:any = document.createElement("input");
      input.type = "file";
      input.accept = accept;
      input.onchange = function(){
        let file = input.files[0];
        if(isBase64)
          reader.readAsDataURL(file);
        else
          reader.readAsText(file);
      };
      input.click();
    };
    const imService=()=> {
      let callback = async function(data:any){
        if(!data.code||!data.name||!data.nodes){
          message.warn("服务文件格式不正确");
          return;
        }
        let postUrl = `${apiPath}/platform/service/import`;
        let result:any = await Api.post(postUrl, data);
        if (result.success) {
          message.success("导入成功!");
          refreshTable()
        }
        else {
          message.error(result.message);
        }
        // let option = {};
        // option["url"] = "../../%s/service/import".format(tenant!="default"?tenant:"platform");
        // option["type"] = "POST";
        // option["data"] = JSON.stringify(data);
        // option["contentType"] = "application/json";
        // option["dataType"] = "json";
        // option["complete"] = (r)=>{
        //   if(r.responseJSON.success)
        //     load();
        //   else
        //     alert("导入失败:"+r.responseJSON.message);
        // };
        // $.ajax(option);
      }
      readFile(callback, ".service");
    }

    const redirectUrl= (record?: any) => {
      let url=`${routePath}/ServiceSetup`;
      if(record){
        url=url+`?id=${record.id}`;
      }
      history.push(url);
    };
    //编辑按钮
    const EditClick = async (record: any) => {
      let result:any = await Api.get(apiPost, { id: record.id });
      if (result.success) {
        if (result.result.length > 0) {
          let _record = result.result[0];
          let { id, code, name, category, description } = record;
          this.modalRef.current?.edit({
            id,
            code,
            name,
            category,
            description,
          });
        }
      }
    };

    //编排
    const SetupClick = (record: any) => {
      let { id } = record;
      let result:any = Api.delete(apiDel, [id]);
      if (result.success) {
        refreshTable();
      }
    };
    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result:any = await Api.delete(apiDel, [id]);
      if (result.success) {
        refreshTable();
      }
    };
    //查询项配置
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "code",
        label: "服务编码",
        allowClear: true,
        col: 6,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "服务名",
        allowClear: true,
        col: 6,
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "category",
        label: "分类",
        allowClear: true,
        col: 6,
      },
    ];

    //新增表单项
    const ModalConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入111",
        name: "code",
        label: "服务编码",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "服务名",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "category",
        label: "分类",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "textarea",
        placeholder: "请输入",
        name: "description",
        label: "服务描述",
        allowClear: true,
        maxlength: 1000,
        height: 80,
        col: 24,
      },
    ];

    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record,index) => `${index + 1}`,
        width: 70,
      },

      {
        title: "服务ID",
        dataIndex: "id",
        key: "id",
        width: 100,
      },
      {
        title: "服务编码",
        dataIndex: "code",
        key: "code",
        width: 100,
      },
      {
        title: "服务名",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "分类",
        dataIndex: "category",
        key: "category",
        width: 100,
      },
      {
        title: "服务描述",
        dataIndex: "description",
        key: "description",
        width: 300,
        render: (text, record) => (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {text}
          </div>
        ),
      },
      {
        title: "修改人",
        dataIndex: "updater",
        key: "updater",
        width: 100,
      },
      {
        title: "最后修改时间",
        dataIndex: "updateDate",
        key: "updateDate",
        render: (_, record: any) => (
          moment(record.updateDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <Space size="middle">
            <Button type="link" onClick={() => redirectUrl(record)}>
              编辑
            </Button>
            {/* <Button type="link" onClick={() => redirectUrl(record)}>
              编排
            </Button> */}
            <Popconfirm
              placement="left"
              title={"确认删除该服务？删除后将导致所有引用该服务的流程失效。"}
              onConfirm={() => deleteClick(record)}
              okText="删除"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </Space>
        ),
        width: 100,
      },
    ];
    const searchProps = {
      option: searchConfig,
      onSearch: refreshTable,
      btnBoolean: true,
      btnWrapBoolean:false
    };
    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
      searchParam:searchParam
    };

    //modal 表单配置
    const modalProps = {
      apiPost,
      apiPut,
      option: ModalConfig,
      refreshTable,
      reformData,
      afterSaveEvent,
    };
    return (
      <>
        <div className="x-main-box">
          <SearchForm ref={this.searchRef} {...searchProps} />
          <Row gutter={100} className="x-mt20 x-mb20">
          <Col span={24}>
            
            <Button className="x-fr" type="primary" onClick={()=>redirectUrl()}>
              新建服务
            </Button>
            <Button className="x-fr" style={{marginRight:"15px"}} onClick={()=>imService()}>
              导入服务
            </Button>
          </Col>
         </Row>
          <XModal ref={this.modalRef} {...modalProps}   isShowAdd={false} />
          <TableList ref={this.tableRef} {...tableProps} />
        </div>
      </>
    );
  }
}
export default withRouter(AppServicesManager);
