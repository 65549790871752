import ViewSection from "@byk/pages/QueryBuilder/components/view/ViewSection";
import ConvertQueryButton from "@byk/pages/QueryBuilder/components/view/ConvertQueryButton";
import {SaveButton} from "@byk/pages/QueryBuilder/components/view/ViewHeader.styled";
import {Button, Col, Form, Input, message, Modal, Row} from "antd";
import React, {useEffect, useState} from 'react';
import TextArea from "antd/lib/input/TextArea";
import {useDispatch, useSelector} from "react-redux";
import {getQuery} from "@byk/pages/QueryBuilder/selectors";
import Schemas from "@byk/pages/QueryBuilder/lib/api/schemas";
import {QUERY_STRUCT} from "@byk/store/modelingReducers";

const NewQuestionHeader: React.FC = (props) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const query = useSelector(getQuery)
  const [editQuery, setEditQuery] = useState(query);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: QUERY_STRUCT,
      data: editQuery
    })
  }, [editQuery])

  useEffect(() => {
    form.validateFields(['title', 'description']);
  }, [title, description, form]);

  // 保存SQL
  const saveQuery = async () => {
    try {
      const values = await form.validateFields();
      query.addFormValues(values);
      let result = await Schemas.saveStructuredQuery(query);
      if (result) {
        query.setWithResult(result);
        setEditQuery(query);
        setOpen(false);
        message.success('保存成功');
        form.resetFields();
      } else {
        console.log('Failed:', result);
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }

  // 保存SQL
  const close = () => {
    setOpen(false);
    form.resetFields();
  }

  const openMoal = () => {
    if (!query.valid()) {
      message.warning('请选择数据！');
    } else {
      setOpen(true);
    }
  }

  return (
    <ViewSection {...props}>
      <ConvertQueryButton/>
      <SaveButton onClick={openMoal}>
        保存
      </SaveButton>

      <Modal open={open}
             footer={null}
             closable={false}
             bodyStyle={{
               padding: "12px"
             }}
      >
        <Form
          form={form}
        >
          <Form.Item
            {...formItemLayoutName}
            name="title"
            label="标题"
            rules={[
              {
                required: true,
                message: "标题必填"
              },
            ]}
          >
            <Input placeholder="请为你的查询功能添加标题"/>
          </Form.Item>
          <Form.Item
            {...formItemLayoutDesc}
            name="description"
            label="功能说明"
            rules={[
              {
                required: true,
                message: "功能说明必填"
              },
            ]}
          >
            <TextArea placeholder="请为你的查询功能添加详细说明"/>
          </Form.Item>
          <Row>
            <Col span={22} style={{textAlign: 'right'}}>
              <Button
                style={{
                  marginRight: "10px"
                }}
                size={"small"}
                onClick={close}
              >
                取消
              </Button>

              <Button size={"small"} type="primary" onClick={saveQuery}>
                保存
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

    </ViewSection>);
}

export default NewQuestionHeader;

const formItemLayoutName = {
  labelCol: {span: 4},
  wrapperCol: {span: 12},
};

const formItemLayoutDesc = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};

