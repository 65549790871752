import React from "react";
import PopoverWithTrigger from "@byk/pages/QueryBuilder/components/ui/PopoverWithTrigger";
import {FIELDS_PICKER_STYLES} from "@byk/pages/QueryBuilder/components/notebook/Notebook.styled";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {NotebookCellItem} from "@byk/pages/QueryBuilder/components/notebook/NotebookCell";
import {DimensionContainer, DimensionSourceName, RemoveDimensionIcon} from "../../steps/JoinStep/JoinStep.styled";
import DimensionOptions from "@byk/pages/QueryBuilder/lib/metadata/DimensionOptions";
import Dimension, {FieldDimension} from "@byk/pages/QueryBuilder/lib/metadata/Dimension";
import Filter from "@byk/pages/QueryBuilder/lib/queries/structured/Filter";
import FieldList from "@byk/pages/QueryBuilder/components/FieldList";
import {IconSize} from "@design-system/widgets-old";


export interface JoinDimensionPickerProps {
  color: string,
  query: StructuredQuery,
  dimension: any,
  options: DimensionOptions,
  onChange: (dimension: any) => void,
  onRemove: () => void,
  ref: any,
}

const JoinDimensionPicker: React.FC<JoinDimensionPickerProps> =
  ({
     color,
     query,
     options,
     onChange,
     onRemove,
     dimension,
     ref,
   }: JoinDimensionPickerProps) => {
    const sections = options.sections();

    function onRemoveDimension(e: Event) {
      e.stopPropagation();
      onRemove();
    }

    function handleDimensionChange(dimension: FieldDimension) {

    }

    function handleCommitFilter(filter: Filter, query: StructuredQuery) {

    }

    return (
      <PopoverWithTrigger
        triggerStyle={FIELDS_PICKER_STYLES.trigger}
        triggerElement={
          <JoinDimensionCellItem
            dimension={dimension}
            color={color}
            onRemove={onRemoveDimension}
          />
        }
      >

        {({onClose}: any) => (
          <FieldList
            width={"300px"}
            className="text-brand"
            field={dimension && dimension.mbql()}
            fieldOptions={options}
            table={query.model()}
            query={query}
            onFieldChange={(field: any, {isSubDimension = false} = {}) => {
              onChange(field);
              onClose();
            }}
            enableSubDimensions
            preventNumberSubDimensions
          />

          // <JoinTypeSelectRoot>
          //   {options.dimensions.map(item => (
          //     <JoinTypeOptionRoot
          //       key={item.displayName()}
          //       isSelected={item.displayName() === dimension?.displayName()}
          //       onClick={() => {
          //         onChange(item);
          //         onClose();
          //       }}
          //     >
          //       {item.displayName()}
          //     </JoinTypeOptionRoot>
          //   ))}
          // </JoinTypeSelectRoot>
        )}

      </PopoverWithTrigger>
    );

  };
export default JoinDimensionPicker;

function JoinDimensionCellItem(
  {
    dimension,
    color,
    onRemove,
    readOnly,
  }: any) {
  return (
    <NotebookCellItem color={color} inactive={!dimension}>
      <DimensionContainer>
        <div
          style={{
            width: "100px",
          }}
        >
          {dimension && (
            <DimensionSourceName>
              {getDimensionSourceName(dimension)}
            </DimensionSourceName>
          )}
          {getDimensionDisplayName(dimension)}
        </div>
        {dimension && !readOnly && <RemoveDimensionIcon onClick={onRemove} size={IconSize.XXL} fillColor={"#FFFFFF"}/>}
      </DimensionContainer>
    </NotebookCellItem>
  );
}


function getDimensionSourceName(dimension: Dimension) {
  return dimension.field().table?.name || "以前的结果";
}

function getDimensionDisplayName(dimension: any) {
  if (!dimension) {
    return "选择列";
  }
  return dimension.displayName();
}



