import type {Page} from "@appsmith/constants/ReduxActionConstants";
import {ThemePropertyPane} from "pages/Editor/ThemePropertyPane";
import React from "react";
import styled from "styled-components";
import {THEME_SETTINGS_SECTION_CONTENT_HEADER,} from "@appsmith/constants/messages";
import {Colors} from "constants/Colors";

export enum AppSettingsTabs {
  General,
  Embed,
  Theme,
  Navigation,
  Page,
  Import,
}

export interface SelectedTab {
  type: AppSettingsTabs;
  page?: Page;
}

const SectionContent = styled.div`
  box-shadow: -1px 0 0 0 ${Colors.GRAY_300};
  // property help label underline
  .underline {
    color: ${(props) => props.theme.colors.paneTextUnderline};
  }
  width: 100%;
`;

const ThemeContentWrapper = styled.div`
  height: calc(100% - 48px);
  overflow-y: overlay;
`;

function ThemeSettings() {
  return (
    <ThemeContentWrapper>
      <SectionContent>
        <ThemePropertyPane/>
      </SectionContent>
    </ThemeContentWrapper>
  );
}

export default ThemeSettings;
