import type { AxiosInstance, AxiosRequestConfig } from "axios";
import axios from "axios";
import { convertObjectToQueryParams } from "@byk/utils/URLUtils";
import { message } from "antd";
import { routePath } from "@byk/routes";
import {appUrlParams} from "@byk/utils/Utils";

export const apiRequestConfig = {
  baseURL: "",
  timeout: 60*1000,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use((config:any)=>{
    return config;
});
axiosInstance.interceptors.response.use(
  (res:any)=>{
    //接口成功，success 为false时，提示错误信息
    if(!res.data.success&&res.data.message){
      message.error(res.data.message);
    }

    if(typeof res.data === 'string'
      && res.data.startsWith('<!DOCTYPE html')
      &&window.location.href.indexOf(`${routePath}/login`)<0){
      if(window.location.hostname.indexOf(`-preview`)>0){
        window.location.href = `${routePath}/appLogin${appUrlParams()}`;
      }else{
        window.location.href = `${routePath}/login`;
      }

    }
      return res.data;
  },
    (error:any)=>{
        if(error.response?.status===500){
            message.error(error.response.data.message);
        }
        if(error.response?.status===413){
          message.error("请求数据超出服务器限制");
        }
        return Promise.resolve(error);
    }
);

class Api {
  static get(url: string, queryParams?: any, config: AxiosRequestConfig = {}) {
    return axiosInstance.get(url + convertObjectToQueryParams(queryParams), {
      ...apiRequestConfig,
      ...config,
    });
  }

  static post(
    url: string,
    body?: any,
    queryParams?: any,
    config: AxiosRequestConfig = {},
  ) {
    return axiosInstance.post(
      url + convertObjectToQueryParams(queryParams),
      body,
      {
        ...apiRequestConfig,
        ...config,
      },
    );
  }

  static put(
    url: string,
    body?: any,
    queryParams?: any,
    config: AxiosRequestConfig = {},
  ) {
    return axiosInstance.put(
      url + convertObjectToQueryParams(queryParams),
      body,
      {
        ...apiRequestConfig,
        ...config,
      },
    );
  }

  static patch(
    url: string,
    body?: any,
    queryParams?: any,
    config: AxiosRequestConfig = {},
  ) {
    return axiosInstance.patch(
      url + convertObjectToQueryParams(queryParams),
      body,
      {
        ...apiRequestConfig,
        ...config,
      },
    );
  }

  static delete(
    url: string,
    queryParams?: any,
    config: AxiosRequestConfig = {},
  ) {
    return axiosInstance.delete(url,
        {
            data: queryParams,
            ...apiRequestConfig,
            ...config,
        });
  }

  static postForm(
    url: string,
    body?: any,
    queryParams?: any,
    config: AxiosRequestConfig = {},
  ) {
    return axiosInstance.post(
      url + convertObjectToQueryParams(queryParams),
      body,
      {
        ...apiRequestConfig,
        ...config,
      },
    );
  }

}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export default Api;
