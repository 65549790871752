import React, { useEffect, useState } from "react";
import Api from "@byk/api/Api";

import { JSEncrypt } from 'jsencrypt'
import { Button, Form, Input, Card, message, Checkbox } from "antd";
import logo from "@byk/assets/svg/logo.svg";
import history from "utils/history";
import encryptedData from "@byk/utils/encryptedData";
import Cookies from 'js-cookie';
import _ from "lodash";
import { routePath } from "@byk/routes";
import {ReduxActionTypes} from "../../ce/constants/ReduxActionConstants";
import {useDispatch} from "react-redux";
import {isValidJSON} from "../../utils/XUtils";
import {appTenantClass} from "@byk/utils/Utils";


const Login: React.FC = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(Cookies.get('checked') || '0');
  const [username, setUsername] = useState(_.isEmpty(Cookies.get('username'))?"":window.atob(Cookies.get('username')));
  const [password, setPassword] = useState(_.isEmpty(Cookies.get('password'))?"":window.atob(Cookies.get('password')));
  const onFinish = async (values: any) => {
    //localStorage.clear();
    window.sessionStorage.clear();
    console.log("Success:", values);
    let pwd = encryptedData(values.password);
    let params = new FormData();
    params.append("username", values.username);
    params.append("password", pwd.toString());
    params.append("checkcode", '');

    let result: any = await Api.post("/byk/platform/system/enter", params);
    if (result.success) {
      console.log("res", result);
      message.success("登录成功!");

      if (checked=="1") {
        // localStorage.setItem('username', values.username)
        // localStorage.setItem('password', values.password)
        // localStorage.setItem('checked', "1")
        Cookies.set('username',window.btoa(values.username), { expires: 7 });
        Cookies.set('password',window.btoa(values.password), { expires: 7 });
        Cookies.set('checked', "1", { expires: 7 });
      } else {
        // 反之清除
        // localStorage.removeItem("username")
        // localStorage.removeItem('password')
        // localStorage.removeItem('checked')
        Cookies.remove('username');
        Cookies.remove('password');
        Cookies.remove('checked');
      }
      let path: string = toLogin(result.ext)
      //window.location.href = path;
      let userInfo = {
        ...result.ext
      };
      if(isValidJSON(result.ext.profile)){
        userInfo.profileObj = JSON.parse(result.ext.profile);
      }
      appTenantClass.setUserId(userInfo.id);
      dispatch({
        type: ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
        payload: userInfo,
      })
      history.push(path);

    }
    else {
      //message.error(result.message);
    }
  };

  const toLogin = (ext: any) => {
    let path = routePath||"/";
    let list = JSON.parse(ext.profile);
    if (list.userTenant.length == 0) {
      path = `${routePath}/join`;
    }
    return path;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const toRegister = () => {
    history.push(`${routePath}/register`);
  };
  const onChange = (e: any) => {
    console.log('checked = ', e.target.checked);
    setChecked(e.target.checked?"1":"0");
  };

  return (
    <>
      <main
        className="x-main-login"
      >
        <Card title={<img src={logo} alt="" />} hoverable className="x-login-card">
          <div className="x-w100 x-tl">
            <span className="x-login-span">登录</span>
          </div>

          <Form
            name="basic"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            initialValues={{
              username: username,
              password: password,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=" "
              colon={false}
              name="username"
              rules={[
                {
                  required: true, message: '请输入手机号!'
                },
              ]}
            >
              <Input placeholder="请输入手机号" className="x-login-text" />
            </Form.Item>

            <Form.Item
              label=" "
              colon={false}
              name="password"
              rules={[{ required: true, message: "请输入密码!" }]}
            >
              <Input.Password placeholder="请输入密码" className="x-login-text" />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 0, span: 24 }} className="x-tr">
              <Checkbox checked={checked=="1"} onChange={onChange}>
              </Checkbox><span className="x-ml10">记住我</span>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button type="primary" htmlType="submit" className="x-login-btn" style={{ width: "100%", height: "50px" }}>
                登录
              </Button>
            </Form.Item>
            {/* <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
          <Button
            type="link"
            className="x-login-btn-text   x-f18"
          >
            切换账号密码登录
          </Button>
        </Form.Item> */}
          </Form>
          <Button type="link" className="x-login-btn-text  x-f18" style={{ width: "100%" }} onClick={() => toRegister()}>
            注册新账号
          </Button>
        </Card>
      </main>
    </>
  );
};
//);

export default Login;
