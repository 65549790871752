import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";

/**
 * 数据查询结构化数据
 *  {
 *   database: null,
 *   type: "query",
 *   query: {
 *     "source-table": null,
 *   },
 * }
 */
export function getQuery(state: { modelingReducers: { queryData: any; }; }): StructuredQuery {
  return state.modelingReducers.queryData || new StructuredQuery();
}
