import {NotebookStepUiComponentProps} from "@byk/pages/QueryBuilder/components/notebook/types";
import React from "react";
import ClauseStep from "@byk/pages/QueryBuilder/components/notebook/steps/ClauseStep";
import {BreakoutColumnPicker} from "@byk/pages/QueryBuilder/components/notebook/steps/BreakoutStep/BreakoutStep.styled";
import Breakout from "@byk/pages/QueryBuilder/lib/queries/structured/Breakout";

const breakoutTetherOptions = {
  attachment: "top left",
  targetAttachment: "bottom left",
  offset: "10px 0",
  constraints: [
    {
      to: "scrollParent",
      attachment: "together",
    },
  ],
};


const BreakoutStep: React.FC<NotebookStepUiComponentProps> =
  ({
     step,
     color,
     query,
     updateQuery,
     readOnly,
     isLastOpened,
     extraProps,
   }: NotebookStepUiComponentProps) => {
    const {stageIndex} = step;
    const clauses: Breakout[] = query.breakouts() || [];

    const renderBreakoutName = (clause: Breakout) => {
      return clause.longDisplayName();
    }

    function getColumnGroups(orderByIndex: number | undefined) {
      return query.groupColumns();
    }

    function checkColumnSelected(item: any, breakoutIndex: any) {
      return false;
    }

    async function handleUpdateBreakoutField(breakout: any, column: any) {
      await updateQuery(query.updateBreakout(breakout, column.field));
    }

    async function handleAddBreakout(column: any) {
      await updateQuery(query.addBreakout(column.field));
    }

    async function handleRemoveBreakout(index: number) {
      await updateQuery(query.removeBreakout(index));
    }

    return (
      <ClauseStep
        items={clauses}
        initialAddText={"选择分组的列"}
        readOnly={readOnly}
        color={color}
        isLastOpened={isLastOpened}
        tetherOptions={breakoutTetherOptions}
        renderName={renderBreakoutName}
        renderPopover={(breakout, idx) => (
          <BreakoutColumnPicker
            query={query}
            stageIndex={stageIndex}
            columnGroups={getColumnGroups(idx)}
            checkIsColumnSelected={(item: any) => checkColumnSelected(item, idx)}
            onSelect={async (column: any) => {
              const isUpdate = breakout != null;
              if (isUpdate) {
                await handleUpdateBreakoutField(idx, column);
              } else {
                await handleAddBreakout(column);
              }
            }}
            extraProps={{
              style: {color: "#88BF4D"},
              width: "260px"
            }}
          />
        )}
        onRemove={handleRemoveBreakout}
      />
    );

  };
export default BreakoutStep;
