export default function Config ():string{
  return `
  import { all, put, takeLatest } from 'redux-saga/effects';
import {watchXActionExecutionSagas} from "./XActionExecutionSagas";
import {watchXActionByk} from "./byk";
import {watchXActionDataTree} from "./dataTree";
import {watchShowAlert} from "./showAlert";
import {watchLinkage} from "./linkage";

export default function* rootSaga() {
  yield all([
    watchXActionExecutionSagas(),
    watchXActionByk(),
    watchXActionDataTree(),
    watchShowAlert(),
    watchLinkage(),
  ]);
}

  `;
}
