import React, {Component} from "react";
import Head from "@byk/pages/components/Header";
import {Layout} from 'antd';

import BusinessPage from "@byk/pages/QueryBuilder/containers/QueryBuilder";
import "./css/index.css";

const {Content} = Layout;

export default class Home extends Component {
  render() {
    return (<div>
      <Layout>
        {/*引入头部 */}
        <Head title="QueryBuilder"/>
        <Layout>
          <Content><BusinessPage/></Content>
        </Layout>
      </Layout>
    </div>);
  }
}

