import TsConfig from "./project/TsConfig";
import ProjectTT from "./project/ProjectTT";
import ProjectConfig from "./project/ProjectConfig";
import Package from "./project/Package";
import BabelConfig from "./project/BabelConfig";
import Eslintrc from "./project/Eslintrc";
import EditorConfig from "./project/EditorConfig";

import ConfigDev from "./project/config/Dev";
import ConfigIndex from "./project/config/Index";
import ConfigProd from "./project/config/Prod";

import TypesGlobal from "./project/types/TypesGlobal";

import SrcAppHtml from "./project/src/AppHtml";
import SrcAppScss from "./project/src/AppScss";
import SrcStore from "./project/src/store";
import SrcApp from "./project/src/App";

//import PagesIndex from "./project/src/pages/index/Index";
//import PagesIndexConfig from "./project/src/pages/index/IndexConfig";
//import PagesIndexScss from "./project/src/pages/index/IndexScss";


import SagaIndex from "./project/src/sagas/index";
import SagaByk from "./project/src/sagas/byk";
import SagaDataTree from "./project/src/sagas/dataTree";
import SagaXAction from "./project/src/sagas/XActionExecutionSagas";
import SagaReEvent from "./project/src/sagas/reEventsActions";
import SagaShowAlert from "./project/src/sagas/showAlert";
import SagaLinkage from "./project/src/sagas/linkage";

import ActionsIndex from "./project/src/actions/index";

import ConstantsInex from "./project/src/constants/ReduxActionConstants";

import HooksIndex from "./project/src/utils/hooks/useDataTreeToValue";
import UtilsGetWidgetValue from "./project/src/utils/getWidgetValue";
import UtilsSetWidgetValue from "./project/src/utils/setWidgetValue";

import ReducersIndex from "./project/src/reducers/index";
import ReducersByk from "./project/src/reducers/byk";
import ReducersToast from "./project/src/reducers/toast";

import ApiIndex from "./project/src/api";

import ComWidgetWrapper from "./project/src/components/WidgetWrapper";

import WidgetsXBase from "./project/src/components/widgets/XBase";
import WidgetsXButton from "./project/src/components/widgets/XButton";
import WidgetsXInput from "./project/src/components/widgets/XInput";
import WidgetsXList from "./project/src/components/widgets/XList";
import WidgetsXSwitch from "./project/src/components/widgets/XSwitch";
import WidgetsXCheckbox from "./project/src/components/widgets/XCheckbox";
import WidgetsXGrid from "./project/src/components/widgets/XGrid";
import WidgetsXInputNumber from "./project/src/components/widgets/XInputNumber";
import WidgetsXPicker from "./project/src/components/widgets/XPicker";
import WidgetsXRadio from "./project/src/components/widgets/XRadio";
import WidgetsXSwiper from "./project/src/components/widgets/XSwiper";
import WidgetsXText from "./project/src/components/widgets/XText";
import WidgetsXTextarea from "./project/src/components/widgets/XTextarea";
import WidgetsXIcon from "./project/src/components/widgets/XIcon";
import WidgetsXListView from "./project/src/components/widgets/XListView";
import WidgetsXImage from "./project/src/components/widgets/XImage";
import WidgetsXNavBar from "./project/src/components/widgets/XNavBar";
import WidgetsXProgress from "./project/src/components/widgets/XProgress";
import WidgetsXImagePicker from "./project/src/components/widgets/XImagePicker";

function TaroConfigFiles (): { [filename: string]: string }{
  const files: { [filename: string]: string } = {};

  files['src/components/widgets/XImagePicker.tsx'] = WidgetsXImagePicker();
  files['src/components/widgets/XProgress.tsx'] = WidgetsXProgress();
  files['src/components/widgets/XNavBar.tsx'] = WidgetsXNavBar();
  files['src/components/widgets/XListView.tsx'] = WidgetsXListView();
  files['src/components/widgets/XImage.tsx'] = WidgetsXImage();
  files['src/components/widgets/XIcon.tsx'] = WidgetsXIcon();
  files['src/components/widgets/XCheckbox.tsx'] = WidgetsXCheckbox();
  files['src/components/widgets/XGrid.tsx'] = WidgetsXGrid();
  files['src/components/widgets/XInputNumber.tsx'] = WidgetsXInputNumber();
  files['src/components/widgets/XPicker.tsx'] = WidgetsXPicker();
  files['src/components/widgets/XRadio.tsx'] = WidgetsXRadio();
  files['src/components/widgets/XSwiper.tsx'] = WidgetsXSwiper();
  files['src/components/widgets/XText.tsx'] = WidgetsXText();
  files['src/components/widgets/XTextarea.tsx'] = WidgetsXTextarea();
  files['src/components/widgets/XSwitch.tsx'] = WidgetsXSwitch();
  files['src/components/widgets/XBase.tsx'] = WidgetsXBase();
  files['src/components/widgets/XButton.tsx'] = WidgetsXButton();
  files['src/components/widgets/XInput.tsx'] = WidgetsXInput();
  files['src/components/widgets/XList.tsx'] = WidgetsXList();
  files['src/components/WidgetWrapper.tsx'] = ComWidgetWrapper();

  files['src/api/index.ts'] = ApiIndex();

  files['src/reducers/toast.ts'] = ReducersToast();
  files['src/reducers/byk.ts'] = ReducersByk();
  files['src/reducers/index.ts'] = ReducersIndex();

  files['src/utils/hooks/useDataTreeToValue.ts'] = HooksIndex();
  files['src/utils/getWidgetValue.ts'] = UtilsGetWidgetValue();
  files['src/utils/setWidgetValue.ts'] = UtilsSetWidgetValue();

  files['src/constants/ReduxActionConstants.ts'] = ConstantsInex();

  files['src/actions/index.ts'] = ActionsIndex();
  files['src/sagas/index.ts'] = SagaIndex();
  files['src/sagas/dataTree.ts'] = SagaDataTree();
  files['src/sagas/byk.ts'] = SagaByk();
  files['src/sagas/XActionExecutionSagas.ts'] = SagaXAction();
  files['src/sagas/reEventsActions.ts'] = SagaReEvent();
  files['src/sagas/showAlert.ts'] = SagaShowAlert();
  files['src/sagas/linkage.ts'] = SagaLinkage();

  files['tsconfig.json'] = TsConfig();
  files['project.tt.json'] = ProjectTT();
  files['project.config.json'] = ProjectConfig();
  files['package.json'] = Package();
  files['babel.config.js'] = BabelConfig();
  files['.eslintrc'] = Eslintrc();
  files['.editorconfig'] = EditorConfig();

  files['config/dev.ts'] = ConfigDev();
  files['config/index.ts'] = ConfigIndex();
  files['config/prod.ts'] = ConfigProd();

  files['types/global.d.ts'] = TypesGlobal();

  files['src/index.html'] = SrcAppHtml();
  files['src/app.scss'] = SrcAppScss();

  files['src/store.ts'] = SrcStore();
  files['src/app.tsx'] = SrcApp();

  //files['src/pages/index/index.tsx'] = PagesIndex();
  //files['src/pages/index/index.scss'] = PagesIndexScss();
  //files['src/pages/index/index.config.ts'] = PagesIndexConfig();



  return files;
}
export default TaroConfigFiles;
