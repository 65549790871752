import {NotebookStepUiComponentProps} from "@byk/pages/QueryBuilder/components/notebook/types";
import React from "react";
import ClauseStep from "@byk/pages/QueryBuilder/components/notebook/steps/ClauseStep";
import {AggregationPicker} from "@byk/pages/QueryBuilder/components/AggregationPicker";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {AGGREGATION_OPERATORS} from "@byk/pages/QueryBuilder/constants";
import Aggregation from "@byk/pages/QueryBuilder/lib/queries/structured/Aggregation";

const aggTetherOptions = {
  attachment: "top left",
  targetAttachment: "bottom left",
  offset: "0 10px",
  constraints: [
    {
      to: "scrollParent",
      attachment: "together",
    },
  ],
};

const AggregateStep: React.FC<NotebookStepUiComponentProps> =
  ({
     step,
     color,
     query,
     updateQuery,
     readOnly,
     isLastOpened,
   }: NotebookStepUiComponentProps) => {

    const clauses: Aggregation[] = query.aggregations();
    const operators: any[] = AGGREGATION_OPERATORS;

    async function handleRemoveAggregation(aggregation: Aggregation) {
      await updateQuery(query.removeAggregation(aggregation));
    }

    async function handleUpdateAggregation(clause: number, aggregation: any) {
      console.log("AggregateStep--handleUpdateAggregation", clause, aggregation);
      await updateQuery(query.updateAggregation(clause, aggregation));
    }

    async function handleAddAggregation(aggregation: any) {
      console.log("AggregateStep--handleAddAggregation", aggregation);
      await updateQuery(query.addAggregation(aggregation));
    }

    function renderAggregationName(item: Aggregation, index: number) {
      return (
        <>{item.longDisplayName()}</>
      );
    }

    return (
      <ClauseStep
        items={clauses}
        initialAddText={"选择要查看的指标"}
        readOnly={readOnly}
        color={color}
        isLastOpened={isLastOpened}
        tetherOptions={aggTetherOptions}
        renderName={renderAggregationName}
        renderPopover={(aggregation, index) => (
          <AggregationPopover
            query={query}
            operators={operators}
            clause={aggregation}
            clauseIndex={index}
            onAddAggregation={handleAddAggregation}
            onUpdateAggregation={handleUpdateAggregation}
            onLegacyQueryChange={updateQuery}
            extraProps={{
              style: {color: "#88BF4D"},
              width: "260px"
            }}
          />
        )}
        onRemove={handleRemoveAggregation}
      />
    );

  };
export default AggregateStep;


interface AggregationPopoverProps {
  query: StructuredQuery;
  operators: any[];
  clause?: any[];
  onUpdateAggregation: (
    currentClause: any,
    nextClause: any,
  ) => void;
  onAddAggregation: (aggregation: any) => void;

  clauseIndex?: number;
  onLegacyQueryChange: (query: StructuredQuery) => void;

  // Implicitly passed by metabase/components/Triggerable
  onClose?: () => void;
  extraProps: any;
}

function AggregationPopover(
  {
    query,
    operators: baseOperators,
    clause,
    clauseIndex,
    onAddAggregation,
    onUpdateAggregation,
    onLegacyQueryChange,
    onClose,
    extraProps,
  }: AggregationPopoverProps) {
  const isUpdate = clause != null && clauseIndex != null;

  const operators = isUpdate
    ? query.selectedAggregationOperators(baseOperators, clause)
    : baseOperators;

  const legacyClause = isUpdate ? query.aggregations()[clauseIndex] : undefined;

  return (
    <AggregationPicker
      query={query}
      legacyClause={legacyClause}
      operators={operators}
      onSelect={aggregation => {
        console.log("AggregationPicker--onSelect", aggregation);
        if (isUpdate) {
          onUpdateAggregation(clauseIndex, aggregation);
        } else {
          onAddAggregation(aggregation);
        }
      }}
      onClose={onClose}
      extraProps={{...extraProps}}
      stageIndex={clauseIndex}/>
  );
}
